import { SET_MENU_INFO, CLEAR_MENU_INFO } from './ActionType';

// menu 관련해서 컨트럴 할 거 관리
const stateMenuInfoInit = {
  todayPopupBool: false,
  todayPopupLeft: 0,
  todayPopupTop: 0
}

const stateMenuInfo = (state = stateMenuInfoInit, action) => {
  let { type } = action;

  switch (type) {
    case SET_MENU_INFO:
      let newState = {
        ...state,
        [action.payload.fieldName]: action.payload.data
      }

      return newState;

    case CLEAR_MENU_INFO:
      return stateMenuInfoInit;

    default:
      return state;
  }
}

export default stateMenuInfo;