import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import request from '../../utils/Request.utils';
import SessionStorage from '../../utils/SessionStorage.utils';
import { nvl, checkPasswordPattern } from "../../utils/Common.utils";

import MyCompany from "./_MyCompany.js";//지점 정보
import ChangePwOk from "./_ChangePwOk.js";//지점 정보
import CustomAlert from '../../atoms/common/CustomAlert';

const ChangePw = () => {
  const navigate = useNavigate();

  const oriPasswdRef = useRef();
  const newPasswdConfirmRef = useRef();
  const newPasswdRef = useRef();

  const [newPasswdCheckBool, setNewPasswdCheckBool] = useState(false);
  const [oriPasswdCheckBool, setOriPasswdCheckBool] = useState(false);
  const [activateMode, setActivateMode] = useState('');

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const closeAlertChangePw = () => {
    setChangePwOkPopup((prev) => { return { ...prev, visible: false } })

    if(changePwOkPopup.closeType === "next") window.location.href = "/main/login"
  }

  const [changePwOkPopup, setChangePwOkPopup] = useState({ 
    closeType: 'next',
    alertMessage: '변경 되었습니다.',
    visible: false
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const changePasswdActivate = () => {
    if(nvl(oriPasswdRef.current.value) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "기존 비밀번호 입력해주세요." } })

      return;
    }

    if(nvl(newPasswdRef.current.value) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "새로운 비밀번호 입력해주세요." } })

      return;
    } else {
      if(!checkPasswordPattern(newPasswdRef.current.value, 8)) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "비밀번호는 영문, 숫자, 특수문자 조합 8자 이상 가능합니다." } })

        return;
      }
    }

    if(nvl(newPasswdConfirmRef.current.value) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "새로운 비밀번호 확인 입력해주세요." } })

      return;
    }

    oriPasswdCheck();    
  }

  const oriPasswdCheck = () => {
    request.post(`/api/manager/${userInfo.seq}`, { manager_passwd: nvl(oriPasswdRef.current.value) }, (response) => {
      // console.log(response)

      if(response.messageCode === 200 && response.data > 0) {
        setOriPasswdCheckBool(true)
        newPasswdChangeDo();
      } else setOriPasswdCheckBool(false)
    });
  }

  const newPasswdCheck = () => {
    if(newPasswdRef.current.value !== newPasswdConfirmRef.current.value) setNewPasswdCheckBool(true);
    else setNewPasswdCheckBool(false);
  }

  const newPasswdChangeDo = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "비밀번호를 변경하시겠습니까?" } })
    setActivateMode('passwdChange'); // 다음 실행할 함수를 호출하기 위해서
  }

  const returnAlertValue = (value) => {
    if(nvl(value) === "OK") {
      if(activateMode === "passwdChange") {
        request.put(`/api/manager/${userInfo.seq}`, { manager_passwd: nvl(newPasswdRef.current.value) }, (res) => {
          // console.log(res)
    
          if(res.messageCode === 200) {
            setChangePwOkPopup((prev) => { return { ...prev, visible: true } })

            SessionStorage.clearItem("managerSession")

          } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "비밀번호 변경중 에러가발생했습니다." } })
        });
      }
    }
  }

	return (
		<>
			<section className="com_content com_mypage p_change_pw com_has_mycompany">
				<div className="container">
          <article className="top_area">
            <p className="tit">마이페이지<span className="sub_tit">비밀번호 변경</span></p>
          </article>
          <article className="body_area">
            <ul className="com_input_section column">
              <li className="tit_field">기존비밀번호</li>
              <li className="input_field">
                <input type="password" ref={ oriPasswdRef } className="input" defaultValue="" placeholder="기존 비밀번호를 입력해 주세요." />
                { !oriPasswdCheckBool && <p className="txt_red">비밀번호가 정확하지 않습니다.</p> }
              </li>
              <li className="tit_field">새로운 비밀번호 입력</li>
              <li className="input_field">
                <input type="password" ref={ newPasswdRef } placeholder="영문, 숫자, 특수문자  8자 이상 입력" className="input" onChange={ newPasswdCheck } />
                <p className="txt_red">사용가능한 특수문자 ~ ! @ \ # $ % &lt; &gt; ^ &amp; *</p>
              </li>
              <li className="tit_field">새로운 비밀번호 확인</li>
              <li className="input_field">
                <input type="password" ref={ newPasswdConfirmRef } className="input" onChange={ newPasswdCheck } />
                { newPasswdCheckBool && <p className="txt_red">새로운 비밀번호가 일치하지 않습니다.</p> }
              </li>
            </ul>
            <div className="com_btn_wrap one">
              <button className="com_btn l blue" onClick={ () => changePasswdActivate() }>변경하기</button>
            </div>
          </article>
        </div>
        <MyCompany/>
        <ChangePwOk onClose={ closeAlertChangePw } popupVisible={ changePwOkPopup.visible } />
        { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage }
        returnValue={ returnAlertValue } /> : null }
			</section>
		</>
	)
}

export default ChangePw