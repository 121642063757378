//* 결제
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import { nvl, convertGender } from "utils/Common.utils.js";

const Detail = ({ onClose }) => {
  const popupSectionRef = useRef();
  const nodeRef = useRef(null);
  const stateCustomer = useSelector(state => state.Customer);

  return (
		<>
      <section ref={popupSectionRef} className="com_popup type_normal pop_detail active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container xxl">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">결제 내역 상세</h1>
            </div>
            <div className="pop_body">
            <article className="com_list">
                <table className="list">
                  <colgroup><col width="6%" /><col /><col width="11%" /><col width="11%" /><col width="15%" /></colgroup>
                  <thead>
                    <tr>
                      <th></th>
                      <th>이름</th>
                      <th>과세</th>
                      <th>급여</th>
                      <th>금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td className="tit">[일요일 특가] 턱 보톡스 50unit  (국산)</td>
                      <td>과세</td>
                      <td>급여</td>
                      <td>6,500</td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="sec_wrap">
                <div className="com_pay_detail">
                  <p className="com_pay_detail__tit">{nvl(stateCustomer.user_name) === "" ? "" : `${stateCustomer.user_name}(${convertGender(stateCustomer.user_gender)}/${stateCustomer.user_age})`} 결제내역</p>
                  <div className="scroll">
                    <ul className="com_input_section info">
                      <li className="tit_field">결제일</li>
                      <li className="input_field">2025, 08, 25 ㅣ 18 : 30</li>
                      <li className="tit_field">결제 방법</li>
                      <li className="input_field">(카드) 50,000원 (현대카드 6465-65465-64565)<br />
                        (현금) 50,000원<br />
                        (무통장) 40,000원
                      </li>
                      <li className="tit_field">수납자</li>
                      <li className="input_field">홍직원</li>
                      <li className="tit_field">현금 영수증</li>
                      <li className="input_field">90,000원</li>
                    </ul>
                    <ul className="com_input_section info">
                      <li className="tit_field">결제일</li>
                      <li className="input_field">2025, 08, 25 ㅣ 18 : 30</li>
                      <li className="tit_field">결제 방법</li>
                      <li className="input_field">(카드) 50,000원 (현대카드 6465-65465-64565)<br />
                        (현금) 50,000원<br />
                        (무통장) 40,000원
                      </li>
                      <li className="tit_field">수납자</li>
                      <li className="input_field">홍직원</li>
                      <li className="tit_field">현금 영수증</li>
                      <li className="input_field">90,000원</li>
                    </ul>
                  </div>
                </div>
                <div className="payment_wrap">
                  <ul className="com_input_section payment">
                    <li className="tit_field">과세 금액</li>
                    <li className="input_field ">670,000</li>
                    <li className="tit_field pd_bottom">비과세 금액</li>
                    <li className="input_field pd_bottom">100,000</li>
                    <li className="tit_field">- 쿠폰할인 금액</li>
                    <li className="input_field">100,000</li>
                    <li className="tit_field">-포인트 결제 금액</li>
                    <li className="input_field">70,000</li>
                    <li className="tit_field ">결제 금액</li>
                    <li className="input_field">
                      <span className="txt_blue">670,000</span>
                    </li>
                  </ul>
                  <ul className="com_input_section payment">
                    <li className="tit_field">진료비</li>
                    <li className="input_field">100,000</li>
                    <li className="tit_field">-건강생활유지비</li>
                    <li className="input_field">- 45,000</li>
                    <li className="tit_field ">진료비 본인 부담금</li>
                    <li className="input_field">
                      <span className="txt_blue">70,000</span>
                    </li>
                  </ul>
                  <ul className="com_input_section payment">
                    <li className="tit_field">총 결제 금액</li>
                    <li className="input_field">12,467,000</li>
                    <li className="tit_field">실 결제 금액</li>
                    <li className="input_field">467,000</li>
                    <li className="tit_field txt_red">미수금</li>
                    <li className="input_field txt_red">566,000</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn blue l" onClick={() => { onClose() }}>확인</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default Detail