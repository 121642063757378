//* 서류발급 > 진료확인서
import React, { forwardRef } from "react";
import { decrypt } from "../../utils/aes256.manager";
import moment from "moment";
import { convertJuminToAge, convertGender, nvl } from "../../utils/Common.utils";

const MedicalConfirm = forwardRef((props, ref) => {
  const today = moment(new Date()).format("YYYY년 MM월 DD일");
  const { selectedCustomer, userInfo, selectedArray, treatmentConfirmation, treatmentDateList, medicalTreatment, hospitalInfo, medicalLicense, selectedDoctorName} = props;


  return (
    <>
      <section className="com_document p_medical_confirm" ref={ref}>
        <h1 className="document_tit">진료확인서</h1>
        <div className="top_area">
          <div className="info">
            <ul className="list">
              <li>
                <div className="item">
                  <p className="tit_field">병록번호 :</p>
                  <p className="input_field">{nvl(selectedCustomer) === "" ? userInfo.chart_code : selectedCustomer.chart_code}</p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p className="tit_field">발급번호 :</p>
                  <p className="input_field">{nvl(selectedCustomer) === "" ? userInfo.pubNum : selectedCustomer.pubNum}</p>
                </div>
                <div className="item">
                  <p className="tit_field">주민번호 :</p>
                  <p className="input_field">{nvl(selectedCustomer) === "" ? userInfo.jumin_1 + "-" + userInfo.jumin_2 : (decrypt(selectedCustomer?.user_jumin)?.substring(0, 6) + "-" + decrypt(selectedCustomer?.user_jumin)?.substring(6, 13))}</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="box">
            <p className="tit">원부대 조필</p>
            <p className="sign">
              인
            </p>
          </div>
        </div>
        <table className="table">
          <colgroup><col width="17%" /><col width="17%" /><col width="9%" /><col width="10%" /><col width="11%" /><col width="15%" /><col width="7%" /><col /></colgroup>
          <tr>
            <th>성명</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.user_name : selectedCustomer.user_name}</td>
            <th>성별</th>
            <td>{nvl(selectedCustomer) === "" ? convertGender(userInfo.user_gender) : selectedCustomer.user_name}</td>
            <th>생년월일</th>
            <td>{nvl(selectedCustomer) === "" ? `${userInfo.user_dob?.slice(0, 4)}년 ${userInfo.user_dob?.slice(4, 6)}월 ${userInfo.user_dob?.slice(6, 8)}일` : `${selectedCustomer.user_dob?.slice(0, 4)}년 ${selectedCustomer.user_dob?.slice(4, 6)}월 ${selectedCustomer.user_dob?.slice(6, 8)}일`}</td>
            <th>연령</th>
            <td>{nvl(selectedCustomer) === "" ? convertJuminToAge((userInfo.jumin_1 + "-" + userInfo.jumin_2)) : convertJuminToAge((userInfo.jumin_1 + "-" + userInfo.jumin_2))}세</td>
          </tr>
          <tr>
            <th>주소</th>
            <td colSpan={7}>{nvl(selectedCustomer) === "" ? `${userInfo.user_address} ${userInfo.user_address_detail}`  : `${selectedCustomer.user_address} ${selectedCustomer.user_address_detail}`}</td>
          </tr>
          <tr>
            <th className="big">
              <br /><br /><br /><br /><br /><br />
              병명<br /><br /><br /><br /><br /><br /><br />
              {treatmentConfirmation.diagnosis_type === "017001000" ?
                <p className="left">
                  [v] 임상적 추정<br />
                  [&nbsp;] 최종진단
                </p>
                :
                <p className="left">
                  [&nbsp;] 임상적 추정<br />
                  [v] 최종진단
                </p>
              }
            </th>
            <td colSpan={7} className="memo" style={{ whiteSpace: "pre-line" }}>
              {selectedArray[0]?.disease_name.map((name, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {name}
                </React.Fragment>
              ))}
            </td>
          </tr>
          <tr>
            <th>진료기간</th>
            <td colSpan={4}>{selectedArray.length === 0 && treatmentDateList.length === 0 ? "-" : selectedArray.length > 0 ? `${treatmentConfirmation?.treatment_start} ~ ${treatmentConfirmation?.treatment_end}` : `${moment(medicalTreatment[0]?.data[0].order_date).format("YYYY-MM-DD")} ~ ${moment(medicalTreatment[medicalTreatment.length - 1].data[medicalTreatment[medicalTreatment.length - 1].data.length - 1].order_date).format("YYYY-MM-DD")}`}</td>
            <th>방문일수</th>
            <td colSpan={2}>총 {selectedArray.length === 0 && treatmentDateList.length === 0 ? 0 : selectedArray.length > 0 ? 1 : treatmentDateList.length}일</td>
          </tr>
          <tr>
            <th>내원일</th>
            {/* <td colSpan={7}>(2021년) 05/16, 06/03, (2022년) 05/16</td> */}
            {selectedArray?.length === 0 ? (
              medicalTreatment?.length === 0 ? (
                ""
              ) : (
                medicalTreatment?.map((orders, index) => (
                  <React.Fragment key={index}>
                    <td /*colSpan={7}*/>
                      ({orders.year}) 
                      {orders.data.map((el, index) => (
                        <React.Fragment key={el.order_seq}>
                          {moment(el?.order_date).format('MM/DD')}
                          {index < orders.data.length - 1 && ', '}
                        </React.Fragment>
                      ))}
                    </td >
                  </React.Fragment>
                ))
              )) : 
              (
              medicalTreatment?.length === 0 ? "" : medicalTreatment?.map((orders, index) => (
                <React.Fragment key={index}>
                  {selectedArray[0]?.order_date?.substring(0, 4) === orders.year ? (
                    <td /*colSpan={7}*/>
                    ({orders.year}){' '}
                    {orders.data.map((el, index) => (
                      selectedArray[0]?.order_date === el.order_date ? (
                        <React.Fragment key={el.order_seq}>
                          {moment(el?.order_date).format('MM/DD')}
                          {index < orders.data.length - 1 && ', '}
                        </React.Fragment>
                      ) : null
                    ))}
                  </td >
                  ) : null}
                </React.Fragment>
              )))
            }
          </tr>
          <tr>
            <th className="big"><br /><br />진료내역<br /><br /></th>
            <td colSpan={7} className="memo">상기 환자는 상기병명으로 본원에서 {selectedArray.length === 0 && treatmentDateList.length === 0 ? "-" : selectedArray.length > 0 ? `${treatmentConfirmation?.treatment_start} ~ ${treatmentConfirmation?.treatment_end}` : `${moment(medicalTreatment[0]?.data[0].order_date).format("YYYY년 MM월 DD일")} ~ ${moment(medicalTreatment[medicalTreatment.length - 1].data[medicalTreatment[medicalTreatment.length - 1].data.length - 1].order_date).format("YYYY년 MM월 DD일")}`} 사이에 진료를 받았기에 이를 확인함.</td>
            
            {/* 진료내역이 고정적인 내용인건지 확인해봐야함 */}
            {/* <td colSpan={7} className="memo">{treatmentConfirmation.treatment_detail}</td> */}
          </tr>
          <tr>
            <th>비고</th>
            <td colSpan={7}>{treatmentConfirmation.paper_remark}</td>
          </tr>
          <tr>
            <td colSpan={8} className="big info">
              <p className="tit">위와 같이 확인함</p>
              <p className="date">발행일 : {today}</p>
              <ul className="list">
                <li className="item">
                  <p className="tit_field">의&nbsp;료&nbsp;기&nbsp;관&nbsp;:</p>
                  <p className="input_field">{hospitalInfo.hospital_name}</p>
                </li>
                <li className="item">
                  <p className="tit_field">주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소&nbsp;:</p>
                  <p className="input_field">{hospitalInfo.hospital_address} {hospitalInfo.hospital_address_detail}</p>
                </li>
                <li className="item">
                  <p className="tit_field">전&nbsp;화&nbsp;번&nbsp;호&nbsp;:</p>
                  <p className="input_field">{hospitalInfo.hospital_phone}</p>
                </li>
              </ul>
              <ul className="list">
                <li className="item">
                  <p className="tit_field">면허번호&nbsp;:</p>
                  <p className="input_field">제 {medicalLicense} 호</p>
                  <p className="tit_field">의사 성명&nbsp;:</p>
                  <p className="input_field">{selectedDoctorName}<span className="sign">(서명 또는 날인)</span></p>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </section>
    </>
  )
})

export default MedicalConfirm