import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nvl } from "../utils/Common.utils";
import Today from "../components/notice/Today";

// 1. a 태그 안에 아무 내용 없이 코딩하지 말것
// 2. a 태그에 href는 비우지 말고 #만 사용도 하지 말것 (#/ 사용하기)
// 3. 사용하지 않는 컴포넌트, 라이브러리와 같은 모듈들 import 시키지 않기
// 4. 빈 문자(열)를 감싼 tag 사용하지 않기. 예) <h1></h1>, <a href='#/'></a>, 기타 등등
// 5. 퍼블리싱 하실 때 colspan 은 colSpan 으로 사용해 주세요.

const Gnb = () => {
	const dispatch = useDispatch();
	const stateMenuManager = useSelector(state => state.MenuManager);

	if (nvl(stateMenuManager.todayPopupBool) === "") dispatch({ type: "setMenuInfo", payload: { fieldName: "todayPopupBool", data: false } });

	let { pathname } = window.location
	pathname = pathname.toLowerCase()

	return (
		<>
			<header>
				<nav>
					{/* <Link to="/main" className="logo">&nbsp;</Link> */}
					<a href="/main" className="logo">&nbsp;</a> {/* 메인은 refresh 효과를 받기 위해서 a tag 사용 */}
					<div>{stateMenuManager?.todayPopupBool && <Today />}</div>
					<div className="menu">
						{/* <Link to="/notice/today" className={pathname === "/notice/today" ? "active" : ""} title="오늘의 안내사항"><i className="pin">&nbsp;</i></Link> */}
						<Link to="" onClick={() => dispatch({ type: "setMenuInfo", payload: { fieldName: "todayPopupBool", data: !stateMenuManager.todayPopupBool } })} title="오늘의 안내사항"><i className="pin">&nbsp;</i></Link>
						<Link to="/notice?noti_type=I" className={pathname === "/notice" ? "active" : ""} title="공지사항"><i className="notice">&nbsp;</i></Link>
						<Link to="/dashboard" className={pathname === "/dashboard" ? "active" : ""} title="대시보드"><i className="dashboard">&nbsp;</i></Link>
						<Link to="/chart" className={pathname === "/chart" ? "active" : ""} title="차트메인"><i className="chart">&nbsp;</i></Link>
						<Link to="/reservation" className={pathname === "/reservation" ? "active" : ""} title="예약확인"><i className="reservation">&nbsp;</i></Link>
						<Link to="/payment" className={pathname === "/payment" ? "active" : ""} title="결제확인"><i className="payment">&nbsp;</i></Link>
						<Link to="/crm/day" className={(pathname).indexOf("/crm") >= 0 ? "active" : ""} title="고객관리"><i className="crm">&nbsp;</i></Link>
						<Link to="/boffice" className={(pathname).indexOf("/boffice") >= 0 ? "active" : ""} title="시술상품 관리"><i className="admin">&nbsp;</i></Link>
						<Link to="/member" className={pathname === "/member" ? "active" : ""} title="직원관리"><i className="member">&nbsp;</i></Link>
						<Link to="/statistics/summary" className={(pathname).indexOf("/statistics") >= 0 ? "active" : ""} title="통계"><i className="statistics">&nbsp;</i></Link>
						<Link to="/sales" className={(pathname).indexOf("/sales") >= 0 ? "active" : ""} title="매출관리"><i className="sales">&nbsp;</i></Link>
					</div>
				</nav>
			</header>
		</>
	)
}

export default Gnb