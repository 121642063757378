//* 당일오더-인쇄
import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from 'react-to-print';
import Draggable from "react-draggable";
import moment from 'moment';
import ExplanAdd from "./_ExplanAdd.js"; //부연설명 입력
import { convertJuminToAge, convertGender, nvl } from "utils/Common.utils";
import request from "utils/Request.utils.js";
import CustomAlert from "atoms/common/CustomAlert.js";

const TodayOrderList = ({ list, setPopup, setTreatment }) => {

  const onPopupClick = (e) => {
    setPopup((prev) => { return { ...prev, note1: true } });
    setTreatment({ ...list[e.target.id], id: e.target.id });
  }

  return (
    <tbody>
      {
        list?.map((item, index) => {
          const useTimes = [];
          for (let i = 0; i < item.current_times; i++) {
            useTimes.push(item.used_times + (i + 1));
          }
          return (
            <tr key={index}>
              <td className="tit bold">{item.goods_name}</td>
              <td>{/* {item.used_times}/ */}{item.total_times}</td>
              <td>{item.used_times}</td>
              <td className="row" >
                <li className="td write">{item.comment}<button><i id={index} onClick={onPopupClick} className="svg_icon icon_write yellow">&nbsp;</i></button></li>
              </td>
            </tr>
          )
        })
      }
    </tbody>
  )
};

const OrderPrint = ({ onClose, userData, popup, setPopup, managerInfo, orderInfo, todayOrders, setTodayOrders }) => {
  /** TODO 
   * 시술 시작시간, 시술층, 시술방, 상담자명 정보 가져오기 */
  const time = moment(new Date()).format("HH:mm"); // 10:35
  const componentRef = useRef();
  const nodeRef = useRef(null);
  const [counselor, setCounselor] = useState('상담자명');
  const [roomInfo, setRoomInfo] = useState({ floor: '4F', name: 'C2' });
  const today = moment(new Date()).format("YYYYMMDD");
  const [treatment, setTreatment] = useState({})
  const [todayRequest, setTodayRequest] = useState({ content: "" });
  const [todayMemo, setTodayMemo] = useState()
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })


  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  }

  const closeCustomAlert = (() => {
    setAlertLayerPopup((prev) => { return { ...prev, visible: false } })
  });


  // 관리자 메모(차트/대시보드의 메모) 불러오기
  const getMemoList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) setTodayMemo(response.data[0]);
      else console.debug('Error >> ', response);
    }

    request.get(`/api/etc/memocard/common/${userData.user_seq}?reg_date=${moment().format("YYYY-MM-DD")}`, null, successHandler);
  }

  // 요청사항(차트 고객 요청사항) 불러오기
  const getCustomerRequestList = (() => {
    const successHandler = (response) => {
      if (response.messageCode === 200 && response.data.length > 0) setTodayRequest(response.data[0])

      else console.log('Error >> ', response);
    }

    request.get(`/api/user/${userData.user_seq}/request?reg_date=${moment().format("YYYY-MM-DD")}`, null, successHandler);
  });

  useEffect(() => {
    if (userData.user_seq > 0) {
      getCustomerRequestList()
      getMemoList()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.user_seq])

  // 인쇄 버튼 클릭
  const printOrder = () => {
    // 메모 저장
    saveOrderMemo() 
  }

  // 메모 저장
  const saveOrderMemo = () => {

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // 요청사항 저장
        saveOrderRequest()
      } else console.debug('Error >> ', response);
    }

    const params = {
      user_seq: userData.user_seq,
      memo: nvl(todayMemo?.memo) === "" ? "" : todayMemo.memo,
      reg_manager_seq: managerInfo?.seq,
      reg_date: moment().format("YYYY-MM-DD")
    }

    request.post(`/api/etc/memocard`, params, successHandler);
  }


  // 요청사항 저장
  const saveOrderRequest = () => {

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // 프린트
        handlePrint();
      } else if(response.messageCode === 999) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "접수먼저 해주세요." } })
        return;
      }else console.debug('Error >> ', response);
    }

    const params = {
      order_seq: orderInfo.order_seq,
      content: nvl(todayRequest.content) === "" ? "" : todayRequest.content,
      reg_manager_seq: managerInfo.seq,
      reg_date: moment().format("YYYY-MM-DD")
    }

    console.log(params)
    request.post(`/api/user/${userData.user_seq}/request`, params, successHandler);
  }
    

  // 프린트
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `동의서내역_${today}`,
  });


  return (
    <section className="com_popup type_normal pop_order_print active">
      <Draggable nodeRef={nodeRef}>
        <div ref={nodeRef}>
          <div className="pop_container l">
            <div ref={el => componentRef.current = el}>
              <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
              <div className="pop_header">
                <h1 className="pop_title">{new Intl.DateTimeFormat('kr').format(new Date()).replaceAll('.', ',')} 오더</h1>
              </div>
              <div className="pop_body">
                <article className="com_custom_info">
                  <p className="item">{userData.user_name} ({convertGender(userData.user_gender)}/{convertJuminToAge((userData.jumin_1 + "-" + userData.jumin_2))})</p>
                  <p className="item">{userData.chart_code} | {time} | {`${roomInfo.floor}, ${roomInfo.name}`}</p>
                </article>
                <article className="consultant">{nvl(orderInfo?.counselor_manager_name) === "" ? "-" : orderInfo?.counselor_manager_name}</article>
                <div className="com_list">
                  <table className="list">
                    <colgroup><col width="" /><col width="11.1%" /><col width="14.5%" /><col width="30%" /></colgroup>
                    <thead>
                      <tr>
                        <th>시술명</th>
                        <th>회차</th>
                        <th>사용할 회차</th>
                        <th>부연설명</th>
                      </tr>
                    </thead>
                    <TodayOrderList list={todayOrders} setPopup={setPopup} setTreatment={setTreatment} />
                  </table>
                </div>
                <p className="com_input_section__sec_tit">관리자 메모</p>
                <ul className="com_input_section">
                  <li className="input_field full">
                    <textarea className="textarea" placeholder="직접입력" value={todayMemo?.memo || ""} onChange={(e) => setTodayMemo({ ...todayMemo, memo: e.target.value })} style={{ resize: "none" }} />
                  </li>
                </ul>
                <p className="com_input_section__sec_tit">요청사항</p>
                <ul className="com_input_section">
                  <li className="input_field full">
                    <textarea className="textarea" placeholder="직접입력" value={todayRequest?.content || ""} onChange={(e) => setTodayRequest({ ...todayRequest, content: e.target.value })} style={{ resize: "none" }} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue line" onClick={printOrder}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
            </div>
          </div>
        </div>
      </Draggable>
      {popup.note1 && <ExplanAdd treatment={treatment} onClose={() => { onClosePop('note1'); }} setTodayOrders={setTodayOrders} userData={userData} managerInfo={managerInfo} />}
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={alertLayerPopup.returnValue} /> : null}
    </section>
  )
}

export default OrderPrint