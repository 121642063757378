//* 서류발급 > 진료비 계산서 영수증
import React, { forwardRef } from "react";
import moment from "moment";
import { nvl } from "../../utils/Common.utils";

const MediReceipt = forwardRef((props, ref) => {
  const { selectedCustomer, userInfo, medicalTreatment, hospitalInfo } = props;


  return (
    <>
      <section className="com_document p_medi_receipt" ref={ref}>
        <div className="document_tit__wrap">
          <fieldset className="com_check_wrap">
            <input type="checkbox" className="checkbox" id="chk01" checked disabled />
            <label className="btn_checkbox" htmlFor="chk01">&nbsp;</label>
            <label htmlFor="chk01">외래</label>
            <input type="checkbox" className="checkbox" id="chk02" disabled />
            <label className="btn_checkbox" htmlFor="chk02">&nbsp;</label>
            <label htmlFor="chk02">입원</label>&nbsp;&nbsp;
            (<input type="checkbox" className="checkbox" id="chk03" disabled />
            <label className="btn_checkbox" htmlFor="chk03">&nbsp;</label>
            <label htmlFor="chk03">퇴원</label>
            <input type="checkbox" className="checkbox" id="chk04" disabled />
            <label className="btn_checkbox" htmlFor="chk04">&nbsp;</label>
            <label htmlFor="chk04">중간</label>&nbsp;&nbsp;)
          </fieldset>
          <h1 className="document_tit">진료비 계산서 영수증</h1>
        </div>
        <table className="table">
          <colgroup><col width="3.2%" /><col width="7%" /><col width="7%" /><col width="10%" /><col width="10%" /><col width="9.5%" /><col width="10%" /><col width="10%" /><col width="7%" /><col width="10%" /><col /></colgroup>
          <tr>
            <th colSpan={3}>환자등록번호</th>
            <th colSpan={3}>환자 성명</th>
            <th colSpan={4}>진료기간</th>
            <th>야간(공휴일)진료</th>
          </tr>
          <tr>
            <td colSpan={3}>{nvl(selectedCustomer) === "" ? userInfo.chart_code : selectedCustomer.chart_code}</td>
            <td colSpan={3}>{nvl(selectedCustomer) === "" ? userInfo.user_name : selectedCustomer.user_name}</td>
            <td colSpan={4}>{`${moment(medicalTreatment[0]?.data[0]?.order_date).format("YYYY-MM-DD")} ~ ${moment(medicalTreatment[medicalTreatment?.length - 1]?.data[medicalTreatment[medicalTreatment?.length - 1]?.data?.length - 1]?.order_date).format("YYYY-MM-DD")}`}</td>
            <td>
              <fieldset className="com_check_wrap">
                <input type="checkbox" className="checkbox" id="chk05" disabled />
                <label className="btn_checkbox" htmlFor="chk05">&nbsp;</label>
                <label htmlFor="chk05">야간/공휴일</label>
              </fieldset>
            </td>
          </tr>
          <tr>
            <th colSpan={3}>진료과목</th>
            <th colSpan={3}>질병균(DRG)번호</th>
            <th colSpan={2}>병실</th>
            <th colSpan={2}>환자구분</th>
            <th>영수증번호<br />(연월-일련번호)</th>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td colSpan={3}></td>
            <td colSpan={2}></td>
            <td colSpan={2}>일반</td>
            <td>20230426-0001</td>
          </tr>
          <tr>
            <th colSpan={3} rowSpan={3}>항목</th>
            <th colSpan={3}>급여</th>
            <th colSpan={2}>비급여</th>
            <th colSpan={3}>금액산정내용</th>
          </tr>
          <tr>
            <th colSpan={2}>일부 본인부담</th>
            <th rowSpan={2}>전액<br />본인부담</th>
            <th rowSpan={2}>선택진료료</th>
            <th rowSpan={2}>선택진료료 외</th>
            <th rowSpan={2} colSpan={2}>진료비 총액 ⑥<br />(①+②+③+④+⑤)</th>
            <td rowSpan={2} className="right">759,900</td>
          </tr>
          <tr>
            <th>본인부담금</th>
            <th>공단부담금</th>
          </tr>
          <tr>
            <th rowSpan={16}>기본항목</th>
            <th colSpan={2}>진찰료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th rowSpan={2} colSpan={2}>환자부담총액 ⑦<br />(①+③+④+⑤)</th>
            <td rowSpan={2} className="right">759,900</td>
          </tr>
          <tr>
            <th colSpan={2}>입원료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={2}>식대</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th rowSpan={2} colSpan={2}>이미납부한금액 ⑧</th>
            <td rowSpan={2} className="right">0</td>
          </tr>
          <tr>
            <th rowSpan={2}>투약 및<br />조제료</th>
            <th>행위료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th>약품비</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th rowSpan={2} colSpan={2}>지원금 ⑬</th>
            <td rowSpan={2} className="right">0</td>
          </tr>
          <tr>
            <th rowSpan={2}>주사료</th>
            <th>행위료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th>약품비</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th rowSpan={2} colSpan={2}>할인액 ⑪</th>
            <td rowSpan={2} className="right">0</td>
          </tr>
          <tr>
            <th colSpan={2}>마취료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={2}>처치 및 수술료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th rowSpan={2} colSpan={2}>환불액 ⑫</th>
            <td rowSpan={2} className="right">0</td>
          </tr>
          <tr>
            <th colSpan={2}>검사료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={2}>영상진단료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th rowSpan={2} colSpan={2}>납부 할 금액 ⑨<br />(⑦-⑧-⑪-⑬)</th>
            <td rowSpan={2} className="right">0</td>
          </tr>
          <tr>
            <th colSpan={2}>방사선치료료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={2}>치료재료대</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th rowSpan={5}>납부 한 금액<br />(⑩)</th>
            <th>카드</th>
            <td className="right">0</td>
          </tr>
          <tr>
            <th colSpan={2}>재활 및 물리치료료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th>현금영수증</th>
            <td className="right">0</td>
          </tr>
          <tr>
            <th colSpan={2}>정신요법료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th>현금</th>
            <td className="right">0</td>
          </tr>
          <tr>
            <th colSpan={2}>전혈 및 혈액성분제제료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th>기타</th>
            <td className="right">0</td>
          </tr>
          <tr>
            <th rowSpan={5}>선택항목</th>
            <td colSpan={2}>CT 진단료</td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th>합계</th>
            <td className="right">0</td>
          </tr>
          <tr>
            <th colSpan={2}>MRI 진단료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th colSpan={2}>미납 금액⑦-⑧-⑪-⑫</th>
            <td className="right">75,900</td>
          </tr>
          <tr>
            <th colSpan={2}>PET 진단료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th colSpan={3}>현금영수증 (소득공제용)</th>
          </tr>
          <tr>
            <th colSpan={2}>초음파 진단료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th colSpan={2}>신분확인번호</th>
            <td></td>
          </tr>
          <tr>
            <th colSpan={2}>보철·교정료</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th colSpan={2}>현금승인번호</th>
            <td></td>
          </tr>
          <tr>
            <th colSpan={3}>「국민건강보험법」 제 41조의 4에 따른 요양급여</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <th rowSpan={7} colSpan={2}>비고</th>
            <td rowSpan={7}></td>
          </tr>
          <tr>
            <th colSpan={3}>65세 이상 등 정액</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={3}>정액 수가(요양병원)</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={3}>정액 수가(완화의료)</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={3}>질병군 포괄수가</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={3}>기타</th>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
            <td className="right"></td>
          </tr>
          <tr>
            <th colSpan={3}>합계</th>
            <td>
              <div className="total">
                <span>①</span>
                <span>0</span>
              </div>
            </td>
            <td>
              <div className="total">
                <span>②</span>
                <span>0</span>
              </div>
            </td>
            <td>
              <div className="total">
                <span>③</span>
                <span>0</span>
              </div>
            </td>
            <td>
              <div className="total">
                <span>④</span>
                <span>0</span>
              </div>
            </td>
            <td>
              <div className="total">
                <span>⑤</span>
                <span>0</span>
              </div>
            </td>
          </tr>
          <tr>
            <th colSpan={3}>상한액 초과금</th>
            <td className="right"></td>
            <td colSpan={4}></td>
            <td colSpan={2}>선택진료 신청</td>
            <td>
              <fieldset className="com_check_wrap">
                <input type="checkbox" className="checkbox" id="chk02_1" disabled />
                <label className="btn_checkbox" htmlFor="chk02_1">&nbsp;</label>
                <label htmlFor="chk02_1">유</label>
                <input type="checkbox" className="checkbox" id="chk02_2" checked disabled />
                <label className="btn_checkbox" htmlFor="chk02_2">&nbsp;</label>
                <label htmlFor="chk02_2">무</label>&nbsp;&nbsp;
              </fieldset>
            </td>
          </tr>
          <tr>
            <th colSpan={3}>요양기관 종류</th>
            <td colSpan={8}>
              <fieldset className="com_check_wrap">
                <input type="checkbox" className="checkbox" id="chk03_1" checked disabled />
                <label className="btn_checkbox" htmlFor="chk03_1">&nbsp;</label>
                <label htmlFor="chk03_1">의원급·보건기관</label>
                <input type="checkbox" className="checkbox" id="chk03_2" disabled />
                <label className="btn_checkbox" htmlFor="chk03_2">&nbsp;</label>
                <label htmlFor="chk03_2">병원급</label>
                <input type="checkbox" className="checkbox" id="chk03_3" disabled />
                <label className="btn_checkbox" htmlFor="chk03_3">&nbsp;</label>
                <label htmlFor="chk03_3">종합병원</label>
                <input type="checkbox" className="checkbox" id="chk03_4" disabled />
                <label className="btn_checkbox" htmlFor="chk03_4">&nbsp;</label>
                <label htmlFor="chk03_4">상급종합병원</label>
              </fieldset>
            </td>
          </tr>
          <tr>
            <th colSpan={3}>사업자 등록번호</th>
            <td colSpan={2}>6574100643</td>
            <th>상호</th>
            <td colSpan={2}>{hospitalInfo.hospital_name}</td>
            <th colSpan={2}>전화번호</th>
            <td>{hospitalInfo.hospital_phone}</td>
          </tr>
          <tr>
            <th colSpan={3}>사업장 소재지</th>
            <td colSpan={5}>대전광역시</td>
            <th colSpan={2}>대표자</th>
            <td>박유라</td>
          </tr>
          <tr>
            <th colSpan={8}>항목별 설명</th>
            <th colSpan={3}>일반사항 안내</th>
          </tr>
          <tr>
            <td colSpan={8} className="memo">
              <ul className="list">
                <li>
                  일부 본인부담 : 일반적으로 다음과 같이 본인부담률을 적용하나, 요양기관 지역, 요양기관의 종별, 환자 자격, 「국민건강보험」 제 41조의 4에 따른 요양급여 여부, 병실종류 등에 따라 달라질 수 있습니다.<br />
                  <p className="depth"><i className="bullet">-</i>외래 본인부담률 : 요양기관 종별에 따라 30%~60%(의료급여는 수급권자 종별 및 의료급여기관 유형등에 따라 0원 ~ 2500원, 0% ~ 15%)등</p>
                  <p className="depth"><i className="bullet">-</i>입원 본인부담률 : 20%(의료급여는 수급권자 종별 및 의료급여기관 유형등에 따라 0% ~ 10%)등
                    <p className="depth"><i className="bullet">※</i>식대: 50%(의료급여는 20%)<br />CT·MRI·PET: 외래 본인부담률(의료급여는 입원 본인부담률과 동일)<br />
                      「국민건강보험」 제 41조의 4에 따른 요양급여(선별급여): 보건복지부장관이 고시한 항목별 본인 부담률</p>
                    <p className="depth"><i className="bullet">※</i>상급종합병원 입원료: 2인실 50%, 3인실 40%, 4인실 30% / 치과병원을 제외한 병원급 의료기관 입원료 : 2인실 40%, 3인실 30%</p>
                  </p>
                </li>
                <li>
                  전액 본인부담 : 「국민건강보험 시행규칙」 별표 6 또는 「의료급여법 시행규칙」 별표 1의2에 따라 적용되는 항목으로 건강보험(의료급여)에서 금액을 정하고 있으나 진료비 전액을 환자 본인이 부담합니다.
                </li>
                <li>
                  상한액 초과금 : 본인부담액 상한제에 따라 같은 의료기관에서 연간 500만원(2015년부터는 「국민건강보험 시행령」 별표 3 제2호에 따라 산정한 본인부담상한액의 최고 금액, 환자가 내는 보험료등에 따라 다를 수 있음) 이상 본인부담금이 발생한 경우 공단이 부담하는 초과분 중 사전 정산하는 금액을 말합니다.
                  <p className="depth"><i className="bullet">※</i>전액 본인부담 및 「국민건강보험법」 제 41조의 4에 따른 요양급여의 본인부담금 등은 본인부담상한액 산정시 제외합니다.</p>
                </li>
                <li>
                  "질병균 포괄수가"란 「국민건강보험법 시행령」 제21조제3항제2호 및 「국민건강보험 요양급여의 기준에 관한 규칙」 제8조3항에 따라 보건복지부장관이 고시한 질병군 입원진료에 대하여 해당입원진료와 관련되는 여러 의료행위를 하나의 행위로 정하여 요양급여비용을 결정한 것을 말합니다. 다만, 해당 질병군의 입원진료와 관련되는 의료행위라도 비급여대상이나 이송처치료등 포괄수가에서 제외되는 항목은 위 표의 기본항목 및 선택항목란에 합산하여 표기됩니다.
                </li>
              </ul>
            </td>
            <td colSpan={3} className="memo">
              <ul className="list">
                <li>이 계산서·영수증에 대한 세부내용은 요양기관에 요구하여 제공받을 수 있습니다.</li>
                <li>「국민건강보험법」 제48조 또는 「의료급여법」 제11조의3에 따라 환자가 전액 부담한 비용과 비급여로 부담한 비용의 타당성 여부를 건강보험심사평가원(☏1644-2000, 홈페이지 : www.hira.or.kr)에 확인 요청하실 수 있습니다.</li>
                <li>계산서·영수증은 「소득세법」에 따른 의료비 공제신청 또는 「조세특례제한법」에 따른 현금영수증 공제 신청(현금영수증 승인번호가 적힌 경우만 해당합니다)에 사용할 수 있습니다. 다만, 지출증빙용으로 발급된"현금영수증(지출증빙)"은 공제신청에 사용할 수 없습니다.(현금영수증 문의 126 인터넷 홈페이지 : http://현금영수증.kr)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={11} className="small">주(註) : 진료항목 중 선택항목은 요양기관의 특성에 따라 추가 또는 생략할 수 있으며, 야간(공휴일)진료 시 진료비가 가산될 수 있습니다.</td>
          </tr>
        </table>
      </section>
    </>
  )
})




export default MediReceipt