//* 시술명 추가
const AddUsingGoodsOld =
  ({
    addProcedureGoods,
    procedure,
    index,
    usingProcedureList,
    addProcedureTime,
    deleteProcedureTime,
    procedureTimeList,
    usingProcedureHandler,
    timesHandler,
    deleteGoods,
    location
  }) => {

    return (
      <section className="box">
        <ul className="input_section">
          <li className="top_tit col01">
            <p className="tit">{index + 1}시술명</p>
            <div><input type="text" className="input" name="goods_name" value={procedure.goods_name || ""} onChange={(e) => usingProcedureHandler(e, index)} /></div>
          </li>
          <li className="col02 join2 price_field">
            <input type="checkbox" className="btn_chk" name="event_yn" id={"event03" + index} value={procedure.event_yn} checked={procedure.event_yn === "Y"} onChange={(e) => usingProcedureHandler(e, index)} disabled /><label className="com_btn chk blue m" htmlFor={"event03" + index}>이벤트 시술노출</label>
            <div className="top_tit">
              <p className="tit">시술 시작금액(원)</p>
              <div><input type="text" className={procedure.event_yn === "Y" ? "input price_x" : "input price"} name="goods_price" value={procedure.goods_price || ""} onChange={(e) => usingProcedureHandler(e, index)} /></div>
            </div>
            <span className="com_txt_between">→</span>
            <div className="top_tit">
              <p className="tit">시술 할인금액(원)</p>
              <div><input type="text" className="input point" name="goods_sale_price" value={procedure.goods_sale_price || ""} disabled={procedure.event_yn === "N"} onChange={(e) => usingProcedureHandler(e, index)} /></div>
            </div>
            <input type="checkbox" className="btn_chk" name="vat_type" id={"chk01" + index} value={procedure.vat_type} checked={procedure.vat_type === "I"} onChange={(e) => usingProcedureHandler(e, index)} /><label className="com_btn chk blue m" htmlFor={"chk01" + index}> 부가세 별도</label>
            <div className="btn_area">
              {location.state === null && ((usingProcedureList.length === 1 && procedure.goods_times === 1) || (usingProcedureList.length > 1 && procedure.goods_times === usingProcedureList.length)) && <button className="com_btn blue m" onClick={() => addProcedureGoods(procedure.goods_times)}><i className="svg_icon icon_add yellow">&nbsp;</i>시술추가</button>}

              {location.state !== null && ((usingProcedureList.length === 1 && index === 0) || (usingProcedureList.length > 1 && index + 1 === usingProcedureList.length)) && <button className="com_btn blue m" onClick={() => addProcedureGoods(procedure.goods_times)}><i className="svg_icon icon_add yellow">&nbsp;</i>시술추가</button>}
              
              {location.state === null && procedure.goods_times !== 1 && <button className="com_btn_del" onClick={() => deleteGoods(procedure.goods_seq !== undefined ? procedure.goods_seq : procedure.goods_times)}>&nbsp;</button>}
              {location.state !== null && index !== 0 && <button className="com_btn_del" onClick={() => deleteGoods(procedure.goods_seq !== undefined ? procedure.goods_seq : procedure.goods_times)}>&nbsp;</button>}
            </div>
          </li>
        </ul>
        <hr className="line" />
        <ul className="input_section only_tit">
          <li className="col01 top_tit">
            <p className="tit">회차 명</p>
          </li>
          <li className="col02 top_tit">
            <p className="tit">회차 시술금액(원)</p>
          </li>
        </ul>
        {procedureTimeList ? procedureTimeList.map((time, index) => (
          location.state === null ? time.goods_times === procedure.goods_times &&
            (<ul className="input_section" key={"time" + index} >
              <li className="col01">
                <div className="input_wrap">
                  <span className="tit_field">{time.view_order}회차</span>
                  <input type="text" className="input" placeholder="회차명 입력 (20자)" name="times_name" value={time.times_name || ""} onChange={(e) => timesHandler(e, index)} />
                </div>
              </li>
              <li className="col02">
                <div className="input_wrap">
                  <input type="text" className="input price" value={time.times_price || ""} name="times_price" onChange={(e) => timesHandler(e, index)} />
                  {time.view_order === procedureTimeList.filter((el) => el.goods_times === procedure.goods_times).length && <button className="com_btn blue m" onClick={() => addProcedureTime(procedure.goods_times)}><i className="svg_icon icon_add yellow">&nbsp;</i>회차추가</button>}
                  {time.view_order !== 1 && <button className="com_btn_del" onClick={() => deleteProcedureTime(time.goods_times, time.view_order)}>&nbsp;</button>}
                </div>
              </li>
            </ul>)
            : time.goods_seq === procedure.goods_seq && time.goods_times === procedure.goods_times &&
            (<ul className="input_section" key={"time" + index} >
              <li className="col01">
                <div className="input_wrap">
                  <span className="tit_field">{time.view_order}회차</span>
                  <input type="text" className="input" placeholder="회차명 입력 (20자)" name="times_name" value={time.times_name || ""} onChange={(e) => timesHandler(e, index)} />
                </div>
              </li>
              <li className="col02">
                <div className="input_wrap">
                  <input type="text" className="input price" value={time.times_price || ""} name="times_price" onChange={(e) => timesHandler(e, index)} />
                  {time.view_order === procedureTimeList.filter((el) => el.goods_seq ? el.goods_seq === procedure.goods_seq : el.goods_times === procedure.goods_times).length && <button className="com_btn blue m" onClick={() => addProcedureTime(procedure.goods_seq ? procedure.goods_seq : procedure.goods_times)}><i className="svg_icon icon_add yellow">&nbsp;</i>회차추가</button>}
                  {time.view_order !== 1 && <button className="com_btn_del" onClick={() => deleteProcedureTime(time.goods_seq ? time.goods_seq : time.goods_times, time.view_order)}>&nbsp;</button>}
                </div>
              </li>
            </ul>)
        )) : null
        }
      </section >
    )
  }

export default AddUsingGoodsOld
