//* 현금영수증 내역
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import StatisticsSubDateSearch from "atoms/statistics/StatisticsSubDateSearch";
import { getTodayDate, getPreviousWeekDate, getPreviousMonthDate, getPreviousYearDate } from "../../utils/Common.utils";
import { utils, writeFile } from 'xlsx';

const CashReceipt = () => {
  const componentRef = useRef();
  const tableRef = useRef();
  const today = getTodayDate()?.slice(0,17);
  const todayDate = moment(new Date()).format("YYYYMMDD");
  const [periodOption, setPeriodOption] = useState(null);
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodValue, setPeriodValue] = useState(0);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `현금영수증_${todayDate}`,
  });

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    } else if(getValue === 2){
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    } else{
      setStartDate(getPreviousYearDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }

  const setPeriodSearchDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('4');
    setPeriodValue(1);
  }

  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `현금영수증_${todayDate}.xlsx`);
  };


	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">&nbsp;</article>
          <article className="com_lnb2_right">
            <StatisticsSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate}/>
          </article>
        </div>
      </section>
      <section className="com_content">
        <div ref={componentRef}>
          <div className="com_list box">
            <article className="top_area">
              <div className="info_field">
                <p className="date">{periodValue === 0 ?  `${startDate}` : `${startDate} ~ ${endDate}`}</p>
              </div>
            </article>
            <div className="scroll with_btn_bottom">
              <table className="list" ref={tableRef}>
                <colgroup><col width="12.5%"/><col/><col width="12.5%"/><col width="12.5%"/><col width="12.5%"/><col width="12.5%"/><col width="12.5%"/><col width="12.5%"/></colgroup>
                <thead className="sticky top">
                  <tr>
                    <th colSpan="8">현금영수증 내역</th>
                  </tr>
                  <tr>
                    <th>발급일</th>
                    <th>이름</th>
                    <th>휴대폰</th>
                    <th>진료일</th>
                    <th>승인번호</th>
                    <th>금액</th>
                    <th>부가세</th>
                    <th>발급상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2022-03-03</td>
                    <td>홍길동 (여/39)</td>
                    <td>010-1111-1111</td>
                    <td>2022-03-03</td>
                    <td>123456</td>
                    <td>50,000</td>
                    <td>5,000</td>
                    <td>완료</td>
                  </tr>
                  <tr>
                    <td>2022-03-03</td>
                    <td>홍길동 (여/39)</td>
                    <td>010-1111-1111</td>
                    <td>2022-03-03</td>
                    <td>123456</td>
                    <td>50,000</td>
                    <td>5,000</td>
                    <td><button className="com_btn txt">미발급</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="com_btn_bottom_area">
          <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
          <button className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
        </div>
      </section>
		</>
	)
}

export default CashReceipt