import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartLeft from "./_ChartLeft.js"; //좌측 영역
import ChartCenter from "./_ChartCenter.js"; //중앙 영역
import ChartRight from "./_ChartRight.js"; //우측 영역
import ChartSetting from "./_ChartSetting.js"; // 차트 화면 설정
import request from "../../utils/Request.utils";
import { getTodayDate, nvl, nvlNumber, vatCalculator, convertJuminToAge } from "../../utils/Common.utils";
import { decrypt } from "../../utils/aes256.manager";
import LoadingBar from '../../utils/LoadingBar';
import moment from "moment";

const Chart = ({ chartSetting, setChartSetting, lnbSearchOptions, setLnbSearchOptions, loadingBarActive, getReceptionList }) => {
  const stateCustomer = useSelector(state => state.Customer);
  const dispatch = useDispatch();

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [displayScreen, setDisplayScreen] = useState({
    customer_display_yn: 'Y',
    todaychart_display_yn: 'Y',
    cust_request_display_yn: 'Y',
    photo_disaplay_yn: 'Y',
    goods_search_disaplay_yn: 'Y',
    visit_display_yn: 'Y',
    today_order_display_yn: 'Y',
    treatment_display_yn: 'Y'

  }); // 차트 화면설정 팝업
  const [addedItems, setAddedItems] = useState({ treatments: [], coupon: {}, point: 0, total_price: 0 }); // 시술 검색 컴포넌트에서 입력 및 선택된 정보 > 당일오더 컴포넌트 추가
  const [paymentItems, setPaymentItems] = useState([]) // 구매한 시술 정보
  const [remains, setRemains] = useState([]); // 잔여시술
  const [userData, setUserData] = useState({});
  const [pointStatus, setPointStatus] = useState(false);
  const [treatmentList, setTreatmentList] = useState([]);
  // ChartLeft, ChartRight에서 각각 호출하던 것 젤 상단(Chart)으로 이동
  const [todayOrders, setTodayOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderInfo, setOrderInfo] = useState() // 오더 시퀀스 정보 
  const [diseaseData, setDiseaseData] = useState([]); // 당일 환자 질병코드 정보
  const [pointData, setPointData] = useState({});
  const [couponData, setCouponData] = useState({});

  const [popup, setPopup] = useState({
    customerCard: false
  });
  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => { },
    id: '',
  });

  // 최초 진입 시 우상단 고객정보 없을 때 알림
  useEffect(() => {
    if (nvl(stateCustomer) === "") {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })

    }
  }, [stateCustomer])

  // 단일 고객 조회
  const getSelectedCustomerInfo = (mode) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const user = response.data.dataList[0];

        setUserData({
          user_seq: user.user_seq,
          chart_code: user.chart_code,
          user_name: user.user_name,
          user_gender: user.user_gender,
          user_level: user.user_level,
          user_level_color: user.user_level_name?.split("|")[1],
          jumin_1: decrypt(user.user_jumin).substring(0, 6),
          jumin_2: decrypt(user.user_jumin).substring(6, 13),
          mobile_1: user.user_mobile.split("-")[0],
          mobile_2: user.user_mobile.split("-")[1],
          mobile_3: user.user_mobile.split("-")[2],
          user_photo: nvlNumber(user.up_file_seq),
          user_photo_path: user.file_path,
          user_reser_seq: user.user_reser_seq,
          user_coupon_cnt: 0,
          order_count: user.order_count,
          main_tc_seq: user.main_tc_seq,
          sub_tc_seq: user.sub_tc_seq,
          user_address: user.user_address,
          user_address_detail: user.user_address_detail,
          interest_area: user.interest_area,
          admissive_channel: user.admissive_channel,
          bank_name: user.bank_name,
          bank_account_number: user.bank_account_number,
          bank_account_holder: user.bank_account_holder
        });

        setPointData(response.data.pointData[0]);
        setCouponData(response.data.couponHistoryData);

        if (mode === 'popup') setPopup((prev) => { return { ...prev, customerCard: true } });
      } else {
        console.log("고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/user/${stateCustomer.user_seq}`, null, successHandler);
  }

  const getTodayOrderInfo = (userSeq) => { // 결제 완료 전까지만 불러옮
    const successHandler = (response) => {
      const tempArr = [];

      if (response.messageCode === 200) {
        const result = response.data;
        setOrderInfo({
          order_seq: result.orderList[0]?.order_seq,
          order_date: moment(result.orderList[0]?.order_date).format("YYYY-MM-DD"),
          order_status: result.orderList[0]?.order_status,
          counselor_manager_name: result.orderList[0]?.counselor_manager_name,
        })
        if (nvl(result) !== "" && result?.orderList.length > 0) {

          let packInfo = {}
          let goodsInfo = {}
          let totalPrice = 0
          let goodsPrice = 0

          for (const orderInfo of result?.orderList) {
            if (orderInfo?.chart_info?.length > 0) {
              for (const chartInfo of orderInfo?.chart_info) {
                if (chartInfo.goods_info !== "") {
                  packInfo = chartInfo.pack_info
                  goodsInfo = chartInfo.goods_info
                  goodsPrice = vatCalculator(packInfo.goods_type, packInfo.vat_type, goodsInfo.pack_sale_price, goodsInfo.goods_sale_price, goodsInfo.vat_type, goodsInfo.goods_price)
                  // 당일 오더 조회로 조회되는 시술은 이미 오더확인으로 구매(미수금 처리) 되었기 때문에 가격은 0으로 고정

                  tempArr.push({
                    order_seq: result.orderList[0]?.order_seq,
                    sub_tc_seq: chartInfo.sub_tc_seq,
                    pack_seq: packInfo.goods_seq,
                    goods_seq: goodsInfo.goods_seq,
                    goods_type: packInfo.goods_type, /*  */
                    goods_name: goodsInfo.goods_name,
                    goods_price: chartInfo.chart_type === 'B' ? goodsPrice : 0,
                    chart_type: chartInfo.chart_type, // 구매(B), 부분양도(T), 무료시술(F), 결제(P), 예약(R)
                    current_times: chartInfo.current_times, // +, - 버튼에 의해 조절 될 수 있는 회차 >> used_times <= current_times <= total_times
                    used_times: 0, // 시술검색에서 넘어온 경우 0회차가 default, 잔여시술에서 넘어온 경우 사용한 회차가 default
                    total_times: chartInfo.total_times,
                    vat_type: goodsInfo.vat_type,
                    insurance_type: goodsInfo.insurance_type,
                    reg_date: new Intl.DateTimeFormat('kr').format(new Date()).replaceAll('.', ','), // 시술상품 구매 일
                    comment: goodsInfo.comment,
                    from: 'S',
                  });
                }
              }
            }
          }
          setTodayOrders(tempArr);
          setTotalPrice(totalPrice);
        }
        if (nvl(result) !== "" && result?.payList.length > 0) {
          setPaymentItems(result.payList)
        }
        if (nvl(result) !== "" && result?.restList.length > 0) {
          const remains = result.restList
          remains.forEach(item => {
            item.used_times = item.total_times - item.remain_times;
            item.current_times = 0
          });
          setRemains(result.restList)
        }
      } else {
        console.log("저장된 정보가 없나?")
      }
    }

    // request.get(`/api/charts/order/dashboard2?chart_type=chart&user_seq=${userSeq}&order_date=${getTodayDate('hyphen')}`, null, successHandler);
    request.get(`/api/charts/order/treatement?delete_yn=N&user_seq=${userSeq}&order_date=${getTodayDate('hyphen')}`, null, successHandler);

  }

  const changeUserHandler = (userSeq) => { // 선택된 값
    if (nvlNumber(userSeq) !== "") {
      if (userSeq > 0) {
        request.get(`/api/user/${userSeq}`, null, (response) => {
          if (response.messageCode === 200) {
            let userInfo = response.data.dataList[0];

            dispatch({ type: "setCustomer", payload: { fieldName: "user_seq", data: userSeq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_name", data: userInfo.user_name } });
            dispatch({ type: "setCustomer", payload: { fieldName: "chart_code", data: userInfo.chart_code } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_gender", data: userInfo.user_gender } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_dob", data: userInfo.user_dob } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_age", data: nvl(userInfo.user_jumin) !== "" ? convertJuminToAge((decrypt(userInfo.user_jumin).substring(0, 6) + "-" + decrypt(userInfo.user_jumin).substring(6, 13))) : " - " } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level", data: userInfo.user_level } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level_color", data: userInfo.user_level_color } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_status", data: userInfo.user_status } });
            dispatch({ type: "setCustomer", payload: { fieldName: "interest_area", data: userInfo.interest_area } });
            dispatch({ type: "setCustomer", payload: { fieldName: "admissive_channel", data: userInfo.admissive_channel } });
            dispatch({ type: "setCustomer", payload: { fieldName: "main_tc_seq", data: userInfo.main_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "sub_tc_seq", data: userInfo.sub_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo", data: nvlNumber(userInfo.up_file_seq) } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo_path", data: userInfo.file_path } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_reser_seq", data: userInfo.user_reser_seq } });
          } else console.log("고객 정보를 가져오지 못했습니다.")
        });
      }
    }
  }

  useEffect(() => {
    const successHandler = (response) => {
      if (response.messageCode === 200) setDisplayScreen(response.data[0]);
      else console.debug("데이터가 없습니다.");
    }

    request.get(`/api/charts/setting/${managerInfo.seq}`, null, successHandler);

    return () => { // managerInfo.seq가 바뀌기 전 초기화

    }
  }, [managerInfo.seq]);

  useEffect(() => {
    if (nvlNumber(stateCustomer.user_seq) > 0) {
      getSelectedCustomerInfo()
      getTodayOrderInfo(stateCustomer.user_seq)
    }

    return () => { // stateCustomer.user_seq가 바뀌기 전 초기화
      setTodayOrders([])
      setPaymentItems([])
      setRemains([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomer.user_seq]);

  console.log(todayOrders, "todayOrders")
  console.log(paymentItems, "paymentItems")
  console.log(remains, "remains")

  return (
    <section className="com_content">
      {chartSetting && <ChartSetting chartSetting={chartSetting} setChartSetting={setChartSetting} displayScreen={displayScreen} setDisplayScreen={setDisplayScreen} />}
      <ChartLeft managerInfo={managerInfo} displayScreen={displayScreen} userData={userData} setUserData={setUserData} lnbSearchOptions={lnbSearchOptions} setLnbSearchOptions={setLnbSearchOptions} setPointStatus={setPointStatus} todayOrders={todayOrders} setTodayOrders={setTodayOrders} getTodayOrderInfo={getTodayOrderInfo} orderInfo={orderInfo} getSelectedCustomerInfo={getSelectedCustomerInfo} popup={popup} setPopup={setPopup} getReceptionList={getReceptionList} diseaseData={diseaseData} setDiseaseData={setDiseaseData} changeUserHandler={changeUserHandler} pointData={pointData} couponData={couponData}/>
      <ChartCenter displayScreen={displayScreen} setAddedItems={setAddedItems} userData={userData} pointStatus={pointStatus} treatmentList={treatmentList} setTreatmentList={setTreatmentList} orderInfo={orderInfo} />
      <ChartRight managerInfo={managerInfo} displayScreen={displayScreen} addedItems={addedItems} userData={userData} setUserData={setUserData} paymentItems={paymentItems} setPaymentItems={setPaymentItems} todayOrders={todayOrders} setTodayOrders={setTodayOrders} remains={remains} setRemains={setRemains} totalPrice={totalPrice} setTotalPrice={setTotalPrice} getTodayOrderInfo={getTodayOrderInfo} orderInfo={orderInfo} diseaseData={diseaseData} />
      {loadingBarActive && <LoadingBar type={"spin"} color={"#000000"} />}
    </section>
  )
}

export default Chart