import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import CustomList from '../components/common/_CustomList';//고객카드 검색결과
import CustomAlert from '../atoms/common/CustomAlert';
import request from '../utils/Request.utils';
import SessionStorage from '../utils/SessionStorage.utils';
import { nvl, nvlNumber, convertJuminToAge, convertGender, getTodayDate } from "../utils/Common.utils";
import { decrypt } from "../utils/aes256.manager";
import HotkeySetting from "../components/common/_HotkeySetting";//hot key목록
import SnsSend from "components/common/_SnsSend";
import SnsSendList from "components/common/_SnsSendList";

const Lnb = ({ setChartSetting, getReceptionList }) => {
  moment.locale('ko-KR');

  let { pathname } = useLocation();
  pathname = pathname.toLowerCase()

  const query = new URLSearchParams(window.location.search);
  const notiType = query.get('noti_type')

  const [path, setPath] = useState('');
  const [timer, setTimer] = useState(moment(new Date()).format("YYYY, MM, DD / hh:mm a")); // 2022, 03, 03 / 월요일 10:35am
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에 담겨진 직원정보 가져오기

  const dispatch = useDispatch();
  const stateCustomer = useSelector(state => state.Customer);

  const [lnbSearchOptions, setLnbSearchOptions] = useState({
    checkedBool: false, // 고객리스트 컴포넌트 노출 여부
    hotkeyBool: false, // 핫키 컴포넌트 노출 여부
    totalCount: 0, // 총 갯수
    searchList: null, // 검색 리스트
    searchKeyword: '', // 이름 or 차트코드 or 휴대전화 번호 검색
    userData: {}, // 고객 정보
    send: false,
    sent: false
  })
  // console.log(lnbSearchOptions)

  const [lnbAlertLayerPopup, setLnbAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false
  })

  useEffect(() => {
    const pathArr = pathname.split('/');

    setPath((prev) => prev = pathArr.length > 1 ? pathArr[1] : '');
  }, [pathname, path])

  setInterval(() => { setTimer(moment(new Date()).format("YYYY, MM, DD / hh:mm a")) }, 1000 * 60) // modified by psk 20230603 - 분으로 바꿈

  const logout = () => {
    SessionStorage.clearItem("managerSession")
    SessionStorage.clearItem("userSession")

    dispatch({ type: "clearCustomer", payload: {} });

    window.location.href = "/main/login"
  }

  const changeUserHandler = (userSeq) => { // 선택된 값
    if (nvlNumber(userSeq) !== "") {
      if (userSeq > 0) {
        request.get(`/api/user/${userSeq}`, null, (response) => {
          if (response.messageCode === 200) {
            let userInfo = response.data.dataList[0];

            dispatch({ type: "setCustomer", payload: { fieldName: "user_seq", data: userSeq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_name", data: userInfo.user_name } });
            dispatch({ type: "setCustomer", payload: { fieldName: "chart_code", data: userInfo.chart_code } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_gender", data: userInfo.user_gender } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_dob", data: userInfo.user_dob } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_age", data: nvl(userInfo.user_jumin) !== "" ? convertJuminToAge((decrypt(userInfo.user_jumin).substring(0, 6) + "-" + decrypt(userInfo.user_jumin).substring(6, 13))) : " - " } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level", data: userInfo.user_level } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level_color", data: userInfo.user_level_color } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_status", data: userInfo.user_status } });
            dispatch({ type: "setCustomer", payload: { fieldName: "interest_area", data: userInfo.interest_area } });
            dispatch({ type: "setCustomer", payload: { fieldName: "admissive_channel", data: userInfo.admissive_channel } });
            dispatch({ type: "setCustomer", payload: { fieldName: "main_tc_seq", data: userInfo.main_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "sub_tc_seq", data: userInfo.sub_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo", data: nvlNumber(userInfo.up_file_seq) } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo_path", data: userInfo.file_path } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_reser_seq", data: userInfo.user_reser_seq } });
            // dispatch({ type: "setPaymentInfo", payload: { fieldName: "point_price", data: 0 } });
            // dispatch({ type: "setPaymentInfo", payload: { fieldName: "coupon_price", data: 0 } });
          } else console.log("고객 정보를 가져오지 못했습니다.")
        });
      }
    }
  }

  const lnbCustomerSearchActivate = () => {
    if (nvl(lnbSearchOptions.searchKeyword) === "" || nvl(lnbSearchOptions.searchKeyword) === "/") {
      setLnbAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "검색어를 입력해주십시오." } })
      setLnbSearchOptions((prev) => { return { ...prev, checkedBool: false, totalCount: 0, searchList: null, searchKeyword: '', userSeq: 0 } })

      return;
    }

    if (nvl(lnbSearchOptions.searchKeyword).indexOf("/") === 0) { // 핫키 검색했을 때
      const successHandler = (response) => {
        if (response.messageCode === 200) {
          setLnbSearchOptions((prev) => { return { ...prev, checkedBool: false, hotkeyBool: true, totalCount: response.data.totalCnt, searchList: response.data.dataList } })
        } else {
          setLnbAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "핫키 목록을 가져오던 중 에러가 발생했습니다.<br />다시 시도해 주십시오." } })
        }
      }

      request.get(`/api/etc/hotkeys?hotkeyKeyword=${lnbSearchOptions.searchKeyword?.replace('/', '')}&orderBy=hotkey_keyword&delete_yn=N`, null, successHandler);
    } else { // 고객 검색했을 때
      const successHandler = (response) => {
        // console.log(response)

        if (response.messageCode === 200) {
          setLnbSearchOptions((prev) => { return { ...prev, checkedBool: true, totalCount: response.data.totalCnt, searchList: response.data.dataList } })
        } else if (response.messageCode === 400) {
        } else {
          setLnbAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "고객 목록을 가져오던 중 에러가 발생했습니다.<br />다시 시도해 주십시오." } })
        }
      }

      request.get(`/api/user?searchKeyword=${lnbSearchOptions.searchKeyword}`, null, successHandler);
    }
  }

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') lnbCustomerSearchActivate();
  }

  const updateSearchKeyword = (e) => {
    setLnbSearchOptions((prev) => { return { ...prev, searchKeyword: e.target.value } })
  }

  const lnbKeywordDeleteDo = (e) => {
    setLnbSearchOptions((prev) => { return { ...prev, checkedBool: false, hotkeyBool: false, searchKeyword: '' } })
  }

  const closeLnbCustomAlert = () => {
    setLnbAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
    setLnbSearchOptions((prev) => { return { ...prev, checkedBool: false, hotkeyBool: false, totalCount: 0, searchList: null, searchKeyword: '', userData: {} } })
  }

  const returnLnbAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      //
    }
  }

  // added by psk 20230603 예약 정보 불러오기 추가 (예약자가 있으면 바로 예약자를 선택된 상태로 만들기 위함)
  const getReservationList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        if (nvl(response.data) !== "" && response.data?.dataList.length > 0) changeUserHandler(response.data?.dataList[0].user_seq)
      } else {
        console.log("예약 고객 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/reservation?listType=F&reser_date=${getTodayDate('hyphen')}`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    if (nvlNumber(stateCustomer.user_seq) === 0) getReservationList()

    console.log(pathname, path)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section className="section com_lnb">
        <div className="inner">
          <article className="com_lnb_left">
            {
              pathname.indexOf("/main/my") >= 0 &&
              <h1 className="lnb_title">{managerInfo?.name} / {managerInfo?.role_kr}</h1>
            }
            {
              path === 'chart' &&
              <div className="lnb_btn_wrap">
                <button className="com_btn m white btn_chart_setting" onClick={() => setChartSetting(true)}>
                  <i className="svg_icon icon_set yellow">&nbsp;</i>차트 화면설정
                </button>
              </div>
            }
            { // 공지사항
              pathname.indexOf("/notice/write") >= 0 ?
                notiType === 'I' ?
                  <div className="lnb_date_wrap">
                    내부 공지 작성하기
                  </div>
                  :
                  <div className="lnb_date_wrap">
                    본사 공지 작성하기
                  </div>
                :
                pathname.indexOf("/notice") >= 0 ?
                  <div className="lnb_date_wrap">
                    공지사항
                  </div>
                  : null
            }
            {/* //* [날짜검색] 사용페이지 : 예약 */}
            {
              pathname.indexOf("/reservation") >= 0 &&
              <div className="lnb_date_wrap">
                <section className="com_field type_normal">
                  <fieldset>
                    <input type="text" className="input datepicker" placeholder="2022. 01. 01" name="" />
                  </fieldset>
                </section>
              </div>
            }
            {/* //* [검색영역] 사용페이지 : 현황판, 차트, 예약, 결제, CRM(고객관리), 직원관리 */}
            {
              pathname.indexOf("/statistics") < 0 && pathname.indexOf("/boffice") < 0 && pathname.indexOf("/sales") < 0 && pathname.indexOf("/crm/day") < 0 && pathname.indexOf("/crm/file") < 0 && pathname.indexOf("/crm/satisfaction") < 0 && pathname.indexOf("/crm/complain") < 0 && pathname.indexOf("/notice") < 0 && pathname.indexOf("/my_owner") < 0 &&
              <div className="lnb_search_wrap">
                <section className="com_field type_normal">
                  <fieldset>
                    <div className="input_del_wrap">
                      <input type="text" className="input" placeholder="고객성명, 고객번호, 휴대폰번호" name="customerSearch" value={lnbSearchOptions?.searchKeyword}
                        onKeyDown={handleOnKeyPress} onChange={updateSearchKeyword} />
                      <button type="button" className="btn_input_del" onClick={lnbKeywordDeleteDo}></button>
                    </div>
                    <button type="button" className="com_btn m yellow" onClick={lnbCustomerSearchActivate}></button>
                  </fieldset>
                </section>
              </div>
            }
            { // [관리메뉴] 사용페이지 : 관리
              // managerInfo != null && nvl(managerInfo?.role) === "009002000" &&
              pathname.indexOf("/boffice") >= 0 && managerInfo.id === "alllift" &&
              <div className="lnb_menu">
                <Link to="/boffice" className={pathname === "/boffice" || pathname === "/boffice/procedure_add" ? "menu_item active" : "menu_item"} title="시술 관리">시술 관리</Link>
                <Link to="/boffice/sms" className={pathname === "/boffice/sms" ? "menu_item active" : "menu_item"} title="문자 관리">문자 관리</Link>
                <Link to="/boffice/drug" className={pathname === "/boffice/drug" ? "menu_item active" : "menu_item"} title="약품 관리">약품 관리</Link>
                <Link to="/boffice/consent" className={pathname === "/boffice/consent" || pathname === "/boffice/consent_add" ? "menu_item active" : "menu_item"} title="동의서 관리">동의서 관리</Link>
                <Link to="/boffice/coupon" className={pathname === "/boffice/coupon" ? "menu_item active" : "menu_item"} title="쿠폰 관리">쿠폰 관리</Link>
              </div>
            }
            { // [통계메뉴] 사용페이지 : 통계
              // managerInfo != null && nvl(managerInfo?.role) === "009001000" &&
              pathname.indexOf("/statistics") >= 0 && managerInfo.id === "alllift" &&
              <div className="lnb_menu">
                <Link to="/Statistics/summary" className={pathname === "/statistics/summary" || pathname === "/statistics/calc_customer" || pathname === "/statistics/calc_best_sales" || pathname === "/statistics/calc_register" ||
                  pathname === "/statistics/calc_age" || pathname === "/statistics/calc_field" || pathname === "/statistics/calc_staff" ? "menu_item active" : "menu_item"} title="결산">결산</Link>
                <Link to="/Statistics/unpaid" className={pathname === "/statistics/unpaid" ? "menu_item active" : "menu_item"} title="미수">미수</Link>
                <Link to="/Statistics/refund" className={pathname === "/statistics/refund" ? "menu_item active" : "menu_item"} title="환불">환불</Link>
                <Link to="/Statistics/order" className={pathname === "/statistics/order" ? "menu_item active" : "menu_item"} title="시술판매">시술판매</Link>
                <Link to="/Statistics/cash_receipt" className={pathname === "/statistics/cash_receipt" ? "menu_item active" : "menu_item"} title="현금영수증 내역">현금영수증 내역</Link>
                <Link to="/Statistics/visiting_route" className={pathname === "/statistics/visiting_route" ? "menu_item active" : "menu_item"} title="내원경로별 매출비중">내원경로별 매출비중</Link>
                <Link to="/Statistics/staff" className={pathname === "/statistics/staff" ? "menu_item active" : "menu_item"} title="직원별 통계">직원별 통계</Link>
                <Link to="/Statistics/insurance" className={pathname === "/statistics/insurance" ? "menu_item active" : "menu_item"} title="보험료 청구">보험료 청구</Link>
              </div>
            }
          </article>

          {/* //^ 우측 영역 */}
          <article className="com_lnb_right">
            {/* //* [고객카드on/off] 사용페이지 : 현황판, 차트, 결제, CRM, 직원관리, 통계, 매출관리 */}

            {
              (pathname.indexOf("/chart") >= 0 || pathname.indexOf("/dashboard") >= 0 || pathname.indexOf("/crm") >= 0 || pathname.indexOf("/member") >= 0 || pathname.indexOf("/statistics") >= 0 || pathname.indexOf("/sales") >= 0 ||
                pathname.indexOf("/reservation") >= 0 || pathname.indexOf("/payment") >= 0) &&
              <div className="lnb_onoff">
                <p className="name">Callee</p>
                <div className="com_toggle_wrap">
                  <input type="checkbox" id="btnToggle1" />
                </div>
              </div>
            }
            {/* //* [날짜] 사용페이지 : 메인&마이, 현황판, 차트, 결제, CRM, 관리, 직원관리, 통계, 매출관리 */}
            <div className="lnb_date">{timer}</div>
            {
              //* [기본 메뉴 버튼] 메인&마이
              pathname.indexOf("/notice") >= 0 || pathname.indexOf("/main/my") >= 0 ?
                managerInfo != null && nvl(managerInfo.id) !== "" ?
                  <div className="lnb_my_btn_wrap">
                    <a href="#/" className="com_btn lnb_btn blue">웹사이트</a>
                    {
                      managerInfo.role === "009001000" ?
                        <a href="/main/my_owner" className="com_btn lnb_btn downnavy">마이페이지</a>
                        :
                        <a href="/main/my" className="com_btn lnb_btn downnavy">마이페이지</a>
                    }
                    <a href="#/" onClick={logout} className="com_btn lnb_btn white">로그아웃</a>
                  </div>
                  :
                  <div className="lnb_my_btn_wrap">
                    <a href="/main/login" className="com_btn lnb_btn white">로그인</a>
                  </div>
                : null
            }
            {/* //* [고객요약정보] 사용페이지 : 현황판, 차트, 결제 */}
            {
              nvlNumber(stateCustomer?.user_seq) > 0 && (pathname.indexOf("/dashboard") >= 0 || pathname.indexOf("/chart") >= 0 || pathname.indexOf("/reservation") >= 0 || pathname.indexOf("/payment") >= 0) ?
                <div className="lnb_customer">
                  <p>{stateCustomer?.user_name} 님 ({convertGender(stateCustomer?.user_gender)}/{stateCustomer?.user_age})</p>
                  <i className="" style={{ backgroundColor: "#359e29" }}>&nbsp;</i>
                </div>
                : null
            }
            {
              path === 'chart' &&
              <button className="com_btn m white btn_chart_setting">
                <i className="svg_icon icon_add yellow">&nbsp;</i>시술 등록
              </button>
            }
          </article>
          {lnbSearchOptions?.checkedBool &&
            <CustomList checkedBool={lnbSearchOptions.checkedBool} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler} totalCount={lnbSearchOptions.totalCount} searchList={lnbSearchOptions.searchList}
              handleOnKeyPress={handleOnKeyPress} lnbCustomerSearchActivate={lnbCustomerSearchActivate} lnbSearchOptions={lnbSearchOptions} updateSearchKeyword={updateSearchKeyword} getReceptionList={getReceptionList}/>}
          {lnbAlertLayerPopup.visible && <CustomAlert onClose={closeLnbCustomAlert} alertType={lnbAlertLayerPopup.alertType} alertMessage={lnbAlertLayerPopup.alertMessage} returnValue={returnLnbAlertValue} />}
          {lnbSearchOptions.hotkeyBool && <HotkeySetting hotkeyBool={lnbSearchOptions.hotkeyBool} setLnbSearchOptions={setLnbSearchOptions} totalCount={lnbSearchOptions.totalCount} searchList={lnbSearchOptions.searchList}
            lnbCustomerSearchActivate={lnbCustomerSearchActivate} />}
          {lnbSearchOptions?.send && <SnsSend lnbSearchOptions={lnbSearchOptions} setLnbSearchOptions={setLnbSearchOptions} />}
          {lnbSearchOptions?.sent && lnbSearchOptions.userData != null && <SnsSendList lnbSearchOptions={lnbSearchOptions} setLnbSearchOptions={setLnbSearchOptions} />}
        </div>
      </section>
    </>
  )
}

export default Lnb