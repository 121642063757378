// *관리>문자관리>통계
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "utils/Request.utils";
import { decrypt } from "utils/aes256.manager.js";
import moment from "moment";
import { nvl, convertJuminToAge, convertGender } from "utils/Common.utils.js";
import SmsPreview from "../common/_SmsPreview"
import Paging from '../../layout/Paging.js';  //페이징
const SmsStats = () => {

  const [preview, setPreview] = useState(false);

  const [sentMessageList, setSentMessageList] = useState([]);

  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [numPerPage, setNumPerPage] = useState(10)
  const [pagePerBlock, setPagePerBlock] = useState(10)
  const [selectMessage, setSelectMessage] = useState("");

  const nextPageMove = (value) => {

    setCurrentPage(value)
  }

  // 보낸 문자 조회하기
  const getSentMessages = () => {
    let params = {
      delete_yn: "N",
      limit: numPerPage,
      offset: ((currentPage - 1) * numPerPage),
      order_by: "msg_seq DESC",
    }
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setSentMessageList(response.data.resultData)
        setTotalCount(response.data.totalCnt)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get(`/api/message/sent?delete_yn=${params.delete_yn}&limit=${params.limit}&offset=${params.offset}&order_by=${params.order_by}`, params, successHandler, failHandler, null, null);
  }
  useEffect(() => {
    getSentMessages()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const previewMessage = (msgSeq) => {
    setPreview(!preview);
    setSelectMessage(sentMessageList.filter(el => el.msg_seq === msgSeq)[0])
  }

  return (
    <>
      <section className="section com_lnb2" >
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <Link to={"/boffice/sms"} className="com_btn m lightgray">일반관리</Link>
              <Link to={"/boffice/sms_stats"} className="com_btn m blue">문자통계</Link>
            </div>
          </article>
        </div>
      </section>
      <section className="com_content">
        <div className="com_list box ">
          <div className="scroll">
            <table className="list">
              <colgroup><col width="7.4%" /><col width="7.4%" /><col width="7.4%" /><col width="11.4%" /><col width="10.2%" /><col width="10.2%" /><col width="11.3%" /><col /><col width="4%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th colSpan="2">수신자</th>
                  <th rowSpan="2">발신자</th>
                  <th rowSpan="2">발송일시</th>
                  <th rowSpan="2">유형</th>
                  <th rowSpan="2">카테고리</th>
                  <th rowSpan="2">문자제목</th>
                  <th rowSpan="2">문자내용</th>
                  <th rowSpan="2">자세히<br />보기</th>
                </tr>
                <tr>
                  <th>이름</th>
                  <th className="lineR">번호</th>
                </tr>
              </thead>
              <tbody>
                {sentMessageList !== null && sentMessageList.length > 0 ? sentMessageList.map((message) => (
                  <tr key={message.msg_seq}>
                    <td>{message.user_name} ({convertGender(message.user_gender)}/{nvl(message?.user_jumin) === "" ? "" : convertJuminToAge((decrypt(message?.user_jumin)?.substring(0, 6) + "-" + decrypt(message?.user_jumin)?.substring(6, 13)))})</td>
                    <td>{message.user_mobile}</td>
                    <td>{"올리프트 웹차트"}</td>
                    <td>{moment(message.reg_date).format("YYYY-MM-DD")} / {moment(message.reg_date).format("HH:mm")}</td>
                    <td>{message.send_method === "M" ? "문자" : "카카오톡"}</td>
                    <td>{message.code_name}</td>
                    <td>{message.msg_subject}</td>
                    <td className="tit">{message.msg_content}</td>
                    <td>
                      <button className="com_btn txt" onClick={() => previewMessage(message.msg_seq)}>보기</button>
                    </td>
                  </tr>
                ))
                  :
                  <tr>
                    <td colSpan="9">데이터가 없습니다.</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        <Paging totalCount={totalCount} currentPage={currentPage} numPerPage={numPerPage} pagePerBlock={pagePerBlock} nextPageMove={nextPageMove} />
      </section>
      {preview && <SmsPreview onClose={() => setPreview(!preview)} selectMessage={selectMessage} />}
    </>
  )
}

export default SmsStats