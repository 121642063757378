import React, { useRef, useEffect, useState } from "react";
import { CHART_TYPE } from "utils/global";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import { nvl } from "../../utils/Common.utils";

const ExplanAdd = ({ onClose, treatment, setTodayOrders, userData, managerInfo, getReceptionList, getTodayOrderInfo }) => {
  const nodeRef = useRef(null);
  const [detail, setDetail] = useState('');

  const saveDetail = () => {
    let params = {
      user_seq: userData.user_seq,
      pack_seq: treatment.pack_seq,
      goods_seq: treatment.goods_seq,
      content: detail,
      reg_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // alert("부연설명 등록")
      }
    }

    const failHandler = (response) => {
      console.log(response)
    }

    request.post(`/api/goods/explainAdd`, params, successHandler, failHandler, null, null);
    setTodayOrders((prev) => {
      const newArr = [...prev];
      newArr[treatment?.id].comment = detail;
      onClose();
      return newArr;
    })
    getReceptionList && getReceptionList() // 대시보드에서 부연설명 입력 한 경우
    getTodayOrderInfo && getTodayOrderInfo() // 오더인쇄에서 부연설명 입력 한 경우
  };

  useEffect(() => {
    setDetail(treatment?.comment);
  }, [treatment?.comment]);

  return (
    <section className="com_popup type_normal pop_explan_add active">
      <Draggable nodeRef={nodeRef}>
        <div ref={nodeRef} className="pop_container m">
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose('note1'); }}></button>
          <div className="pop_header">
            <h1 className="pop_title">부연설명 입력</h1>
          </div>
          <div className="pop_body">
            <article className="top_area">
              <p className="item">{treatment?.goods_name}</p>
              <p className="item">{`${treatment?.goods_price ? new Intl.NumberFormat().format(treatment?.goods_price) : 0}(${treatment?.insurance_type === "N" ? "비급여" : "급여"})`}</p>
              <p className="item">{treatment?.reg_date}</p>
              <p className="item">{CHART_TYPE[treatment?.chart_type]}</p>
              <p className="item">{`(${userData?.user_name} / ${nvl(userData?.mobile_2) === "" ? " " : `010-${userData?.mobile_2}-${userData?.mobile_3}`})`}</p>
            </article>
            <textarea className="textarea" value={detail} onChange={(e) => { setDetail(e.target.value) }} style={{ resize: "none" }}></textarea>
          </div>
          <div className="pop_footer com_btn_wrap">
            <button className="com_btn l blue" onClick={saveDetail}>입력</button>
          </div>
        </div>
      </Draggable>
    </section>
  )
}

export default ExplanAdd