//* 컴플레인
import React, { useEffect, useState } from "react";import request from "utils/Request.utils";
import Graph from "atoms/common/graph/graph";
import CustomerSubButton from "../../atoms/crm/SubButton";
import CustomerSubDateSearch from "../../atoms/crm/SubDateSearch";
import ComplainWriteOk from "../../components/crm/_ComplainWriteOk";//등록 완료 alert
import { useLocation } from "react-router-dom";
import { getTodayDate, getPreviousWeekDate, getPreviousMonthDate } from "../../utils/Common.utils";

const ComplainChart = () => {
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodOption, setPeriodOption] = useState(2);
  const [periodValue, setPeriodValue] = useState(0);
  const [complainTotal, setComplainTotal] = useState([]);
  const [complainData, setComplainData] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const location = useLocation();
  const {category} =  location.state;
  const today = getTodayDate()?.slice(0,17);
  const prevWeekDate = getPreviousWeekDate();
  const complainTotalCount = complainTotal.reduce((acc, cur) => acc + cur.total_count, 0);

  const returnData = (getValue) => {
    // console.log(getValue);
  }

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){ //오늘
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){ //주간
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    
      getWeekDateArr();
    }else{ //월간
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
      let now = new Date();
      let prevOneYear = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      let arr = [];
      for (let i = prevOneYear; i <= now; i.setMonth(i.getMonth() + 1)) {
        let dateString = i.getFullYear() + '-' + ('0' + (i.getMonth() + 1)).slice(-2);
        arr.push(dateString);
      }
    
      setDateArray(arr);
    }
    setPeriodOption(getValue);
  }

  const setPeriodSearchDate = (startDate, endDate) => {

    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('3');
    setPeriodValue(1);
  }


   // 컴플레인 개수 가져오기
   const getComplainCount = () => {
    let params = {
      periodOption: periodOption,
      dates: ''
      // delete_yn: "N"
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setComplainTotal(response?.data);
      } else {
        console.log("컴플레인 개수를 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/complain/count/G/${params.dates}?periodOption=${params.periodOption}&startDate=${startDate}&endDate=${endDate}`, null, successHandler, failHandler, null, null);
  }

  const getComplainData = () => {
    let params = {
      periodOption: periodOption,
      dates: ''
      // delete_yn: "N"
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response?.data.resultData
        // console.log(result)

        setComplainData(result.map((el) => ({
          common_code: el.common_code,
          code_name: el.code_name,
          daily_count_array: JSON.parse(el.daily_count_array)
        })));
        getComplainCount();
      } else {
        console.log("컴플레인 개수를 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/complain/weekend?periodOption=${params.periodOption}&startDate=${startDate}&endDate=${endDate}`, null, successHandler, failHandler, null, null);
  }

  const getWeekDateArr = () => {
    let now = new Date();
    let prevOneWeek = new Date();
    let arr = [];
  
    prevOneWeek.setDate(now.getDate() - 6);

    for (let i = prevOneWeek; i <= now; i.setDate(i.getDate() + 1)) {
      let dateString = i.getFullYear() + '-' + ('0' + (i.getMonth() + 1)).slice(-2) + '-' + ('0' + i.getDate()).slice(-2);
      arr.push(dateString);
    }
  
    setDateArray(arr);
  }

  useEffect(() => {
    getWeekDateArr();
    getComplainData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

   useEffect(() => {
    getComplainCount(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodOption, startDate])


	return (
		<>
		<section className="section com_lnb2">
			<div className="inner">
        <CustomerSubButton buttonHandler={returnData} />
        <CustomerSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate} periodOption={periodOption}/>
			</div>
		</section>
		<section className="com_content">
      <div className="com_list box">
        <article className="top_area">
          <div className="info_field">
            <p className="date">{`${prevWeekDate} ~ ${today}`}</p>
          </div>
        </article>
        <article className="chart_area">
          차트 영역
          <Graph dateArray={dateArray} data={complainData}/>
        </article>
      </div>
      <div className="com_list box" style={{marginTop:'2%'}}>
        <table className="list">
        <colgroup><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col/></colgroup>
          <thead>
            <tr>
              <th colSpan="19">시술 카테고리별 집계</th>
            </tr>
            <tr>
              <th>시술 카테고리</th>
              {category?.map((el,i)=>(
                <th key={i} value={el.common_code}>{el.code_name}</th>
              ))}
              <th>총 컴플레인 수</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>건수</td>
              {complainTotal?.map((el,i)=>(
                  <td key={i}>{el.total_count}</td>
                ))}
              <td className="total_field"><b className="total">{complainTotalCount}</b></td>
            </tr>
          </tbody>
        </table>
      </div>
		</section>
    {/* <ComplainWriteOk/> */}
		</>
	)
}

export default ComplainChart