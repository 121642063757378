const cryptoJs = require("crypto-js");

const secretKey = process.env.REACT_APP_AES_PRIVATE_KEY; // 32자리 비밀키
const iv = process.env.REACT_APP_AES_IV_KEY; // 16자리 iv

// 암호화
const encrypt = (plainText) => {
  const cipher = cryptoJs.AES.encrypt(plainText, cryptoJs.enc.Utf8.parse(secretKey), {
    iv: cryptoJs.enc.Utf8.parse(iv),
    padding: cryptoJs.pad.Pkcs7,
    mode: cryptoJs.mode.CBC,
  });

  return cipher.toString();
};

// 복호화
const decrypt = (encryptedText) => {
  const decipher = cryptoJs.AES.decrypt(
    encryptedText,
    cryptoJs.enc.Utf8.parse(secretKey),
    {
      iv: cryptoJs.enc.Utf8.parse(iv),
      padding: cryptoJs.pad.Pkcs7,
      mode: cryptoJs.mode.CBC,
    }
  );

  return decipher.toString(cryptoJs.enc.Utf8);
};

const aes256Manager = {
  encrypt,
  decrypt,
};

module.exports = aes256Manager;
