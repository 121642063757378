//* 직무 카테고리 관리
import React, { useRef, useState, useEffect } from "react";
import Draggable from 'react-draggable';

import request from '../../utils/Request.utils';
import { nvl } from "../../utils/Common.utils";
import CustomAlert from '../../atoms/common/CustomAlert';

const PositionCategoryManage = (props) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [assignmentList, setAssignmentList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [activateMode, setActivateMode] = useState('');

  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false
  });

  const nodeRef = useRef();
  const assignmentNameRef = useRef();

  const onClose = () => {
    props.onClose();
  }

  // 직무 리스트 가져오기
  const getSubAssignmentList = () => {
    const successHandler = (response) => {
      console.log(response);

      if(response.messageCode === 200) setAssignmentList(response.data.dataList);
      else console.log("코드 값을 찾지 못했습니다.");
    }

    request.get("/api/etc/commoncd/2/010", null, successHandler);
  }

  useEffect(() => {
    getSubAssignmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = (event) => {
    // console.log(event.target.value + " | " + event.target.id)

    setSelectedValue(event.target.value)
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const deleteAssignmentName = () => {
    if(nvl(selectedValue) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 직무가 없습니다." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "선택된 직무를 삭제하시겠습니까?" } })
    setActivateMode('deleteAssignment'); // 다음 실행할 함수를 호출하기 위해서
  }

  const addAssignmentName = () => {
    let assignmentName = assignmentNameRef.current.value;

    // console.log("assignmentName : " + assignmentName);

    if(nvl(assignmentName) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "직무명을 입력해주십시오." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "직무를 추가하시겠습니까?" } })
    setActivateMode('addAssignment'); // 다음 실행할 함수를 호출하기 위해서
  }

  const returnAlertValue = (value) => {
    console.log("value ==> " + value);

    if(nvl(value) === 'OK') {
      if(activateMode === 'addAssignment') { // 직무 추가
        let params = {
          parent_code: "010000000",
          code_level: "2",
          code_name: nvl(assignmentNameRef.current.value),
          code_desc: "",
          reg_manager_seq: managerInfo.seq
        }
    
        const successHandler = (response) => {
          console.log(response);

          if(response.messageCode === 200) { // ok 코드 값
            assignmentNameRef.current.value = "";
    
            getSubAssignmentList(); // 다시 호출
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        request.post("/api/etc/commoncd", params, successHandler);
      } else if(activateMode === 'deleteAssignment') { // 직무 삭제
        const successHandler = (response) => {
          console.log(response);

          if(response.messageCode === 200) { // ok 코드 값
            assignmentNameRef.current.value = "";
            setSelectedValue('');
    
            getSubAssignmentList(); // 다시 호출
          } else if(response.messageCode === 409) {
            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "관리자와 연결된 직무가 있으므로<br />삭제가 불가능합니다." } })
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        request.del(`/api/etc/commoncd/assignment/${selectedValue}/${managerInfo.seq}`, null, successHandler);
      }
    }
  }

	return (
		<>
      <section className={ props.positionCategoryManageBool ? "com_popup type_normal active" : "com_popup type_normal" }>
        
        <Draggable nodeRef={ nodeRef} >

          <div ref={ nodeRef } className="pop_container s">
            { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> : null }

            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={ onClose }></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">직무 카테고리 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">     
              <fieldset className="add">
                <input type="text" className="input" ref={ assignmentNameRef } />
                <button type="button" className="com_btn m blue" onClick={ addAssignmentName }>등록</button>
              </fieldset>
              <article className="list">
                {
                  (assignmentList == null || assignmentList.length < 1) ?
                    <ul className="item_wrap scroll">
                      <li className="item no_data">등록된 직무카테고리가 없습니다.</li>
                    </ul>
                  :
                    <ul className="item_wrap scroll">
                      {
                        assignmentList.map((item, index) => (
                          item.code_level > 1 ?
                            <li key={item.common_code} className="item">
                              <fieldset className="com_check_wrap">
                                <input type="radio" className="radio" id={"assignment" + index} name="radio" value={item.common_code}
                                  onChange={ clickHandler } checked={ item.common_code === selectedValue } />
                                <label className="btn_radio" htmlFor={"assignment" + index}>&nbsp;</label>
                                <label htmlFor={"assignment" + index}>{item.code_name}</label>
                              </fieldset>
                            </li>
                          : null
                        ))
                      }
                    </ul>
                }
              </article>
              <p className="tip_txt">관리자와 연결된 직무가 있는 경우<br/>삭제가 불가능합니다.</p>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={ deleteAssignmentName }>삭제</button>
            </div>
          </div>

          </Draggable>

      </section>
		</>
	)
}

export default PositionCategoryManage