//* 고객관리 상세 view
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import VisitType from './_VisitType.js';//내원경로관리 팝업

import CustomerSubButton from "../../atoms/crm/SubButton";
import CustomAlert from '../../atoms/common/CustomAlert';
import SnsSend from "components/common/_SnsSend.js";
import SnsSendList from "components/common/_SnsSendList.js";
import MemoCard from "../../components/common/_CustomMemo";

import request from '../../utils/Request.utils';
import { decrypt } from "../../utils/aes256.manager";
import { nvl, nvlNumber, checkOnlyNumber, convertJuminToAge, addComma, convertGender } from "../../utils/Common.utils";
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

const CustomerView = () => {
  moment.locale('ko-KR');

  const query = new URLSearchParams(window.location.search);
  const userSeq = query.get('userSeq')

  const navigate = useNavigate();

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [userDetailInfo, setUserDetailInfo] = useState({});
  const [genderSelectedValue, setGenderSelectedValue] = useState('');
  const [admissiveChannelList, setAdmissiveChannelList] = useState([]);
  const [admissiveChannel, setAdmissiveChannel] = useState('');
  const [userLevelList, setUserLevelList] = useState([]);
  const [userLevel, setUserLevel] = useState('');
  const [bankList, setBankList] = useState([]);
  const [bankName, setBankname] = useState('');

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const [customerPopup, setCustomerPopup] = useState({
    sent: false,
    memo: false,
    userData: ""
  })

  const [activateMode, setActivateMode] = useState('');
  const [visitDisplayBool, setVisitDisplayBool] = useState(false);
  const [snsSendBool, setSnsSendBool] = useState(false);
  const [memoBool, setMemoBool] = useState(false)
  const [userUsePointTotal, setUserUsePointTotal] = useState(0);
  const [pointList, setPointList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [couponValue, setCouponValue] = useState('');
  const [couponHistoryList, setCouponHistoryList] = useState([]);

  // const userNicknameRef = useRef();
  const interestAreaRef = useRef();
  const userMobileRef = useRef();
  const bankAccountNumberRef = useRef();
  const bankAccountHolderRef = useRef();
  // const userEmailRef = useRef();
  const chargePointRef = useRef();

  const returnData = (getValue) => {
    // console.log("customerView : " + getValue);
  }

  const getUserInfo = () => {
    const successHandler = (response) => {

      if (response.messageCode === 200) { // ok 코드
        setUserDetailInfo(response.data.dataList[0])
        setGenderSelectedValue(response.data.dataList[0].user_gender)
        setUserLevel(response.data.dataList[0].user_level)
        setAdmissiveChannel(response.data.dataList[0].admissive_channel)
        setBankname(response.data.dataList[0].bank_name)
        setUserUsePointTotal(response.data.dataList[0].user_use_point_total)

        setPointList(response.data.pointData);
        setCouponList(response.data.couponData);
        setCouponHistoryList(response.data.couponHistoryData);
      } else {
        console.log("데이터가 없습니다..");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "사용자 정보를 가져오던 중 에러가 발생하였습니다." } })
    }

    request.get(`/api/user/${userSeq}`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getUserInfo();
  }, [userSeq]);

  const genderChangeHandler = (event) => {
    setGenderSelectedValue(event.target.value);
  }

  const qualificationCheck = (event) => {
    console.log("자격조회는 좀 나중에...")
  }

  const getAdmissiveChannel = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) { // ok 코드 값

        setAdmissiveChannelList(response.data.dataList);
        getCustomerGrade();
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "내원경로 리스트를 가져오지 못했습니다." } })
    }

    request.get("/api/etc/commoncd/2/002", null, successHandler, failHandler, null, null);
  }

  const getCustomerGrade = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) { // ok 코드 값

        setUserLevelList(response.data.dataList);
        getBankCode()
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객등급 정보를 가져오지 못했습니다." } })
    }

    request.get("/api/etc/commoncd/2/008", null, successHandler, failHandler, null, null);
  }

  const getBankCode = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) { // ok 코드 값
        setBankList(response.data.dataList);
      } else {
        console.log("은행 목록을 가져오지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "은행정보를 가져오지 못했습니다." } })
    }

    request.get('/api/bank/list', null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getAdmissiveChannel();
  }, []);

  useEffect(() => {
    getAdmissiveChannel();
  }, [visitDisplayBool]);


  const admissiveChannelChangeHandler = ({ target: { value } }) => {
    setAdmissiveChannel(value)
  }

  const userLevelChangeHandler = ({ target: { value } }) => {
    setUserLevel(value)
  }

  const banknameChangeHandler = ({ target: { value } }) => {
    setBankname(value)
  }

  const couponChangeHandler = ({ target: { value } }) => {
    setCouponValue(value)
  }

  const userInfoModify = () => {
    let params = {
      // user_name: userDetailInfo.user_name,
      user_jumin: decrypt(userDetailInfo.user_jumin).substring(0, 6) + "-" + decrypt(userDetailInfo.user_jumin).substring(6, 13),
      user_level: userLevel,
      user_gender: genderSelectedValue,
      // user_dob: userDetailInfo.user_dob,
      // user_nickname: userNicknameRef.current.value,
      user_mobile: userMobileRef.current.value,
      interest_area: interestAreaRef.current.value,
      user_postcode: userDetailInfo.user_postcode,
      user_address: userDetailInfo.user_address,
      user_address_detail: userDetailInfo.user_address_detail,
      admissive_channel: admissiveChannel,
      // user_email: userEmailRef.current.value,
      bank_name: bankName,
      bank_account_number: bankAccountNumberRef.current.value,
      bank_account_holder: bankAccountHolderRef.current.value,
      modi_manager_seq: managerInfo.seq
    }

    params = {...userDetailInfo,  ...params}

    const successHandler = (response) => {

      if (response.messageCode === 200) { // ok 코드 값
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "수정이 완료되었습니다." } })
      } else {
        console.log("수정 처리중 에러가 발생했습니다.");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "수정 처리중 에러가 발생했습니다." } })
    }

    request.put(`/api/user/${userSeq}`, params, successHandler, failHandler, null, null);
  }

  const userInfoModifyActivate = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "고객 정보를 수정하시겠습니까?" } })
    setActivateMode('userInfoModify'); // 다음 실행할 함수를 호출하기 위해서
  }

  const userWithdrawalActivate = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "현재 고객을 탈퇴처리 하시겠습니까?" } })
    setActivateMode('userWithdrawal'); // 다음 실행할 함수를 호출하기 위해서
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const returnAlertValue = (value) => {

    if (nvl(value) === 'OK') {
      if (activateMode === 'userInfoModify') userInfoModify();
      else if (activateMode === 'userWithdrawal') { // 회원탈퇴
        const successHandler = (response) => {
          if (response.messageCode === 200) { // ok 코드 값

            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "탈퇴 처리가 완료되었습니다." } })
            navigate("/crm/customer")
          } else {
            console.log("탈퇴 처리중 에러가 발생했습니다.");
          }
        }

        request.del(`/api/user/${userSeq}/W/${managerInfo.seq}`, null, successHandler);
      }

      else if (activateMode === 'userPointCharge') {
        let params = {
          user_seq: userSeq,
          use_point: chargePointRef.current.value,
          use_purpose: "충전",
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {

          if (response.messageCode === 200) { // ok 코드 값
            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "충전이 완료 되었습니다." } })

            chargePointRef.current.value = 0

            pointListReload()
          } else {
            console.log("포인트 충전중 에러가 발생했습니다.");
          }
        }

        const failHandler = (response) => {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "포인트 충전중 에러가 발생했습니다." } })
        }

        request.post('/api/point', params, successHandler, failHandler, null, null);
      }

      else if (activateMode === 'userCouponSend') {
        let params = {
          coupon_seq: couponValue,
          user_seq: userSeq,
          coupon_status: 'R'
        }

        const successHandler = (response) => {

          if (response.messageCode === 200) { // ok 코드 값
            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "쿠폰 지급이 완료 되었습니다." } })
            setCouponValue('')

            couponHistoryListReload()
          } else {
            console.log("쿠폰 지급중 에러가 발생했습니다.");
          }
        }

        const failHandler = (response) => {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "쿠폰 지급중 에러가 발생했습니다." } })
        }

        request.post('/api/coupon/history', params, successHandler, failHandler, null, null);
      }
    }
  }

  const visitDisplayToggle = () => {
    setVisitDisplayBool(!visitDisplayBool);

  }

  const chargePoint = () => {
    let chargePoint = chargePointRef.current.value;

    if (!checkOnlyNumber(chargePoint)) {
      // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력하세요." } })

      chargePointRef.current.value = 0
    }

    else if (chargePoint <= 0) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "0 보다 큰 수를 입력하세요." } })

      chargePointRef.current.value = 0
    }

    else {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "포인트를 충전하시겠습니까?" } })
      setActivateMode('userPointCharge'); // 다음 실행할 함수를 호출하기 위해서
    }
  }

  const sendCoupon = () => {
    if (nvl(couponValue) === "") setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "쿠폰을 선택해주세요." } })
    else {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "쿠폰을 지급하시겠습니까?" } })
      setActivateMode('userCouponSend'); // 다음 실행할 함수를 호출하기 위해서
    }
  }

  const pointListReload = () => {
    const successHandler = (response) => {

      if (response.messageCode === 200) { // ok 코드 값
        setPointList(response.data);
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "포인트 정보를 가져오지 못했습니다." } })
    }

    request.get(`/api/point/${userSeq}/list`, null, successHandler, failHandler, null, null);
  }

  const couponHistoryListReload = () => {

    const successHandler = (response) => {

      if (response.messageCode === 200) { // ok 코드 값
        setCouponHistoryList(response.data?.dataList);
      } else {
        console.log("쿠폰 정보를 가져오지 못했습니다.");
      }
    }

    request.get(`/api/coupon/history?user_seq=${userSeq}&delete_yn=N&orderBy=coupon_history_seq DESC`, null, successHandler);
  }


  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <CustomerSubButton buttonHandler={returnData} />
        </div>
      </section>
      <section className="com_content p_customer_view">
        <div className="com_view">
          {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
          <article className="top_area">
            <div className="info_field">
              <p className="tit">고객정보 상세보기</p>
            </div>
            <div className="btn_field">
              <button type="button" className="com_btn m line blue" onClick={() => { setCustomerPopup(prev => { return { ...prev, memo: true, userData: userDetailInfo } }) }}><i className="svg_icon icon_write yellow">&nbsp;</i>메모 보기</button>
              <button type="button" className="com_btn m line blue" onClick={() => { setCustomerPopup(prev => { return { ...prev, sent: true, userData: userDetailInfo } }) }}><i className="svg_icon icon_list yellow">&nbsp;</i>문자 내역보기</button>
              <button type="button" className="com_btn m line blue" onClick={() => { setSnsSendBool(!snsSendBool) }}><i className="svg_icon icon_sms yellow">&nbsp;</i>문자 보내기</button>
            </div>
          </article>
          <table className="view">
            <colgroup><col width="8.4%" /><col width="41.6%" /><col width="8.4%" /><col /></colgroup>
            <tbody>
              <tr>
                <th>고객 코드</th>
                <td>{userDetailInfo.chart_code}</td>
                <th>성별</th>
                <td>
                  <fieldset className="com_check_wrap">
                    <input type="radio" className="checkbox" id="userGenderM" name="userGender" value="M" checked={genderSelectedValue === 'M'} onChange={genderChangeHandler} />
                    <label className="btn_checkbox" htmlFor="userGenderM">&nbsp;</label>
                    <label htmlFor="userGenderM">남</label>
                    <input type="radio" className="checkbox" id="userGenderF" name="userGender" value="F" checked={genderSelectedValue === 'F'} onChange={genderChangeHandler} />
                    <label className="btn_checkbox" htmlFor="userGenderF">&nbsp;</label>
                    <label htmlFor="userGenderF">여</label>
                  </fieldset>
                </td>
                {/* <th>비밀번호</th>
                <td><input type="password" defaultValue="" className="input" placeholder="변경 할 비밀번호" />고객은 비밀번호가 없어요</td> */}
              </tr>
              {/* <tr>
                <th>아이디</th>
                <td>고객은 아이디가 없어요... ㅠㅠ</td>
                <th>성별</th>
                <td>
                  <fieldset className="com_check_wrap">
                    <input type="radio" className="checkbox" id="userGenderM" name="userGender" value="M" checked={genderSelectedValue === 'M'} onChange={genderChangeHandler} />
                    <label className="btn_checkbox" htmlFor="userGenderM">&nbsp;</label>
                    <label htmlFor="userGenderM">남</label>
                    <input type="radio" className="checkbox" id="userGenderF" name="userGender" value="F" checked={genderSelectedValue === 'F'} onChange={genderChangeHandler} />
                    <label className="btn_checkbox" htmlFor="userGenderF">&nbsp;</label>
                    <label htmlFor="userGenderF">여</label>
                  </fieldset>
                </td>
              </tr> */}
              <tr>
                <th>이름</th>
                <td>{userDetailInfo.user_name} ({convertGender(userDetailInfo.user_gender)}/{nvl(userDetailInfo.user_jumin) !== "" ?
                  convertJuminToAge((decrypt(userDetailInfo.user_jumin).substring(0, 6) + "-" + decrypt(userDetailInfo.user_jumin).substring(6, 13))) : " - "})</td>
                <th>주민등록번호</th>
                <td>
                  <p className="txt">{nvl(userDetailInfo.user_jumin) !== "" ? decrypt(userDetailInfo.user_jumin).substring(0, 6) : " - "}  -  *********</p>
                  <button className="com_btn blue m" onClick={qualificationCheck}>자격조회</button>
                </td>
              </tr>
              <tr>
                {/* <th>닉네임</th>
                <td><input type="text" className="input" ref={userNicknameRef} defaultValue={userDetailInfo.user_nickname} /></td> */}
                <th>휴대폰</th>
                <td><input type="text" className="input" ref={userMobileRef} defaultValue={userDetailInfo.user_mobile} /> ex) 010-1234-6789</td>
                <th>관심부위</th>
                <td><input type="text" className="input" ref={interestAreaRef} defaultValue={userDetailInfo.interest_area} /></td>
              </tr>
              <tr>
                <th>내원경로</th>
                <td>
                  <select className="select" value={admissiveChannel} onChange={admissiveChannelChangeHandler}>
                    <option value="">내원경로 선택</option>

                    {
                      (admissiveChannelList == null || admissiveChannelList.length < 1) ?
                        null
                        :
                        admissiveChannelList.map((item, index) => (
                          item.code_level > 1 ?
                            <option key={item.common_code} value={item.common_code}>{item.code_name}</option>
                            : null
                        ))
                    }

                  </select>
                  <button className="com_btn m blue" onClick={(e) => { visitDisplayToggle() }}><i className="svg_icon icon_set yellow">&nbsp;</i>내원경로 관리</button>
                </td>
                <th>최근 시술일</th>
                <td>{moment(userDetailInfo.order_reg_date).format("YYYY. MM. DD")}</td>
              </tr>
              <tr>
                {/* <th>이메일</th>
                <td><input type="text" className="input" ref={userEmailRef} defaultValue={userDetailInfo.user_email} /></td> */}
              </tr>
              <tr>
                <th>등급</th>
                <td>
                  <select className="select" value={userLevel} onChange={userLevelChangeHandler}>
                    <option value="">고객등급</option>

                    {
                      (userLevelList == null || userLevelList.length < 1) ? null
                        :
                        userLevelList.map((item, index) => (
                          item.code_level > 1 ?
                            <option key={item.common_code} value={item.common_code}>{item.code_name}</option>
                            : null
                        ))
                    }

                  </select>
                </td>
                <th>방문 횟수</th>
                <td>{userDetailInfo.order_count}</td>
              </tr>
              <tr>
                <th>환불 계좌</th>
                <td>
                  <select className="select s" value={bankName} onChange={banknameChangeHandler}>
                    <option>은행선택</option>

                    {
                      (bankList == null || bankList.length < 1) ?
                        null
                        :
                        bankList.map((item, index) => (
                          <option key={item.bank_code} value={item.bank_code}>{item.bank_name}</option>
                        ))
                    }

                  </select>
                  <input type="text" className="input" ref={bankAccountNumberRef} defaultValue={userDetailInfo.bank_account_number} />
                  <input type="text" className="input s" placeholder="예금주명" ref={bankAccountHolderRef} defaultValue={userDetailInfo.bank_account_holder} />
                </td>
                <th>가입일</th>
                <td>{moment(userDetailInfo.reg_date).format("YYYY. MM. DD")}</td>
              </tr>
              {/* <tr>
                <th>탈퇴일</th>
                <td colSpan={3}>2022.02.02</td>
              </tr> */}
            </tbody>
          </table>
          <article className="btn_area" style={{marginTop: "1%"}}>
            <button className="com_btn l blue" onClick={userInfoModifyActivate}>수정</button>
            <button className="com_btn l blue line" onClick={userWithdrawalActivate}>회원탈퇴</button>
          </article>
        </div>
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="tit">포인트 사용내역</p>
            </div>
            <div className="right_wrap">
              <span className="point_field"><b className="point">{nvlNumber(userUsePointTotal) > 0 ? addComma(userUsePointTotal) : 0}</b> Point</span>
              <fieldset>
                <input type="number" ref={chargePointRef} className="input" placeholder="포인트 입력" defaultValue="0" />
                <button type="button" className="com_btn m blue" onClick={chargePoint}>충전하기</button>
              </fieldset>
            </div>
          </article>
          <div className="scroll" style={{ maxHeight: '200px' }}>
            <table className="list">
              <colgroup><col width="13.5%" /><col /><col width="13.5%" /><col width="13.5%" /><col width="13.5%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>변동일자</th>
                  <th>사용 내용</th>
                  <th>충전 금액</th>
                  <th>차감 금액</th>
                  <th>보유 포인트</th>
                </tr>
              </thead>
              <tbody>
                {

                  (pointList == null || pointList.length < 1) ?
                    <tr>
                      <td colSpan="5">포인트 사용 내역이 없습니다.</td>
                    </tr>
                    :

                    pointList.map((item, index) => (
                      <tr key={item.point_his_seq}>
                        <td>{nvl(item.modi_date) === "" ? moment(item.reg_date).format("YYYY-MM-DD") : moment(item.modi_date).format("YYYY-MM-DD")}</td>
                        <td className="tit">{item.use_purpose}</td>
                        <td className="point_plus">{nvlNumber(item.use_point) > 0 ? addComma(item.use_point) : 0}P</td>
                        <td className="point_minus">{nvlNumber(item.use_point) < 0 ? addComma(item.use_point * -1) : 0}P</td>
                        <td>{addComma(item?.current_point)}P</td>
                      </tr>
                    ))
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="tit">쿠폰 사용내역</p>
            </div>
            <div className="right_wrap">
              <fieldset>
                <select className="select" value={couponValue} onChange={couponChangeHandler}>
                  <option>쿠폰선택</option>

                  {
                    (couponList == null || couponList.length < 1) ?
                      null
                      :
                      couponList.map((item, index) => (
                        <option key={item.coupon_seq} value={item.coupon_seq}>{item.coupon_name}</option>
                      ))
                  }

                </select>
                <button type="button" className="com_btn m blue" onClick={(e) => { sendCoupon() }}>지급하기</button>
              </fieldset>
            </div>
          </article>
          <div className="scroll" style={{ maxHeight: '200px' }}>
            <table className="list">
              <colgroup><col width="13.5%" /><col width="13.5%" /><col /><col width="13.5%" /><col width="13.5%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>지급일</th>
                  <th>사용가능일</th>
                  <th>보유쿠폰 명</th>
                  <th>금액</th>
                  <th>사용일</th>
                </tr>
              </thead>
              <tbody>

                {
                  (couponHistoryList == null || couponHistoryList.length < 1) ?
                    <tr>
                      <td colSpan="5">쿠폰 사용 내역이 없습니다.</td>
                    </tr>
                    :

                    couponHistoryList.map((item, index) => (
                      <tr key={item.coupon_history_seq}>
                        <td>{nvl(item.provide_date) !== "" ? moment(item.provide_date.substring(0, 10)).format("YYYY-MM-DD") : ""}</td>
                        <td>
                          {nvl(item.use_start_date) !== "" ? moment(item.use_start_date.substring(0, 10)).format("YYYY-MM-DD") : ""}
                          {nvl(item.use_end_date) !== "" ? " ~ " + moment(item.use_end_date.substring(0, 10)).format("YYYY-MM-DD") : ""}
                        </td>
                        <td className="tit">{item.coupon_name}</td>
                        <td className="num">{nvlNumber(item.coupon_price) > 0 ? addComma(item.coupon_price) : 0}</td>
                        <td className="unused">{nvl(item.use_date) !== "" ? moment(item.use_date.substring(0, 10)).format("YYYY-MM-DD") : "미사용"}</td>
                      </tr>
                    ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </section>
      <VisitType visitDisplayBool={visitDisplayBool} visitDisplayToggle={visitDisplayToggle} />
      {customerPopup.memo && <MemoCard customerPopup={customerPopup} setCustomerPopup={setCustomerPopup} />}
      {snsSendBool && <SnsSend snsSendBool={snsSendBool} setSnsSendBool={setSnsSendBool} />}
      {customerPopup.sent && <SnsSendList customerPopup={customerPopup} setCustomerPopup={setCustomerPopup} />}
    </>
  )
}

export default CustomerView