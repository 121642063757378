export const CHART_TYPE = {
    'A': '전체양도',
    'B': '구매',
    'C': '쿠폰', // 2023.01.27, 무료
    'F': '무료시술',
    'P': '결제',
    'T': '부분양도'
}

// 일반, 보험만 사용, 2023.01.26
export const INSURANCE_TYPE = {
    'N': '일반',
    'S': '급여',
    'U': '비급여',
    'I': '보험'
}
