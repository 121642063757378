//* 진료비관리
import React, { useState, useRef } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import { nvl, nvlOnlyNumber } from "../../utils/Common.utils";
import CustomAlert from "atoms/common/CustomAlert";

const MedicalFeeManage = ({ onClose, getMedicalManageList, mediMangeList, mediCateList, setMediCateList }) => {
    const nodeRef = useRef(null);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [isActive, setIsActive] = useState(1);
  const [mediCate, setMediCate] = useState('');
  const [updateMediCate, setUpdateMediCate] = useState();
  const [onUpdateBool, setOnUpdateBool] = useState(false);
  const [onCheckBool, setOnCheckBool] = useState(false);
  const [feeSeq, setFeeSeq] = useState('');
  const [commonCd, setCommonCd] = useState('');
  const initmediManageInfo = {
    treatment_fee_type: '',
    treatment_fee_name: '',
    treatment_fee_price: '' 
  }
  const [mediManage, setMediManage] = useState(initmediManageInfo);
  const [updateMediManage, setUpdateMediManage] = useState(initmediManageInfo);
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "" } })
  }


  const mediCateHandler = (e, num) => {
    const {value} = e.target;
    num === 1 ? setMediCate(value) : setUpdateMediCate(value);
  }


  const regMediCate = () => {
    if(!onUpdateBool){ 
      if (nvl(mediCate) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "구분명을 입력해주세요." } })
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "진료비 구분을 등록하시겠습니까?", activateMode: "regMediCate"}});
    }else{
      if (nvl(updateMediCate) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "구분명을 입력해주세요." } })
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "진료비 구분을 변경하시겠습니까?", activateMode: "updateMediCate"}});
    }
  }

  const delMediCate = (num) => {
    if(num === 1){ 
      if (nvl(commonCd) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제할것을 선택해주세요." } })
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "진료비 구분을 삭제하시겠습니까?", activateMode: "delMediCate"}});
    } else{
      if (nvl(feeSeq) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제할것을 선택해주세요." } })
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "진료비 구분을 삭제하시겠습니까?", activateMode: "delMediCate"}});
    }
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (!onUpdateBool && alertLayerPopup.activateMode === "regMediCate") {
        let params = {
          parent_code: "016000000",
          code_level: "2",
          code_name: mediCate,
          code_desc: "",
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료비 구분이 등록되었습니다.",activateMode:''}});
            setMediCate('');
            getMedicalFeeList();
          } else {
            console.log("진료비 구분을 등록하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/etc/commoncd", params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "updateMediCate") {
        let params = {
          code_name: updateMediCate,
          code_desc: "",
          modi_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료비 구분이 변경되었습니다.",activateMode:''}});
            getMedicalFeeList();
          } else {
            console.log("진료비 구분을 변경하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.put(`/api/etc/commoncd/u/${commonCd}`, params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "delMediCate") {
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료비 구분을 삭제하였습니다.",activateMode:''}});
            getMedicalFeeList();
          } else if (response.messageCode === 409) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료비 구분내 명칭이 있어 삭제가 불가능합니다.",activateMode:''}});
          } else {
            console.log("진료비 구분을 삭제하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.del(`/api/etc/commoncd/medicalFee/${commonCd}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
      } else if (!onCheckBool && alertLayerPopup.activateMode === "regMediManage") {
        let params = {
          treatment_fee_type: mediManage.treatment_fee_type,
          treatment_fee_name: mediManage.treatment_fee_name,
          treatment_fee_price: mediManage.treatment_fee_price,
          reg_manager_seq: managerInfo.seq,
          use_yn: "Y"
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료비 명칭이 등록되었습니다.",activateMode:''}});
            setMediManage({
              treatment_fee_type: '',
              treatment_fee_name: '',
              treatment_fee_price: ''
            });
            getMedicalManageList();
          } else {
            console.log("진료비 명칭을 등록하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/medicalfee", params, successHandler, failHandler, null, null);
      } else if (onCheckBool && alertLayerPopup.activateMode === "updateMediManage") {
        let params = {
          treatment_fee_type: updateMediManage.treatment_fee_type,
          treatment_fee_name: updateMediManage.treatment_fee_name,
          treatment_fee_price: updateMediManage.treatment_fee_price,
          modi_manager_seq: managerInfo.seq
        }
  
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료비 명칭이 변경되었습니다.",activateMode:''}});
            setUpdateMediManage(initmediManageInfo);
            getMedicalManageList();
          } else {
            console.log("진료비 명칭을 변경하지 못했습니다.")
          }

        }
  
        const failHandler = (response) => {
          console.log("에러" + response);
        }
  
        request.put(`/api/medicalfee/${feeSeq}`, params, successHandler, failHandler, null, null);
      } else if (onCheckBool && alertLayerPopup.activateMode === "delMediCate") {
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료비 명칭이 삭제되었습니다.",activateMode:''}});
            setUpdateMediManage(initmediManageInfo);
            getMedicalManageList();
          } else {
            console.log("진료비 명칭을 삭제하지 못했습니다.")
          }

        }
  
        const failHandler = (response) => {
          console.log("에러" + response);
        }
  
        request.del(`/api/medicalfee/${feeSeq}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
      } 
    }
  }

  const onDetailClick = (event, common_code, fee_seq) => {

    if(common_code === commonCd) {
      setCommonCd('')
      setOnUpdateBool(false);

      event.target.checked = false;
    } else if (fee_seq === feeSeq) {
      setFeeSeq('')
      setOnCheckBool(false);

      event.target.checked = false;
    }else {
      if(nvl(common_code) !== "") {
        setCommonCd(common_code);

        const copyData = mediCateList.filter(el => el.common_code === common_code);
        const data = [...copyData][0]

        setUpdateMediCate(data.code_name);
        setOnUpdateBool(true);
      } else {
        setFeeSeq(fee_seq)

        const copyData = mediMangeList.filter(el => el.fee_seq === fee_seq);
        const data = [...copyData][0]

        setUpdateMediManage({
          treatment_fee_type: data.treatment_fee_type,
          treatment_fee_name: data.treatment_fee_name,
          treatment_fee_price: data.treatment_fee_price
        })

        setOnCheckBool(true);
      }
    }
  }
  
   // 진료비 구분 리스트 가져오기
   const getMedicalFeeList = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) {
        setMediCateList(response.data.dataList);
        getMedicalManageList();
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    request.get("/api/etc/commoncd/2/016", null, successHandler);
  }

  const mediManageHandler = (e) => {
    const {name, value} = e.target;
    if(!onCheckBool){ 
      if(name === "treatment_fee_price"){
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요." }});
          return;
        }
      }
      setMediManage((prev) => ({...prev, [name]:value}));
    }else{
      if(name === "treatment_fee_price"){
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요." }});
          return;
        }
      }
      setUpdateMediManage((prev) => ({...prev, [name]:value}));
    }
  }


  const regMediManage = () => {
    if(!onCheckBool){ 
      if (nvl(mediManage.treatment_fee_type) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "진료비 구분을 선택해주세요." } })
        return;
      } else if (nvl(mediManage.treatment_fee_name) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "진료비 명칭을 입력해주세요." } })
        return;
      } else if (nvl(mediManage.treatment_fee_price) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "진료비 금액을 입력해주세요." } })
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "진료비 명칭을 등록하시겠습니까?", activateMode: "regMediManage"}});
    }else{
      if (nvl(updateMediManage.treatment_fee_type) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "진료비 구분을 선택해주세요." } })
        return;
      } else if (nvl(updateMediManage.treatment_fee_name) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "진료비 명칭을 입력해주세요." } })
        return;
      } else if (nvl(updateMediManage.treatment_fee_price) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "진료비 금액을 입력해주세요." } })
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "진료비 명칭을 변경하시겠습니까?", activateMode: "updateMediManage"}});
    }
  }

  


	return (
		<>
      <section className="com_popup type_normal pop_medifee active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">진료비 관리</h1>
            </div>
            <div className="com_tab">
            <button className={`menu ${isActive === 1 ? "active" : ""}`} onClick={() => {setIsActive(1); setUpdateMediManage(initmediManageInfo); setOnCheckBool(false); setFeeSeq('');}}>진료비 구분</button>

            <button className={`menu ${isActive === 2 ? "active" : ""}`} onClick={() => {setIsActive(2); setUpdateMediCate(''); setOnUpdateBool(false); setCommonCd('');}}>진료비 명칭</button>
            </div> 
            <div className="pop_body com_add_item">  
              {/*진료비 구분*/}
              {isActive === 1 &&
              <>
                <fieldset className="add">
                  <input type="text" className="input" placeholder="구분명 입력" value={!onUpdateBool ? mediCate || "" : updateMediCate || ""} onChange={(e) => !onUpdateBool ? mediCateHandler(e,1) : mediCateHandler(e,2)}/>
                  <button type="button" className="com_btn m blue" onClick={regMediCate}>{!onUpdateBool ? "등록" : "변경"}</button>
                </fieldset>
                <article className="list">
                  <ul className="item_wrap scroll">
                    {(mediCateList == null || mediCateList.length < 1) ? 
                    <li className="item no_data">등록된 진료비가 없습니다.</li>
                    :
                    mediCateList.map((el, i) => (
                      el.code_level > 1 ? 
                      <li className="item" key={el.common_code}>
                        <fieldset className="com_check_wrap">
                          <input type="radio" className="radio" id={`radio${i}`} name="radio" value={el.common_code || ""} onClick={(e) => onDetailClick(e, el.common_code)} />
                          <label className="btn_radio" htmlFor={`radio${i}`}>&nbsp;</label>
                          <label htmlFor={`radio${i}`}>{el.code_name}</label>
                        </fieldset>
                      </li>
                      : null
                      ))  
                    }
                  </ul>
                </article>
                <p className="tip_txt">진료비 구분내 명칭이 있는 경우 <br/>삭제가 불가능합니다.</p>
              </> }
              {/*진료비 명칭*/}
              {isActive === 2 &&
              <>
                <fieldset className="add name_sec">
                  <select className="select" name="treatment_fee_type" value={!onCheckBool ? mediManage.treatment_fee_type : updateMediManage.treatment_fee_type} onChange={mediManageHandler}>
                    <option value="">진료비 구분 선택</option>
                    {mediCateList?.map((el, i) => (
                      el.code_level > 1 ? 
                      <option key={el.common_code} value={el.common_code}>{el.code_name}</option>
                      : null
                      ))}
                  </select>
                  <div className="form_area">
                    <div className="input_wrap">
                      <input type="text" className="input" name="treatment_fee_name" value={!onCheckBool ? mediManage.treatment_fee_name : updateMediManage.treatment_fee_name} placeholder="진료비 명칭 입력" onChange={mediManageHandler}/>
                      <input type="text" className="input" name="treatment_fee_price" value={!onCheckBool ? mediManage.treatment_fee_price : updateMediManage.treatment_fee_price} placeholder="진료비 금액입력" onChange={mediManageHandler}/>
                    </div>
                    <button type="button" className="com_btn m blue" onClick={regMediManage}>{!onCheckBool ? "등록" : "변경"}</button>
                  </div>
                </fieldset>
                <article className="list">
                  <ul className="item_wrap scroll">
                    {(mediMangeList === null || mediMangeList.length < 1) ?
                    <li className="item no_data">등록된 진료비가 없습니다.</li>
                    :
                    mediMangeList.map((el, i) => (
                      <li className="item" key={el.fee_seq}>
                        <fieldset className="com_check_wrap">
                          <input type="radio" className="radio" id={`radio_${i}`} name="radio" onClick={(e) => onDetailClick(e, null, el.fee_seq)} />
                          <label className="btn_radio" htmlFor={`radio_${i}`}>&nbsp;</label>
                          <label className="txt" htmlFor={`radio_${i}`}>{el.treatment_fee_name}<span className="price">{new Intl.NumberFormat().format(el.treatment_fee_price)}</span></label>
                        </fieldset>
                      </li>
                      ))
                    }
                  </ul>
                </article>
              </>}
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={(e) => isActive === 1 ? delMediCate(1) : delMediCate(2)}>삭제</button>
            </div>
          </div>
        </Draggable>
      </section>
      { alertLayerPopup.visible && <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> }
		</>
	)
}

export default MedicalFeeManage