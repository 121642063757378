import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import CalcSummary from "../components/statistics/CalcSummary.js";//결산요약
import CalcCustomer from "../components/statistics/CalcCustomer.js";//결산>환자별
import CalcBestSales from "../components/statistics/CalcBestSales.js";//결산>매출왕
import CalcRegister from "../components/statistics/CalcRegister.js";//결산>접수별
import CalcAge from "../components/statistics/CalcAge.js";//결산>연령대별
import CalcField from "../components/statistics/CalcField.js";//결산>분야별 
import CalcStaff from "../components/statistics/CalcStaff.js";//결산>담당별
import Unpaid from "../components/statistics/Unpaid.js";//미수
import Refund from "../components/statistics/Refund.js";//환불
import Order from "../components/statistics/Order.js";//시술판매
import CashReceipt from "../components/statistics/CashReceipt.js";//현금영수증 내역
import VisitingRoute from "../components/statistics/VisitingRoute.js";//내원경로별 매출비중
import Staff from "../components/statistics/Staff.js";//직원별 통계
import Insurance from "../components/statistics/Insurance.js";//보험료 청구
import request from '../utils/Request.utils';

const StatisticsRoute = () => {
  let { pathname } = window.location

  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    let authBool = false;

    authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

    if(authBool) {
      const authRoutes = [
        { path: "/summary", element: <CalcSummary /> },
        { path: "/calc_customer", element: <CalcCustomer /> }, 
        { path: "/calc_best_sales", element: <CalcBestSales /> },  
        { path: "/calc_register", element: <CalcRegister /> },
        { path: "/calc_age", element: <CalcAge /> }, 
        { path: "/calc_field", element: <CalcField /> }, 
        { path: "/calc_staff", element: <CalcStaff /> },    
        { path: "/unpaid", element: <Unpaid /> }, 
        { path: "/refund", element: <Refund /> },
        { path: "/order", element: <Order /> },    
        { path: "/cash_receipt", element: <CashReceipt /> }, 
        { path: "/visiting_route", element: <VisitingRoute /> },  
        { path: "/staff", element: <Staff /> },  
        { path: "/insurance", element: <Insurance /> }
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/*", element: <Navigate to='/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

	return (
		<>
			<Gnb />
      <main>
        <Lnb/>
        <Routes> { AuthorizationRoutes() } </Routes>
      </main>
		</>
	)
}

export default StatisticsRoute