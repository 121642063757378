import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";

import CustomAlert from "atoms/common/CustomAlert";
import { decrypt } from "utils/aes256.manager";
import { nvl, birthdayToAge, convertGender, convertJuminToAge } from "utils/Common.utils";
import request from "utils/Request.utils";
import moment from "moment";
import { socket, SOCKET_EVENT } from "../etc/socket";

const UserMemoList = ({ list, setAlertPop, getMemoList, alertPop, managerInfo }) => {
  const onChangeBookMark = ((e, index) => {
    const successHandlerBookMark = (response) => {
      if (response.messageCode === 200) getMemoList();
      else console.debug('Error >> ', response);
    }

    const params = {
      top_display_yn: list[index].book_mark ? 'N' : 'Y',
      modi_manager_seq: managerInfo?.seq,
      memo_seq: list[index].memo_seq,
    }

    request.put(`/api/etc/memocard/book-mark`, params, successHandlerBookMark);
  });

  const deleteMemoCard = ((e) => {
    setAlertPop((prev) => { return { ...prev, visible: true, id: e.target.id } })
  });

  useEffect(() => {
    const successHandler = (response) => {
      if (response.messageCode === 200) getMemoList();
      else console.debug('Error >> ', response);
    }

    setAlertPop((prev) => {
      return {
        ...prev, returnValue: (value) => {
          if (nvl(value) === 'OK') request.del(`/api/etc/memocard/${alertPop.id}/${managerInfo.seq}`, null, successHandler);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerInfo?.seq, alertPop?.id]);


  return (
    list?.map((item, index) => {
      return (
        <section key={item.memo_seq} className={item.book_mark ? 'item bookmark' : 'item'}>
          <article key='top_area' className="top_area">
            <div key='left_wrap' className="left_wrap">
              <p key={item.memo_seq + '_name'} className="name">{item.manager_name}</p>
              <p key={item.memo_seq + '_date'} className="date">{item.date}</p>
              <p key={item.memo_seq + '_time'} className="time">{item.time}</p>
            </div>
            <div key='right_wrap' className="right_wrap">
              {/* //^ 즐겨찾기 */}
              <fieldset className="com_bookmark">
                <input type="checkbox" className="input" id={`fav-${item.memo_seq}`} checked={item.book_mark} onChange={(e) => onChangeBookMark(e, index)} />
                <label className="btn_bookmark" htmlFor={`fav-${item.memo_seq}`}>&nbsp;</label>
              </fieldset>
              <button onClick={deleteMemoCard}><i id={item.memo_seq} className="svg_icon icon_trashcan darkgray">&nbsp;</i></button>
            </div>
          </article>
          <article key='txt_area' className="txt_area">
            {item.memo}
          </article>
        </section>
      )
    })
  )
}

const CustomMemo = ({ onClose, customerPopup, setCustomerPopup }) => {
  const stateCustomer = useSelector(state => state.Customer);

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [content, setContent] = useState('');
  const [memoList, setMemoList] = useState([]);

  const nodeRef = useRef(null);
  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '선택한 메모카드를 삭제하시겠습니까?',
    alertType: 'confirm',
    returnValue: () => { },
    id: '',
  });

  useEffect(() => {
    getMemoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomer?.user_seq, customerPopup?.userData?.user_seq])

  const closeCustomAlert = (() => {
    setAlertPop((prev) => { return { ...prev, visible: false } })
  });


  //소켓 이벤트 수신 시 데시보드 데이터 갱신 - 2023-09-06 WEM
  useEffect(() => {
    socket.on(SOCKET_EVENT.DASHBOARD, () => {
      console.log("SocketServer : dashboard. 메모등록");
      getMemoList();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);



  const getMemoList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) setMemoList(response.data);
      else console.debug('Error >> ', response);
    }

    request.get(`/api/etc/memocard/common/${customerPopup?.memo ? customerPopup.userData?.user_seq : stateCustomer?.user_seq}`, null, successHandler);
  }

  const saveMemoCard = (() => {
    if (nvl(content) === "") {
      setAlertPop((prev) => { return { ...prev, visible: true, alertType: "alert", alertMessage: "내용을 입력하세요." } })

      return;
    }

    const successHandler = (response) => {
      console.log(response)
      if (response.messageCode === 200) {
        getMemoList();
        setContent('');
      } else console.debug('Error >> ', response);
    }

    const params = {
      user_seq: customerPopup?.memo ? customerPopup.userData?.user_seq : stateCustomer?.user_seq,
      memo: content,
      reg_manager_seq: managerInfo?.seq,
      reg_date: moment().format("YYYY-MM-DD")
    }

    request.post(`/api/etc/memocard`, params, successHandler);
  })


  return (
    <>
      <section className="com_popup type_normal pop_custom_memo PopCustomMemo active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container m">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { customerPopup?.memo ? setCustomerPopup(prev => { return { ...prev, memo: false } }) : onClose(); }}></button>
            <div className="pop_header">
              <h1 className="pop_title">
                {customerPopup?.userData ? `${customerPopup?.userData.user_name}( ${convertGender(customerPopup.userData.user_gender)} / ${nvl(customerPopup?.userData.jumin_1) === "" ? " - " : convertJuminToAge((customerPopup?.userData.jumin_1) + " - " + (customerPopup?.userData.jumin_2))}) - 메모카드` : stateCustomer?.user_seq ? `${stateCustomer?.user_name}( ${convertGender(stateCustomer.user_gender)} / ${stateCustomer?.user_age} ) - 메모카드` : '메모카드'}
              </h1>
            </div>
            <div className="pop_body com_memo">
              <div className="scroll">
                <div className="list">
                  <UserMemoList list={memoList} getMemoList={getMemoList} setAlertPop={setAlertPop} alertPop={alertPop} managerInfo={managerInfo} />
                </div>
              </div>
              <div className="com_textarea_in_btn">
                <div className="input_del_wrap">
                  <textarea className="textarea" placeholder="내용을 입력하세요. (500자)" value={content} onChange={(e) => setContent(e.target.value)} style={{ resize: "none" }}></textarea>
                  <button type="button" className="btn_input_del big" onClick={() => { setContent('') }}>&nbsp;</button>
                </div>
                <button type="button" className="com_btn blue m w120 btn_save" onClick={saveMemoCard}>등록</button>
              </div>
            </div>
          </div>
        </Draggable>
      </section>
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
    </>
  )
}

export default CustomMemo