import React, { useState, } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { getTodayDate } from "../utils/Common.utils";

import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import Member from "../components/member/Member";//직원관리
import request from '../utils/Request.utils';

const MemberRoute = () => {
	let { pathname } = window.location

  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const [loadingBarActive, setLoadingBarActive] = useState(false);

  const getReceptionList = (order_seq, user_seq) => {
    setLoadingBarActive(true) // 로딩바 띄우기

    const successHandler = (response) => {

      if (response.messageCode === 200) {
        setLoadingBarActive(false) // 로딩바 없애기
      } else {
        console.log("접수 고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/charts/order/dashboard2?chart_type=chart&order_date=${getTodayDate('hyphen')}`, null, successHandler);
  }


  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    let authBool = false;

    authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

    if(authBool) {
      const authRoutes = [
        { path: "/", element: <Member loadingBarActive={loadingBarActive}/> }
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/", element: <Navigate to='/main/login' /> },
        { path: "/*", element: <Navigate to='/main/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

	return (
		<>
			<Gnb />
				<main>
					<Lnb getReceptionList={getReceptionList}/>
					<Routes> { AuthorizationRoutes() } </Routes>
				</main>
		</>
	)
}

export default MemberRoute