export default function Toolbar(props) {
  const { date, onView, onNavigate } = props;

  // console.log(props)

  const navigate = (action) => {
    onNavigate(action);
  }

  const customView = (type) => {
    onView(type);
  }

  return (
    <div className="rbc-toolbar com_select_date">
      <span className="rbc-btn-group">
        <button type="button" onClick={navigate.bind(null, 'PREV')} className="com_btn"><i className="svg_icon icon_prev white">&nbsp;</i></button>
         <span className="rbc-toolbar-label">{`${date.getFullYear()}년 ${date.getMonth() + 1}월`}</span>
        <button type="button" onClick={navigate.bind(null, 'NEXT')} className="com_btn"><i className="svg_icon icon_next white">&nbsp;</i></button>
      </span>
     {/* <span className="rbc-btn-group btn_wrap" >
        <button type="button" onClick={navigate.bind(null, 'PREV')}>이전</button>
        <button type="button" onClick={navigate.bind(null, 'NEXT')}>다음</button>
         <button type="button" onClick={navigate.bind(null, 'TODAY')} className="today">오늘</button> 
      </span>*/}
      {/* <span className="rbc-btn-group">
        <button type="button" onClick={ () => customView('month') }>달</button>
        <button type="button" onClick={ () => customView('week') }>주</button>
        <button type="button" onClick={ () => customView('day') }>일</button>
        <button type="button" onClick={ () => customView('agenda') }>일정</button>
      </span> */}
    </div>
  );
}