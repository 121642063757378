/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import OrderPrint from "./_OrderPrint"; // 당일오더-인쇄하기
import Prescription from "./_Prescription.js"; //당일오더-약처방
import ExplanAdd from "./_ExplanAdd.js"; //부연설명 입력
import ServiceProcedureAdd from "./_ServiceProcedureAdd"; //서비스 시술 추가
import Handover from "./_Handover.js"; //양도하기
import CustomAlert from "atoms/common/CustomAlert";
import request from "utils/Request.utils";
import PaymentDetail from "../../components/payment/_PaymentDetail";//결제팝업
import Document from "components/payment/_Document";
import { nvl, nvlNumber, vatCalculator, getTodayDate } from "utils/Common.utils";
import { CHART_TYPE } from "utils/global";
import { useSelector, useDispatch } from "react-redux";

const TodayOrderList = ({ list, setTodayOrders, setTotalPrice, popup, onClosePop, setPopup, remains, setRemains, userData, managerInfo, treatment, setTreatment, paymentItems, setPaymentItems }) => {

  const onPopupClick = (e) => {
    setPopup((prev) => { return { ...prev, note: true } });
    setTreatment({ ...list[e.target.id], id: e.target.id });
  }

  // 시술 삭제(X 버튼 클릭)
  const deleteItem = (e) => {
    const newItem = list[e.target.id];
    // 동일 시술 중복 확인
    const samePackExist = paymentItems.filter(el => el.pack_seq === newItem.pack_seq && el.goods_seq === newItem.goods_seq)


    const bothTodayRemain = remains.filter(remain => list.some(item => item.id === remain.id && nvl(remain.id) !== ''));
    const bothPayRemain = remains.filter(remain => paymentItems.some(item => (newItem.buy_order_seq === item.order_seq || newItem.order_seq === item.order_seq) && item.pay_YN === "N"))

    if (bothTodayRemain.length > 0) {
      // 시술 검색 => 당일 오더 => 일부 잔여시술 상태에서 당일 오더 해당 시술 삭제 시 잔여시술도 함께 삭제 
      setRemains(remains.filter(remain => !list.some(item => item.id === remain.id && nvl(remain.id) !== '')))
    } else if (bothPayRemain.length > 0) {
      // 오더 확인 후 당일 오더 => 일부 잔여시술 상태에서 당일 오더 해당 시술 삭제 시 잔여시술도 함께 삭제
      setRemains(remains.filter(remain => !paymentItems.some(item => remain.buy_order_seq === item.order_seq && item.pay_YN === "N")))
    } else {
      if (newItem.remain_tc_seq > 0 && newItem.chart_type !== 'F') {
        // 잔여 시술 => 당일 오더 
        newItem.remain_times = newItem.total_times - newItem.used_times
        newItem.current_times = 0;
        setRemains((prev) => [...prev, newItem]);
      } else if (newItem.chart_type === 'F') {
        // 잔여시술에 서비스 시술로 추가된 경우
        const serviceRemain = remains.findIndex(remain => remain.pack_seq === newItem.pack_seq && remain.goods_seq === newItem.goods_seq && remain.used_times !== 0)
        const tempArr = [...remains]
        if (serviceRemain !== -1) {
          tempArr[serviceRemain].remain_times += newItem.current_times
          tempArr[serviceRemain].used_times = 0
          tempArr[serviceRemain].current_times = 0
          setRemains(tempArr)
        } else {
          newItem.remain_times = newItem.current_times
          newItem.current_times = 0
          newItem.service_YN = 'Y'
          newItem.buy_order_seq = 0
          setRemains([...tempArr, newItem])
        }

      }

    }

    const newPaymentItems = paymentItems.filter((item, index) => {
      if (samePackExist.length > 1) {
        return !paymentItems.slice(0, index).some(li => li.pack_seq === item.pack_seq && li.goods_seq === item.goods_seq);
      } else if (samePackExist.length === 1 && newItem.goods_type === 'P') {
        return item.pack_seq !== newItem.pack_seq;
      } else {
        return item.id ? item.id !== newItem.id : item.goods_seq !== newItem.goods_seq;
      }
    })

    setPaymentItems(newPaymentItems)

    if (newItem.from === 'S') { // 시술 검색 => 당일 오더
      const totalGoodsPrice = newPaymentItems.reduce((sum, item) => sum + item.goods_price, 0);

      setTotalPrice(totalGoodsPrice)
    }


    const newTodayOrder = list.filter((item, index) => {
      if (samePackExist.length > 1) {
        return !list.slice(0, index).some(li => li.pack_seq === item.pack_seq && li.goods_seq === item.goods_seq);
      } else if (samePackExist.length === 1 && newItem.goods_type === 'P') {
        return item.pack_seq !== newItem.pack_seq;
      } else {
        return item !== newItem;
      }
    })
    setTodayOrders(newTodayOrder)
  }

  // 당일 오더 사용할 회차 +
  const plusUseTimes = (e) => {
    const newTodayOrders = [...list];
    const newTodayItem = newTodayOrders[e.target.id];

    newTodayItem.current_times = Math.min(newTodayItem.current_times + 1, newTodayItem.total_times - newTodayItem.used_times);

    const matchedIndex = remains.findIndex(item =>
      item.pack_seq === newTodayItem.pack_seq &&
      item.goods_seq === newTodayItem.goods_seq &&
      (
        (newTodayItem.order_seq > 0 && item.buy_order_seq === newTodayItem.order_seq) ||
        (newTodayItem.remain_tc_seq === 0 && item.id === newTodayItem.id) ||
        (newTodayItem.remain_tc_seq > 0 && item.remain_tc_seq === newTodayItem.remain_tc_seq)
      )
    );

    if (matchedIndex !== -1) {
      remains[matchedIndex].remain_times = newTodayItem.remain_times;

      if (newTodayItem.current_times === newTodayItem.total_times - newTodayItem.used_times) {
        remains.splice(matchedIndex, 1); // 사용할 회차 0일 경우 잔여 시술 또는 당일 오더에서 삭제
      }
    }

    setRemains([...remains]);
    setTodayOrders([...newTodayOrders]);
  }

  // 당일 오더 사용할 회차 -
  const minusUseTimes = (e) => {
    const newTodayOrders = [...list];
    const newTodayItem = newTodayOrders[e.target.id];

    newTodayItem.current_times = (newTodayItem.current_times > 0) ? newTodayItem.current_times - 1 : 0;

    if (newTodayItem.order_seq > 0) { // 잔여시술 => 당일 오더
      newTodayItem.remain_times = newTodayItem.total_times - newTodayItem.current_times;

    } else { // 시술 검색 => 당일 오더
      newTodayItem.remain_tc_seq = newTodayItem.remain_tc_seq || 0;
      newTodayItem.remain_times = newTodayItem.total_times - newTodayItem.current_times;
      newTodayItem.id = `${newTodayOrders.length}|${e.target.id}`; // 동일 시술 구별 값 추가 
    }

    const matchedIndex = remains.findIndex(item =>
      item.pack_seq === newTodayItem.pack_seq &&
      item.goods_seq === newTodayItem.goods_seq &&
      (
        (newTodayItem.order_seq > 0 && item.buy_order_seq === newTodayItem.order_seq) ||
        (newTodayItem.remain_tc_seq === 0 && item.id === newTodayItem.id) ||
        (newTodayItem.remain_tc_seq > 0 && item.remain_tc_seq === newTodayItem.remain_tc_seq)
      )
    );

    if (matchedIndex !== -1) {
      remains[matchedIndex].remain_times = newTodayItem.remain_times;
      // remains[matchedIndex].used_times -= 1;
    } else {
      setRemains([...remains, { ...newTodayItem, buy_order_seq: newTodayItem.order_seq > 0 ? newTodayItem.order_seq : newTodayItem.buy_order_seq }]);
    }

    if (newTodayItem.current_times === 0) {
      newTodayOrders.splice(e.target.id, 1); // 사용할 회차 0일 경우 당일 오더에서 삭제
    }

    setTodayOrders(newTodayOrders);
  }

  return (
    <>
      {
        list?.map((item, index) => {
          return (
            <section key={item.goods_seq + '_main' + index} className="list">
              <ul key={item.goods_seq + '_item'} className="row">
                <li key={item.goods_seq} className="td tit">
                  <button className="com_btn lightgray m icon"><i id={index} className="svg_icon icon_x gray" onClick={(e) => deleteItem(e)}>&nbsp;</i></button>
                  {item.goods_name}
                </li>
                <li key={item.goods_seq + '_price'} className="td price">{item.goods_price ? new Intl.NumberFormat().format(item.goods_price) : 0}</li>
                <li key={item.goods_seq + '_state'} className="td state">{CHART_TYPE[item.chart_type]}</li>
                <li key={item.goods_seq + '_time'} className="td time">{`${/* item.remain_times ? item.remain_times :  */item.total_times - item.used_times}/${item.total_times}`}</li>
                <li key={item.goods_seq + '_use'} className="td use">
                  <div className="com_btn_amount">
                    <button className="com_btn icon m"><i id={index} onClick={plusUseTimes} className="svg_icon icon_plus">&nbsp;</i></button>
                    {item.current_times}
                    <button className="com_btn icon m"><i id={index} onClick={minusUseTimes} className="svg_icon icon_minus">&nbsp;</i></button>
                  </div>
                </li>
              </ul>
              <ul key={item.goods_seq + '_comment'} className="row">
                <li className="td write">{item.comment}<button><i id={index} onClick={onPopupClick} className="svg_icon icon_write yellow">&nbsp;</i></button></li>
              </ul>
            </section>
          )
        })
      }
      {popup.note && <ExplanAdd treatment={treatment} onClose={() => { onClosePop('note'); }} setTodayOrders={setTodayOrders} userData={userData} managerInfo={managerInfo} />}
    </>
  );
}

const RemainTreatmentList = ({ list, setRemains, isClicked, todayOrders, setTodayOrders }) => {

  const onChangeCheck = (e, index) => {
    const isChecked = e.target.checked;

    const newArr = [...list];
    newArr[index].checked = isChecked;
    setRemains(newArr);

  }
  // 잔여시술 회차 +
  const plusUseTimes = (e) => {
    list[e.target.id].current_times = (list[e.target.id].current_times < list[e.target.id].remain_times) ? list[e.target.id].current_times + 1 : list[e.target.id].remain_times;
    setRemains([...list]);
  }

  // 잔여시술 회차 -
  const minusUseTimes = (e) => {
    list[e.target.id].current_times = (list[e.target.id].current_times >= list[e.target.id].remain_times) ? list[e.target.id].current_times - 1 : 0;
    setRemains([...list]);
  }

  // 잔여시술 => 당일 오더 (오더 이동)
  const moveTodayOrders = (index) => {

    const newTodayItem = list[index]; // 선택한 잔여 시술 항목
    const newTodayOrders = [...todayOrders]
    console.log(newTodayItem.used_times)

    const matchedIndex = newTodayOrders.findIndex(order =>
      newTodayItem.pack_seq === order.pack_seq &&
      newTodayItem.goods_seq === order.goods_seq &&
      (
        ((newTodayItem.order_seq > 0 || newTodayItem.buy_order_seq > 0) && newTodayItem.buy_order_seq === order.order_seq) ||
        (newTodayItem.remain_tc_seq === 0 && newTodayItem.id === order.id) ||
        (newTodayItem.remain_tc_seq > 0 && newTodayItem.remain_tc_seq === order.remain_tc_seq)
      )
    );

    if (matchedIndex !== -1) {
      newTodayOrders[matchedIndex].remain_times = 0;
      newTodayOrders[matchedIndex].current_times = newTodayItem.total_times - newTodayItem.used_times;
    } else {
      newTodayOrders.push({
        ...newTodayItem,
        remain_times: 0,
        current_times: newTodayItem.total_times - newTodayItem.used_times,
      });
    }
    const newRemainArr = [...list];
    newRemainArr.splice(index, 1);
    setRemains(newRemainArr);

    setTodayOrders(newTodayOrders);
  }

  return (
    list?.map((item, index) => {
      return (
        <tr key={/* item.sub_tc_seq */index}>
          <td key={/* item.sub_tc_seq */ index + '_move'}><button className="btn_move" onClick={() => { moveTodayOrders(index) }}>&nbsp;</button></td>
          <td key={/* item.sub_tc_seq */ index + '_name'} className="tit bold">{item.goods_name}</td>
          <td key={/* item.sub_tc_seq */ index + '_gubun'}>{CHART_TYPE[item.chart_type]}</td>
          <td key={/* item.sub_tc_seq */ index + '_remain_times'}>
            {!isClicked ? `${item.remain_times}/${item.total_times}` :
              <div className="com_btn_amount">
                <button className="com_btn icon m"><i id={index} onClick={plusUseTimes} className="svg_icon icon_plus">&nbsp;</i></button>
                {`${item.current_times ? item.current_times : 0}`}
                <button className="com_btn icon m"><i id={index} onClick={minusUseTimes} className="svg_icon icon_minus">&nbsp;</i></button>
              </div>}
          </td>
          <td key={item.sub_tc_seq + '_check'}>
            <fieldset className="com_check_wrap">
              <input type="checkbox" className="checkbox" id={`rt-chk-${index}`} checked={item.checked ? true : false} onChange={(e) => onChangeCheck(e, index)} />
              <label className="btn_checkbox" htmlFor={`rt-chk-${index}`}>&nbsp;</label>
            </fieldset>
          </td>
        </tr>
      );
    }
    ))
}

const ChartRight = ({ managerInfo, displayScreen, addedItems, userData, setUserData, paymentItems, setPaymentItems, todayOrders, setTodayOrders, remains, setRemains, totalPrice, setTotalPrice, getTodayOrderInfo, orderInfo }) => {
  const stateCustomer = useSelector(state => state.Customer);
  const [popup, setPopup] = useState({});
  const [couponPrice, setCouponPrice] = useState(0);
  const [pointPrice, setPointPrice] = useState(0);
  const dispatch = useDispatch();
  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => { },
    id: '',
  });

  const [isClicked, setIsClicked] = useState(false); // 잔여시술 컴포넌트 > 회차수정 버튼 클릭 상태
  const [treatment, setTreatment] = useState({});

  const [taxGoods, setTaxGoods] = useState([]);
  const [taxFreeGoods, setTaxFreeGoods] = useState([]);
  const [payTreatment, setPayTreatment] = useState({});

  const prescriptionInit = {
    prescription_use: "", // 처방전 용도
    prescription_date: "", // 교부일
    period_amount: "", // 기간
    medical_history_yn: "N", // 주증상 및 병력 제외
    resident_no_yn: "N", // 처방전 주민번호 출력
    including_injections_yn: "N", // 처방전 원내 주사약 포함
    disease_code: "" // 질병코드 추가
  }
  const [prescriptionInfo, setPrescriptionInfo] = useState(prescriptionInit) // 처방전 데이터

  const [medicineSetList, setMedicineSetList] = useState([]) // 세트처방 리스트

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  }

  const onPopupClick = (e) => {

    if (nvl(userData.user_seq) === "") {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })

      return
    } else if (e.target.id === 'handOver' && !remains.some(el => el.checked === true)) { // 양도하기 전 시술 선택 필수
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '양도할 시술을 선택해 주세요.', alertType: 'alert' } })

      return
    }
    setPopup((prev) => { return { ...prev, [e.target.id]: true } })

  }

  const onSaveClick = () => {
    console.log(addedItems.treatments)

    if ((nvl(addedItems?.treatments) === "" || addedItems?.treatments.length < 1) && (nvl(todayOrders) === "" || todayOrders.length < 1)) {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '선택된 시술 상품이 없습니다.', alertType: 'alert' } });

      return
    }

    const today = getTodayDate('hyphen');

    let params = {
      today: today,
      userSeq: userData.user_seq,
      treatmentList: addedItems.treatments,
      todayOrders: todayOrders,
      reg_manager_seq: managerInfo.seq,
      remainsTc: remains, // 잔여시술 추가
      paymentsTc: paymentItems // 결제시술 추가
    }


    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // console.log("저장 완료")
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '당일 오더를 저장했습니다.', alertType: 'alert' } });
      } else if (response.messageCode === 997) {
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '접수 먼저 해주세요.', alertType: 'alert' } });
      } else {
        console.log('Error >> ', response);
      }
    }

    request.post('/api/charts/order/today', params, successHandler);
  }

  // added by psk 20230602 - 오더 확인 (결제가 완료되지 않은 상태에서 오더 진행. 상태는 결제완료)
  const todayOrderConfirm = (e) => {
    if ((nvl(addedItems?.treatments) === "" || addedItems?.treatments.length < 1) && (nvl(todayOrders) === "" || todayOrders.length < 1)) {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '선택된 시술 상품이 없습니다.', alertType: 'alert' } });

      return
    }

    const today = getTodayDate('hyphen');

    let params = {
      today: today,
      userSeq: userData.user_seq,
      treatmentList: addedItems.treatments,
      todayOrders: todayOrders,
      reg_manager_seq: managerInfo.seq,
      couponPrice: couponPrice,
      pointPrice: pointPrice,
      remainsTc: remains, // 잔여시술 추가
      paymentsTc: paymentItems // 결제시술 추가

    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // setTodayOrders([])
        dispatch({ type: "setPaymentInfo", payload: { fieldName: "point_price", data: addedItems.point } });
        dispatch({ type: "setPaymentInfo", payload: { fieldName: "coupon_price", data: addedItems.coupon } });
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '오더를 확인했습니다.', alertType: 'alert' } });

        getTodayOrderInfo(stateCustomer.user_seq)
      } else if (response.messageCode === 997) {
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '접수 먼저 해주세요.', alertType: 'alert' } });
      } else {
        console.log('Error >> ', response);
      }
    }

    request.post('/api/charts/order/confirm', params, successHandler);

  }

  const paymentActivate = (e, order_status) => {
    if (order_status < "013005000") {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '오더확인 후 결제를 진행해주세요.', alertType: 'alert' } })
    } else {
      onPopupClick(e);
    }
  }

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertPop((prev) => { return { ...prev, visible: false } })
  }

  const onClickModifyButton = () => {
    if (nvlNumber(userData.user_seq) > 0) {
      setIsClicked((prev) => prev = !prev);

      // 수정한 회차 저장하기
      if (isClicked) {
        const updatedRemains = remains.filter(item => item.current_times > 0)
        // 잔여시술 변경 회차 적용
        const newRemain = remains.filter(el => el.current_times === 0 || el.current_times !== el.remain_times).map(item => ({
          ...item,
          remain_times: item.remain_times - item.current_times,
          // used_times: item.total_times - item.current_times,
          current_times: 0
        }))

        setRemains(newRemain)

        // 당일 오더 변경 회차 적용
        const newTodayOrders = [...todayOrders]
        updatedRemains.forEach((remain, index) => {
          const matchedIndex = newTodayOrders.findIndex(order =>
            remain.pack_seq === order.pack_seq &&
            remain.goods_seq === order.goods_seq &&
            (
              (remain.remain_tc_seq > 0 && remain.remain_tc_seq === order.remain_tc_seq) ||
              (remain.remain_tc_seq === 0 && remain.id === order.id) ||
              (remain.remain_tc_seq > 0 && remain.remain_tc_seq === order.remain_tc_seq)
            )
          );
          if (matchedIndex !== -1) {
            newTodayOrders[matchedIndex].remain_times -= remain.current_times;
            newTodayOrders[matchedIndex].current_times += remain.current_times;
          } else {
            newTodayOrders.push({
              ...remain,
              remain_times: remain.remain_times - remain.current_times,
              current_times: remain.current_times,
            });
          }
        });
        setTodayOrders(newTodayOrders);
      }
    } else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
  }
  useEffect(() => {
    const today = new Intl.DateTimeFormat('kr').format(new Date()).replaceAll('.', ',');
    const orderArr = [];
    const paymentArr = [];

    addedItems.treatments.forEach((item) => {
      orderArr.push({
        sub_tc_seq: null,
        pack_seq: item.pack_seq,
        goods_seq: item.goods_seq,
        goods_type: item.p_goods_type, /*  */
        goods_name: item.goods_name,
        goods_price: vatCalculator(item.p_goods_type, item.p_vat_type, item.pack_sale_price, item.goods_sale_price, item.vat_type, item.goods_price), // item.goods_price,
        chart_type: 'B', // 구매(B), 부분양도(T), 무료시술(F), 결제(P), 예약(R)
        current_times: item.treatment_times, // +, - 버튼에 의해 조절 될 수 있는 회차 >> used_times <= current_times <= total_times
        used_times: 0, // 시술검색에서 넘어온 경우 0회차가 default, 잔여시술에서 넘어온 경우 사용한 회차가 default
        total_times: item.treatment_times,
        vat_type: item.vat_type,
        insurance_type: item.insurance_type,
        reg_date: today, // 시술상품 구매 일
        comment: item.comment,
        from: 'S',
        buy_order_seq: 0,
        last_order_seq: 0
      });
      paymentArr.push({
        order_seq: null,
        pack_seq: item.pack_seq,
        goods_seq: item.goods_seq,
        goods_type: item.goods_type,
        goods_name: item.goods_name,
        goods_price: vatCalculator(item.p_goods_type, item.p_vat_type, item.pack_sale_price, item.goods_sale_price, item.vat_type, item.goods_price),
        goods_sale_price: vatCalculator(item.p_goods_type, item.p_vat_type, item.pack_sale_price, item.goods_sale_price, item.vat_type, item.goods_price),
        insurance_type: item.insurance_type,
        vat_type: item.vat_type,
        pay_yn: "N",
        pay_seq: null,
        pay_tc_no: null,
        reg_dtm: today,
      })
    });

    setTotalPrice((prev) => prev + addedItems.total_price);
    setPointPrice((prev) => prev + addedItems.point);
    setTodayOrders((prev) => prev.concat(orderArr));
    setPaymentItems((prev) => prev.concat(paymentArr))


  }, [addedItems]);

  // added by psk 20230608 - 투데이 오더 관리 (가 오더)
  const newOrderManager = (today, userSeq, treatmentList) => {

    const successHandler = (response) => {
      if (response.messageCode === 200) console.log("처리완료");
      else console.log("처리중 문제 발생");
    }

    request.post('/api/charts/order/today', { today: today, userSeq: userSeq, treatmentList: treatmentList, reg_manager_seq: managerInfo.seq }, successHandler);
  }

  useEffect(() => {
    setCouponPrice((prev) => (nvlNumber(prev) === 0 ? 0 : prev) + nvlNumber(addedItems.coupon.coupon_price) === 0 ? (nvlNumber(addedItems?.coupon?.discount_rate)) === 0 ? 0 : Number((totalPrice) * (addedItems?.coupon?.discount_rate / 100)) : Number(addedItems?.coupon?.coupon_price));
  }, [totalPrice, addedItems])

  const getTreatmentPaytInfo = (order_seq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setTaxGoods(response.data.tax_goods)
        setTaxFreeGoods(response.data.tax_free_goods)
        setPayTreatment(response.data)
      } else {
        console.log("결제할 시술 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/payment/treatment?order_seq=${order_seq}`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    if (nvl(orderInfo?.order_seq) !== "") {
      getTreatmentPaytInfo(orderInfo.order_seq);
    }else{
      setTaxFreeGoods([]);
      setTaxGoods([]);
    }
  }, [orderInfo, stateCustomer.user_seq])


  return (
    <>
      <div className="com_content__inner">
        {
          displayScreen?.today_order_display_yn === 'Y' &&
          <section className="com_sec sec_order">
            <hgroup className="com_sec__hgroup">
              <h1 className="com_sec__title">당일 오더</h1>
            </hgroup>
            <div className="cont_box">
              <div className="scroll">
                <article className="order_list">
                  <section className="list">
                    <ul className="row">
                      <li className="th tit">시술명</li>
                      <li className="th price">금액</li>
                      <li className="th state">구분</li>
                      <li className="th time">회차</li>
                      <li className="th use">사용할 회차</li>
                    </ul>
                  </section>
                  <TodayOrderList list={todayOrders} setTodayOrders={setTodayOrders} setTotalPrice={setTotalPrice} CHART_TYPE={CHART_TYPE} popup={popup} onClosePop={onClosePop} setPopup={setPopup} remains={remains}
                    setRemains={setRemains} userData={userData} managerInfo={managerInfo} treatment={treatment} setTreatment={setTreatment} paymentItems={paymentItems} setPaymentItems={setPaymentItems} />
                </article>
              </div>
              <div className="wrap">
                <div className="btn_area">
                  <button className="com_btn blue l line" id='order' onClick={onPopupClick}><i className="svg_icon icon_print yellow">&nbsp;</i>오더 인쇄</button>
                  <button className="com_btn blue m" id='document' onClick={(e) => paymentActivate(e, orderInfo?.order_status)}>처방전</button>
                </div>
                <ul className="com_input_section payment">
                  <li className="tit_field">총 금액</li>
                  <li className="input_field">{new Intl.NumberFormat().format(totalPrice)}원</li>
                  <li className="tit_field">- 쿠폰할인 금액</li>
                  <li className="input_field">
                    <input type="text" className="input" value={`${nvl(couponPrice) !== "" ? new Intl.NumberFormat().format(couponPrice) : 0}원`} readOnly={true} />
                  </li>
                  <li className="tit_field">-포인트 결제 금액</li>
                  <li className="input_field">
                    <input type="text" className="input" value={`${nvl(pointPrice) !== "" ? new Intl.NumberFormat().format(pointPrice) : 0}원`} readOnly={true} />
                  </li>
                  <li className="tit_field total">결제 금액</li>
                  <li className="input_field total">
                    <span>{new Intl.NumberFormat().format(totalPrice - couponPrice - pointPrice)}원</span>
                    {/* <span className="red_txt">결제 완료</span> */}
                  </li>
                  <li className="tit_field total">실결제 금액</li>
                  <li className="input_field total red">
                    <span>{new Intl.NumberFormat().format(totalPrice - couponPrice - pointPrice)}</span>{/*230420*/}
                    {/* <span className="red_txt">결제 완료</span> */}
                  </li>
                </ul>
              </div>
              <div className="wrap">
                <button className="com_btn blue m" onClick={onSaveClick}>저장</button>
                {/* <button className="com_btn red m division" onClick={payBill} id="pay">{totalPrice - couponPrice - pointPrice > 0 ? '결제하기' : '오더확인'}</button> */}
                <button className="com_btn red m division" onClick={todayOrderConfirm} id="pay">오더확인</button>
                <button className="com_btn red m" id="paymentDetail" onClick={(e) => paymentActivate(e, orderInfo?.order_status)}>결제하기</button>
              </div>
            </div>
          </section>
        }
        {displayScreen?.treatment_display_yn === 'Y' &&
          <section className="com_sec sec_leftover">
            <hgroup className="com_sec__hgroup">
              <h1 className="com_sec__title">잔여 시술</h1>
              <button className="com_btn blue m" id='addTreatments' onClick={onPopupClick}><i className="svg_icon icon_add yellow">&nbsp;</i>서비스 시술추가</button>
              <button className="com_btn blue line m" id='handOver' onClick={onPopupClick}>양도하기</button>
              <button className={isClicked ? "com_btn blue m" : "com_btn blue line m"} onClick={onClickModifyButton}>
                {isClicked ? '회차저장' : '회차수정'}
              </button>
            </hgroup>
            <div className="cont_box">
              <article className="com_list">
                <div className="scroll">
                  <table className="list">
                    <colgroup><col width="71px" /><col /><col width="13.4%" /><col width="98px" /><col width="8%" /></colgroup>
                    <thead>
                      <tr>
                        <th>오더 이동</th>
                        <th>시술명</th>
                        <th>구분</th>
                        <th>회차</th>
                        <th>선택</th>
                      </tr>
                    </thead>
                    <tbody>
                      <RemainTreatmentList list={remains} CHART_TYPE={CHART_TYPE} setRemains={setRemains} isClicked={isClicked}
                        todayOrders={todayOrders} setTodayOrders={setTodayOrders} />
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
          </section>
        }
        {popup.order && <OrderPrint onClose={() => { onClosePop('order'); }} userData={userData} popup={popup} setPopup={setPopup} managerInfo={managerInfo} orderInfo={orderInfo} todayOrders={todayOrders} setTodayOrders={setTodayOrders} />}
        {popup.addTreatments && <ServiceProcedureAdd onClose={() => { onClosePop('addTreatments'); }} setRemains={setRemains} />}
        {popup.handOver && <Handover onClose={() => { onClosePop('handOver'); }} remains={remains} setRemains={setRemains} />}
        {popup.paymentDetail && <PaymentDetail onClose={() => { onClosePop('paymentDetail'); }} setAlertPop={setAlertPop} userData={userData} orderInfo={orderInfo} payTreatment={payTreatment} taxGoods={taxGoods} taxFreeGoods={taxFreeGoods} prescriptionInfo={prescriptionInfo} setPrescriptionInfo={setPrescriptionInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList} userInfo={userData} setUserInfo={setUserData}/>}
        {popup.document && <Document onClose={() => { onClosePop('document'); }} btnValue={6} prescriptionInfo={prescriptionInfo} setPrescriptionInfo={setPrescriptionInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList} userInfo={userData} setUserInfo={setUserData}/>}
      </div>
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
    </>
  )
}

export default ChartRight