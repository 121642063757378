//* 매출결산
import React, { useState } from "react";
import CustomAlert from "atoms/common/CustomAlert";
import moment from 'moment';
import PopupCalendar from "atoms/common/PopupCalendar";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

const Calc = () => {
  const [viewType, setViewType] = useState('');
  const [dateType, setDateType] = useState('hyphen');
  const [isPaid, setIsPaid] = useState(false);
  const [isPayFinished, setIsPayFinished] = useState(false);
  const stateToday = useSelector(state => state.Today)
  const [startDate, setStartDate] = useState(stateToday);

  const [payInfo, setPayInfo] = useState({
    payment_date: stateToday,
    payment_method: '',
    bank_account: ''
  });

  const [finishInfo, setFinishInfo] = useState({
    payment_date: stateToday,
    payment_method: '',
    bank_account: ''
  });


  const calculateDate = (symbols, value) => {
    if (symbols === 'add') {
      const date = new Date(value);
      date.setMonth(date.getMonth() - 1);
      const formattedDate = date.toISOString().slice(0, 7);
      setStartDate(formattedDate);
    } else if (symbols === 'sub') {
      const date = new Date(value);
      date.setMonth(date.getMonth() + 1);
      const formattedDate = date.toISOString().slice(0, 7);
      setStartDate(formattedDate);
    }
  }

  const popupCalendarHTML = () => {
    let retArr = [];

    if(viewType === 'from' || viewType === 'finish') {
      retArr.push(<PopupCalendar onClose={ closePopup } getCalendar={ getCalendar } key={ uuidv4() } dateType={ dateType } />)
    } else retArr.push(null);

    return retArr;
  }

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const closePopup = () => {
    setViewType('');
  }

  const getCalendar = (value) => {

    if (value <= stateToday) setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "오늘 이후의 날짜를 선택해주세요." } })
    else {
      if(viewType === "from"){
        setPayInfo({ ...payInfo, payment_date: value })
      }else{
        setFinishInfo({ ...finishInfo, payment_date: value })
      }
    }
  }



  const payHandler = () => {
    setIsPaid(!isPaid);
  };

  const payFinishHandler = () => {
    setIsPayFinished(!isPayFinished);
  };


	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <a href="/sales" className="com_btn m blue">매출결산</a>
              <a href="/sales/account_manage" className="com_btn m lightgray">계좌 관리</a>
            </div>
          </article>
          <article className="com_lnb2_right">
            <p className="account">본사 입금계좌 : 농협 / 465-6542123-6541321 ㈜올리프트</p>
            <p className="account">출금 계좌 : 하나 / 465-6542123-6541321 대전지점</p>
          </article>
        </div>
      </section>
      <section className="com_content">
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="tit">미납된 매출목록</p>
            </div>
          </article>
          <table className="list">
            <colgroup><col/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/></colgroup>
            <thead>
              <tr>
                <th colSpan="8">2022년 3월<span className="divisionBar">&nbsp;</span>매출요약</th>
              </tr>
              <tr>
                <th>분류</th>
                <th>보험본인부담금</th>
                <th>비과세결제액</th>
                <th>과세 총결제액</th>
                <th>과세결제액</th>
                <th>부가세</th>
                <th>합계</th>
                <th>월 납부 입금액 (10%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>현금매출합계</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td></td>
              </tr>
              <tr>
                <th>카드매출합계</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td></td>
              </tr>
              <tr>
                <th>기타매출합계</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td></td>
              </tr>
              <tr className="total">
                <th>매출 합계</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_red">0</span></td>
                <td><span className="txt_red">0</span></td>
              </tr>
            </tbody>
          </table>
          <article className="detail">
            <div className="wrap">
              <ul className="scroll detail_contents">
                <li className="item">
                  <p className="info">2022, 08, 05 본사 관리자</p>
                  안녕하세요 키리 본사입니다. 3월 15일까지 납부 하셔야 합니다. 감사합니다.
                </li>
                <li className="item">
                  <p className="info">2022, 08, 05 본사 관리자</p>
                  안녕하세요 키리 본사입니다. 3월 15일까지 납부 하셔야 합니다. 감사합니다.
                </li>
                <li className="item">
                  <p className="info">2022, 08, 05 본사 관리자</p>
                  안녕하세요 키리 본사입니다. 3월 15일까지 납부 하셔야 합니다. 감사합니다.
                </li>
              </ul>
            </div>
            <div className="wrap">
              <ul className="com_input_section">
                <li className="tit_field">납부일</li>
                <li className="input_field"><input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name="payment_date" value={payInfo.payment_date} onClick={() => {setViewType('from')}} readOnly style={{ backgroundColor: 'transparent'}} /></li>{popupCalendarHTML()}
                <li className="tit_field">납부방법</li>
                <li className="input_field bank">
                  <input type="text" className="input" placeholder="입금자 명"/>
                  <input type="text" className="input" placeholder="입금한 통장계좌번호"/>
                </li>
              </ul>
            </div>
            <button className={isPaid ? "com_btn gray l" : "com_btn blue l"} onClick={payHandler}>납부<br/>하기</button>
          </article>
        </div>
        <hr className="com_line"/>
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="tit">납부 완료된 매출목록</p>
            </div>
            <article className="com_select_date">
              <button className="com_btn" onClick={() => { calculateDate('add', startDate) }}><i className="svg_icon icon_prev white">&nbsp;</i></button>
              <div className="date" value={viewType}>{startDate}</div>
              <button className="com_btn" onClick={() => { calculateDate('sub', startDate) }}><i className="svg_icon icon_next white">&nbsp;</i></button>
            </article>
            <article></article>
          </article>
          <table className="list">
            <colgroup><col/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/><col width="11.2%"/></colgroup>
            <thead>
              <tr>
                <th colSpan="8">{moment(startDate).format("YYYY년 MM월")}<span className="divisionBar">&nbsp;</span>매출요약</th>
              </tr>
              <tr>
                <th>분류</th>
                <th>보험본인부담금</th>
                <th>비과세결제액</th>
                <th>과세 총결제액</th>
                <th>과세결제액</th>
                <th>부가세</th>
                <th>합계</th>
                <th>월 납부 입금액 (10%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>현금매출합계</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td></td>
              </tr>
              <tr>
                <th>카드매출합계</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td></td>
              </tr>
              <tr>
                <th>기타매출합계</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td></td>
              </tr>
              <tr className="total">
                <th>매출 합계</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_red">0</span></td>
                <td><span className="txt_red">0</span></td>
              </tr>
            </tbody>
          </table>
          <article className="detail">
            <div className="wrap">
              <ul className="scroll detail_contents">
                <li className="item">
                  <p className="info">2022, 08, 05 본사 관리자</p>
                  안녕하세요 키리 본사입니다. 3월 15일까지 납부 하셔야 합니다. 감사합니다.
                </li>
                <li className="item">
                  <p className="info">2022, 08, 05 본사 관리자</p>
                  안녕하세요 키리 본사입니다. 3월 15일까지 납부 하셔야 합니다. 감사합니다.
                </li>
                <li className="item">
                  <p className="info">2022, 08, 05 본사 관리자</p>
                  안녕하세요 키리 본사입니다. 3월 15일까지 납부 하셔야 합니다. 감사합니다.
                </li>
              </ul>
            </div>
            <div className="wrap">
              <ul className="com_input_section">
                <li className="tit_field">납부일</li>
                <li className="input_field"><input type="text" className="input datepicker" id="finish" placeholder="2023. 01. 01" name="payment_finish_date" value={finishInfo.payment_date} onClick={() => {setViewType('finish')}} readOnly style={{ backgroundColor: 'transparent'}} /></li>{popupCalendarHTML()}
                <li className="tit_field">납부방법</li>
                <li className="input_field bank">
                  <input type="text" className="input" placeholder="입금자 명"/>
                  <input type="text" className="input" placeholder="입금한 통장계좌번호"/>
                </li>
              </ul>
            </div>
            <button className={isPayFinished ? "com_btn gray l" : "com_btn blue l"} onClick={payFinishHandler}>납부<br/>완료</button>
          </article>
        </div>
      </section>
      { alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={alertLayerPopup.returnValue} /> }
		</>
	)
}

export default Calc