import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers';
import ReduxThunk from 'redux-thunk';
import logger from 'redux-logger';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

const store = configureStore({
    reducer: rootReducer,
    // middleware: [ReduxThunk, logger],
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] } }),
    // devTools: true,
    // preloadedState: {}
});

export default store;