import { SET_CUSTOMER, CLEAR_CUSTOMER } from './ActionType';

const stateCustomerInit = {
  user_seq: 0,
  user_name: '',
  chart_code: '',
  user_gender: '',
  user_dob: '',
  user_age: 0,
  user_level: '',
  user_level_color: '',
  user_status: '',
  interest_area: '',
  admissive_channel: '',
  main_tc_seq: 0,
  sub_tc_seq: 0
}

const stateCustomer = (state = stateCustomerInit, action) => {
  let { type } = action;

  switch (type) {
    case SET_CUSTOMER:
      let newState = {
        ...state,
        [action.payload.fieldName]: action.payload.data
      };

      return newState;

    case CLEAR_CUSTOMER:
      return stateCustomerInit;

    default:
      return state;
  }
}

export default stateCustomer;