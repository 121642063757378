//* 진료비관리
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import { nvl } from "../../utils/Common.utils";
import CustomAlert from "atoms/common/CustomAlert";

const DiseaseAdd = ({ onClose, getDiseaseCodeList, diseaseList }) => {
  const nodeRef = useRef(null);
  const [onUpdateBool, setOnUpdateBool] = useState(false);
  const [diseaseInfo, setDiseaseInfo] = useState({});
  const [diseaseData, setDiseaseData] = useState({});
  const [diseaseSeq, setDiseaseSeq] = useState('');
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "" } })
  }

  const diseaseCodeHandler = (e, num) => {
    const {name, value} = e.target;
    num === 1 ? setDiseaseInfo(prev => ({...prev, [name]: value})) : setDiseaseData(prev => ({...prev, [name]: value}));
  }

  
  const regDisaseCode = () => {
    if(!onUpdateBool){ 
      if (nvl(diseaseInfo.disease_code) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "질병코드를 입력해주세요." } })
        return;
      } else if (nvl(diseaseInfo.disease_name) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "질병명을 입력해주세요." } })
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "질병코드를 등록하시겠습니까?", activateMode: "regDiseaseCode"}});
    }else{
      if (nvl(diseaseData.disease_code) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "질병코드를 입력해주세요." } })
        return;
      } else if (nvl(diseaseData.disease_name) === '') {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "질병명을 입력해주세요." } })
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "질병코드를 수정하시겠습니까?", activateMode: "updateDiseaseCode"}});
    }
  }


  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (!onUpdateBool && alertLayerPopup.activateMode === "regDiseaseCode") {
        let params = {
          disease_code: diseaseInfo.disease_code,
          disease_name: diseaseInfo.disease_name,
          use_yn: "Y",
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "질병코드가 등록되었습니다.",activateMode:''}});
            setDiseaseInfo({
              disease_code: '',
              disease_name: ''
            });
            getDiseaseCodeList();
          } else {
            console.log("질병코드를 등록하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/disease", params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "updateDiseaseCode") {
        let params = {
          disease_seq: diseaseSeq,
          disease_code: diseaseData.disease_code,
          disease_name: diseaseData.disease_name,
          modi_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "질병코드가 수정되었습니다.",activateMode:''}});
            getDiseaseCodeList();
          } else {
            console.log("질병코드를 수정하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.put(`/api/disease/${diseaseSeq}/`, params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "deleteDiseaseCode") {

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "질병코드가 삭제되었습니다.",activateMode:''}});
            setDiseaseData({
              disease_code: '',
              disease_name: ''
            });
            getDiseaseCodeList();
          } else {
            console.log("질병코드를 삭제하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.del(`/api/disease/${diseaseSeq}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
      }
    }
  }



  const onDetailClick = (e, disease_seq) => {
    if(disease_seq === diseaseSeq){
      setDiseaseSeq('')
      setOnUpdateBool(false);
      e.target.checked = false;
      setDiseaseInfo((prev) => ({...prev, disease_code: '', disease_name: ''}))
    }else{
      setDiseaseSeq(disease_seq);
      const copyData = diseaseList.filter(el => el.disease_seq === disease_seq);
      const data = [...copyData][0]
      setDiseaseData({
        disease_code: data.disease_code,
        disease_name: data.disease_name
      });
      setOnUpdateBool(true);
    }
  }

  const delDisaseCode = () => {
    
    if (nvl(diseaseData.disease_code) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "질병코드를 입력해주세요." } })
      return;
    } else if (nvl(diseaseData.disease_name) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "질병명을 입력해주세요." } })
      return;
    }
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "질병코드를 삭제하시겠습니까?", activateMode: "deleteDiseaseCode"}});
  }



	return (
		<>
      <section className="com_popup type_normal pop_medifee active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">질병코드 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">  
              <fieldset className="add name_sec">
                <div className="form_area">
                  <div className="input_wrap">
                    <input type="text" className="input" placeholder="질병코드 입력" name="disease_code" value={!onUpdateBool ? diseaseInfo.disease_code : diseaseData.disease_code} onChange={(e) => !onUpdateBool ? diseaseCodeHandler(e,1) : diseaseCodeHandler(e,2) } />
                    <input type="text" className="input" placeholder="질병명 입력" name="disease_name" value={!onUpdateBool ? diseaseInfo.disease_name : diseaseData.disease_name} onChange={(e) => !onUpdateBool ? diseaseCodeHandler(e,1) : diseaseCodeHandler(e,2)}/>
                  </div>
                  <button type="button" className="com_btn m blue" onClick={regDisaseCode}>{!onUpdateBool ? "등록" : "변경"}</button>
                </div>
              </fieldset>
              <article className="list">
                <ul className="item_wrap scroll">
                  {diseaseList.length !== 0 ? diseaseList.map((el, i) => (
                    <li className="item"  key={i}>
                      <fieldset className="com_check_wrap">
                        <input type="radio" className="radio" id={`radio${i}`} name="radio" value={el.disease_code || ""} onClick={(e) => onDetailClick(e, el.disease_seq)}/>
                        <label className="btn_radio" htmlFor={`radio${i}`}>&nbsp;</label>
                        <label className="txt" htmlFor={`radio${i}`}>{el.disease_code} {el.disease_name}</label>
                      </fieldset>
                    </li>
                    )) : 
                    <li className="item no_data">등록된 질병이 없습니다.</li>
                    }
                  </ul>
                
              </article>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={delDisaseCode}>삭제</button>
            </div>
          </div>
        </Draggable>
      </section>
      { alertLayerPopup.visible && <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> }
		</>
	)
}

export default DiseaseAdd