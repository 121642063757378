import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import SignaturePad from 'signature_pad';
import request from '../../utils/Request.utils';
import CustomAlert from "atoms/common/CustomAlert.js";
import { nvl } from "../../utils/Common.utils";

import '../../../src/styles/scss/component/_popupSign.scss';

let signaturePad = null;
let signed = false;

export default function PopupSign ({ selectedContent, setSelectedContent }) {
  const stateCustomer = useSelector(state => state.Customer);

  const [alertPop, setAlertPop] = useState({
		visible: false,
		alertMessage: '',
		alertType: 'alert'
	});

  // added by psk 20230403 - 화면 이동 후에도 싸인 화면이 가운데 오게 작업
  const popupSignWrap = useRef()

  // modified by psk 20230403 - 마우스 포인터와 그려지는 부분의 위치 버그 수정
  useEffect(() => {
    bodyScrollDisable(); // 백그라운드가 딤처리가 되었을 때 스크롤 방지
    
    const getCanvas = document.querySelector('canvas');

    let getScrollTop = window.scrollY;

    popupSignWrap.current.style.cssText = `
        margin-top: ${getScrollTop}px;
    `;

    signaturePad = new SignaturePad(getCanvas);
    signaturePad.minWidth = 1;
    signaturePad.maxWidth = 2;
    // signaturePad.penColor = "rgb(66, 133, 244)";
    signaturePad.penColor = "rgb(0, 0, 0)";
    signaturePad.backgroundColor = "rgb(255, 255, 255)";
    
    // Triggered before stroke begins
    signaturePad.addEventListener("beginStroke", () => {
      signed = true;
      // console.log("그리기 시작");
    }, { once: false }); // true : 한 번만, false : 계속

    // Triggered after stroke ends
    signaturePad.addEventListener("endStroke", () => {
      // console.log("그리기 끝");
    }, { once: false }); // true : 한 번만, false : 계속

    // Triggered before stroke update
    signaturePad.addEventListener("beforeUpdateStroke", () => {
      //console.log("업데이트 되기 전");
    }, { once: false }); // true : 한 번만, false : 계속

    // Triggered after stroke update
    signaturePad.addEventListener("beforeUpdateStroke", () => {
      //console.log("업데이트 된 후");
    }, { once: false }); // true : 한 번만, false : 계속

    window.addEventListener("resize", resizeCanvas(getCanvas)); // 이벤트 등록하고

    resizeCanvas(getCanvas); // 실행도 함 하고

    return () => { // 일단 cleanup 부분은 필요 없을듯...
      // // Clears the canvas
      // if(!signaturePad.isEmpty()) signaturePad.clear();

      // // Unbinds all event handlers
      // signaturePad.off();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resizeCanvas = (getCanvas) => {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);

    getCanvas.width = getCanvas.offsetWidth * ratio;
    getCanvas.height = getCanvas.offsetHeight * ratio;
    getCanvas.getContext("2d").scale(ratio, ratio);
    signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }
  
  const onClickConfirm = () => {
    if(signed === false) {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '서명을 하지 않으셨습니다.', alertType: 'alert' } })	

      return;
    }

    // added by psk 20230403 - 서명을 완료하면 서명 종류 별로 바로 저장하기로 함
    const params = {
      file_cate: selectedContent.file_cate,
      sign_data: signaturePad.toDataURL('image/png'),
      user_seq: stateCustomer?.user_seq
    }

    const successHandler = (response) => {
      signed = false;

      if(response.messageCode === 200) { // ok 코드 값
        setSelectedContent((prev) => { return { ...prev, sign_seq: response.data.signSeq, up_file_path: response.data.up_file_path } })

        customClose();
      } else if(response.messageCode === 400) {
        console.log("잘못된 요청입니다.\n다시 시도해 주십시오.");
      } else {
        console.log("서명 이미지를 저장하던 중 에러가 발생했습니다.\n관리자에게 문의해 주십시오.");
      }
    }

    // request.postMultipart("/api/upload/sign", params, successHandler);
    request.post("/api/upload/signbase64", params, successHandler);
  }

  const customClose = () => { // 체크박스 그냥 두고 닫기
    bodyScrollEnable();

    signed = false;

    setSelectedContent((prev) => { return { ...prev, visible: false } })
  }

  const initClose = () => { // 체크박스 해제 하면서 닫기
      bodyScrollEnable();

      signed = false;

      setSelectedContent((prev) => { return { ...prev, agree_yn: '', visible: false } })
  }

  const clearCanvas = () => {
      signed = false;

      signaturePad.clear();
  }

  const bodyScrollDisable = () => {
    document.body.classList.add('disable-scroll');
    document.body.classList.remove('enable-scroll');
  }

  const bodyScrollEnable = () => {
    document.body.classList.remove('disable-scroll');
    document.body.classList.add('enable-scroll');
  }

	const closeCustomAlert = (() => {
		setAlertPop((prev) => { return { ...prev, visible: false } })
	});

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      //
    }
  }

  return(
    <div ref={popupSignWrap} className='PopupSign'>
      <div className='inner'>
        <div className='innerTitle'>서명해주세요</div>
        <canvas className='canvas'/>
        <div className='com_btn_wrap'>
          <button className='confirmButton com_btn blue m' type='button' onClick={ () => { onClickConfirm() } } >확인</button>
          <button className='confirmButton com_btn blue m' type='button' onClick={ () => { clearCanvas() } } >다시서명</button>
          <button className='cancelButton com_btn gray m' type='button' onClick={ () => { initClose() } } >닫기</button>
        </div>
      </div>
      { alertPop.visible && <CustomAlert onClose={ closeCustomAlert } alertType={ alertPop.alertType } alertMessage={ alertPop.alertMessage } returnValue={ returnAlertValue } /> }
    </div>
  )
}
