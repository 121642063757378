import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import Footer from '../layout/Footer.js';
import Notice from "../components/notice/Notice.js";
import NoticeWrite from "../components/notice/NoticeWrite.js";
import Today from "../components/notice/Today.js";
import request from '../utils/Request.utils';

const NoticeRoute = () => {
  let { pathname } = window.location

  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    let authBool = false;

    authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

    if(authBool) {
      const authRoutes = [
        { path: "/", element: <Notice /> },
        { path: "/write", element: <NoticeWrite /> },
        { path: "/today", element: <Today /> }
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/", element: <Navigate to='/main/login' /> },
        { path: "/*", element: <Navigate to='/main/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

	return (
		<>
			<Gnb />
      <main>
        <Lnb />
        <Routes> { AuthorizationRoutes() } </Routes>
      </main>
			<Footer />
		</>
	)
}

export default NoticeRoute