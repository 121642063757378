export const nvl = (getObject) => {
  if (getObject !== null && getObject !== undefined && getObject !== "undefined") return getObject;

  return "";
}

export const nvlNumber = (getObject) => {
  if (getObject !== null && getObject !== undefined && getObject !== "undefined" && checkNumber(getObject)) return Number(getObject);

  return 0;
}

export const nvlOnlyNumber = (getObject) => {
  if (getObject !== null && getObject !== undefined && getObject !== "undefined" && checkOnlyNumber(getObject)) return getObject;

  return 0;
}

// modified by psk 20230117
export const checkNumber = (str) => { // 음수 허용
  var pattern = /[0-9-]/g; // 숫자
  var returnStr = "";

  returnStr = str.toString().replaceAll(pattern, '');

  if (returnStr !== "") return false;
  else return true;
}

export const checkOnlyNumber = (str) => {
  var pattern = /[0-9]/g; // 숫자
  var returnStr = "";

  returnStr = str.toString().replaceAll(pattern, '');

  if (returnStr !== "") return false;
  else return true;
}

export const checkAlphabet = (str) => {
  var pattern = /^[a-zA-Z]+$/; // 영문 소문자, 대문자

  return pattern.test(str);
}

export const checkPhoneNumber = (str) => {
  var pattern = /^[0-9-]+$/; // 숫자, 하이픈

  return pattern.test(str);
}

export const checkKoreanAlphabetNumber = (str) => {
  var pattern = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/; // 한글, 영문 소문자, 대문자, 숫자

  return pattern.test(str);
}

export const checkIdPattern = (str, len) => {
  var pattern1 = /[0-9a-zA-Z]/g; // 영문 + 숫자
  var returnStr = "";

  // console.log("str : " + str);

  returnStr = str.replaceAll(pattern1, '');

  if (returnStr !== "" || str.length < len) return false;
  else return true;
}

export const checkPasswordPatternCascade = (str, len) => {
  var passPoint = 0;

  var pattern1 = /[0-9]/; // 숫자
  var pattern2 = /[a-z]/; // 영문 소문자
  var pattern3 = /[A-Z]/; // 영문 대문자
  var pattern4 = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자 

  if (pattern1.test(str)) passPoint++;
  if (pattern2.test(str)) passPoint++;
  if (pattern3.test(str)) passPoint++;
  if (pattern4.test(str)) passPoint++;

  if (passPoint < 2 || str.length < len) return false;
  else return true;
}

export const checkPasswordPattern = (str, len) => {
  var pattern1 = /[0-9a-zA-Z~!@#$%<>^&*]/g; // 영문 + 숫자 + 특수문자
  var returnStr = "";

  returnStr = str.replaceAll(pattern1, '');

  if (returnStr !== "" || str.length < len) return false;
  else return true;
}

export const validateEmail = (email) => {
  var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

  return re.test(email);
}

// added by psk 20221229 - 커스텀 컨펌 (이 프로젝트에서 안 씀)
// const customConfirmCancel = (message, onConfirm, onCancel) => {
//   if(!onConfirm || typeof onConfirm !== "function") return;
//   if(onCancel && typeof onCancel !== "function") return;

//   if(window.confirm(message)) onConfirm();
//   else onCancel();
// }

// const customConfirmOnly = (message, onConfirm) => {
//   if(!onConfirm || typeof onConfirm !== "function") return;

//   if(window.confirm(message)) onConfirm();
//   else console.log("취소용~~~");
// }

// added by psk 20230109 - 날짜 관련 함수 추가
let now = new Date();

export const getYearYYYY = () => {
  // console.log(now.getFullYear())

  return now.getFullYear();
}

export const getMonthMM = () => {
  const currentMonth = (now.getMonth() + 1) < 10 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1);

  // console.log(currentMonth);

  return currentMonth;
}

export const getDateDD = () => {
  const currentDate = now.getDate() < 10 ? '0' + (now.getDate()) : now.getDate();

  // console.log(currentDate);

  return currentDate
}

// added by psk 20230112 - 요일 구하기
export const getDayOfWeek = () => {
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = week[new Date().getDay()];

  return dayOfWeek;
};

// added by psk 20230109 - 서버 OS
export const getPlatformOS = () => {
  if (/^win/.test(process.platform)) return "win";
  else return process.platform;
}

// added by psk 20230109 - OS 별 슬래시
export const getFileSeparator = () => {
  if (getPlatformOS() === "win") return "\\";
  else return "/";
}

// added by psk 20230109 - 제약사 랜덤 코드 생성  // wem 20230111 - 약품 랜덤 코드로 수정, 무조건 8자리로 
export const createMedicineCode = (min, max) => { // min <= number <= max
  // let getCode = Math.floor(Math.random() * 100000000);

  // return String(getCode).padStart(8, "0"); 
  return String(Math.floor(Math.random() * (max - min) + min)).padStart(8, "0");
}

// added by psk 20230109 - 차트번호 랜덤 코드 생성
export const createChartCode = (min, max) => { // min <= number <= max
  const alphabets = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const randomAlphabet = alphabets[Math.floor(Math.random() * alphabets.length)] + alphabets[Math.floor(Math.random() * alphabets.length)] + alphabets[Math.floor(Math.random() * alphabets.length)];
  const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

  return String(getYearYYYY()).substring(2, 4) + getMonthMM() + getDateDD() + "-" + randomAlphabet + randomNum;
}

/** 암호화된 주민번호를 만나이 변환
 * input   암호화된 주민번호
 * output  만나이
 * added by jskim 2023-01-12 */
export const convertJuminToAge = (jumin) => {
  // 복호화된 주민번호 형식: 920901-1000000
  const today = new Date();

  const result = jumin?.replace('-', '').substring(0, 7);
  const baseValue = result?.slice(-1); // 주민번호 뒤에 첫자리 숫자
  const text = baseValue < 3 ? '19' : '20'; // 1900년대 생과 2000년대생 한국인만 고려함.
  const year = parseInt(text + result?.substring(0, 2), 10);
  const month = parseInt(result?.substring(2, 4), 10);

  const todayMonth = today?.getMonth() + 1; // 0부터 시작
  const todayYear = today?.getFullYear();
  const todayDate = today?.getDate();
  const monthDiff = todayMonth - month;

  let age = 0;
  if (monthDiff > 0) {
    age = todayYear - year;
  } else if (monthDiff === 0) {
    const date = parseInt(result?.substring(4, 6), 10);
    const dateDiff = todayDate - date;
    if (dateDiff <= 0) age = todayYear - year;
    else age = todayYear - year - 1;
  } else {
    age = todayYear - year - 1;
  }

  return age;
}

// added by psk 20230303 - birthday to age
export const birthdayToAge = (birthday) => {
  let bDay = new Date(birthday);

  let distance = now.getTime() - bDay.getTime();
  let daysOld = Math.floor(distance / (1000 * 60 * 60 * 24));
  let yearsOld = Number((daysOld / 365).toFixed(0))

  return yearsOld
}

// added by psk 20230117 - 숫자에 콤마 넣기
export const addComma = (getNumber) => {
  if(getNumber !== undefined){
    return getNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return 0;
  }
}

// added by psk 20230118 - 성별 한글 변환
export const convertGender = (gender) => {
  switch (gender) {
    case 'F':
      return '여'

    case 'M':
      return '남'

    default:
      return ''
  }
}

// added by jin 20230212 전화번호에 - 넣기
export const changeMobileFormat = (mbNumber) => {
  if (mbNumber === undefined || mbNumber === "" || mbNumber === null) return "";
  return mbNumber.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
}

// added by jin 20230212 오늘 날짜 구하기
export const getTodayDate = (value) => {
  let now = new Date();
  let todayYear = now.getFullYear();
  let todayMonth = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
  let todayDate = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토', '일'];
  let dayOfWeek = week[now.getDay()];
  let hours = now.getHours() < 10 ? `${now.getHours()}` : now.getHours();
  let minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();

  if (value === "time") {
    return `${todayYear}. ${todayMonth}. ${todayDate}. ${hours} : ${minutes}`;
  } else if (value === "hyphen") {
    return `${todayYear}-${todayMonth}-${todayDate}`;
  } else {
    return todayYear = `${todayYear}년 ${todayMonth}월 ${todayDate}일 (${dayOfWeek})`;
  }
}

// 일주일 뒤 날짜 구하기
export const getNextWeekDate = (value) => {
  let now = new Date();
  let nextWeek = new Date(now.getTime() + 6 * 24 * 60 * 60 * 1000);

  let nextWeekYear = nextWeek.getFullYear();
  let nextWeekMonth = nextWeek.getMonth() + 1 < 10 ? `0${nextWeek.getMonth() + 1}` : nextWeek.getMonth() + 1;
  let nextWeekDate = nextWeek.getDate() < 10 ? `0${nextWeek.getDate()}` : nextWeek.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  let nextWeekDayOfWeek = week[nextWeek.getDay()];

  if (value === 'time') {
    let hours = nextWeek.getHours() < 10 ? `${nextWeek.getHours()}` : nextWeek.getHours();
    let minutes = nextWeek.getMinutes() < 10 ? `0${nextWeek.getMinutes()}` : nextWeek.getMinutes();
    return `${nextWeekYear}. ${nextWeekMonth}. ${nextWeekDate}. ${hours} : ${minutes}`;
  } else {
    return `${nextWeekYear}년 ${nextWeekMonth}월 ${nextWeekDate}일 (${nextWeekDayOfWeek})`;
  }
}

// 한달 뒤 날짜 구하기
export const getNextMonthDate = (value) => {
  let now = new Date();
  let nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()); // 현재 날짜를 기준으로 다음 달의 날짜를 구함

  let nextMonthYear = nextMonth.getFullYear();
  let nextMonthMonth = nextMonth.getMonth() + 1 < 10 ? `0${nextMonth.getMonth() + 1}` : nextMonth.getMonth() + 1;
  let nextMonthDate = nextMonth.getDate() < 10 ? `0${nextMonth.getDate()}` : nextMonth.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  let nextMonthDayOfWeek = week[nextMonth.getDay()];

  if (value === 'time') {
    let hours = nextMonth.getHours() < 10 ? `${nextMonth.getHours()}` : nextMonth.getHours();
    let minutes = nextMonth.getMinutes() < 10 ? `0${nextMonth.getMinutes()}` : nextMonth.getMinutes();
    return `${nextMonthYear}. ${nextMonthMonth}. ${nextMonthDate}. ${hours} : ${minutes}`;
  } else {
    return `${nextMonthYear}년 ${nextMonthMonth}월 ${nextMonthDate}일 (${nextMonthDayOfWeek})`;
  }
}

// 일주일 전 날짜 구하기 - YYYY년MM월DD일(요일)
export const getPreviousWeekDate = (value) => {
  let now = new Date();
  let previousWeek = new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000);

  let previousWeekYear = previousWeek.getFullYear();
  let previousWeekMonth = previousWeek.getMonth() + 1 < 10 ? `0${previousWeek.getMonth() + 1}` : previousWeek.getMonth() + 1;
  let previousWeekDate = previousWeek.getDate() < 10 ? `0${previousWeek.getDate()}` : previousWeek.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  let previousWeekDayOfWeek = week[previousWeek.getDay()];

  if (value === 'time') {
    let hours = previousWeek.getHours() < 10 ? `${previousWeek.getHours()}` : previousWeek.getHours();
    let minutes = previousWeek.getMinutes() < 10 ? `0${previousWeek.getMinutes()}` : previousWeek.getMinutes();
    return `${previousWeekYear}. ${previousWeekMonth}. ${previousWeekDate}. ${hours} : ${minutes}`;
  } else if (value === 'ymd') {
    return `${previousWeekYear}-${previousWeekMonth}-${previousWeekDate}`;
  } else {
    return `${previousWeekYear}년 ${previousWeekMonth}월 ${previousWeekDate}일 (${previousWeekDayOfWeek})`;
  }
}

// 한달 전 날짜 구하기
export const getPreviousMonthDate = (value) => {
  let now = new Date();
  let previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()); // 현재 날짜를 기준으로 이전 달의 날짜를 구함

  let previousMonthYear = previousMonth.getFullYear();
  let previousMonthMonth = previousMonth.getMonth() + 1 < 10 ? `0${previousMonth.getMonth() + 1}` : previousMonth.getMonth() + 1;
  let previousMonthDate = previousMonth.getDate() < 10 ? `0${previousMonth.getDate()}` : previousMonth.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  let previousMonthDayOfWeek = week[previousMonth.getDay()];

  if (value === 'time') {
    let hours = previousMonth.getHours() < 10 ? `${previousMonth.getHours()}` : previousMonth.getHours();
    let minutes = previousMonth.getMinutes() < 10 ? `0${previousMonth.getMinutes()}` : previousMonth.getMinutes();
    return `${previousMonthYear}. ${previousMonthMonth}. ${previousMonthDate}. ${hours} : ${minutes}`;
  } else if (value === 'ymd') {
    return `${previousMonthYear}-${previousMonthMonth}-${previousMonthDate}`;
  } else {
    return `${previousMonthYear}년 ${previousMonthMonth}월 ${previousMonthDate}일 (${previousMonthDayOfWeek})`;
  }
}

// 일년 전 날짜 구하기
export const getPreviousYearDate = (value) => {
  let now = new Date();
  let previousYear = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()); // 현재 날짜를 기준으로 작년의 날짜를 구함

  let previousYearYear = previousYear.getFullYear();
  let previousYearMonth = previousYear.getMonth() + 1 < 10 ? `0${previousYear.getMonth() + 1}` : previousYear.getMonth() + 1;
  let previousYearDate = previousYear.getDate() < 10 ? `0${previousYear.getDate()}` : previousYear.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  let previousYearDayOfWeek = week[previousYear.getDay()];

  if (value === 'time') {
    let hours = previousYear.getHours() < 10 ? `${previousYear.getHours()}` : previousYear.getHours();
    let minutes = previousYear.getMinutes() < 10 ? `0${previousYear.getMinutes()}` : previousYear.getMinutes();
    return `${previousYearYear}. ${previousYearMonth}. ${previousYearDate}. ${hours} : ${minutes}`;
  } else if (value === 'ymd') {
    return `${previousYearYear}-${previousYearMonth}-${previousYearDate}`;
  } else {
    return `${previousYearYear}년 ${previousYearMonth}월 ${previousYearDate}일 (${previousYearDayOfWeek})`;
  }
}

//날짜형식을 스트링으로 반환 - YYYY-MM-DD > YYYY년-m월-d일(요일)
export const convDateToString = (ymd) => {
  let date = new Date(ymd);
  let strYear = date.getFullYear();
  let strMonth = date.getMonth();
  let strDate = date.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  let previousYearDayOfWeek = week[date.getDay()];

  return `${strYear}년 ${strMonth}월 ${strDate}일 (${previousYearDayOfWeek})`;
}

// added by jin 20230214 날짜 더하기
export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

//한글 or 영어로만 구성되어있는지
export const checkKoreanAlphabet = input => /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣]+$/.test(input);

// added by psk 20230329 - Strip tag
export const removeTags = (value) => {
  if (nvl(value) === '') return '';

  value = value.toString();

  return value.replace(/(<([^>]+)>)/ig, '');
}

export const generateRandomNumber = () => {
  const randomNumber = Math.floor(Math.random() * 100000000);
  const formattedNumber = String(randomNumber).padStart(8, '0');

  return formattedNumber;
}

export const vatCalculator = (pGoodsType, pVatType, packSalePrice, goodsSalePrice, vatType, goodsPrice) => {
  let returnPrice = pGoodsType === 'P' && nvl(pVatType) === "I" ? nvlNumber(packSalePrice) + nvlNumber(packSalePrice) * 0.1
    :
    pGoodsType === 'P' && nvl(pVatType) !== "I" ? nvlNumber(packSalePrice)
      :
      nvlNumber(goodsSalePrice) === 0 && nvl(vatType) === "I" ? nvlNumber(goodsPrice) + nvlNumber(goodsPrice) * 0.1
        :
        nvlNumber(goodsSalePrice) === 0 && nvl(vatType) !== "I" ? nvlNumber(goodsPrice)
          :
          nvlNumber(goodsSalePrice) !== 0 && nvl(vatType) === "I" ? nvlNumber(goodsSalePrice) + nvlNumber(goodsSalePrice) * 0.1
            :
            nvlNumber(goodsSalePrice)

  return returnPrice
}

// added by wem 20230804  - 접수 리스트 정렬
export const sortByOrderStatus = (orderStatus, active, receptionList) => {
  if (active) {
    const sortedList = receptionList.slice().sort((a, b) => {
      const aStatus = a.order_status;
      const bStatus = b.order_status;

      if (aStatus === orderStatus && bStatus !== orderStatus) {
        return -1;
      } else if (aStatus !== orderStatus && bStatus === orderStatus) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedList;
  } else {
    return [...receptionList].sort((a, b) => a.order_seq - b.order_seq);
  }
}

const commonUtils = {
  nvl,
  checkPasswordPattern,
  validateEmail,
  checkNumber,
  checkOnlyNumber,
  checkAlphabet,
  checkPhoneNumber,
  // customConfirmCancel,
  // customConfirmOnly,
  getYearYYYY,
  getMonthMM,
  getDateDD,
  getDayOfWeek,
  getPlatformOS,
  getFileSeparator,
  checkKoreanAlphabetNumber,
  createMedicineCode,
  createChartCode,
  checkIdPattern,
  checkPasswordPatternCascade,
  convertJuminToAge,
  addComma,
  nvlNumber,
  nvlOnlyNumber,
  convertGender,
  changeMobileFormat,
  getTodayDate,
  getNextWeekDate,
  getNextMonthDate,
  addDays,
  birthdayToAge,
  checkKoreanAlphabet,
  removeTags,
  getPreviousWeekDate,
  getPreviousMonthDate,
  getPreviousYearDate,
  generateRandomNumber,
  vatCalculator,
  sortByOrderStatus
}
