import React, { useState, useEffect } from "react";
import moment from 'moment';
import request from "utils/Request.utils";
import { nvl, nvlNumber } from "utils/Common.utils";

const CreditCard = ({ creditCardInfo, creditCardActivate, setCreditCardActivate, setAlertPop, returnResult }) => {
  moment.locale('ko-KR');

  const currentDate = moment(new Date()).format("YYYY년 MM월 DD일");
  const cardURL = process.env.REACT_APP_CREDIT_CARD_URL;

  const kscatInfoInit = {
    taRequest: '', // 요청
    taResponse: '', // 응답
    txtCatApprDate: '', // 승인일자
    txtCatApprNo: '', // 승인번호
    txtMsg: '' // 결과 값
  }

  const [kscatInfo, setKscatInfo] = useState(kscatInfoInit)

  // 신용 승인 ------------------
  const CatCredit = () => {
    let request_msg = "";

    if(nvlNumber(creditCardInfo.totalPrice) > 0) {
      // 전문 길이 마지막에 입력
      request_msg += "A1"; // 전문구분코드(a1:신용승인) 알파벳 대문자 : 전표인쇄, 소문자:전표인쇄 안함
      request_msg += "00"; // 할부개월 (installment)
      request_msg += " "; // 현금영수증 소비자 구분
      request_msg += " "; // 현금영수증 취소 사유
      request_msg += String(creditCardInfo.totalPrice).padStart(9, '0');; // 총금액 (totalPrice)
      request_msg += "000000091"; // 세금 (tax)
      request_msg += "000000000"; // 면세금액 (taxFreePrice)
      request_msg += "000000000"; // 봉사료 (serviceCharge)
      request_msg += "      "; // 원거래 일시 (currentDate)
      request_msg += "            "; // 원거래 승인번호 (이건 뭐지?)
      request_msg += "0001"; // 거래일련번호
      request_msg += "           "; // 사용자 식별번호
      request_msg += String.fromCharCode(3); // ETX

      // var telegramLen = ("" + request_msg.length).fillZero(4); // 길이
      let telegramLen = ("0000" + request_msg.length).slice(-4); // 길이

      console.log("telegramLen", telegramLen)

      request_msg = String.fromCharCode(2) + telegramLen + request_msg; // STX 추가 + 전문 길이 + 전송 전문
      request_msg = 'CC' + request_msg; // CC : CAT 단말기 제어

      setKscatInfo((prev) => { return { ...prev, taRequest: request_msg } })
    }

    return request_msg
  }

  // 신용 취소 ------------------
  function CatCreditCancel() {
    let request_msg = "";

    // 전문 길이 마지막에 입력
    request_msg += "B1"; // 전문구분코드
    request_msg += "00"; // 할부개월
    request_msg += " "; // 현금영수증 소비자 구분
    request_msg += " "; // 현금영수증 취소 사유
    request_msg += "000001004"; // 총금액
    request_msg += "000000091"; // 세금
    request_msg += "000000000"; // 면세금액
    request_msg += "000000000"; // 봉사료
    request_msg += document.getElementById("txtCatApprDate").value.fillSpace(6); // 원거래 일시
    request_msg += document.getElementById("txtCatApprNo").value.fillSpace(12); // 원거래 승인번호
    request_msg += "0002"; // 거래일련번호 <== timestamp 값으로 해도 될듯
    request_msg += "           "; // 사용자 식별번호
    request_msg += String.fromCharCode(3); // ETX

    // var telegramLen = ("" + request_msg.length).fillZero(4); // 길이
    let telegramLen = ("0000" + request_msg.length).slice(-4); // 길이

    request_msg = String.fromCharCode(2) + telegramLen + request_msg; // STX 추가 + 전문 길이 + 전송 전문

    document.getElementById("taRequest").value = "CC" + request_msg;
  }

  // 통신 ------------------
  const Communication = (requestMsg) => {
    const successHandler = (response) => {
      console.log("response 확인", response) // response을 json object로 바로 사용?
      // console.log("결과 확인", JSONtoString(response))

      // console.log(response, response.RES)


      let trans = kscatInfo.taRequest.substring(0, 2);

      console.log("trans 확인", trans)

      if (FindJSONtoString("RES", response) !== "0000") {
        console.log('RES:', FindJSONtoString("MSG", response));

        setKscatInfo((prev) => { return { ...prev, txtMsg: FindJSONtoString("MSG", response) } })
      } else setKscatInfo((prev) => { return { ...prev, txtMsg: '' } })

      if (trans === "CC") {
        // 정상 처리인 경우에만 Fair가 맞으면서.. 전문구분 코드가 NE가 아닌 경우.. (a1으로 전송 시 a1으로 응답)
        if (FindJSONtoString("REQ", response) === "CC" && FindJSONtoString("RES", response) === "0000" && FindJSONtoString("TELEGRAMFLAG", response) !== "NE") {
          console.log("승인일자", FindJSONtoString("APPROVALTIME", response).substring(0, 6)); // 승인일자

          setKscatInfo((prev) => { return { ...prev, txtCatApprDate: FindJSONtoString("APPROVALTIME", response).substring(0, 6) } })

          // --------------------------------------------------------------------------

          console.log("승인번호", FindJSONtoString("APPROVALNO", response));

          setKscatInfo((prev) => { return { ...prev, txtCatApprNo: FindJSONtoString("APPROVALNO", response) } })
        }
      }

      setKscatInfo((prev) => { return { ...prev, taResponse: JSONtoString(response) } })



    }

    request.jsonpFunc(`${cardURL}?REQ=${requestMsg}`, null, successHandler);
  }

  const FindJSONtoString = (Key, object) => {
    let results = ""
    let value

    for (var property in object) {
      value = object[property];

      if (value) {
        if (Key === property.toString()) {
          results = value;

          break;
        }
      }
    }

    return results;
  }

  const JSONtoString = (object) => {
    let results = [];
    var value

    for (var property in object) {
      value = object[property];

      if (value) results.push(property.toString() + ': ' + value);
    }
    
    return '{' + results.join(', ') + '}';
  }

  useEffect(() => {
    if(creditCardActivate.credit) { // 카드 승인
      if(nvl(CatCredit()) !== '') Communication(CatCredit());

      setCreditCardActivate(false);
    } else if(creditCardActivate.creditCancel) { // 카드 취소
      console.log('카드 취소')
    } else if(creditCardActivate.cash) { // 현금 승인
      console.log('현금 승인')
    } else if(creditCardActivate.cashCancel) { // 현금 취소
      console.log('현금 취소')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardActivate]);
}




export default CreditCard