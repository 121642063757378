//* 일간 예약자 목록 (오늘 날짜 선택시)
import React, { useState, useRef } from "react";
import moment from "moment";
import Draggable from "react-draggable";
import "moment/locale/ko";
import { convertJuminToAge, convertGender, nvl } from "utils/Common.utils";
import { decrypt } from "utils/aes256.manager";
import SnsSend from "components/common/_SnsSend";


const DayList = (props) => {
  const today = props.reservationDateList[0]
  const [checkAll, setCheckAll] = useState(false)
  const [snsSendBool, setSnsSendBool] = useState(false);

  const nodeRef = useRef(null);

  const [checkedList, setCheckedList] = useState([])

  // 전체 선택
  const checkedAllList = (e) => {
    if (e.target.checked) {
      setCheckAll(!checkAll)
      const checkArray = [];
      props.reservationDateList.forEach(reserve => checkArray.push(reserve))
      setCheckedList(checkArray)
    } else {
      setCheckAll(!checkAll)
      setCheckedList([])
    }
  }

  // 개별 선택
  const checkedElement = (e, reserve) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, reserve])
    } else {
      setCheckedList(checkedList.filter(el => el !== reserve))
    }
  }

  // 예약 상태 표시
  const changeToStatus = (today) => {
    if (parseInt(today.reser_time.split(":")[0]) < 12 && today.reser_status !== "C") return "오전 예약"
    if (parseInt(today.reser_time.split(":")[0]) > 12 && today.reser_status !== "C") return "오후 예약"
    if (today.reser_status === "C") return "예약 취소"
    if (today.reser_status === "R" && today.modi_date !== null) return "예약 변경"
  }

  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_reservation_day active ">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => props.setReserPopup(false)}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">{moment(today?.reser_date).format("YYYY,MM, DD(dd)")}</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <article className="top_area">
                <fieldset className="com_check_wrap">
                  <input type="checkbox" className="checkbox" id="chk1" onChange={(e) => checkedAllList(e)}
                    checked={checkedList.length === 0 ? false : checkedList.length === props.reservationDateList.length && checkAll ? true : false
                    } />
                  <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                  <label htmlFor="chk1">{`${changeToStatus(today)}(${props.reservationDateList.length})`}</label>
                </fieldset>
                <button className="com_btn line yellow m icon" onClick={() => setSnsSendBool(!snsSendBool)}><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
              </article>
              <article className="list">
                <ul className="item_wrap scroll">
                  {props.reservationDateList !== null && props.reservationDateList.map((el, index) => (
                    <li className="item" key={el.reser_seq + "reser" + index}>
                      <fieldset className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id={el.reser_seq + "reser" + index} onChange={(e) => checkedElement(e, el)}
                          checked={checkedList.includes(el)} />
                        <label className="btn_checkbox" htmlFor={el.reser_seq + "reser" + index}>&nbsp;</label>
                        <label htmlFor={el.reser_seq + "reser" + index}>{index + 1}. {el.user_name} ({convertGender(el.user_gender)}/{nvl(el.user_jumin) === "" ? " - " : convertJuminToAge((decrypt(el.user_jumin).substring(0, 6) + "-" + decrypt(el.user_jumin).substring(6, 13)))}) {/* <span className="txt_red">15분</span>  */}({el.reser_time})</label>
                      </fieldset>
                    </li>
                  ))}
                </ul>
              </article>
            </div>
          </div>
        </Draggable>
      </section>
      {snsSendBool && <SnsSend snsSendBool={snsSendBool} setSnsSendBool={setSnsSendBool} checkedList={checkedList} />}
    </>
  )
}

export default DayList
