import React, { useState, useRef, useEffect } from "react";
import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';
import { nvl } from "../../utils/Common.utils";
import PopupCalendar from '../common/PopupCalendar';
import CustomAlert from "atoms/common/CustomAlert";
import { getPreviousWeekDate, getPreviousMonthDate, getPreviousYearDate } from "../../utils/Common.utils";

import 'react-calendar/dist/Calendar.css'

const StatisticsSubDateSearch2 = ({searchHandler, startDate, endDate, setStartDate, setEndDate}) => {
  let buttonsInfo = [
    { text: "오늘", value: 0 },
    { text: "주간", value: 1 },
    { text: "월간", value: 2 },
    { text: "연간", value: 3 }
  ]

  const [buttonNo, setButtonNo] = useState(0);
  const [viewType, setViewType] = useState('');

  const todayDate = moment(new Date()).format("YYYY-MM-DD");

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const popupCalendarHTML = () => {
    let retArr = [];

    if(viewType === 'from' || viewType === 'to') {
      retArr.push(<PopupCalendar onClose={ closePopup } getCalendar={ getCalendar } key={ uuidv4() } dateType="hyphen" />)
    } else retArr.push(null);

    return retArr;
  }

  const closePopup = () =>  {
    setViewType('');
  }

  //기간 유효성 검사
  const getCalendar = (value) => {
    // console.log("get calendarValue : " + value + " | " + dateType + " | " + viewType)
    if(viewType === 'from') setStartDate(value);
    else {
      if(value <= startDate) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시작일보다 나중인 날짜를 선택해주세요." } })
        return false;
      }

      setEndDate(value)   
    }
  }

  //검색기간 종류(오늘/주간/월간/연간) > 클릭
  const clickHandler = (value) => {

    setButtonNo(value);

    let sDate = startDate; 
    let eDate = todayDate;
    if (value === 0){  //오늘
      sDate = todayDate;
    } else if(value === 1){  //주간
      sDate = getPreviousWeekDate('ymd');
    } else if(value === 2){  //월간
      sDate = getPreviousMonthDate('ymd');
    } else if(value === 3){   //연간
      sDate = getPreviousYearDate('ymd');
    }

    setStartDate(sDate);
    setEndDate(eDate);

    searchHandler();
  }

  //조회 버튼
  const periodSearchActivate = () => {
    if(startDate === "" || endDate === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "조회 기간을 모두 선택해주세요" } })

      return;
    }

    searchHandler();
  }

  return (
    <>
      {
        alertLayerPopup.visible ?
          <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={alertLayerPopup.returnValue} />
        : null
      }
      <article className="com_lnb2_right">
        {/*//^ lnb2 검색 영역 */}
        <div className="lnb2_search_wrap">
          <section className="com_field type_normal">
            <fieldset className="many">
              {
                buttonsInfo.map(item => (
                  <button key={"subButton_" + uuidv4()} className={item.value === buttonNo ? "com_btn m blue" : "com_btn m line blue"} onClick={(e) => {clickHandler(item.value)}}>{item.text}</button>
                ))
              }
              <div className="datepicker_from_to">
                <input type="text" className="input datepicker130" id="from" placeholder="2023-01-01" name="startDate" value={startDate} onClick={() => {setViewType('from')}} readOnly />
                {popupCalendarHTML()}
                <span className="txt_between">~</span>
                <input type="text" className="input datepicker130" id="to" placeholder="2023-01-01" name="endDate" value={endDate} onClick={() => {setViewType('to')}} readOnly />
                {popupCalendarHTML()}
              </div>
              <button type="button" className="com_btn m blue" onClick={ () => periodSearchActivate() }>조회</button>
            </fieldset>
          </section>
        </div>
      </article>
    </>
  )
}

export default StatisticsSubDateSearch2