//* 현금 영수증
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import { nvl } from "../../utils/Common.utils";


const CashReceipt = ({ onClose, vatYTotalPrice, vatNTotalPrice, userData }) => {
  const nodeRef = useRef(null);
  const [cashReceiptInfo, setCashReceiptInfo] = useState({
    type:"1",
    jumin: userData.mobile_1+userData.mobile_2+userData.mobile_3,
    self:"1"
  });

  const CashReceiptInfoHandler = (e) => {
    const {name, value} = e.target;
    setCashReceiptInfo((prev) => ({...prev, [name]: value}));
  }


	return (
		<>
      <section className="com_popup type_normal up pop_cash_receipt active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container m">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">현금영수증 발행</h1>
            </div>
            <div className="pop_body">
              <article className="com_view none_round">
                <table className="view">
                  <colgroup><col width="24%"/><col width="24%"/><col/></colgroup>
                  <tbody>
                    <tr>
                      <th>비과세</th>
                      <td>발급 금액</td>
                      <td className="alignR">{nvl(vatYTotalPrice) === "" ? 0 : new Intl.NumberFormat().format(vatYTotalPrice)}원</td>
                    </tr>
                    <tr>
                      <th rowSpan="2">과세</th>
                      <td>발급 금액</td>
                      <td className="alignR">{nvl(vatNTotalPrice) === "" ? 0 : new Intl.NumberFormat().format(vatNTotalPrice)}원</td>
                    </tr>
                    <tr>
                      <td>부가가치세</td>
                      <td className="alignR">{nvl(vatNTotalPrice) === "" ? 0 : new Intl.NumberFormat().format(vatNTotalPrice*0.1)}원</td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <ul className="com_input_section column">
                <li className="tit_field">거래자 구분</li>
                <li className="input_field">
                  <fieldset className="com_radio_wrap" name="type" value={cashReceiptInfo.type} >
                    <input type="radio" className="radio" id="radio1" name="type" value="1" checked={cashReceiptInfo.type === "1"} onChange={CashReceiptInfoHandler}/>
                    <label className="btn_radio" htmlFor="radio1">&nbsp;</label>
                    <label htmlFor="radio1">개인소득공제</label>
                    <input type="radio" className="radio" id="radio2" name="type" value="2" checked={cashReceiptInfo.type === "2"} onChange={CashReceiptInfoHandler}/>
                    <label className="btn_radio" htmlFor="radio2">&nbsp;</label>
                    <label htmlFor="radio2">사업자지출증빙</label>
                  </fieldset>
                </li>
                <li className="tit_field">주민번호(사업자번호 or HP)</li>
                <li className="input_field input_wrap">
                  <input type="text" className="input" placeholder="번호를 입력해 주세요" value={cashReceiptInfo.jumin} name="jumin" onChange={CashReceiptInfoHandler} />
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="radio3" name="radio2"/>
                    <label className="btn_radio" htmlFor="radio3">&nbsp;</label>
                    <label htmlFor="radio3">자진신고</label>
                  </fieldset>
                </li>
              </ul>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn l blue">확인</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default CashReceipt