import axios from 'axios';
import jwt_decode from "jwt-decode";
import jsonp from 'jsonp';
import * as ReactDOM from 'react-dom';

import SessionStorage from './SessionStorage.utils';
import { nvl, nvlNumber } from "./Common.utils";
import LoadingBar from './LoadingBar';

import CryptoJS from 'crypto-js';
import 'whatwg-fetch'

import moment from 'moment';

import { socket, SOCKET_EVENT } from "../components/etc/socket";

moment.locale('ko-KR');

const local = ['localhost', '127.0.0.1'].some((d) => d === window.location.hostname);

let protocol = window.location.protocol;
let hostname = window.location.hostname;
let port = window.location.port === '80' || window.location.port === '443' || window.location.port === '' ? '' : ":" + window.location.port;

let redirectUrl = process.env.REACT_APP_WEB_URL + "/...."; // 외부 api 사용 때 redirect 필요 시 사용
let base = process.env.REACT_APP_API_URL; // api 주소

// added by psk 20230328 - page 이동 시 이전 요청 모두 끊을 때 사용
const source = axios.CancelToken.source();
const requestInstance = axios.create();

const managerSession = SessionStorage.getItemJsonParse("managerSession");

// console.log("토큰 확인 : " + nvl(managerSession?.refreshToken))

// requestInstance.interceptors.request.use(
//   config => {
//     // setLoading(true);

//     // ReactDOM.render(<LoadingBar type={"spin"} color={"#FFFFFF"} active={true} />, document.getElementById("root"));

//     return config;
//   },
//   error => {
//     // setLoading(false);

//     return Promise.reject(error)
//   }
// );

// requestInstance.interceptors.response.use(
//   response => {
//     // setLoading(false);

//     // ReactDOM.render(<LoadingBar type={"spin"} color={"#FFFFFF"} active={false} />, document.getElementById("root"));

//     return response;
//   },
//   error => {
//     // setLoading(false);

//     return Promise.reject(error)
//   }
// );

const axiosConfig = {
  timeout: 5000,
  withCredentials: false,
  headers: {
    "Authorization": `Bearer ${nvl(managerSession?.accessToken)}`,
    "Content-Type": "application/json"
  },
  cancelToken: source.token
};

// ---------------------------------------------

const axiosMultipartConfig = {
  timeout: 5000,
  withCredentials: false,
  headers: {
    "Authorization": `Bearer ${nvl(managerSession?.accessToken)}`,
    "content-type": "multipart/form-data; charset=UTF-8"
  },
  cancelToken: source.token
};

const getBase = () => {
  return base;
}

const getRedirectUrl = () => {
  return redirectUrl;
}

const currentTimeStamp = () => {
  return Date.now().toString();
}

const processSuccess = (response, successHandler) => {
  if (successHandler && typeof successHandler === 'function') {
    try {
      successHandler(response.data);
    } catch (e) {
      console.error(e);
      alert('스크립트 동작 중 오류가 발생했습니다.');
    }
  }
}

const processError = (error, failHandler, goPrecessError) => {
  console.log(error)

  if (!error.response || !error.response.status) console.error('NETWORK ERROR');
  else if (failHandler && typeof failHandler === 'function' && !goPrecessError) failHandler(error, error.response);
  else if (error && error.response) {
    if (goPrecessError && failHandler && typeof failHandler === 'function') failHandler(error, error.response);

    switch (error.response.status) {
      case 400: {
        console.error('요청이 올바르지 않습니다.');
        console.log(error.response.data);

        break;
      }

      case 401: {
        console.error('인증 필요');

        break;
      }

      case 404: {
        console.error('404 NOT FOUND');

        break;
      }

      case 409: {
        console.error('이미 존재하는 값. 중복된 내용이 있는지 확인필요');

        break;
      }

      default: {
        console.error('오류');

        break;
      }
    }
  } else {
    console.error('알 수 없는 오류가 발생했습니다.');
  }
}

const afterRequest = () => {
  // console.log("응답 받고 실행~!! 끝");
}

const get = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .get(`${base}${uri}`, axiosConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
}

const post = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .post(`${base}${uri}`, params, axiosConfig)
    .then((response) => {
      console.log('>>POST::socket.emit(dashboard)');
      socket.emit('dashboard', {room:1, name:'react' });
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
}

// 첨부 파일용
const postMultipart = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .post(`${base}${uri}`, params, axiosMultipartConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
}

const put = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .put(`${base}${uri}`, params, axiosConfig)
    .then((response) => {
      console.log('>>PUT::socket.emit(dashboard)');
      socket.emit('dashboard', {room:1, name:'react' });
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
}

const patch = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .patch(`${base}${uri}`, params, axiosConfig)
    .then((response) => {
      console.log('>>PATCH::socket.emit(dashboard)');
      socket.emit('dashboard', {room:1, name:'react' });
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
}

const del = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .delete(`${base}${uri}`, axiosConfig, { data: params })
    // .delete(`${base}${uri}`, axiosConfig, { params })
    .then((response) => {
      console.log('>>DEL::socket.emit(dashboard)');
      socket.emit('dashboard', {room:1, name:'react' });
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
}

// added by psk 20230502 - jsonp 추가
const jsonpFunc = async (uri, callback, successHandler) => {
  jsonp(uri, { name: callback }, (error, response) => {
    if (error) processError(error);
    else successHandler(response)
  });
}

// added by psk 20230216 - 토큰 유효 체크 (백단에서 체크)
const tokenVerifyFromServer = () => { // token verify
  get("/api/etc/token/validation", null, (response) => {
    if(response.messageCode === 200) return true; // console.log("정상이구만");
    else if(response.messageCode === 401) {
      window.location.href = "/main/login"; // SessionStorage에 저장된 토근이 없는 상태

      return;
    } else if(response.messageCode === 444) { // 토큰이 만료가 된 상태
      put("/api/etc/token/refresh", { refresh: nvl(managerSession?.refreshToken) }, (res) => { // refresh token 으로 access token 재발급
        // console.log(res)

        if(res.messageCode === 200) { // 재발급 된 토근을 로컬에 저장
          SessionStorage.clearItem("managerSession")
          SessionStorage.setItem("managerSession", "accessToken", res.data.accessToken)
          SessionStorage.setItem("managerSession", "refreshToken", res.data.refreshToken)

          return true;
        } else if(res.messageCode === 401) {
            window.location.href = "/main/login"; // SessionStorage에 저장된 토근이 없는 상태

            return;
        }
      });
    } else {
      window.location.href = "/main/login";

      return;
    }

    return false
  });
}

// added by psk 20230322 - 토큰 유효 체크 (로컬에서 체크)
const tokenVerify = (pathname) => {
  const managerToken = tokenDecoder()

  if(managerToken != null && nvlNumber(managerToken?.seq) > 0) {    
    // console.log("만료시간 체크", moment(managerToken?.exp * 1000).format("YYYY-MM-DD HH:mm:ss"), moment(managerToken?.exp * 1000).diff(moment()))

    if(moment(managerToken?.exp * 1000).diff(moment()) < 0) {
      // console.log("만료된 상태");

      put("/api/etc/token/refresh", { refresh: nvl(managerSession?.refreshToken) }, (res) => { // refresh token 으로 access token 재발급
        // console.log(res)

        if(res.messageCode === 200) { // 재발급된 토근을 로컬에 저장
          SessionStorage.clearItem("managerSession")
          SessionStorage.setItem("managerSession", "accessToken", res.data.accessToken)
          SessionStorage.setItem("managerSession", "refreshToken", res.data.refreshToken)

          if(nvl(pathname) !== "" ) {
            window.location.href = pathname;
            return;
          }

          return true;
        }
      })
    } else return true; // accessToken 이 유효한 상태
  }

  return false;
}

const tokenDecoder = () => {
  if(nvl(managerSession?.accessToken) === "") return null;

  return jwt_decode(managerSession?.accessToken);
}

export function waitFor(promises, callback) {
  return axios.all(promises).then(callback);
}

const request = {
  get,
  post,
  put,
  patch,
  del,
  getBase,
  processError,
  waitFor,
  getRedirectUrl,
  postMultipart,
  tokenVerify,
  tokenVerifyFromServer,
  tokenDecoder,
  currentTimeStamp,
  jsonpFunc
};

export default request;