import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import Calc from "../components/sales/Calc.js";//매출결산
import AccountManage from "../components/sales/AccountManage.js";//계좌관리
import request from '../utils/Request.utils';

const SalesRoute = () => {
  let { pathname } = window.location

  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    let authBool = false;

    authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

    if(authBool) {
      const authRoutes = [
        { path: "/", element: <Calc /> },
        { path: "/account_manage", element: <AccountManage /> }
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/", element: <Navigate to='/main/login' /> },
        { path: "/*", element: <Navigate to='/main/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

	return (
		<>
			<Gnb />
      <main>
        <Lnb />
        <Routes> { AuthorizationRoutes() } </Routes>
      </main>
		</>
	)
}

export default SalesRoute