//* 날짜별
import React, { useEffect, useState, useRef } from "react";
import request from "utils/Request.utils";
import CustomerSubButton from "../../atoms/crm/SubButton";
import CustomerSubDateSearch from "../../atoms/crm/SubDateSearch";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { getTodayDate, getPreviousWeekDate, getPreviousMonthDate } from "../../utils/Common.utils";
import { utils, writeFile } from 'xlsx';

const CrmDay = () => {
  const componentRef = useRef();
  const tableRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState(10);
  const [periodOption, setPeriodOption] = useState(null);
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodValue, setPeriodValue] = useState(0);
  const [avg, setAvg] = useState(0); //전체 평균
  const today = getTodayDate()?.slice(0,17);
  const todayDate = moment(new Date()).format("YYYYMMDD");
  const [satisfactionList, setSatisfactionList] = useState([{
    date: '',
    receptionist_point: 0,
    counselor_point: 0,
    incharge_point: 0,
    assist_point: 0,
    treatment_point: 0,
    avg_all_points: 0,
    record_count: 0,
    user_avg: 0
  }]);
  
  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    }else{
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }


  const setPeriodSearchDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('3');
    setPeriodValue(1);
  }
 
  // 고객 만족도 리스트 가져오기
  const getSurveyAvg = () => {
  
    let params = {
      limit: numPerPage,
      offset: ((currentPage - 1) * numPerPage),
      periodOption: periodOption,
      // delete_yn: "N"
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data.dataList.map((el, i) => ({
          date: moment(el.date).utcOffset('+0900').format('YYYY-MM-DD'),
          receptionist_point: el.receptionist_point,
          counselor_point: el.counselor_point,
          incharge_point: el.incharge_point,
          assist_point: el.assist_point,
          treatment_point: el.treatment_point,
          avg_all_points: el.avg_all_points,
          record_count: el.record_count,
          user_avg: (Number(el.receptionist_point) + Number(el.counselor_point) + Number(el.incharge_point) + Number(el.assist_point) + Number(el.treatment_point)) / 5
        }))
        setSatisfactionList(result);
      } else {
        console.log("날짜별 고객 만족도 목록을 가져오지 못했습니다.");
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/etc/satisfaction/by-date?limit=${params.limit}&offset=${params.offset}&periodOption=${params.periodOption}&startDate=${startDate}&endDate=${endDate}`, null, successHandler, failHandler, null, null);
  }

  // 날짜별 고객 만족도 평균 구하기
  const calculateAvg = () => {
    const avgList = satisfactionList.map((el) => (el.avg_all_points));
    const result = avgList.reduce((sum, cur) => {
      return Number(sum) + Number(cur)
    },0);
    setAvg(result / avgList.length);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `날짜별 고객만족도_${todayDate}`,
  });


  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `날짜별 고객만족도_${todayDate}.xlsx`);
  };


  useEffect(()=>{
    getSurveyAvg(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[periodOption, startDate])

  useEffect(()=>{
    calculateAvg(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[satisfactionList])


	return (
		<>
		<section className="section com_lnb2">
			<div className="inner">
        <CustomerSubButton buttonHandler={subButtonAction} />
        <CustomerSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate} periodOption={periodOption}/>
			</div>
		</section>
		<section className="com_content">
      <div ref={el => componentRef.current = el}>
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="date">{periodValue === 0 ?  `${startDate}` : `${startDate} ~ ${endDate}`}</p>
              <p className="num">날짜별 만족도 평균점수 ({isNaN(avg) ? "0.00" : avg.toFixed(2)}점)</p>
            </div>
          </article>
          <div className="scroll with_btn_bottom">
            <table className="list" ref={tableRef}>
              <colgroup><col/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/><col width="7.4%"/></colgroup>
              <thead className="sticky top">
                <tr>
                  <th rowSpan="2">만족도 발송일</th>
                  <th rowSpan="2">발송수</th>
                  <th rowSpan="2">응답수(건)</th>
                  <th colSpan="2">신규</th>
                  <th colSpan="2">재진</th>
                  <th rowSpan="2">만족도</th>
                  <th colSpan="5">직무별</th>
                </tr>
                <tr>
                  <th>고객수</th>
                  <th>응답율</th>
                  <th>고객수</th>
                  <th>응답율</th>
                  <th>접수</th>
                  <th>상담</th>
                  <th>담당의사</th>
                  <th>어시스트</th>
                  <th>에스테틱</th>
                </tr>
              </thead>
              <tbody>
              {satisfactionList.length > 0 ? satisfactionList.map((el,i) => {
                return (
                  <tr key={i}>
                    <td>{el.date}</td>
                    <td>0</td>
                    <td>{el.record_count}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>{el.avg_all_points}</td>
                    <td>{el.receptionist_point}</td>
                    <td>{el.counselor_point}</td>
                    <td>{el.incharge_point}</td>
                    <td>{el.assist_point}</td>
                    <td>{el.treatment_point}</td>
                  </tr>
                );
              }) :
              <tr>
                <td colSpan="13">데이터가 없습니다.</td>
              </tr>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="com_btn_bottom_area">
        <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
        <button type="button" className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
      </div>
		</section>
		</>
	)
}

export default CrmDay