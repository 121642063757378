//* 설문조사
import React, {useState} from "react";
import request from "utils/Request.utils";
import LogoImg from'../../assets/img/logo2.svg';
import CustomAlert from "atoms/common/CustomAlert";
import { nvl, getTodayDate } from "utils/Common.utils";

const Survey = () => {

  const [answer, setAnswer] = useState({
    survey_0: "5",
    survey_1: "5",
    survey_2: "5",
    survey_3: "5",
    survey_4: "5",
    survey_5: "5",
    survey_6: "1",
    survey_7: "1",
    wish_goods:'',
    remark:''
  })

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  // alert창 닫기
  const closeCustomAlert = () => {
  setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
}

  const radioBtnHandler = (e) => {
    const {name, value} = e.target;
    setAnswer({...answer, [name]:value})
  }

  // 요기 부터
  const [surveyList, setSurveyList] = useState([
    { subject: "오늘 방문하신 올리프트 ‘대전둔산점’의 전체적인 서비스는 만족하셨나요?", index: 0 },
    { subject: "데스크 담당 직원의 응대가 친절하였나요?", index: 1 },
    { subject: "상담 직원의 설명이 자세하고 응대가 친절하였나요?", index: 2 },
    { subject: "담당의사의 시술과 응대가 만족하셨나요?", index: 3 },
    { subject: "어시스트님의 응대가 친절하였나요?", index: 4 },
    { subject: "피부 관리사의 관리 및 응대가 친절하였나요?", index: 5 },
    { subject: "올리프트에 재방문 의사가 있으신가요?", index: 6 },
    { subject: "올리프트를 주위 분들에게 추천할 의사가 있으신가요?", index: 7 }
  ]);


  const answerRadioGroupList = (index) => {
    let aTagList = [];

		for (let i = 0; i < 6; i++) {
			aTagList.push(
        <fieldset key={"com_radio_wrap_" + i} className="com_radio_wrap">
          <input type="radio" className="radio" id={`survey_${i}_01`} name={`survey_${i}`} value="5" onChange={(e)=>radioBtnHandler(e)} checked={answer["survey_"+i] === "5" ? true : false}/>
          <label className="btn_radio" htmlFor={`survey_${i}_01`}>&nbsp;</label>
          <label htmlFor={`survey_${i}_01`}>매우만족</label>
          <input type="radio" className="radio" id={`survey_${i}_02`} name={`survey_${i}`} value="4" onChange={(e)=>radioBtnHandler(e)} checked={answer["survey_"+i] === "4" ? true : false}/>
          <label className="btn_radio" htmlFor={`survey_${i}_02`}>&nbsp;</label>
          <label htmlFor={`survey_${i}_02`}>만족</label>
          <input type="radio" className="radio" id={`survey_${i}_03`} name={`survey_${i}`} value="3" onChange={(e)=>radioBtnHandler(e)} checked={answer["survey_"+i] === "3" ? true : false}/>
          <label className="btn_radio" htmlFor={`survey_${i}_03`}>&nbsp;</label>
          <label htmlFor={`survey_${i}_03`}>보통</label>
          <input type="radio" className="radio" id={`survey_${i}_04`} name={`survey_${i}`} value="2" onChange={(e)=>radioBtnHandler(e)} checked={answer["survey_"+i] === "2" ? true : false}/>
          <label className="btn_radio" htmlFor={`survey_${i}_04`}>&nbsp;</label>
          <label htmlFor={`survey_${i}_04`}>불만</label>
          <input type="radio" className="radio" id={`survey_${i}_05`} name={`survey_${i}`} value="1" onChange={(e)=>radioBtnHandler(e)} checked={answer["survey_"+i] === "1" ? true : false}/>
          <label className="btn_radio" htmlFor={`survey_${i}_05`}>&nbsp;</label>
          <label htmlFor={`survey_${i}_05`}>개선필요</label>
        </fieldset>
      )
		}
    for (let i = 6; i < 8; i++) {
			aTagList.push(
        <fieldset key={"com_radio_wrap_" + i} className="com_radio_wrap">
          <input type="radio" className="radio" id={`survey_${i}_01`} name={`survey_${i}`} value="1" onChange={(e)=>radioBtnHandler(e)} checked={answer["survey_"+i] === "1" ? true : false}/>
          <label className="btn_radio" htmlFor={`survey_${i}_01`}>&nbsp;</label>
          <label htmlFor={`survey_${i}_01`}>예</label>
          <input type="radio" className="radio" id={`survey_${i}_02`} name={`survey_${i}`} value="0" onChange={(e)=>radioBtnHandler(e)} checked={answer["survey_"+i] === "0" ? true : false}/>
          <label className="btn_radio" htmlFor={`survey_${i}_02`}>&nbsp;</label>
          <label htmlFor={`survey_${i}_02`}>아니요</label>
        </fieldset>
      )
		}

		return aTagList[index];
	}

  const submitSurvey = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "설문조사를 등록하시겠습니까?" } })
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {// 설문조사 등록
        let params = {
          pack_seq: 1, //일단은 하드코딩
          goods_seq: 2,
          user_seq: 1,
          bp_seq: 1,
          business_place_point: answer.survey_0,
          receptionist_point: answer.survey_1,
          counselor_point: answer.survey_2,
          incharge_point: answer.survey_3,
          assist_point: answer.survey_4,
          treatment_point: answer.survey_5,
          revisitation_point: answer.survey_6,
          recommend_point: answer.survey_7,
          wish_goods: answer.wish_goods,
          remark: answer.remark
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "설문조사가 등록되었습니다.<br/>고객님의 소중한 의견으로<br/>더 좋은 서비스를 만들겠습니다.<br/>감사합니다." } })
            setAnswer({
              survey_0: "5",
              survey_1: "5",
              survey_2: "5",
              survey_3: "5",
              survey_4: "5",
              survey_5: "5",
              survey_6: "1",
              survey_7: "1",
              wish_goods:'',
              remark:''
            })
          } else {
            console.log("설문조사를 등록하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/etc/satisfaction", params, successHandler, failHandler, null, null);
    }
  }

	return (
		<>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
			<main className="p_survey">
        <div className="box">
          <section className="top_area">
            <article className="logo"><img src={LogoImg} alt="logo"/></article>
            <h1 className="sec_tit">고객 만족도 입력하기</h1>
            <p className="txt">안녕하세요 고객님 오늘도 저희 병원을 찾아주셔서 감사합니다.<br/>고객 만족도 설문에 응해 주시면 더욱 더 좋은 서비스로 보답 드리겠습니다.</p>
            <article className="info">
              <p className="product">보톡스 3회 트리플 케어 모이스처</p>
              <p className="date"><span className="field">{getTodayDate('time')?.slice(0,12)}</span><span className="field">{getTodayDate('time')?.slice(14,22)}</span></p>
            </article>
          </section>
          <section className="survey_area"> 
            {surveyList.map((item, index) => (
                <article key={ `article_${item.index}` } className="item">
                  <p className="q">{ item.subject }</p>
                  <div className="a">
                    { answerRadioGroupList(item.index) }
                  </div>
                </article>
            ))}
            <article className="item">
              <p className="q">올리프트에서 진행하고 있는 시술 이벤트에 대한 생각이나 앞으로 받아보고 싶은 시술이 있나요?</p>
              <div className="input_wrap">
                <input type="text" className="input" name="wish_goods" value={answer.wish_goods} onChange={(e)=>radioBtnHandler(e)}/>
              </div>
              <div className="input_wrap">
                <textarea className="textarea" placeholder="기타 의견을 입력해 주세요. (좋았던점 / 개선할점)" name="remark" value={answer.remark} onChange={(e)=>radioBtnHandler(e)}></textarea>
              </div>
            </article>
          </section>
          <section className="btn_area">
            <button className="com_btn l blue" onClick={submitSurvey}>확인</button>
          </section>
        </div>
      </main>
		</>
	)
}

export default Survey