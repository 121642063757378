import React from "react";

const Footer = () => {
	return (
		<footer>
			<div className="left_wrap">
				<strong className="company_tit">AllLIFT International</strong>
				<article className="company_info">
					<p>대표자 : 박유라</p>
					<p>주소 :  대전광역시 서구 대덕대로 223, 4층</p>
					<p>사업자등록번호 : 306-22-91740</p>
					<p>TEL : 042-719-7827 /  FAX : 042 719 7828</p>
					<p>Email : allift@naver.com</p>
				</article>
			</div>
			<nav className="footer_nav">
				<a href="#/" className="menu">개인정보처리방침</a>
				<a href="#/" className="menu">이용약관</a>
			</nav>
		</footer>
	)
}

export default Footer