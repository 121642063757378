//* 진료비 세부내역서, 진료비 영수증, 진료기록부
import React, { useState } from "react";
import PopupCalendar from "atoms/common/PopupCalendar";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";


const DocumentMediRecord = ({ getOrderRecord, setAlertLayerPopup, treatmentReport, setTreatmentReport, medicalTreatmentList, checkedItems, setCheckedItems, setSelectedRecordArray }) => {
  const [viewType, setViewType] = useState('');


  const popupCalendarHTML = () => {
    let retArr = [];

    if (viewType === 'from' || viewType === 'to') {
      retArr.push(<PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={'hyphen'} />)
    } else retArr.push(null);

    return retArr;
  }

  const closePopup = () => {
    setViewType('');
  }

  const getCalendar = (value) => {
    if (viewType === 'from') setTreatmentReport((prev) => ({ ...prev, treatment_start: value }));
    else {
      if (value <= treatmentReport.treatment_start) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시작일보다 나중인 날짜를 선택해주세요." } })

        return false;
      }

      setTreatmentReport((prev) => ({ ...prev, treatment_end: value }));
    }
  }


  const handleCheckboxChange = (e, orderSeq) => {
    const isChecked = e.target.checked;
    let indexNum = medicalTreatmentList.findIndex(el => el.order_seq === orderSeq);
    const copyData = [...medicalTreatmentList]
    copyData[indexNum] = { ...copyData[indexNum], chkItem: isChecked };
    setSelectedRecordArray(copyData[indexNum]);

    if (isChecked) {
      setCheckedItems(prev => [...prev, copyData[indexNum]]);
    } else {
      setCheckedItems(prev => prev.filter(item => item.order_seq !== orderSeq));
    }

  }



  return (
    <div className="p_document_medi_fee">
      <ul className="com_input_section">
        <li className="tit_field">진료기간</li>
        <li className="input_field">
          <fieldset className="many">
            <div className="datepicker_from_to">
              <input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name="treatment_start" value={treatmentReport?.treatment_start} onClick={() => { setViewType('from') }} readOnly /> {popupCalendarHTML()}
              <span className="txt_between">~</span>
              <input type="text" className="input datepicker" id="to" placeholder="2023. 01. 01" name="treatment_end" value={treatmentReport?.treatment_end} onClick={() => { setViewType('to') }} readOnly /> {popupCalendarHTML()}
              <button className="com_btn blue m" onClick={() => getOrderRecord(1)}>조회</button>
            </div>
          </fieldset>
        </li>
      </ul>
      <div className="com_list">
        <table className="list">
          <colgroup><col width='8%' /><col width='12%' /><col width='12%' /><col width='12%' /><col /></colgroup>
          <thead>
            <tr>
              <th>선택</th>
              <th>진료일자</th>
              <th>담당의</th>
              <th>질병코드</th>
              <th>질병명</th>
            </tr>
          </thead>
          <tbody>
            {medicalTreatmentList.length > 0 ? medicalTreatmentList.map((el, i) => (
              <tr key={i}>
                <td>
                  <fieldset className="com_check_wrap">
                    <input type="checkbox" className="checkbox" id={`chk${i}_1`} value={el.order_seq || ""} onChange={(e) => handleCheckboxChange(e, el.order_seq)} checked={checkedItems?.some(item => item.order_seq === el.order_seq)} />
                    <label className="btn_checkbox" htmlFor={`chk${i}_1`}>&nbsp;</label>
                  </fieldset>
                </td>
                <td>{moment(el.order_date).format("YYYY, MM, DD")}</td>
                <td>{el.manager_name}</td>
                <td>{el.disease_code}</td>
                <td className="tit">{el.disease_name}</td>
              </tr>
            ))
              :
              <tr>
                <td colSpan="5">
                  데이터가 없습니다.
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DocumentMediRecord