//* 고객관리>list
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import NonePay from './_NonePay.js';//미수액팝업
import CsGrade from '../../components/common/_CsGrade.js';//고객등급관리 팝업

import CustomerSubButton from "../../atoms/crm/SubButton";
import CustomerSubSearch from "../../atoms/crm/SubSearch";


import { nvl, addComma, convertJuminToAge, nvlNumber, getTodayDate, getPreviousWeekDate, getPreviousMonthDate, convertGender } from "../../utils/Common.utils";
import { decrypt } from "../../utils/aes256.manager";
import request from '../../utils/Request.utils';
import SnsSend from "components/common/_SnsSend.js";

const Customer = () => {
  const [csGradeDisplayBool, setCsGradeDisplayBool] = useState(false);
  const [snsSendBool, setSnsSendBool] = useState(false);
  const [nonepayDisplayBool, setNonepayDisplayBool] = useState(false);
  const [userList, setUserList] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [selectedValue, setSelectedValue] = useState(0);
  const [allCheckBool, setAllCheckBool] = useState('');
  const [userSeq, setUserSeq] = useState(0);
  const [periodOption, setPeriodOption] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [periodValue, setPeriodValue] = useState('');
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0, 17));
  const [endDate, setEndDate] = useState('');

  const today = getTodayDate()?.slice(0,17);

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    }else{
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }

  const keywordSearchDo = (getValue) => {
    console.log(getValue);

    setSearchKeyword(getValue);
  }

  const csGradeToggle = () => {
    setCsGradeDisplayBool(!csGradeDisplayBool);

    // console.log("csGradeDisplayBool : " + csGradeDisplayBool);
  }

  const nonePayToggle = () => {
    setNonepayDisplayBool(!nonepayDisplayBool);
  }

  const getUserInfo = () => {
    let params = {
      periodOption: periodOption,
      searchKeyword: searchKeyword,
      orderBy: "user_seq DESC",
      limit: 10,
      offset: 0
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) { // ok 코드
        setUserList(response.data.dataList);
        setTotalCnt(response.data.totalCnt);
      } else {
        console.log("데이터가 없습니다..");
      }
    }

    const failHandler = (response) => {
      console.log("에라야 에라~~" + response);
    }

    request.get(
      `/api/user?periodOption=${params.periodOption}&orderBy=${params.orderBy}&searchKeyword=${searchKeyword}`,
      null,
      successHandler,
      failHandler,
      null,
      null
    );
  }

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodOption, searchKeyword]);

  const clickHandler = (event) => {
    setSelectedValue(event.target.value);

    // console.log("clickHandler 값 확인 : " + event.target.value + " | " + selectedValue + " | " + event.target.checked);
  }

  const nonePayWinOpen = (userSeq) => {
    if (nvl(userSeq) !== "") {
      setUserSeq(userSeq);
      nonePayToggle();
    }
  }


  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <CustomerSubButton buttonHandler={subButtonAction} />
          <CustomerSubSearch searchHandler={subSearchAction} keywordSearchHandler={keywordSearchDo} />
        </div>
      </section>
      <section className="com_content">
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="date">{periodValue === 1 ? `${startDate} ~ ${endDate}` : periodValue === 2 ? `${startDate} ~ ${endDate}` : `${startDate}`}</p>
              <p className="num">전체환자수 ({totalCnt})명</p>
            </div>
            <div className="btn_field">
              <button type="button" className="com_btn m blue" onClick={() => { csGradeToggle() }}><i className="svg_icon icon_set yellow">&nbsp;</i>고객 등급 관리</button>
              <button type="button" className="com_btn m blue" onClick={() => { setSnsSendBool(!snsSendBool) }}><i className="svg_icon icon_sms yellow">&nbsp;</i>문자 보내기</button>
            </div>
          </article>
          <div className="scroll">
            <table className="list">
              <colgroup><col width="3.4%" /><col width="7.9%" /><col width="7.9%" /><col width="8.9%" /><col /><col width="8.5%" /><col width="6.5%" /><col width="7.9%" /><col width="7.9%" /><col width="7.9%" /><col width="7.9%" /><col width="7.9%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>선택</th>
                  <th>고객코드</th>
                  <th>이름</th>
                  <th>주민번호</th>
                  {/* <th>이메일</th> */}
                  <th>휴대폰번호</th>
                  <th>등급</th>
                  <th>가입일</th>
                  <th>최근 시술일</th>
                  <th>총진료비</th>
                  <th>총결제액</th>
                  <th>미수액</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>

                {
                  (userList == null || userList.length < 1) ?
                    <tr>
                      <td colSpan="13">
                        등록된 고객이 없습니다.
                      </td>
                    </tr>
                  :
                    userList.map((item, index) => (
                      <tr key={item.user_seq}>
                        <td>
                          <fieldset className="com_check_wrap">
                            <input type="checkbox" className="checkbox" id={"userList_" + item.user_seq} value={item.user_seq}
                              onClick={clickHandler} defaultChecked={item.user_seq === selectedValue || allCheckBool} />
                            <label className="btn_checkbox" htmlFor={"userList_" + item.user_seq}>&nbsp;</label>
                          </fieldset>
                        </td>
                        <td>{item.chart_code}</td>
                        <td>{item.user_name} ({convertGender(item.user_gender)}/{nvl(item.user_jumin) !== "" ? convertJuminToAge((decrypt(item.user_jumin).substring(0, 6) + "-" + decrypt(item.user_jumin).substring(6, 13))) : " - "})</td>
                        <td>{item.user_jumin !== "" ? (decrypt(item.user_jumin).substring(0, 6)) : " - "} - *********</td>
                        {/* <td>{item.user_email}</td> */}
                        <td>{item.user_mobile}</td>
                        <td>{item?.user_level_name?.split("|")[0]}</td>
                        <td>{item.reg_date.substring(0, 10)}</td>
                        <td>{item.order_reg_date?.substring(0, 10)}</td>
                        <td className="num">{addComma(nvlNumber(item.current_price_total))}</td>
                        <td className="num">{addComma(nvlNumber(item.use_price_total))}</td>
                        <td onClick={() => nonePayWinOpen(item.user_seq)}>{addComma(nvlNumber(item.unpaid_price))}
                        </td>
                        <td><Link to={`/crm/customer_view?userSeq=${item.user_seq}`} className="com_btn txt">상세보기</Link></td>
                      </tr>
                    ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </section>
      {nonepayDisplayBool && <NonePay nonePayToggle={nonePayToggle} userSeq={userSeq} />}
      {csGradeDisplayBool &&<CsGrade csGradeToggle={csGradeToggle} />}
      {snsSendBool && <SnsSend snsSendBool={snsSendBool} setSnsSendBool={setSnsSendBool} />}
    </>
  )
}

export default Customer