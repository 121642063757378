//* 시간별 예약 인원 관리
import moment from "moment";
import request from "utils/Request.utils";
import React, { useEffect, useRef, useState } from "react";
import CustomAlert from "atoms/common/CustomAlert";
import Draggable from "react-draggable";

const TimeManage = (props) => {
  let timeManageBool = props.timeManageBool;
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  const popupSectionRef = useRef();
  const nodeRef = useRef(null);

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false
  })

  // 시간별 예약인원 관리 팝업창 닫기
  const popupClose = () => {
    props.timeManageToggle()
    props.getReservationLimit()
  }

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"))

  // alert 창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }
  const returnAlertValue = () => {

  }



  // 추가
  const addReservationLimit = () => {

    let newDate = "";
    if (props.reservationLimit.length === 0) {
      newDate = moment(startDate)
    } else {
      newDate = moment(props.reservationLimit[props.reservationLimit.length - 1].manage_date).add(1, "day")
    }
    const endOfMonth = newDate.clone().endOf("month") // 해당 월의 마지막 날짜
    console.log(moment(endOfMonth).add(1, "day").startOf("month"))
    if (newDate.isSameOrAfter(endOfMonth)) {
      newDate = moment(endOfMonth).add(1, "day").startOf("month") // 다음 달의 첫 번째 날짜로 설정
    }
    const newLimit = { manage_date: newDate.format("YYYY-MM-DD"), manage_amount: "", reg_manager_seq: managerInfo.seq }
    props.setReservationLimit([...props.reservationLimit, newLimit])
  }

  // 인원 변경하기
  const changePersonnelLimit = (e, order) => {
    const { value } = e.target;
    let copy = [...props.reservationLimit]
    copy[order] = { ...copy[order], manage_amount: value }

    props.setReservationLimit(copy)
  }

  // 등록
  const submitReservationLimit = () => {
    let params = {
      personnel_info: props.reservationLimit
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {

        props.getReservationLimit()
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.post("/api/reservation/manage/T", params, successHandler, failHandler, null, null)
  }


  if (timeManageBool) popupSectionRef.current.classList.add("active");
  else if (!timeManageBool && popupSectionRef.current) popupSectionRef.current.classList.remove("active");

  return (
    <>
      {/* //* 일반 팝업 */}
      <section ref={popupSectionRef} className="com_popup type_normal pop_time_manage">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => popupClose()}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">시간별 예약인원 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">
              <article className="top_area">
                <p className="tit">30분 단위 예약인원 등록(명)</p>
              </article>
              <article className="list">
                <ul className="item_wrap scroll">
                  {props.reservationLimit !== null && props.reservationLimit.map((date, index) => (
                    <li className="item" key={"date" + index}>
                      {`${moment(date.manage_date).format("DD")}일`}<input type="text" className="input" value={date.manage_amount || ""} onChange={(e) => changePersonnelLimit(e, index)} />
                    </li>
                  ))}
                </ul>
                <button className="com_btn blue line m" onClick={() => addReservationLimit()}><i className="svg_icon icon_add yellow">&nbsp;</i>추가</button>
              </article>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue" onClick={() => submitReservationLimit()}>등록</button>
            </div>
          </div>
        </Draggable>
      </section>
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
    </>
  )
}

export default TimeManage