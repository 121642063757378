import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';
import request from "utils/Request.utils.js";
import moment from 'moment';
import CustomAlert from '../../atoms/common/CustomAlert';

import { nvl, nvlNumber } from "../../utils/Common.utils";

import MyCompany from "./_MyCompany.js";//지점 정보
import Paging from '../../layout/Paging.js';//페이징
import { useDispatch } from "react-redux";

const Main = () => {
	const dispatch = useDispatch();

	const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
	const [boardList, setBoardList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [numPerPage, setNumPerPage] = useState(10);
	const [pagePerBlock, setPagePerBlock] = useState(10);

	const [searchKeyword, setSearchKeyword] = useState('');


	const [currentArticleSeq, setCurrentArticleSeq] = useState(0)
	const [articleViewSubject, setArticleViewSubject] = useState('');
	const [articleViewWriter, setArticleViewWriter] = useState('');
	const [articleViewRegDate, setArticleViewRegDate] = useState('');
	const [articleViewContent, setArticleViewContent] = useState('');
	const [articleViewUploadFile, setArticleViewUploadFile] = useState([]);

	const [alertLayerPopup, setAlertLayerPopup] = useState({
		alertType: 'alert',
		alertMessage: '',
		visible: false,
		activateMode: '',
		value: 0
	});

	const closeCustomAlert = () => {
		setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "", value: 0 } })
	}

	const getBoardList = () => {
		let pms = {
			// noti_type: "I",
			manager_position: managerInfo.role,
			search_keyword: searchKeyword,
			delete_yn: "N",
			limit: numPerPage,
			offset: ((currentPage - 1) * numPerPage),
			order_by: "board_seq DESC"
		}

		const successHandler = (response) => {
			// console.log(response)

			if (response.messageCode === 200) {
				setBoardList(response.data.dataList);
				setTotalCount(response.data.totalCnt);

				dispatch({ type: "setToday", payload: { data: moment(response.data.today).format("YYYY-MM-DD") } });

			}
		}

		const failHandler = (result) => {
			console.log("에라야 에라~~" + result);
		}

		request.get(`/api/boards/notice?noti_type=${pms.noti_type}&manager_position=${pms.manager_position}&delete_yn=${pms.delete_yn}&limit=${pms.limit}&offset=${pms.offset}&order_by=${pms.order_by}&search_keyword=${pms.search_keyword}`,
			null, successHandler, failHandler, null, null);
	}

	useEffect(() => {
		getBoardList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const nextPageMove = (value) => {
		// console.log("value : " + value)

		setCurrentPage(value)
	}

	const articleViewActivate = (boardSeq) => {
		setCurrentArticleSeq(boardSeq); // 전역으로 사용할 거
		getCurrentArticle(boardSeq);
	}

	const getCurrentArticle = (boardSeq) => { // 게시글 상세
		const successHandler = (response) => {
			console.log(response)

			if (response.messageCode === 200) {
				getBoardList() // 조회수 및 다른 컬럼의 갱신 값을 바로 반영하기 위해서 다시 부름

				// 상세보기
				setArticleViewSubject(nvl(response.data.articleData.subject));
				setArticleViewWriter(nvl(response.data.articleData.writer));
				setArticleViewRegDate(nvl(response.data.articleData.reg_date));
				setArticleViewContent(nvl(response.data.articleData.content));
				setArticleViewUploadFile(response.data.uploadFileData);
			}
		}

		const failHandler = (result) => {
			console.log("에라야 에라~~" + result);
		}

		request.get(`/api/boards/notice/${boardSeq}`, null, successHandler, failHandler, null, null);
	}

	const fileDownload = (fileSeq) => {
		window.location.href = process.env.REACT_APP_API_URL + "/api/upload/download/file/notice/" + fileSeq;
	}

	const returnAlertValue = (value) => {
	}

	const prevArticleMove = (event) => {
		console.log("이전 글 ")

		if (nvlNumber(currentArticleSeq) > 0) {
			let pms = {
				// noti_type: "I",
				manager_position: managerInfo.role,
				search_keyword: searchKeyword
			}

			const successHandler = (response) => {
				console.log(response)

				if (response.messageCode === 200) {
					if (nvl(response.data) !== "" && nvlNumber(response.data.board_seq) > 0) articleViewActivate(response.data.board_seq)
					else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이전 글이 없습니다." } })
				} else console.log("뭔가 문제가 있는디?")
			}

			const failHandler = (result) => {
				console.log("에라야 에라~~" + result);
			}

			request.get(`/api/boards/notice/${currentArticleSeq}/prev?noti_type=${pms.noti_type}&manager_position=${pms.manager_position}&search_keyword=${pms.search_keyword}`, null, successHandler, failHandler, null, null);
		}
	}

	const nextArticleMove = (event) => {
		console.log("다음 글 ")

		if (nvlNumber(currentArticleSeq) > 0) {
			let pms = {
				// noti_type: "I",
				manager_position: managerInfo.role,
				search_keyword: searchKeyword
			}

			const successHandler = (response) => {
				console.log(response)

				if (response.messageCode === 200) {
					if (nvl(response.data) !== "" && nvlNumber(response.data.board_seq) > 0) articleViewActivate(response.data.board_seq)
					else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "다음 글이 없습니다." } })
				}
				else console.log("뭔가 문제가 있는디?")
			}

			const failHandler = (result) => {
				console.log("에라야 에라~~" + result);
			}

			request.get(`/api/boards/notice/${currentArticleSeq}/next?noti_type=${pms.noti_type}&manager_position=${pms.manager_position}&search_keyword=${pms.search_keyword}`, null, successHandler, failHandler, null, null);
		}
	}

	return (
		<>
			<section className="com_content com_has_mycompany">
				<div className="p_main">
					<section className="com_board_list">
						<table>
							<tbody>
								<tr>
									<th className="th_no">No.</th>
									<th className="th_tit">가맹점 공지</th>
									<th className="th_writer">작성자</th>
									<th className="th_date">작성일</th>
								</tr>
								{
									(boardList === null || boardList.length < 1) ?
										<tr>
											<td colSpan="4">데이터가 없습니다.</td>
										</tr>
										:
										boardList.map((item, index) => (
											<tr key={item.board_seq}>
												<td>{totalCount - (numPerPage * (currentPage - 1)) - index}</td>
												<td className="td_tit"><a onClick={() => articleViewActivate(item.board_seq)} href="#/" className="ellipsis">{item.subject}</a></td>
												<td>{item.writer}</td>
												<td>{moment(item.reg_date).format("YYYY-MM-DD")}</td>
											</tr>
										))

								}


							</tbody>
						</table>
						<Paging totalCount={totalCount} currentPage={currentPage} numPerPage={numPerPage} pagePerBlock={pagePerBlock} nextPageMove={nextPageMove} />
					</section>
					{<section className="com_board_view">
						<article className="top_area">
							<p className="tit_field">{articleViewSubject}</p>
							<p className="info_field">
								<span>{articleViewWriter}</span>
								<span>{nvl(articleViewRegDate) !== "" ? moment(articleViewRegDate).format("YYYY-MM-DD") : ""}</span>
							</p>
						</article>
						{(articleViewUploadFile === null || articleViewUploadFile.length < 1) ? null
							:
							articleViewUploadFile.map((item, index) => (
								<article className="info_area" key={item.file_sys_name}>
									<p className="file_field"><span className="tit" >첨부파일</span><a onClick={() => fileDownload(item.up_file_seq)} href="#/" className="file_link">{item.file_real_name}</a></p>
								</article>
							))
						}
						<article className="view_content scroll">
							{parse(articleViewContent)}
						</article>
						<article className="btn_area">
							<button className="com_btn line m blue" onClick={prevArticleMove}><i className="svg_icon icon_prev blue">&nbsp;</i>이전</button>
							<button className="com_btn m blue" onClick={nextArticleMove}>다음<i className="svg_icon icon_next white">&nbsp;</i></button>
						</article>
					</section>
					}
					{alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage}
						returnValue={returnAlertValue} /> : null}
				</div>
				<MyCompany />
			</section>
		</>
	)
}

export default Main