//*문자 발송내용
import React, { useRef, useEffect } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import moment from "moment";

const SnsSendDetail = ({ selectMessage, setSelectMessage, onClose, managerInfo, getSentMessages }) => {

  const nodeRef = useRef(null);

  const updateSentMessage = () => {
    let params = {
      msg_cate: selectMessage.msg_cate,
      msg_subject: selectMessage.msg_subject,
      msg_content: selectMessage.msg_content,
      up_file_path: selectMessage.up_file_path,
      auto_send_type: selectMessage.auto_send_type,
      send_method: selectMessage.send_method,
      from_number: selectMessage.from_number,
      recipient_number: selectMessage.user_mobile.replace(/-/g, ""),
      send_type: selectMessage.send_type,
      send_time: selectMessage.send_time,
      send_hour: moment(selectMessage.send_hour).format("YYYY-MM-DD HH:mm:sss"),
      msg_status: selectMessage.msg_status,
      modi_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      console.log(response)
      if (response.messageCode === 200) {

        onClose();
        getSentMessages()
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.put(`/api/message/sent/${selectMessage.msg_seq}`, params, successHandler, failHandler, null, null)
  }


  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_sns_send_detail PopSnsSendDetail active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container m">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }} ></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">문자 발송내용</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <ul className="com_input_section">
                <li className="input_field full">
                  <textarea className="textarea" value={selectMessage.msg_content} onChange={(e) => setSelectMessage({ ...selectMessage, msg_content: e.target.value })} readOnly={selectMessage.msg_status === "S"}></textarea>
                </li>
              </ul>
            </div>
            <div className="pop_footer com_btn_wrap one">
              {selectMessage.msg_status === "R" && <button className="com_btn blue l" onClick={() => updateSentMessage()}>저장</button>}
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default SnsSendDetail