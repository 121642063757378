//* 시술명 추가 1115 전체 수정
const AddUsingGoods =
  ({
    addProcedureGoods,
    procedure,
    index,
    usingProcedureList,
    addProcedureTime,
    deleteProcedureTime,
    procedureTimeList,
    usingProcedureHandler,
    timesHandler,
    deleteGoods,
    location,
    setViewType,
    popupCalendarHTML
  }) => {

    return (
      <>
      <section className="com_write box">
        <table className="write">
        <colgroup><col width='10%'/><col width='40%'/><col width='10%'/><col/></colgroup>
          <tr>
            <th>{index + 1}  옵션 시술명</th>
            <td>
              <fieldset className="many">
                <input type="text" name="goods_name" className="input" placeholder="시술명 입력" defaultValue={procedure.goods_name || ""} onChange={(e) => usingProcedureHandler(e, index)}/>
                <input type="checkbox" className="btn_chk" name="vat_type" id="chk01" /><label className="com_btn chk blue m" htmlFor="chk01"> 부가세 별도</label>
              </fieldset>
            </td>
            {/*
            <th>이벤트</th>
            <td>
              <fieldset className="com_check_wrap" >
                <input type="radio" className="radio" id="radio41" name="event_yn" value="N" checked={procedure.event_yn === "N"} onChange={(e) => usingProcedureHandler(e, index)} />
                <label className="btn_radio" htmlFor="radio41" >&nbsp;</label >
                <label htmlFor="radio21" >이벤트 안함</label>
                <input type="radio" className="radio" id="radio42" name="event_yn" value="U" checked={procedure.event_yn === "U"} onChange={(e) => usingProcedureHandler(e, index)} />
                <label className="btn_radio" htmlFor="radio42" >&nbsp;</label >
                <label htmlFor="radio42" >무기한 이벤트</label>
                <input type="radio" className="radio" id="radio43" name="event_yn" value="L" checked={procedure.event_yn === "L"} onChange={(e) => usingProcedureHandler(e, index)} />
                <label className="btn_radio" htmlFor="radio43" >&nbsp;</label >
                <label htmlFor="radio43" >기한 이벤트</label>
              </fieldset>
            </td>
          */}
          </tr>
          <tr>
            <th>시술 설명</th>
            <td><input type="text" name="goods_description" className="input full" placeholder="시술 간단설명 입력" defaultValue={procedure?.goods_description || ""} onChange={(e) => usingProcedureHandler(e, index)}/></td>
            {/*
            <th>이벤트 금액</th>
            <td>
              <input type="number" name="event_price" defaultValue={procedure.event_price} className="input" onChange={(e) => usingProcedureHandler(e, index)} /> 할인율(%) : 12
            </td>
            */}
          </tr>
          <tr>
            <th>시술 금액</th>
            <td>
              <input type="number" name="goods_price" defaultValue={procedure?.goods_price} className="input price_x" onChange={(e) => usingProcedureHandler(e, index)} /> → 
              <input type="number" name="goods_sale_price" defaultValue={procedure?.goods_sale_price} className="input" onChange={(e) => usingProcedureHandler(e, index)} />
            </td>
            {/*
            <th>이벤트 기간</th>
            <td>
              <fieldset className="many">
                <div className="datepicker_from_to">
                  <input type="text" name="event_start_date" className="input datepicker" id="from" placeholder="2023. 01. 01" value={procedure.event_start_date || ""} disabled={procedure.event_yn === "N"} onClick={() => { setViewType('from') }} readOnly />
                  {popupCalendarHTML()}
                  <span className="txt_between">~</span>
                  <input type="text" name="event_end_date"  className="input datepicker" id="to" placeholder="2023. 01. 01" value={procedure.event_end_date || ""} disabled={procedure.event_yn === "N"} onClick={() => { setViewType('to') }} readOnly />
                  {popupCalendarHTML()}
                </div>
              </fieldset>
            </td>
            */}
          </tr>
        </table>
        <ul className="input_section only_tit">
          <li className="col01 top_tit">
            <p className="tit">회차 명</p>
          </li>
          <li className="col02 top_tit">
            <p className="tit">회차 시술금액(원)</p>
          </li>
        </ul>
        {procedureTimeList ? procedureTimeList.map((time, idx) => (
          location.state === null ? time.goods_times === procedure.goods_times &&
            (<ul className="input_section" key={"time" + idx} >
              <li className="col01">
                <div className="input_wrap">
                  <span className="tit_field">{time.view_order}회차</span>
                  <input type="text" className="input" placeholder="회차명 입력 (20자)@" name="times_name" value={time.times_name || ""} onChange={(e) => timesHandler(e, idx)} />
                </div>
              </li>
              <li className="col02">
                <div className="input_wrap">
                  <input type="text" className="input price" defaultValue={time.times_price || ""} name="times_price" onChange={(e) => timesHandler(e, idx)} />
                  {time.view_order === procedureTimeList.filter((el) => el.goods_times === procedure.goods_times).length && 
                  <button className="com_btn blue m" onClick={() => addProcedureTime(procedure.goods_times)}><i className="svg_icon icon_add yellow">&nbsp;</i>회차추가@</button>}
                  {time.view_order !== 1 && <button className="com_btn_del" onClick={() => deleteProcedureTime(time.goods_times, time.view_order)}>&nbsp;</button>}
                </div>
              </li>
            </ul>)
            : time.goods_seq === procedure.goods_seq && time.goods_times === procedure.goods_times &&
            (<ul className="input_section" key={"time" + idx} >
              <li className="col01">
                <div className="input_wrap">
                  <span className="tit_field">{time.view_order}회차</span>
                  <input type="text" className="input" placeholder="회차명 입력 (20자)2" name="times_name" defaultValue={time.times_name || ""} onChange={(e) => timesHandler(e, idx)} />
                </div>
              </li>
              <li className="col02">
                <div className="input_wrap">
                  <input type="text" className="input price" defaultValue={time.times_price || ""} name="times_price" onChange={(e) => timesHandler(e, idx)} />
                  {time.view_order === procedureTimeList.filter((el) => el.goods_seq ? el.goods_seq === procedure.goods_seq : el.goods_times === procedure.goods_times).length && 
                  <button className="com_btn blue m" onClick={() => addProcedureTime(procedure.goods_seq ? procedure.goods_seq : procedure.goods_times)}><i className="svg_icon icon_add yellow">&nbsp;</i>회차추가</button>}
                  {time.view_order !== 1 && <button className="com_btn_del" onClick={() => deleteProcedureTime(time.goods_seq ? time.goods_seq : time.goods_times, time.view_order)}>&nbsp;</button>}
                </div>
              </li>
            </ul>)
        )) : null
        }
        <div className="com_btn_wrap right">

            <button className="com_btn line m blue" onClick={() => deleteGoods(procedure.goods_seq !== undefined ? procedure.goods_seq : procedure.goods_times)}>
              <i className="svg_icon icon_trashcan yellow">&nbsp;</i>옵션 시술 삭제
            </button>
        </div>
      </section >

      <section className="com_btn_wrap one bottom">
        <div className="com_btn blue m" onClick={() => addProcedureGoods(procedure.goods_times)}>
          <i className="svg_icon icon_add yellow">&nbsp;</i>옵션 시술 추가
        </div>
      </section>

      </>
    )
  }

export default AddUsingGoods
