//* 공지사항 - 목록
import React, { useEffect, useState, useRef } from "react";
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { nvl, nvlNumber } from "../../utils/Common.utils";

import request from "utils/Request.utils.js";
import Paging from '../../layout/Paging.js';
import moment from 'moment';
import CustomAlert from '../../atoms/common/CustomAlert';

const Notice = () => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  const [boardList, setBoardList] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [numPerPage, setNumPerPage] = useState(10)
  const [pagePerBlock, setPagePerBlock] = useState(10)

  const [searchKeyword, setSearchKeyword] = useState('');

  // 상세보기 부분
  const [currentArticleSeq, setCurrentArticleSeq] = useState(0)
  const [articleViewSubject, setArticleViewSubject] = useState('');
  const [articleViewWriter, setArticleViewWriter] = useState('');
  const [articleViewRegDate, setArticleViewRegDate] = useState('');
  const [articleViewContent, setArticleViewContent] = useState('');
  const [articleViewUploadFile, setArticleViewUploadFile] = useState([]);
  const [articleWriterSq, setArticleWriterSeq] = useState('');
  // 상세보기 전체
  const [articleInfo, setArticleInfo] = useState("")

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
    value: 0
  });

  const searchKeywordRef = useRef();

  const navigate = useNavigate();

  const query = new URLSearchParams(window.location.search);
  const notiType = query.get('noti_type')

  const getBoardList = () => {
    let pms = {
      noti_type: notiType,
      manager_position: managerInfo.role, // 로그인 구현 후 처리할 사항
      search_keyword: searchKeyword,
      delete_yn: "N",
      limit: numPerPage,
      offset: ((currentPage - 1) * numPerPage),
      order_by: "board_seq DESC"
    }

    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        setBoardList(response.data.dataList);
        setTotalCount(response.data.totalCnt);
      }
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);
    }

    request.get(`/api/boards/notice?noti_type=${pms.noti_type}&manager_position=${pms.manager_position}&delete_yn=${pms.delete_yn}&limit=${pms.limit}&offset=${pms.offset}&order_by=${pms.order_by}&search_keyword=${pms.search_keyword}`,
      null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    if (nvl(notiType) === "") window.location.href = "/notice?noti_type=I"
    else {
      // console.log("현재 : " + currentPage)

      getBoardList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    boardListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  const nextPageMove = (value) => {
    // console.log("value : " + value)

    setCurrentPage(value)
  }

  const noticeWriteGo = () => {
    if (currentArticleSeq > 0) {
      navigate("/notice/write?noti_type=" + notiType, { state: articleInfo })
    } else {
      navigate("/notice/write?noti_type=" + notiType);
    }

  }

  const boardListReload = () => {
    setCurrentPage(1) // 1부터 다시
    getBoardList()
  }

  const keywordSearchDo = (e) => {
    console.log(searchKeywordRef.current.value);

    setSearchKeyword(searchKeywordRef.current.value);
  }

  const keywordDeleteDo = (e) => {
    searchKeywordRef.current.value = "";
    setSearchKeyword("");
  }


  // 리스트에서 공지사항 글 선택시
  const articleViewActivate = (boardSeq) => {
    if (currentArticleSeq === boardSeq) {
      setCurrentArticleSeq(0); // 전역으로 사용할 거

      // 상세값 전부 초기화
      setArticleViewSubject('')
      setArticleViewWriter('')
      setArticleViewRegDate('')
      setArticleViewContent('')
      setArticleViewUploadFile('')
    } else {
      setCurrentArticleSeq(boardSeq); // 전역으로 사용할 거
      getCurrentArticle(boardSeq);
    }
  }



  const getCurrentArticle = (boardSeq) => { // 게시글 상세
    const successHandler = (response) => {
      console.log(response)

      if (response.messageCode === 200) {
        getBoardList() // 조회수 및 다른 컬럼의 갱신 값을 바로 반영하기 위해서 다시 부름

        setArticleInfo(response.data)

        // 상세보기
        setArticleViewSubject(nvl(response.data.articleData.subject));
        setArticleViewWriter(nvl(response.data.articleData.writer));
        setArticleViewRegDate(nvl(response.data.articleData.reg_date));
        setArticleViewContent(nvl(response.data.articleData.content));
        setArticleViewUploadFile(response.data.uploadFileData);
        setArticleWriterSeq(nvl(response.data.articleData.reg_manager_seq))
      }
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);
    }

    request.get(`/api/boards/notice/${boardSeq}`, null, successHandler, failHandler, null, null);
  }

  const fileDownload = (fileSeq) => {
    window.location.href = process.env.REACT_APP_API_URL + "/api/upload/download/file/notice/" + fileSeq;
  }

  const currentArticleDelete = (boardSeq) => { // 게시글 삭제
    console.log(boardSeq)
  }

  const currentUploadFileDelete = (fileSeq) => { // 첨부파일 삭제
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "현재 파일을 삭제하시겠습니까?", activateMode: "uploadFileDelete", value: fileSeq } })
  }

  const currentUploadFileDeleteDo = () => { // 첨부파일 삭제
    let fileSeq = alertLayerPopup.value

    // console.log("파일 삭제 :" + fileSeq)

    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        console.log("파일 삭제 완료!!")

        getCurrentArticle(currentArticleSeq);
      }
    }

    request.del(`/api/upload/notice/${fileSeq}`, null, successHandler, (result) => { console.log("Error : " + result) }, null, null);
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "", value: 0 } })
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      if (alertLayerPopup.activateMode === 'uploadFileDelete') currentUploadFileDeleteDo()
    }
  }

  const prevArticleMove = (event) => {
    console.log("이전 글 ")

    if (nvlNumber(currentArticleSeq) > 0) {
      let pms = {
        noti_type: notiType,
        manager_position: managerInfo.role,
        search_keyword: searchKeyword
      }

      const successHandler = (response) => {
        console.log(response)

        if (response.messageCode === 200) {
          if (nvl(response.data) !== "" && nvlNumber(response.data.board_seq) > 0) articleViewActivate(response.data.board_seq)
          else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이전 글이 없습니다." } })
        }
        else console.log("뭔가 문제가 있는디?")
      }

      const failHandler = (result) => {
        console.log("에라야 에라~~" + result);
      }

      request.get(`/api/boards/notice/${currentArticleSeq}/prev?noti_type=${pms.noti_type}&manager_position=${pms.manager_position}&search_keyword=${pms.search_keyword}`, null, successHandler, failHandler, null, null);
    }
  }

  const nextArticleMove = (event) => {
    console.log("다음 글 ")

    if (nvlNumber(currentArticleSeq) > 0) {
      let pms = {
        noti_type: notiType,
        manager_position: managerInfo.role,
        search_keyword: searchKeyword
      }

      const successHandler = (response) => {

        if (response.messageCode === 200) {
          if (nvl(response.data) !== "" && nvlNumber(response.data.board_seq) > 0) articleViewActivate(response.data.board_seq)
          else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "다음 글이 없습니다." } })
        }
        else console.log("뭔가 문제가 있는디?")
      }

      const failHandler = (result) => {
        console.log("에라야 에라~~" + result);
      }

      request.get(`/api/boards/notice/${currentArticleSeq}/next?noti_type=${pms.noti_type}&manager_position=${pms.manager_position}&search_keyword=${pms.search_keyword}`, null, successHandler, failHandler, null, null);
    }
  }
  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            {/*//^ lnb2 버튼 영역 */}
            <div className="lnb2_menu_container">
              <a href="/notice?noti_type=I" className={nvl(notiType) === "I" || nvl(notiType) === "" ? "com_btn m blue" : "com_btn m lightgray"}>내부 공지</a>
              <a href="/notice?noti_type=B" className={nvl(notiType) === "B" ? "com_btn m blue" : "com_btn m lightgray"}>본사 공지</a>
            </div>
          </article>
          <article className="com_lnb2_right">
            {/*//^ lnb2 검색 영역 */}
            <div className="lnb2_search_wrap">
              <section className="com_field type_normal">
                <fieldset className="many">
                  <div className="input_del_wrap">
                    <input type="text" ref={searchKeywordRef} className="input" placeholder="공지사항 제목, 작성자 검색" onKeyDown={(e) => { if (e.key === 'Enter') keywordSearchDo(e) }} />
                    <button type="button" className="btn_input_del" onClick={keywordDeleteDo}></button>
                  </div>
                  <button type="button" className="com_btn m blue" onClick={keywordSearchDo}>조회</button>
                </fieldset>
              </section>
            </div>
          </article>
        </div>
      </section>
      <div className="com_content">
        <section className="com_board_list">
          <table>
            <tbody>
              <tr>
                <th className="th_no">No.</th>
                <th className="th_tit">제목</th>
                <th className="th_hit">조회수</th>
                <th className="th_writer">작성자</th>
                <th className="th_date">작성일</th>
              </tr>

              {
                (boardList == null || boardList.length < 1) ?
                  <tr>
                    <td colSpan="5">데이터가 없습니다.</td>
                  </tr>
                  :
                  boardList.map((item, index) => (
                    <tr key={item.board_seq}>
                      <td>{totalCount - (numPerPage * (currentPage - 1)) - index}</td>
                      <td className="td_tit" style={{ cursor: "pointer" }} onClick={() => articleViewActivate(item.board_seq)}><span className="ellipsis">{item.subject}</span></td>
                      <td>{item.hit}</td>
                      <td>{item.writer}</td>
                      <td>{moment(item.reg_date).format("YYYY-MM-DD")}</td>
                    </tr>
                  ))
              }

            </tbody>
          </table>
          <Paging totalCount={totalCount} currentPage={currentPage} numPerPage={numPerPage} pagePerBlock={pagePerBlock} nextPageMove={nextPageMove} />
        </section>
        {
          // nvl(articleViewSubject) !== "" ?
          <section className="com_board_view">
            <article className="top_area">
              <p className="tit_field">{articleViewSubject}</p>
              <p className="info_field">
                <span>{articleViewWriter}</span>
                <span>{nvl(articleViewRegDate) !== "" ? moment(articleViewRegDate).format("YYYY-MM-DD") : ""}</span>
              </p>
            </article>

            {
              (articleViewUploadFile == null || articleViewUploadFile.length < 1) ? null
                :
                articleViewUploadFile.map((item, index) => (
                  <article key={item.file_sys_name} className="info_area">
                    <p className="file_field">
                      <span className="tit">첨부파일</span>
                      <span onClick={() => fileDownload(item.up_file_seq)} className="file_link">{item.file_real_name}</span>
                    </p>
                    <p className="btn_field">
                      <button className="button" onClick={() => currentUploadFileDelete(item.up_file_seq)}><i className="svg_icon icon_trashcan yellow">&nbsp;</i>삭제</button>
                    </p>
                  </article>
                ))
            }

            <article className="contview_contentent scroll" style={{ minHeight: '100px' }}> {parse(articleViewContent)}</article>
            <article className="btn_area">
              <button className="com_btn line m blue" onClick={prevArticleMove}><i className="svg_icon icon_prev blue">&nbsp;</i>이전</button>
              <button className="com_btn m blue" onClick={nextArticleMove}>다음<i className="svg_icon icon_next white">&nbsp;</i></button>
              {nvl(notiType) === "I" && <button className="com_btn m line blue btn_right" onClick={noticeWriteGo}><i className="svg_icon icon_write yellow">&nbsp;</i>내부공지 {articleWriterSq === managerInfo.seq && currentArticleSeq > 0 ? "수정" : "작성"}하기</button>}
            </article>
          </section>
          // : null
        }
        {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue} /> : null}
      </div>
    </>
  )
}

export default Notice