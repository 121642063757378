//* id차단 팝업
import React from "react";
import LogoImg from'../../assets/img/logo2.svg';

const IDBlock = (props) => {
	return (
		<>
			{/* //* 알림 팝업 */}
      <section className="com_popup type_alert pop_id_block">
        <div className="pop_container m">
          <button type="button" className="svg_icon btn_pop_close btnPopClose"></button>
          {/* //^ 알림 디자인 본문 영역 */}
          <div className="pop_body">
            <article className="logo"><img src={LogoImg} alt="logo"/></article>
            <h1 className="alert_title">알림</h1>
            <p className="alert_title">본사 관리자에 의해 웹차트가 정지 되었습니다.</p>
            <p className="alert_text">본사 관리자에게 문의하세요</p>
            <p className="tel"><i className="svg_icon icon_tel red">&nbsp;</i>070-1234-4567</p>
          </div>
          {/* //^ 알림 디자인 버튼 영역 */}
          <div className="pop_footer com_btn_wrap">
            <button type="button" className="com_btn m blue btnPopClose">확인</button>
          </div>
        </div>
      </section>
		</>
	)
}

export default IDBlock