//* 공지사항 - 작성
import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';
import Editor from '../smartEditor/Editor';
import request from "utils/Request.utils.js";
import { nvl, nvlNumber } from "../../utils/Common.utils";
import PopupCalendar from '../../atoms/common/PopupCalendar';
import CustomAlert from '../../atoms/common/CustomAlert';
import { useLocation, useNavigate } from 'react-router-dom';

import AlertNoticeWriteOk from "./AlertWriteOk.js"; // 등록완료 알림팝업

import 'react-calendar/dist/Calendar.css'

const NoticeWrite = () => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [content, setContent] = useState("");
  const [uploadFile, setUploadFile] = useState(null);

  const uploadFileRef = useRef();
  const uploadFilePathRef = useRef();
  const subjectRef = useRef();
  const wishDate = useRef();

  const [viewType, setViewType] = useState('');
  const [dateType, setDateType] = useState('hyphen');
  const [notiWhen, setNotiWhen] = useState('now');
  const [notiTarget, setNotiTarget] = useState('all');
  const [boardSeq, setBoardSeq] = useState(''); // 수정할 공지사항 시퀀스

  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    if (location.state !== null) {
      subjectRef.current.value = location.state?.articleData.subject
      setContent(location.state.articleData.content)
      setBoardSeq(location.state.articleData.board_seq)
      if (location.state.uploadFileData.length > 0) {
        uploadFilePathRef.current.value = location.state.uploadFileData[0].file_real_name
      }
    }
  }, [location.state])


  const query = new URLSearchParams(window.location.search);
  const notiType = query.get('noti_type')

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const [noticeWriteOkPopup, setNoticeWriteOkPopup] = useState({
    closeType: 'next',
    alertMessage: '등록 되었습니다.',
    visible: false
  });


  // added by wem 20230913 불필요해 보여 주석 
  // useEffect(() => {
  //   if (nvl(notiType) === "") navigate("/notice/write?noti_type=I");

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const noticeWriteActivate = () => {
    if (nvl(subjectRef.current.value) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "제목을 입력해주세요." } })

      return;
    } else if (nvl(notiWhen) === "wish" && nvl(wishDate.current.value) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "공지일을 선택해주세요." } })

      return;
    } else if (nvl(content) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "내용을 입력해주세요." } })

      return;
    }

    if (nvl(uploadFile) !== "") submitUploadFile();
    else location.state === null ? submitNoticeArticle() : updateNoticeArticle();
  }

  const newContent = (value) => {

    setContent(value)
  }

  // 첨부파일 등록
  const selectUploadFile = () => {
    uploadFileRef.current.click();
  }

  const uploadFileHandler = (e) => {
    let currentId = e.target.getAttribute("id");
    let currentName = e.target.name;
    let currentValue = e.target.value;


    uploadFilePathRef.current.value = currentValue;

    setUploadFile(e.target.files[0]);
  }

  const submitUploadFile = () => {
    const formData = new FormData();

    formData.append("uploadFile", uploadFile)
    formData.append("file_cate", "notice")

    const successHandler = (response) => {

      if (response.messageCode === 200) location.state === null ? submitNoticeArticle(response.data) : updateNoticeArticle(response.data); // 첨부 파일이 있을 때는 첨부 파일을 먼저 입력하고 게시글을 입력
      else if (response.messageCode === 999) setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력중 에러가 발생했습니다.<br/>다시 시도해 주세요." } })
      else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 형식의 파일이 있습니다." } })

      uploadFileInit();
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "파일 첨부중 에러가 발생했습니다." } })

      uploadFileInit();
    }

    request.postMultipart("/api/upload/noticefile", formData, successHandler, failHandler, null, null);
  }


  //  공지 작성하기
  const submitNoticeArticle = (uploadFileSeq) => {
    let params = {
      noti_type: notiType,
      subject: nvl(subjectRef.current.value),
      noti_when: notiWhen,
      wish_date: nvl(wishDate.current.value),
      noti_target: notiTarget,
      content: content,
      noti_yn: "Y",
      writer: managerInfo.id,
      up_file_seq: nvlNumber(uploadFileSeq),
      reg_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {

      if (response.messageCode === 200) {
        navigate("/notice?noti_type=" + notiType);
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
    }

    request.post("/api/boards/notice", params, successHandler, failHandler, null, null);
  }

  // 공지 수정하기
  const updateNoticeArticle = (uploadFileSeq) => {
    let params = {
      noti_type: notiType,
      subject: nvl(subjectRef.current.value),
      noti_when: notiWhen,
      wish_date: nvl(wishDate.current.value),
      noti_target: notiTarget,
      content: content,
      noti_yn: "Y",
      writer: managerInfo.id,
      up_file_seq: nvlNumber(uploadFileSeq),
      modi_manager_seq: managerInfo.seq,
      board_seq: boardSeq
    }

    const successHandler = (response) => {
      console.log(response)

      if (response.messageCode === 200) {
        navigate("/notice?noti_type=" + notiType);
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
    }

    request.put("/api/boards/notice", params, successHandler, failHandler, null, null);
  }

  // 공지 삭제하기
  const deleteNoticeArticle = (boardSeq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {

        navigate("/notice?noti_type=" + notiType);
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }
    request.del(`/api/boards/notice/${boardSeq}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
  }

  const uploadFileInit = () => {
    uploadFilePathRef.current.value = "";
    uploadFileRef.current.value = "";

    setUploadFile(null);
  }

  const getCalendar = (value) => {

    wishDate.current.value = value;
  }

  const closePopup = () => {
    setViewType('');
  }

  const noticeDisplayType = (event) => {

    setNotiWhen(event.target.value)
  }

  const choiceNoticeTarget = (event) => {

    setNotiTarget(event.target.value)
  }

  const returnAlertValue = (value) => {
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const closeAlertNoticeWrite = () => {
    setNoticeWriteOkPopup((prev) => { return { ...prev, visible: false } })

    if (noticeWriteOkPopup.closeType === "next") {
      navigate("/notice?noti_type=" + notiType);

    }
  }

  return (
    <>
      <section className="com_content">
        <div className="com_board_write">
          <table className="write">
            <tbody>
              <tr>
                <th>제목 입력</th>
                <td className="td_tit"><input type="text" ref={subjectRef} placeholder="제목입력 (50자)" className="input" /></td>
              </tr>
              <tr>
                <th>첨부파일 등록</th>
                <td>
                  <input type="text" ref={uploadFilePathRef} placeholder="ZIP, JPG 파일 등록" className="input" readOnly />
                  <input type="file" id="uploadFile" name="files" className="input" ref={uploadFileRef} style={{ display: "none" }}
                    onChange={uploadFileHandler} />
                  <button className="com_btn m blue" onClick={selectUploadFile}>파일 찾기</button>
                </td>
              </tr>
              <tr>
                <th>공지일 선택</th>
                <td>
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="date01" name="SelectDate" onChange={noticeDisplayType}
                      checked={notiWhen === "now"} value="now" />
                    <label className="btn_radio" htmlFor="date01">&nbsp;</label>
                    <label htmlFor="date01">지금 등록</label>
                    <input type="radio" className="radio" id="date02" name="SelectDate" onChange={noticeDisplayType}
                      checked={notiWhen === "wish"} value="wish" />
                    <label className="btn_radio" htmlFor="date02">&nbsp;</label>
                    <label htmlFor="date02">
                      <input type="text" className="input datepicker" placeholder="2023. 01. 01" name="" ref={wishDate} onClick={() => { setViewType('wish'); setNotiWhen('wish') }} readOnly style={{ width: "130px" }} />&nbsp;
                    </label>
                  </fieldset>
                  {viewType !== '' ? <PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={dateType} /> : null}
                </td>
              </tr>
              <tr>
                <th>노출 관리자 선택</th>
                <td>
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="admin01" name="SelectAdmin" onChange={choiceNoticeTarget}
                      checked={notiTarget === "all"} value="all" />
                    <label className="btn_radio" htmlFor="admin01">&nbsp;</label>
                    <label htmlFor="admin01">전체 관리자</label>
                    <input type="radio" className="radio" id="admin02" name="SelectAdmin" onChange={choiceNoticeTarget}
                      checked={notiTarget === "009002000"} value="009002000" />
                    <label className="btn_radio" htmlFor="admin02">&nbsp;</label>
                    <label htmlFor="admin02">중 관리자</label>
                    <input type="radio" className="radio" id="admin03" name="SelectAdmin" onChange={choiceNoticeTarget}
                      checked={notiTarget === "009003000"} value="009003000" />
                    <label className="btn_radio" htmlFor="admin03">&nbsp;</label>
                    <label htmlFor="admin03">하 관리자</label>
                  </fieldset>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="editor" style={{ minHeight: '400px' }}>
            <Editor
              height={400}
              data={content}
              newContent={newContent}
            />
          </div>
          <article className="btn_area">
            <button className="com_btn m blue" onClick={noticeWriteActivate}>{location.state === null ? "등록" : "수정"}</button>
            {location.state !== null && <button className="com_btn blue line m" onClick={() => deleteNoticeArticle(boardSeq)}>삭제</button>}
          </article>
        </div>
      </section>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage}
        returnValue={returnAlertValue} /> : null}
      <AlertNoticeWriteOk onClose={closeAlertNoticeWrite} popupVisible={noticeWriteOkPopup.visible} />
    </>
  )
}

export default NoticeWrite