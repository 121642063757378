//* 결산-담당별
import React, { useState, useRef } from "react";
import StatisticsSubButton from "atoms/statistics/StatisticsSubButton";
import StatisticsSubDateSearch from "atoms/statistics/StatisticsSubDateSearch";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { getTodayDate, getPreviousWeekDate, getPreviousMonthDate, getPreviousYearDate } from "../../utils/Common.utils";
import { utils, writeFile } from 'xlsx';

const CalcStaff = () => {
  const componentRef = useRef();
  const tableRef = useRef();
  const today = getTodayDate()?.slice(0,17);
  const todayDate = moment(new Date()).format("YYYYMMDD");
  const [periodOption, setPeriodOption] = useState(null);
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodValue, setPeriodValue] = useState(0);

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `담당별_${todayDate}`,
  });

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    } else if(getValue === 2){
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    } else{
      setStartDate(getPreviousYearDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }

  const setPeriodSearchDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('4');
    setPeriodValue(1);
  }

  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `담당별_${todayDate}.xlsx`);
  };


	return (
		<>
		<section className="section com_lnb2">
			<div className="inner">
        <article className="com_lnb2_left">
          {/*//^ lnb2 버튼 영역 */}
          <div className="lnb2_menu_container">
          <StatisticsSubButton subButtonAction={subButtonAction} />
          </div>
        </article>
        <article className="com_lnb2_right">
          <StatisticsSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate}/>
        </article>
			</div>
		</section>
		<section className="com_content">
      <div ref={componentRef}>
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="date">{periodValue === 0 ?  `${startDate}` : `${startDate} ~ ${endDate}`}</p>
              <p className="num">매출내역</p>
            </div>
          </article>
          <div className="scroll with_btn_bottom">
            <table className="list" ref={tableRef}>
              <colgroup><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col width="7.1%"/><col/></colgroup>
              <thead className="sticky top">
                <tr>
                  <th colSpan="14">담당별 집계</th>
                </tr>
                <tr>
                  <th>담당</th>
                  <th>이름</th>
                  <th>비과세급여</th>
                  <th>과세비급여총액</th>
                  <th>과세비급여</th>
                  <th>부가세</th>
                  <th>급여본부금</th>
                  <th>급여청구액</th>
                  <th>결제할 금액</th>
                  <th>지원금</th>
                  <th>총매출액</th>
                  <th>환불</th>
                  <th>결제</th>
                  <th>매출비율</th>
                </tr>
                {/* <tr className="total">
                  <th colSpan="2"><span className="txt_blue">합계</span></th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th><span className="txt_blue">100%</span></th>
                </tr> */}
              </thead>
              <tbody>
              <tr>
                <td>접수</td>
                <td>가길동</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0.00%</td>
              </tr>
              <tr className="total">
                <td colSpan="2"><span className="txt_blue">합계</span></td>
                <td>0</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><span className="txt_blue">100%</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="com_btn_bottom_area">
        <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
        <button className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
      </div>
		</section>
		</>
	)
}

export default CalcStaff