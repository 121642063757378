//* 마취 시작하기 알람
import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import moment from "moment";
import CustomAlert from "atoms/common/CustomAlert";

import { nvl, nvlNumber } from "utils/Common.utils";

const AnesthesiaAlarm = ({ onClose, popup, changeTimerStatus, timers, btnTimerSubmitHandler }) => {
  const nodeRef = useRef(null);
  const [anesthesiaTime, setAnesthesiaTime] = useState({ hour: "", minute: "" })

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    closeType: ''
  });

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }
  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
    }
  }

  const hours = Array.from({ length: 24 }, (_, index) => index);
  const minutes = Array.from({ length: 60 }, (_, index) => index);

  // 시간, 분 뽑아오기
  useEffect(() => {
    const filteredTimers = timers.find(timer => timer.value === 1 && timer.user_seq === popup.user_seq && timer.order_seq === popup.order_seq && timer.alarmTime);

    if (filteredTimers) {
      // const timeDiff = filteredTimers.alarmTime - filteredTimers.startTime
      // console.log(timeDiff, "timeDiff")
      // alarmTime 그 자체로 시, 분 가져오기
      const alarmHour = Math.floor((filteredTimers.alarmTime % (60 * 60 * 24)) / (60 * 60)).toString();
      const alarmMinute = Math.floor((filteredTimers.alarmTime % (60 * 60)) / (60)).toString();
      console.log(alarmHour, alarmMinute, "asdf")
      setAnesthesiaTime({ hour: alarmHour, minute: alarmMinute });
    }
    else {
      setAnesthesiaTime({ hour: "0", minute: "0" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup.order_seq, popup.user_seq]);



  const selectAnesthesiaTime = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, "asdf")
    setAnesthesiaTime({ ...anesthesiaTime, [name]: value })
  }


  //알람 저장 - 기존, 사용안함 KSR
  /*
  const saveAlarm = () => {
    if (nvl(anesthesiaTime.hour) === "" && nvl(anesthesiaTime.minute) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "시간 또는 분을 선택해주세요." } })
      return
    }
    const alarmTime = (parseInt(anesthesiaTime.hour) * 60 * 60 * 1000) + (parseInt(anesthesiaTime.minute) * 60 * 1000);

    const keyParams = {
      order_date: moment().format("YYYY-MM-DD"),
      user_seq: popup.user_seq,
      order_seq: popup.order_seq,
      value: 1
    }
    const valueParams = {
      order_status: "013008000",
      alarmTime: alarmTime,
      text: "마취중(알람)",
      active: true,
      isLive: true
    }


    changeTimerStatus(keyParams, valueParams);

    onClose()
  }
  */

  //알람 저장 2023-09-17 KSR
  const saveAlarm2 = () => {
    if (nvl(anesthesiaTime.hour) === "" && nvl(anesthesiaTime.minute) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "시간 또는 분을 선택해주세요." } })
      return
    }
    const alarmTime = (parseInt(anesthesiaTime.hour) * 60 * 60) + (parseInt(anesthesiaTime.minute) * 60);

    let cTimer = timers.find((timer) => timer.order_seq === popup.order_seq && timer.value === 1);
    cTimer = { ...cTimer, alarmTime: alarmTime, text: "마취중(알람)", order_status: "013008000", className: "alarm" }

    btnTimerSubmitHandler(cTimer);  //parent :: PUT > 타이머 상태 변경

    onClose()
  }


  return (
    <>
      <section className="com_popup type_normal pop_anesthesia_alarm active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">마취 시작하기</h1>
            </div>
            <div className="pop_body">
              <ul className="input_section">
                <li className="tit_field">지금부터</li>
                <li className="input_field">
                  <select className="select" name="hour" value={anesthesiaTime.hour} onChange={(e) => selectAnesthesiaTime(e)}>
                    {hours.map(hour => (
                      <option key={hour} value={hour}>{hour}</option>
                    ))}
                  </select>
                  시간
                  <select className="select" name="minute" value={anesthesiaTime.minute} onChange={(e) => selectAnesthesiaTime(e)}>
                    {/* <option value="0">0</option> */}
                    {minutes.map((minute, i) => (
                      <option key={i} value={minute}>{minute}</option>
                    ))}
                  </select>
                  분
                </li>
                <li className="tit_field">뒤 알람 울리기</li>
              </ul>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn blue l" onClick={() => saveAlarm2()}>확인</button>
            </div>
          </div>
        </Draggable>
      </section>
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
    </>
  )
}

export default AnesthesiaAlarm
