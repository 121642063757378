//* 결산-접수별
import React, { useEffect, useState, useRef } from "react";
import StatisticsSubButton from "atoms/statistics/StatisticsSubButton";
import StatisticsSubDateSearch from "atoms/statistics/StatisticsSubDateSearch";
import { getTodayDate, getPreviousWeekDate, getPreviousMonthDate, getPreviousYearDate } from "../../utils/Common.utils";
import Graph from "atoms/common/graph/graph";

const CalcRegister = () => {
  const today = getTodayDate()?.slice(0,17);
  const [periodOption, setPeriodOption] = useState(null);
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodValue, setPeriodValue] = useState(0);
  const [dateArray, setDateArray] = useState([]);
  const [calRegData, setCalRegData] = useState([]);
  const [calRegTotal, setCalRegTotal] = useState([]);
  const calRegTotalCount = calRegTotal.reduce((acc, cur) => acc + cur.total_count, 0);

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    } else if(getValue === 2){
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    } else{
      setStartDate(getPreviousYearDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }

  const setPeriodSearchDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('4');
    setPeriodValue(1);
  }
  

	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <StatisticsSubButton subButtonAction={subButtonAction} />
            </div>
          </article>
          <article className="com_lnb2_right">
            <StatisticsSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate}/>
          </article>
        </div>
      </section>
      <section className="com_content p_calc_register">
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="date">{periodValue === 0 ?  `${startDate}` : `${startDate} ~ ${endDate}`}</p>
            </div>
          </article>
          <article className="chart_area">
            차트영역
            <Graph dateArray={dateArray} data={calRegData}/>
          </article>
        </div>
        <div className="com_list box">
          <table className="list">
            <colgroup><col width="24.6%"/><col width="24.6%"/><col width="24.6%"/><col/></colgroup>
            <thead>
              <tr>
                <th colSpan="4">접수별 집계</th>
              </tr>
              <tr>
                <th>접수형태</th>
                <th>초진(20)</th>
                <th>재진(09)</th>
                <th>총 매출액</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>인원</td>
                <td>0</td>
                <td>0</td>
                <td rowSpan="3" className="total">0</td>
              </tr>
              <tr>
                <td>매출액</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>매출비율</td>
                <td>0</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
		</>
	)
}

export default CalcRegister