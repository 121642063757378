import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import '../../styles/scss/component/_colorPicker.scss';

export default function ColorPicker (props) {
  const [selectedColor, setSelectedColor] = useState({ background: '#FFFFFF' });
  const [presetColors, setPresetColors] = useState([
    '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505',
    '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000',
    '#4A4A4A', '#9B9B9B', '#FFFFFF'
  ]); // 흠.. 이건 좀 나중에 필요하다면 해야겠구만..

  let handleChangeComplete = (color) => {
    setSelectedColor({ background: color.hex.toUpperCase() });
  };

  const onClickConfirm = () => {
    // console.log("color : " + selectedColor.background);

    props.getColor(selectedColor.background);

    paletteClose();
  }

  function paletteClose() {
    // console.log("닫혀라 참깨~!");

    props.onClose();
  }

  return(
    <div className='popupColorPicker'>
      <SketchPicker className='colorPicker' color={ selectedColor.background } // presetColors={ presetColors }
        onChangeComplete={ handleChangeComplete } />
      <div className='buttonWrapper'>
        <button className='cancelButton' type='button' onClick={ () => { paletteClose() } } >닫기</button>
        <button className='confirmButton' type='button' onClick={ () => { onClickConfirm() } } >확인</button>
      </div>
    </div>
  )
}
