// *관리>동의서관리
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';

import _ConsentCategoryManage from "./_ConsentCategoryManage.js"; // 동의서 카테고리 관리
import request from '../../utils/Request.utils';
import { nvl, nvlNumber } from "../../utils/Common.utils";
import CustomAlert from '../../atoms/common/CustomAlert';

const Consent = () => {
  const navigate = useNavigate();
  moment.locale('ko-KR');

  const [consentCategoryPopupBool, setConsentCategoryPopupBool] = useState(false);
  const [consentList, setConsentList] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState('');
  
  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const getConsentList = () => {
    let params = {
      searchKeyword: searchKeyword,
      orderBy: "agree_seq DESC",
      limit: 10,
      offset: 0
    }

    const successHandler = (response) => {
      console.log(response)

      if(response.messageCode === 200) { // ok 코드
        setConsentList(response.data.dataList);
        setTotalCnt(response.data.totalCnt);
      } else {
        console.log("데이터가 없습니다..");
      }
    }

    request.get(`/api/etc/agreements?limit=${params.limit}&offset=${params.offset}&orderBy=${params.orderBy}&searchKeyword=${searchKeyword}&deleteYn=N`, null, successHandler);
  }

  useEffect(() => {
    getConsentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  const handleOnKeyPress = (e) => {
    if(e.key === 'Enter') getConsentList();
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const returnAlertValue = (value) => {
    // console.log("value ==> " + value);

    if(nvl(value) === 'OK') {
      // 창 닫히고 처리할 거 
    }
  }

	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_search_wrap">
              <fieldset className="many">
                <input type="text" className="input" placeholder="동의서명 검색" onChange={ (e) => { setSearchKeyword(e.target.value) } } value={ searchKeyword }
                  onKeyDown={ handleOnKeyPress } />
                <button className="com_btn blue m" onClick={ (e) => { getConsentList() } }>조회</button>
              </fieldset>
            </div>
          </article>
          <article className="com_lnb2_right">
            <button className="com_btn blue m w_auto" onClick={ () => navigate('/boffice/consent_add') }><i className="svg_icon icon_set yellow">&nbsp;</i>동의서 추가</button>
            <button className="com_btn blue m w_auto" onClick={ () => setConsentCategoryPopupBool(true) }><i className="svg_icon icon_set yellow">&nbsp;</i>동의서 카테고리 관리</button>
          </article>
        </div>
      </section>
      <section className="com_content">
        <div className="com_list box">
          <div className="scroll">
            <table className="list">
              <colgroup><col width="8.4%"/><col width="8.4%"/><col width="28.8%"/><col/><col width="8.4%"/><col width="8.4%"/><col width="8.4%"/></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>등록일</th>
                  <th>카테고리</th>
                  <th>동의서 명</th>
                  <th>동의서 내용</th>
                  <th>등록자</th>
                  <th>노출</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
                {
                  (consentList == null || consentList.length < 1) ?
                    <tr>
                      <td colSpan="7">데이터가 없습니다.</td>
                    </tr>
                  :
                    consentList.map((item, index) => (
                      <tr key={ item.agree_seq }>
                        <td>{ moment(item.reg_data).format("YYYY, MM, DD") }</td>
                        <td>{ item.agree_cate_name }</td>
                        <td>{ item.agree_name }</td>
                        <td>{ parse(item.agree_description) }</td>
                        <td>{ item.manager_name }</td>
                        <td>{ nvl(item.display_yn) === 'Y' ? '노출' : '비노출' }</td>
                        <td>
                        <Link to={"/boffice/consent_add"} state={{ agree_seq: item.agree_seq, editBoolean: true }}><button className="com_btn txt">상세보기</button></Link>
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
      { consentCategoryPopupBool && <_ConsentCategoryManage consentCategoryPopupBool={ consentCategoryPopupBool } setConsentCategoryPopupBool={ setConsentCategoryPopupBool } /> }
      { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> : null }
		</>
	)
}

export default Consent