//* 계좌 등록 완료
import React from "react";

const AccountOk = () => {
	return (
		<>
			{/* //* 알림 팝업 */}
      <section className="com_popup type_alert">{/*클래스 active 추가시 노출*/}
					<div className="pop_container">
						<button type="button" className="svg_icon btn_pop_close btnPopClose"></button>
						{/* //^ 알림 디자인 본문 영역 */}
						<div className="pop_body">
							<p className="alert_text">계좌가 등록 되었습니다.</p>
						</div>
						{/* //^ 알림 디자인 버튼 영역 */}
						<div className="pop_footer com_btn_wrap">
							<button type="button" className="com_btn m blue btnPopClose">확인</button>
						</div>
					</div>
        </section>
		</>
	)
}

export default AccountOk