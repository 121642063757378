//* 고객만족도
import React, { useEffect, useState, useRef } from "react";
import request from "utils/Request.utils";
import SatisfactionView from './_SatisfactionView.js';//답변 상세 팝업
import CustomerSubButton from "../../atoms/crm/SubButton";
import CustomerSubDateSearch from "../../atoms/crm/SubDateSearch";
import SnsSend from "components/common/_SnsSend.js";
import { decrypt } from "../../utils/aes256.manager";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { convertGender, getTodayDate, getPreviousWeekDate, getPreviousMonthDate, convertJuminToAge, changeMobileFormat, nvl } from "../../utils/Common.utils";
import { utils, writeFile } from 'xlsx';

const Satisfaction = () => {
  const componentRef = useRef();
  const tableRef = useRef();
  const [satisfactionList, setSatisfactionList] = useState([{
    sfn_seq: 0,
    send_date: '',
    date: '',
    hm: '',
    user_name: '',
    user_gender: '',
    user_jumin: '',
    user_mobile: '',
    business_place_point: 0,
    receptionist_point: 0,
    counselor_point: 0,
    incharge_point: 0,
    assist_point: 0,
    treatment_point: 0,
    revisitation_point: 1,
    recommend_point: 1,
    wish_goods: '',
    remark: '',
    user_avg: 0
  }]);
  const [answerDisplayBool, setAnswerDisplayBool] = useState(false); //답변
  const [popData, setPopData] = useState(false); //팝업
  const [avg, setAvg] = useState(0); //전체 평균
  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState(10);
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0, 17));
  const [endDate, setEndDate] = useState('');
  const [periodOption, setPeriodOption] = useState(null);
  const [periodValue, setPeriodValue] = useState(0);
  const [snsSendBool, setSnsSendBool] = useState(false);

  const today = getTodayDate()?.slice(0,17);
  const todayDate = moment(new Date()).format("YYYYMMDD");

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    }else{
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }


  const setPeriodSearchDate = (startDate, endDate) => {
    // console.log(startDate + " | " + endDate)

    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('3');
    setPeriodValue(1);
  }

  const AnswerDetailToggle = (sfn_seq) => {
    const copyData = satisfactionList.filter(el => el.sfn_seq === sfn_seq);
    setPopData([...copyData][0]);
    setAnswerDisplayBool(!answerDisplayBool);
  }

  // 고객 만족도 리스트 가져오기
  const getUserSurvey = () => {

    let params = {
      limit: numPerPage,
      offset: ((currentPage - 1) * numPerPage),
      periodOption: periodOption,
      // delete_yn: "N"
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data.dataList.map((el, i) => ({
          sfn_seq: el.sfn_seq,
          send_date: el.send_date,
          date: moment(el.res_date).format("YYYY-MM-DD"),
          hm: new Date(el.res_date)?.toString().split(' ')[4],
          user_name: el.user_name,
          user_gender: el.user_gender,
          user_jumin: el.user_jumin,
          user_mobile: el.user_mobile,
          business_place_point: el.business_place_point,
          receptionist_point: el.receptionist_point,
          counselor_point: el.counselor_point,
          incharge_point: el.incharge_point,
          assist_point: el.assist_point,
          treatment_point: el.treatment_point,
          revisitation_point: el.revisitation_point,
          recommend_point: el.recommend_point,
          wish_goods: el.wish_goods,
          remark: el.remark,
          user_avg: (el.business_place_point + el.receptionist_point + el.counselor_point + el.incharge_point + el.assist_point + el.treatment_point) / 6
        }))
        setSatisfactionList(result);
      } else {
        console.log("고객 만족도 목록을 가져오지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/etc/satisfaction?limit=${params.limit}&offset=${params.offset}&periodOption=${params.periodOption}&startDate=${startDate}&endDate=${endDate}`, null, successHandler, failHandler, null, null);
  }

  // 고객 만족도 평균 구하기
  const calculateAvg = () => {
    const avgList = satisfactionList.map((el) => (el.user_avg));
    const result = avgList.reduce((sum, cur) => {
      return sum + cur
    }, 0);
    setAvg(result / avgList.length);
  }

  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `고객만족도_평균${todayDate}`,
  });

  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `고객만족도_평균${todayDate}.xlsx`);
  };

  useEffect(() => {
    getUserSurvey(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodOption, startDate])

  useEffect(() => {
    calculateAvg(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satisfactionList])


  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <CustomerSubButton buttonHandler={subButtonAction} />
          <CustomerSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate} periodOption={periodOption} />
        </div>
      </section>
      <section className="com_content">
        <div ref={el => componentRef.current = el}>
          <div className="com_list box">
            <article className="top_area">
              <div className="info_field">
                <p className="date">{periodValue === 0 ? `${startDate}` : `${startDate} ~ ${endDate}`}</p>
                <p className="num">고객만족도 평균점수 ({isNaN(avg) ? "0.00" : avg.toFixed(2)}점)</p>
              </div>
              <div className="btn_field">
                <button className="com_btn blue m" onClick={() => setSnsSendBool(!snsSendBool)}><i className="svg_icon icon_sms yellow">&nbsp;</i>문자보내기</button>
              </div>
            </article>
            <div className="scroll with_btn_bottom">
              <table className="list" ref={tableRef}>
                <colgroup><col width="4%" /><col width="7%" /><col width="6%" /><col width="6%" /><col width="4%" /><col width="4%" /><col width="4%" /><col width="4%" /><col width="4%" /><col width="4%" /><col width="4%" /><col width="4%" /><col width="4%" /><col width="10%" /><col width="10%" /></colgroup>
                <thead className="sticky top">
                  <tr>
                    {/* <th rowSpan="2">선택</th> */}
                    <th colSpan="2">일시</th>
                    <th colSpan="3">고객정보</th>
                    <th colSpan="8">응답결과</th>
                    <th colSpan="2">서술답변</th>
                  </tr>
                  <tr>
                    <th>발송일시</th>
                    <th>응답일시</th>
                    <th>이름</th>
                    <th>휴대폰번호</th>
                    <th>초진 / 재진</th>
                    <th>지점</th>
                    <th>접수</th>
                    <th>상담</th>
                    <th>담당의</th>
                    <th>어시스트</th>
                    <th>에스테틱</th>
                    <th>재방문의사</th>
                    <th>추천의사</th>
                    <th>답변1</th>
                    <th>답변2</th>
                  </tr>
                </thead>
                <tbody>
                  {satisfactionList.length > 0 ? satisfactionList.map((el, i) => (
                    <tr key={i}>
                      <td>2022-04-20</td>
                      <td>{el.date} {el.hm}</td>
                      <td>{`${el.user_name} (${convertGender(el.user_gender)}/${nvl(el.user_jumin) === "" ? " - " : convertJuminToAge((decrypt(el.user_jumin).substring(0, 6) + "-" + decrypt(el.user_jumin).substring(6, 13)))})`} </td>
                      <td>{changeMobileFormat(el.user_mobile)}</td>
                      <td>초진</td>
                      <td>{el.business_place_point}</td>
                      <td>{el.receptionist_point}</td>
                      <td>{el.counselor_point}</td>
                      <td>{el.incharge_point}</td>
                      <td>{el.assist_point}</td>
                      <td>{el.treatment_point}</td>
                      <td>{el.revisitation_point === 1 ? 'Y' : 'N'}</td>
                      <td>{el.recommend_point === 1 ? 'Y' : 'N'}</td>
                      <td>{el.wish_goods === '' ? "-" : el.wish_goods}</td>
                      <td onClick={(e) => { AnswerDetailToggle(el.sfn_seq) }} style={{cursor:"pointer"}}>{el.remark === '' ? "-" : el.remark}</td>
                    </tr>
                  )) :
                    <tr>
                      <td colSpan="15">데이터가 없습니다.</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="com_btn_bottom_area">
          <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
          <button type="button" className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
        </div>
      </section>
      <SatisfactionView answerDisplayBool={answerDisplayBool} AnswerDetailToggle={AnswerDetailToggle} popData={popData} />
      {snsSendBool && <SnsSend snsSendBool={snsSendBool} setSnsSendBool={setSnsSendBool} />}
    </>
  )
}

export default Satisfaction