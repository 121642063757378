//*시술지급 쿠폰
import React, { useRef, useEffect, useState } from "react";
import request from "utils/Request.utils";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert";
import { nvl, nvlOnlyNumber } from "utils/Common.utils";

const CouponProcedure = ({surgeryCouponRegDisplayBool, couponRegToggle, setSurgeryCategory, onUpdateBool, setOnUpdateBool, setSurgeryCategoryDetail, categoryShown, setCategoryShown, popData, setPopData, categoryDetail, surgeryCategory, surgeryCategoryDetail, category, getSurgery, getSurgeryDetail, autoCouponSeq, getProcedureCouponList, alertLayerPopup, setAlertLayerPopup, closeCustomAlert, deleteCoupon, setCheckedItems}) => {
const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
const [surgeryCategoryDetailList, setSurgeryCategoryDetailList] = useState([]);
const [procedureData, setProcedureData] = useState({
    coupon_type: '5', //쿠폰종류,
    coupon_name: '', //쿠폰명
    goods_cate: '', //시술 카테고리
    goods_seq: '', //시술 선택
    radio_button: 'Y', //할인율, 할인금액 라디오버튼
    discount_rate: '', //할인율
    coupon_price: '', //할인 금액
    expired_date: '', //유효기간
    treatment_times: 0, //회차선택 and 지급회차
    attendance_times: '', //출석 회차
    payment_attainment: '', //결제 도달 금액
    procedure_yn: 'Y'
  })
  let numArr = [1,2,3,4,5];
  const popupSectionRef = useRef();
  const nodeRef = useRef(null);

  if(surgeryCouponRegDisplayBool) popupSectionRef?.current?.classList.add('active');
  else if(!surgeryCouponRegDisplayBool && popupSectionRef.current) popupSectionRef?.current?.classList.remove('active');

  //초기화
  const resetPopup = () => {
    setProcedureData({
      coupon_type: '5',
      coupon_name: '', //쿠폰명
      goods_cate: '', //시술 카테고리
      goods_seq: '', //시술 선택
      discount_radio_button: 'Y', //할인율, 할인금액 라디오버튼
      discount_rate: '', //할인율
      coupon_price: '', //할인 금액
      expired_date: '', //유효기간
      treatment_times: 0, //회차선택 and 지급회차
      attendance_times: '', //출석 회차
      payment_attainment: '', //결제 도달 금액
      procedure_yn: 'Y'
    })
    setSurgeryCategory(""); // 시술 카테고리 초기화
    setSurgeryCategoryDetail(""); // 세부 시술 초기화
    setCategoryShown([]); // 세부 시술 리스트 초기화
    setPopData({
      coupon_type: '5',
      coupon_name: '', //쿠폰명
      goods_cate: '', //시술 카테고리
      goods_seq: '', //시술 선택
      discount_radio_button: 'Y', //할인율, 할인금액 라디오버튼
      discount_rate: '', //할인율
      coupon_price: '', //할인 금액
      expired_date: '', //유효기간
      treatment_times: 0, //회차선택 and 지급회차
      attendance_times: '', //출석 회차
      payment_attainment: '', //결제 도달 금액
      coupon_status: 'Y'
    }); //팝업 데이터 
    setOnUpdateBool(false); //수정 초기화
  }

  const popupClose = () => {
    resetPopup();
    couponRegToggle(3);
  }

  const onChangeProcedure = (e) => {
    const { name, value } = e.target;
    if(!onUpdateBool){ // 등록
      if(name === "coupon_name"){
        setProcedureData({...procedureData, coupon_name:value});
      } else if (name === "surgery_category") {
        const newArray = categoryDetail.filter(el => value === el.category);
        setCategoryShown(newArray);
      } else if (name === "surgery_category_detail"){
        setSurgeryCategoryDetail(value);
        if (surgeryCategoryDetailList.findIndex(el => value === el.category)) {
          return false;
        }
        setSurgeryCategory(""); // 시술 카테고리 초기화
        setSurgeryCategoryDetail(""); // 세부 시술 초기화
        setCategoryShown([]); // 세부 시술 리스트 초기화
      } else if (name === "expired_date"){ //유효기간
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regProcedureCoupon"}});
          setProcedureData({...procedureData, expired_date:''});
          return false;
        }else{
          setProcedureData({...procedureData, expired_date:value});
        }
      } else if (name === "treatment_times"){
        setProcedureData({...procedureData, treatment_times:value});
      } else if (name === "procedure_yn"){
        setProcedureData({...procedureData, procedure_yn:value});
      }
    } else{ // 수정
      if(name === "coupon_name"){
        setPopData({...popData, coupon_name:value});
      } else if (name === "surgery_category") {
        const newArray = categoryDetail.filter(el => value === el.category);
        setCategoryShown(newArray);
        setPopData({...popData, goods_cate:value});
      } else if (name === "surgery_category_detail"){
        setSurgeryCategoryDetail(value);
        setPopData({...popData, goods_seq:value});
        if (surgeryCategoryDetailList.findIndex(el => value === el.category)) {
          return false;
        }
        setSurgeryCategory(""); // 시술 카테고리 초기화
        setSurgeryCategoryDetail(""); // 세부 시술 초기화
        setCategoryShown([]); // 세부 시술 리스트 초기화
      } else if (name === "useType"){
        setPopData({...popData, coupon_status:value});
      } else if (name === "expired_date"){ //유효기간
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateProcedureCoupon"}});
          setPopData({...popData, expired_date:''});
          return false;
        }else{
          setPopData({...popData, expired_date:value});
        }
      } else if (name === "attendance_times"){ //출석회차
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateProcedureCoupon"}});
          setPopData({...popData, attendance_times:''});
          return false;
        }else{
          setPopData({...popData, attendance_times:value});
        }
      } else if (name === "payment_attainment"){
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateProcedureCoupon"}});
          setPopData({...popData, payment_attainment:''});
          return false;
        }else{
          setPopData({...popData, payment_attainment:value});
        }
      } else if (name === "treatment_times"){
        setPopData({...popData, treatment_times:value});
      } else if (name === "procedure_yn"){
        setPopData({...popData, coupon_status:value});
      }
    }
  };

  // 쿠폰 등록 & 수정 버튼 클릭
  const registerCoupon = () => {
    if (!onUpdateBool) { // 쿠폰 등록
      if (procedureData.coupon_name === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "쿠폰 명을 입력해주세요.", activateMode: "regProcedureCoupon"}});
        return;
      } else if (surgeryCategory === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 카테고리를 선택해주세요.", activateMode: "regProcedureCoupon"}});
        return;
      } else if(surgeryCategoryDetail === ""){
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술을 선택해주세요.", activateMode: "regProcedureCoupon"}});
          return;
        } else if (procedureData.treatment_times === 0) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "지급 회차를 선택해주세요.", activateMode: "regProcedureCoupon"}});
        return;
      } else if (procedureData.expired_date === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "유효기간을 입력해주세요.", activateMode: "regProcedureCoupon"}});
        return;
      } 
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: `시술지급 쿠폰을 등록하시겠습니까?`, activateMode: "regProcedureCoupon"}});
    } else { //쿠폰 수정
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "쿠폰을 수정하시겠습니까?", activateMode: "updateProcedureCoupon"}});
    }
  }


  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (onUpdateBool === false && alertLayerPopup.activateMode === "regProcedureCoupon") {
        let params = {
          coupon_image: "",
          coupon_type: procedureData.coupon_type,
          coupon_name: procedureData.coupon_name,
          coupon_description: "",
          goods_cate:surgeryCategory,
          goods_seq: surgeryCategoryDetail === "" ? 0 : surgeryCategoryDetail,
          treatment_times: procedureData.treatment_times, //시술회차
          discount_rate: 0,
          coupon_price: 0,
          attendance_times: procedureData.coupon_type === "1" ? procedureData.attendance_times : 0, //출석회차
          payment_attainment: procedureData.coupon_type === "3" ? procedureData.payment_attainment : 0, //지급회차
          remark: "",
          coupon_status: procedureData.procedure_yn,
          expired_date: procedureData.expired_date,
          use_start_date: null,
          use_end_date: null,
          modi_manager_seq: managerInfo.seq,
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `쿠폰이 등록되었습니다.`,activateMode:''}});
            getProcedureCouponList();
            popupClose();
          } else {
            console.log(`쿠폰을 등록하지 못했습니다.`)
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/coupon", params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "updateProcedureCoupon") { // 쿠폰 수정
        let params = {
          coupon_seq: autoCouponSeq,
          coupon_image: "",
          coupon_type: 5,
          coupon_name: popData.coupon_name,
          coupon_description: "",
          goods_cate: popData.goods_cate,
          goods_seq: popData?.goods_seq,
          treatment_times: popData.treatment_times, //시술회차
          discount_rate: 0,
          coupon_price: 0,
          attendance_times: popData.attendance_times ? popData.attendance_times : 0, //출석회차
          payment_attainment: popData.payment_attainment ? popData.payment_attainment : 0, //지급회차
          remark: "",
          coupon_status: popData.coupon_status,
          expired_date: popData.expired_date,
          use_start_date: null,
          use_end_date: null,
          modi_manager_seq: managerInfo.seq,
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            getProcedureCouponList();
            popupClose();
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "시술지급 쿠폰을 수정하였습니다." } });
          } else {
            console.log("쿠폰을 수정하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.put("/api/coupon", params, successHandler, failHandler, null, null);
      } else if(onUpdateBool && alertLayerPopup.activateMode === "deleteProcedureCoupon"){ 
        const queryString = deleteCoupon.map(el => `id=${el}`).join('&');
  
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "쿠폰을 삭제하였습니다." } });
            setOnUpdateBool(false);
            getProcedureCouponList();
            setCheckedItems([]);
          } else {
            console.log("쿠폰을 삭제하지 못했습니다.");
          }
        }
  
        const failHandler = (response) => {
          console.log("에러" + response);
        }
        
        request.del(`/api/coupon/${queryString}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
      }
    } 
    // getProcedureCouponList();
  }


	return (
		<>
			 {alertLayerPopup.visible && (alertLayerPopup.activateMode === "regProcedureCoupon" || alertLayerPopup.activateMode === "updateProcedureCoupon" || alertLayerPopup.activateMode === "deleteProcedureCoupon") ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
      <section ref={popupSectionRef} className="com_popup type_normal">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={popupClose}></button>
            <div className="pop_header">
              <h1 className="pop_title">시술지급 쿠폰</h1>
            </div>
            <div className="pop_body">
              <ul className="com_input_section">
                <li className="tit_field">쿠폰 명</li>
                <li className="input_field">
                  <input type="text" className="input" placeholder="쿠폰 명 입력" name="coupon_name" value={!onUpdateBool ? procedureData.coupon_name : popData.coupon_name || ''} onChange={(e) => onChangeProcedure(e)}/>
                </li>
                <li className="tit_field">시술 카테고리</li>
                <li className="input_field">
                  <select className="select" name="surgery_category" id="surgery_category" value={!onUpdateBool ? surgeryCategory : popData.goods_cate || ''} onChange={(e) => {
                      setSurgeryCategory(e.target.value);
                      onChangeProcedure(e);
                      }}>
                    <option value={0}>시술 카테고리 선택</option>
                    {category?.map((el,i)=>(
                      <option key={i} value={el.common_code}>{el.code_name}</option>
                    ))}
                  </select>
                </li>
                <li className="tit_field">시술 선택</li>
                <li className="input_field">
                  <select className="select" name="surgery_category_detail" id="surgery_category_detail" value={!onUpdateBool ? surgeryCategoryDetail : popData?.goods_seq} onChange={(e) => onChangeProcedure(e)}>
                    <option value={0}>시술 선택</option>
                    {categoryShown.map((el, i) => (
                      <option key={i} value={el?.goods_seq}>
                        {el?.goods_name}
                      </option>
                    ))}
                  </select>
                </li>
                <li className="tit_field">지급 회차</li>
                <li className="input_field">
                  <select className="select" name="treatment_times" value={!onUpdateBool ? procedureData.treatment_times : popData?.treatment_times} onChange={(e) => onChangeProcedure(e)}>
                    <option value={0}>회차 선택</option>
                    {numArr.map((el,i) => (
                      <option key={i} value={el}>{el}회</option>
                    ))}
                  </select>
                </li>
                <li className="tit_field">유효기간</li>
                <li className="input_field input_wrap">
                  <input type="text" className="input" name="expired_date" value={!onUpdateBool ?  procedureData.expired_date : popData?.expired_date || ''} onChange={(e) => onChangeProcedure(e)}/><span className="unit">일</span>
                </li>
                <li className="input_field full line center">
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="useType5" name="procedure_yn" value="Y" checked={!onUpdateBool ?  (procedureData.procedure_yn === "Y" ? true : false) : (popData?.coupon_status === "Y" ? true : false)} onChange={(e) => onChangeProcedure(e)}/>
                    <label className="btn_radio" htmlFor="useType5">&nbsp;</label>
                    <label htmlFor="useType5">사용</label>
                    <input type="radio" className="radio" id="useType6" name="procedure_yn" value="N" checked={!onUpdateBool ? (procedureData.procedure_yn === "N" ? true : false) : (popData?.coupon_status === "N" ? true : false)}
                    onChange={(e) => onChangeProcedure(e)}/>
                    <label className="btn_radio" htmlFor="useType6">&nbsp;</label>
                    <label htmlFor="useType6">미사용</label>
                  </fieldset>
                </li>
              </ul>
            </div>
            <div className="pop_footer com_btn_wrap many two">
              <button className="com_btn gray l" onClick={popupClose}>취소</button>
              <button className="com_btn blue l" onClick={registerCoupon}>{!onUpdateBool ? "등록" : "수정"}</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default CouponProcedure