import React, { useState } from "react";
import request from '../../utils/Request.utils';
import { decrypt } from "../../utils/aes256.manager";
import { convertJuminToAge, convertGender } from "utils/Common.utils";
import { nvl, nvlNumber } from "utils/Common.utils";
import moment from "moment";
import CustomCard from "components/common/_CustomCard";
import CustomAlert from "atoms/common/CustomAlert";
import ReservationAdd from "components/common/_ReservationAdd";
import { useSelector } from "react-redux";

const DashCustomCard = ({ customerList, userData, setUserData, getReservationList, getReceptionList, getSelectedUserInfo, setLnbSearchOptions, changeUserHandler }) => {
  const stateToday = useSelector(state => state.Today)
  const [userSeq, setUserSeq] = useState('');
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [timer, setTimer] = useState(moment().format("HH:mm"));
  const [reserSeq, setReserSeq] = useState("");
  const [insertOrderData, setInsertOrderData] = useState({
    main_tc_seq: 0,
    sub_tc_seq: 0,
    user_seq: 0,
    order_date: stateToday,
    order_times: 0,
    order_status: "013002000",
    reg_manager_seq: managerInfo.seq
  })

  const [popup, setPopup] = useState({
    customerCard: false,
    reservationAdd: false,
  });

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
    setUserData('');
  };

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    closeType: ''
  });

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const updateReservationUser = (reser_seq) => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "예약을 취소하시겠습니까?", activateMode: "cancel" } })

    setReserSeq(reser_seq);
  }

  const updateReserStatus = () => {
    let params = {
      reser_seq: reserSeq,
      reser_status: 'S',
      modi_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "접수가 완료되었습니다.", activateMode: '' } });

        // console.log("접수가 완료되었습니다")

      } else console.log(`고객을 RESERVATION 테이블에 접수하지 못했습니다.`)
    }

    request.put(`/api/reservation/${nvlNumber(reserSeq)}/${params.modi_manager_seq}`, params, successHandler);
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (alertLayerPopup.activateMode === "cancel") {
        let params = {
          reser_status: "C",
          modi_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "예약이 취소되었습니다.", activateMode: "" } });
            getReservationList();
          } else {
            console.log("예약을 취소하지 못했습니다.");
          }
        }

        request.put(`/api/reservation/${nvlNumber(reserSeq)}/${managerInfo.seq}`, params, successHandler);
      } else if (alertLayerPopup.activateMode === "reception") {
        // console.log(insertOrderData)

        if (insertOrderData.goods_info === "pack_null") { //시술상품 없이 예약했을 때
          let params = {
            user_seq: insertOrderData.user_seq,
            order_date: stateToday,
            order_status: "013002000",
            reg_manager_seq: managerInfo.seq
          }

          const successHandler = (response) => {
            console.log(response)
            if (response.messageCode === 200) {
              getReservationList();
              getReceptionList();
            } else console.log(`시술상품이 없을 때 고객을 접수하지 못했습니다.`)
          }

          request.post(`/api/charts/order`, params, successHandler);
        } else {
          // modified by psk 20230514 - 이미 차트 정보는 있으니 차트 정보만 연결
          let params = {
            user_seq: insertOrderData.user_seq,
            order_status: "013002000",
            order_times: 0,
            order_date: stateToday,
            reg_manager_seq: managerInfo.seq,

            // 진료차트 정보 =============
            main_tc_seq: insertOrderData.main_tc_seq,
            sub_tc_seq: insertOrderData.sub_tc_seq,
            chart_status: "013003000"
          }

          const successHandler = (response) => {
            // console.log("어라?", response)

            if (response.messageCode === 200) {
              getReservationList();
              getReceptionList();
            } else {
              console.log("시술상품이 있을 때 고객을 접수하지 못했습니다.");
            }

            updateReserStatus(); // 예약 상태 변경
          }

          request.post(`/api/charts/order/dashboard`, params, successHandler);
        }

        updateReserStatus(); // 접수가 완료되면 예약 정보 상태 수정
      }
    }
  }

  const receptionHandler = (item) => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "접수하시겠습니까?", activateMode: "reception" } })

    setReserSeq(item.reser_seq);
    setInsertOrderData(item);
  }

  setInterval(() => { setTimer(moment(new Date()).format("HH:mm")) }, 30000)

  return (
    <>
      {customerList?.map((item, index) => (
        <section className="com_sec" key={index}>
          <article className="com_user_box">
            <div className={`color ${item?.order_count === 0 ? "first" : "second"}`}></div>
            <div className="age_phone" onClick={() => getSelectedUserInfo(item.user_seq, 'popup')}>
              <h1 className="age">{item.user_name} ({convertGender(item.user_gender)}/{nvl(item.user_jumin) === "" ? " - " : convertJuminToAge((decrypt(item.user_jumin).substring(0, 6) + "-" + decrypt(item.user_jumin).substring(6, 13)))})</h1>
              <h2 className="phone">{item.user_mobile}</h2>
            </div>
            {item.reser_status === 'R' ?
              <p className={`info ${timer > item.reser_time ? "red cancel" : "gray"}`} onClick={() => getSelectedUserInfo(item.user_seq, 'reservationAdd')}>
                <span className="txt time">{item.reser_time}</span>
                <span className="division_bar">|</span>
                <span className="txt product">{item.treatment_name}</span>
              </p>
              :
              <p className="info red cancel" onClick={() => getSelectedUserInfo(item.user_seq, 'reservationAdd')}>
                <span className="txt time">{item.reser_time}</span>
                <span className="division_bar">|</span>
                <span className="txt product">{item.treatment_name}</span>
              </p>
            }
            <div className="btn_area">
              <button className="com_btn red m" onClick={() => updateReservationUser(item.reser_seq)} disabled={item.reser_status === 'C' ? true : ""}>예약취소{item.reser_status === 'C' ? moment(item.modi_date).format('HH:mm') : ""}</button>
              <button className="com_btn blue m" onClick={() => receptionHandler(item)}>접수</button>
            </div>
          </article>
        </section>
      ))}
      {popup.customerCard && <CustomCard onClose={() => onClosePop('customerCard')} userData={userData} setUserData={setUserData} getSelectedUserInfo={getSelectedUserInfo} setLnbSearchOptions={setLnbSearchOptions}/>}
      {popup.reservationAdd && <ReservationAdd onClose={() => onClosePop('reservationAdd')} userData={userData} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler} />}
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
    </>
  )

}

export default DashCustomCard