//* 파일별
import React, { useEffect, useState, useRef } from "react";
import request from "utils/Request.utils";
import CustomerSubButton from "../../atoms/crm/SubButton";
import CustomerSubDateSearch from "../../atoms/crm/SubDateSearch";
import { getTodayDate, getPreviousWeekDate, getPreviousMonthDate } from "../../utils/Common.utils";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { utils, writeFile } from 'xlsx';

const CrmFile = () => {
  const componentRef = useRef();
  const tableRef = useRef();
  const [satisfactionList, setSatisfactionList] = useState([]);
  const [avg, setAvg] = useState(0); //전체 평균
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodOption, setPeriodOption] = useState(null);
  const [periodValue, setPeriodValue] = useState(0);
  const today = getTodayDate()?.slice(0,17);
  const todayDate = moment(new Date()).format("YYYYMMDD");

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    }else{
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }


  const setPeriodSearchDate = (startDate, endDate) => {

    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('3');
    setPeriodValue(1);
  }

  // 파일별 고객 만족도 리스트 가져오기
  const getFileSurvey = () => {

    let params = {
      periodOption: periodOption,
      // delete_yn: "N"
    }
    
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setSatisfactionList(response.data.dataList);
      } else {
        console.log("파일별 고객 만족도 목록을 가져오지 못했습니다.");
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/etc/satisfaction/by-file?periodOption=${params.periodOption}&startDate=${startDate}&endDate=${endDate}`, null, successHandler, failHandler, null, null);
  }

  // 직원별 고객 만족도 평균 구하기
  const calculateAvg = () => {
    const avgList = satisfactionList.map((el) => (el.avg_point));
    const result = avgList.reduce((sum, cur) => {
      return Number(sum) + Number(cur)
    },0);
    setAvg(result / avgList.length);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `파일별 고객만족도_${todayDate}`,
  });

  
  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `파일별 고객만족도_${todayDate}.xlsx`);
  };

  useEffect(()=>{
    getFileSurvey(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[periodOption, startDate])

  useEffect(()=>{
    calculateAvg(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[satisfactionList])
  
	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <CustomerSubButton buttonHandler={subButtonAction} />
          <CustomerSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate} periodOption={periodOption}/>
        </div>
      </section>
      <section className="com_content">
        <div ref={el => componentRef.current = el}>
          <div className="com_list box">
            <article className="top_area">
              <div className="info_field">
                <p className="date">{periodValue === 0 ?  `${startDate}` : `${startDate} ~ ${endDate}`}</p>
                <p className="num">직원별 만족도 평균점수 ({isNaN(avg) ? "0.00" : avg.toFixed(2)}점)</p>
              </div>
            </article>
            <div className="scroll with_btn_bottom">
              <table className="list" ref={tableRef}>
                <colgroup><col/><col width="12.5%"/><col width="12.5%"/><col width="12.5%"/><col width="12.5%"/><col width="12.5%"/><col width="12.5%"/></colgroup>
                <thead className="sticky top">
                  <tr>
                    <th>이름</th>
                    <th>파트</th>
                    <th>매우만족(5)</th>
                    <th>만족(4)</th>
                    <th>보통(3)</th>
                    <th>불만(2)</th>
                    <th>개선필요(1)</th>
                    <th>평균</th>
                  </tr>
                </thead>
                <tbody>
                { satisfactionList.some((el) => el.manager_name !== null && el.avg_point !== null) ? (
                    satisfactionList.map((el, i) => (
                      (el.manager_name !== null && el.avg_point !== null) ?
                        <tr key={i}>
                          <td>{el.manager_name}</td>
                          <td>{el.part_name}</td>
                          <td>{el.point_5}</td>
                          <td>{el.point_4}</td>
                          <td>{el.point_3}</td>
                          <td>{el.point_2}</td>
                          <td>{el.point_1}</td>
                          <td>{el.avg_point}</td>
                        </tr>
                      : null
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">데이터가 없습니다.</td>
                    </tr>
                  )
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="com_btn_bottom_area">
          <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
          <button type="button" className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
        </div>
      </section>
		</>
	)
}

export default CrmFile