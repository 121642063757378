import React, { useState, useRef, useEffect } from "react";

import { v4 as uuidv4 } from 'uuid';
import PopupCalendar from "atoms/common/PopupCalendar";
import CustomAlert from "atoms/common/CustomAlert";

import 'react-calendar/dist/Calendar.css'

const DashCalendar = (props) => {

  const [dateType, setDateType] = useState('hyphen');
  const [viewType, setViewType] = useState('');
  const [endDate, setEndDate] = useState('');

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const popupCalendarHTML = () => {
    let retArr = [];

    if(viewType === 'from') {
      retArr.push(<PopupCalendar onClose={ closePopup } getCalendar={ getCalendar } key={ uuidv4() } dateType={ dateType } />)
    } else retArr.push(null);

    return retArr;
  }

  const closePopup = () =>  {
    setViewType('');
  }

  const getCalendar = (value) => {
    if(viewType === 'from') props.setStartDate(value);
    else {
      if(value <= props.startDate) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시작일보다 나중인 날짜를 선택해주세요." } })
        return false;
      }
      setEndDate(value)   
    }
  }


  return (
    <>
      {
        alertLayerPopup.visible ?
          <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={alertLayerPopup.returnValue} />
        : null
      }
      <article className="com_lnb2_right">
        {/*//^ lnb2 검색 영역 */}
        <div className="lnb2_search_wrap">
          <section className="com_field type_normal">
            <fieldset className="many">
              <div className="datepicker_from_to" style={{width: '130px'}}>
                <input type="text" className="input" id="from" placeholder="2023. 01. 01" name="startDate" value={props.startDate} onClick={() => {setViewType('from')}} readOnly style={{fontSize: '20px', border: 'none', color:"black", backgroundColor: 'transparent'}}  />
                {popupCalendarHTML()}
              </div>
            </fieldset>
          </section>
        </div>
      </article>
    </>
  )
}

export default DashCalendar