//* 층관리, 룸관리
import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert";
import request from "utils/Request.utils";
import { nvl } from "../../utils/Common.utils";

const RoomManage = ({ onClose }) => {
  const nodeRef = useRef(null);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [activeTab, setActiveTab] = useState(0);
  const [surgeryRoom, setSurgeryRoom] = useState('');
  const [room, setRoom] = useState('');
  const [surgeryList, setSurgeryList] = useState([]); 
  const [roomList, setRoomList] = useState([]); 
  const [selectedValue, setSelectedValue] = useState('012001000');

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "" } })
  }

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const roomHandler = (e) => {
    const {name, value} = e.target;
    if(name === 'surgeryRoom'){
      setSurgeryRoom(value);
    } else if(name === 'room'){
      setRoom(value);
    }
  }

  const addSurgeryRoom = () => {
    if(surgeryRoom === ''){
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "시술실 명을 입력해주세요", activateMode: "" }});
      return;
    } 

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시술실을 추가하시겠습니까?", activateMode: "addSurgeryRoom"} })
  }

  const addRoom = () => {
    if (room === ''){
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "룸을 입력해주세요", activateMode: "" }});
      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "룸을 추가하시겠습니까?", activateMode: "addRoom" } })
  }

  const deleteSurgeryRoom = (num) => {
    if(num === 1){ //시술실관리 삭제
      if(nvl(selectedValue) === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 시술실이 없습니다." } })
        return;
      }
  
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "선택된 시술실을 삭제하시겠습니까?", activateMode: "deleteSurgeryRoom" } })
    } else{
      if(nvl(selectedValue) === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 룸이 없습니다." } })
        return;
      }
  
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "선택된 룸을 삭제하시겠습니까?", activateMode: "deleteSurgeryRoom" } })
    }
  }
  

  const getSurgeryRoomList = () => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data;
        setSurgeryList(result);
        setSurgeryRoom('');
      } else {
        console.debug('Error >> ', response);
      }
    }
    request.get(`/api/etc/commoncd/category/012000000`, null, successHandler, failHandler, null);
  };

  const getRoomList = () => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data;
        setRoomList(result);
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/${selectedValue}`, null, successHandler, failHandler, null);
  };

  useEffect(() => {
    getSurgeryRoomList();
  },[])

  useEffect(()=>{
    getRoomList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedValue])

  const clickHandler = (e) => {
    setSelectedValue(e.target.value)
  }

  const returnAlertValue = (value) => {

    if(nvl(value) === 'OK') {
      if(alertLayerPopup.activateMode === 'addSurgeryRoom') { // 시술실 추가
        let params = {
          parent_code: "012000000",
          code_level: "2",
          code_name: surgeryRoom,
          code_desc: "",
          reg_manager_seq: managerInfo.seq
        }
    
        const successHandler = (response) => {
          if(response.messageCode === 200) {
            console.log(response.data);
            getSurgeryRoomList();
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        request.post("/api/etc/commoncd", params, successHandler);
      } else if(alertLayerPopup.activateMode === 'deleteSurgeryRoom') { // 시술실 삭제
        let params = {
          code_level: "2",
          parent_code: "selectedValue"
        }
        const successHandler = (response) => {
          console.log(response);

          if(response.messageCode === 200) {
            getSurgeryRoomList();
          } else if(response.messageCode === 409) {
            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술실내 름이 있으므로 삭제가 불가능합니다." } })
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        const failHandler = (response) => {
          console.log(response)

          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제중 에러가 발생했습니다.<br/>확인 후 다시 시도해주세요." } })
        }

        request.del(`/api/etc/commoncd/room/${selectedValue}/${managerInfo.seq}`, params, successHandler, failHandler);
      } else if(alertLayerPopup.activateMode === 'addRoom') { // 룸 추가
        let params = {
          parent_code: selectedValue,
          code_level: "3",
          code_name: room,
          code_desc: "",
          reg_manager_seq: managerInfo.seq
        }
    
        const successHandler = (response) => {
          if(response.messageCode === 200) {
            getRoomList();
            setRoom('');
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        request.post("/api/etc/commoncd", params, successHandler);
      } 
    }
  }
  
  console.log(selectedValue)

	return (
		<>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
      <section className="com_popup type_normal active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
            <div className="pop_header">
              <h1 className="pop_title">시술실 관리</h1>
            </div>
            <div className="com_tab">
              <button className={activeTab === 0 ? 'menu active' : 'menu'} onClick={() => handleTabClick(0)}>시술실 관리</button>
              <button className={activeTab === 1 ? 'menu active' : 'menu'} onClick={() => handleTabClick(1)}>룸 관리</button>
            </div>
            {activeTab === 0 ?
            <>
              <div className="pop_body com_add_item">     
                <fieldset className="add">
                  <input type="text" className="input" name="surgeryRoom" onChange={roomHandler} value={surgeryRoom} onKeyDown={(e) => {if (e.key === 'Enter') addSurgeryRoom(e)}}/>
                  <button type="button" className="com_btn m blue" onClick={addSurgeryRoom}>등록</button>
                </fieldset>
                <article className="list">
                  <ul className="item_wrap scroll">
                    <li className="item">
                      <fieldset className="com_radio_wrap">
                        {surgeryList?.map((el,i) => (
                          <React.Fragment key={i}>
                            <input type="radio" className="radio" id={`visit${i}`}  name="select_surgery_room" value={el.code} onChange={ clickHandler } checked={ el.code === selectedValue }/>
                            <label className="btn_radio" htmlFor={`visit${i}`}>&nbsp;</label>
                            <label htmlFor={`visit${i}`}>{el.name}</label>
                          </React.Fragment>
                        ))}
                      </fieldset>
                    </li>                
                  </ul>
                </article>
                <p className="tip_txt">시술실내 룸이 있는 경우 <br/>삭제가 불가능합니다.</p>
              </div>
              <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={ () => deleteSurgeryRoom(1) }>삭제</button>
              </div>
            </>
           : 
           <>
            <div className="pop_body com_add_item"> 
                <select className="select" onChange={ clickHandler }>
                  <option value={0}>시술실 선택</option>
                  {surgeryList.map((el, i) => (
                    <option key={el.code} value={el.code}>{el.name}</option>
                  ))}
                </select>    
              <fieldset className="add">
                <input type="text" className="input" name="room" onChange={roomHandler} value={room} onKeyDown={(e) => {if (e.key === 'Enter') addRoom(e)}}/>
                <button type="button" className="com_btn m blue" onClick={addRoom}>등록</button>
              </fieldset>
              <article className="list">
                <ul className="item_wrap scroll">
                  <li className="item">
                    <fieldset className="com_radio_wrap">
                      {roomList.map((el,i) => (
                        <React.Fragment key={el.code}>
                          <input type="radio" className="radio" id={`visit_${i}`}  name="select_visit" value={el.code} />
                          <label className="btn_radio" htmlFor={`visit_${i}`}>&nbsp;</label>
                          <label htmlFor={`visit_${i}`}>{el.name}</label>
                        </React.Fragment>
                      ))}
                    </fieldset>
                  </li>                
                </ul>
              </article>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={ () => deleteSurgeryRoom(2) }>삭제</button>
            </div>
           </>}
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default RoomManage