import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import MyCompany from "./_MyCompany.js"; // 지점 정보
import CustomAlert from '../../atoms/common/CustomAlert';
import ReactDaumPost from 'react-daumpost-hook';
import request from '../../utils/Request.utils';
import SessionStorage from '../../utils/SessionStorage.utils';
import { nvl, validateEmail } from "../../utils/Common.utils";
import {v4 as uuidv4} from 'uuid';

const MyOwner = () => {
  const navigate = useNavigate();

  const ref = useRef(null);

  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    closeType: ''
  });

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [userFullInfo, setUserFullInfo] = useState({}); // DB에서 가져온 값
  const [modifyModeActivate, setModifyModeActivate] = useState(true);
  const [idDuplicationBool, setIdDuplicationBool] = useState({ visible: false, count: 0 }); // 아이디 중복 체크

  const [popupAddressActivate, setPopupAddressActivate] = useState(false); // 주소검색 레이어 팝업
  
  const [managerEmailInfo, setManagerEmailInfo] = useState({ id: "", domain: "", value: "", domainType: "S" });
  const [representativeEmailInfo, setRepresentativeEmailInfo] = useState({ id: "", domain: "", value: "", domainType: "S" });

  const managerIdRef = useRef();

  useEffect(() => {
    if(managerInfo.role !== "009001000") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: 'next', visible: true, alertMessage: "접근 권한이 없습니다." } })
  
      return
    }

    getMyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 이메일 정보의 도메인만
  const [domainList, setDomainList] = useState([
    { name: "naver.com", value: "naver.com" },
    { name: "google.com", value: "google.com" },
    { name: "gmail.com", value: "gmail.com" },
    { name: "hanmail.net", value: "hanmail.net" },
    { name: "nate.com", value: "nate.com" },
    { name: "kakao.com", value: "kakao.com" },
    { name: "korea.com", value: "korea.com" },
    { name: "yahoo.com", value: "yahoo.com" },
    { name: "직접입력", value: "userInput" }
  ]);

  const filterItems = (query) => {
    return domainList.filter((searchStr) =>
      (searchStr.value).toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    );
  }
  
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: false, alertMessage: "" } })

    if(alertLayerPopup.closeType === "next") navigate("/main");
    else if(alertLayerPopup.closeType === "login") {
      SessionStorage.clearItem("managerSession")

      window.location.href = "/main/login";
    }
  }

  const returnAlertValue = (value) => {
    // console.log("value ==> " + value);

    if(nvl(value) === 'OK') {
      
    }
  }

  const getMyInfo = () => {
    const successHandler = (response) => {
      // console.log(response);

      if(response.messageCode === 200) { // ok 코드 값
        // 직원 이메일 처리
        if(nvl(response.data.dataList[0].manager_email) !== "") {
          setManagerEmailInfo((prev) => { // 일단 아이디 값하고 도메인 값 세팅
            return { ...prev, id: response.data.dataList[0].manager_email.split('@')[0], domain: response.data.dataList[0].manager_email.split('@')[1] }
          })

          const managerEmailCheck = filterItems(nvl(response.data.dataList[0].manager_email.split('@')[1]))

          if(managerEmailCheck !== null && managerEmailCheck.length > 0) {
            setManagerEmailInfo((prev) => { return { ...prev, value: response.data.dataList[0].manager_email.split('@')[1], domainType: "S" } }) // select 박스에 도메인이 있음
          } else {
            setManagerEmailInfo((prev) => { return { ...prev, value: "userInput", domainType: "I" } }) // 직접입력
          }
        }

        // ------------------------------------------------------------------------

        // 대표 이메일 처리
        if(nvl(response.data.dataList[0].representative_email) !== "") {
          setRepresentativeEmailInfo((prev) => { // 일단 아이디 값하고 도메인 값 세팅
            return { ...prev, id: response.data.dataList[0].representative_email.split('@')[0], domain: response.data.dataList[0].representative_email.split('@')[1] }
          })

          const representativeEmailCheck = filterItems(nvl(response.data.dataList[0].representative_email.split('@')[1]))

          if(representativeEmailCheck !== null && representativeEmailCheck.length > 0) {
            setRepresentativeEmailInfo((prev) => { return { ...prev, value: response.data.dataList[0].representative_email.split('@')[1], domainType: "S" } }) // select 박스에 도메인이 있음
          } else {
            setRepresentativeEmailInfo((prev) => { return { ...prev, value: "userInput", domainType: "I" } }) // 직접입력
          }
        }

        // ------------------------------------------------------------------------

        setUserFullInfo(response.data.dataList[0])        
      } else console.log("에라야 에라");
    }

    request.get("/api/manager/fullinfo/list/" + managerInfo.seq, null, successHandler);
  }

  const emailDomainManager = (domain, target) => {
    // console.log(domain + " | " + target)

    if(nvl(domain) === "userInput") {
      if(nvl(target) === "M") setManagerEmailInfo((prev) => { return { ...prev, value: "userInput", domain: "", domainType: "I" } })
      else setRepresentativeEmailInfo((prev) => { return { ...prev, value: "userInput", domain: "", domainType:  "I" } })
    } else {
      if(nvl(target) === "M") setManagerEmailInfo((prev) => { return { ...prev, value: domain, domain: domain, domainType: "S" } })
      else setRepresentativeEmailInfo((prev) => { return { ...prev, value: domain, domain: domain, domainType: "S" } })
    }
  }
  
  const emailIdManager = (id, target) => {
    // console.log(id + " | " + target)

    if(nvl(target) === "M") setManagerEmailInfo((prev) => { return { ...prev, id: id } })
    else setRepresentativeEmailInfo((prev) => { return { ...prev, id: id } })
  }

  const idDuplicationCheck = (event) => { // 아이디 중복 체크
    const successHandler = (response) => {
      console.log(response)

      if(response.data === 0) {
        setIdDuplicationBool((prev) => { return { ...prev, visible: false, count: 1 } })
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "사용 가능한 아이디 입니다." } })
      } else {
        setIdDuplicationBool((prev) => { return { ...prev, visible: true, count: 0 } })
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "이미 사용하고 있는 아이디 입니다." } })

        // 이건 둘 다 빈 걸로 처리
        userFullInfoUpdate("manager_id", '');
        managerIdRef.current.value = '';

        // 이건 원래 값으로 돌리는 거
        // managerIdRef.current.value = managerInfo.id
      }
    }

    request.get("/api/manager/id/duplication/" + userFullInfo.manager_id, null, successHandler);
  }

  const getAddress = (addressInfo) => { // 달력에서 날짜 받기
    // console.log(addressInfo)

    userFullInfoUpdate("company_postal", addressInfo.postal)
    userFullInfoUpdate("company_address", addressInfo.address)
    userFullInfoUpdate("company_address_detail", "")
  }

  const togglePopupAddress = () => {
    setPopupAddressActivate(!popupAddressActivate)
  }

  const userFullInfoUpdate = (key, value) => {
    // console.log(key + " | " + value)

    if(key === "manager_id" && managerInfo.id === value) setIdDuplicationBool((prev) => { return { ...prev, visible: false, count: 0 } }) // 사용중 메시지 지우기
    else if(key === "manager_mobile_1") {
      key = "manager_mobile";
      value = value + "-" + userFullInfo.manager_mobile.split("-")[1] + "-" + userFullInfo.manager_mobile.split("-")[2];
    } else if(key === "manager_mobile_2") {
      key = "manager_mobile";
      value = userFullInfo.manager_mobile.split("-")[0] + "-" + value + "-" + userFullInfo.manager_mobile.split("-")[2];
    } else if(key === "manager_mobile_3") {
      key = "manager_mobile";
      value = userFullInfo.manager_mobile.split("-")[0] + "-" + userFullInfo.manager_mobile.split("-")[1] + "-" + value;
    } else if(key === "registration_number_1") {
      key = "registration_number";
      value = value + "-" + userFullInfo.registration_number.split("-")[1] + "-" + userFullInfo.registration_number.split("-")[2];
    } else if(key === "registration_number_2") {
      key = "registration_number";
      value = userFullInfo.registration_number.split("-")[0] + "-" + value + "-" + userFullInfo.registration_number.split("-")[2];
    } else if(key === "registration_number_3") {
      key = "registration_number";
      value = userFullInfo.registration_number.split("-")[0] + "-" + userFullInfo.registration_number.split("-")[1] + "-" + value;
    }

    setUserFullInfo((prev) => { return { ...prev, [key]: value } })
  }

  const managerInfoUpdateActivate = () => { // 직원 정보 업뎃
    // console.log(userFullInfo)

    if(nvl(userFullInfo.manager_id) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "아이디를 입력해주세요." } })
      
      return;
    }

    if(userFullInfo.manager_id !== managerInfo.id && idDuplicationBool.count === 0) {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "아이디 중복 체크를해주세요." } })

      return;
    }

    if(nvl(userFullInfo.manager_name) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "이름을 입력해주세요." } })
      
      return;
    }

    if(validateEmail(managerEmailInfo.id + "@" + managerEmailInfo.domain)) userFullInfo.manager_email = managerEmailInfo.id + "@" + managerEmailInfo.domain;
    else {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "잘못된 이메일 형식입니다." } })
      
      return;
    }

    if(nvl(userFullInfo.manager_mobile) === "" && userFullInfo.manager_mobile.split("-").length < 3) {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "휴대폰 번호를 확인해주세요." } })
      
      return;
    }

    if(nvl(userFullInfo.manager_mobile.split("-")[0]) === "" || nvl(userFullInfo.manager_mobile.split("-")[1]) === "" || nvl(userFullInfo.manager_mobile.split("-")[2]) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "휴대폰 번호를 확인해주세요." } })
      
      return;
    }

    if(nvl(userFullInfo.representative_name) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "대표자 이름을 입력해주세요." } })
      
      return;
    }

    if(validateEmail(representativeEmailInfo.id + "@" + representativeEmailInfo.domain)) userFullInfo.representative_email = representativeEmailInfo.id + "@" + representativeEmailInfo.domain
    else {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "잘못된 대표 이메일 형식입니다." } })
      
      return;
    }

    if(nvl(userFullInfo.registration_number) === "" && userFullInfo.registration_number.split("-").length < 3) {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "사업자 등록번호를 확인해주세요." } })
      
      return;
    }

    if(nvl(userFullInfo.registration_number.split("-")[0]) === "" || nvl(userFullInfo.registration_number.split("-")[1]) === "" || nvl(userFullInfo.registration_number.split("-")[2]) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "사업자 등록번호를 확인해주세요." } })
      
      return;
    }

    if(nvl(userFullInfo.company_postal) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "사업장 주소를 확인해주세요" } })
      
      return;
    }

    if(nvl(userFullInfo.company_address) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "사업장 주소를 확인해주세요" } })
      
      return;
    }

    if(nvl(userFullInfo.company_address_detail) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "사업장 주소를 확인해주세요" } })
      
      return;
    }

    // 저장할 때 직원정보만(M) 그리고 직원과 사업장정보(F) 모두를 나누는 플래그
    userFullInfo.updateType = "F";

    const successHandler = (response) => {
      // console.log(response)

      if(response.messageCode === 200) {
        if(managerInfo.id === userFullInfo.manager_id) {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "정보 변경이 완료되었습니다." } })
        } else {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: 'login', visible: true, alertMessage: "바뀐 정보로 다시 로그인해주세요" } })

          return;
        }
      } else {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "정보 수정중 에러가 발생했습니다." } })
      }
    }

    request.put("/api/manager", userFullInfo, successHandler);
  }

  const postConfig = {
    onComplete: (data) => {
      console.log(data)

      let addressType = data.userSelectedType;
      let postal = data.zonecode;
      let fullAddress = data.address;
      let extraAddress = '';
      
      // if(data.bname !== '') extraAddress += data.bname;
      // if(addressType === 'R')
      if(data.buildingName !== '') extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);

      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');

      // 일단은 도로명 주소만 사용하는 걸로
      // if(addressType === 'J') fullAddress = data.jibunAddress; // 지번 주소 클릭했을 때

      console.log(addressType + " | " + postal + " | " + fullAddress)
      
      getAddress({ addressType: addressType, postal: postal, address: fullAddress })
    },
  }

	return (
		<>
			<section className="com_content com_mypage com_has_mycompany">
				<div className="container">
          <article className="top_area">
            <p className="tit">마이페이지</p>
            <button className="com_btn m blue line" onClick={ () => setModifyModeActivate(!modifyModeActivate) }>정보 변경</button>
          </article>
          <article className="body_area">
            <div className="top_info">
              <p className="item company_name">{ userFullInfo.company_name }</p>
            </div>
            <ul className="com_input_section">
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>아이디</li>
              <li className="input_field">
                <input type="text" className="input" ref={ managerIdRef } defaultValue={ userFullInfo.manager_id } style={{ width: "327px" }} readOnly={ modifyModeActivate }
                  onChange={ (e) => userFullInfoUpdate("manager_id", e.target.value) } />
                &nbsp;&nbsp;&nbsp;
                <button className="com_btn blue m" disabled={ modifyModeActivate } onClick={ idDuplicationCheck } >중복 체크</button>
                { idDuplicationBool.visible && <p className="txt_red">이미 사용중인 아이디 입니다.</p> }
              </li>
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>비밀번호</li>
              <li className="input_field input_wrap">
                <input type="password" className="input" value="11111111" readOnly />
                <button className="com_btn blue m" disabled={ modifyModeActivate } onClick={ () => navigate("/main/change_pw") }>비밀번호 변경</button>
              </li>
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>이름</li>
              <li className="input_field">
                <input type="text" className="input" defaultValue={ userFullInfo.manager_name } readOnly={ modifyModeActivate }
                  onChange={ (e) => userFullInfoUpdate("manager_name", e.target.value) } />
              </li>
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>이메일</li>
              <li className="input_field input_wrap">
                <input type="text" className="input" readOnly={ modifyModeActivate } defaultValue={ managerEmailInfo.id } onChange={(e)=>{ emailIdManager(e.target.value, 'M') }} />
                <span className="space">@</span>
                {
                  <select className="select" disabled={ modifyModeActivate } onChange={(e)=>{ emailDomainManager(e.target.value, 'M') }}
                    key={ nvl(managerEmailInfo.value) } defaultValue={ nvl(managerEmailInfo.value) }>
                    <option value="">:: 도메인 선택 ::</option>
                    {
                      domainList.map((item, index) => (
                        <option key={ item.value } value={ item.value }>{ item.name }</option>
                      ))
                    }
                  </select>
                }
                {
                  nvl(managerEmailInfo.domainType) === "I" ?
                    <input type="text" className="input" readOnly={ modifyModeActivate } defaultValue={ managerEmailInfo.domain }
                      onChange={(e)=>{ setManagerEmailInfo((prev) => { return { ...prev, value: "userInput", domain: e.target.value, domainType: "I" } }) }} />
                  : null
                }
              </li>
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>휴대폰</li>
              <li className="input_field input_wrap">
                <input type="text" className="input" defaultValue={ userFullInfo != null && nvl(userFullInfo.manager_mobile) !== "" ? nvl(userFullInfo.manager_mobile.split("-")[0]) : "" } readOnly={ modifyModeActivate }
                  onChange={ (e) => userFullInfoUpdate("manager_mobile_1", e.target.value) } maxLength={ 3 } />
                <span className="space">-</span>
                <input type="text" className="input" defaultValue={ userFullInfo != null && nvl(userFullInfo.manager_mobile) !== "" ? nvl(userFullInfo.manager_mobile.split("-")[1]) : "" } readOnly={ modifyModeActivate }
                  onChange={ (e) => userFullInfoUpdate("manager_mobile_2", e.target.value) } maxLength={ 4 } />
                <span className="space">-</span>
                <input type="text" className="input" defaultValue={ userFullInfo != null && nvl(userFullInfo.manager_mobile) !== "" ? nvl(userFullInfo.manager_mobile.split("-")[2]) : "" } readOnly={ modifyModeActivate }
                  onChange={ (e) => userFullInfoUpdate("manager_mobile_3", e.target.value) } maxLength={ 4 } />
              </li>
            </ul>
            <ul className="com_input_section">
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>대표자 이름</li>
              <li className="input_field">
                <input type="text" className="input" defaultValue={ userFullInfo.representative_name } readOnly={ modifyModeActivate }
                  onChange={ (e) => userFullInfoUpdate("representative_name", e.target.value) } />
              </li>
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>대표 이메일</li>
              <li className="input_field input_wrap">
                <input type="text" className="input" readOnly={ modifyModeActivate } defaultValue={ representativeEmailInfo.id } onChange={(e)=>{ emailIdManager(e.target.value, 'R') }} />
                <span className="space">@</span>
                {
                  <select className="select" disabled={ modifyModeActivate } onChange={(e)=>{ emailDomainManager(e.target.value, 'R') }}
                    key={ nvl(representativeEmailInfo.value) } defaultValue={ nvl(representativeEmailInfo.value) }>
                    <option value="">:: 도메인 선택 ::</option>
                    {
                      domainList.map((item, index) => (
                        <option key={ item.value } value={ item.value }>{ item.name }</option>
                      ))
                    }
                  </select>
                }
                {
                  nvl(representativeEmailInfo.domainType) === "I" ?
                    <input type="text" className="input" readOnly={ modifyModeActivate } defaultValue={ representativeEmailInfo.domain }
                      onChange={(e)=>{ setRepresentativeEmailInfo((prev) => { return { ...prev, value: "userInput", domain: e.target.value, domainType: "I" } }) }} />
                  : null
                }
              </li>
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>대표전화</li>
              <li className="input_field">
                <input type="text" className="input" defaultValue={ userFullInfo.representative_phone } readOnly={ modifyModeActivate }
                  onChange={ (e) => userFullInfoUpdate("representative_phone", e.target.value) } placeholder="02-1234-5679" />
              </li>
              <li className="tit_field"><i className="svg_icon icon_must">&nbsp;</i>사업자 등록번호</li>
              <li className="input_field input_wrap">
                <input type="text" className="input" defaultValue={ userFullInfo != null && nvl(userFullInfo.registration_number) !== "" ? nvl(userFullInfo.registration_number.split("-")[0]) : "" }
                  readOnly={ modifyModeActivate } onChange={ (e) => userFullInfoUpdate("registration_number_1", e.target.value) } maxLength={ 3 } />
                <input type="text" className="input" defaultValue={ userFullInfo != null && nvl(userFullInfo.registration_number) !== "" ? nvl(userFullInfo.registration_number.split("-")[1]) : "" }
                  readOnly={ modifyModeActivate } onChange={ (e) => userFullInfoUpdate("registration_number_2", e.target.value) } maxLength={ 2 } />
                <input type="text" className="input" defaultValue={ userFullInfo != null && nvl(userFullInfo.registration_number) !== "" ? nvl(userFullInfo.registration_number.split("-")[2]) : "" }
                  readOnly={ modifyModeActivate } onChange={ (e) => userFullInfoUpdate("registration_number_3", e.target.value) } maxLength={ 5 } />
              </li>
              <li className="tit_field address"><i className="svg_icon icon_must">&nbsp;</i>사업장 주소입력</li>
              <li className="input_field address">
                <div className="input_wrap">
                  <input type="text" className="input" defaultValue={ userFullInfo.company_postal } readOnly
                    onChange={ (e) => userFullInfoUpdate("company_postal", e.target.value) } />
                  {/* <button className="com_btn blue m" disabled={ modifyModeActivate } onClick={ togglePopupAddress }>우편번호</button> */}
                  <button className="com_btn blue m" disabled={ modifyModeActivate } onClick={ ReactDaumPost(postConfig) }>우편번호</button>                  
                </div>
                <div className="input_wrap">
                  <input type="text" className="input" defaultValue={ userFullInfo.company_address } readOnly={ modifyModeActivate }
                    onChange={ (e) => userFullInfoUpdate("company_address", e.target.value) } />
                </div>
                <div className="input_wrap">
                  <input type="text" className="input" defaultValue={ userFullInfo.company_address_detail } readOnly={ modifyModeActivate }
                    onChange={ (e) => userFullInfoUpdate("company_address_detail", e.target.value) } />
                </div>
              </li>
            </ul>
            {
              !modifyModeActivate ?
                <div className="com_btn_wrap one">
                  <button className="com_btn l blue" onClick={ managerInfoUpdateActivate }>저장</button>
                </div>
              : null
            }
          </article>
        </div>
        <MyCompany />
        {
          alertLayerPopup.visible ?
            <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } />
          : null
        }
			</section>
		</>
	)
}

export default MyOwner