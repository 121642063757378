//* 결산-연령대별
import React, { useEffect, useState, useRef, useCallback } from "react";
import StatisticsSubButton from "atoms/statistics/StatisticsSubButton";
import StatisticsSubDateSearch2 from "atoms/statistics/StatisticsSubDateSearch2";
import moment from "moment";
import { convDateToString, addComma } from "../../utils/Common.utils";
import Graph from "atoms/common/graph/graph";
import request from "utils/Request.utils";

const CalcAge = () => {
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [dateArray, setDateArray] = useState(['2024-08-01', '2024-08-02', '2024-08-03', '2024-08-04', '2024-08-05']);   //그래프 Data
  const [calAgeData, setCalAgeData] = useState([
    { code_name: '20대', daily_count_array: [4, 3, 4, 10, 8] },
    { code_name: '30대', daily_count_array: [5, 8, 20, 18, 34] },
    { code_name: '40대', daily_count_array: [12, 20, 19, 30, 52] }
  ]); //그래프 Data

  const [dataList, setDataList] = useState([]);         //연령대별 Data

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  //조회 > 연령대별
  const getSummary = useCallback( (sDate, eDate) => {
      
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        console.log('getSummary::', response.data);
        setDataList(response?.data?.dataList);
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response);
      setDataList([]);
    }
    if(sDate !== undefined && eDate !== undefined){
      request.get(`/api/statistics/settlement/age?start_date=${sDate}&end_date=${eDate}`, null, successHandler, failHandler, null, null);
    }

  },[]);

  useEffect(() => {
    getSummary(startDate, endDate);
  },[getSummary, startDate, endDate]);

	return (
		<>
		<section className="section com_lnb2">
			<div className="inner">
        <article className="com_lnb2_left">
          {/*//^ lnb2 버튼 영역 */}
          <div className="lnb2_menu_container">
          <StatisticsSubButton subButtonAction={subButtonAction} />
          </div>
        </article>
        <article className="com_lnb2_right">
          <StatisticsSubDateSearch2 searchHandler={getSummary} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
        </article>
			</div>
		</section>
		<section className="com_content">
      <div className="com_list box">
        <article className="top_area">
          <div className="info_field">
            <p className="date">{startDate === endDate ?  convDateToString(startDate) : `${convDateToString(startDate)} ~ ${convDateToString(endDate)}`}</p>
          </div>
        </article>
        <article className="chart_area">
          차트영역
          <Graph dateArray={dateArray} data={calAgeData}/>
        </article>
      </div>
      <div className="com_list box">
        <table className="list sales">
          <colgroup><col width="11.3%"/><col width="8.9%"/><col width="8.9%"/><col width="8.9%"/><col width="8.9%"/><col width="8.9%"/><col width="8.9%"/><col width="8.9%"/><col width="8.9%"/><col/></colgroup>
          <thead>
            <tr>
              <th colSpan="10">연령대별 집계</th>
            </tr>
            <tr>
              <th>접수형태</th>
              <th>10대</th>
              <th>20대</th>
              <th>30대</th>
              <th>40대</th>
              <th>50대</th>
              <th>60대</th>
              <th>70대</th>
              <th>80대</th>
              <th>합계</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>건수</td>
              {dataList?.length > 0 ? dataList?.map((age, index) => {
                  return( <td key={`td_cnt_${index}`}>{age.pay_cnt}</td> )
                })
                : <td colspan="8">내역이 없습니다.</td>
              }
              <td>0</td>
            </tr>
            <tr>
              <td>매출액</td>
              {dataList?.length > 0 ? dataList?.map((age, index) => {
                  return( <td key={`td_pay_${index}`}>{addComma(age.payment_sum)}</td> )
                })
                : <td colspan="8">내역이 없습니다.</td>
              }
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
		</section>
		</>
	)
}

export default CalcAge