//* 계좌관리
import React, { useEffect, useState } from "react";
import request from "utils/Request.utils.js";
import moment from "moment";
import AccountChange from "./_AccountChange.js"; //출금계좌변경
import AccountOk from "./_AccountOk.js"; //계좌등록 완료 안내
import CustomAlert from "atoms/common/CustomAlert";

const AccountManage = () => {
  const [accountList, setAccountList] = useState([]);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());

  const bankInit = {
    bam_seq:0,
    bl_idx:0,
    use_name:'',
    bank_code:'',
    bank_name:'',
    bank_account1:'',
    bank_account2:'',
    bank_account3:'',
    bank_depositor:'',
    head_office_yn:'N',
    display_yn:'Y'
  }

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { },
  })

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "" } })
  }

  const [popData, setPopData] = useState({...bankInit});

  const [popup, setPopup] = useState({
    accountChange: false,
  });

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
    setPopData({bankInit});
  }

  const onPopupClick = (e) => {
    setPopup((prev) => { return { ...prev, [e.target.id]: true } });
  }

  const onDetailClick = (e, bam_seq) => {
    setPopup((prev) => { return { ...prev, [e.target.id]: true } });
    const copyData = accountList.filter(el => el.bam_seq === bam_seq);
    const data = [...copyData][0]
    setPopData({
      bam_seq: data.bam_seq,
      bl_idx: data.bl_idx,
      use_name: data.use_name,
      bank_code: data.bank_code,
      bank_name: data.bank_name,
      bank_account1: data.bank_account.split("-")[0],
      bank_account2: data.bank_account.split("-")[1],
      bank_account3: data.bank_account.split("-")[2],
      bank_depositor: data.bank_depositor,
      head_office_yn: data.head_office_yn,
      display_yn: data.display_yn
    });
  }

  const getBankAccount = () => {
  
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setAccountList(response.data.dataList);
      } else {
        console.log("계좌 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/bank`, null, successHandler, failHandler, null, null);
  }

  const onUpdateHeadOfficeYn = (e, bam_seq) => {
    let params = {
      bam_seq: bam_seq,
      head_office_yn: "Y",
      modi_manager_seq: managerInfo.seq
    }
    
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "본사출금계좌 선택을 변경하였습니다.",activateMode:''}});
        getBankAccount();
      } else {
        console.log("본사출금계좌 선택을 변경하지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.put("/api/bank", params, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getBankAccount();
  }, [])


	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <a href="/sales" className="com_btn m lightgray">매출결산</a>
              <a href="/sales/account_manage" className="com_btn m blue">계좌 관리</a>
            </div>
          </article>
          <article className="com_lnb2_right">
            <button className="com_btn m blue" id='accountChange' onClick={(e) => onPopupClick(e)}><i className="svg_icon icon_add yellow">&nbsp;</i>계좌등록</button>
          </article>
        </div>
      </section>
      <section className="com_content">
        <div className="com_list box">
          <div className="scroll">
              <table className="list">
                <colgroup><col width="11.9%"/><col width="11.9%"/><col width="11.9%"/><col/><col width="11.9%"/><col width="11.9%"/><col width="11.9%"/><col width="11.9%"/></colgroup>
                <thead>
                  <tr>
                    <th>등록일</th>
                    <th>사용명</th>
                    <th>은행명</th>
                    <th>계좌번호</th>
                    <th>예금주</th>
                    <th>본사출금계좌 선택</th>
                    <th>사용 상태</th>
                    <th>비고</th>
                  </tr>
                </thead>
                {accountList?.length > 0 ? accountList.map((el, i) => (
                  <tbody key={i}> 
                    <tr>
                      <td>{moment(el.reg_date).format("YYYY, MM, DD")}</td>
                      <td>{el.use_name}</td>
                      <td>{el.bank_name}</td>
                      <td>{el.bank_account}</td>
                      <td>{el.bank_depositor}</td>
                      <td>
                        <fieldset className="com_radio_wrap">
                          <input type="radio" className="radio" id={`radio0${i}`} name="head_office_yn" value={el.head_office_yn} onClick={(e) => onUpdateHeadOfficeYn(e, el.bam_seq)} defaultChecked={el.head_office_yn === "Y"}/>
                          <label className="btn_radio" htmlFor={`radio0${i}`}>&nbsp;</label>
                        </fieldset>
                      </td>
                      <td>{el.display_yn === "Y" ? "사용" : "미사용"}</td>
                      <td><button className="com_btn txt" id='accountChange' onClick={(e) => onDetailClick(e, el.bam_seq)}>상세보기</button></td>
                    </tr>
                  </tbody>
                  )) : 
                  <tbody>
                    <tr>
                      <td colSpan="8">데이터가 없습니다.</td>
                    </tr>
                  </tbody>
                }
              </table>
          </div>
        </div>
      </section>
      {popup.accountChange && <AccountChange onClose={() => { onClosePop('accountChange') }}  getBankAccount={getBankAccount} popData={popData} setPopData={setPopData}/>}
		  <AccountOk/>
      { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ alertLayerPopup.returnValue } /> : null }
    </>
	)
}

export default AccountManage