//*SNS 문자 보내기
import CustomAlert from "atoms/common/CustomAlert";
import React, { useRef, useState, useEffect } from "react";
import Draggable from "react-draggable";
import { nvl, convertJuminToAge, convertGender } from "utils/Common.utils";
import { decrypt } from "utils/aes256.manager";
import request from "utils/Request.utils";
import CustomListMsg from "./_CustomListMsg";

const SnsSend = ({ setLnbSearchOptions, snsSendBool, setSnsSendBool, checkedList }) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const nodeRef = useRef(null);
  const mobile_1Ref = useRef()
  const mobile_2Ref = useRef()
  const mobile_3Ref = useRef()
  const imageRef = useRef();
  const imagePathRef = useRef();

  const [messageSearchOptions, setMessageSearchOptions] = useState({
    checkedBool: false, // 컴포넌트 노출 여부
    totalCount: 0, // 총 갯수
    searchList: null, // 검색 리스트
    searchKeyword: '', // 이름 or 차트코드 or 휴대전화 번호 검색
    userSeq: 0 // 고객 시퀀스
  })
  const [msgAlertLayerPopup, setMsgAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false
  })
  const [userList, setUserList] = useState(checkedList ? checkedList : []);
  const [smsCategoryList, setSmsCategoryList] = useState([])
  const [frequentMessageList, setFrequentMessageList] = useState([]);
  const [filterList, setFilterList] = useState([])
  const [input, setInput] = useState({
    msgCate: "",
    subject: "",
    content: "",
    sendType: "N",
  })
  const [directUser, setDirectUser] = useState({});

  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [uploadFilePath, setUploadFilePath] = useState("")

  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0)

  const infoInit = () => {
    setInput({
      msgCate: "",
      msgSeq: "",
      content: "",
      sendType: "N",
      sendTime: "",
    })
    setUserList([])
    setHour(0)
    setMinute(0)
  }



  // alert 창 닫기
  const closeCustomAlert = () => {
    setMsgAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  // 문자 카테고리 불러오기
  const getSmsCategory = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setSmsCategoryList(response.data.dataList)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }
    request.get("/api/etc/commoncd/3/003000000", null, successHandler, failHandler, null, null);
  }

  // 자주 사용하는 문자 불러오기
  const getFrequentMessageList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setFrequentMessageList(response.data.resultData)
      } else {
        console.log("목록을 불러오지 못했습니다.")
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get("/api/message/frequent/use", null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getSmsCategory()
    getFrequentMessageList()
  }, [])


  const msgCustomerSearch = () => {
    if (nvl(messageSearchOptions.searchKeyword) === "") {
      setMsgAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "검색어를 입력해주십시오." } })
      setMessageSearchOptions((prev) => { return { ...prev, checkedBool: false, totalCount: 0, searchList: null, searchKeyword: '', userSeq: 0 } })

      return;
    }

    const successHandler = (response) => {

      if (response.messageCode === 200) {
        setMessageSearchOptions((prev) => { return { ...prev, checkedBool: true, searchKeyword: "", totalCount: response.data.totalCnt, searchList: response.data.dataList } })
      } else if (response.messageCode === 400) {
      } else {
        setMsgAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "고객 목록을 가져오던 중 에러가 발생했습니다.<br />다시 시도해 주십시오." } })
      }
    }

    request.get(`/api/user?searchKeyword=${messageSearchOptions.searchKeyword}`, null, successHandler);
  }

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') msgCustomerSearch();
  }

  const updateSearchKeyword = (e) => {
    setMessageSearchOptions((prev) => { return { ...prev, searchKeyword: e.target.value } })
  }

  const checkHandler = () => {
    setMessageSearchOptions((prev) => { return { ...prev, checkedBool: !messageSearchOptions.checkedBool } })
  }

  const returnAlertValue = () => {

  }

  // 직접입력창 변경
  const changePhoneNumber = (e) => {
    const { name, value } = e.target;
    if (name === "phone_num1") {
      mobile_1Ref.current.value = value
      if (value !== "") mobile_2Ref.current.focus()
    }
    if (name === "phone_num2") {
      mobile_2Ref.current.value = value
      if (value.length === 4) mobile_3Ref.current.focus()
    }
    if (name === "phone_num3") {
      mobile_3Ref.current.value = value
    }
    setDirectUser({ phone_number: `${mobile_1Ref.current.value}-${mobile_2Ref.current.value}-${mobile_3Ref.current.value}` })
  }

  //  직접입력 추가
  const addUserList = () => {
    if (userList.findIndex(el => el.phone_number === directUser.phone_number) < 0) {
      setUserList([...userList, directUser])
    } else {
      setMsgAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이미 추가한 번호입니다." } })
    }
    mobile_1Ref.current.value = "010"
    mobile_2Ref.current.value = ""
    mobile_3Ref.current.value = ""
  }

  // 번호 삭제
  const deleteUserList = (order) => {
    setUserList(userList.filter((el, index) => index !== order))
  }

  const changeInput = (e) => {
    const { name, value } = e.target

    setInput({ ...input, [name]: value })
    if (name === "msgCate") {
      setFilterList(frequentMessageList.filter(el => el.msg_cate === value))
    }
    if (name === "subject") {
      let result = filterList.filter(el => el.msg_seq === Number(value))[0]
      console.log(result)
      setInput({ ...input, subject: result ? result.msg_subject : "", content: result ? result.msg_content : "" })
    }
    if (name === "sendType") {
      setHour(0);
      setMinute(0)
    }
  }

  // 메시지 전송 수신자 형식으로 변경
  const ChangeToRecipientPhoneNumber = (userList) => {
    let result = [];
    for (const d of userList) {
      const phone_number = d.phone_number.replace(/-/g, "");
      result.push({ to: phone_number });
    }
    return result
  }

  // 지연발송 시간 분 단위로 변경
  const changeToMinute = (hour, minute) => {
    return (Number(hour) * 60) + Number(minute)
  }

  // 저장 버튼 클릭
  const sendMessage = () => {
    if (userList.length < 1) {
      setMsgAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "수신인을 선택해주세요." } })

      return;
    }
    if (nvl(input.msgCate) === "") {
      setMsgAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "카테고리를 선택해주세요." } })

      return;
    }
    // if (nvl(input.subject) === "") {
    //   setMsgAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "문자 제목을 선택해주세요." } })

    //   return;
    // }
    if (nvl(input.content) === "") {
      setMsgAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "문자 내용을 입력해주세요." } })

      return;
    }
    // 이미지 관련 로직 추가
    if (nvl(thumbnailImage) !== "") uploadImage()
    else
      sendMessageActivate()
  }

  // 이미지 등록하기
  const selectImage = () => {
    imageRef.current.click()
  }
  const changeImageHandler = (e) => {

    if (e.target.files[0].size > 307200) {
      setMsgAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "파일 용량이 300KB를 초과합니다." } })
      imagePathRef.current.value = "";
      return;
    } else {
      let currentValue = e.target.value
      imagePathRef.current.value = currentValue
      setThumbnailImage(e.target.files[0])
    }

  }

  const uploadImage = () => {
    const formData = new FormData();

    formData.append("uploadFile", thumbnailImage)
    formData.append("file_cate", "mmsimage")

    const successHandler = (response) => {
      imagePathRef.current.value = "";
      imageRef.current.value = "";
      setThumbnailImage(null);

      if (response.messageCode === 200) {
        sendMessageActivate(response.data.up_file_path)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response);
    }
    request.postMultipart("/api/upload/mmsimage", formData, successHandler, failHandler, null, null)
  }




  // 문자 보내기
  const sendMessageActivate = (upFilePath) => {
    if (input.sendType === "N") {
      let params = {
        sendMethod: "M",
        sendType: input.sendType,
        msgCate: input.msgCate,
        upFileName: upFilePath,
        msgStatus: "R",
        recipientPhoneNumber: ChangeToRecipientPhoneNumber(userList),
        sendTime: changeToMinute(0, 0),
        subject: input.subject,
        content: input.content,
        managerSeq: managerInfo.seq
      }
      console.log(params, "params")
      const successHandler = (response) => {
        console.log(response)
        if (response.messageCode === 200) {
          infoInit()
        }
      }
      const failHandler = (response) => {
        console.log("에러" + response)
      }
      request.post("/api/message/sms/send", params, successHandler, failHandler, null, null);
    } else {
      let params = {
        msgCate: input.msgCate,
        subject: input.subject,
        content: input.content,
        upFileName: upFilePath,
        // auto_send_type: "",
        sendMethod: "M",
        recipientPhoneNumber: ChangeToRecipientPhoneNumber(userList),
        sendType: input.sendType,
        sendTime: changeToMinute(hour, minute),
        msg_status: "R",
        managerSeq: managerInfo.seq
      }
      console.log(params, "params")
      const successHandler = (response) => {
        console.log(response)
        if (response.messageCode === 200) {
          infoInit()
        }
      }
      const failHandler = (response) => {
        console.log("에러" + response)
      }
      request.post("/api/message/sent_multi", params, successHandler, failHandler, null, null);
    }

  }

  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_sns_send PopSnsSend active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container m">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => snsSendBool ? setSnsSendBool(!snsSendBool) : setLnbSearchOptions(prev => { return { ...prev, send: false } })}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">SNS 문자 보내기</h1>
            </div>
            <div className="com_tab">
              <button className="menu"><i className="svg_icon icon_kakao">&nbsp;</i>카카오톡</button>
              <button className="menu active"><i className="svg_icon icon_sms yellow">&nbsp;</i>문자</button>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <ul className="com_input_section">
                <li className="tit_field">
                  수신인검색
                </li>
                <li className="input_field input_wrap">
                  <input type="text" className="input" value={messageSearchOptions.searchKeyword} placeholder="고객명, 카드번호, 전화번호"
                    onKeyDown={handleOnKeyPress} onChange={updateSearchKeyword} />
                  <button className="com_btn m blue icon" onClick={msgCustomerSearch}><i className="svg_icon icon_search white">&nbsp;</i></button>
                </li>
                <li className="tit_field"> 직접 입력</li>
                <li className="input_field input_wrap tel_list_wrap">
                  <div>
                    <fieldset className="com_field type_tel">
                      <select className="select" name="phone_num1" ref={mobile_1Ref} onChange={(e) => changePhoneNumber(e)}>
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                        <option value="019">019</option>
                      </select>
                      <input type="text" className="input" name="phone_num2" ref={mobile_2Ref} onChange={(e) => changePhoneNumber(e)} />
                      <input type="text" className="input" name="phone_num3" ref={mobile_3Ref} onChange={(e) => changePhoneNumber(e)} />
                    </fieldset>
                  </div>
                  <button className="com_btn icon m" onClick={() => addUserList()}><i className="svg_icon icon_plus">&nbsp;</i></button>
                </li>
                <li className="input_field full line">
                  <div className="call_list scroll">
                    {userList !== null && userList.length > 0 ? userList.map((user, index) => (
                      user.user_seq !== undefined ?
                        <p className="item" key={"user" + index}>
                          {user.user_name} ({convertGender(user.user_gender)}/{nvl(user.jumin_1) === "" ? " - " : convertJuminToAge((user.jumin_1) + "-" + (user.jumin_2))}) {":"} {user.phone_number ? user.phone_number : user.user_mobile}
                          <button className="com_btn icon" onClick={() => deleteUserList(index)}><i className="svg_icon icon_x">&nbsp;</i></button>
                        </p>
                        : <p className="item" key={"user" + index}>
                          {`익명 (무관) : ${user.phone_number}`}
                          <button className="com_btn icon" onClick={() => deleteUserList(index)}><i className="svg_icon icon_x">&nbsp;</i></button>
                        </p>)
                    ) : null}
                  </div>
                </li>
                <li className="input_field full line">
                  <select className="select" name="msgCate" value={input.msgCate} onChange={(e) => changeInput(e)}>
                    <option value="">카테고리 선택</option>
                    {smsCategoryList !== null && smsCategoryList?.map((category) => (
                      <option key={category.common_code} value={category.common_code}>{category.code_name}</option>
                    ))}
                  </select>
                </li>
                <li className="input_field full">
                  <select className="select" name="subject" value={input.msgSeq} onChange={(e) => changeInput(e)}>
                    <option value="">문자 제목 선택</option>
                    {filterList !== null && filterList.map((frequent) => (
                      <option key={frequent.msg_seq} value={frequent.msg_seq}>{frequent.msg_subject}</option>
                    ))}
                  </select>
                </li>
                <li className="input_field full">
                  <textarea className="textarea" placeholder="내용 (500자)" name="content" value={input.content} onChange={(e) => changeInput(e)}></textarea>
                </li>
                <li className="input_field full input_wrap">
                  <input type="text" className="input" placeholder="등록할 이미지" ref={imagePathRef} />
                  <input type="file" accept="image/jpg" style={{ display: "none" }} ref={imageRef} onChange={changeImageHandler} />
                  <button className="com_btn blue m" onClick={() => selectImage()}>이미지 찾기</button>
                </li>
                <li className="tit_field col_left">발송조건</li>
                <li className="tit_field col_right">뒤 발송</li>
                <li className="input_field col_left">
                  <select className="select" name="sendType" value={input.sendType} onChange={(e) => changeInput(e)}>
                    <option value="N">즉시 발송</option>
                    <option value="A">지연 발송</option>
                  </select>
                </li>
                <li className="input_field input_wrap col_right">
                  <div className="com_btn_amount" >
                    <button className="com_btn icon m" onClick={() => setHour(prev => Math.max(prev + 1, 0))} disabled={input.sendType === "N"}><i className="svg_icon icon_plus">&nbsp;</i></button>
                    {hour.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                    <button className="com_btn icon m" onClick={() => setHour(prev => Math.max(prev - 1, 0))} disabled={input.sendType === "N"}><i className="svg_icon icon_minus">&nbsp;</i></button>
                  </div>
                  <div className="com_btn_amount">
                    <button className="com_btn icon m" onClick={() => setMinute(prev => Math.max(prev + 1, 0))} disabled={input.sendType === "N"}><i className="svg_icon icon_plus">&nbsp;</i></button>
                    {minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                    <button className="com_btn icon m" onClick={() => setMinute(prev => Math.max(prev - 1, 0))} disabled={input.sendType === "N"}><i className="svg_icon icon_minus">&nbsp;</i></button>
                  </div>
                </li>
              </ul>

            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn blue l" onClick={() => (sendMessage())}>보내기</button>
            </div>
          </div>
        </Draggable>
      </section>
      {messageSearchOptions.checkedBool && <CustomListMsg checkedBool={messageSearchOptions.checkedBool} checkHandler={checkHandler}
        totalCount={messageSearchOptions.totalCount} searchList={messageSearchOptions.searchList} setUserList={setUserList} userList={userList} />}
      {msgAlertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={msgAlertLayerPopup.alertType} alertMessage={msgAlertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
    </>
  )
}

export default SnsSend