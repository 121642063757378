//* 문자 카테고리 관리
import React, { useRef, useState } from "react";
import request from '../../utils/Request.utils';
import Draggable from "react-draggable";
import { nvl } from "utils/Common.utils";
import CustomAlert from "atoms/common/CustomAlert";


const SmsCategoryManage = (props) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  let smsCategoryManageBool = props.smsCategoryManageBool;

  const popupSectionRef = useRef();
  const categoryNameRef = useRef();
  const nodeRef = useRef(null);

  const [selectedCategory, setSelectedCategory] = useState("")
  // alert, confirm 창 
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  if (smsCategoryManageBool) popupSectionRef.current.classList.add('active');
  else if (!smsCategoryManageBool && popupSectionRef.current) popupSectionRef.current.classList.remove('active');

  // 문자 카테고리 관리 팝업창 닫기
  const popupClose = () => {
    props.smsCategoryManageToggle()
  }

  // alert 창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const clickHandler = (e, codeName) => {
    if(e.target.value === selectedCategory) { // 선택해제
      setSelectedCategory('')
      categoryNameRef.current.value = ''
      e.target.checked = false
    } else { // 선택
      setSelectedCategory(e.target.value)
      categoryNameRef.current.value = codeName
      e.target.checked = true
    }
  }

  // 문자 카테고리 등록하기
  const saveSmsCategory = () => {
    let categoryName = categoryNameRef.current.value;

    if (nvl(categoryName) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "카테고리명을 입력해주세요." } })

      return;
    }

    if(nvl(selectedCategory) === '') setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "카테고리를 등록하시겠습니까?", activateMode: "saveCategory" } })
    else {
      // added by psk 20230607 - 문자 카테고리 수정하기
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "카테고리를 수정하시겠습니까?", activateMode: "updateCategory" } })
    }
  }

  // 문자 카테고리 삭제하기
  const deleteCategory = () => {
    if (nvl(selectedCategory) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "카테고리를 선택해주세요.." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "카테고리를 삭제하시겠습니까?", activateMode: "deleteCategory" } })
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (alertLayerPopup.activateMode === "saveCategory") {
        let params = {
          parent_code: "003000000",
          code_level: "2",
          code_name: nvl(categoryNameRef.current.value),
          code_desc: null,
          reg_manager_seq: managerInfo.seq
        }
        // console.log(params)

        const successHandler = (response) => {
          console.log(response)
          if (response.messageCode === 200) {
            categoryNameRef.current.value = "";

            // 다시 호출
            props.getSmsCategory()
          } else {
            console.log("카테고리를 등록하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response)
        }

        request.post("/api/etc/commoncd", params, successHandler, failHandler, null, null);
      } else if (alertLayerPopup.activateMode === "deleteCategory") {
        let params = {
          modi_manager_seq: managerInfo.seq
        }
        const successHandler = (response) => {
          console.log(response)
          if (response.messageCode === 200) {
            props.getSmsCategory()

            categoryNameRef.current.value = ''
            setSelectedCategory('')
          } else if (response.messageCode === 409) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "카테고리 내 문자내용이 있는 경우 삭제가 불가능합니다." } })
          }
        }
        const failHandler = (response) => {
          console.log("에러" + response)
        }

        request.del(`/api/etc/commoncd/smsCategory/${selectedCategory}/${managerInfo.seq}`, params, successHandler, failHandler, null, null)
      } else if (alertLayerPopup.activateMode === "updateCategory") {
        let params = {
          code_name: nvl(categoryNameRef.current.value),
          modi_manager_seq: managerInfo.seq,
          code_desc: null
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {

            props.getSmsCategory()
          }
        }
        
        request.put(`/api/etc/commoncd/smsCategory/${selectedCategory}`, params, successHandler)
      }
    }
  }

  return (
    <>
      {/* //* 일반 팝업 */}
      <section ref={popupSectionRef} className="com_popup type_normal">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => popupClose()}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">문자 카테고리 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">
              <fieldset className="add">
                <input type="text" className="input" ref={categoryNameRef} />
                <button type="button" className="com_btn m blue" onClick={() => saveSmsCategory()}>등록</button>
              </fieldset>
              <article className="list">
                {props.smsCategoryList === null && props.smsCategoryList.length === 0 ?
                  <ul className="item_wrap scroll">
                    <li className="item no_data">카테고리가 없습니다.</li>
                  </ul> :
                  props.smsCategoryList?.map((category, index) => (
                    <ul className="item_wrap scroll" key={category.common_code}>
                      <li className="item">
                        <fieldset className="com_radio_wrap">
                          <input type="radio" className="radio" id={"radio1" + index} name="select_visit" value={category.common_code} onClick={(e) => clickHandler(e, category.code_name)} />
                          <label className="btn_radio" htmlFor={"radio1" + index}>&nbsp;</label>
                          <label htmlFor={"radio1" + index}>{category.code_name}</label>
                        </fieldset>
                      </li>
                    </ul>
                  ))
                }
              </article>
              <p className="tip_txt">카테고리내 문자내용이 있는 경우<br />삭제가 불가능합니다.</p>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={() => deleteCategory()}>삭제</button>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default SmsCategoryManage