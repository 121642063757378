//* 결산-매출왕
import React, { useEffect, useState, useRef, useCallback } from "react";
import StatisticsSubButton from "atoms/statistics/StatisticsSubButton";
import StatisticsSubDateSearch2 from "atoms/statistics/StatisticsSubDateSearch2";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { convDateToString, addComma } from "../../utils/Common.utils";
import { utils, writeFile } from 'xlsx';
import request from "utils/Request.utils";
import SnsSend from "components/common/_SnsSend.js";

const CalcBestSales = () => {
  const componentRef = useRef();
  const tableRef = useRef();
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [snsSendBool, setSnsSendBool] = useState(false);

  const [dataList, setDataList] = useState([]);         //매출왕 Data

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `매출왕_${todayDate}`,
  });

  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `매출왕_${todayDate}.xlsx`);
  };

  //조회 > 매출왕
  const getSummary = useCallback( (sDate, eDate) => {
      
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        console.log('getSummary::', response.data);
        setDataList(response?.data?.dataList);
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response);
      setDataList([]);
    }
    if(sDate !== undefined && eDate !== undefined){
      request.get(`/api/statistics/settlement/customer/max?start_date=${sDate}&end_date=${eDate}`, null, successHandler, failHandler, null, null);
    }

  },[]);

  useEffect(() => {
    getSummary(startDate, endDate);
  },[getSummary, startDate, endDate]);
  
	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            {/*//^ lnb2 버튼 영역 */}
            <div className="lnb2_menu_container">
            <StatisticsSubButton subButtonAction={subButtonAction} />
            </div>
          </article>
          <article className="com_lnb2_right">
            <StatisticsSubDateSearch2 searchHandler={getSummary} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
          </article>
        </div>
      </section>
      <section className="com_content">
        <div ref={componentRef}>
          <div className="com_list box">
            <article className="top_area">
              <div className="info_field">
                <p className="date">{startDate === endDate ?  convDateToString(startDate) : `${convDateToString(startDate)} ~ ${convDateToString(endDate)}`}</p>
              </div>
              {/*
              <div className="btn_area">
                <button className="com_btn blue m" onClick={() => { setSnsSendBool(!snsSendBool) }}><i className="svg_icon icon_sms yellow">&nbsp;</i>문자보내기</button>
              </div>
              */}
            </article>
            <div className="scroll with_btn_bottom">
              <table className="list" ref={tableRef}>
                <colgroup><col width="6.2%"/><col width="6.2%"/><col/><col width="12.4%"/><col width="12.4%"/><col width="12.4%"/><col width="12.4%"/><col width="12.4%"/><col width="12.4%"/></colgroup>
                <thead className="sticky top">
                  <tr>
                    <th>No</th>
                    <th>선택</th>
                    <th>차트번호</th>
                    <th>이름</th>
                    <th>등급</th>
                    <th>최근 내원일</th>
                    <th>내원경로</th>
                    <th>기간내 매출액</th>
                    <th>전화번호</th>
                  </tr>
                </thead>
                <tbody>

                {dataList?.length > 0 ? dataList?.map((cust, index) => {
                  return(
                    <React.Fragment key={"max" + index}>

                  <tr>
                    <td>1</td>
                    <td>
                      <fieldset className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="chk1" />
                        <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                      </fieldset>
                    </td>
                    <td>{cust.chart_code}</td>
                    <td>{cust.user_name} (남/25)</td>
                    <td>{cust.user_level_name}</td>
                    <td>{cust.max_date?.slice(0,10)}</td>
                    <td>홈페이지</td>
                    <td><span className="txt_blue">{addComma(cust.payment_amt)}</span></td>
                    <td>{cust.user_mobile}</td>
                  </tr>
                  </React.Fragment>
                    )
                  }) : <tr><td colspan="19">내역이 없습니다.</td></tr>
                }

                <tr className="total">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><span className="txt_blue">3,108,960</span></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="com_btn_bottom_area">
          <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
          <button className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
        </div>
      </section>
      {snsSendBool && <SnsSend snsSendBool={snsSendBool} setSnsSendBool={setSnsSendBool} />}
		</>
	)
}

export default CalcBestSales