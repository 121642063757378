//*HotKey 등록 & 수정
import React, { useState, useEffect } from "react";

import { nvl, nvlNumber } from "../../utils/Common.utils";
import CustomAlert from '../../atoms/common/CustomAlert';
import request from '../../utils/Request.utils';

const HotkeyAdd = ({ layerPopup, setLayerPopup, closeHotkeyAddPopup }) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const hotkeyAdd =() => {
    if(nvl(layerPopup.hotkey_keyword) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "핫키를 입력해 주십시오." } })

      return;
    } else if(nvl(layerPopup.hotkey_keyword) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "내용을 입력해 주십시오." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "핫키를 등록하시겠습니까?", activateMode: 'hotkeyAdd' } })
  }

  const hotkeyModify =() => {
    if(nvl(layerPopup.hotkey_keyword) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "핫키를 입력해 주십시오." } })

      return;
    } else if(nvl(layerPopup.hotkey_keyword) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "내용을 입력해 주십시오." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "핫키를 수정하시겠습니까?", activateMode: 'hotkeyModify' } })
  }

  const returnAlertValue = (value) => {
    if(nvl(value) === 'OK') {
      if(alertLayerPopup.activateMode === 'hotkeyAdd') {
        let params = {
          hotkey_keyword: layerPopup.hotkey_keyword,
          hotkey_description: layerPopup.hotkey_description,
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if(response.messageCode === 200) {
            closeHotkeyAddPopup()
          } else {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "핫키를 등록하던 중 에러가 발생했습니다.<br />다시 시도해 주십시오." } })
          }
        }
  
        request.post(`/api/etc/hotkeys`, params, successHandler);
      } else if(alertLayerPopup.activateMode === 'hotkeyModify') {
        let params = {
          hotkey_keyword: layerPopup.hotkey_keyword,
          hotkey_description: layerPopup.hotkey_description,
          modi_manager_seq: managerInfo.seq,
          hotkey_seq: layerPopup.hotkey_seq
        }

        const successHandler = (response) => {
          if(response.messageCode === 200) {
            closeHotkeyAddPopup()
          } else {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "핫키를 수정하던 중 에러가 발생했습니다.<br />다시 시도해 주십시오." } })
          }
        }
  
        request.put('/api/etc/hotkeys', params, successHandler);
      }
    }
  }

  const valueInputHandler = (e) => {
    let { id, value } = e.target;

    if(id === 'hotkey_keyword') setLayerPopup((prev) => { return { ...prev, hotkey_keyword: value } })
    else if(id === 'hotkey_description') setLayerPopup((prev) => { return { ...prev, hotkey_description: value } })
  }

  useEffect(() => {
    if(nvlNumber(layerPopup.hotkey_seq) > 0) {
      const successHandler = (response) => {
        if (response.messageCode === 200) {
          let hotkeyInfo = response.data.dataList[0];

          setLayerPopup((prev) => { return { ...prev, hotkey_keyword: hotkeyInfo.hotkey_keyword, hotkey_description: hotkeyInfo.hotkey_description } })
        } else console.debug("데이터가 없습니다.");
      }

      request.get(`/api/etc/hotkeys/${layerPopup.hotkey_seq}`, null, successHandler);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

	return (
		<>
      <section className={ layerPopup.hotkeyAdd ? "com_popup type_normal pop_hotkey_add PopHotkeyAdd active" : "com_popup type_normal pop_hotkey_add PopHotkeyAdd" }>{/*클래스 active추가시 노출*/}
        <div className="pop_container m">
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={ closeHotkeyAddPopup }></button>
          {/* //^ 파란색 팝업 타이틀 */}
          <div className="pop_header">
            <h1 className="pop_title">HotKey { nvlNumber(layerPopup.hotkey_seq) > 0 ? '수정' : '등록' }</h1>
          </div>
          {/* //^ 팝업 본문 영역 */}
          <div className="pop_body">
            <ul className="com_input_section">
              <li className="input_field input_wrap full">/&nbsp;&nbsp;
                <input type="text" id="hotkey_keyword" placeholder="상용구 입력" className="input" onChange={ valueInputHandler } value={ layerPopup != null ? nvl(layerPopup?.hotkey_keyword) : "" } />
              </li>
              <li className="input_field full">
                <textarea id="hotkey_description" className="textarea" placeholder="상용구 내용 입력 (100자)" maxLength={ 100 } onChange={ valueInputHandler } value={ layerPopup != null ? nvl(layerPopup?.hotkey_description) : "" }></textarea>
              </li>
            </ul>
          </div>
          <div className="pop_footer com_btn_wrap">
            {
              nvlNumber(layerPopup.hotkey_seq) > 0 ?
                <button className="com_btn blue l" onClick={ hotkeyModify }>수정</button>
              :
                <button className="com_btn blue l" onClick={ hotkeyAdd }>등록</button>
            }
          </div>
        </div>
      </section>
      { alertLayerPopup.visible && <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> }
		</>
	)
}

export default HotkeyAdd