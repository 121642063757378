import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import { nvl } from "../../utils/Common.utils";
import CustomAlert from '../../atoms/common/CustomAlert';

const SubSearch = (props) => {
  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
    returnValue: () => { }
  });

  const [searchKeyword, setSearchKeyword] = useState('');

  const [buttons, setButtons] = useState([
    { text: "오늘", value: 0, active:true },
    { text: "주간", value: 1, active:false },
    { text: "월간", value: 2, active:false }
  ]);

  const clickHandler = (value) => {
    // console.log("value : " + value);

    let newSubButton = [];

    for(const button of buttons) {
      if(button.value === value) button.active = true;
      else button.active = false;

      newSubButton = [
        ...newSubButton,
        button
      ]
    }

    setButtons(newSubButton);

    props.searchHandler(value);
  }

  const searchKeywordRef = useRef();

  const keywordSearchActivate = () => {
    let searchKeyword = nvl(searchKeywordRef.current.value);

    // if(searchKeyword === "") {
    //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "검색어를 입력해주세요." } })
    //   return;
    // }

    props.keywordSearchHandler(searchKeyword);
  }

  useEffect(() => {
    clickHandler(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }


  const getPlaceholder = () => {
    let path = window.location.pathname;

    switch(path) {
      case '/crm/customer':
        return '고객명 검색';

      case '/notice':
        return '공지사항 제목, 작성자 검색';

      default:
          return '검색어를 입력해 주세요.';
    }
  }

  const keywordInput = (e) => {
    setSearchKeyword(e.target.value);
  }

  const keywordInit = () => {
    setSearchKeyword('');

    props.keywordSearchHandler('');
  }

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      keywordSearchActivate();
    }
  }
  
  return (
    <>
      <article className="com_lnb2_right">
        <div className="lnb2_search_wrap">
          { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ alertLayerPopup.returnValue } /> : null }
          <section className="com_field type_normal">
            <fieldset className="many">
              {
                buttons.map(item => (
                  <button key={"subButton_" + uuidv4()} className={item.active ? "com_btn m blue" : "com_btn m line blue"} onClick={(e) => {clickHandler(item.value)}}>{item.text}</button>
                ))
              }
              <div className="input_del_wrap">
                <input ref={searchKeywordRef} type="text" className="input" placeholder={ getPlaceholder() } onChange={ keywordInput } value={ searchKeyword } onKeyDown={ handleOnKeyPress } />
                <button type="button" className="btn_input_del" onClick={ keywordInit }></button>
              </div>
              <button type="button" className="com_btn m blue" onClick={(e) => { keywordSearchActivate() }}>조회</button>
            </fieldset>
          </section>
        </div>
      </article>
    </>
  )
}

export default SubSearch