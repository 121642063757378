import CustomAlert from "atoms/common/CustomAlert";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import request from "utils/Request.utils";
import { nvl, nvlNumber, vatCalculator } from "utils/Common.utils.js";
import stateCustomer from "reducers/Customer";

const Treatment = ({ treatmentList, setTreatmentList, setSelectedItems, selectedItems, stateCustomer }) => {

  const onChangeCheck = (e, index) => {
    const newArr = [...treatmentList];
    newArr[index].checked = e.target.checked;

    setTreatmentList(newArr);

    let tempList = []
    let selectedList = []
    let existCheck = []

    const selectedCheck = treatmentList.filter((item, itemIndex) => {
      existCheck = [];

      if ((item.p_goods_type === 'P' && `pack_${item.pack_seq}` === e.target.name) || (item.p_goods_type === 'D' && `${item.pack_seq}_${item.goods_seq}` === e.target.value)) {
        tempList[itemIndex] = { ...treatmentList[itemIndex], checked: e.target.checked } // 일단 checked 상태 변경

        if (e.target.checked) { // checked 상태에서는 구매 상품으로~

          console.log(selectedList, ' | ', selectedList?.length)
          const serviceGoods = {
            user_seq: stateCustomer.user_seq,
            buy_order_seq: 0,
            chart_type: "F",
            pack_seq: tempList[itemIndex].pack_seq,
            goods_seq: tempList[itemIndex].goods_seq,
            goods_name: tempList[itemIndex].goods_name,
            total_times: tempList[itemIndex].treatment_times,
            remain_times: tempList[itemIndex].treatment_times,
            current_times: 0,
            used_times: 0,
            last_order_seq: 0,
            done_YN: "N",
            service_YN: "Y",
          }

          if (selectedList == null || selectedList.length === 0) {

            selectedList.push(serviceGoods);
          } else {
            existCheck = selectedList.filter(subItem => (subItem.pack_seq === tempList[itemIndex].pack_seq && subItem.goods_seq === tempList[itemIndex].goods_seq))

            if (existCheck == null || existCheck.length < 1) {
              selectedList.push(serviceGoods);
            }
          }

        } else { // 해제 시
          selectedItems.forEach((buyItem, buyIndex) => {
            if (item.pack_seq === buyItem.pack_seq) { //  && item.goods_seq === buyItem.goods_seq

              setSelectedItems((prev) => {
                return prev.filter((item, index) => index !== buyIndex);
              });
            }
          })
        }


      } else tempList[itemIndex] = { ...treatmentList[itemIndex] }

      return selectedList
    });

    if (selectedCheck != null && selectedCheck.length > 0) {

      setTreatmentList(tempList)
      setSelectedItems((prev) => prev.concat(selectedList));
    }
  }

  return (
    treatmentList?.map((item, index) => {
      return (
        <tr key={index}>
          <td key={item.goods_seq + '_chk'} >
            <fieldset className="com_check_wrap">
              <input type="checkbox" className="checkbox" id={`p-tr-chk-${index}`} name={`pack_${item.pack_seq}`} checked={nvl(item?.checked) === '' ? false : item.checked} onChange={(e) => onChangeCheck(e, index)} value={`${item.pack_seq}_${item.goods_seq}`} />
              <label className="btn_checkbox" htmlFor={`p-tr-chk-${index}`}>&nbsp;</label>
            </fieldset>
          </td>
          <td key={item.goods_seq + '_name'} className="tit">{item.goods_name}</td>
          <td key={item.goods_seq + '_times'}>{item.treatment_times}</td>
          <td key={item.goods_seq + '_price'} className="num">{vatCalculator(item.p_goods_type, item.p_vat_type, item.pack_sale_price, item.goods_sale_price, item.vat_type, item.goods_price)}</td>
        </tr>)
    })
  )
}

const ServiceProcedureAdd = ({ onClose, setRemains }) => {
  const stateCustomer = useSelector(state => state.Customer); // 고객정보
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  const [categoryList, setCategoryList] = useState([]);
  const [treatmentList, setTreatmentList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchText, setSearchText] = useState('');
  const [selectedItems, setSelectedItems] = useState("");

  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => { },
    id: '',
  });

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertPop((prev) => { return { ...prev, visible: false } })
  }

  const onChangeCategory = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedItems([]);
  }

  const searchTreatment = () => {
    let params = {
      searchData: searchText,
      category: selectedCategory,
      delete_yn: 'N',
      display_yn: 'Y',
      orderBy: "PG.goods_seq DESC, CG.goods_seq ASC"
    }

    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        setTreatmentList(response.data.dataList)
      } else {
        console.log("시술 목록을 가져오지 못했습니다.")
      }
    }

    // 여기서 초기화 하는 이유는 당일 오더에 추가하지 않은 상태에서 다시 검색을 하면 기존 데이터를 날리기 위해서
    setSelectedItems([]);

    request.get(`/api/goods/list/service?searchData=${params.searchData}&category=${params.category}&delete_yn=${params.delete_yn}&display_yn=${params.display_yn}&order_by=${params.orderBy}`, null, successHandler);
  }





  const addFreeTreatments = () => {

    if (selectedItems.length === 0) {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: "시술을 선택해주세요." } })

      return;
    }
    setRemains(prev => prev.concat(selectedItems))
  };

  const getTreatmentList = () => {
    setSearchText('')
    searchTreatment()
  }

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') searchTreatment();
  }

  useEffect(() => {
    const getCategoryList = () => {
      const successHandler = (response) => {
        if (response.messageCode === 200) {
          setCategoryList(response.data);
        } else console.debug('Error >> ', response);
      }

      request.get(`/api/etc/commoncd/category/001000000`, null, successHandler);
    };

    getCategoryList();
  }, []);

  useEffect(() => {
    if (nvl(selectedCategory) !== '') getTreatmentList();

    return () => { // selectedCategory 바뀌기 전 초기화
      setTreatmentList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);


  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_service_procedure_add active">
        <div className="pop_container l">
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
          {/* //^ 파란색 팝업 타이틀 */}
          <div className="pop_header">
            <h1 className="pop_title">서비스 시술 추가</h1>
          </div>
          {/* //^ 팝업 본문 영역 */}
          <div className="pop_body">
            <div className="com_field">
              <fieldset className="many">
                <select className="select" name='category' onChange={onChangeCategory} key={selectedCategory} value={selectedCategory}>
                  <option value="">카테고리 선택</option>
                  {
                    categoryList != null && categoryList.length ?
                      categoryList?.map((item) => { return <option key={item.code} value={item.code}>{item.name}</option> })
                      : null
                  }
                </select>
                <div className="input_del_wrap">
                  <input type="text" className="input" placeholder="시술명 검색" name="" value={nvl(searchText)} onChange={(e) => setSearchText(e.target.value)} onKeyDown={handleOnKeyPress} />
                  <button type="button" className="btn_input_del" onClick={() => setSearchText('')}>&nbsp;</button>
                </div>
                <button className="com_btn icon blue m" onClick={searchTreatment}><i className="svg_icon icon_search">&nbsp;</i></button>
              </fieldset>
            </div>
            <article className="com_list">
              <table className="list">
                <colgroup><col width="60px" /><col /><col width="20%" /><col width="23%" /></colgroup>
                <thead>
                  <tr>
                    <th>선택</th>
                    <th>서비스 시술명</th>
                    <th>회차</th>
                    <th>금액</th>
                  </tr>
                </thead>
                <tbody>
                  <Treatment treatmentList={treatmentList} setTreatmentList={setTreatmentList} setSelectedItems={setSelectedItems} selectedItems={selectedItems} stateCustomer={stateCustomer} />
                </tbody>
              </table>
            </article>
          </div>
          <div className="pop_footer com_btn_wrap">
            <button className="com_btn l blue" onClick={addFreeTreatments}><i className="svg_icon icon_add yellow">&nbsp;</i>잔여 시술에 추가</button>
          </div>
        </div>
      </section>
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
    </>
  )
}

export default ServiceProcedureAdd