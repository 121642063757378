import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PwFail from "./_PwFail.js"//로그인 실패 alert
import IDBlock from "./_IDBlock.js"//아이디 차단

import request from '../../utils/Request.utils';
import CustomAlert from '../../atoms/common/CustomAlert';
import LocalStorage from '../../utils/LocalStorage.utils';
import SessionStorage from '../../utils/SessionStorage.utils';

import { nvl } from "../../utils/Common.utils";

const Login = () => {
  const dispatch = useDispatch();
  
  const etcSession = LocalStorage.getItemJsonParse("etcSession");

  const [bussinessPlaceInfoList, setBussinessPlaceInfoList] = useState([]);
  const [activateMode, setActivateMode] = useState('');
  const [clickedValue, setClickedValue] = useState(false);
  const [businessPlaceInfo, setBusinessPlaceInfo] = useState('');
  const [managerPasswdFailCnt, setManagerPasswdFailCnt] = useState(0);
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const managerIdRef = useRef();
  const managerPasswdRef = useRef();

  if(userInfo != null && nvl(userInfo.id) !== "") window.location.href = "/main";

  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  // 지점 리스트 코드 가져오기
  const getBussinessPlaceInfo = () => {
    let params = {
      order_by: "bp_seq DESC",
      delete_yn: "N"
    }

    const successHandler = (response) => {
      if(response.messageCode === 200) setBussinessPlaceInfoList(response.data.dataList); // ok 코드 값
      else console.log("코드 값을 찾지 못했습니다.");
    }

    request.get(`/api/business?delete_yn=${params.delete_yn}&order_by=${params.order_by}`, null, successHandler);
  }

  useEffect(() => {
    getBussinessPlaceInfo();

    if(nvl(etcSession) !== "") managerIdRef.current.value = nvl(etcSession?.saveId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // 로그인 하기
  const managerLogin = () => {
    if(nvl(businessPlaceInfo) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "지점을 선택해주세요" } })

      return
    } else if(nvl(managerIdRef.current.value) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "아이디를 입력해주세요" } })

      return
    } else if(nvl(managerPasswdRef.current.value) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "패스워드를 입력해주세요" } })

      return
    }

    // ---------------------------------------
    
    let params = {
      business_place_code: businessPlaceInfo,
      manager_id: managerIdRef.current.value,
      manager_passwd: managerPasswdRef.current.value,
      manager_passwd_fail_cnt: managerPasswdFailCnt
    }

    // console.log("실패 체크 : " + managerPasswdFailCnt)

    const successHandler = (response) => {
      if(response.messageCode === 200) { // ok 코드 값
        //console.log("response : " + JSON.stringify(response));
        //alert(JSON.stringify(response));

        setManagerPasswdFailCnt(0)

        if(clickedValue) LocalStorage.setItem("etcSession", "saveId", nvl(managerIdRef.current.value))

        // 토근을 로컬에 저장
        SessionStorage.clearItem("managerSession")
        SessionStorage.setItem("managerSession", "accessToken", response.data.accessToken)
        SessionStorage.setItem("managerSession", "refreshToken", response.data.refreshToken)

        dispatch({ type: "clearCustomer" });

        window.location.href = "/main"; // dashboard 로 가야하나?
      } else if(response.messageCode === 401) { // 이건 블럭된 내용 처리
        setManagerPasswdFailCnt(0)
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "5회 이상 ID 또는 PW 입력오류입니다.<br/>관리자에게 문의하세요." } })
      } else if(response.messageCode === 404) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 정보 이거나 가입 정보가 없습니다." } })
        setManagerPasswdFailCnt(managerPasswdFailCnt + 1)
      } else {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 정보 이거나 가입 정보가 없습니다." } })
        setManagerPasswdFailCnt(managerPasswdFailCnt + 1)
      }
    }

    request.post("/api/manager/auth/login", params, successHandler);
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const returnAlertValue = (value) => {
    // console.log("value ==> " + value);

    if(nvl(value) === 'OK') {
      // if(activateMode === '뭐 할게 있나?') 몰러();
    }
  }

  const saveIdToggle = (event) => {
    console.log("현재 값 : " + event.target.value + " | " + event.target.checked)

    setClickedValue(event.target.checked)
  }

  const businessPlaceInfoChangeHandler = ({ target: { value } }) => {
    console.log("businessPlaceInfo : " + value);

    setBusinessPlaceInfo(value)
  }

  const handleOnKeyPress = (e) => {
    if(e.key === 'Enter') managerLogin();
  };

	return (
		<>
			<section className="com_content p_login">
				<div className="img_area">&nbsp;</div>
        <div className="login_area">
          <fieldset className="wrap">
           <legend className="tit">로그인</legend>
            <select className="select" onChange={ businessPlaceInfoChangeHandler }>
              <option value="">지점선택</option>

              {
                (bussinessPlaceInfoList == null || bussinessPlaceInfoList.length < 1) ?
                  null
                :
                bussinessPlaceInfoList.map((item, index) => (
                  <option key={ item.bp_seq } value={ item.bp_seq }>{ item.company_name }</option>
                ))
              }

            </select>
            <div className="input"><i className="svg_icon icon_id gray">&nbsp;</i><input type="text" ref={ managerIdRef } placeholder="ID"/></div>
            <div className="input"><i className="svg_icon icon_password">&nbsp;</i><input type="password" ref={ managerPasswdRef } placeholder="PASSWORD" onKeyDown={ handleOnKeyPress } /></div>
            {
              managerPasswdFailCnt > 0 ?
                <p className="txt_red">
                  ID 또는 PW가 정확하지 않습니다.<br/>{ 5 - managerPasswdFailCnt }회 남았습니다.
                </p>
              : null
            }
            <div className="com_check_wrap">
              <input type="checkbox" className="checkbox" id="saveIdToggleCheckBox" onClick={ saveIdToggle } />
              <label className="btn_checkbox" htmlFor="saveIdToggleCheckBox">&nbsp;</label>
              <label htmlFor="saveIdToggleCheckBox">ID저장</label>
				    </div>
            <button className="com_btn blue l btn_login" onClick={ () => managerLogin() }>로그인</button>
          </fieldset> 
        </div>
			</section>
      <PwFail />
      <IDBlock />
      {
        alertLayerPopup.visible ?
          <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } />
        : null
      }
		</>
	)
}

export default Login