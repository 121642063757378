// *관리>시술관리>등록
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import request from "utils/Request.utils.js";
import { v4 as uuidv4 } from 'uuid'
import Editor from "components/smartEditor/Editor.js";
import ProcedureCategoryManage from './_ProcedureCategoryManage.js';//시술 카테고리 관리 팝업
import CustomAlert from "atoms/common/CustomAlert.js"; // alert창
import AddUsingGoodsOld from "atoms/common/procedure_Add/AddUsingGoods_1115bak.js";
import PopupCalendar from '../../atoms/common/PopupCalendar.js';
import { addComma, nvl, nvlNumber } from "utils/Common.utils.js";
import AlertWriteOk from "components/notice/AlertWriteOk.js";

const ProcedureAddOld = () => {
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const imageRef = useRef();
  const imagePathRef = useRef();
  const inputRef = useRef();

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  const [WriteOkPopup, setWriteOkPopup] = useState({
    closeType: 'next',
    alertMessage: '등록 되었습니다.',
    visible: false
  });

  const [activateMode, setActivateMode] = useState("")
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값


  // 달력
  const [dateType, setDateType] = useState('hyphen');
  const [viewType, setViewType] = useState('');

  const [procedureCategoryManageBool, setProcedureCategoryManageBool] = useState(false); // 시술 카테고리 관리

  const [procedureCategory, setProcedureCategory] = useState([])// 시술 카테고리 리스트
  const [procedureList, setProcedureList] = useState([]) // 시술 목록
  const [filterProcedureList, setFilterProcedureList] = useState({}); // 패키지시술 관련 시술 리스트
  const [filterGoodProcedureList, setFilterGoodProcedureList] = useState({}); // 같이하기 좋은 시술 관련 리스트
  const [selectedCategory, setSelectedCategory] = useState({});
  const [procedureNumber, setProcedureNumber] = useState(1); // 시술추가 count
  const [procedureTime, setProcedureTime] = useState(1); // 시술횟수 count
  const defaultInput = {
    category: "",
    goods_type: "D", // 임시
    event_yn: "N",
    event_start_date: "",
    event_end_date: "",
    goods_name: "",
    goods_price: "",
    goods_sale_price: "",
    goods_description: "",
    view_flag: "M",
    using_movie_image: "",
    attention_items: "",
    vat_type: "N",
    insurance_type: "N"
  } // 기본시술
  const [defaultProcedure, setDefaultProcedure] = useState(defaultInput); // 기본시술
  const usingProcedure = {
    goods_times: procedureNumber,
    event_yn: "N",
    goods_name: "",
    goods_price: "",
    goods_sale_price: "",
    insurance_type: "N",
    vat_type: "N"
  }; // 상용시술
  const [usingProcedureList, setUsingProcedureList] = useState([usingProcedure]); // 일반시술 리스트
  const [procedureTimeList, setProcedureTimeList] = useState([{
    goods_times: procedureNumber,
    view_order: 1,
  }]); // 일반시술 횟수리스트
  const [packageTimeList, setPackageTimeList] = useState([{
    goods_times: 1
  }])//  패키지시술 횟수리스트 
  const [goodsTag, setGoodsTag] = useState(""); // 태그 입력창
  const [goodsTagList, setGoodsTagList] = useState([]); // 시술 태그(5개)
  const [qnaList, setQnaList] = useState([{}]); // Q&A 리스트

  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [uploadFileSeq, setUploadFileSeq] = useState("");

  // 시술 카테고리 관리 팝업창
  const procedureCategoryManageToggle = () => {
    setProcedureCategoryManageBool(!procedureCategoryManageBool);
  }
  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }
  const closeAlertWrite = () => {
    setWriteOkPopup((prev) => { return { ...prev, visible: false } })

    if (WriteOkPopup.closeType === "next") {
      setDefaultProcedure(defaultInput)
      setUsingProcedureList([usingProcedure])
      setContent("")
      setGoodsTagList([])
      setProcedureNumber(1)
      setProcedureTimeList([{
        goods_times: procedureNumber,
        view_order: 1,
      }])
      setPackageTimeList([{
        goods_times: 1,
      }])
      // 이미지 초기화 이동 (이미지 등록 => 시술 등록)
      imagePathRef.current.value = ""
      imageRef.current.value = "";
      setThumbnailImage(null);
    }
  }

  // 달력 팝업창
  const popupCalendarHTML = () => {
    let retArr = [];

    if (viewType === 'from' || viewType === 'to') {
      retArr.push(
        <PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={dateType} />
      )
    } else retArr.push(null);
    return retArr;
  }


  const closePopup = () => {
    setViewType('');
  }

  const getCalendar = (value) => {
    // console.log("get calendarValue : " + value + " | " + dateType + " | " + viewType)
    if (viewType === "from") {
      setDefaultProcedure({
        ...defaultProcedure, event_start_date: value
      })
    } else {
      setDefaultProcedure({
        ...defaultProcedure, event_end_date: value
      })
    }
  }

  // 시술 카테고리 가져오기
  const getProcedureCategory = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setProcedureCategory(response.data.dataList)
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get("/api/etc/commoncd/3/001000000", null, successHandler, failHandler, null, null);
  }


  // 수정할 시술 Q&A 가져오기
  const getUpdateQna = useCallback(() => {
    let params = {
      pack_seq: location.state.goods_seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setQnaList(response.data.length > 0 ? response.data : [{}])
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/goods/qna/${params.pack_seq}`, null, successHandler, failHandler, null, null)
  }, [location.state?.goods_seq])

  // 수정할 시술 회차 가져오기
  const getUpdateTreatment = useCallback(() => {
    let params = {
      pack_seq: location.state.goods_seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // console.log(response.data, "treatment")
        setProcedureTimeList(response.data.length > 0 ? response.data : [{
          goods_times: 1,
          view_order: 1,
        }])

        getUpdateQna()
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/goods/treatment-times/${params.pack_seq}/goods_seq/view_order`, null, successHandler, failHandler, null, null);
  }, [getUpdateQna, location.state?.goods_seq])
  // 수정할 자식 시술 가져오기
  const getUpdateChildProcedure = useCallback(() => {
    let params = {
      pack_seq: location.state.goods_seq
    }
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // console.log(response.data, "child")

        if (defaultProcedure?.goods_type === "D") {
          setUsingProcedureList(response.data.dataList.length > 0 ? response.data.dataList : [usingProcedure])
        } else {
          setPackageTimeList(response.data.dataList.length > 0 ? response.data.dataList.map((el) => (
            {
              category: el.category,
              goods_seq: el.goods_seq,
              goods_name: el.goods_name,
              goods_price: el.goods_price,
              pack_sale_price: el.pack_sale_price
            }
          )) : [{
            goods_times: 1
          }])
          if (selectedCategory[1] !== "" && selectedCategory[2] !== "") {
            let filter = { ...filterProcedureList }
            if (defaultProcedure.goods_type === "P") {
              response.data.dataList.forEach((el, i) => {
                filter[`pack${i + 1}`] = procedureList.filter((e) => e.category === el.category);
              });
            }
            setFilterProcedureList(filter);
          }
        }
        getUpdateTreatment()
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get(`/api/goods/list/children?pack_seq=${params.pack_seq}&delete_yn=N`, null, successHandler, failHandler, null, null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultProcedure?.goods_type, getUpdateTreatment, location.state?.goods_seq, procedureList])

  // 수정할 기본시술 가져오기
  const getUpdateProcedure = useCallback(() => {
    let params = {
      pack_seq: location.state.goods_seq
    }
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // console.log(response.data[0], "parent")

        if (response.data[0]?.recommendation_goods !== "") {
          setSelectedCategory({
            1: procedureList.filter((el) => el.goods_seq === Number(response.data[0]?.recommendation_goods?.split("|")[0]))[0],
            2: procedureList.filter((el) => el.goods_seq === Number(response.data[0]?.recommendation_goods?.split("|")[1]))[0]
          })
          setFilterGoodProcedureList({
            1: procedureList.filter((e) => e.category === procedureList.filter((el) => el.goods_seq === Number(response.data[0].recommendation_goods?.split("|")[0]))[0]?.category),
            2: procedureList.filter((e) => e.category === procedureList.filter((el) => el.goods_seq === Number(response.data[0].recommendation_goods?.split("|")[1]))[0]?.category)
          })
        }
        setDefaultProcedure(response.data[0])
        setContent(response.data[0].detail_description)
        getUpdateChildProcedure()

      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/goods/${params.pack_seq}?gubun=G`, null, successHandler, failHandler, null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUpdateChildProcedure, location.state?.goods_seq]);

  useEffect(() => {
    if (location.state !== null && location.state.editBoolean === true && location.state.goods_seq !== undefined) {
      getUpdateProcedure()
    }

  }, [getUpdateProcedure, location.state])

  // 시술 가져오기 (카테고리 선택용)
  const getProcedure = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setProcedureList(response.data)
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get("/api/goods?gubun=U", null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getProcedureCategory();
    getProcedure();

    return () => { // 컴포넌트가 화면에서 사라졌을 때 초기화
      setProcedureCategory([])// 시술 카테고리 리스트
      setProcedureList([]) // 시술 목록
      setFilterProcedureList({}); // 패키지시술 관련 시술 리스트
      setFilterGoodProcedureList({}); // 같이하기 좋은 시술 관련 리스트
      setSelectedCategory({});
    }
  }, [])

  // 시술추가 버튼 클릭 시
  const addProcedureGoods = () => {
    setProcedureNumber(procedureNumber => procedureNumber + 1);
    if (defaultProcedure.goods_type === "D") {
      const procedureGoods = {
        goods_times: procedureNumber + 1,
        goods_name: usingProcedureList.length === 1 ? usingProcedureList[0].goods_name : usingProcedureList[usingProcedureList.length - 1].goods_name,
        event_yn: usingProcedureList.length === 1 ? usingProcedureList[0].event_yn : usingProcedureList[usingProcedureList.length - 1].event_yn,
        goods_price: usingProcedureList.length === 1 ? usingProcedureList[0].goods_price : usingProcedureList[usingProcedureList.length - 1].goods_price,
        goods_sale_price: usingProcedureList.length === 1 ? usingProcedureList[0].goods_sale_price : usingProcedureList[usingProcedureList.length - 1].goods_sale_price,
        insurance_type: usingProcedureList.length === 1 ? usingProcedureList[0].insurance_type : usingProcedureList[usingProcedureList.length - 1].insurance_type, // 임시로 고정
        vat_type: usingProcedureList.length === 1 ? usingProcedureList[0].vat_type : usingProcedureList[usingProcedureList.length - 1].vat_types,
      }

      setUsingProcedureList([...usingProcedureList, procedureGoods])
      setProcedureTimeList([...procedureTimeList, { goods_times: procedureNumber + 1, view_order: 1 }])
    }
  }

  // 시술 삭제(-) 버튼 클릭 시
  const deleteGoods = (order) => {
    console.log(order, "asdf")
    // n개의 시술 추가 후 중간 입력창을 삭제하려는 경우 -- 임시
    if (location.state === null && order < usingProcedureList.length) {
      alert("삭제할 수 없습니다.")
      return
    }

    setProcedureNumber(procedureNumber - 1)
    setUsingProcedureList(usingProcedureList.filter((el) => el.goods_seq !== undefined ? el.goods_seq !== order : el.goods_times !== order))
    setProcedureTimeList(procedureTimeList.filter((el) => el.goods_seq !== undefined ? el.goods_seq !== order : el.goods_times !== order))
  }

  // 일반(회차추가)/ 패키지(패키지추가) 클릭 시
  const addProcedureTime = (number) => {
    console.log('EV::', '회차 or 패지지 추가');
    setProcedureTime(() => procedureTime + 1)
    if (defaultProcedure.goods_type === "D") {
      let temp = procedureTimeList.filter(el => el.goods_seq !== undefined ? el.goods_seq === number : el.goods_times === number)
      let last = temp[temp.length - 1]
      const pro_times = {
        goods_times: number,
        view_order: last.view_order + 1,
        times_name: last.times_name,
        times_price: last.times_price
        // times_price: usingProcedureList[number - 1].event_yn === "Y" ? usingProcedure[number - 1].goods_sale_price - last.times_price : usingProcedureList[number - 1].goods_price - last.times_price
      }
      if (last.goods_seq !== undefined) {
        pro_times.goods_seq = pro_times.goods_times
        delete pro_times.goods_times
      }

      setProcedureTimeList([...procedureTimeList, pro_times]);
    } else {
      let sum = packageTimeList.map(el => Number(el.pack_sale_price)).reduce((pre, cur) => pre + cur, 0)
      let last = packageTimeList[packageTimeList.length - 1]
      const pro_times = {
        goods_times: last.goods_times ? last.goods_times + 1 : undefined,
        // pack_sale_price: Number(defaultProcedure.goods_price) - sum
      }
      if (last.goods_seq !== undefined) pro_times.goods_seq = pro_times.goods_times
      setPackageTimeList([...packageTimeList, pro_times])
    }
  }

  // 일반(회차 삭제-)/ 패키지(패키지 삭제-) 클릭 시
  const deleteProcedureTime = (seq, order) => {
    console.log(seq, order)
    setProcedureTime(() => procedureTime - 1)
    if (defaultProcedure.goods_type === "D") {
      let deleteList = procedureTimeList.filter((el) => el.goods_seq === undefined ? el.goods_times === seq && el.view_order === order : el.goods_seq === seq && el.view_order === order)[0]

      setProcedureTimeList(procedureTimeList.filter(el => el !== deleteList))
    } else {
      setPackageTimeList(packageTimeList.filter((el, index) => el.goods_times !== undefined ? el.goods_times !== seq : index !== seq))
    }
  }

  // Q&A추가 버튼 클릭 시
  const addQnA = () => {
    const qnaInput = {
      qna_question: "",
      qna_answer: ""
    }

    setQnaList([...qnaList, qnaInput])
  }

  //Q&A 삭제 버튼 클릭 시
  const deleteQna = (order) => {
    setQnaList(qnaList.filter((el, index) => index !== order))
  }
  // 기본시술 입력창 
  const defaultProcedureHandler = (e, num) => {
    const { name, value, checked } = e.target;
    console.log(name, value)

    setDefaultProcedure({ ...defaultProcedure, [name]: value })
    if (name === "category" && value === "001017000") {
      setDefaultProcedure({ ...defaultProcedure, [name]: value, goods_type: "A" })
    } else if (name === "event_yn") {
      setDefaultProcedure({
        ...defaultProcedure, [name]: checked === true ? "Y" : "N", goods_sale_price: null
      })
      setUsingProcedureList(usingProcedureList.map(el => ({ ...el, event_yn: checked === true ? "Y" : "N", goods_sale_price: null })))
    } else if (name === "goods_sale_price") {
      if (Number(value) > Number(defaultProcedure.goods_price)) {
        setAlertLayerPopup(prev => { return { ...prev, visible: true, alertMessage: "시술금액보다 낮은 금액을 입력해주세요." } })
        setDefaultProcedure({ ...defaultProcedure, [name]: "" })
      }
    } else if (name === "recommendation_goods") {
      setSelectedCategory({ ...selectedCategory, [num]: filterGoodProcedureList[num].filter((el) => el.goods_seq === Number(value))[0] })
    } else if (name === "goods_type") {
      if (value === "D") {
        setDefaultProcedure({ ...defaultProcedure, [name]: value })
        setProcedureTimeList([{
          goods_times: procedureNumber,
          view_order: 1,
        }])
        setPackageTimeList([{ goods_times: 1 }])
      } else {
        setDefaultProcedure({ ...defaultProcedure, [name]: value })
        setUsingProcedureList([usingProcedure])
      }
    } else if (name === "vat_type" || name === "insurance_type") { // 부가세 별도, 보험가능상품 공통
      setDefaultProcedure({
        ...defaultProcedure, [name]: checked === true ? "I" : "N"
      })
    }
  }

  //카테고리 선택 - 패키지 시술
  const procedureCategoryHandler = (e, num, order) => {
    // console.log(num, order)
    const { value } = e.target;

    // 이전 패키지에서 선택한 동일 카테고리 내 시술, 다음 패키지에서 삭제 처리 
    let temp = procedureList.filter(item => item.category === value)
    if (defaultProcedure.goods_type === "P" && packageTimeList.length > 1 && packageTimeList.filter((i) => temp.some((ii) => ii.goods_seq === i.goods_seq))) {
      let copy = [...packageTimeList]
      copy[order] = { ...copy[order], category: value }
      setPackageTimeList(copy)
      let deleteList = temp.filter(el => !packageTimeList.some((ii) => el.goods_seq === ii.goods_seq))

      setFilterProcedureList({ ...filterProcedureList, [num]: deleteList.filter(item => item.category === value) })
    } else if (value === "") {
      let copy = [...packageTimeList]
      copy[order] = { goods_times: order + 1 }
    } else {
      setFilterProcedureList({ ...filterProcedureList, [num]: procedureList.filter(item => item.category === value) })
      let copy = [...packageTimeList]
      copy[order] = { ...copy[order], category: value }
      setPackageTimeList(copy)
    }
  }
  // 카테고리 선택 - 같이하기 좋은 시술
  const goodProcedureCategoryHandler = (e, num) => {
    const { value } = e.target;
    let temp = procedureList.filter(item => item.category === value)
    if (num === 1 && selectedCategory[2]?.goods_seq !== undefined) {
      let deleteList = temp.filter(el => el.goods_seq !== Number(selectedCategory[2].goods_seq))
      setFilterGoodProcedureList({ ...filterGoodProcedureList, [num]: deleteList.filter(item => item.category === value) })
      setSelectedCategory({ ...selectedCategory, [num]: { category: value } })
    } else if (num === 2 && selectedCategory[1]?.goods_seq !== undefined) {
      let deleteList = temp.filter(el => el.goods_seq !== Number(selectedCategory[1].goods_seq))
      setFilterGoodProcedureList({ ...filterGoodProcedureList, [num]: deleteList.filter(item => item.category === value) })
      setSelectedCategory({ ...selectedCategory, [num]: { category: value } })
    } else if (value === "") {
      setSelectedCategory({ ...selectedCategory, [num]: "" })
    } else {
      setFilterGoodProcedureList({ ...filterGoodProcedureList, [num]: procedureList.filter(item => item.category === value) })
      setSelectedCategory({ ...selectedCategory, [num]: { category: value } })
    }
  }

  // 일반시술 입력창
  const usingProcedureHandler = (e, index) => {
    const { name, value, checked } = e.target;

    if (index !== undefined) {
      let copy = [...usingProcedureList];

      copy[index] = { ...copy[index], [name]: value }

      if (name === "event_yn") {
        copy[index] = { ...copy[index], [name]: checked === true ? "Y" : "N" }
      } else if (name === "vat_type") {
        copy[index] = { ...copy[index], [name]: checked === true ? "I" : "N" }
      } else if (name === "goods_sale_price") {
        if (Number(value) > Number(copy[index].goods_price)) {
          setAlertLayerPopup(prev => { return { ...prev, visible: true, alertMessage: "시술금액보다 낮은 금액을 입력해주세요." } })
          copy[index] = { ...copy[index], [name]: "" }
        }

      }
      setUsingProcedureList(copy)
    }
  }

  // 일반(회차) / 패키지(패키지) 입력창
  const timesHandler = (e, order) => {
    // console.log(order)
    const { name, value } = e.target;

    if (order !== undefined) {
      if (defaultProcedure.goods_type === "D") {
        let copy = [...procedureTimeList];
        copy[order] = { ...copy[order], [name]: value }
        setProcedureTimeList(copy)
      } else {
        let copy = [...packageTimeList];
        if (name === "package_goods") {
          const goods = filterProcedureList[`pack${order + 1}`].filter((el) => el.goods_seq === Number(value))[0]
          console.log(goods, "goods")
          copy[order] = { ...copy[order], goods_name: goods.goods_name, goods_seq: Number(value), goods_price: goods.goods_price }
          setDefaultProcedure({ ...defaultProcedure, goods_price: sumGoodsAndPackSalePrice(copy).goodsPrice })
        } else
          if (name === "pack_sale_price") {
            if (value > copy[order].goods_price) {
              setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "할인금액은 시술금액보다 작은 금액만 입력 가능합니다." } })

              return
            }
            copy[order] = { ...copy[order], [name]: value }
            setDefaultProcedure({ ...defaultProcedure, goods_sale_price: sumGoodsAndPackSalePrice(copy).packSalePrice })
          }
        setPackageTimeList(copy)

      }
    }
  }

  // 패키지 시술 가격 더하기
  const sumGoodsAndPackSalePrice = (packageTimeList) => {
    return packageTimeList.reduce(
      (acc, item) => {
        const goodsPrice = parseInt(item.goods_price || 0);
        const packSalePrice = parseInt(item.pack_sale_price || 0);
        return {
          goodsPrice: acc.goodsPrice + goodsPrice,
          packSalePrice: acc.packSalePrice + packSalePrice,
        };
      },
      { goodsPrice: 0, packSalePrice: 0 } // 
    );
  }

  // Q&A 입력창
  const qnaHandler = (e, index) => {
    const { name, value } = e.target;

    if (index !== undefined) {
      let copy = [...qnaList];
      copy[index] = { ...copy[index], [name]: value }
      setQnaList(copy)
    }
  }

  // 태그 추가
  const addTag = (e) => {
    const { value } = e.target;

    if (value.length !== 0 && e.key === "Enter" && e.nativeEvent.isComposing === false) {
      if (goodsTagList.length > 4) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "태그는 5개까지 추가 가능합니다." } })
        setGoodsTag("")

        return;
      } else if (goodsTagList.findIndex(el => el === e.target.value) >= 0) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이미 등록한 태그입니다." } })
        setGoodsTag("")

        return;
      }

      e.preventDefault();
      setGoodsTagList([...goodsTagList, value])
      setGoodsTag("")
    }
  }
  // 태그 삭제
  const deleteTag = (e) => {
    setGoodsTagList(goodsTagList.filter((el) => el !== e.target.value));
  }

  // 시술 등록
  const saveProcedure = () => {
    if (nvl(defaultProcedure.category) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "카테고리를 선택해주세요." } })

      return;
    } else if (nvl(defaultProcedure.goods_type) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 종류를 선택해주세요." } })

      return;
    } else if (nvl(defaultProcedure.goods_name) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "메인 시술명을 입력해주세요." } })

      return;
    } else if (nvl(defaultProcedure.event_yn) === "Y" && (nvl(defaultProcedure.event_start_date) === "" && nvl(defaultProcedure.event_end_date) === "")) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이벤트 기간을 입력해주세요." } })

      return;
    } else if ((nvl(defaultProcedure.goods_type) === "D" || nvl(defaultProcedure.goods_type) === "A") && nvl(defaultProcedure.event_yn) === "Y" && (nvl(defaultProcedure.goods_price) === "" || nvl(defaultProcedure.goods_sale_price) === "")) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "메인 시술 금액을 입력해주세요." } })

      return;
    } else if ((nvl(defaultProcedure.goods_type) === "D" || nvl(defaultProcedure.goods_type) === "A") && nvl(defaultProcedure.event_yn) === "N" && nvl(defaultProcedure.goods_price) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "메인 시술 금액을 입력해주세요." } })

      return;
    } else if (nvl(defaultProcedure.goods_type) === "P" && nvl(sumGoodsAndPackSalePrice(packageTimeList).goodsPrice) === 0) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "메인 시술 금액을 입력해주세요." } })

      return;
    } else if (nvl(defaultProcedure.goods_type) === "P" && nvl(sumGoodsAndPackSalePrice(packageTimeList).packSalePrice) === 0) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "메인 시술 할인금액을 입력해주세요." } })

      return;
    } else if (nvl(defaultProcedure.goods_description) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 간단 설명을 입력해주세요." } })

      return;
    }

    if (nvl(defaultProcedure.goods_type) === "D" && usingProcedureList.length > 0) {
      for (let i = 0; i < usingProcedureList.length; i++) {
        if (JSON.stringify(usingProcedureList[i]) !== JSON.stringify(usingProcedure) && usingProcedureList[i].goods_name === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${usingProcedureList[i].goods_times}시술명을 입력해주세요.` } })

          return;
        } else if (JSON.stringify(usingProcedureList[i]) !== JSON.stringify(usingProcedure) && usingProcedureList[i].event_yn === "N" && usingProcedureList[i].goods_price === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${usingProcedureList[i].goods_times}시술 시술금액을 입력해주세요.` } })

          return;
        } else if (JSON.stringify(usingProcedureList[i]) !== JSON.stringify(usingProcedure) && usingProcedureList[i].event_yn === "Y" && (usingProcedureList[i].goods_price === "" || usingProcedureList[i].goods_sale_price === "")) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${usingProcedureList[i].goods_times}시술 시술금액을 입력해주세요.` } })

          return;
        }
      }

      for (let j = 0; j < procedureTimeList.length; j++) {
        if (procedureTimeList[j].times_name === "" || procedureTimeList[j].times_name === undefined) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${procedureTimeList[j].goods_times}시술 ${procedureTimeList[j].view_order}회차명을 입력해주세요.` } })

          return;
        } else if (procedureTimeList[j].times_price === "" || procedureTimeList[j].times_price === undefined) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${procedureTimeList[j].goods_times}시술 ${procedureTimeList[j].view_order}회차금액을 입력해주세요.` } })

          return;
        }
      }
    }

    for (let i = 0; i < qnaList.length; i++) {
      if ((nvl(qnaList[i].qna_answer) !== "" && nvl(qnaList[i].qna_question) === "") || (nvl(qnaList[i].qna_answer) === "" && nvl(qnaList[i].qna_question) !== "")) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `질문과 답변을 입력해주세요.` } })

        return;
      }
    }
    if (nvl(content) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 기본정보를 입력해주세요." } })

      return;
    }
    else {
      if (nvl(thumbnailImage) !== "") {
        submitImage() //
      }
      else {
        setActivateMode("saveProcedure")
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시술을 등록하시겠습니까?" } })
      }
    }
  }

  // 시술 수정
  const updateProcedure = () => {

    if (nvl(defaultProcedure.goods_type) === "D" && usingProcedureList.length > 0) {
      for (let i = 0; i < usingProcedureList.length; i++) {
        if (JSON.stringify(usingProcedureList[i]) !== JSON.stringify(usingProcedure) && usingProcedureList[i].goods_name === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${usingProcedureList[i].goods_times}시술명을 입력해주세요.` } })

          return;
        } else if (JSON.stringify(usingProcedureList[i]) !== JSON.stringify(usingProcedure) && usingProcedureList[i].event_yn === "N" && usingProcedureList[i].goods_price === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${usingProcedureList[i].goods_times}시술 시술금액을 입력해주세요.` } })

          return;
        } else if (JSON.stringify(usingProcedureList[i]) !== JSON.stringify(usingProcedure) && usingProcedureList[i].event_yn === "Y" && (usingProcedureList[i].goods_price === "" || usingProcedureList[i].goods_sale_price === "")) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${usingProcedureList[i].goods_times}시술 시술금액을 입력해주세요.` } })

          return;
        }
      }

      for (let j = 0; j < procedureTimeList.length; j++) {
        if (procedureTimeList[j].times_name === "" || procedureTimeList[j].times_name === undefined) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${procedureTimeList[j].goods_times}시술${procedureTimeList[j].view_order}회차명을 입력해주세요.` } })

          return;
        } else if (procedureTimeList[j].times_price === "" || procedureTimeList[j].times_price === undefined) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `${procedureTimeList[j].goods_times}시술${procedureTimeList[j].view_order}회차금액을 입력해주세요.` } })

          return;
        }
      }
    }
    for (let i = 0; i < qnaList.length; i++) {
      if ((nvl(qnaList[i].qna_answer) !== "" && nvl(qnaList[i].qna_question) === "") || (nvl(qnaList[i].qna_answer) === "" && nvl(qnaList[i].qna_question) !== "")) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `질문과 답변을 입력해주세요.` } })

        return;
      }
    }
    if (nvl(content) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 기본정보를 입력해주세요." } })

      return;
    }
    else {
      if (nvl(thumbnailImage) !== "") {
        submitImage() //
      } else {
        setActivateMode("updateProcedure")
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시술을 수정하시겠습니까?" } })
      }
    }
  }

  // 시술 삭제
  const deleteProcedure = () => {
    setActivateMode("deleteProcedure")
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: `${defaultProcedure.goods_name}시술을 삭제하시겠습니까?` } })
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK" && activateMode === "saveProcedure") {
      let params = {
        category: defaultProcedure.category,
        goods_type: defaultProcedure.goods_type,
        insurance_type: defaultProcedure.goods_type === "I" ? defaultProcedure.insurance_type : "N",
        vat_type: defaultProcedure.goods_type === "D" ? "N" : defaultProcedure.vat_type,
        goods_name: defaultProcedure.goods_name,
        goods_description: defaultProcedure.goods_description,
        goods_price: defaultProcedure.goods_type === "D" || defaultProcedure.goods_type === "A" ? Number(defaultProcedure.goods_price) : sumGoodsAndPackSalePrice(packageTimeList).goodsPrice,
        goods_sale_price: (defaultProcedure.goods_type === "D" || defaultProcedure.goods_type === "A") && defaultProcedure.event_yn === "Y" ? Number(defaultProcedure.goods_sale_price) : defaultProcedure.goods_type === "P" ? sumGoodsAndPackSalePrice(packageTimeList).packSalePrice : null,
        goods_tag: goodsTagList ? goodsTagList.join("|") : null,
        hot_yn: "N",
        event_yn: defaultProcedure.event_yn,
        event_start_date: defaultProcedure.event_yn === "N" || defaultProcedure.event_start_date === "" ? null : defaultProcedure.event_start_date,
        event_end_date: defaultProcedure.event_yn === "N" || defaultProcedure.event_end_date === "" ? null : defaultProcedure.event_end_date,
        display_yn: "N",
        view_flag: defaultProcedure.view_flag, // 임시로 고정
        // using_movie_image: defaultProcedure.view_flag === "M" ? defaultProcedure.using_movie_image : thumbnailImage ? thumbnailImage.name : "",
        movie_url: nvl(defaultProcedure.movie_url) !== "" ? defaultProcedure.movie_url : null,
        image_url: thumbnailImage ? thumbnailImage.name : null,
        upload_file_seq: uploadFileSeq ? nvlNumber(uploadFileSeq) : null,
        recommendation_goods: selectedCategory[1] !== undefined && selectedCategory[2] === undefined ? selectedCategory[1].goods_seq : selectedCategory[1] !== undefined && selectedCategory[2] !== undefined ? selectedCategory[1].goods_seq + "|" + selectedCategory[2].goods_seq : "",
        detail_description: content,
        attention_items: defaultProcedure.attention_items,
        reg_manager_seq: managerInfo.seq,
        using_procedure: defaultProcedure.goods_type === "D" && JSON.stringify(usingProcedure) !== JSON.stringify(usingProcedureList[0]) ? usingProcedureList : "", // 일반시술상품리스트
        procedure_time: defaultProcedure.goods_type === "D" ? procedureTimeList : packageTimeList.length === 1 && packageTimeList[0].goods_seq === undefined ? "" : packageTimeList, // 회차리스트
        qna: qnaList[0].qna_answer === undefined || qnaList[0].ana_question === undefined ? "" : qnaList // Q&A리스트
      }

      console.log(params)

      const successHandler = (response) => {
        console.log(response)
        if (response.messageCode === 200) {
          setWriteOkPopup((prev) => { return { ...prev, visible: true } })
        } else {
          console.log("시술을 등록하지 못했습니다.")
        }
      }

      const failHandler = (response) => {
        console.log("에러" + response)
      }

      request.post("/api/goods", params, successHandler, failHandler, null, null)
    } else if (nvl(value) === "OK" && activateMode === "deleteProcedure") {
      let params = {
        pack_seq: defaultProcedure.goods_seq,
        goods_seq: usingProcedureList.map(el => el.goods_seq),
        modi_manager_seq: managerInfo.seq,
      }

      const successHandler = (response) => {
        if (response.messageCode === 200) {
          navigate("/boffice")
        }
      }
      const failHandler = (response) => {
        console.log("에러" + response)
      }

      request.del(`/api/goods/${params.pack_seq}/${params.modi_manager_seq}`, params, successHandler, failHandler, null, null);
    } else if (nvl(value) === "OK" && activateMode === "updateProcedure") {
      let params = {
        goods_seq: defaultProcedure.goods_seq,
        category: defaultProcedure.category,
        goods_type: defaultProcedure.goods_type,
        insurance_type: defaultProcedure.goods_type === "I" ? defaultProcedure.insurance_type : "N",
        vat_type: defaultProcedure.goods_Type === "D" ? "N" : defaultProcedure.vat_type,
        goods_name: defaultProcedure.goods_name,
        goods_description: defaultProcedure.goods_description,
        goods_price: defaultProcedure.goods_type === "D" || defaultProcedure.goods_type === "A" ? Number(defaultProcedure.goods_price) : sumGoodsAndPackSalePrice(packageTimeList).goodsPrice,
        goods_sale_price: (defaultProcedure.goods_type === "D" || defaultProcedure.goods_type === "A") && defaultProcedure.event_yn === "Y" ? Number(defaultProcedure.goods_sale_price) : defaultProcedure.goods_type === "P" ? sumGoodsAndPackSalePrice(packageTimeList).packSalePrice : null,
        goods_tag: goodsTagList ? goodsTagList.join("|") : null,
        hot_yn: "N",
        event_yn: defaultProcedure.event_yn,
        event_start_date: defaultProcedure.event_yn === "N" || defaultProcedure.event_start_date === "" ? null : defaultProcedure.event_start_date,
        event_end_date: defaultProcedure.event_yn === "N" || defaultProcedure.event_end_date === "" ? null : defaultProcedure.event_end_date,
        display_yn: defaultProcedure.display_yn,
        view_flag: defaultProcedure.view_flag, // 임시로 고정
        // using_movie_image: defaultProcedure.view_flag === "M" ? defaultProcedure.using_movie_image : thumbnailImage ? thumbnailImage.name : "",
        movie_url: nvl(defaultProcedure.movie_url) !== "" ? defaultProcedure.movie_url : null,
        image_url: thumbnailImage ? thumbnailImage.name : defaultProcedure.image_url,
        upload_file_seq: uploadFileSeq ? nvlNumber(uploadFileSeq) : nvlNumber(defaultProcedure.upload_file_seq),
        recommendation_goods: nvl(selectedCategory[1]) !== "" && nvl(selectedCategory[2]) === "" ? selectedCategory[1].goods_seq : nvl(selectedCategory[1]) !== "" && nvl(selectedCategory[2]) !== "" ? selectedCategory[1].goods_seq + "|" + selectedCategory[2].goods_seq : "",
        detail_description: content,
        attention_items: defaultProcedure.attention_items,
        modi_manager_seq: managerInfo.seq, // 일단은 고정
        using_procedure: defaultProcedure.goods_type === "D" && JSON.stringify(usingProcedure) !== JSON.stringify(usingProcedureList[0]) ? usingProcedureList : "", // 일반시술상품리스트
        procedure_time: defaultProcedure.goods_type === "D" ? procedureTimeList : packageTimeList.length === 1 && packageTimeList[0].goods_seq === undefined ? "" : packageTimeList, // 회차리스트
        qna: qnaList[0].qna_answer === undefined ? "" : qnaList // Q&A리스트
      }

      const successHandler = (response) => {
        if (response.messageCode === 200) {
          setWriteOkPopup((prev) => { return { ...prev, visible: true } })

          // 다시 호출
          getUpdateProcedure()
        } else if (response.messageCode === 409 && response.data.type === "inPackage") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `패키지 시술에 포함된 시술이 있어 내용을 변경할 수 없습니다.` } })

          // 다시 호출
          getUpdateProcedure()
        } else if (response.messageCode === 409 && response.data.type === "inPackagePrice") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `패키지 시술에 포함된 시술 <br>또는  구매 고객이 있는 시술의 가격 정보는<br>변경할 수 없습니다.` } }) // 패키지 & 오더에 포함된 시술 처리 완료 - 문구 임시 개행처리

          // 다시 호출
          getUpdateProcedure()
        } else if (response.messageCode === 409 && response.data.type === "inOrder") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: `구매 고객이 있는 시술의 내용은 변경할 수 없습니다.` } }) // 

          // 다시 호출
          getUpdateProcedure()
        }
        else {
          console.log("시술을 수정하지 못했습니다.")
        }
      }

      const failHandler = (response) => {
        console.log("에러" + response)
      }

      request.put("/api/goods", params, successHandler, failHandler, null, null)
    }
  }

  // 썸네일 이미지 등록
  const selectThumbnailImage = () => {
    imageRef.current.click();
  }

  const thumbnailImageHandler = (e) => {
    let currentId = e.target.getAttribute("id");
    let currentName = e.target.name;
    let currentValue = e.target.value;

    console.log(currentId + " | " + currentName + " | " + currentValue);
    imagePathRef.current.value = currentValue;
    setThumbnailImage(e.target.files[0]);
  }

  const submitImage = (parent_seq) => {
    console.log("섭네일은 따로 전송~ 원글이 있다면 원글의 시퀀스가 키로 와야 함");

    const formData = new FormData();

    formData.append("uploadFile", thumbnailImage)
    formData.append("file_cate", "goods")

    const successHandler = (response) => {
      console.log(response);

      if (response.messageCode === 200) {
        setActivateMode(location.state === null ? "saveProcedure" : "updateProcedure")
        setUploadFileSeq(response.data)
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: location.state === null ? "시술을 등록하시겠습니까?" : "시술을 수정하시겠습니까?" } })
      } else {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 형식의 파일이 있습니다. jpg파일만 등록 가능합니다. " } })
      }
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "파일 첨부 중 에러가 발생했습니다." } })
    }

    request.postMultipart("/api/upload/boimage", formData, successHandler, failHandler, null, null);
  }

  const newContent = (value) => {
    setContent(value)
  }

  // modified by psk 20230603 - useEffect 안 태우고 바로 호출
  // getProcedureCategory();
  // getProcedure();

  return (
    <>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
      <AlertWriteOk onClose={closeAlertWrite} popupVisible={WriteOkPopup.visible} />
      <section className="section com_lnb2">
        <div className="inner">
          <article className="lnb2_page_location">
            <Link to="/boffice" title="시술 관리"><h1 className="dep1">시술 관리</h1></Link>
            <h2 className="dep2 txt_blue">시술 등록</h2>
          </article>
          <article className="com_lnb2_right">
            <button className="com_btn m blue" onClick={() => procedureCategoryManageToggle()}><i className="svg_icon icon_set yellow">&nbsp;</i>시술 카테고리 관리</button>
          </article>
        </div>
      </section>
      <div className="com_content p_procedure_add">
        {/* //!공통 : 시술종류 선택 및 기본정보 입력*/}
        <section className="box shadow">
          <ul className="input_section">
            <li className="col01 full select_cate">
              <select className="select" name="category" value={defaultProcedure.category || ""} onChange={(e) => defaultProcedureHandler(e)} ref={inputRef}>
                <option value="">시술 카테고리 선택</option>
                {procedureCategory?.map((category) => (
                  <option key={category.common_code} value={category.common_code}>{category.code_name}</option>
                ))}
              </select>
            </li>
            <li className="col01">
              <fieldset className="com_check_wrap" >
                <input type="radio" className="radio" id="radio01" value="D" name="goods_type" onChange={(e) => defaultProcedureHandler(e)}
                  checked={defaultProcedure.goods_type === "D"} disabled={location.state !== null || defaultProcedure.category === "001017000"} />
                <label className="btn_radio" htmlFor="radio01" >&nbsp;</label >
                <label htmlFor="radio01" >일반 시술</label>
                <input type="radio" className="radio" id="radio02" value="P" name="goods_type" onChange={(e) => defaultProcedureHandler(e)}
                  checked={defaultProcedure.goods_type === "P"} disabled={location.state !== null || defaultProcedure.category === "001017000"} />
                <label className="btn_radio" htmlFor="radio02">&nbsp;</label>
                <label htmlFor="radio02">패키지 시술</label>
                {defaultProcedure.category === "001017000" && <>
                  <input type="checkbox" className="checkbox" id="radio03" value="A" name="goods_type" onChange={(e) => defaultProcedureHandler(e)}
                    checked={defaultProcedure.category === "001017000"} disabled={location.state !== null} />
                  <label className="btn_radio" htmlFor="radio03">&nbsp;</label>
                  <label htmlFor="radio03">보조상품</label>
                </>
                }
              </fieldset>
            </li>
            <li className="col02 join2">
              <input type="checkbox" className="btn_chk" id="event02" name="event_yn" checked={defaultProcedure.event_yn === "Y"} onChange={(e) => defaultProcedureHandler(e)} /><label className="com_btn chk blue m" htmlFor="event02">이벤트 시술노출</label>
              <fieldset className="many">
                <div className="datepicker_from_to">
                  <input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" value={defaultProcedure.event_start_date || ""} name="event_start_date" disabled={defaultProcedure.event_yn === "N"} onClick={() => { setViewType('from') }} readOnly />
                  {popupCalendarHTML()}
                  <span className="txt_between">~</span>
                  <input type="text" className="input datepicker" id="to" placeholder="2023. 01. 01" value={defaultProcedure.event_end_date || ""} name="event_end_date" disabled={defaultProcedure.event_yn === "N"} onClick={() => { setViewType('to') }} readOnly />
                  {popupCalendarHTML()}
                </div>
                {(defaultProcedure.goods_type === "P" || defaultProcedure.goods_type === "A") && <> <input type="checkbox" className="btn_chk" name="vat_type" id="chk01" value={defaultProcedure.vat_type} checked={defaultProcedure.vat_type === "I"} onChange={(e) => defaultProcedureHandler(e)} /><label className="com_btn chk blue m" htmlFor="chk01"> 부가세 별도</label></>}
                {defaultProcedure.goods_type === "A" && <> <input type="checkbox" className="btn_chk" name="insurance_type" id="chk02" value={defaultProcedure.insurance_type} checked={defaultProcedure.insurance_type === "I"} onChange={(e) => defaultProcedureHandler(e)} /><label className="com_btn chk blue m" htmlFor="chk02"> 보험가능상품</label></>}
              </fieldset>
            </li>
          </ul>
          <div className="procedure_info">
            <article className="left_wrap">
              <ul className="input_section">
                <li className="top_tit col01">
                  <p className="tit">메인 시술명</p>
                  <div><input type="text" value={defaultProcedure.goods_name || ""} className="input" name="goods_name" onChange={(e) => defaultProcedureHandler(e)} /* ref={inputRef} */ /></div>
                </li>
                <li className="col02 price_field">
                  <div className="top_tit">
                    <p className="tit">시술 시작금액(원)</p>
                    <div><input type="text" value={defaultProcedure.goods_type === "D" || defaultProcedure.goods_type === "A" ? defaultProcedure.goods_price || "" : sumGoodsAndPackSalePrice(packageTimeList).goodsPrice || ""} className={defaultProcedure.event_yn === "Y" ? "input price_x" : "input price"} name="goods_price" onChange={(e) => defaultProcedureHandler(e)} readOnly={defaultProcedure.goods_type === "P"} /></div>
                  </div>
                  <span className="com_txt_between">→</span>
                  <div className="top_tit">
                    <p className="tit">시술 할인금액(원)</p>
                    <div><input type="text" value={defaultProcedure.goods_type === "D" || defaultProcedure.goods_type === "A" ? defaultProcedure.goods_sale_price || "" : sumGoodsAndPackSalePrice(packageTimeList).packSalePrice || ""} className="input point" name="goods_sale_price" onChange={(e) => defaultProcedureHandler(e)} readOnly={defaultProcedure.goods_type === "P"} disabled={(defaultProcedure.goods_type === "D" || defaultProcedure.goods_type === "A") && defaultProcedure.event_yn === "N"} /></div>
                  </div>
                </li>
                <li className="full">
                  <div className="top_tit">
                    <p className="tit">시술 간단설명</p>
                    <div><input type="text" placeholder="시술 간단설명 입력 (50자)" className="input" name="goods_description" value={defaultProcedure.goods_description || ""} onChange={(e) => defaultProcedureHandler(e)} /></div>
                  </div>
                </li>
              </ul>
            </article>
            <article className="right_wrap">
              <ul className="input_section">
                <li className="top_tit full">
                  <p className="tit">태그 (5개)</p>
                  <div><input type="text" placeholder="태그명 입력" className="input" name="goods_tag" value={goodsTag} onChange={(e) => setGoodsTag(e.target.value)} onKeyDown={(e) => addTag(e)} /></div>
                  <div className="tag_field">
                    {goodsTagList.map((tag, index) => (
                      <button className="tag" key={"tag" + index} value={tag} onClick={(e) => deleteTag(e)}>{tag}</button>
                    ))}
                  </div>
                </li>
              </ul>
            </article>
          </div>
        </section>
        <div className="scroll">
          {/* //^일반시술 선택 시*/}
          {(defaultProcedure.goods_type === "D") ? usingProcedureList.map((procedure, index) => (
            <AddUsingGoodsOld
              key={"procedure" + index}
              addProcedureGoods={addProcedureGoods}
              procedure={procedure}
              index={index}
              usingProcedureList={usingProcedureList}
              addProcedureTime={addProcedureTime}
              deleteProcedureTime={deleteProcedureTime}
              procedureTime={procedureTime}
              setProcedureTime={setProcedureTime}
              procedureTimeList={procedureTimeList}
              setProcedureTimeList={setProcedureTimeList}
              usingProcedureHandler={usingProcedureHandler}
              timesHandler={timesHandler}
              deleteGoods={deleteGoods}
              location={location}
            />
          )) : null}
          {/* //^패키지시술 선택 시*/}
          {defaultProcedure.goods_type === "P" &&
            <section className="box package">
              <div className="tit_area">
                <p className="tit">패키지 시술</p>
              </div>
              <ul className="input_section only_tit">
                <li className="col01 top_tit"><p className="tit">시술 카테고리</p></li>
                <li className="col02 top_tit"><p className="tit">시술명</p></li>
                <li className="col03 top_tit"><p className="tit">시술금액(원)</p></li>
                <li className="col04 top_tit"><p className="tit">시술 할인금액(원)</p></li>
              </ul>

              {/* //*추가 시 반복 start*/}
              {packageTimeList ? packageTimeList.map((packaging, index) => (
                <ul className="input_section" key={"package" + index}>
                  <li className="col01">
                    <select className="select" key={packaging.category} value={packaging.category}
                      onChange={(e) => procedureCategoryHandler(e, `pack${nvl(packaging.goods_seq) !== "" || nvl(packaging.goods_times) === "" ? index + 1 : packaging.goods_times}`, index)}>
                      <option value="">시술 카테고리 선택</option>
                      {
                        procedureCategory == null || procedureCategory.length < 1 ? null
                          :
                          procedureCategory?.map((category) => (
                            <option key={category.common_code} value={category.common_code} >{category.code_name}</option>
                          ))
                      }
                    </select>
                  </li>
                  <li className="col02">
                    <select className="select" name="package_goods" key={packaging.goods_seq} value={packaging.goods_seq}
                      onChange={(e) => timesHandler(e, index)}>
                      <option value="">시술 선택</option>
                      {
                        filterProcedureList == null || filterProcedureList.length < 1 ? null
                          :
                          filterProcedureList[`pack${nvl(packaging.goods_seq) !== "" || nvl(packaging.goods_times) === "" ? index + 1 : packaging.goods_times}`]?.map((category) => (
                            <option key={category.goods_seq} value={category.goods_seq}>{category.goods_name}</option>
                          ))
                      }
                    </select>
                  </li>
                  <li className="col03 price_field">
                    <input type="text" className="input price_x" value={packaging.goods_price || ""} readOnly />
                    <span className="com_txt_between">→</span>
                  </li>
                  <li className="col04 price_field">
                    <input type="text" className="input point" name="pack_sale_price" value={packaging.pack_sale_price || ""} disabled={packaging.goods_seq === undefined} onChange={(e) => timesHandler(e, index)} />
                    {location.state === null && packaging.goods_times === packageTimeList.length && <button className="com_btn blue m" onClick={() => addProcedureTime(packaging.goods_times)}><i className="svg_icon icon_add yellow">&nbsp;</i>패키지추가</button>}
                    {location.state === null && packaging.goods_times !== 1 && <button className="com_btn_del" onClick={() => deleteProcedureTime(packaging.goods_times !== undefined ? packaging.goods_times : index)}>&nbsp;</button>}
                    {location.state !== null && ((packageTimeList.length === 1 && index === 0) || (packageTimeList.length > 1 && index + 1 === packageTimeList.length)) && <button className="com_btn blue m" onClick={() => addProcedureTime(packaging.goods_times)}><i className="svg_icon icon_add yellow">&nbsp;</i>패키지추가</button>}
                    {location.state !== null && index !== 0 && <button className="com_btn_del" onClick={() => deleteProcedureTime(packaging.goods_times !== undefined ? packaging.goods_times : index)}>&nbsp;</button>}
                  </li>
                </ul>
              )) : null}
              {/*/ //*추가 시 반복 end*/}
              {location.state !== null && <div className="total_price">
                <span>총 시술 금액</span>
                <span className="price">{defaultProcedure.goods_sale_price !== null ? addComma(defaultProcedure.goods_sale_price || "") : addComma(defaultProcedure.goods_price || "")}원</span>
              </div>}
              {location.state === null && <div className="total_price">
                <span>총 시술 금액</span>
                <span className="price">{defaultProcedure.goods_sale_price !== "" ? addComma(defaultProcedure.goods_sale_price || "") : addComma(defaultProcedure.goods_price || "")}원</span>
              </div>}
            </section>
          }
          {/* //!공통 : 상세 동영상 & 이미지 등록*/}
          <section className="box">
            <div className="tit_area">
              <p className="tit">상세 동영상 & 이미지 등록</p>
            </div>
            <ul className="input_section">
              <li className="col01 top_tit">
                <p className="tit">상세 동영상</p>
                <div className="input_wrap">
                  <input type="text" className="input" name="movie_url" value={defaultProcedure.movie_url || ""} onChange={(e) => defaultProcedureHandler(e)} /* disabled={defaultProcedure.view_flag !== "M"} */ />
                </div>
              </li>
              <li className="top_tit">
                <p className="tit">섬네일 이미지</p>
                <div className="input_wrap">
                  <input type="text" ref={imagePathRef} className="input" placeholder="이미지 파일 노출" />
                  <input type="file" id="uploadFile" name="files" className="input" ref={imageRef} accept="image/*" style={{ display: "none" }} onChange={thumbnailImageHandler} />
                  <button className="com_btn m blue" /* disabled={defaultProcedure.view_flag !== "I"} */ onClick={selectThumbnailImage}>파일 찾기</button>
                </div>
              </li>
            </ul>
          </section>
          {/* //!공통 : 같이하기 좋은 시술 등록*/}
          <section className="box recommend">
            <article className="tit_area">
              <p className="tit">같이하기 좋은 시술 등록</p>
            </article>
            <ul className="input_section">
              <li className="col01">
                <select className="select" key={selectedCategory[1]?.category} value={selectedCategory[1]?.category} onChange={(e) => goodProcedureCategoryHandler(e, 1)}>
                  <option value="" >시술 카테고리 선택</option>
                  {procedureCategory?.map((category) => (
                    <option key={category.common_code} value={category.common_code}>{category.code_name}</option>
                  ))}
                </select>
                <select className="select" name="recommendation_goods" key={selectedCategory[1]?.goods_seq} value={selectedCategory[1]?.goods_seq} onChange={(e) => defaultProcedureHandler(e, 1)}>
                  <option value="">시술 선택</option>
                  {filterGoodProcedureList[1]?.map((procedure) => (
                    <option key={procedure.goods_seq} value={procedure.goods_seq}>{procedure.goods_name}</option>
                  ))}
                </select>
              </li>
            </ul>
            <ul className="input_section">
              <li className="col01">
                <select className="select" key={selectedCategory[2]?.category} value={selectedCategory[2]?.category} onChange={(e) => goodProcedureCategoryHandler(e, 2)}>
                  <option value="">시술 카테고리 선택</option>
                  {procedureCategory?.map((category) => (
                    <option key={category.common_code} value={category.common_code}>{category.code_name}</option>
                  ))}
                </select>
                <select className="select" name="recommendation_goods" key={selectedCategory[2]?.goods_seq} value={selectedCategory[2]?.goods_seq} onChange={(e) => defaultProcedureHandler(e, 2)}>
                  <option value="">시술 선택</option>
                  {filterGoodProcedureList[2]?.map((procedure) => (
                    <option key={procedure.goods_seq} value={procedure.goods_seq}>{procedure.goods_name}</option>
                  ))}
                </select>
              </li>
            </ul>
          </section>
          {/* //!공통 : 시술 기본 정보*/}
          <section className="box">
            <article className="tit_area">
              <p className="tit">시술 기본 정보</p>
            </article>
            <div className="tit">
              <p>상세내용</p>
            </div>
            <div className="editor" style={{ minHeight: '100px' }}>
              <Editor
                height={100}
                data={content}
                newContent={newContent}
              />
            </div>
            <ul className="input_section">
              <li className="full top_tit">
              </li>
              <li className="full top_tit">
                <div className="tit">
                  <p>Q & A</p>
                  <button className="com_btn blue m" onClick={addQnA}><i className="svg_icon icon_add yellow">&nbsp;</i>Q&A 추가</button>
                </div>
              </li>
            </ul>
            {qnaList ? qnaList.map((qna, index) => (
              <ul className="qna_section" key={"qna" + index}>
                <li className="Q" style={{ display: "flex" }}>
                  Q <input type="text" className="txt" name="qna_question" value={qna?.qna_question || ""} onChange={(e) => qnaHandler(e, index)} />
                  {index !== 0 && <button className="com_btn_del" onClick={() => deleteQna(index)}>&nbsp;</button>}
                </li>
                <li className="A">
                  <input type="text" className="txt" name="qna_answer" value={qna?.qna_answer || ""} onChange={(e) => qnaHandler(e, index)} />
                </li>
              </ul>
            )) : null}
            <ul className="input_section">
              <li className="top_tit full">
                <p className="tit">주의사항</p>
                <div>
                  <textarea placeholder="시술 상세내용 입력 줄 바꿈 글씨 크기가 그대로 노출 됩니다.(1000자)" name="attention_items" value={defaultProcedure.attention_items || ""} onChange={(e) => defaultProcedureHandler(e)}></textarea>
                </div>
              </li>
            </ul>
          </section>
          {/* //!공통 : 하단버튼*/}
          <section className="box bottom_btn_area">
            {/* //*신규등록일경우*/}
            {location.state === null && <button className="com_btn blue l" onClick={saveProcedure}>등록</button>}
            {/* //*수정일경우*/}
            {location.state != null && <><button className="com_btn blue l" onClick={updateProcedure}>저장</button><button className="com_btn line blue l" onClick={deleteProcedure}>삭제</button></>}
          </section>
        </div>
      </div>
      <ProcedureCategoryManage procedureCategoryManageBool={procedureCategoryManageBool} procedureCategoryManageToggle={procedureCategoryManageToggle} getProcedureCategory={getProcedureCategory} procedureCategory={procedureCategory} />
    </>
  )
}

export default ProcedureAddOld




