//* 내원기록
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import moment from 'moment';

const VisitHistory = ({ onClose, personalRecord, userData, diseaseCode }) => {
  const nodeRef = useRef(null);
  const [medicineList, setMedicineList] = useState([]);

  const getUserMedicine = () => {

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setMedicineList(response.data?.dataList);
      } else {
        console.log("약 처방 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/medicine/user/prescription?user_seq=${userData.user_seq}`, null, successHandler);
  }

  useEffect(() => {
    getUserMedicine(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <>
      <section className="com_popup type_normal pop_visit_history active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container l">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">내원 기록</h1>
            </div>
            <div className="pop_body">
              {personalRecord?.map((el, i) => (
                <article className="com_list" key={i}>
                  <p className="visit_date">{moment(el[0].order_date).format("YYYY,MM,DD")}</p>
                  <table className="list">
                    <colgroup><col /><col width="20%" /><col width="20%" /><col width="20%" /><col width="20%" /></colgroup>
                    <thead>
                      <tr>
                        <th>담당의</th>
                        <th>접수</th>
                        <th>상담</th>
                        <th>결제금</th>
                        <th>실결제 금액</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{el[0].doctor}</td>
                        <td>{el[0].receptionist_manager_name}</td>
                        <td>{el[0].counselor_manager_name}</td>
                        <td>10,000</td>
                        <td>10,000</td>
                      </tr>
                    </tbody>
                  </table>
                  {/*반복 start*/}
                  {el.length > 0 ? el.map((ele) => (
                    <div className="info_wrap">
                      <ul className="dot_list">
                        <li>시술명 : {JSON.parse(ele.goods_info).goods_name}</li>
                        <li>준비 : {ele.preparation_manager_name}</li>
                        <li>시술 : {ele.treatment_manager_name}</li>
                      </ul>
                      <ul className="dot_list">
                        <li>부연설명 : {ele.today_content} </li>
                        <li>관리 : {ele.manager_name}</li>
                        <li>마무리 : {ele.finish_manager_name}</li>
                      </ul>
                    </div>
                  )): ""  }
                  {/*반복 end*/}
                  <textarea className="textarea" style={{ resize: "none" }} value={el.today_content || ""} readOnly></textarea>
                  <textarea className="textarea" style={{ resize: "none" }} readOnly
                    value={
                      diseaseCode
                        .filter(item => moment(item.reg_date).format("YYYY-MM-DD") === moment(el.order_date).format("YYYY-MM-DD"))
                        .map((item, index) => item.disease_code)
                        .join('\n')
                    } />
                  <div className="scroll">
                    <table className="list">
                      <colgroup><col /><col width="11.3%" /><col width="11.3%" /><col width="11.3%" /><col width="11.3%" /><col width="11.3%" /></colgroup>
                      <thead className="sticky top">
                        <tr>
                          <th>약명칭</th>
                          <th>용량</th>
                          <th>일투</th>
                          <th>일수</th>
                          <th>원내</th>
                          <th>원외</th>
                        </tr>
                      </thead>
                      {medicineList.length > 0 ? medicineList.filter((item) => moment(el.order_date).format("YYYY-MM-DD") === moment(item.prescription_date).format("YYYY-MM-DD")).length > 0 ? medicineList.filter((item) => moment(el.order_date).format("YYYY-MM-DD") === moment(item.prescription_date).format("YYYY-MM-DD"))[0]?.medicine_info.split("::").map((ele, i) => {
                        return (
                          <tbody key={i}>
                            <tr>
                              <td>{ele.split("|")[2]}</td>
                              <td>{ele.split("|")[3]}</td>
                              <td>{ele.split("|")[4]}</td>
                              <td>{ele.split("|")[5]}</td>
                              <td>{ele.split("|")[6] === "I" ? "O" : ""}</td>
                              <td>{ele.split("|")[6] === "O" ? "O" : ""}</td>
                            </tr>
                          </tbody>
                        )
                      })
                      :
                      <tbody key={i}>
                        <tr>
                          <td colSpan="6">데이터가 없습니다.</td>
                        </tr>
                      </tbody>
                      :
                      <tr>
                        <td colSpan="6">데이터가 없습니다.</td>
                      </tr>}
                    </table>
                  </div>
                </article>
              ))}
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn l blue" onClick={() => { onClose() }}>확인</button>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default VisitHistory