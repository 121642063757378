//* 간단 시술 등록 231115 추가
import CustomAlert from "atoms/common/CustomAlert";
import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import { addComma, nvl, nvlNumber } from "utils/Common.utils.js";
import request from "utils/Request.utils.js";


const SimpleProcedureAdd = ({ setShowSimpleProcedureAdd, procedureCategory, getAllProcedure, managerInfo }) => {


  const [selectedCategory, setSelectedCategory] = useState("");
  const inputGoodName = useRef();
  const inputGoodPrice = useRef();
  const [vatType, setVatType] = useState("Y"); //부가세 포함 Y,  별도 N


  // 시술 카테고리 관리 팝업창 닫기
  const popupClose = () => {
    setShowSimpleProcedureAdd(false);
  }


  const changeProcedureCategory = (e) => {
    const { name, value } = e.target;
    setSelectedCategory(value);
  }


  const changeVatType = (e) => {
    const { checked } = e.target;
    if (checked === true) {
      setVatType('N');
    } else {
      setVatType('Y');
    }

  }


  const registerSimpleProcedure = () => {

    if (nvl(selectedCategory) === "") {
      alert('시술 카테고리를 선택해주세요.');
      return;
    }
    if (nvl(inputGoodName.current.value) === "") {
      alert('시술명을 입력해주세요.');
      return;
    }
    if (nvl(inputGoodPrice.current.value) === "") {
      alert('시술금액을 입력해주세요.');
      return;
    }
    if (nvlNumber(inputGoodPrice.current.value) === 0) {
      alert('시술금액을 숫자만 입력해주세요');
      return;
    }


    let params = {
      category: selectedCategory,
      goods_name: inputGoodName.current.value,
      goods_price: inputGoodPrice.current.value,
      vat_type: vatType,
      reg_manager_seq:managerInfo.seq
    }


    const successHandler = (response) => {
      console.log(response)
      if (response.messageCode === 200) {
        getAllProcedure();
        popupClose();
      } else {
        console.log("시술을 등록하지 못했습니다.");
        alert("시술을 등록하지 못했습니다.");

      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
      alert("시술을 등록하지 못했습니다.");
    }

    request.post("/api/goods/simple", params, successHandler, failHandler, null, null)


  }



  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal active">{/*클래스 active추가시 노출*/}
        <Draggable>
          <div className="pop_container l">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { popupClose() }}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">간단 시술 등록</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">
              <div className="com_write">
                <table className="write">
                  <colgroup><col width="130px" /><col /></colgroup>
                  <tbody>
                    <tr>
                      <th>시술 카테고리</th>
                      <td>
                        <select className="select" name="goods_name" onChange={(e) => changeProcedureCategory(e)}>
                          <option>시술 카테고리 선택</option>
                          {procedureCategory?.map((category) => (
                            <option key={category.common_code} value={category.common_code}>{category.code_name}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>시술명</th>
                      <td><input type="text" className="input" placeholder="시술 간단설명 입력" ref={inputGoodName} /></td>
                    </tr>
                    <tr>
                      <th>시술 금액</th>
                      <td><input type="number" className="input" ref={inputGoodPrice} /></td>
                    </tr>
                    <tr>
                      <th>부가세</th>
                      <td>
                        <fieldset className="com_radio_wrap">
                          <input type="checkbox" className="checkbox" id="chk1" name="payType" onChange={(e) => changeVatType(e)} />
                          <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                          <label htmlFor="chk1">부가세 별도</label>
                        </fieldset>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue" onClick={() => registerSimpleProcedure()}>등록</button>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default SimpleProcedureAdd