import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentDetail from "./_PaymentDetail.js";//결제팝업
import Refund from "./_Refund.js";//환불팝업
import CashReceipt from "./_CashReceipt.js";//현금영수증 팝업
import PayManage from "./_PayManage.js" //결제 방법 변경 팝업
import { nvl, nvlNumber, convertGender, vatCalculator, getTodayDate, convertJuminToAge, addComma } from "utils/Common.utils.js";
import { decrypt } from "../../utils/aes256.manager";
import moment from "moment";
import request from "../../utils/Request.utils";
import SnsSend from "components/common/_SnsSend.js";
import SnsSendList from "components/common/_SnsSendList.js";
import MemoCard from "../../components/common/_CustomMemo"
import ConsentList from "components/chart/_ConsentList.js";
import ReservationAdd from '../../components/common/_ReservationAdd';
import CouponManage from '../../components/common/_CouponManage';
import PointManage from "components/common/_PointManage.js";
import CustomAlert from "atoms/common/CustomAlert.js";
import Receivable from "./_Receivable.js"//미수금 결제
import Detail from "./_Detail.js"//결제 상세
import Document from "./_Document.js"//서류발급
import LoadingBar from '../../utils/LoadingBar';
import { v4 as uuidv4 } from 'uuid';

const Pay = ({ lnbSearchOptions, setLnbSearchOptions, loadingBarActive }) => {
  const stateCustomer = useSelector(state => state.Customer);
  const paymentInfo = useSelector(state => state.PaymentInfo);
  const dispatch = useDispatch();
  const uploadFileRef = useRef();
  const [userData, setUserData] = useState({});
  const [imgBase64, setImgBase64] = useState();
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [todayOrders, setTodayOrders] = useState([]); // 'from': 시술의 출처, S 시술 검색 컴포넌트   R 잔여 시술 컴포넌트

  const [popup, setPopup] = useState({});
  const [taxGoods, setTaxGoods] = useState();
  const [taxFreeGoods, setTaxFreeGoods] = useState();
  const [treatmentList, setTreatmentList] = useState([]);
  const [paymentList, setPaymentList] = useState([]); //결제내역(목록)
  const [refundList, setRefundtList] = useState([]);  //환불내역(목록)
  const [paymentListSum, setPaymentListSum] = useState({ paySum: 0, unPaySum: 0 }); //합계 금액 정보
  const [refundSum, setRefundSum] = useState(0); //환불 합계 정보

  const [orderInfo, setOrderInfo] = useState({}); // 오더 시퀀스 정보 

  const [payTreatment, setPayTreatment] = useState({});
  const [buttons, setButtons] = useState([
    { text: "결제내역", value: 0, active: true },
    { text: "환불내역", value: 1, active: false },
    { text: "미수금", value: 2, active: false },
    { text: "보험 청구내역", value: 3, active: false },
  ]);

  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => { },
    id: '',
  });

  const [payStatus, setPayStatus] = useState('014004000'); //결제 상태(목록 조회용) 
  const [paySeq, setPaySeq] = useState(0); //결제 시퀀스

  let customerInfo = stateCustomer;

  const prescriptionInit = {
    prescription_use: "", // 처방전 용도
    prescription_date: "", // 교부일
    period_amount: "", // 기간
    medical_history_yn: "N", // 주증상 및 병력 제외
    resident_no_yn: "N", // 처방전 주민번호 출력
    including_injections_yn: "N", // 처방전 원내 주사약 포함
    disease_code: "" // 질병코드 추가
  }
  const [prescriptionInfo, setPrescriptionInfo] = useState(prescriptionInit) // 처방전 데이터

  const [medicineSetList, setMedicineSetList] = useState([]) // 세트처방 리스트

  const closeCustomAlert = (() => {
    setAlertPop((prev) => { return { ...prev, visible: false } })
  });

  //팝업창 열기
  const onPopupClick = ((e) => {
    if (nvlNumber(stateCustomer.user_seq) > 0) {
      //결제내역 선택 확인
      if(paySeq > 0){
        setPopup((prev) => { return { ...prev, [e.target.id]: true } })
      } else {
        setAlertPop((prev) => { 
          return { ...prev, visible: true, alertMessage: '결제 내역을 선택하세요.', alertType: 'alert' } 
        });
      }

    } else {
      setAlertPop((prev) => { 
        return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } 
      });
    }
  });

  const paymentPopupClick = ((e, order_status) => {
    if (nvlNumber(stateCustomer.user_seq) === 0) setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
    else if (nvl(order_status) === "" || order_status < "013005000") setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '오더확인 후 결제를 진행해주세요.', alertType: 'alert' } })
    else setPopup((prev) => { return { ...prev, [e.target.id]: true } })
  });

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  }

  // 단일 고객 조회
  const getSelectedCustomerInfo = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const user = response.data.dataList[0];

        setUserData({
          user_seq: user.user_seq,
          chart_code: user.chart_code,
          user_name: user.user_name,
          user_gender: user.user_gender,
          user_level: user.user_level,
          user_level_color: user.user_level_name?.split("|")[1],
          jumin_1: decrypt(user.user_jumin).substring(0, 6),
          jumin_2: decrypt(user.user_jumin).substring(6, 13),
          mobile_1: user.user_mobile.split("-")[0],
          mobile_2: user.user_mobile.split("-")[1],
          mobile_3: user.user_mobile.split("-")[2],
          user_photo: nvlNumber(user.up_file_seq),
          user_photo_path: user.file_path,
          user_reser_seq: user.user_reser_seq,
          user_coupon_cnt: 0,
          order_count: user.order_count
        });
      } else {
        console.log("고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/user/${stateCustomer.user_seq}`, null, successHandler);
  }

  const changeUserHandler = (userSeq) => { // 선택된 값
    if (nvlNumber(userSeq) !== "") {
      if (userSeq > 0) {
        request.get(`/api/user/${userSeq}`, null, (response) => {
          if (response.messageCode === 200) {
            let userInfo = response.data.dataList[0];

            dispatch({ type: "setCustomer", payload: { fieldName: "user_seq", data: userSeq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_name", data: userInfo.user_name } });
            dispatch({ type: "setCustomer", payload: { fieldName: "chart_code", data: userInfo.chart_code } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_gender", data: userInfo.user_gender } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_dob", data: userInfo.user_dob } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_age", data: nvl(userInfo.user_jumin) !== "" ? convertJuminToAge((decrypt(userInfo.user_jumin).substring(0, 6) + "-" + decrypt(userInfo.user_jumin).substring(6, 13))) : " - " } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level", data: userInfo.user_level } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level_color", data: userInfo.user_level_color } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_status", data: userInfo.user_status } });
            dispatch({ type: "setCustomer", payload: { fieldName: "interest_area", data: userInfo.interest_area } });
            dispatch({ type: "setCustomer", payload: { fieldName: "admissive_channel", data: userInfo.admissive_channel } });
            dispatch({ type: "setCustomer", payload: { fieldName: "main_tc_seq", data: userInfo.main_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "sub_tc_seq", data: userInfo.sub_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo", data: nvlNumber(userInfo.up_file_seq) } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo_path", data: userInfo.file_path } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_reser_seq", data: userInfo.user_reser_seq } });
          } else console.log("고객 정보를 가져오지 못했습니다.")
        });
      }
    }
  }


  // 첨부파일 등록
  const selectUploadFile = () => {
    if (nvlNumber(stateCustomer.user_seq) === 0) setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
    else uploadFileRef.current.click();
  }

  const uploadFileHandler = (e) => {
    if (e.target.files[0]) {

      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onloadend = () => {
        const base64 = reader.result;
        if (base64) setImgBase64(base64.toString());
      }
    }

    const formData = new FormData();

    formData.append("uploadFile", e.target.files[0])
    formData.append("file_cate", "userPhoto")
    formData.append("user_seq", nvlNumber(userData?.user_seq))
    formData.append("manager_seq", nvlNumber(managerInfo.seq))

    const successHandler = (response) => {

      if (response.messageCode === 200) {

      } else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 형식의 파일이 있습니다." } })
    }

    const failHandler = (result) => {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: "파일 첨부중 에러가 발생했습니다." } })
    }

    uploadFileRef.current.value = "";

    request.postMultipart("/api/upload/dash/user", formData, successHandler, failHandler);
  }

  useEffect(() => {
    if (nvlNumber(stateCustomer.user_seq) > 0) getSelectedCustomerInfo()

    return () => { // stateCustomer.user_seq가 바뀌기 전 초기화

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomer.user_seq]);


  const getTodayOrderInfo = (userSeq) => {
    const successHandler = (response) => {
      const tempArr = [];

      if (response.messageCode === 200) {
        if (nvl(response.data) !== "" && response.data?.totalCnt > 0) {
          // console.log("이미 저장되어 있던 당일오더", response.data?.dataList) // response.data?.dataList

          let packInfo = {}
          let goodsInfo = {}
          let totalPrice = 0
          let goodsPrice = 0

          setOrderInfo({
            order_seq: response?.data?.dataList[0]?.order_seq,
            order_status: response?.data?.dataList[0]?.order_status,
            order_date: moment(response?.data?.dataList[0]?.order_date).format("YYYY-MMDD")
          })

          for (const orderInfo of response.data?.dataList) {
            if (orderInfo?.chart_info?.length > 0) {
              for (const chartInfo of orderInfo?.chart_info) {
                // console.log("섭 차트 정보", chartInfo.sub_tc_seq)
                // console.log("상품 정보", chartInfo.goods_info)

                if (chartInfo.goods_info !== "") {
                  packInfo = chartInfo.pack_info
                  goodsInfo = chartInfo.goods_info
                  goodsPrice = vatCalculator(packInfo.goods_type, packInfo.vat_type, goodsInfo.pack_sale_price, goodsInfo.goods_sale_price, goodsInfo.vat_type, goodsInfo.goods_price)
                  totalPrice += goodsPrice

                  tempArr.push({
                    sub_tc_seq: chartInfo.sub_tc_seq,
                    pack_seq: packInfo.goods_seq,
                    goods_seq: goodsInfo.goods_seq,
                    goods_name: goodsInfo.goods_name,
                    goods_price: goodsPrice,
                    chart_type: 'B', // 구매(B), 부분양도(T), 무료시술(F), 결제(P), 예약(R)
                    current_times: 0, // +, - 버튼에 의해 조절 될 수 있는 회차 >> used_times <= current_times <= total_times
                    used_times: 0, // 시술검색에서 넘어온 경우 0회차가 default, 잔여시술에서 넘어온 경우 사용한 회차가 default
                    total_times: chartInfo.total_times,
                    vat_type: goodsInfo.vat_type,
                    insurance_type: goodsInfo.insurance_type,
                    reg_date: new Intl.DateTimeFormat('kr').format(new Date()).replaceAll('.', ','), // 시술상품 구매 일
                    comment: goodsInfo.comment,
                    from: 'S',
                  });
                }

              }
            }
          }

          setTodayOrders(tempArr);

          getTreatmentPaytInfo(response?.data?.dataList[0]?.order_seq);

        }else{
          setOrderInfo({})
        }
      } else {
        console.log("저장된 정보가 없나?")
      }
    }

    request.get(`/api/charts/order/dashboard2?chart_type=chart&user_seq=${userSeq}&order_date=${getTodayDate('hyphen')}`, null, successHandler);
  }

  //공통버튼(결제내역, 환불내역, 미수금..) 클릭
  const clickHandler = (value) => {
    let newSubButton = [];

    for (const button of buttons) {
      if (button.value === value) button.active = true;
      else button.active = false;

      newSubButton = [...newSubButton, button]
    }

    if(value === 0){ //결제완료
      setPayStatus('014004000');
      getPaymentsList(stateCustomer.user_seq);
    } else if(value === 1) {  //환불
      getRefundList(stateCustomer.user_seq);
    } else if(value === 2) {  //미결제
      setPayStatus('014001000');
      getPaymentsList(stateCustomer.user_seq);
    } else {
      setPayStatus(null);
    }

    setButtons(newSubButton);
  }

  useEffect(() => {
    if (nvlNumber(stateCustomer.user_seq) > 0) {
      setTodayOrders([])

      getTodayOrderInfo(stateCustomer.user_seq);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomer.user_seq, paymentInfo])


  //결제 대상 시술 목록
  const getTreatmentPaytInfo = (order_seq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setTaxGoods(response.data.tax_goods)
        setTaxFreeGoods(response.data.tax_free_goods)
        setPayTreatment(response.data)
        setTreatmentList([...response.data.tax_goods, ...response.data.tax_free_goods]);
      } else {
        console.log("결제할 시술 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/payment/treatment?order_seq=${order_seq}`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    if (nvl(orderInfo?.order_seq) !== "") {
      getTreatmentPaytInfo(orderInfo?.order_seq);
      getPaymentsList(stateCustomer.user_seq);
    }else{
      setTaxGoods([])
      setTaxFreeGoods([])
      setPayTreatment([])
      setTreatmentList([]);
    }
  }, [orderInfo?.order_seq, stateCustomer.user_seq])

  //결제내역(전체), 환불내역
  useEffect(() => {
    if(stateCustomer.user_seq > 0){
      getPaymentsList(stateCustomer.user_seq);
      getRefundList(stateCustomer.user_seq);
    }
  }, [stateCustomer.user_seq])

  //결제내역 조회(전체)______________________________
  const getPaymentsList = (user_seq) => {
    if(nvlNumber(user_seq) > 0) {
      const successHandler = (response) => {
        if (response.messageCode === 200) {
          console.log('list', response);
          setPaymentList(response?.data?.dataList);
        } else {
          console.log("결제내역 조회 실패")
        }
      }

      const failHandler = (response) => {
        console.log('에러' + response);
      }

      request.get(`/api/payment/list/${user_seq}`, null, successHandler, failHandler, null, null);
    } else {
      console.log('선택 환자 없음');
    }
  }

  //환불 내역 조회______________________________
  const getRefundList = (user_seq) => {
    if(nvlNumber(user_seq) > 0) {
      const successHandler = (response) => {
        if (response.messageCode === 200) {
          console.log('list', response);
          setRefundtList(response?.data?.dataList);
        } else {
          console.log("환불내역 조회 실패")
        }
      }

      const failHandler = (response) => {
        console.log('에러' + response);
      }

      request.get(`/api/payment/refund/list/${user_seq}`, null, successHandler, failHandler, null, null);
    } else {
      console.log('선택 환자 없음');
    }
  }



  //결제내역 합계 계산
  useEffect(() => {
    const listSum = paymentList.reduce(
      (sum, item, idx) => {
        const payment_sum = parseInt(nvlNumber(item.payment_sum));
        const unpaid_price = parseInt(nvlNumber(item.unpaid_price));
        return {
          paySum: sum.paySum + payment_sum,
          unPaySum: sum.unPaySum + unpaid_price,
        };
      },
      { paySum: 0, unPaySum: 0 } // 
    );
    setPaymentListSum(listSum);
  },[paymentList]);

  //환불내역 합계 계산
  useEffect(() => {
    const listSum = refundList.reduce(
      (sum, item) => {
        return sum + item.refund_amt;
      }, 0 );
    setRefundSum(listSum);
  },[refundList]);

  return (
    <>
      <section className="com_content p_pay">
        <div className="top_area">
          {/***** 고객카드 **************************************************/}
          <section className="com_sec pd20">
            <article className="com_user_box">
              <div className={`color ${nvl(userData?.order_count) === "" || userData?.order_count <= 1 ? "first" : "second"}`}>&nbsp;</div>
              <div className="pic" onClick={selectUploadFile} style={{ cursor: "pointer" }}>
                <label htmlFor="user_photo" style={{ cursor: "pointer" }} >
                  {
                    nvl(userData?.user_photo_path) === '' ?
                      nvl(imgBase64) !== '' && <img key={imgBase64} src={imgBase64} alt="미리보기" style={{ width: "74px" }} />
                      :
                      nvl(imgBase64) !== '' ?
                        <img key={imgBase64} src={imgBase64} alt="미리보기" style={{ width: "74px" }} />
                        :
                        nvl(userData?.user_photo_path) !== '' &&
                        <img src={`${process.env.REACT_APP_API_URL}/api/upload/viewer/image/photo/${userData?.user_photo_path.split("/")[userData?.user_photo_path.split("/").length - 2]}_${userData?.user_photo_path.split("/")[userData?.user_photo_path.split("/").length - 1]}`}
                          alt="" style={{ width: "74px" }} />
                  }
                  <input type="file" id="uploadFile" name="files" className="input" ref={uploadFileRef} style={{ display: "none" }} onChange={uploadFileHandler} />
                </label>
              </div>
              <div className="btns_wrap">
                <button type="button" className="btn" id="send" onClick={() => setLnbSearchOptions(prev => { return { ...prev, send: true } })}><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
                <button type="button" className="btn" id="sent" onClick={() => setLnbSearchOptions(prev => { return { ...prev, sent: true, userData: userData } })}><i className="svg_icon icon_list yellow">&nbsp;</i></button>
              </div>
              <div className="num">{customerInfo.chart_code}</div>
              <div className="age_phone" style={{ background: `${userData.user_level_color}` }}>
                <h1 className="age">{nvl(stateCustomer.user_name) === "" ? "" : `${customerInfo.user_name}(${convertGender(customerInfo.user_gender)}/${customerInfo.user_age})`}</h1>
                <h2 className="phone">{customerInfo.mobile}</h2>
              </div>
              <button id='memo' type="button" className="btn_memo com_btn m icon_vertical skyblue_bg txt_blue" onClick={onPopupClick}>
                <i className="svg_icon icon_write blue">&nbsp;</i>메모
              </button>
            </article>
            <article className="com_user_box_btns">
              <div className="btns_wrap">
                <button id='consent' type="button" className="com_btn m line blue" onClick={onPopupClick}>동의서</button>
                <button id='point' type="button" className="com_btn m line blue" onClick={onPopupClick}>포인트 정보</button>
                <button id='coupon' type="button" className="com_btn m line blue" onClick={onPopupClick}>쿠폰 정보</button>
              </div>
              <button id='reservation' type="button" className="com_btn m red" onClick={onPopupClick}>예약하기</button>
            </article>
          </section>
          
          {/***** 오더 **************************************************/}
          <section className="com_sec pd20 order">
            <div className="com_list">
              <p className="list_tit">{`${moment(new Date()).format("YYYY,MM,DD")}`} 오더</p>
              <div className="scroll">
                <table className="list">
                  <colgroup><col /><col width="16%" /><col width="14%" /><col width="27%" /></colgroup>
                  <thead className="sticky top">
                    <tr>
                      <th>시술명</th>
                      <th>금액</th>
                      <th>급여</th>
                      <th>부연설명</th>
                    </tr>
                  </thead>
                  <tbody>
                    {treatmentList.length > 0 ? treatmentList.map((el, i) => (
                      <tr key={i}>
                        <td className="tit">{el.goods_name}</td>
                        <td className="num">{new Intl.NumberFormat().format(el.goods_price)}</td>
                        <td>{el.insurance_type === "N" ? "비급여" : "급여"}</td>
                        <td className="alignL">{el.comment}</td>
                      </tr>
                    )) :
                      <tr>
                        <td colSpan="4">데이터가 없습니다.</td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>

            {/**>> 결제 대상 <<**/}
            <ul className="com_input_section payment">
              <li className="tit_field">총 금액</li>
              <li className="input_field"><span className="only_txt">{`${nvl(payTreatment?.payment_sum) !== "" ? new Intl.NumberFormat().format(payTreatment?.payment_sum) : 0}원`}</span></li>
              <li className="tit_field">- 쿠폰할인 금액</li>
              <li className="input_field">
                <input type="text" className="input" value={`${nvl(payTreatment?.coupon_amt) !== "" ? new Intl.NumberFormat().format(payTreatment?.coupon_amt) : 0}원`} />
              </li>
              <li className="tit_field">-포인트 결제 금액</li>
              <li className="input_field">
                <input type="text" className="input" value={`${nvl(payTreatment?.point_amt) !== "" ? new Intl.NumberFormat().format(payTreatment?.point_amt) : 0}원`} />
              </li>
              <li className="tit_field total">최종 결제 금액</li>
              <li className="input_field total">
                <span className="only_txt txt_blue">{`${nvlNumber(payTreatment?.payment_sum - (payTreatment?.coupon_amt) - (payTreatment?.point_amt)) !== 0 ? new Intl.NumberFormat().format(payTreatment?.payment_sum - (payTreatment?.coupon_amt) - (payTreatment?.point_amt)) : 0}원`}</span>
              </li>
            </ul>
            <div className="btn_area">
              <button className="com_btn blue m" id="paymentDetail" onClick={(e) => paymentPopupClick(e, orderInfo?.order_status)}>결제하기</button>
            </div>
          </section>
        </div>

        {/***** 결제 기록 **************************************************/}
        <div className="com_list box">
          {/**>> 공통 버튼 <<**/}
          <div className="top_area">
            <div className="btn_field">
              {
                buttons.map((item, i) => (
                  <button key={"button_" + uuidv4()} className={item.value === 2 ? "com_btn lightpink m" : item.active ? "com_btn blue m " : "com_btn lightgray m"} onClick={() => clickHandler(i)}>{item.value === 2 ? `${item.text}: ${addComma(paymentListSum.unPaySum)}` : item.text}</button>
                ))
              }
            </div>
            {payStatus === '014004000' &&
              <div className="btn_field">
                <button className="com_btn blue line m" id="document" onClick={onPopupClick}><i className="svg_icon icon_print yellow">&nbsp;</i>서류 발급</button>
                {/*
                <button className="com_btn blue line m" id="pay" onClick={onPopupClick}><i className="svg_icon icon_refresh yellow">&nbsp;</i>결제 방법 변경</button>
                */}
                <button className="com_btn blue line m" id="refund" onClick={onPopupClick}><i className="svg_icon icon_money">&nbsp;</i>환불하기</button>
              </div>
            }
          </div>

          {/**>> 결제 내역 <<**/}
          {(buttons[0].active || buttons[2].active) &&
            <div className="scroll">
              <table className="list">
                <colgroup><col width="70px" /><col width="8%" /><col width="25%" /><col width="6%" /><col width="6%" /><col width="6%" /><col width="6%" /><col width="5%" /><col width="5%" /><col width="5%" /><col width="5%" /><col width="5%" /><col width="5%" /><col width="5%" /></colgroup>
                <thead className="sticky top">
                  <tr>
                    <th>선택</th>
                    <th>결제일</th>
                    <th>시술명</th>
                    <th>수납자</th>
                    <th>현금 영수증</th>
                    <th>본인 부담금</th>
                    <th>과세</th>
                    <th>비과세</th>
                    <th>합계</th>
                    <th>쿠폰할인</th>
                    <th>포인트사용</th>
                    <th>실 결제액</th>
                    <th>카드결제</th>
                    <th>현금결제</th>
                    <th>미수액</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentList?.length > 0 ? paymentList?.filter((item) => item.pay_status === payStatus).map((payment, index) => {
                    return(
                      <React.Fragment key={"payList" + index}>
                        <tr>
                          <td>
                            <fieldset className="com_check_wrap">
                              <input type="radio" name="pay_idx" className="checkbox" id={"chk" + index} key={"chk" + index} onClick={()=>setPaySeq(payment?.pay_seq)} />
                              <label className="btn_checkbox" htmlFor={"chk" + index}>&nbsp;</label>
                            </fieldset>
                          </td>
                          <td>{payment?.reg_date?.slice(0, 10)}</td>
                          <td id="detail" onClick={onPopupClick} style={{ cursor: "pointer" }}>
                            {payment?.treatment_name}
                          </td>
                          <td>{payment?.reg_manager_name}</td>
                          <td><button className="com_btn txt" id="cashReceipt" onClick={onPopupClick}>발행완료</button></td>
                          <td>0</td>
                          <td>{addComma(payment?.taxation_total)}</td>
                          <td>{addComma(payment?.tax_free_total)}</td>
                          {/*합계*/}
                          <td><span className="txt_blue">{addComma(payment?.goods_sum)}</span></td>
                          <td>0</td>
                          <td>0</td>
                          {/*실결제금액*/}
                          <td><span className="txt_blue">{addComma(payment?.payment_sum)}</span></td>
                          <td>{addComma(payment?.payment_card)}</td>
                          <td>{addComma(payment?.payment_cash)}</td>
                          <td id="receivable" onClick={onPopupClick} style={{ cursor: "pointer" }}>
                            {addComma(payment?.unpaid_price)}
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  }) : <tr><td colspan="15">내역이 없습니다.</td></tr>
                }
                </tbody>
              </table>
            </div>
          }

          {/**>> 환불 내역 <<**/}
          {buttons[1].active &&
            <div className="scroll">
              <table className="list">
                <colgroup><col width="10%" /><col width="20%" /><col width="10%" /><col width="10%" /><col width="10%" /><col width="10%" /><col width="10%" /><col width="10%" /><col width="10%" /></colgroup>
                <thead className="sticky top">
                  <tr>
                    <th>환불일</th>
                    <th>환불 목록</th>
                    <th>수납자</th>
                    <th>과세</th>
                    <th>비과세</th>
                    <th>결제액</th>
                    <th>위약금</th>
                    <th>환불 방법</th>
                    <th>환불액</th>
                  </tr>
                </thead>
                <tbody>
                  {refundList?.length > 0 ? refundList?.map((refund, index) => {
                    return(
                      <React.Fragment key={"refList" + index}>

                        <tr>
                          <td>{refund?.reg_date}</td>
                          <td id="detail" onClick={onPopupClick} style={{ cursor: "pointer" }}>시술명1</td>
                          <td>홍길동</td>
                          <td>{addComma(refund?.taxation_total)}</td>
                          <td>{addComma(refund?.tax_free_total)}</td>
                          <td><span className="txt_blue">{addComma(refund?.payment_sum)}</span></td>
                          <td><span className="txt_blue">{addComma(refund?.penalty_price)}</span></td>
                          <td>
                            <ul>
                              <li>카드: {addComma(refund?.refund_card)}</li>
                              <li>현금: {addComma(refund?.refund_cash)}</li>
                              <li>통장: {addComma(refund?.refund_bank)}</li>
                            </ul>
                          </td>
                          <td>
                            <span className="txt_red" id="receivable" onClick={onPopupClick} style={{ cursor: "pointer" }}>
                              {addComma(refund?.refund_amt)}
                            </span>
                          </td>
                        </tr>
                        </React.Fragment>
                    )
                  }) : <tr><td colspan="9">내역이 없습니다.</td></tr>
                }
                </tbody>
              </table>
            </div>
          }
          
          {/**>> 보험금 청구 내역 <<**/}
          {buttons[3].active &&
            <div className="scroll">
              <table className="list">
                <colgroup><col width="8%" /><col width="8%" /><col /><col width="6%" /><col width="7%" /><col width="7%" /><col width="7%" /><col width="7%" /><col width="7%" /><col width="7%" /><col width="7%" /><col width="7%" /><col width="7%" /></colgroup>
                <thead className="sticky top">
                  <tr>
                    <th>보험 청구일</th>
                    <th>결제일</th>
                    <th>시술명</th>
                    <th>수납자</th>
                    <th>현금 영수증</th>
                    <th>본인 부담금</th>
                    <th>과세</th>
                    <th>부과세</th>
                    <th>결제액</th>
                    <th>쿠폰할인</th>
                    <th>포인트사용</th>
                    <th>실 결제액</th>
                    <th>결제 방법</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td colspan="15">내역이 없습니다.</td></tr>
                  {/*
                  <tr>
                    <td>청구완료<br />2022-06-25</td>
                    <td>2022-06-25<br />11:30</td>
                    <td id="detail" onClick={onPopupClick} style={{ cursor: "pointer" }}>시술명1</td>
                    <td>홍길동</td>
                    <td><button className="com_btn txt" id="cashReceipt" onClick={onPopupClick}>발행완료</button></td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td><span className="txt_blue">100,000</span></td>
                    <td>0</td>
                    <td>0</td>
                    <td><span className="txt_blue">100,000</span></td>
                    <td>
                      <ul>
                        <li>카드: 10,000</li>
                        <li>통장: 14,000</li>
                        <li>현금: 42,000</li>
                      </ul>
                    </td>
                  </tr>
                  */}

                </tbody>
              </table>
            </div>}
        </div>

        {/**>> 합계 금액 <<**/}
        <div className="com_total_price">
          {buttons[0].active &&
            <p className="item">
              <span className="tit">실결제 총액</span>
              <span className="txt_blue">{addComma(paymentListSum.paySum)}</span>
              <span className="txt">(포인트 + 추가 결제금)</span>
            </p>
          }

          {buttons[1].active &&
            <p className="item">
              <span className="tit">총 환불액</span>
              <span className="txt_red">{addComma(refundSum)}</span>
            </p>
          }

          {buttons[2].active &&
            <p className="item">
              <span className="tit">미수 총액</span>
              <span className="txt_red">{addComma(paymentListSum.unPaySum)}</span>
            </p>
          }
        </div>
      </section>
      {lnbSearchOptions.send && <SnsSend setLnbSearchOptions={setLnbSearchOptions} />}
      {lnbSearchOptions.sent && <SnsSendList setLnbSearchOptions={setLnbSearchOptions} lnbSearchOptions={lnbSearchOptions} />}
      {popup.memo && <MemoCard onClose={() => { onClosePop('memo') }} />}
      {popup.consent && <ConsentList userData={userData} onClose={() => { onClosePop('consent') }} />}
      {popup.point && <PointManage onClose={() => { onClosePop('point') }} />}
      {popup.coupon && <CouponManage onClose={() => { onClosePop('coupon') }} userData={userData} setUserData={setUserData} />}
      {popup.reservation && <ReservationAdd onClose={() => { onClosePop('reservation') }} userData={userData} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler}/>}
      {/*결제 팝업*/}
      {popup.paymentDetail && <PaymentDetail onClose={() => { onClosePop('paymentDetail') }} alertPop={alertPop} setAlertPop={setAlertPop} userData={userData} payTreatment={payTreatment} taxGoods={taxGoods} taxFreeGoods={taxFreeGoods} prescriptionInfo={prescriptionInfo} setPrescriptionInfo={setPrescriptionInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList} userInfo={userData} setUserInfo={setUserData}/>}
      
      {/*환불 팝업*/}
      {popup.refund && <Refund onClose={() => { onClosePop('refund') }} setAlertPop={setAlertPop} payTreatment={payTreatment} taxGoods={taxGoods} taxFreeGoods={taxFreeGoods} paySeq={paySeq} clickHandler={clickHandler} />}

      {popup.cashReceipt && <CashReceipt onClose={() => { onClosePop('cashReceipt') }} userData={userData} />}
      {popup.pay && <PayManage onClose={() => { onClosePop('pay') }} popup={popup} setPopup={setPopup} onPopupClick={onPopupClick} onClosePop={onClosePop} userData={userData} setAlertPop={setAlertPop} payTreatment={payTreatment} taxGoods={taxGoods} taxFreeGoods={taxFreeGoods} />}
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
      {popup.receivable && <Receivable onClose={() => { onClosePop('receivable') }} popup={popup} todayOrders={todayOrders} setAlertPop={setAlertPop} setPopup={setPopup} userData={userData} />}{/*0517 미수금 결제 팝업*/}
      {popup.detail && <Detail onClose={() => { onClosePop('detail') }} />}{/*0517 결제상세 팝업*/}
      {popup.document && <Document onClose={() => { onClosePop('document') }} btnValue={0} prescriptionInfo={prescriptionInfo} setPrescriptionInfo={setPrescriptionInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList} userInfo={userData} setUserInfo={setUserData}/>}{/*0517 서류발급 팝업*/}
      {loadingBarActive && <LoadingBar type={"spin"} color={"#000000"} />}
      {/*<MediConfirm/>
      <MediNote/>
      <MediCertificate/>
      <MediRecord/>
      <MediFeeDetail/>
      <Prescription/>
      <MediReceipt/> */}
    </>
  )
}

export default Pay