//* 컴플레인
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import request from "utils/Request.utils";
import CustomAlert from "atoms/common/CustomAlert";
import CustomerSubButton from "../../atoms/crm/SubButton";
import CustomerSubDateSearch from "../../atoms/crm/SubDateSearch";
import ComplainWriteOk from "../../components/crm/_ComplainWriteOk";//등록 완료 alert
import CustomList from "components/common/_CustomList"; //고객 이름 검색 결과 창
import { decrypt } from "../../utils/aes256.manager";
import { nvl, nvlNumber, changeMobileFormat, convertJuminToAge, convertGender, getTodayDate, getPreviousWeekDate, getPreviousMonthDate } from "../../utils/Common.utils";
import { useDispatch } from "react-redux";

const Complain = () => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [searchName, setSearchName] = useState(''); // 이름
  const [userSeq, setUserSeq] = useState(0); // 유저 seq
  const [chartCode, setChartCode] = useState(''); // 차트번호
  const [searchList, setSearchList] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [category, setCategory] = useState([]); //시술 카테고리
  const [categoryShown, setCategoryShown] = useState([]); //시술 카테고리 안에 세부 시술
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [surgeryCategory, setSurgeryCategory] = useState('');
  const [surgeryCategoryDetail, setSurgeryCategoryDetail] = useState('');
  const [surgeryCategoryDetailList, setSurgeryCategoryDetailList] = useState([]);
  const [managerList, setManagerList] = useState([]); 
  const [onUpdateBool, setOnUpdateBool] = useState(false); // 수정
  const [regManager,setRegManager] = useState(''); //등록자
  const [manager1, setManager1] = useState(null); //관련자1
  const [manager2, setManager2] = useState(null); //관련자2
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [complainList, setComplainList] = useState([]);
  const [complainTotal, setComplainTotal] = useState([]); //컴플레인 개수
  const [complainSeq, setComplainSeq] = useState(0); //컴플레인 시퀀스
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodOption, setPeriodOption] = useState(null);
  const [periodValue, setPeriodValue] = useState(0);
  const today = getTodayDate()?.slice(0,17);
  const complainTotalCount = complainTotal.reduce((acc, cur) => acc + cur.total_count, 0);

  const dispatch = useDispatch();

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    }else{
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }

  const setPeriodSearchDate = (startDate, endDate) => {

    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('3');
    setPeriodValue(1);
  }

  //고객 이름
  const UserNameHandler = (e) => {
    const { value } = e.target;
    setSearchName(value);
  }

  //고객 이름 삭제
  const RemoveNameHandler = () => {
    setSearchName("");
    setChartCode("");
  }

  // 고객 리스트 가져오기
  const SearchUserNameHandler = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        if(response.data.dataList.length >= 0) setLnbSearchOptions((prev) => { return { ...prev, checkedBool: true } })

        setSearchList(response.data.dataList);
        setTotalCount(response.data.totalCnt);
      } else if(response.messageCode === 304){
      } else {
        console.log("고객 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
        
    if(searchName === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객 이름을 입력해주세요."}});

      return false;
    } 
    
    request.get(`/api/user?searchKeyword=${searchName}`, null, successHandler, failHandler, null, null);
  }
  
  // 시술 카테고리 가져오기
  const getSurgery = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setCategory(response?.data.dataList);
      } else {
        console.log("시술 카테고리 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/etc/commoncd/3/001000000`, null, successHandler, failHandler, null, null);
  }
    
  // 시술 카테고리 > 세부 시술 목록 가져오기
  const getSurgeryDetail = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        if (response.data.length > 0) {
          setCategoryDetail(response.data);
        }
      } else {
        console.log("세부 시술 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }
  
    request.get(`/api/goods?gubun=G`, null, successHandler, failHandler, null, null);
  }

  const [lnbSearchOptions, setLnbSearchOptions] = useState({
    checkedBool: false, // 고객리스트 컴포넌트 노출 여부
    hotkeyBool: false, // 핫키 컴포넌트 노출 여부
    totalCount: 0, // 총 갯수
    searchList: null, // 검색 리스트
    searchKeyword: '', // 이름 or 차트코드 or 휴대전화 번호 검색
    userData: {}, // 고객 정보
    send: false,
    sent: false
  })

  const changeUserHandler = (userSeq) => { // 선택된 값
    if (nvlNumber(userSeq) !== "") {
      if (userSeq > 0) {
        request.get(`/api/user/${userSeq}`, null, (response) => {
          if (response.messageCode === 200) {
            let userInfo = response.data.dataList[0];

            dispatch({ type: "setCustomer", payload: { fieldName: "user_seq", data: userSeq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_name", data: userInfo.user_name } });
            dispatch({ type: "setCustomer", payload: { fieldName: "chart_code", data: userInfo.chart_code } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_gender", data: userInfo.user_gender } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_dob", data: userInfo.user_dob } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_age", data: nvl(userInfo.user_jumin) !== "" ? convertJuminToAge((decrypt(userInfo.user_jumin).substring(0, 6) + "-" + decrypt(userInfo.user_jumin).substring(6, 13))) : " - " } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level", data: userInfo.user_level } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_status", data: userInfo.user_status } });
            dispatch({ type: "setCustomer", payload: { fieldName: "interest_area", data: userInfo.interest_area } });
            dispatch({ type: "setCustomer", payload: { fieldName: "admissive_channel", data: userInfo.admissive_channel } });
            dispatch({ type: "setCustomer", payload: { fieldName: "main_tc_seq", data: userInfo.main_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "sub_tc_seq", data: userInfo.sub_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo", data: nvlNumber(userInfo.up_file_seq) } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo_path", data: userInfo.file_path } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_reser_seq", data: userInfo.user_reser_seq } });

            setSearchName(userInfo.user_name);
            setChartCode(userInfo.chart_code);
            setUserSeq(userSeq);

          } else console.log("고객 정보를 가져오지 못했습니다.")
        });
      }
    }
  }

  //시술 카테고리 변경
  const onChangeSurgery = (e) => {
    const { name, value } = e.target;
     if (name === "surgery_category") {
        const newArray = categoryDetail.filter(el => value === el.category);
        setCategoryShown(newArray);
      }else if(name === "surgery_category_detail"){
        setSurgeryCategoryDetail(value);
    
        if (surgeryCategoryDetailList.findIndex(el => value === el.category)) {
          return false;
        }
        
        setSurgeryCategory(""); // 시술 카테고리 초기화
        setSurgeryCategoryDetail(""); // 세부 시술 초기화
        setCategoryShown([]); // 세부 시술 리스트 초기화
      }
  };
  
  //등록자, 관련자 셀렉트박스 변경
  const onChangeManager = (e) => {
    const { name, value } = e.target;

    if (name === "regManager") {
      setRegManager(value)
    } else if (name === "manager1") {
      setManager1(value)
    } else {
      setManager2(value)
    }
  };
  
  // 등록자 가져오기
  const getManager = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setManagerList(response?.data);
      } else {
        console.log("등록자 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/manager`, null, successHandler, failHandler, null, null);
  }

  //컴플레인 등록
  const complainInputHandler = (e) => {
    const { name, value } = e.target;
    if(name === "subject"){
      setSubject(value);
    }else{
      setContent(value);
    }
  }

  // 컴플레인 등록 버튼 클릭
  const registerComplain = () => {
    if (searchName === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객이름을 선택해주세요." } })
      return;
    } else if (surgeryCategory === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 카테고리를 선택해주세요." } })
      return;
    } else if (surgeryCategoryDetail === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술을 선택해주세요." } })
      return;
    } else if (regManager === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "등록자를 선택해주세요." } })
      return;
    } else if (subject === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "제목을 입력해주세요." } })
      return;
    } else if (content === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "내용을 입력해주세요." } })
      return;
    }

    if (!onUpdateBool) { 
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "컴플레인을 등록하시겠습니까?", activateMode: "regComplain" } })
    } else { //컴플레인 수정
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "컴플레인을 수정하시겠습니까?", activateMode: "updateComplain" } })
    }
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (!onUpdateBool && alertLayerPopup.activateMode === "regComplain") {
        let params = {
          user_seq: searchList[0].user_seq,
          category: surgeryCategory,
          goods_seq: surgeryCategoryDetail,
          selected_manager_seq: regManager,
          manager1_seq: manager1,
          manager2_seq: manager2,
          subject: subject,
          content: content,
          reg_manager_seq: regManager,
          modi_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            resetComplain();
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "컴플레인이 등록되었습니다." } })
          } else {
            console.log("컴플레인을 등록하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/complain", params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "updateComplain") {
        let params = {
          category: surgeryCategory,
          goods_seq: surgeryCategoryDetail,
          selected_manager_seq: regManager,
          manager1_seq: manager1,
          manager2_seq: manager2,
          subject: subject,
          content: content,
          reg_manager_seq: regManager,
          modi_manager_seq: managerInfo.seq,
          complain_seq: complainSeq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            resetComplain();
            getComplain();
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "컴플레인을 수정하였습니다." } })
          } else {
            console.log("컴플레인을 수정하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.put("/api/complain", params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "deleteComplain") {
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            resetComplain();
            getComplain();
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "컴플레인을 삭제하였습니다." } })
          } else {
            console.log("컴플레인을 삭제하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.del(`/api/complain/${complainSeq}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
      }
    }
    getComplain();
  }
  
  //complain 등록할 수 있게 초기화
  const resetComplain = () => {
    setSearchName("");
    setChartCode("");
    setSurgeryCategory("")
    setSurgeryCategoryDetail("")
    setCategoryShown([]);
    setRegManager("");
    setManager1("");
    setManager2("");
    setSubject("");
    setContent("");
    setOnUpdateBool(false);
  }

  // 컴플레인 리스트 가져오기
  const getComplain = () => {
    let params = {
      periodOption: periodOption
    }
    
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setComplainList(response?.data.resultData); 
      } else {
        console.log("컴플레인 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/complain/detail?periodOption=${params.periodOption}&startDate=${startDate}&endDate=${endDate}&order_by=complain_seq DESC&delete_yn=N`, null, successHandler, failHandler, null, null);
  }


   // 컴플레인 개수 가져오기
   const getComplainCount = () => {
     let params = {
       periodOption: periodOption,
       dates: ''
     }
 
     const successHandler = (response) => {
       if (response.messageCode === 200) {
         setComplainTotal(response?.data);
         getComplain();
       } else {
         console.log("컴플레인 개수를 가져오지 못했습니다.")
       }
     }
     
     const failHandler = (response) => {
       console.log('에러' + response);
     }
     
     request.get(`/api/complain/count/G/${params.dates}?limit=${params.limit}&offset=${params.offset}&periodOption=${params.periodOption}&startDate=${startDate}&endDate=${endDate}`, null, successHandler, failHandler, null, null);
   }

  // 컴플레인 고객 조회
  const getComplainUserseq = (user_seq,reg_date,chart_code, user_name) => {
    let params = {
      user_seq: user_seq,
      dates: reg_date?.substr(0,10),
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response?.data.resultData[0];

        setChartCode(chart_code);
        setSearchName(user_name);
        setSurgeryCategory(result?.category);
        setSurgeryCategoryDetail(result?.goods_seq);
        setRegManager(result?.reg_manager_seq);
        setManager1(result?.manager1_seq);
        setManager2(result?.manager2_seq);
        setSubject(result?.subject);
        setContent(result?.content);
        setComplainSeq(result?.complain_seq);
      } else {
        console.log("컴플레인 상세보기를 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/complain/${params.user_seq}/${params.dates}?limit=${params.limit}&offset=${params.offset}&periodOption=${params.periodOption}&startDate=${startDate}&endDate=${endDate}&delete_yn=N`, null, successHandler, failHandler, null, null);
  }

  useEffect(()=>{
    getSurgery();
    getSurgeryDetail();
    getManager();
    getComplainCount(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    getComplainCount(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[periodOption, startDate])


  const updatetHandler = (e, user_seq, reg_date, chart_code, user_name,goods_seq) => {
    setOnUpdateBool(true);
    getComplainUserseq(user_seq,reg_date,chart_code, user_name);
    getSurgeryDetail();
    const newArray = categoryDetail.filter(el => Number(goods_seq) === el.goods_seq);
    setCategoryShown(newArray);
    setSurgeryCategoryDetail(categoryShown[0]?.goods_seq);
  };

  const deleteComplain = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "컴플레인을 삭제하시겠습니까?", activateMode: "deleteComplain"}});
  }

  return (
    <>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
      
      <CustomList checkedBool={lnbSearchOptions.checkedBool} searchList={searchList} changeUserHandler={changeUserHandler} totalCount={totalCount} setLnbSearchOptions={setLnbSearchOptions} />

      <section className="section com_lnb2 p_complain">
        <div className="inner">
          <CustomerSubButton buttonHandler={subButtonAction} />
          <CustomerSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate} periodOption={periodOption}/>
          {onUpdateBool === false ?
          <p>&nbsp;</p>
          : 
          <>
          <button className="com_btn green m btn_add" onClick={resetComplain}><i className="svg_icon icon_add white">&nbsp;</i>컴플레인 등록</button>
          </>
          }
        </div>
      </section>
      <section className="com_content p_complain">
        <div className="com_write box">
          <article className="left_wrap">
            <table className="write">
              <colgroup><col width="130px"/><col/></colgroup>
              <tbody>
                <tr>
                  <th>고객 코드</th>
                  <td>{chartCode}</td>
                </tr>
                <tr>
                  <th>고객 이름</th>
                  <td>
                    <fieldset className="many">
                      <div className="input_del_wrap">
                        <input type="text" className="input" placeholder="고객 이름 검색" name="user_name" onChange={(e) => UserNameHandler(e)} onKeyDown={(e) => {if (e.key === 'Enter') SearchUserNameHandler(e)}} value={searchName}/>
                        <button type="button" className="btn_input_del" onClick={(e) => RemoveNameHandler(e)}></button>
                      </div>
                      <button className="com_btn yellow m btn_search" onClick={(e) => SearchUserNameHandler(e)}><i className="svg_icon icon_search">&nbsp;</i></button>
                    </fieldset>
                  </td>
                </tr>
                <tr>
                  <th>시술 카테고리</th>
                  <td>
                    <select className="select" name="surgery_category" id="surgery_category" value={surgeryCategory || ''} onChange={(e) => {
                    setSurgeryCategory(e.target.value);
                    onChangeSurgery(e);
                    }}>
                    <option value={0}>시술 카테고리 선택</option>
                    {category?.map((el,i)=>(
                      <option key={i} value={el.common_code}>{el.code_name}</option>
                    ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>시술 선택</th>
                  <td>
                    <select className="select" name="surgery_category_detail" id="surgery_category_detail" value={surgeryCategoryDetail || ''} onChange={(e) => onChangeSurgery(e)}>
                    <option value={0}>시술 선택</option>
                    {categoryShown.map((el, i) => (
                      <option key={i} value={el?.goods_seq}>
                        {el?.goods_name}
                      </option>
                    ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>등록자</th>
                  <td>
                    <select className="select half" name="regManager" value={nvl(regManager)} onChange={(e) => onChangeManager(e)}>
                      <option value={0}>등록자 선택</option>
                      {managerList.map((el,i)=>(
                        <option key={i} value={el.manager_seq}>{el.manager_name}</option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>관련자</th>
                  <td>
                    <select className="select half" name="manager1" value={manager1 || ''} onChange={(e) => onChangeManager(e)}>
                      <option value={0}>관련자 선택</option>
                      {managerList.map((el,i)=>(
                        <option key={i} value={el.manager_seq}>{el.manager_name}</option>
                      ))}
                    </select>
                    <select className="select half" name="manager2" value={manager2 || ''} onChange={(e) => onChangeManager(e)}>
                      <option value={0}>관련자 선택</option>
                      {managerList.map((el,i)=>(
                        <option key={i} value={el.manager_seq}>{el.manager_name}</option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </article>
          <hr className="line"/>
          <article className="right_wrap">
          <table className="input_contents">
            <colgroup><col/><col width="90px"/></colgroup>
            <tbody>
              <tr>
                <td><input type="text" className="input" placeholder="컴플레인 제목 입력" name="subject" value={subject} onChange={(e)=>complainInputHandler(e)}/></td>
                {onUpdateBool === false ? 
                  <td rowSpan="2" className="btn_field">
                    <button className="com_btn blue m" onClick={registerComplain}>등록</button>
                  </td> 
                  : 
                  <td rowSpan="2" className="btn_field two">
                    <button className="com_btn darkgray m" onClick={deleteComplain}>삭제</button>
                    <button className="com_btn blue m" onClick={registerComplain}>수정</button>
                  </td> 
                }
              </tr>
              <tr>
                <td>
                  <textarea className="textarea" placeholder="컴플레인 내용 입력" name="content" value={content} onChange={(e)=>complainInputHandler(e)}></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          </article>
        </div>
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="date">{periodValue === 0 ?  `${startDate}` : `${startDate} ~ ${endDate}`}</p>
            </div>
            <div className="btn_field">
              <button className="com_btn blue m">
                <Link to="/crm/complain_chart" state={{category : category, complainTotalCount:complainTotalCount}}>컴플레인 통계보기</Link></button>
            </div>
          </article>
          <table className="list">
            <colgroup><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col width="5.6%"/><col/></colgroup>
            <thead>
              <tr>
                {category.map((el,i)=>(
                  <th key={i}>{el.code_name}</th>
                ))}
                <th>총 컴플레인 수</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {complainTotal?.map((el,i)=>(
                  <td key={i}>{el.total_count}</td>
                ))}
                <td className="total_field"><b className="total">{String(complainTotalCount)}</b></td>
              </tr>
            </tbody>
          </table>
          <article className="scroll">
            <table className="list">
              <colgroup>
              {/* <col width="3.4%"/> */}
              <col width="6.1%"/><col width="6.1%"/><col width="6.7%"/><col width="7.8%"/><col width="6.1%"/><col width="6.8%"/><col width="7.8%"/><col width="11.2%"/><col/><col width="6.1%"/><col width="6.1%"/></colgroup>
              <thead>
                <tr>
                  {/* <th>선택</th> */}
                  <th>등록날짜</th>
                  <th>고객코드</th>
                  <th>이름</th>
                  <th>휴대폰번호</th>
                  <th>등급</th>
                  <th>등록자</th>
                  <th>관련자</th>
                  <th>시술</th>
                  <th>컴플레인 제목</th>
                  <th>진행상황</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
              {complainList?.length !== 0 ? complainList?.map((el,i)=>(
                <tr key={i}>
                  <td>{el.reg_date?.substr(0,10)}</td>
                  <td>{el.chart_code}</td>
                  <td>{el.user_name}({convertGender(el?.user_gender)}/{nvl(el.user_jumin) === "" ? " - " : convertJuminToAge((decrypt(el.user_jumin).substring(0, 6) + "-" + decrypt(el.user_jumin).substring(6, 13)))})</td>
                  <td>{nvl(el?.user_mobile) === "" ? " - " : changeMobileFormat(el?.user_mobile)}</td>
                  <td>{el.user_level_name}</td>
                  <td>{el.selected_manager_name}</td>
                  <td> {el.manager_name1 === null && el.manager_name2 === null ? "-" : 
                  `${el.manager_name1 === null ? "" : el.manager_name1}  /  ${el.manager_name2 === null ? "" : el.manager_name2}`}</td>
                  <td>{el.category_name}</td>
                  <td>{el.subject}</td>
                  <td>진행중</td>
                  <td><button className="com_btn txt" onClick={(e) => updatetHandler(e,el.user_seq,el.reg_date,el.chart_code,el.user_name,el.goods_seq)}>상세보기</button></td>
                </tr>
              )) :  
                <tr>
                  <td colSpan="11">데이터가 없습니다.</td>
                </tr>
              }
            </tbody>
            </table> 
          </article>  
        </div>
      </section>
      {/* <ComplainWriteOk/> */}
    </>
  )
}

export default Complain