import React, { useEffect, useState } from "react";
import request from "utils/Request.utils";
import { decrypt } from "../../utils/aes256.manager";
import { nvl } from "utils/Common.utils";
import { convertJuminToAge, convertGender } from "utils/Common.utils";
import CustomCard from "components/common/_CustomCard";
import CustomAlert from "atoms/common/CustomAlert";
import moment from "moment";

const DashFinish = ({ userData, setUserData, today, getSelectedUserInfo, getReceptionList, finishList, setFinishList, totalCount }) => {

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    closeType: ''
  });

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  // 접었다 폈다
  const activateArticle = (user_seq) => {
    let copyFinishList = [...finishList];
    let findIndex = copyFinishList.findIndex((el) => el.user_seq === user_seq);

    if (copyFinishList[findIndex].active) copyFinishList[findIndex].active = false;
    else copyFinishList[findIndex].active = true;
    setFinishList(copyFinishList);
  };

  const [popup, setPopup] = useState({
    customerCard: false
  });

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
    setUserData('');
  };

  const updateOrderStatus = (user_seq, order_seq) => {
    let params = {
      order_status: "013007000",
      modi_manager_seq: managerInfo.seq,
      order_date: moment().format("YYYY-MM-DD"),
      user_seq: user_seq,
      order_seq: order_seq
    }
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        getReceptionList()
      }
    }
    const failHandler = (response) => {
      console.log(response + "에러");
    }

    request.put(`/api/charts/order/dashboard`, params, successHandler, failHandler, null, null);
  }

  const btnStatusSubmitHandler = (user_seq, sub_tc_seq, order_seq) => {
    // e.stopPropagation();

    let params = {
      chart_status: "013009000",
      user_seq: user_seq,
      sub_tc_seq: sub_tc_seq,
      modi_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {

        updateOrderStatus(user_seq, order_seq)
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "준비 상태로 변경하는 중 에러가 발생했습니다." } })
    }

    const failHandler = (result) => {
      console.log("Error >> " + result);
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "준비 상태로 변경하는 중 에러가 발생했습니다." } })
    }

    request.put("/api/charts/sub/status", params, successHandler, failHandler, null, null);
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
    }
  }

  return (
    <>
      <div className="dashboard_sec dashboard_end">
        <div className="tab_box">
          <ul className="tab">
            <li className="item"><button className="menu">시술완료 {totalCount}</button></li>
          </ul>
          <button className="svg_icon icon_refresh" onClick={() => getReceptionList()}></button>
        </div>
        {finishList?.length > 0 ? finishList.map((item, index) =>
          <section className="com_sec" key={index}>
            <article className="com_user_box">
              <div className={`color ${item?.order_count <= 1 ? "first" : "second"}`}></div>
              <div className="age_phone" style={{ background: "#0070c0" }} onClick={() => getSelectedUserInfo(item.user_seq, 'popup')}>
                <h1 className="age">{item?.member_info?.user_name} ({convertGender(item?.member_info?.user_gender)}/{nvl(item?.member_info?.user_jumin) === "" ? " - " : convertJuminToAge((decrypt(item?.member_info?.user_jumin).substring(0, 6) + "-" + decrypt(item?.member_info?.user_jumin).substring(6, 13)))})</h1>
                <h2 className="phone">{item?.member_info?.user_mobile}</h2>
              </div>
            </article>
            <article className={`memo_list ${item.active ? 'active' : ''}`} onClick={() => activateArticle(item.user_seq)}>
              {item?.chart_info?.length > 0 ? item?.chart_info.map((ele, idx) => (
                <div className="memo" key={idx}>
                  <div className="tit">
                    <span className="txt">{`${idx + 1} / ${ele.total_times}`}</span>
                    <span className="txt">{ele.current_times} 회차</span>
                    <span className="txt">{ele?.goods_info === "" ? "-" : ele.pack_info.goods_type === "P" ? `패키지 ${ele.goods_info.goods_name}` : `${ele.goods_info.goods_name}`}</span>
                  </div>
                  <div className="detail">
                    <p className="txt">부연설명입니다.</p>
                    <div className="staff__box">
                      <ul className="staff">
                        <li><span className="part">접수</span><span className="name">{ele.receptionist_manager_name}</span></li>
                        <li><span className="part">상담</span><span className="name">{ele.counselor_manager_name}</span></li>
                      </ul>
                      <ul className="staff">
                        <li><span className="part">준비자</span><span className="name">{ele.preparation_manager_name}</span></li>
                        <li><span className="part">시술자</span><span className="name">{ele.treatment_manager_name}</span></li>
                        <li><span className="part">관리자</span><span className="name">{ele.manager_name}</span></li>
                        <li><span className="part">마무리</span><span className="name">{ele.finish_manager_name}</span></li>
                      </ul>
                    </div>
                    <button className="com_btn blue m" onClick={() => btnStatusSubmitHandler(item.user_seq, ele.sub_tc_seq, item.order_seq)}>{"<< "}&nbsp;{" 준비로 이동"}</button>
                  </div>
                </div>
              )) : null}
            </article>
          </section>
        ) : null}
      </div>
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
      {popup.customerCard && <CustomCard onClose={() => onClosePop('customerCard')} userData={userData} />}
    </>
  )
}

export default DashFinish