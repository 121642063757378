//* 제약사 관리
import CustomAlert from "atoms/common/CustomAlert";
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import { nvl, nvlNumber } from "../../utils/Common.utils";


const CompanyManage = (props) => {
  let drugCompanyManageBool = props.drugCompanyManageBool;

  const popupSectionRef = useRef();
  const companyNameRef = useRef();
  const nodeRef = useRef(null);

  // alert, confirm 창 
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  const [onUpdateBool, setOnUpdateBool] = useState(false)
  const [activateMode, setActivateMode] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(""); // 선택한 회사
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  if (drugCompanyManageBool) popupSectionRef.current.classList.add('active');
  else if (!drugCompanyManageBool && popupSectionRef.current) popupSectionRef.current.classList.remove('active');

  // 제약사 관리 팝업창 닫기
  const popupClose = () => {
    setSelectedCompany("")
    props.drugCompanyManageToggle()
  }

  // alert 창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  // 제약회사 등록하기
  const AddCompany = () => {
    let companyName = companyNameRef.current.value;

    if (nvl(companyName) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "회사명을 입력해주세요." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "회사를 등록하시겠습니까?" } })
    setActivateMode('addPharmCompany')
  }

  // 제약회사 수정하기
  const updateCompany = () => {

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "회사를 수정하시겠습니까?" } })
    setActivateMode('updatePharmCompany')
  }

  // 회사 선택하기
  const clickHandler = (e, pharmSeq, pharmName) => {
    if (selectedCompany === pharmSeq) {
      setOnUpdateBool(false)
      setSelectedCompany("")
      companyNameRef.current.value = ""

      e.target.checked = false
    } else {
      setOnUpdateBool(true)
      setSelectedCompany(pharmSeq)
      companyNameRef.current.value = pharmName

      e.target.checked = true
    }
  }

  // 제약 회사 삭제하기
  const deletePharmCompany = () => {
    if (nvl(selectedCompany) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 회사가 없습니다." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "선택된 회사를 삭제하시겠습니까?" } })
    setActivateMode('deletePharmCompany'); // 다음 실행할 함수를 호출하기 위해서
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      if (activateMode === "addPharmCompany") { // 제약사 등록
        let params = {
          pharm_name: nvl(companyNameRef.current.value),
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            companyNameRef.current.value = "";

            // 리스트 재호출
            props.getCompany();
            setSelectedCompany("")
          } else {
            console.log('회사를 등록하지 못했습니다')
          }
        }

        const failHandler = (response) => {
          console.log('에러' + response)
        }

        request.post("/api/pharms", params, successHandler, failHandler, null, null);

      } else if (activateMode === "deletePharmCompany") { // 제약사 삭제
        let params = {
          modi_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) { // ok 코드 값 

            companyNameRef.current.value = "";

            // 다시 호출
            props.getCompany();
          } else if (response.messageCode === 409) {
            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "카테고리 내 약품이 있으므로 삭제가 불가능합니다." } })
            setSelectedCompany("")
          } else {
            console.log("코드 값을 찾지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에라야 에라~~" + response);
        }

        request.del(`/api/pharms/${selectedCompany}/${managerInfo.seq}`, params, successHandler, failHandler, null, null);
      } else if (activateMode === "updatePharmCompany") {
        let params = {
          pharm_name: companyNameRef.current.value,
          pharm_seq: selectedCompany,
          modi_manager_seq: managerInfo.seq
        }
        const successHandler = (response) => {
          if (response.messageCode === 200) { // ok 코드 값 
            setSelectedCompany("")
            companyNameRef.current.value = "";

            // 다시 호출
            props.getCompany();
          } else {
            console.log("코드 값을 찾지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에라야 에라~~" + response);
        }
        request.put("/api/pharms", params, successHandler, failHandler, null, null)
      }
    }
  }

  return (
    <>
      {/* //* 일반 팝업 */}
      <section ref={popupSectionRef} className="com_popup type_normal">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => popupClose()}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">제약사 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">
              <fieldset className="add">
                <input type="text" className="input" ref={companyNameRef} />
                <button type="button" className="com_btn m blue" onClick={onUpdateBool ? updateCompany : AddCompany}>{onUpdateBool ? "수정" : "등록"}</button>
              </fieldset>
              <article className="list">
                {props.pharmCompany.length === 0 ?
                  <ul className="item_wrap scroll">
                    <li className="item no_data">등록된 제약사가 없습니다.</li>
                  </ul>
                  :
                  <ul className="item_wrap scroll">
                    {props.pharmCompany?.map((company, index) => (
                      <li className="item" key={company.pharm_seq}>
                        <fieldset className="com_check_wrap" >
                          <input type="radio" className="radio" id={"company" + index} name="radio" value={company.pharm_seq}
                            onClick={(e) => clickHandler(e, company.pharm_seq, company.pharm_name)} defaultChecked={selectedCompany === company.pharm_seq} />
                          <label className="btn_radio" htmlFor={"company" + index} >&nbsp;</label>
                          <label htmlFor={"company" + index}>{company.pharm_name}</label>
                        </fieldset>

                      </li>
                    ))}
                  </ul>
                }
              </article>
              <p className="tip_txt">카테고리내 약품명이 있는 경우<br />삭제가 불가능합니다.</p>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={deletePharmCompany}>삭제</button>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default CompanyManage
