import CustomAlert from "atoms/common/CustomAlert";
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import request from "utils/Request.utils";
import Draggable from "react-draggable";
import { nvlNumber } from "utils/Common.utils";
import moment from "moment";

const CouponHistory = ({ couponHistory }) => {
  return (
    couponHistory?.map((item, index) => {
      return (
        <tr key={item.coupon_seq + '_' + index}>
          <td>{moment(item.provide_date).format("YYYY-MM-DD")}</td>
          <td className='tit'>{item.coupon_name}</td>
          <td className='num'>{(item.coupon_price === 0 && item.discount_rate === 0) ? "-" : (item.coupon_price === 0 ? new Intl.NumberFormat().format(item.discount_rate) + " %" : new Intl.NumberFormat().format(item.coupon_price) + " 원")}</td>
          <td>{item.use_date}</td>
        </tr>
      )
    })
  )
}

// 동일한 고객에게 동일한 쿠폰을 두 개 이상 지급할 수 없음
const CouponManage = ({ onClose, userData, setUserData }) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const stateCustomer = useSelector(state => state.Customer);
  const stateToday = useSelector(state => state.Today)
  const nodeRef = useRef(null);

  const [couponHistory, setCouponHistory] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [coupon, setCoupon] = useState('');

  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => { }
  });

  const closeCustomAlert = () => {
    setAlertPop((prev) => { return { ...prev, visible: false, alertMessage: '' } })
  }

  const getCouponHistory = () => {
    const successHandler = (response) => {

      // console.log(response)

      if (response.messageCode === 200) {
        setCouponHistory(response.data?.dataList);
        // setUserData({ ...userData, user_coupon_cnt: response.data?.totalCnt });
      } else console.debug('Error >> ', response);
    }

    request.get(`/api/coupon/history?user_seq=${stateCustomer?.user_seq}&delete_yn=N&orderBy=coupon_history_seq DESC`, null, successHandler);
  }

  const giveCoupon = () => {
    if(nvlNumber(coupon) < 1) {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '쿠폰을 선택해 주세요.' } })

      return
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        if (response.data) {
          // setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '쿠폰 지급이 완료됐습니다.' } })
          setCoupon('')
          getCouponHistory();
        }
      } else console.debug('Error >> ', response);
    }

    let params = {
      coupon_seq: coupon,
      user_seq: stateCustomer?.user_seq,
      coupon_status: 'R'
    }

    request.post(`/api/coupon/history`, params, successHandler);
  }

  const getCouponList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setCouponList(response.data);

        getCouponHistory();
      } else console.debug('Error >> ', response);
    }

    request.get(`/api/coupon/code?today=${stateToday}`, null, successHandler);
  }

  useEffect(() => {
    getCouponList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section className="com_popup type_normal pop_coupon_manage PopCouponManage active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container l">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
            <div className="pop_header">
              <h1 className="pop_title">쿠폰 내역</h1>
            </div>
            <div className="pop_body">
              <article className="com_add_item">
                <fieldset className="add">
                  <select className="select" onChange={(e) => { setCoupon(e.target.value) }} key={ coupon } value={ coupon }>
                   <option value="">쿠폰 선택</option>
                    {
                      couponList.map((item, index) => (
                        <option key={item.coupon_seq} value={item.coupon_seq}>{ item.coupon_name }</option>
                      ))
                    }
                  </select>
                  <button type="button" className="com_btn m blue" onClick={ giveCoupon }>지급하기</button>
                </fieldset>
              </article>
              <article className="com_list">
                <div className="scroll">
                  <table className="list">
                    <colgroup><col width="17%" /><col /><col width="17%" /><col width="17%" /></colgroup>
                    <thead className="sticky top">
                      <tr>
                        <th>지급일</th>
                        <th>보유쿠폰</th>
                        <th>할인율 & 금액</th>
                        <th>사용일</th>
                      </tr>
                    </thead>
                    <tbody key='_couponHistory'>
                      <CouponHistory couponHistory={couponHistory} />
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
          </div>
        </Draggable>
      </section>
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
    </>
  )
}

export default CouponManage