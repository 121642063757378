//* 당일오더-약처방-세트처방추가
import CustomAlert from "atoms/common/CustomAlert";
import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils";

const SetPrescriptionSave = ({ onClose, medicineSetList, onUpdateBool, setOnUpdateBool, medicineSetUpdateList, deleteMedicineFromSet, getMedicineSetCategory }) => {
  const nodeRef = useRef(null);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => { },
  });

  const [mediSetName, setMediSetName] = useState("");

  useEffect(() => {
    if (onUpdateBool) {
      setMediSetName(medicineSetUpdateList[0]?.medi_set_name)
    } else {
      setMediSetName("")
    }

  }, [medicineSetUpdateList, onUpdateBool])
  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertPop((prev) => { return { ...prev, visible: false } })
  };


  // 세트 약품 등록
  const insertMedicineSet = () => {
    if (mediSetName === "") {
      setAlertPop((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "세트처방명을 입력해주세요." } })
      return
    } else if (medicineSetList.length <= 0) {
      setAlertPop((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "세트처방 약품을 추가해주세요." } })
      return
    }
    let params = {
      medi_code: "000000000", // 약 세트 약품코드 임의 값
      medi_name: mediSetName,
      insurance_type: "N",
      set_flag: "Y",
      reg_manager_seq: managerInfo.seq,
      medi_set_seq: medicineSetList.map(el => el.medi_seq)
    }

    const successHandler = (response) => {
      console.log(response)
      if (response.messageCode === 200) {
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: "세트처방이 등록되었습니다." } })

        getMedicineSetCategory()
        // onClose() // 세트 처방내용 저장 창 닫기
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.post("/api/medicine/set", params, successHandler, failHandler, null, null);
  }

  // 세트 약품 수정
  const updateMedicineSet = () => {
    let params = {
      medi_set_seq: medicineSetUpdateList[0].medi_set_seq,
      medi_name: mediSetName,
      modi_manager_seq: managerInfo.seq,
      medi_seq: medicineSetUpdateList.map(el => el.medi_seq)
    }
    const successHandler = (response) => {
      console.log(response)
      if (response.messageCode === 200) {
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: "세트처방이 수정되었습니다." } })

        getMedicineSetCategory()
        // setOnUpdateBool(!onUpdateBool) // 세트처방 수정 창 닫기
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }
    request.put("/api/medicine/S", params, successHandler, failHandler, null, null);
  }


  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_set_prescription_save active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container l">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => onUpdateBool ? setOnUpdateBool(!onUpdateBool) : onClose()}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">{!onUpdateBool ? "세트처방 내용 저장" : "세트처방 내용 수정"}</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <input type="text" className="input" placeholder="세트처방 명 입력(20자)" value={mediSetName} onChange={(e) => setMediSetName(e.target.value)} />
              <article className="com_list">
                <table className="list">
                  <colgroup><col /><col width="15.5%" /><col width="15.5%" /><col width="15.5%" /><col width="9.7%" /><col width="9.7%" /></colgroup>
                  <thead>
                    <tr>
                      {onUpdateBool && <th></th>}
                      <th>명칭</th>
                      <th>용량</th>
                      <th>일투</th>
                      <th>일수</th>
                      <th>원내</th>
                      <th>원외</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!onUpdateBool ?
                      medicineSetList.map((item) => {
                        return (
                          <tr key={item.medi_seq}>
                            <td className="tit">{item.medi_name}</td>
                            <td>{item.medi_volume}</td>
                            <td>{item.day_injection}</td>
                            <td>{item.day_amount}</td>
                            <td>{item.in_out_flag === 'I' && <i className="svg_icon icon_chk" />}</td>
                            <td>{item.in_out_flag === 'O' && <i className="svg_icon icon_chk" />}</td>
                          </tr>
                        )
                      })
                      :
                      medicineSetUpdateList.map((item) => {
                        return (
                          <tr key={item.medi_seq}>
                            <td>
                              <button className="com_btn lightgray icon btn_del" onClick={() => deleteMedicineFromSet("sub", item.medi_seq)}><i className="svg_icon icon_x" >&nbsp;</i></button>
                            </td>
                            <td className="tit">{item.medi_name}</td>
                            <td>{item.medi_volume}</td>
                            <td>{item.day_injection}</td>
                            <td>{item.day_amount}</td>
                            <td>{item.in_out_flag === 'I' && <i className="svg_icon icon_chk" />}</td>
                            <td>{item.in_out_flag === 'O' && <i className="svg_icon icon_chk" />}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </article>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn l blue" onClick={() => !onUpdateBool ? insertMedicineSet() : updateMedicineSet()} >{!onUpdateBool ? "등록" : "수정"}</button>
            </div>
          </div>
        </Draggable>
      </section>
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
    </>
  )
}

export default SetPrescriptionSave