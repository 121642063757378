//*자동발행 쿠폰
import React, { useRef, useEffect, useState } from "react";
import request from "utils/Request.utils";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert";
import { nvl, nvlOnlyNumber } from "utils/Common.utils";

const CouponAuto = ({autoCouponRegDisplayBool, couponRegToggle, getAutoCouponList, popData, setPopData, onUpdateBool, setOnUpdateBool, getSurgeryDetail, categoryDetail, surgeryCategoryDetail, setSurgeryCategoryDetail, categoryShown, setCategoryShown, autoCouponSeq, alertLayerPopup, setAlertLayerPopup, closeCustomAlert, getSurgery, category, surgeryCategory, setSurgeryCategory, deleteCoupon, setCheckedItems }) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [surgeryCategoryDetailList, setSurgeryCategoryDetailList] = useState([]);
  const couponInit = {
    coupon_type: '1', //쿠폰종류,
    coupon_name: '', //쿠폰명
    goods_cate: '', //시술 카테고리
    goods_seq: '', //시술 선택
    discount_radio_button: 'Y', //할인율, 할인금액 라디오버튼
    discount_rate: '', //할인율
    coupon_price: '', //할인 금액
    expired_date: '', //유효기간
    treatment_times: 0, //회차선택
    attendance_times: '', //출석 회차
    payment_attainment: '', //결제 도달 금액
    coupon_status: 'Y'
  }
  const [couponData, setCouponData] = useState(couponInit);
  
  const popupSectionRef = useRef();
  const nodeRef = useRef(null);
  let numArr = [1,2,3,4,5,6,7,8,9,10];

  if(autoCouponRegDisplayBool) popupSectionRef?.current?.classList.add('active');
  else if(!autoCouponRegDisplayBool && popupSectionRef?.current) popupSectionRef?.current?.classList.remove('active');

  //초기화
  const resetPopup = () => {
    setCouponData(couponInit);
    setSurgeryCategory(""); // 시술 카테고리 초기화
    setSurgeryCategoryDetail(""); // 세부 시술 초기화
    setCategoryShown([]); // 세부 시술 리스트 초기화
    setPopData(couponInit); //팝업 데이터 
  }

  const popupClose = () => {
    resetPopup();
    couponRegToggle(1);
  }
  
  //자동발행 쿠폰 onChange 핸들러
  const onChangeAuto = (e) => {
    const { name, value } = e.target;
    if(!onUpdateBool){ // 등록
      if(name === "coupon_type"){
        setCouponData({...couponData, coupon_type:value});
      } else if(name === "coupon_name"){
        setCouponData({...couponData, coupon_name:value});
      } else if (name === "surgery_category") {
        const newArray = categoryDetail.filter(el => value === el.category);
        setCategoryShown(newArray);
      } else if (name === "surgery_category_detail"){
        setSurgeryCategoryDetail(value);
        if (surgeryCategoryDetailList.findIndex(el => value === el.category)) {
          return false;
        }
        setSurgeryCategory(""); // 시술 카테고리 초기화
        setSurgeryCategoryDetail(""); // 세부 시술 초기화
        setCategoryShown([]); // 세부 시술 리스트 초기화
      } else if (name === "useType"){
        setCouponData({...couponData, coupon_status:value});
      } else if (name === "discount_radio_button"){ //할인율, 할인금액 라디오버튼
        setCouponData({...couponData, discount_radio_button:value, discount_rate:'', coupon_price:''});
      } else if (name === "discount_rate"){ //할인율
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regAutoCoupon"}});
          setCouponData({...couponData, discount_rate:''});
          return false;
        }else{
          setCouponData({...couponData, discount_rate:value});
        }
      } else if (name === "coupon_price"){ //할인금액
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regAutoCoupon"}});
          setCouponData({...couponData, coupon_price:''});
          return false;
        }else{
          setCouponData({...couponData, coupon_price:value});
        }
      } else if (name === "expired_date"){ //유효기간
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regAutoCoupon"}});
          setCouponData({...couponData, expired_date:''});
          return false;
        }else{
          setCouponData({...couponData, expired_date:value});
        }
      } else if (name === "attendance_times"){ //출석회차
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regAutoCoupon"}});
          setCouponData({...couponData, attendance_times:''})
          return false;
        }else{
          setCouponData({...couponData, attendance_times:value});
        }
      } else if (name === "payment_attainment"){
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regAutoCoupon"}});
          setCouponData({...couponData, payment_attainment:''});
          return false;
        }else{
          setCouponData({...couponData, payment_attainment:value});
        }
      } else if (name === "treatment_times"){
        setCouponData({...couponData, treatment_times:value});
      } else if (name === "coupon_status"){
        setCouponData({...couponData, coupon_status:value});
      }
    } else{ // 수정
      if(name === "coupon_name"){
        setPopData({...popData, coupon_name:value});
      } else if (name === "surgery_category") {
        const newArray = categoryDetail.filter(el => value === el.category);
        setCategoryShown(newArray);
        setPopData({...popData, goods_cate:value});
      } else if (name === "surgery_category_detail"){
        setSurgeryCategoryDetail(value);
        setPopData({...popData, goods_seq:value});
        if (surgeryCategoryDetailList.findIndex(el => value === el.category)) {
          return false;
        }
        setSurgeryCategory(""); // 시술 카테고리 초기화
        setSurgeryCategoryDetail(""); // 세부 시술 초기화
        setCategoryShown([]); // 세부 시술 리스트 초기화
      } else if (name === "useType"){
        setPopData({...popData, coupon_status:value});
      } else if (name === "discount_radio_button"){ //할인율, 할인금액 라디오버튼
        setPopData({...popData, discount_radio_button:value, discount_rate:'', coupon_price:''});
      } else if (name === "discount_rate"){ //할인율
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateAutoCoupon"}});
          setPopData({...popData, discount_rate:''});
          return false;
        }else{
          setPopData({...popData, discount_rate:value});
        }
      } else if (name === "coupon_price"){ //할인금액
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateAutoCoupon"}});
          setPopData({...popData, coupon_price:''});
          return false;
        }else{
          setPopData({...popData, coupon_price:value});
        }
      } else if (name === "expired_date"){ //유효기간
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateAutoCoupon"}});
          setPopData({...popData, expired_date:''});
          return false;
        }else{
          setPopData({...popData, expired_date:value});
        }
      } else if (name === "attendance_times"){ //출석회차
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateAutoCoupon"}});
          setPopData({...popData, attendance_times:''});
          return false;
        }else{
          setPopData({...popData, attendance_times:value});
        }
      } else if (name === "payment_attainment"){
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateAutoCoupon"}});
          setPopData({...popData, payment_attainment:''});
          return false;
        }else{
          setPopData({...popData, payment_attainment:value});
        }
      } else if (name === "treatment_times"){
        setPopData({...popData, treatment_times:value});
      } else if (name === "coupon_status"){
        setPopData({...popData, coupon_status:value});
      }
    }
  };

  // 쿠폰 등록 & 수정 버튼 클릭
  const registerCoupon = () => {
    if (!onUpdateBool) { // 쿠폰 등록
      if (couponData.coupon_name === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "쿠폰 명을 입력해주세요.", activateMode: "regAutoCoupon"}});
        return;
      } else if (surgeryCategory === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 카테고리를 선택해주세요.", activateMode: "regAutoCoupon"}});
        return;
      } else if(couponData.coupon_type === "2"){
        if(surgeryCategoryDetail === ""){
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술을 선택해주세요.", activateMode: "regAutoCoupon"}});
        return;
        }
      } 
      
      if(couponData.coupon_type === "1" || couponData.coupon_type === "3"){
        if (couponData.discount_radio_button === "Y" && couponData.discount_rate === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "할인율을 입력해주세요.", activateMode: "regAutoCoupon"}});
          return;
        } else if (couponData.discount_radio_button === "N" && couponData.coupon_price === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "할인금액을 입력해주세요.", activateMode: "regAutoCoupon"}});
          return;
        }
      } 
      
      if(couponData.coupon_type === "2"){
        if (couponData.treatment_times === 0) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "회차를 선택해주세요.", activateMode: "regAutoCoupon"}});
          return;
        }
      } 
      
      if (couponData.expired_date === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "유효기간을 입력해주세요.", activateMode: "regAutoCoupon"}});
        return;
      } else if(couponData.coupon_type === "1"){
        if (couponData.attendance_times === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "출석회차를 입력해주세요.", activateMode: "regAutoCoupon"}});
          return;
        } 
      } else if(couponData.coupon_type === "3"){
        if (couponData.payment_attainment === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "결제 도달액을 입력해주세요.", activateMode: "regAutoCoupon"}});
          return;
        } 
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: `${couponData.coupon_type === "1" ? "출석 " : couponData.coupon_type === "2" ? "생일 축하 " : "결제금 도달 "}쿠폰을 등록하시겠습니까?`, activateMode: "regAutoCoupon"}});
    } else { //쿠폰 수정
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "쿠폰을 수정하시겠습니까?", activateMode: "updateAutoCoupon"}});
    }
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (onUpdateBool === false && alertLayerPopup.activateMode === "regAutoCoupon") {  // 쿠폰 등록
        let params = {
          coupon_image: "",
          coupon_type: couponData.coupon_type,
          coupon_name: couponData.coupon_name,
          coupon_description: "",
          goods_cate:surgeryCategory,
          goods_seq: surgeryCategoryDetail === "" ? 0 : surgeryCategoryDetail,
          treatment_times: couponData.treatment_times, //시술회차
          discount_rate: couponData.coupon_type !== "2" && couponData.discount_radio_button === "Y" ? couponData.discount_rate : 0,
          coupon_price: couponData.coupon_type !== "2" && couponData.discount_radio_button === "N" ? couponData.coupon_price : 0,
          attendance_times: couponData.coupon_type === "1" ? couponData.attendance_times : 0, //출석회차
          payment_attainment: couponData.coupon_type === "3" ? couponData.payment_attainment : 0, //지급회차
          remark: "",
          coupon_status: couponData.coupon_status,
          expired_date: couponData.expired_date,
          use_start_date: null,
          use_end_date: null,
          modi_manager_seq: managerInfo.seq,
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `${couponData.coupon_type === "1" ? "출석 " : couponData.coupon_type === "2" ? "생일 축하 " : "결제금 도달 "}쿠폰이 등록되었습니다.`,activateMode:''}});
            getAutoCouponList();
            popupClose();
          } else {
            console.log(`${couponData.coupon_type === "1" ? "출석 " : couponData.coupon_type === "2" ? "생일 축하 " : "결제금 도달 "}쿠폰을 등록하지 못했습니다.`)
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/coupon", params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "updateAutoCoupon") { // 쿠폰 수정
        let params = {
          coupon_seq: autoCouponSeq,
          coupon_image: "",
          coupon_type: popData.coupon_type,
          coupon_name: popData.coupon_name,
          coupon_description: "",
          goods_cate: popData.goods_cate,
          goods_seq: popData?.goods_seq,
          treatment_times: popData.treatment_times, //시술회차
          discount_rate: popData.coupon_type !== "2" && popData.discount_radio_button === "Y" ? popData.discount_rate : 0,
          coupon_price: popData.coupon_type !== "2" && popData.discount_radio_button === "N" ? popData.coupon_price : 0,
          attendance_times: popData.coupon_type === "1" ? popData.attendance_times : 0, //출석회차
          payment_attainment: popData.coupon_type === "3" ? popData.payment_attainment : 0, //지급회차
          remark: "",
          coupon_status: popData.coupon_status,
          expired_date: popData.expired_date,
          use_start_date: null,
          use_end_date: null,
          modi_manager_seq: managerInfo.seq,
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            resetPopup();
            popupClose();
            setOnUpdateBool(false);
            getAutoCouponList();
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "쿠폰을 수정하였습니다." } });
          } else {
            console.log("쿠폰을 수정하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.put("/api/coupon", params, successHandler, failHandler, null, null);
      } else if(onUpdateBool && alertLayerPopup.activateMode === "deleteAutoCoupon"){ // 삭제
        const queryString = deleteCoupon.map(el => `id=${el}`).join('&');

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "쿠폰을 삭제하였습니다." } });
            setOnUpdateBool(false);
            getAutoCouponList();
            setCheckedItems([]);
          } else {
            console.log("쿠폰을 삭제하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }
        
        request.del(`/api/coupon/${queryString}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
      }
    }
  }

  useEffect(()=>{
    getSurgery();
    getSurgeryDetail(); // eslint-disable-next-line react-hooks/exhaustive-deps
    resetPopup(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // coupon_type 변경 시 안에 input 초기화
  useEffect(()=>{
    setCouponData({
      coupon_type: couponData.coupon_type,
      coupon_name: '', //쿠폰명
      goods_cate: '', //시술 카테고리
      goods_seq: '', //시술 선택
      discount_radio_button: 'Y', //할인율, 할인금액 라디오버튼
      discount_rate: '', //할인율
      coupon_price: '', //할인 금액
      expired_date: '', //유효기간
      treatment_times: 0, //회차선택
      attendance_times: '', //출석 회차
      payment_attainment: '', //결제 도달 금액
      coupon_status: 'Y'
    })
    setSurgeryCategory(""); // 시술 카테고리 초기화
    setSurgeryCategoryDetail(""); // 세부 시술 초기화
    setCategoryShown([]); // 세부 시술 리스트 초기화
    setPopData({
      coupon_type: popData.coupon_type,
      coupon_name: '', //쿠폰명
      goods_cate: '', //시술 카테고리
      goods_seq: '', //시술 선택
      discount_radio_button: 'Y', //할인율, 할인금액 라디오버튼
      discount_rate: '', //할인율
      coupon_price: '', //할인 금액
      expired_date: '', //유효기간
      treatment_times: 0, //회차선택
      attendance_times: '', //출석 회차
      payment_attainment: '', //결제 도달 금액
      coupon_status: 'Y'
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[couponData.coupon_type, setPopData, setCategoryShown, setSurgeryCategory, setSurgeryCategoryDetail])

	return (
		<>
      {alertLayerPopup.visible && (alertLayerPopup.activateMode === "regAutoCoupon" || alertLayerPopup.activateMode === "updateAutoCoupon" || alertLayerPopup.activateMode === "deleteAutoCoupon") ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
      <section ref={popupSectionRef} className="com_popup type_normal">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={popupClose}></button>
            <div className="pop_header">
              <h1 className="pop_title">자동발행 쿠폰</h1>
            </div>
            <div className="pop_body com_add_item">
              <ul className="com_input_section">
                <li className="input_field full">
                  <select className="select" name="coupon_type" id="coupon_type" value={!onUpdateBool ? couponData.coupon_type : (popData.coupon_type || '')} onChange={(e) => onChangeAuto(e)}>
                    <option value="1">출석 쿠폰</option>
                    <option value="2">생일 축하 쿠폰</option>
                    <option value="3">결제금 도달 쿠폰</option>
                  </select>
                </li>
                <li className="tit_field line">쿠폰 명</li>
                <li className="input_field line">
                  <input type="text" className="input" placeholder="쿠폰 명 입력" name="coupon_name" value={!onUpdateBool ? couponData.coupon_name : popData.coupon_name || ''} onChange={(e) => onChangeAuto(e)}/>
                </li>
                <li className="tit_field">시술 카테고리</li>
                <li className="input_field">
                <select className="select" name="surgery_category" id="surgery_category" value={!onUpdateBool ? surgeryCategory : popData.goods_cate || ''} onChange={(e) => {
                    setSurgeryCategory(e.target.value);
                    onChangeAuto(e);
                    }}>
                  <option value={0}>시술 카테고리 선택</option>
                  {category?.map((el,i)=>(
                    <option key={i} value={el.common_code}>{el.code_name}</option>
                  ))}
                </select>
                </li>
                {(!onUpdateBool && (couponData.coupon_type === "1" || couponData.coupon_type === "3")) || (onUpdateBool && (popData?.coupon_type === "1" || popData?.coupon_type === "3")) ?
                  <>
                    <li className="tit_field has_input">
                      <fieldset className="com_radio_wrap" name="discount_radio_button">
                        <input type="radio" className="radio" id="discount01" name="discount_radio_button" value="Y" checked={!onUpdateBool ? (couponData.discount_radio_button === "Y" ? true : false) : (popData?.discount_radio_button === "Y" ? true : false)} onChange={(e) => onChangeAuto(e)}/>
                        <label className="btn_radio" htmlFor="discount01">&nbsp;</label>
                        <label htmlFor="discount01">할인율</label>
                      </fieldset>
                    </li>
                    <li className="input_field input_wrap">
                      <input type="text" className="input" name="discount_rate" value={!onUpdateBool ? couponData.discount_rate : (popData?.discount_rate === 0 ? "" : popData?.discount_rate || '')} disabled={!onUpdateBool ? (couponData?.discount_radio_button === "N" ? true : false) : (popData?.discount_radio_button === "N" ? true : false)} onChange={(e) => onChangeAuto(e)}/><span className="unit">%</span>
                    </li>
                    <li className="tit_field has_input">
                      <fieldset className="com_radio_wrap">
                        <input type="radio" className="radio" id="discount02" name="discount_radio_button" value="N" onChange={(e) => onChangeAuto(e)} checked={!onUpdateBool ? (couponData.discount_radio_button === "N" ? true : false) : (popData?.discount_radio_button === "N" ? true : false)}/>
                        <label className="btn_radio" htmlFor="discount02">&nbsp;</label>
                        <label htmlFor="discount02">할인금액</label>
                      </fieldset>
                    </li>
                    <li className="input_field input_wrap">
                      <input type="text" className="input" name="coupon_price" 
                      value={!onUpdateBool ? (couponData.coupon_price) : (popData?.coupon_price === 0 ? "" : (popData?.coupon_price ? (popData?.coupon_price) : ""))} 
                      disabled={!onUpdateBool ? (couponData?.discount_radio_button === "Y" ? true : false) : (popData?.discount_radio_button === "Y" ? true : false)} onChange={(e) => onChangeAuto(e)}/><span className="unit">원</span>
                    </li>
                  </> : ""
                }
                {couponData.coupon_type === "2" || popData?.coupon_type === "2" ? 
                  <>
                    <li className="tit_field">시술 선택</li>
                    <li className="input_field">
                    <select className="select" name="surgery_category_detail" id="surgery_category_detail" value={!onUpdateBool ? surgeryCategoryDetail : popData?.goods_seq} onChange={(e) => onChangeAuto(e)}>
                      <option value={0}>시술 선택</option>
                      {categoryShown.map((el, i) => (
                        <option key={i} value={el?.goods_seq}>
                          {el?.goods_name}
                        </option>
                      ))}
                    </select>
                    </li>
                    <li className="tit_field">회차 선택</li>
                    <li className="input_field">
                      <select className="select" name="treatment_times" value={!onUpdateBool ? couponData.treatment_times : popData?.treatment_times} onChange={(e) => onChangeAuto(e)}>
                        <option value={0}>회차 선택</option>
                        {numArr.map((el,i) => (
                          <option key={i} value={el}>{el}회</option>
                        ))}
                      </select>
                    </li>
                  </> : ""
                }
                <li className="tit_field">유효기간</li>
                <li className="input_field input_wrap">
                  <input type="text" className="input" name="expired_date" value={!onUpdateBool ?  couponData.expired_date : popData?.expired_date || ''} onChange={(e) => onChangeAuto(e)}/><span className="unit">일</span>
                </li>
                {(!onUpdateBool && couponData.coupon_type === "1") || (onUpdateBool &&  popData?.coupon_type === "1") ? 
                  <>
                    <li className="tit_field">출석 회차</li>
                    <li className="input_field input_wrap">
                      <input type="text" className="input" name="attendance_times" value={!onUpdateBool ? couponData.attendance_times : popData?.attendance_times || ''} onChange={(e) => onChangeAuto(e)}/><span className="unit">회</span>
                    </li>
                  </> : ""
                }
                {couponData.coupon_type === "3" || popData?.coupon_type === "3" ?
                  <>
                    <li className="tit_field">결제 도달액</li>
                    <li className="input_field input_wrap">
                      <input type="text" className="input" name="payment_attainment" value={!onUpdateBool ? (couponData.payment_attainment) : (popData?.payment_attainment)} onChange={(e) => onChangeAuto(e)}/><span className="unit">원</span>
                    </li>
                  </> : ""
                }
                <li className="input_field full line center">
                  <fieldset className="com_radio_wrap" name="useType" >
                    <input type="radio" className="radio" id="useType1" name="coupon_status" value="Y" checked={!onUpdateBool ? (couponData.coupon_status === "Y" ? true : false) : (popData?.coupon_status === "Y" ? true : false)}
                    onChange={(e) => onChangeAuto(e)}/>
                    <label className="btn_radio" htmlFor="useType1">&nbsp;</label>
                    <label htmlFor="useType1">사용</label>
                    <input type="radio" className="radio" id="useType2" name="coupon_status" value="N" checked={!onUpdateBool ? (couponData.coupon_status === "N" ? true : false) : (popData?.coupon_status === "N" ? true : false)}
                    onChange={(e) => onChangeAuto(e)}/>
                    <label className="btn_radio" htmlFor="useType2">&nbsp;</label>
                    <label htmlFor="useType2">미사용</label>
                  </fieldset>
                </li>
              </ul>
              <p className="tip_txt">* 이 쿠폰은 해당 조건이 만족 시 자동 지급됩니다.</p>
            </div>
            <div className="pop_footer com_btn_wrap many two">
              <button className="com_btn gray l" onClick={popupClose}>취소</button>
              <button className="com_btn blue l" onClick={registerCoupon}>{!onUpdateBool ? "등록" : "수정"}</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default CouponAuto