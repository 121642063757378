// *관리>쿠폰관리
import React, { useState, useEffect } from "react";
import _CouponAuto from "../../atoms/admin/_CouponAuto.js";//자동발행쿠폰
import _CouponNormal from "../../atoms/admin/_CouponNormal.js";//일반할인쿠폰
import _CouponProcedure from "../../atoms/admin/_CouponProcedure.js";//시술지급 쿠폰
import _CouponAdd from "../../atoms/admin/_CouponAdd.js";//쿠폰 발행
import request from "utils/Request.utils.js";
import { addComma, nvl } from "../../utils/Common.utils";

const Coupon = () => {
  const [autoCouponRegDisplayBool, setAutoCouponRegDisplayBool] = useState(false); //자동발행 쿠폰 팝업
  const [normalCouponRegDisplayBool, setNormalCouponRegDisplayBool] = useState(false); //일반할인 쿠폰 팝업
  const [surgeryCouponRegDisplayBool, setSurgeryCouponRegDisplayBool] = useState(false); //시술지급 쿠폰 팝업
  const [publishCouponRegDisplayBool, setPublishCouponRegDisplayBool] = useState(false); //시술지급 쿠폰 팝업
  const [autoCouponList, setAutoCouponList] = useState([]); //자동발행 쿠폰
  const [normalCouponList, setNormalCouponList] = useState([]); //일반할인 쿠폰
  const [procedureCouponList, setProcedureCouponList] = useState([]); //시술지급 쿠폰
  const [autoCouponSeq, setAutoCouponSeq] = useState('');
  const [checkedAuto, setCheckedAuto] = useState([]); //자동발행쿠폰 선택삭제
  const [checkedNormal, setCheckedNormal] = useState([]); //일반할인쿠폰 선택삭제
  const [checkedProcedure, setCheckedProcedure] = useState([]); //시술쿠폰 선택삭제
  const [deleteCoupon, setDeleteCoupon] = useState('');
  const [couponSeq, setCouponSeq] = useState([]);
  const [couponStatus, setCouponStatus] = useState([]);
  const [onUpdateBool, setOnUpdateBool] = useState(false); // 수정
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [surgeryCategoryDetail, setSurgeryCategoryDetail] = useState(''); 
  const [categoryShown, setCategoryShown] = useState([]); //시술 카테고리 안에 세부 시술
  const [category, setCategory] = useState([]); //시술 카테고리
  const [surgeryCategory, setSurgeryCategory] = useState('');
  const [checkedItems, setCheckedItems] = useState([]); // 각 체크박스에 대한 상태 객체
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  
  const [popData, setPopData] = useState([{
    coupon_type: '1', //쿠폰종류,
    coupon_name: '', //쿠폰명
    goods_cate: '', //시술 카테고리
    goods_seq: '', //시술 선택
    discount_radio_button: 'Y', //할인율, 할인금액 라디오버튼
    discount_rate: '', //할인율
    coupon_price: '', //할인 금액
    expired_date: '', //유효기간
    treatment_times: 0, //회차선택
    attendance_times: '', //출석 회차
    payment_attainment: '', //결제 도달 금액
    coupon_status: 'Y'
  }]);

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  let delArr = [];

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "" } })
  }

  const couponRegToggle = (num) => { //1:자동발행, 2:일반할인, 3:시술지급
    if(num === 1) setAutoCouponRegDisplayBool(!autoCouponRegDisplayBool);
    else if(num === 2) setNormalCouponRegDisplayBool(!normalCouponRegDisplayBool);
    else if(num === 3) setSurgeryCouponRegDisplayBool(!surgeryCouponRegDisplayBool);
  }

  // const PublishToggle = (e, coupon_seq, coupon_status) => { 
  //   setPublishCouponRegDisplayBool(!publishCouponRegDisplayBool);
  //   setCouponSeq(coupon_seq)
  //   setCouponStatus(coupon_status);
  // }

  // 시술 카테고리 가져오기
  const getSurgery = async() => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setCategory(response?.data.dataList);
      } else {
        console.log("시술 카테고리 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/etc/commoncd/3/001000000`, null, successHandler, failHandler, null, null);
  };

  // 자동발행 쿠폰 리스트 가져오기
  const getAutoCouponList = () => {

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setAutoCouponList(response?.data);
        setCheckedAuto(response?.data);
        getNormalCouponList();
      } else {
        console.log("자동발행 쿠폰 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/coupon/auto?order_by=coupon_seq DESC`, null, successHandler, failHandler, null, null);
  }

   // 일반할인 쿠폰 리스트 가져오기
   const getNormalCouponList = () => {

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        typeof(response.data) === 'object' ? setNormalCouponList(response?.data) : setNormalCouponList([]);
        setCheckedNormal(response?.data);
        getProcedureCouponList();
      } else {
        console.log("일반할인 쿠폰 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/coupon/normal?order_by=coupon_seq DESC`, null, successHandler, failHandler, null, null);
  }

  // 시술지급 쿠폰 리스트 가져오기
  const getProcedureCouponList = () => {

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setProcedureCouponList(response?.data);
        setCheckedProcedure(response?.data);
      } else {
        console.log("시술지급 쿠폰 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/coupon/procedure?order_by=coupon_seq DESC`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getAutoCouponList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    setCheckedItems(prev => {
      if (isChecked) {
        return [...prev, id];
      } else {
        return prev.filter(item => item !== id);
      }
    });
  }

  // 자동발행 삭제 체크
  const onCheckHandler = (e, coupon_seq) => {
    const bmode = Number(e.currentTarget.checked);
    let indexNum = checkedAuto.findIndex(el => el.coupon_seq === coupon_seq);
    const copyData = [...checkedAuto]
    copyData[indexNum] = { ...copyData[indexNum], chkItem: bmode };
    setCheckedAuto(copyData);
  }

  // 일반시술 삭제 체크
  const onCheckHandler2 = (e, coupon_seq) => {
    const bmode = Number(e.currentTarget.checked);
    let indexNum = checkedNormal.findIndex(el => el.coupon_seq === coupon_seq);
    const copyData = [...checkedNormal]
    copyData[indexNum] = { ...copyData[indexNum], chkItem: bmode };
    setCheckedNormal(copyData);
  }

  // 시술지급 삭제 체크
  const onCheckHandler3 = (e, coupon_seq) => {
    const bmode = Number(e.currentTarget.checked);
    let indexNum = checkedProcedure.findIndex(el => el.coupon_seq === coupon_seq);
    const copyData = [...checkedProcedure]
    copyData[indexNum] = { ...copyData[indexNum], chkItem: bmode };
    setCheckedProcedure(copyData);
  }

  // 시술 카테고리 > 세부 시술 목록 가져오기
  const getSurgeryDetail = async() => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        if (response.data.length > 0) {
          setCategoryDetail(response.data);
        }
      } else {
        console.log("세부 시술 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }
  
    request.get(`/api/goods?gubun=G`, null, successHandler, failHandler, null, null);
  };

  const updateAutoCoupontHandler = (e, goods_seq, coupon_type) => {
    const { value } = e.target;
    setAutoCouponSeq(value);

    // 단일 자동발행 쿠폰 가져오기
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data[0];
        setPopData({
          coupon_type: result.coupon_type,
          coupon_name: result.coupon_name,
          goods_cate: result.goods_cate,
          goods_seq: result.goods_seq,
          discount_radio_button: result.coupon_price === 0 ? "Y" : "N",
          discount_rate: result.discount_rate,
          coupon_price: result.coupon_price,
          expired_date: result.expired_date,
          treatment_times: result.treatment_times,
          attendance_times: result.attendance_times,
          payment_attainment: result.payment_attainment,
          coupon_status: result.coupon_status
        });

        if(coupon_type <=3 ) {
          setOnUpdateBool(true);
          getSurgeryDetail();
          const newArray = categoryDetail.filter(el => Number(goods_seq) === el.goods_seq);
          setCategoryShown(newArray);
          setSurgeryCategoryDetail(categoryShown[0]?.goods_seq);
        }else {
          setOnUpdateBool(true);
        }
      } else {
        console.log("자동발행 쿠폰을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/coupon/${value}`, null, successHandler, failHandler, null, null);
  };

  const deletePop = () => {
    setOnUpdateBool(true);
    delArr = checkedAuto.filter((el) => el.chkItem === 1).map(el=>el.coupon_seq);

    setDeleteCoupon(delArr);

    if (nvl(delArr).length === 0) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 쿠폰이 없습니다.", activateMode: "deleteAutoCoupon" } })
      return;
    }

    if(nvl(delArr).length !== 0){
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "삭제하시겠습니까?<br/>삭제된 쿠폰은 복구할 수 없습니다.",activateMode: "deleteAutoCoupon" } });
    } 
  }

  const deleteNormalPop = () => {
    setOnUpdateBool(true);
    delArr = checkedNormal.filter((el) => el.chkItem === 1).map(el=>el.coupon_seq);
    setDeleteCoupon(delArr);
    if (nvl(delArr).length === 0) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 쿠폰이 없습니다.", activateMode: "deleteNormalCoupon" } })
      return;
    }

    if(nvl(delArr).length !== 0){
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "삭제하시겠습니까?<br/>삭제된 쿠폰은 복구할 수 없습니다.",activateMode: "deleteNormalCoupon" } });
    } 
  }

  const deleteProcedurePop = () => {
    setOnUpdateBool(true);
    delArr = checkedProcedure.filter((el) => el.chkItem === 1).map(el=>el.coupon_seq);
    setDeleteCoupon(delArr);
    if (nvl(delArr).length === 0) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 쿠폰이 없습니다.", activateMode: "deleteProcedureCoupon"}})
      return;
    }

    if(nvl(delArr).length !== 0){
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "삭제하시겠습니까?<br/>삭제된 쿠폰은 복구할 수 없습니다.",activateMode: "deleteProcedureCoupon" } });
    } 
  }

	return (
		<>
		<section className="section com_lnb2">
			<div className="inner">
        <article className="com_lnb2_left">
          <div className="lnb2_menu_container">
            <span className="com_btn m blue">쿠폰 관리</span>
            {/* <span className="com_btn m lightgray">쿠폰 발행 내역</span> */}
          </div>
        </article>
			</div>
		</section>
		<section className="com_content p_coupon">
      <div className="com_list box">
        <article className="top_area">
          <div className="info_field">
            <p className="tit">자동발행 쿠폰</p>
          </div>
          <div className="btn_field">
            <button type="button" className="com_btn m blue" onClick={(e) => {couponRegToggle(1)}}><i className="svg_icon icon_add yellow">&nbsp;</i>쿠폰 등록</button>
            <button type="button" className="com_btn m line gray" onClick={deletePop}>선택 항목 삭제</button>
          </div>
        </article>
        <div className="scroll">
          <table className="list">
            <colgroup><col width="5%"/><col width="5%"/><col width="20%"/><col width="12%"/><col width="12%"/><col width="7%"/><col width="5%"/><col width="5%"/><col width="7%"/><col width="5%"/><col width="12%"/><col width="5%"/></colgroup>
            <thead className="sticky top">
              <tr>
                <th>선택</th>
                <th>No.</th>
                <th>쿠폰 명</th>
                <th>카테고리</th>
                <th>시술명</th>
                <th>결제 도달액</th>
                <th>시술 회차</th>
                <th>출석 회차</th>
                <th>할인율 & 금액</th>
                <th>유효기간</th>
                <th>사용여부</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {autoCouponList?.length > 0 ? autoCouponList?.map((el,i) => (
                <tr key={i}>
                  <td>
                    <fieldset className="com_check_wrap">
                      <input type="checkbox" className="checkbox" id={`chk${i}`} value={el.coupon_seq || ""} onChange={(e) => {onCheckHandler(e, el.coupon_seq); handleCheckboxChange(e, el.coupon_seq);}} checked={checkedItems.includes(el.coupon_seq)} />
                      <label className="btn_checkbox" htmlFor={`chk${i}`}>&nbsp;</label>
                    </fieldset>
                  </td> 
                  <td>{autoCouponList?.length-i}</td>
                  <td>{el.coupon_name}</td>
                  <td>{el.code_name}</td>
                  <td>{el.goods_name === null ? "-" : el.goods_name}</td>
                  <td>{el.payment_attainment === 0 ? "-" : addComma(el?.payment_attainment)+" 원"}</td>
                  <td>{el.treatment_times === 0 ? "-" : el.treatment_times+" 회"}</td>
                  <td>{el.attendance_times === 0 ? "-" : el.attendance_times+" 회"}</td>
                  <td>{el.discount_rate === 0 && el.coupon_price === 0 ? "-" : el.coupon_price === 0 ? `${el.discount_rate} %` :  `${addComma(el.coupon_price)} 원`}</td>
                  <td>{`${el.expired_date} 일`}</td>
                  <td>
                    <fieldset className="com_radio_wrap">
                      <input type="radio" className="radio" id={`radio${i}`} name={`radio${i}`} value="Y" checked={el.coupon_status === "Y" ? true : false} readOnly/>
                      <label className="btn_radio" htmlFor={`radio${i}`}>&nbsp;</label>
                      <label htmlFor={`radio${i}`}>사용</label>
                      <input type="radio" className="radio" id={`radio${i}`} name={`radio${i}`} value="N" checked={el.coupon_status === "N" ? true : false} readOnly/>
                      <label className="btn_radio" htmlFor={`radio${i}`}>&nbsp;</label>
                      <label htmlFor={`radio${i}`}>미사용</label>
                    </fieldset>
                  </td>
                  <td>
                    <button className="com_btn txt" value={el.coupon_seq} onClick={(e) => {couponRegToggle(1); updateAutoCoupontHandler(e, el.goods_seq, el.coupon_type);}}
                    >수정</button>
                  </td>
                </tr>
              )) :
              <tr>
                <td colSpan="12">데이터가 없습니다.</td>
              </tr> }
            </tbody>
          </table>
        </div>
      </div>
      <div className="com_list box">
        <article className="top_area">
          <div className="info_field">
            <p className="tit">일반할인 쿠폰</p>
          </div>
          <div className="btn_field">
            <button type="button" className="com_btn m blue" onClick={(e) => {couponRegToggle(2)}}><i className="svg_icon icon_add yellow">&nbsp;</i>쿠폰 등록</button>
            <button type="button" className="com_btn m line gray" onClick={deleteNormalPop}>선택 항목 삭제</button>
          </div>
        </article>
        <div className="scroll">
          <table className="list">
            <colgroup><col width="5%"/><col width="5%"/><col /><col width="15%"/><col width="12%"/><col width="10%"/><col width="15%"/><col width="5%"/>
            {/* <col width="5.1%"/> */}
            </colgroup>
            <thead className="sticky top">
              <tr>
                <th>선택</th>
                <th>No.</th>
                <th>쿠폰 명</th>
                <th>카테고리</th>
                <th>할인율 & 금액</th>
                <th>유효기간</th>
                <th>사용여부</th>
                <th>관리</th>
                {/* <th>발행</th> */}
              </tr>
            </thead>
            <tbody>
            {normalCouponList.length > 0 ? normalCouponList?.map((el,i) => (
              <tr key={el.coupon_seq}>
                <td>
                  <fieldset className="com_check_wrap">
                    <input type="checkbox" className="checkbox" id={`chk_0${i}`} value={el.coupon_seq} onChange={(e) => {onCheckHandler2(e, el.coupon_seq); handleCheckboxChange(e, el.coupon_seq);}} checked={checkedItems.includes(el.coupon_seq)}/>
                    <label className="btn_checkbox" htmlFor={`chk_0${i}`}>&nbsp;</label>
                  </fieldset>
                </td>
                <td>{normalCouponList?.length-i}</td>
                <td>{el.coupon_name}</td>
                <td>{el.code_name}</td>
                <td>{el.discount_rate === 0 && el.coupon_price === 0 ? "-" : el.coupon_price === 0 ? `${el.discount_rate} %` :  `${addComma(el.coupon_price)} 원`}</td>
                <td>{`${el.expired_date} 일`}</td>
                <td>
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id={`radio_y_${i}`} name={`radio_0${i}`} value="Y" checked={el.coupon_status === "Y" ? true : false} readOnly/>
                    <label className="btn_radio" htmlFor={`radio_y_${i}`} >&nbsp;</label>
                    <label htmlFor={`radio_y_${i}`} >사용</label>
                    <input type="radio" className="radio" id={`radio_n_${i}`} name={`radio_0${i}`} value="N" checked={el.coupon_status === "N" ? true : false} readOnly/>
                    <label className="btn_radio" htmlFor={`radio_n_${i}`} >&nbsp;</label>
                    <label htmlFor={`radio_n_${i}`} >미사용</label>
                  </fieldset>
                </td>
                <td>
                  <button className="com_btn txt" value={el.coupon_seq} onClick={(e) => {couponRegToggle(2); updateAutoCoupontHandler(e, el.goods_seq);}}>수정</button>
                </td>
                {/* <td>
                  <button className={"com_btn txt" + el.coupon_status === 'Y' ? "" : "red"} onClick={(e) => el.coupon_status === 'Y' ? PublishToggle(e, el.coupon_seq, el.coupon_status) : ""}>{el.coupon_status === 'Y' ? "발행" : "발행불가"}</button>
                </td> */}
              </tr>
            )) :
              <tr>
                <td colSpan="8">데이터가 없습니다.</td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>
      <div className="com_list box">
        <article className="top_area">
          <div className="info_field">
            <p className="tit">시술지급 쿠폰</p>
          </div>
          <div className="btn_field">
            <button type="button" className="com_btn m blue" onClick={(e) => {couponRegToggle(3)}}><i className="svg_icon icon_add yellow">&nbsp;</i>쿠폰 등록</button>
            <button type="button" className="com_btn m line gray" onClick={deleteProcedurePop}>선택 항목 삭제</button>
          </div>
        </article>
        <div className="scroll">
          <table className="list">
            <colgroup><col width="5%"/><col width="5%"/><col width="25%"/><col width="15%"/><col width="15%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="5%"/>
            {/* <col width="5.1%"/> */}
            </colgroup>
            <thead className="sticky top">
              <tr>
                <th>선택</th>
                <th>No.</th>
                <th>쿠폰 명</th>
                <th>카테고리</th>
                <th>시술명</th>
                <th>시술 회차</th>
                <th>유효기간</th>
                <th>사용여부</th>
                <th>관리</th>
                {/* <th>발행</th> */}
              </tr>
            </thead>
            <tbody>
            {procedureCouponList.length > 0 ? procedureCouponList.map((el,i) => (
              <tr key={i}>
                <td>
                  <fieldset className="com_check_wrap">
                    <input type="checkbox" className="checkbox" id={`chk_${i}`} value={el.coupon_seq || ""} onChange={(e) => {onCheckHandler3(e, el.coupon_seq); handleCheckboxChange(e, el.coupon_seq);}} checked={checkedItems.includes(el.coupon_seq)}/>
                    <label className="btn_checkbox" htmlFor={`chk_${i}`}>&nbsp;</label>
                  </fieldset>
                </td>
                <td>{procedureCouponList.length-i}</td>
                <td>{el.coupon_name}</td>
                <td>{el.code_name}</td>
                <td>{el.goods_name === null ? "-" : el.goods_name}</td>
                <td>{el.treatment_times === 0 ? "-" : el.treatment_times+" 회"}</td>
                <td>{`${el.expired_date} 일`}</td>
                <td>
                <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id={`radio_${i}`} name={`radio_${i}`} value="Y" checked={el.coupon_status === "Y" ? true : false} readOnly/>
                    <label className="btn_radio" htmlFor={`radio_${i}`} >&nbsp;</label>
                    <label htmlFor={`radio_${i}`} >사용</label>
                    <input type="radio" className="radio" id={`radio_${i}`} name={`radio_${i}`} value="N" checked={el.coupon_status === "N" ? true : false} readOnly/>
                    <label className="btn_radio" htmlFor={`radio_${i}`} >&nbsp;</label>
                    <label htmlFor={`radio_${i}`} >미사용</label>
                  </fieldset>
                </td>
                <td>
                  <button className="com_btn txt" value={el.coupon_seq} onClick={(e) => {couponRegToggle(3); updateAutoCoupontHandler(e, el.goods_seq);}}>수정</button>
                </td>
                {/* <td>
                <button className={"com_btn txt" + el.coupon_status === 'Y' ? "" : "red"} onClick={(e) => el.coupon_status === 'Y' ? PublishToggle(e, el.coupon_seq, el.coupon_status) : ""}>{el.coupon_status === 'Y' ? "발행" : "발행불가"}</button>
              </td> */}
              </tr>)):
              <tr> 
                <td colSpan="10">데이터가 없습니다.</td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>
		</section>
    <_CouponAuto autoCouponRegDisplayBool={autoCouponRegDisplayBool} couponRegToggle={couponRegToggle} getAutoCouponList={getAutoCouponList} popData={popData} setPopData={setPopData} onUpdateBool={onUpdateBool} setOnUpdateBool={setOnUpdateBool} getSurgeryDetail={getSurgeryDetail} categoryDetail={categoryDetail} surgeryCategoryDetail={surgeryCategoryDetail} setSurgeryCategoryDetail={setSurgeryCategoryDetail} categoryShown={categoryShown} setCategoryShown={setCategoryShown} autoCouponSeq={autoCouponSeq} alertLayerPopup={alertLayerPopup} setAlertLayerPopup={setAlertLayerPopup} closeCustomAlert={closeCustomAlert} getSurgery={getSurgery} category={category} surgeryCategory={surgeryCategory} setSurgeryCategory={setSurgeryCategory} deleteCoupon={deleteCoupon} setCheckedItems={setCheckedItems}/>
    <_CouponNormal normalCouponRegDisplayBool={normalCouponRegDisplayBool} couponRegToggle={couponRegToggle} category={category} onUpdateBool={onUpdateBool} setOnUpdateBool={setOnUpdateBool} surgeryCategory={surgeryCategory} setSurgeryCategory={setSurgeryCategory} alertLayerPopup={alertLayerPopup} setAlertLayerPopup={setAlertLayerPopup} closeCustomAlert={closeCustomAlert} popData={popData} setPopData={setPopData} autoCouponSeq={autoCouponSeq} getNormalCouponList={getNormalCouponList} deleteCoupon={deleteCoupon} setCheckedItems={setCheckedItems}/>
    <_CouponProcedure surgeryCouponRegDisplayBool={surgeryCouponRegDisplayBool} couponRegToggle={couponRegToggle} setSurgeryCategory={setSurgeryCategory} onUpdateBool={onUpdateBool} setOnUpdateBool={setOnUpdateBool} setSurgeryCategoryDetail={setSurgeryCategoryDetail} categoryShown={categoryShown} setCategoryShown={setCategoryShown} popData={popData} setPopData={setPopData} surgeryCategory={surgeryCategory} surgeryCategoryDetail={surgeryCategoryDetail} getSurgery={getSurgery} getSurgeryDetail={getSurgeryDetail} category={category} categoryDetail={categoryDetail} autoCouponSeq={autoCouponSeq} getProcedureCouponList={getProcedureCouponList} alertLayerPopup={alertLayerPopup} setAlertLayerPopup={setAlertLayerPopup} closeCustomAlert={closeCustomAlert} deleteCoupon={deleteCoupon} setCheckedItems={setCheckedItems}/>
    <_CouponAdd publishCouponRegDisplayBool={publishCouponRegDisplayBool} userList={userList} setUserList={setUserList} totalCount={totalCount} setTotalCount={setTotalCount} checkedItems={checkedItems} setCheckedItems={setCheckedItems} handleCheckboxChange={handleCheckboxChange} setPublishCouponRegDisplayBool={setPublishCouponRegDisplayBool} closeCustomAlert={closeCustomAlert} alertLayerPopup={alertLayerPopup} setAlertLayerPopup={setAlertLayerPopup} couponSeq={couponSeq} couponStatus={couponStatus}/>
		</>
	)
}

export default Coupon