import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const Graph = ({dateArray, data}) => {

  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      series: [
        ...data.map((el,i) => ({
          name: el.code_name,
          data: el.daily_count_array
        }))
      ],
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
        curve: 'straight'
      },
      colors: ['#FF1654', '#247BA0', '#bada55', '#999999', '#546E7A', '#b2d1ee', '#7E36AF', '#FF9800', '#2E93fA', '#800000', '#e2d54b', '#808000', '#c0c0c0', '#7c501a', '#f15a29', '#333333'],
      xaxis: {
        type: 'category',
        categories: dateArray,
        tickAmount: 7,
        tickPlacement: 'on',
        // min: 0,
        position: 'bottom',
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
      },
      tooltip: {
        shared: true,
        formatter: function(params) {
          let str = params[0].name + '<br>';
          for (let i = 0; i < params.length; i++) {
            str += params[i].seriesName + ': ' + params[i].data.toFixed(0) + ' 건<br>';
          }
          return str;
        }
      } 
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [dateArray, data]);

  return <div ref={chartRef} />;
};

export default Graph;
