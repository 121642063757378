//*보험료 청구
import React, { useEffect, useState, useRef } from "react";
import moment from 'moment';
import StatisticsSubDateSearch from "atoms/statistics/StatisticsSubDateSearch";
import { getTodayDate, getPreviousWeekDate, getPreviousMonthDate, getPreviousYearDate } from "../../utils/Common.utils";
import InsuranceList from "./_InsuranceList.js";//청구할 내역

const Insurance = () => {
  const today = getTodayDate()?.slice(0,17);
  const [periodOption, setPeriodOption] = useState(null);
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodValue, setPeriodValue] = useState(0);
  const [popup, setPopup] = useState({ insurance: false });

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    }else if(getValue === 2){
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    } else{
      setStartDate(getPreviousYearDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }

  const setPeriodSearchDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('4');
    setPeriodValue(1);
  }

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  }

  const onPopupClick = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: true } });
  }


	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <select className="select">
              <option>외래보험</option>
              <option>외래급여</option>
            </select>
          </article>
          <article className="com_lnb2_right">
            <StatisticsSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate}/>
          </article>
        </div>
      </section>
      <section className="com_content p_insurance">
        <div className="left_area">
          <div className="com_list box">
            <article className="top_area">
              <div className="info_field">
                <p className="tit">보험료 청구</p>
              </div>
            </article>
            <div className="scroll with_btn_bottom">
              <table className="list">
                <colgroup><col width="7.4%"/><col width="9%"/><col width="9%"/><col /><col width="9%"/><col width="9%"/><col width="9%"/><col width="9%"/><col width="9%"/><col width="9%"/><col width="9%"/></colgroup>
                <thead className="sticky top">
                  <tr>
                    <th>
                      <fieldset className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="all_chk" />
                        <label className="btn_checkbox" htmlFor="all_chk">&nbsp;</label>
                        <label htmlFor="all_chk">선택</label>
                      </fieldset>
                    </th>
                    <th>진료일</th>
                    <th>이름</th>
                    <th>주민번호</th>
                    <th>진료의</th>
                    <th>종류</th>
                    <th>건보 청구 금액</th>
                    <th>장애인 의료비</th>
                    <th>지원금</th>
                    <th>본인부담금</th>
                    <th>진료비 총액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <fieldset className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="chk1" />
                        <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                      </fieldset>
                    </td>
                    <td>2022-03-03</td>
                    <td>홍길동 (여/39)</td>
                    <td>987131-2******</td>
                    <td>김의사</td>
                    <td>보험</td>
                    <td>50,000</td>
                    <td>50,000</td>
                    <td>50,000</td>
                    <td>50,000</td>
                    <td>50,000</td>
                  </tr>
                  <tr>
                    <td>청구완료</td>
                    <td>2022-03-03</td>
                    <td>홍길동 (여/39)</td>
                    <td>987131-2******</td>
                    <td>김의사</td>
                    <td>보험</td>
                    <td>50,000</td>
                    <td>50,000</td>
                    <td>50,000</td>
                    <td>50,000</td>
                    <td>50,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="com_total_price">
            <p className="item">
              <span className="tit">건보 청구 총액</span>
              <span className="txt_blue">466,456,000</span>
            </p>
            <p className="item">
              <span className="tit">진료비 총액</span>
              <span className="txt_blue">466,456,000</span>
            </p>
          </div>
          <article className="com_btn_wrap four" style={{justifyContent: "flex-start"}}>
            <button className="com_btn l blue"><i>&nbsp;</i>선택한 항목 청구할 내역으로 이동</button>
          </article>
        </div>
        <div className="sum_area com_sec pd20">
          <p className="tit_sec">청구할 내역 요약</p>
          <article className="com_num_card">
            <p className="tit">명세서 건 수</p>
            <div className="info">
              보험료 <span className="txt_blue">22</span>건,   급여 <span className="txt_blue">12</span>건
            </div>
          </article>
          <article className="com_num_card">
            <p className="tit">청구 합계</p>
            <div className="info">
              <span className="txt_blue">41,45,120,000원</span>
              <p className="txt">(보험료 65,432,000원<span className="divisionBar">|</span>3134,000원)</p>
            </div>
          </article>
          <article className="com_num_card">
            <p className="tit">본인 부담금</p>
            <div className="info">
              <span className="txt_blue">41,45,120,000원</span>
              <p className="txt">(보험료 65,432,000원<span className="divisionBar">|</span>3134,000원)</p>
            </div>
          </article>
          <article className="com_btn_wrap two many">
            <button className="com_btn l blue"><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
            <button className="com_btn l blue line" id='insurance' onClick={() => onPopupClick('insurance')} >내용 자세히 보기</button>
          </article>
          <article className="com_btn_wrap one">
            <button className="com_btn l blue"><i className="svg_icon icon_add yellow">&nbsp;</i>선택한 항목 청구하기</button>
          </article>
        </div>
      </section>
      {popup.insurance && <InsuranceList onClose={() => { onClosePop('insurance'); }}/>}
		</>
	)
}

export default Insurance