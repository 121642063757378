//보험청구하기

import React from "react";

const Insurance = () => {
	return (
		<>
		<section className="com_content p_pay">
			<div className="top_area">
        {/* //^ 고객카드 */}
        <section className="com_sec pd20">
          <article className="com_user_box">
            <div className="color first"></div>
            <div className="pic"></div>
            <div className="btns_wrap">
              <button type="button" className="btn"><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
              <button type="button" className="btn"><i className="svg_icon icon_list yellow">&nbsp;</i></button>
            </div>
            <div className="num">123-45661</div>
            <div className="age_phone" style={{background:"#0070c0", borderColor:"#005897"}}>
              <h1 className="age">홍길동(남/32)</h1>
              <h2 className="phone">010-1234-5678</h2>
            </div>
            <button type="button" className="btn_memo com_btn m icon_vertical skyblue_bg txt_blue"><i className="svg_icon icon_write blue">&nbsp;</i>메모</button>
          </article>
          <article className="com_user_box_btns">
            <div className="btns_wrap">
              <button type="button" className="com_btn m line blue">동의서</button>
              <button type="button" className="com_btn m line blue">포인트 정보</button>
              <button type="button" className="com_btn m line blue">쿠폰 정보</button>
            </div>
            <button type="button" className="com_btn m red">예약하기</button>
          </article>
        </section>
        {/*오더*/}
        <section className="com_sec pd20 order">
          <div className="com_list">
            <p className="list_tit">2022, 08, 25 오더</p>
            <div className="scroll">
              <table className="list">
                <colgroup><col/><col width="16%"/><col width="14%"/><col width="14%"/><col width="27%"/></colgroup>
                <thead className="sticky top">
                  <tr>
                    <th>시술명</th>
                    <th>금액</th>
                    <th>구분</th>
                    <th>급여</th>
                    <th>부연설명</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tit">루카드 토닝</td>
                    <td className="num">100,000</td>
                    <td>구매</td>
                    <td>급여</td>
                    <td className="alignL">설명입력</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ul className="com_input_section payment">
            <li className="tit_field">총 금액</li>
            <li className="input_field"><span className="only_txt">670,000원</span></li>
            <li className="tit_field">- 쿠폰할인 금액</li>
            <li className="input_field">
              <input type="text" className="input" value="70,000원"/>
            </li>
            <li className="tit_field">-포인트 결제 금액</li>
            <li className="input_field">
              <input type="text" className="input" value="70,000원"/>
            </li>
            <li className="tit_field total">최종 결제 금액</li>
            <li className="input_field total only_txt">
              <span className="only_txt txt_blue">670,000원</span>
            </li>
          </ul>
          <div className="btn_area">
            <button className="com_btn blue m">결제하기</button>
          </div>
        </section>
      </div>
      <div className="com_list box">
        <div className="top_area">
          <div className="btn_field">
            <button className="com_btn gray m">결제내역</button>
            <button className="com_btn gray m">환불내역</button>
            <button className="com_btn lightpink m">미수금 : 457,000</button>
            <button className="com_btn blue m">보험청구하기</button>
          </div>
          <div className="btn_field">
            <button className="com_btn blue line m"><i className="svg_icon icon_refresh yellow">&nbsp;</i>결제 방법 변경</button>
            <button className="com_btn blue line m"><i className="svg_icon icon_money">&nbsp;</i>환불하기</button>
          </div>
        </div>
        <div className="scroll">
          <table className="list">
            <colgroup><col width="70px"/><col width="8%"/><col/><col width="6%"/><col width="6%"/><col width="6%"/><col width="6%"/><col width="6%"/><col width="6%"/><col width="6%"/><col width="6%"/><col width="6%"/><col width="6%"/><col width="6%"/></colgroup>
            <thead className="sticky top">
              <tr>
                <th>선택</th>
                <th>결제일</th>
                <th>시술명</th>
                <th>결제자</th>
                <th>현금 영수증</th>
                <th>급여</th>
                <th>비급여</th>
                <th>부가세</th>
                <th>청구액</th>
                <th>- 쿠폰할인</th>
                <th>포인트사용</th>
                <th>추가 결제금</th>
                <th>결제 방법</th>
                <th>보험 청구</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <fieldset className="com_check_wrap">
                    <input type="checkbox" className="checkbox" id="chk1" />
                    <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                  </fieldset>
                </td>
                <td>2022-06-25</td>
                <td>&nbsp;</td>
                <td>홍길동</td>
                <td><button className="com_btn txt">발행완료</button></td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td>신용카드</td>
                <td>청구완료</td>
              </tr>
              <tr>
                <td>
                  <fieldset className="com_check_wrap">
                    <input type="checkbox" className="checkbox" id="chk2" />
                    <label className="btn_checkbox" htmlFor="chk2">&nbsp;</label>
                  </fieldset>
                </td>
                <td>2022-06-25</td>
                <td>&nbsp;</td>
                <td>홍길동</td>
                <td><button className="com_btn blue s">발행하기</button></td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td>0</td>
                <td>0</td>
                <td><span className="txt_blue">0</span></td>
                <td>신용카드</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="com_total_price">
        <button className="com_btn blue l">선택한 항목 보험청구 하기</button>
        <p className="item">
          <span className="tit">실결제 총액</span>
          <span className="txt_blue">466,456,000</span>
          <span className="txt">(포인트 + 추가 결제금)</span>
        </p>
      </div>
		</section>
		</>
	)
}

export default Insurance