import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import moment from 'moment';
import { getTodayDate, sortByOrderStatus } from "../utils/Common.utils";
import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import Dashboard from "../components/dashboard/Dashboard.js";
import request from '../utils/Request.utils';
import { useSelector } from "react-redux";

const DashboardRoute = () => {
  let { pathname } = window.location
  const stateToday = useSelector(state => state.Today)
  const [loadingBarActive, setLoadingBarActive] = useState(false);
  const [receptionList, setReceptionList] = useState([]);
  const [finishList, setFinishList] = useState([]);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [startDate, setStartDate] = useState(stateToday);
  const [orderButtons, setOrderButtons] = useState([
    { text: "접수", value: "013002000", active: false, count: 0 },
    { text: "상담", value: "013003000", active: false, count: 0 },
    { text: "결제대기", value: "013004000", active: false, count: 0 },
    { text: "시술", value: "013009000", active: false, count: 0 },
    { text: "처치대기", value: "013005000", active: false, count: 0 },
    { text: "마취중", value: "013008000", active: false, count: 0 },
  ]);


  const updateOrderStatus = (order_seq, user_seq) => {
    let params = {
      order_seq: order_seq,
      order_status: "013013000", // 완료
      modi_manager_seq: managerInfo.seq,
      order_date: moment().format("YYYY-MM-DD"),
      user_seq: user_seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        getReceptionList()
      }
    }

    const failHandler = (response) => {
      console.log(response + "에러");
    }

    request.put(`/api/charts/order/dashboard`, params, successHandler, failHandler, null, null);
  }


  const loadingBarClose = async (order_seq, user_seq, result) => {

    const filteredReceptionList = result.filter((reception) => { //접수 -> 완료처리
      if (reception.chart_info.length > 0) {
        return reception.chart_info.some((chart) => chart.sub_chart_status !== "013013000");
      } else {
        return true;
      }
    });

    // setReceptionList(filteredReceptionList);


    const filteredFinishList = result.filter((finish) => finish.order_status === "013013000"); //시술 완료된 사람들 필터링

    setFinishList(filteredFinishList);


    if (user_seq) {
      let personalReception = result.filter(el => el.order_seq === order_seq && el.user_seq === user_seq).map(el => el.chart_info)[0] // order_seq 와 user_seq 모두 체크
      if (personalReception.every(el => el.sub_chart_status === "013013000")) { // 완료
        updateOrderStatus(order_seq, user_seq)
      }
    }

    const updatedOrderButtons = orderButtons.map((button, i) => {
      const count = result.filter(el => el.order_status === button.value).length;

      return { ...button, count: count }
    });

    // 대시보드 상단 버튼 active에 맞춰서 정렬
    const activeButton = updatedOrderButtons.find(button => button.active === true)
    if (activeButton) {
      setReceptionList(sortByOrderStatus(activeButton.value, activeButton.active, filteredReceptionList))
    } else {
      setReceptionList(filteredReceptionList)
    }
    setOrderButtons(updatedOrderButtons);
  }

  const getReceptionList = (order_seq, user_seq) => { // 고객 시퀀스가 있을 때는 '완료' 처리 때
    setLoadingBarActive(true) // 로딩바 띄우기

    const successHandler = (response) => {

      if (response.messageCode === 200) {
        const result = response.data.dataList

        loadingBarClose(order_seq, user_seq, result).then(() => {
          setLoadingBarActive(false) // 로딩바 없애기
        })
      } else {
        console.log("접수 고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/charts/order/dashboard2?chart_type=dash&order_date=${startDate}`, null, successHandler);
  }


  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    let authBool = false;

    authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

    if (authBool) {
      const authRoutes = [
        { path: "/", element: <Dashboard getReceptionList={getReceptionList} receptionList={receptionList} setReceptionList={setReceptionList} orderButtons={orderButtons} setOrderButtons={setOrderButtons} loadingBarActive={loadingBarActive} startDate={startDate} setStartDate={setStartDate} finishList={finishList} setFinishList={setFinishList} /> }
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/", element: <Navigate to='/main/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

  return (
    <>
      <Gnb />
      <main>
        <Lnb getReceptionList={getReceptionList} />
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
    </>
  )
}

export default DashboardRoute