//* 서류발급 > 진료비 세부 내역서
import React, { forwardRef } from "react";
import { decrypt } from "../../utils/aes256.manager";
import moment from "moment";
import { nvl } from "../../utils/Common.utils";

const MediFeeDetail = forwardRef((props, ref) => {
  const { selectedCustomer, userInfo, treatmentDateList, medicalTreatment } = props;


  return (
    <>
      <section className="com_document p_medi_fee_detail" ref={ref}>
        <h1 className="document_tit">진료비 세부 내역서</h1>
        <table className="table">
          <colgroup><col width="10%" /><col width="10%" /><col width="8%" /><col width="10%" /><col width="11%" /><col width="18%" /><col width="11%" /><col /></colgroup>
          <tr>
            <th>차트번호</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.chart_code : selectedCustomer.chart_code}</td>
            <th>성명</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.user_name : selectedCustomer.user_name}</td>
            <th>주민번호</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.jumin_1 + "-" + userInfo.jumin_2 : (decrypt(selectedCustomer?.user_jumin)?.substring(0, 6) + "-" + decrypt(selectedCustomer?.user_jumin)?.substring(6, 13))}</td>
            <th>연락처</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.user_mobile : selectedCustomer.user_mobile}</td>
          </tr>
          <tr>
            <th>주소</th>
            <td colSpan={3}>{nvl(selectedCustomer) === "" ? `${userInfo.user_address} ${userInfo.user_address_detail}`  : `${selectedCustomer.user_address} ${selectedCustomer.user_address_detail}`}</td>
            <th>내원기간</th>
            <td>{`${moment(medicalTreatment[0]?.data[0].order_date).format("YYYY-MM-DD")} ~ ${moment(medicalTreatment[medicalTreatment?.length - 1]?.data[medicalTreatment[medicalTreatment?.length - 1].data.length - 1]?.order_date).format("YYYY-MM-DD")}`}</td>
            <th>내원횟수</th>
            <td>{treatmentDateList.length}회</td>
          </tr>
        </table>
        <p className="table__top">상병명 : </p>
        <table className="table">
          <colgroup><col width="10%" /><col width="7%" /><col width="7%" /><col /><col width="8%" /><col width="5%" /><col width="5%" /><col width="5%" /><col width="8%" /><col width="8%" /><col width="8%" /></colgroup>
          <thead>
            <tr>
              <th>진료일</th>
              <th>항목</th>
              <th>코드</th>
              <th>수가명</th>
              <th>단가</th>
              <th>용량</th>
              <th>일투</th>
              <th>일수</th>
              <th>금액</th>
              <th>급여</th>
              <th>비급여</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>21-11-16</td>
              <td>기타</td>
              <td>13213</td>
              <td className="left">11/09-30 (화수목) 여성제모 1년 패키지 (브리질리언,다리전체,팔전체,얼굴전체,가슴,등,배 중 부위 택 1)</td>
              <td className="right">456,000</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td className="right">501,600</td>
              <td className="right">0</td>
              <td className="right">501,600</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={8}>소계</th>
              <td className="right">501,600</td>
              <td className="right">0</td>
              <td className="right">501,600</td>
            </tr>
          </tfoot>
        </table>
        <p className="table__top">상병명 : </p>
        <table className="table">
          <colgroup><col width="10%" /><col width="7%" /><col width="7%" /><col /><col width="8%" /><col width="5%" /><col width="5%" /><col width="5%" /><col width="8%" /><col width="8%" /><col width="8%" /></colgroup>
          <thead>
            <tr>
              <th>진료일</th>
              <th>항목</th>
              <th>코드</th>
              <th>수가명</th>
              <th>단가</th>
              <th>용량</th>
              <th>일투</th>
              <th>일수</th>
              <th>금액</th>
              <th>급여</th>
              <th>비급여</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>21-11-16</td>
              <td>기타</td>
              <td>13213</td>
              <td className="left">11/09-30 (화수목) 여성제모 1년 패키지 (브리질리언,다리전체,팔전체,얼굴전체,가슴,등,배 중 부위 택 1)</td>
              <td className="right">456,000</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td className="right">501,600</td>
              <td className="right">0</td>
              <td className="right">501,600</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={8}>소계</th>
              <td className="right">501,600</td>
              <td className="right">0</td>
              <td className="right">501,600</td>
            </tr>
          </tfoot>
        </table>
        <table className="table total">
          <colgroup><col /><col width="8%" /><col width="8%" /><col width="8%" /></colgroup>
          <tr>
            <th>합계</th>
            <td className="right">501,600</td>
            <td className="right">0</td>
            <td className="right">501,600</td>
          </tr>
        </table>
      </section>
    </>
  )
})




export default MediFeeDetail