// *관리>약품관리
// *******************작업 간 특이사항(2023-01-17)*******************
// 1. alert창, confirm창, calendar창 크기가 crm/customer 페이지에서 노출되는 크기와 다름 . 추후 타 페이지 작업 시에 확인해보고 퍼블리싱 요청 혹은 직접 조정 예정
// 2. 약품 리스트 조회 시마다 짧은 loading시간으로 깜빡임처럼 표시됨. => 2023-01-18 LoadingBar.js의 ModalOverLay 주석으로 해결

import CustomAlert from "atoms/common/CustomAlert";
import React, { useState, useLayoutEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import request from "utils/Request.utils";
import { nvl } from "../../utils/Common.utils";
import PopupCalendar from '../../atoms/common/PopupCalendar';
import DrugCompanyManage from "./_DrugCompanyManage.js"; // 제약사 관리

const Drug = () => {
  const [drugCompanyManageBool, setDrugCompanyManageBool] = useState(false); // 제약사 관리 팝업창
  const [onUpdateBool, setOnUpdateBool] = useState(false); // 수정
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })
  const [activateMode, setActivateMode] = useState("")

  // 달력 팝업창 
  const [dateType, setDateType] = useState('hyphen');
  const [viewType, setViewType] = useState('');

  const [pharmCompany, setPharmCompany] = useState([]); //제약 회사 리스트
  const [injectionMethod, setInjectionMethod] = useState([]); // 투약경로
  const [medicines, setMedicines] = useState([]) // 약품 리스트
  const [selectedMedicine, setSelectedMedicine] = useState("") // 수정 약품
  const initMedicineInfo = {
    medi_code: "", // 약품 코드
    pharm_seq: "", // 제약사 시퀀스
    medi_name: "", // 약품명
    medi_volume: 1, // 기준용량
    day_injection: 1, // 기준일투
    day_amount: 1, // 기준일수 
    injection_method: "005001000", // 투여경로
    upper_limit_price: 1, // 상한가
    use_amount: 0, // 사용횟수
    use_total_amount: 0,  // 총 사용량 (기준용량 * 사용횟수)
    in_out_flag: "I", // 원내/원외
    use_yn: "", // 사용여부
    set_flag: "N", // 세트여부
    insurance_type: "N" // 보험 가능
  }
  const [infoMedicine, setInfoMedicine] = useState(initMedicineInfo); // 약품 정보

  const [selectedCompany, setSelectedCompany] = useState(""); // 선택한 회사
  const [selectedMediSeq, setSelectedMediSeq] = useState(""); // 선택한 약품 시퀀스
  const [searchDate, setSearchDate] = useState("") // 날짜
  const [searchData, setSearchData] = useState(""); // 검색어

  // 달력 팝업창
  const popupCalendarHTML = () => {
    let retArr = [];

    if (viewType === 'from' || viewType === 'to') {
      retArr.push(
        <PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={dateType} />
      )
    } else retArr.push(null);
    return retArr;
  }

  const closePopup = () => {
    setViewType('');
  }

  const getCalendar = (value) => {
    console.log("get calendarValue : " + value + " | " + dateType + " | " + viewType)
    setSearchDate(value)
  }

  // 제약사 관리 팝업창 
  const drugCompanyManageToggle = () => {
    setDrugCompanyManageBool(!drugCompanyManageBool);
  }

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  // 제약 회사 리스트 가져오기
  const getCompany = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setPharmCompany(response.data);
      } else {
        console.log("회사 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get("/api/pharms", null, successHandler, failHandler, null, null);
  }

  //투약경로(common code) 가져오기
  const getInjectionMethod = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setInjectionMethod(response.data.dataList);
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
      // setRequestData(false);

    }

    request.get("/api/etc/commoncd/3/005000000", null, successHandler, failHandler, null, null);
  }

  // 약품 가져오기
  const getMedicines = () => {

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setMedicines(response.data);

      } else {
        console.log("약품 정보를 불러오지 못했습니다");
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get(`/api/medicine/M?searchData=${searchData}&pharm_name=${selectedCompany}&searchDate=${searchDate}`, null, successHandler, failHandler, null, null); // 2023.01.17 GET METHOD의 검색 내용 queryString으로 추가함. 확인 필요
  }

  useLayoutEffect(() => {
    getCompany() // 제약회사 호출
    getInjectionMethod() // 투약경로 호출
    getMedicines() // 약품 호출
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany])

  // 검색어 입력(약품명, 코드 검색) & 회사명
  const changeSearchData = (e) => {
    const { name, value } = e.target

    if (name === "pharm_name") {
      setSelectedCompany(value)
      console.log(value)
    } else if (name === "searchData") {
      setSearchData(value)
    }
  }
  // console.log(infoMedicine)

  // 약품 입력창
  const DrugInfoHandler = (e) => {
    const { name, value, checked } = e.target;
    console.log(name, ".//", value, "//", checked)
    if (onUpdateBool) {
      if (name === "insurance_type") {
        setSelectedMedicine({
          ...selectedMedicine, [name]: checked === true ? "I" : "N"
        })
      } else {
        setSelectedMedicine({
          ...selectedMedicine, [name]: value
        })
      }
    } else {
      if (name === "insurance_type") {
        setInfoMedicine({
          ...infoMedicine, [name]: checked === true ? "I" : "N"
        })
      } else {
        setInfoMedicine({
          ...infoMedicine, [name]: value
        })
      }

    }

  }

  // 약품 등록 버튼 클릭
  const registerMedicine = () => {
    // 약품 등록
    if (!onUpdateBool) {
      if (infoMedicine.pharm_seq === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "제약사를 선택해주세요." } })

        return;
      } else if (infoMedicine.medi_code === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "약품코드를 입력해주세요." } })

        return;
      } else if (infoMedicine.medi_name === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "약품명을 입력해주세요." } })

        return;
      } else if (infoMedicine.use_yn === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "사용여부를 체크해주세요." } })

        return;
      }

      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "약품을 등록하시겠습니까?" } })


      //약품 수정
    }
    else {
      if (selectedMedicine === infoMedicine) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "변동사항이 없습니다. 그래도 저장하시겠습니까?" } })

        setActivateMode("updateMedicine");
      } else {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "약품을 수정하시겠습니까?" } })
        setActivateMode("updateMedicine");
      }

    }

  }
  // 약품 수정 버튼 클릭
  const updateOnMedicine = (medi_seq) => {
    if (selectedMediSeq === medi_seq && onUpdateBool) {
      setOnUpdateBool(false)
      setSelectedMediSeq(0)
      setInfoMedicine(initMedicineInfo);  // 비교본
      setSelectedMedicine(""); // 수정본
    } else {
      setOnUpdateBool(true);
      setSelectedMediSeq(medi_seq)
      setInfoMedicine(medicines.filter((el) => el.medi_seq === medi_seq)[0]);  // 비교본
      setSelectedMedicine(medicines.filter((el) => el.medi_seq === medi_seq)[0]); // 수정본
    }

  }


  // 약품 삭제 버튼 클릭
  const deleteMedicine = () => {
    if (!onUpdateBool) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제할 약품을 선택해주세요." } })

      return;
    }
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "약품을 삭제하시겠습니까?" } })
    setActivateMode("deleteMedicine");
  }


  const returnAlertValue = (value) => {
    // 약품 등록
    if (nvl(value) === "OK") {
      if (!onUpdateBool) {
        let params = {
          medi_code: infoMedicine.medi_code,
          pharm_seq: Number(infoMedicine.pharm_seq),
          medi_name: infoMedicine.medi_name,
          medi_volume: infoMedicine.medi_volume,
          day_injection: infoMedicine.day_injection,
          day_amount: infoMedicine.day_amount,
          injection_method: infoMedicine.injection_method,
          upper_limit_price: infoMedicine.upper_limit_price,
          use_amount: infoMedicine.use_amount, // 사용횟수 - 
          use_total_amount: infoMedicine.medi_volume * infoMedicine.use_amount, // 총 사용량
          in_out_flag: infoMedicine.in_out_flag,
          insurance_type: infoMedicine.insurance_type,
          use_yn: infoMedicine.use_yn,
          set_flag: infoMedicine.set_flag,
          reg_manager_seq: managerInfo.seq
        }
        const successHandler = (response) => {
          console.log(response)
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "약품이 등록되었습니다." } })
            setInfoMedicine(initMedicineInfo);

            // 다시 호출
            getMedicines()
          } else {
            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "약품을 등록하지 못했습니다." } })
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/medicine", params, successHandler, failHandler, null, null);
        // 약품 수정
      }
      else if (onUpdateBool && activateMode === "updateMedicine") {
        let params = {
          // medi_code: selectedMedicine.medi_code,
          gubun: "M",
          medi_code: selectedMedicine.medi_code,
          medi_name: selectedMedicine.medi_name,
          pharm_seq: Number(selectedMedicine.pharm_seq),
          medi_volume: selectedMedicine.medi_volume,
          day_injection: selectedMedicine.day_injection,
          day_amount: selectedMedicine.day_amount,
          injection_method: selectedMedicine.injection_method,
          upper_limit_price: selectedMedicine.upper_limit_price,
          // use_amount: selectedMedicine.use_amount, // 사용횟수 
          // use_total_amount: selectedMedicine.medi_volume * selectedMedicine.use_amount, // 총 사용량
          in_out_flag: selectedMedicine.in_out_flag,
          insurance_type: selectedMedicine.insurance_type,
          use_yn: selectedMedicine.use_yn,
          set_flag: selectedMedicine.set_flag,
          modi_manager_seq: managerInfo.seq,
          medi_seq: selectedMedicine.medi_seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setOnUpdateBool(false);
            setSelectedMedicine("");
            setInfoMedicine(initMedicineInfo);


            // 다시 호출
            getMedicines()
          } else {
            console.log("약품을 수정하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.put("/api/medicine/M", params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && activateMode === "deleteMedicine") {
        let params = {
          modi_manager_seq: managerInfo.seq
        }
        const medi_seq = selectedMedicine.medi_seq

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setOnUpdateBool(false);
            setInfoMedicine(initMedicineInfo);
            setSelectedMedicine("")

            // 다시 호출
            getMedicines();
          } else {
            console.log("약품을 삭제하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.del(`/api/medicine/${medi_seq}/${params.modi_manager_seq}`, params, successHandler, failHandler, null, null);
      }
    }
  }

  return (
    <>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <select className="select" name="pharm_name" onChange={(e) => changeSearchData(e)}>
              <option value="">제약사 전체</option>
              {pharmCompany.map((company) => (
                <option key={company.pharm_seq} value={company.pharm_name}>{company.pharm_name}</option>
              ))}

            </select>
          </article>
          <article className="lnb2_search_wrap">
            <fieldset className="many">
              <input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name="searchDate" value={searchDate} onClick={() => { setViewType('from') }} readOnly />
              {popupCalendarHTML()}
              <input type="text" className="input" placeholder="약품명, 코드 검색" name="searchData" onChange={(e) => changeSearchData(e)} />
              <button className="com_btn blue m" onClick={() => getMedicines()}>조회</button>
            </fieldset>
          </article>
        </div>
      </section>

      <section className="com_content p_drug">
        <div className="com_write box">
          <table className="com_input_section">
            <colgroup><col width="11.7%" /><col width="150px" /><col /><col /><col /><col /><col /><col width="10%" /><col width="150px" /><col width="90px" /></colgroup>
            <tbody>
              <tr>
                <th className="tit_field"></th>
                <th className="tit_field">코드</th>
                <th colSpan="5" className="tit_field">약품명</th>
                <th className="tit_field">투여경로</th>
                <th className="tit_field">원내 / 원외</th>
                {onUpdateBool &&
                  <th className="btn_field_right">
                    <button className="com_btn blue line m" onClick={deleteMedicine}>약품삭제</button>
                  </th>
                }
              </tr>
              <tr>
                <td className="input_field">

                  <select className="select" name="pharm_seq" value={onUpdateBool ? selectedMedicine.pharm_seq : infoMedicine.pharm_seq ? infoMedicine.pharm_seq : ""} onChange={(e) => DrugInfoHandler(e)} >
                    <option value="" >제약사 선택</option>
                    {pharmCompany.map((company) => (
                      <option key={company.pharm_seq} value={company.pharm_seq}>{company.pharm_name}</option>
                    ))}
                  </select>


                </td>
                <td className="input_field">
                  <input type="text" className="input num" name="medi_code" maxLength={9} value={onUpdateBool ? selectedMedicine.medi_code : infoMedicine.medi_code} onChange={(e) => DrugInfoHandler(e)} />
                  {/* <button className="com_btn m line white" onChange={(e) => DrugInfoHandler(e)}>{onUpdateBool ? selectedMedicine.medi_code : infoMedicine.medi_code ? infoMedicine.medi_code : "코드 자동노출"}{!onUpdateBool && medicines.length === 0 && selectedCompany === "" && <i className="svg_icon icon_refresh" onClick={createMedicineCodeHandler}>&nbsp;</i>}</button> */}
                </td>
                <td colSpan="5" className="input_field"><input type="text" className="input" placeholder="약품명 입력" name="medi_name" value={onUpdateBool ? selectedMedicine.medi_name : infoMedicine.medi_name ? infoMedicine.medi_name : ""} onChange={(e) => DrugInfoHandler(e)} /></td>
                <td className="input_field">
                  <select className="select" name="injection_method" value={onUpdateBool ? selectedMedicine?.injection_method : infoMedicine.injection_method} onChange={(e) => DrugInfoHandler(e)}>
                    {injectionMethod.map((method) => (
                      <option key={method.common_code} value={method.common_code}>{method.code_name}</option>
                    ))}
                  </select>
                </td>
                <td className="input_field" >
                  <select className="select" name="in_out_flag" value={onUpdateBool ? selectedMedicine.in_out_flag : infoMedicine.in_out_flag ? infoMedicine.in_out_flag : ""} onChange={(e) => DrugInfoHandler(e)}>
                    <option value="I">원내</option>
                    <option value="O">원외</option>
                  </select>
                </td>
                <td rowSpan="3" className="btn_field_right"><button className="com_btn blue m" onClick={registerMedicine}>{onUpdateBool ? "약품수정" : "약품등록"}</button></td>
              </tr>
              <tr>
                <th colSpan="2" className="tit_field">&nbsp;</th>
                <th className="tit_field">상한가</th>
                <th className="tit_field">기준용량</th>
                <th className="tit_field">기준일투</th>
                <th className="tit_field">기준일수</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
              <tr>
                <td colSpan="2" className="btn_field">
                  <button className="com_btn m blue" onClick={() => drugCompanyManageToggle()}><i className="svg_icon icon_set yellow">&nbsp;</i>제약사 관리</button>
                </td>
                <td className="input_field"><input type="text" className="input num" value={onUpdateBool ? selectedMedicine.upper_limit_price : infoMedicine.upper_limit_price} name="upper_limit_price" onChange={(e) => DrugInfoHandler(e)} /></td>
                <td className="input_field"><input type="text" className="input num" value={onUpdateBool ? selectedMedicine.medi_volume : infoMedicine.medi_volume} name="medi_volume" onChange={(e) => DrugInfoHandler(e)} /></td>
                <td className="input_field"><input type="text" className="input num" value={onUpdateBool ? selectedMedicine.day_injection : infoMedicine.day_injection} name="day_injection" onChange={(e) => DrugInfoHandler(e)} /></td>
                <td className="input_field"><input type="text" className="input num" value={onUpdateBool ? selectedMedicine.day_amount : infoMedicine.day_amount} name="day_amount" onChange={(e) => DrugInfoHandler(e)} /></td>

                {/*0208 추가*/}
                <td className="input_field"><input type="checkbox" className="btn_chk" id="chk" value={onUpdateBool ? selectedMedicine.insurance_type : infoMedicine.insurance_type} name="insurance_type" checked={onUpdateBool ? selectedMedicine.insurance_type === "I" : infoMedicine.insurance_type === "I"} onChange={(e) => DrugInfoHandler(e)} /><label className="com_btn chk blue m" htmlFor="chk">보험 가능</label></td>

                <td>&nbsp;</td>
                <td className="input_field">
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="radio01" name="use_yn" value="Y" onChange={(e) => DrugInfoHandler(e)}
                      checked={onUpdateBool ? selectedMedicine.use_yn === "Y" : infoMedicine.use_yn === "Y" ? true : false} />
                    <label className="btn_radio" htmlFor="radio01" >&nbsp;</label>
                    <label htmlFor="radio01" >사용</label>
                    <input type="radio" className="radio" id="radio02" name="use_yn" value="N" onChange={(e) => DrugInfoHandler(e)}
                      checked={onUpdateBool ? selectedMedicine.use_yn === "N" : infoMedicine.use_yn === "N" ? true : false} />
                    <label className="btn_radio" htmlFor="radio02">&nbsp;</label>
                    <label htmlFor="radio02">미사용</label>
                  </fieldset>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="com_list box">
          <div className="scroll">
            <table className="list">
              <colgroup><col width="8.5%" /><col width="8.5%" /><col width="9.6%" /><col /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /><col width="5.7%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>코드</th>
                  <th>적용일</th>
                  <th>제약사명</th>
                  <th>약품명</th>
                  <th>급여</th>
                  <th>투여경로</th>
                  <th>원내/원외</th>
                  <th>상한가</th>
                  <th>기준용량</th>
                  <th>기준일투</th>
                  <th>기준일수</th>
                  <th>사용횟수</th>
                  <th>총 사용량</th>
                  <th>사용여부</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {medicines.length > 0 ?
                  medicines.map((medicine) => (
                    <tr key={medicine.medi_seq}>
                      <td>{medicine.medi_code}</td>
                      <td>{medicine.reg_date.slice(0, 10)}</td>
                      <td>{medicine.pharm_name}</td>
                      <td>{medicine.medi_name}</td>
                      <td>{medicine.insurance_type === "I" ? "급여" : "비급여"}</td>{/*0208 추가*/}
                      <td>{medicine.injection_method === "005001000" ? "내복" :
                        medicine.injection_method === "005002000" ? "외용" : "주사"}
                      </td>
                      <td>{medicine.in_out_flag === "I" ? "원내" : "원외"}</td>
                      <td>{medicine.upper_limit_price}</td>
                      <td>{medicine.medi_volume}</td>
                      <td>{medicine.day_injection}</td>
                      <td>{medicine.day_amount}</td>
                      <td>{medicine.use_amount}</td>
                      <td>{medicine.use_total_amount}</td>
                      <td>{medicine.use_yn === "Y" ? "사용" : "미사용"}</td>
                      <td>
                        <button className="com_btn txt" onClick={() => updateOnMedicine(medicine.medi_seq)}>수정</button>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan="15">데이터가 없습니다.</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <DrugCompanyManage drugCompanyManageBool={drugCompanyManageBool} drugCompanyManageToggle={drugCompanyManageToggle} getCompany={getCompany} pharmCompany={pharmCompany} />
    </>
  )
}

export default Drug