import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage/session"; // session storage
import storage from "redux-persist/lib/storage" // local storage

import Customer from "./Customer";
import Manager from "./Manager";
import OrderInfo from './OrderInfo';
import TimerInfo from './TimerInfo';
import PaymentInfo from './PaymentInfo';
import MenuManager from './MenuManager';
import Today from './Today'

const persistConfig = {
    key: "alllift_root",
    storage,
    // whitelist: ['Customer'], // storage에 저장
    // blacklist: ['any'] // 제외
};

export const rootReducer = combineReducers({ Customer, Manager, OrderInfo, TimerInfo, PaymentInfo, MenuManager, Today });

export default persistReducer(persistConfig, rootReducer);