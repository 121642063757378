import React from "react";
import parse from 'html-react-parser';

const CustomAlert = (props) => {
  const confirmClose = () => {
    props.returnValue('OK');
    props.onClose();
  }

  const cancelClose = () => {
    props.returnValue('');
    props.onClose();
  }

  return (
    <>
      <section className="com_popup type_alert active">
        <div className="pop_container">
          <button type="button" className="svg_icon btn_pop_close btnPopClose" onClick={ cancelClose }></button>
          <div className="pop_body">
            <h1 className="alert_title">{ parse(props.alertMessage) }</h1>
          </div>
          {
            props.alertType === 'confirm' ?
              <div className="pop_footer com_btn_wrap one buttonWrapper">
                <button type="button" className="com_btn m blue btnPopClose confirmButton" onClick={ confirmClose }>확인</button>
                &nbsp;&nbsp;&nbsp;
                <button type="button" className="com_btn m darkgray btnPopClose cancelButton" onClick={ cancelClose }>취소</button>
              </div>
            :
              <div className="pop_footer com_btn_wrap one buttonWrapper">
                <button type="button" className="com_btn m blue btnPopClose confirmButton" onClick={ cancelClose }>확인</button>
              </div>
          }
        </div>
      </section>
    </>
  )
}

export default CustomAlert