import React, { useState, useRef, useEffect } from "react";

import { v4 as uuidv4 } from 'uuid';
import { nvl } from "../../utils/Common.utils";
import PopupCalendar from '../common/PopupCalendar';
import CustomAlert from "atoms/common/CustomAlert";
import 'react-calendar/dist/Calendar.css'

const SubDateSearch = (props) => {
  let buttonsInfo = [
    { text: "오늘", value: 0, active:true },
    { text: "주간", value: 1, active:false },
    { text: "월간", value: 2, active:false }
  ]

  const [buttons, setButtons] = useState(buttonsInfo);
  const [dateType, setDateType] = useState('hyphen');
  const [viewType, setViewType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const buttonInit = () => {
    setButtons(buttonsInfo)
  }

  useEffect(() => {
    if(nvl(props.periodOption) === "") buttonInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.periodOption]);

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const popupCalendarHTML = () => {
    let retArr = [];

    if(viewType === 'from' || viewType === 'to') {
      retArr.push(<PopupCalendar onClose={ closePopup } getCalendar={ getCalendar } key={ uuidv4() } dateType={ dateType } />)
    } else retArr.push(null);

    return retArr;
  }

  const closePopup = () =>  {
    setViewType('');
  }

  const getCalendar = (value) => {
    // console.log("get calendarValue : " + value + " | " + dateType + " | " + viewType)
    if(viewType === 'from') setStartDate(value);
    else {
      if(value <= startDate) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시작일보다 나중인 날짜를 선택해주세요." } })

        return false;
      }

      setEndDate(value)   
    }
  }

  const clickHandler = (value) => {
    // console.log("value : " + value);

    let newSubButton = [];

    for(const button of buttons) {
      if(button.value === value) button.active = true;
      else button.active = false;

      newSubButton = [
        ...newSubButton,
        button
      ]
    }

    setStartDate('');
    setEndDate('');
    setButtons(newSubButton);

    props.searchHandler(value);
  }

  
  const periodSearchActivate = () => {
    if(startDate === "" || endDate === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "조회 기간을 모두 선택해주세요" } })

      return;
    }

    props.setPeriodSearchDate(startDate, endDate);
  }

  return (
    <>
      {
        alertLayerPopup.visible ?
          <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={alertLayerPopup.returnValue} />
        : null
      }
      <article className="com_lnb2_right">
        {/*//^ lnb2 검색 영역 */}
        <div className="lnb2_search_wrap">
          <section className="com_field type_normal">
            <fieldset className="many">
              {
                buttons.map(item => (
                  <button key={"subButton_" + uuidv4()} className={item.active ? "com_btn m blue" : "com_btn m line blue"} onClick={(e) => {clickHandler(item.value)}}>{item.text}</button>
                ))
              }
              <div className="datepicker_from_to">
                <input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name="startDate" value={startDate} onClick={() => {setViewType('from')}} readOnly />
                {popupCalendarHTML()}
                <span className="txt_between">~</span>
                <input type="text" className="input datepicker" id="to" placeholder="2023. 01. 01" name="endDate" value={endDate} onClick={() => {setViewType('to')}} readOnly />
                {popupCalendarHTML()}
              </div>
              <button type="button" className="com_btn m blue" onClick={ () => periodSearchActivate() }>조회</button>
            </fieldset>
          </section>
        </div>
      </article>
    </>
  )
}

export default SubDateSearch