//*문자 자동발송 설정
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils.js";


const SmsAuto = (props) => {
  let smsAutoManageBool = props.smsAutoManageBool;
  let autoSendType = props.autoSendType;
  const popupSectionRef = useRef();
  const nodeRef = useRef(null);


  const [procedureCategory, setProcedureCategory] = useState([])
  const [procedureList, setProcedureList] = useState([])
  const [filterProcedureList, setFilterProcedureList] = useState();


  if (smsAutoManageBool) popupSectionRef.current.classList.add('active');
  else if (!smsAutoManageBool && popupSectionRef.current) popupSectionRef.current.classList.remove('active');

  // 문자 자동발송 팝업창 닫기
  const popupClose = () => {
    props.smsAutoManageToggle()

  }

  // 시술 카테고리 가져오기
  const getProcedureCategory = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setProcedureCategory(response.data.dataList)
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get("/api/etc/commoncd/3/001000000", null, successHandler, failHandler, null, null);
  }

  // 시술 가져오기 (카테고리 선택용)
  const getProcedure = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setProcedureList(response.data)
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get("/api/goods?gubun=U", null, successHandler, failHandler, null, null);
  }
  useEffect(() => {
    if (autoSendType === "C") {
      getProcedureCategory()
      getProcedure()
    }
  }, [autoSendType])

  useEffect(() => {
    if (props.onUpdateBool) {
      setFilterProcedureList(procedureList.filter(el => el.category === props.popUpInput.goods_cate))
    }
  }, [procedureList, props.onUpdateBool, props.popUpInput.goods_cate, props.popUpInput.goods_seq])

  // 카테고리 선택
  const selectCategory = (e) => {
    const { value } = e.target;
    props.setPopUpInput({ ...props.popUpInput, goods_cate: value })
    setFilterProcedureList(procedureList.filter(el => el.category === value))
  }


  return (
    <>
      {/* //* 일반 팝업 */}
      <section ref={popupSectionRef} className="com_popup type_normal pop_sms_auto active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => popupClose()}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">문자 자동발송 설정</h1>
            </div>
            <div className="pop_body">
              <ul className="com_input_section">
                <li className="input_field full">
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="radio01" name="radio" checked={autoSendType === "R"} onChange={() => props.setAutoSendType("R")} />
                    <label className="btn_radio" htmlFor="radio01">&nbsp;</label>
                    <label htmlFor="radio01">예약</label>
                    <input type="radio" className="radio" id="radio02" name="radio" checked={autoSendType === "C"} onChange={() => props.setAutoSendType("C")} />
                    <label className="btn_radio" htmlFor="radio02">&nbsp;</label>
                    <label htmlFor="radio02">만족도 & 시술완료</label>
                  </fieldset>
                </li>
                {/* //! 예약 선택시 start */}
                {autoSendType === "R" &&
                  <>
                    <li className="tit_field line">당일</li>
                    <li className="input_field line input_wrap">
                      <input type="text" className="input" name="reservation_day" value={props.popUpInput.reservation_day || ""} onChange={(e) => props.changePopUpInput(e)} /><p className="txt">시 발송</p>
                    </li>
                    <li className="tit_field">예약</li>
                    <li className="input_field input_wrap">
                      <input type="text" className="input" name="reservation_before_1" value={props.popUpInput.reservation_before_1 || ""} onChange={(e) => props.changePopUpInput(e)} /><p className="txt">시간 전 발송</p>
                    </li>
                    <li className="tit_field">예약</li>
                    <li className="input_field input_wrap">
                      <input type="text" className="input" name="reservation_before_2" value={props.popUpInput.reservation_before_2 || ""} onChange={(e) => props.changePopUpInput(e)} /><p className="txt">시간 전 발송</p>
                    </li>
                    <li className="tit_field">예약</li>
                    <li className="input_field input_wrap">
                      <input type="text" className="input" name="reservation_before_3" value={props.popUpInput.reservation_before_3 || ""} onChange={(e) => props.changePopUpInput(e)} /><p className="txt">일 전 발송</p>
                    </li>
                  </>
                }
                {/* //!  만족도 & 시술완료 선택시 start */}
                {autoSendType === "C" && <>
                  <li className="input_field full line">
                    <select className="select" key={props.popUpInput.goods_cate} value={props.popUpInput.goods_cate} onChange={(e) => selectCategory(e)}>
                      <option value="">시술 카테고리 선택</option>
                      {procedureCategory !== null && procedureCategory.map((category, index) => (
                        <option key={category.common_code} value={category.common_code}>{category.code_name}</option>
                      ))}
                    </select>
                  </li>
                  <li className="input_field full">
                    <select className="select" name="goods_seq" key={props.popUpInput.goods_seq} value={props.popUpInput.goods_seq} onChange={(e) => props.changePopUpInput(e)}>
                      <option value="">시술 선택</option>
                      {filterProcedureList !== null && filterProcedureList?.map((procedure, index) => (
                        <option key={procedure.goods_seq} value={procedure.goods_seq}>{procedure.goods_name}</option>

                      ))}
                    </select>
                  </li>
                  <li className="tit_field">완료 후</li>
                  <li className="input_field input_wrap">
                    <input type="text" className="input" name="completion_after_1" value={props.popUpInput.completion_after_1 || ""} onChange={(e) => props.changePopUpInput(e)} /><p className="txt">분 뒤 발송</p>
                  </li>
                  <li className="tit_field">완료 후</li>
                  <li className="input_field input_wrap">
                    <input type="text" className="input" name="completion_after_2" value={props.popUpInput.completion_after_2 || ""} onChange={(e) => props.changePopUpInput(e)} /><p className="txt">일 후 발송</p>
                  </li>
                  <li className="tit_field">완료 후</li>
                  <li className="input_field input_wrap">
                    <input type="text" className="input" name="completion_after_3" value={props.popUpInput.completion_after_3 || ""} onChange={(e) => props.changePopUpInput(e)} /><p className="txt">개월 후 발송</p>
                  </li>
                </>}
              </ul>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn blue l" onClick={() => props.autoSetCheck()}>확인</button>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default SmsAuto