//*문자 발송기록
import React, { useEffect, useRef, useState } from "react";
import request from "utils/Request.utils";
import moment from "moment";
import { decrypt } from "utils/aes256.manager";

import Draggable from "react-draggable";
import { convertGender, convertJuminToAge, nvl } from "utils/Common.utils";
import SnsSendDetail from "./_SnsSendDetail";

const SnsSendList = ({ lnbSearchOptions, setLnbSearchOptions, customerPopup, setCustomerPopup }) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  const nodeRef = useRef(null);

  const [msgDetail, setMsgDetail] = useState(false);

  const [sentMessageList, setSentMessageList] = useState([]);
  const [selectMessage, setSelectMessage] = useState("")
  // console.log(customerPopup.userData)
  const getSentMessages = () => {
    const successHandler = (response) => {
      // console.log(response.data.resultData)

      if (response.messageCode === 200) {
        setSentMessageList(response.data?.resultData)
      } else console.log("없음")
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/message/sent?user_seq=${lnbSearchOptions ? lnbSearchOptions.userData.user_seq : customerPopup.userData.user_seq}&order_by=msg_seq DESC`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getSentMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 수정 / 내용보기 이동
  const moveToDetail = (msgSeq) => {
    setMsgDetail(!msgDetail)
    setSelectMessage(sentMessageList.filter(el => el.msg_seq === msgSeq)[0])
  }

  // 발송취소
  const cancelMessage = (msgSeq) => {
    const successHandler = (response) => {
      console.log(response)
      if (response.messageCode === 200) {

        getSentMessages()
      }
    }

    request.put(`/api/message/sent/C/${msgSeq}/${managerInfo.seq}`, {}, successHandler)
  }

  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_sns_send_list PopSnsSendList active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container m">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => lnbSearchOptions ? setLnbSearchOptions(prev => { return { ...prev, sent: false } }) : setCustomerPopup(prev => { return { ...prev, sent: false } })}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">문자 발송기록</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              {lnbSearchOptions ?
                <article className="com_custom_info">
                  <p className="item">{lnbSearchOptions.userData.user_name} ({convertGender(lnbSearchOptions.userData.user_gender)}/{nvl(lnbSearchOptions.userData.jumin_1) === "" ? " - " : convertJuminToAge(lnbSearchOptions.userData.jumin_1 + "-" + lnbSearchOptions.userData.jumin_2)})</p>
                  <p className="item">{nvl(lnbSearchOptions.userData.mobile_1) === "" ? "" : `${lnbSearchOptions.userData.mobile_1}-${lnbSearchOptions.userData.mobile_2}-${lnbSearchOptions.userData.mobile_3}`}</p>
                </article>
                :
                <article className="com_custom_info">
                  <p className="item">{customerPopup.userData.user_name} ({convertGender(customerPopup.userData.user_gender)}/{convertJuminToAge((decrypt(customerPopup.userData.user_jumin).substring(0, 6) + "-" + decrypt(customerPopup.userData.user_jumin).substring(6, 13)))})</p>
                  <p className="item">{`${customerPopup.userData.user_mobile}`}</p>
                </article>
              }
              <article className="send_list scroll">
                {sentMessageList !== null && sentMessageList.length > 0 ?
                  sentMessageList.map((message) => (
                    <table className="item" key={message.msg_seq}>
                      <thead>
                        <tr>
                          <th className="info_field">
                            <p className="date">{moment(message.reg_date).format("YYYY, MM, DD")}</p>
                            <p className="time">{moment(message.reg_date).format("HH : mm")}</p>
                            {message.msg_status === "R" ? <p className="state">미완료</p> : <p className="state end">완료</p>}
                          </th>
                          <th className="btn_field">
                            {message.msg_status === "R" && <button className="com_btn blue m" onClick={() => cancelMessage(message.msg_seq)}>발송취소</button>}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="tit_field">{message.msg_content}</td>
                          <td className="btn_field">
                            {message.msg_status === "R" ? <button className="com_btn white line m" onClick={() => moveToDetail(message.msg_seq)}>수정</button> : <button className="com_btn blue line m" onClick={() => moveToDetail(message.msg_seq)}>내용보기</button>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )) : null}
              </article>
            </div>
          </div>
        </Draggable>
      </section>
      {msgDetail && <SnsSendDetail onClose={() => setMsgDetail(!msgDetail)} selectMessage={selectMessage} setSelectMessage={setSelectMessage} managerInfo={managerInfo} getSentMessages={getSentMessages} />}
    </>
  )
}

export default SnsSendList