//* 결산-환자별
import React, { useEffect, useState, useRef, useCallback } from "react";
import StatisticsSubButton from "atoms/statistics/StatisticsSubButton";
import StatisticsSubDateSearch2 from "atoms/statistics/StatisticsSubDateSearch2";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { convDateToString, addComma } from "../../utils/Common.utils";
import { utils, writeFile } from 'xlsx';
import request from "utils/Request.utils";

const CalcCustomer = () => {
  const componentRef = useRef();
  const tableRef = useRef();
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(todayDate);  //검색 > 시작일
  const [endDate, setEndDate] = useState(todayDate);      //검색 > 종료일
  const [custList, setCustList] = useState([]);         //환자별 DATA

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `환자별_${todayDate}`,
  });

  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `환자별_${todayDate}.xlsx`);
  };

  //조회 > 고객별
  const getSummary = useCallback( (sDate, eDate) => {
      
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        console.log('getSummary::', response.data);
        setCustList(response?.data?.dataList);
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response);
      setCustList([]);
    }
    if(sDate !== undefined && eDate !== undefined){
      request.get(`/api/statistics/settlement/customer?start_date=${sDate}&end_date=${eDate}`, null, successHandler, failHandler, null, null);
    }

  },[]);

  useEffect(() => {
    getSummary(startDate, endDate);
  },[getSummary, startDate, endDate]);


	return (
		<>
		<section className="section com_lnb2">
			<div className="inner">
        <article className="com_lnb2_left">
          {/*//^ lnb2 버튼 영역 */}
          <div className="lnb2_menu_container">
            <StatisticsSubButton subButtonAction={subButtonAction} />
          </div>
        </article>
        <article className="com_lnb2_right">
          <StatisticsSubDateSearch2 searchHandler={getSummary} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        </article>
			</div>
		</section>
		<section className="com_content">
      <div ref={componentRef}>
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="date">{startDate === endDate ?  convDateToString(startDate) : `${convDateToString(startDate)} ~ ${convDateToString(endDate)}`}</p>
              <p className="num">매출내역</p>
            </div>
          </article>
          <div className="scroll with_btn_bottom">
            <table className="list" ref={tableRef}>
              <colgroup>
                <col/>{/*결제일*/}
                <col width="4%"/><col width="6%"/><col width="6%"/>
                <col width="3%"/><col width="3%"/>
                <col width="4%"/>{/*접수*/}
                <col width="4%"/><col width="4%"/>{/*<col width="4%"/><col width="4%"/>*/}
                <col width="4.6%"/>{/*본인부담금*/}
                <col width="4.6%"/><col width="4.6%"/><col width="4.6%"/>
                <col width="4.6%"/><col width="4.6%"/><col width="4.6%"/>
                <col width="4.6%"/>{/*환불총액*/}
                <col width="4.6%"/><col width="4.6%"/>
                </colgroup>
              <thead className="sticky top">
                <tr>
                  <th colSpan="19">환자 별 집계</th>
                </tr>
                <tr>
                  <th colSpan="2">날짜</th>
                  <th colSpan="4">고객</th>
                  <th colSpan="3">담당</th>
                  <th colSpan="7">결제</th>
                  <th colSpan="3">환불</th>
                </tr>
                <tr>
                  <td>결제일</td>
                  <td>진료일</td>
                  <td>차트번호</td>
                  <td>이름</td>
                  <td>구분</td>
                  <td>내원경로</td>
                  <td>접수</td>
                  <td>상담</td>
                  <td>진료의</td>
                  {/*
                  <td>어시스트</td>
                  <td>에스테틱</td>
                  */}
                  <td>본인부담금</td>
                  <td>비과세</td>
                  <td>과세</td>
                  <td>현금</td>
                  <td>통장</td>
                  <td>카드</td>
                  <td>수납자</td>
                  <td>환불총액</td>
                  <td>위약금</td>
                  <td>환불수단</td>
                </tr>
                <tr className="total">
                  <th>합계</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  {/*
                  <th></th>
                  <th>0</th>
                  */}
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th>0</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {custList?.length > 0 ? custList?.map((cust, index) => {
                  return(
                    <React.Fragment key={"cust" + index}>
                  <tr>
                    <td>{cust.reg_date?.slice(0,10)}</td>
                    <td>{cust.reg_date?.slice(0,10)}</td>
                    <td>{cust.chart_code}</td>
                    <td>{cust.user_name}({cust.user_gender === 'M' ? '남' : '여'}/{cust.user_age})</td>
                    <td>재진</td>
                    <td>웹</td>
                    <td>{cust.receptionist_name}</td>{/*접수*/}
                    <td>{cust.counselor_name}</td>
                    <td>{cust.incharge_name}</td>
                    {/*
                    <td>-</td>
                    <td>-</td>
                    */}
                    <td>0</td>{/*본인부담금*/}
                    <td>{addComma(cust.tax_free_total)}</td>
                    <td>{addComma(cust.taxation_total)}</td>
                    <td>{addComma(cust.payment_cash)}</td>
                    <td>{addComma(cust.payment_bank)}</td>
                    <td>{addComma(cust.payment_card)}</td>
                    <td>나길동</td>{/*수납자*/}
                    <td>{cust.refund_seq > 0 ? addComma(cust.refund_info?.refund_amt) : 0}</td>{/*환불총액*/}
                    <td>0</td>{/*위약금*/}
                    <td>현금</td>{/*환불수단*/}
                  </tr>
                  </React.Fragment>
                    )
                  }) : <tr><td colspan="19">내역이 없습니다.</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="com_btn_bottom_area">
        <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
        <button className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
      </div>
		</section>
		</>
	)
}

export default CalcCustomer