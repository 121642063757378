//* 출금계좌변경
import React, {useEffect, useRef, useState} from "react";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert";
import request from "utils/Request.utils";
import { nvl, nvlOnlyNumber, checkKoreanAlphabet, nvlNumber } from "../../utils/Common.utils";

const AccountChange = ({onClose, getBankAccount, popData, setPopData}) => {
  const nodeRef = useRef(null);
  const [bankList, setBankList] = useState([]);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [bankInfo,setBankInfo] = useState({
    bl_idx:0,
    use_name:'',
    bank_code:'0',
    bank_name:'',
    bank_account1:'',
    bank_account2:'',
    bank_account3:'',
    bank_depositor:'',
    head_office_yn:'N',
    display_yn:'Y'
  });

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

   // alert창 닫기
   const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "" } })
  }

  const onChangeAccountInfo = (e) => {
    const { name, value } = e.target;
  
    if(name === "bank_account1" || name === "bank_account2" || name === "bank_account3"){
      if(nvl(value) !== '' && !nvlOnlyNumber(value)){
        // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: ""}});
        setBankInfo({...bankInfo, [name]:''});
        return false;
      }else{
        setBankInfo((prev) => { return { ...prev, [name]: value }; });
      }
    } else if(name === "bank_depositor"){
      if (nvl(value) !== '' && !checkKoreanAlphabet(value)) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "한글 또는 영문만 입력해주세요.", activateMode: "" } })
        return false;
      } else{
        setBankInfo((prev) => { return { ...prev, [name]: value }; });
      }
    }
    if (nvlNumber(popData.bam_seq) === 0) setBankInfo((prev) => { return { ...prev, [name]: value } });
    else { //수정
      if(name === "bank_account1" || name === "bank_account2" || name === "bank_account3"){
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: ""}});
          setPopData({...popData, [name]:''});
          return false;
        }
        setPopData((prev) => { return { ...prev, [name]: value }; });
      } else if(name === "bank_depositor"){
        if (nvl(value) !== '' && !checkKoreanAlphabet(value)) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "한글 또는 영문만 입력해주세요.", activateMode: "" } })
          return false;
        } 
        setPopData((prev) => { return { ...prev, [name]: value }; });
      }
      setPopData((prev) => { return { ...prev, [name]: value }; });
    }
  };
  

  const onRegAccountValidation = () => {
    if(nvl(bankInfo.use_name) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "사용명을 입력해주세요." } })
      return;
    } else if(nvl(bankInfo.bank_code) === '0') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "은행을 선택해주세요." } })
      return;
    } else if(nvl(bankInfo.bank_account1) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "계좌번호 첫 번째 자리를 입력해주세요." } })
      return;
    } else if(nvl(bankInfo.bank_account2) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "계좌번호를 두 번째 자리를 입력해주세요." } })
      return;
    } else if(nvl(bankInfo.bank_account3) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "계좌번호를 세 번째 자리를 입력해주세요." } })
      return;
    } else if(nvl(bankInfo.bank_depositor) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "예금주를 입력해주세요." } })
      return;
    } 
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "출금계좌를 등록하시겠습니까?", activateMode: "reg"}});
  }


  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if(alertLayerPopup.activateMode === "reg"){

        let params = {
          use_name: bankInfo.use_name,
          bank_code: bankInfo.bank_code,
          bank_account: `${bankInfo.bank_account1}-${bankInfo.bank_account2}-${bankInfo.bank_account3}`,
          bank_depositor: bankInfo.bank_depositor,
          display_yn: bankInfo.display_yn,
          head_office_yn:'N',
          reg_manager_seq: managerInfo.seq
        }
  
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "출금계좌가 등록되었습니다.",activateMode:''}});
            getBankAccount();
            onClose();
          } else {
            console.log("출금계좌를 등록하지 못했습니다.")
          }
        }
  
        const failHandler = (response) => {
          console.log("에러" + response);
        }
  
        request.post("/api/bank", params, successHandler, failHandler, null, null);
      } else if(alertLayerPopup.activateMode === "update"){

        let params = {
          bam_seq: popData.bam_seq,
          use_name: popData.use_name,
          bank_code: popData.bank_code,
          bank_account: `${popData.bank_account1}-${popData.bank_account2}-${popData.bank_account3}`,
          bank_depositor: popData.bank_depositor,
          display_yn: popData.display_yn,
          modi_manager_seq: managerInfo.seq
        }
  
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            getBankAccount();
            onClose();
          } else {
            console.log("출금계좌를 수정하지 못했습니다.")
          }
        }
  
        const failHandler = (response) => {
          console.log("에러" + response);
        }
  
        request.put(`/api/bank/${params.bam_seq}`, params, successHandler, failHandler, null, null);
      } else if(alertLayerPopup.activateMode === "delete"){
  
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            getBankAccount();
            onClose();
          } else {
            console.log("출금계좌를 삭제하지 못했습니다.")
          }
        }
  
        const failHandler = (response) => {
          console.log("에러" + response);
        }
  
        request.del(`/api/bank/${popData.bam_seq}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
      }
    }
  }

  const getBankList = () => {
  
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setBankList(response.data.dataList);
      } else {
        console.log("은행 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/bank/list`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getBankList();
  }, [])

  const onUpdateAccountValidation = () => {
    if(nvl(popData.use_name) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "사용명을 입력해주세요." } })
      return;
    } else if(nvl(popData.bank_code) === '0') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "은행을 선택해주세요." } })
      return;
    } else if(nvl(popData.bank_account1) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "계좌번호 첫 번째 자리를 입력해주세요." } })
      return;
    } else if(nvl(popData.bank_account2) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "계좌번호를 두 번째 자리를 입력해주세요." } })
      return;
    } else if(nvl(popData.bank_account3) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "계좌번호를 세 번째 자리를 입력해주세요." } })
      return;
    } else if(nvl(popData.bank_depositor) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "예금주를 입력해주세요." } })
      return;
    } 
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "출금계좌를 변경하시겠습니까?", activateMode: "update"}});
  }

  const onDeleteAccount = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "출금계좌를 삭제하시겠습니까?", activateMode: "delete"}});
  }



	return (
		<>
      <section className="com_popup type_normal pop_account_change active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container l">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">출금 계좌 변경</h1>
            </div>
            <div className="pop_body">
              <div className="com_write none_round">
                <table className="write">
                  <colgroup><col width="21%"/><col/></colgroup>
                  <tbody>
                    <tr>
                      <th>사용명</th>
                      <td>
                        <input className="input" type="text" name="use_name" value={nvlNumber(popData.bam_seq) === 0 ? bankInfo.use_name : popData.use_name} placeholder="지점명과 대표자명을 정확하게 입력해주세요." onChange={(e)=>onChangeAccountInfo(e)} maxLength={50}/>
                      </td>
                    </tr>
                    <tr>
                      <th>은행명</th>
                      <td>
                      <select className="select" name="bank_code" value={nvlNumber(popData.bam_seq) === 0 ? `${bankInfo.bank_code}` : `${popData.bank_code}`} onChange={onChangeAccountInfo}>
                        <option value="0">은행선택</option>
                        {bankList.map((el, i) => (
                          <option key={`bankIdx_${i}`} value={`${el.bank_code}`}>{el.bank_name}</option>
                        ))}
                      </select>
                      </td>
                    </tr>
                    <tr>
                      <th>계좌번호</th>
                      <td>
                        <fieldset className="account_num">
                          <input className="input" type="text" name="bank_account1" value={nvlNumber(popData.bam_seq) === 0 ? bankInfo.bank_account1 : popData.bank_account1} onChange={(e)=>onChangeAccountInfo(e)} maxLength={7}/> 
                          - <input className="input" type="text" name="bank_account2" value={nvlNumber(popData.bam_seq) === 0 ? bankInfo.bank_account2 : popData.bank_account2} onChange={(e)=>onChangeAccountInfo(e)} maxLength={7}/> 
                          - <input className="input" type="text" name="bank_account3" value={nvlNumber(popData.bam_seq) === 0 ? bankInfo.bank_account3 : popData.bank_account3} onChange={(e)=>onChangeAccountInfo(e)} maxLength={7}/>
                        </fieldset>
                      </td>
                    </tr>
                    <tr>
                      <th>예금주</th>
                      <td><input className="input" type="text" placeholder="정확한 예금주 입력" name="bank_depositor" value={nvlNumber(popData.bam_seq) === 0 ? bankInfo.bank_depositor : popData.bank_depositor } onChange={(e)=>onChangeAccountInfo(e)}/></td>
                    </tr>
                    <tr>
                      <th>사용 상태</th>
                      <td>
                        <fieldset className="com_check_wrap">
                          <input type="radio" className="checkbox" id="chk1" name="display_yn" value="Y" onChange={(e)=>onChangeAccountInfo(e)} checked={nvlNumber(popData.bam_seq) === 0 ? (bankInfo.display_yn === "Y" ? true : false) : (popData.display_yn === "Y" ? true : false)}/>
                          <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                          <label htmlFor="chk1">사용</label>
                          <input type="radio" className="checkbox" id="chk2" name="display_yn" value="N" onChange={(e)=>onChangeAccountInfo(e)} checked={nvlNumber(popData.bam_seq) === 0 ? (bankInfo.display_yn === "N" ? true : false) : (popData.display_yn === "N" ? true : false)}/>
                          <label className="btn_checkbox" htmlFor="chk2">&nbsp;</label>
                          <label htmlFor="chk2">미사용</label>
                        </fieldset>  
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              {nvlNumber(popData.bam_seq) === 0 ?
                <button className="com_btn blue l" onClick={(e)=>onRegAccountValidation(e)}>등록하기</button>
                :
                <>
                  <button className="com_btn blue l" onClick={(e)=> onUpdateAccountValidation(e)}>수정</button>
                  <button className="com_btn blue l" onClick={(e)=> onDeleteAccount(e)}>삭제</button>
                </>
              }
            </div>
          </div>  
        </Draggable>
      </section>
      { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> : null }
		</>
	)
}

export default AccountChange