//* 동의서 카테고리 관리
import React, { useRef, useState, useEffect } from "react";
import Draggable from "react-draggable";
import request from '../../utils/Request.utils';
import { nvl } from "../../utils/Common.utils";
import CustomAlert from '../../atoms/common/CustomAlert';

const ConsentCategoryManage = ( { consentCategoryPopupBool, setConsentCategoryPopupBool } ) => {
  const nodeRef = useRef(null);

  const consentCategoryRef = useRef();

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [consentCategoryList, setConsentCategoryList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [activateMode, setActivateMode] = useState('');

  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const getConsentCategory = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) { // ok 코드 값
        // console.log("response : " + JSON.stringify(response));

        setConsentCategoryList(response.data.dataList);
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서 카테고리 리스트를 가져오지 못했습니다." } })
    }

    request.get("/api/etc/commoncd/2/007", null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getConsentCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = (event) => {
    // console.log(event.target.value + " | " + event.target.id)

    setSelectedValue(event.target.value)
  }

  const addConsentCategory = () => {
    let consentCategory = consentCategoryRef.current.value;

    console.log("consentCategory : " + consentCategory);

    if(nvl(consentCategory) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "카테고리 명을 입력해주십시오." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "카테고리를 추가하시겠습니까?" } })
    setActivateMode('addConsentCategory');
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const deleteConsentCategory = () => {
    if(nvl(selectedValue) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 카테고리가 없습니다." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "선택된 카테고리를 삭제하시겠습니까?" } })
    setActivateMode('deleteConsentCategory'); // 다음 실행할 함수를 호출하기 위해서
  }

  const returnAlertValue = (value) => {
    // console.log("value ==> " + value);

    if(nvl(value) === 'OK') {
      if(activateMode === 'addConsentCategory') { // 동의서 카테고리 추가
        let params = {
          parent_code: "007000000",
          code_level: "2",
          code_name: nvl(consentCategoryRef.current.value),
          code_desc: "",
          reg_manager_seq: managerInfo.seq
        }
    
        const successHandler = (response) => {
          // console.log(response);

          if(response.messageCode === 200) { // ok 코드 값
            consentCategoryRef.current.value = "";

            getConsentCategory();
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        request.post("/api/etc/commoncd", params, successHandler);
      } else if(activateMode === 'deleteConsentCategory') { // 동의서 카테고리 삭제
        const successHandler = (response) => {
          // console.log(response);

          if(response.messageCode === 200) { // ok 코드 값
            consentCategoryRef.current.value = "";
            setSelectedValue('');

            getConsentCategory();
          } else if(response.messageCode === 409) {
            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "카테고리내 동의서가 있으므로 삭제가 불가능합니다." } })
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        const failHandler = (response) => {
          console.log(response)

          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제중 에러가 발생했습니다.<br/>확인 후 다시 시도해주세요." } })
        }

        request.del(`/api/etc/commoncd/consent/${selectedValue}/${managerInfo.seq}`, null, successHandler, failHandler);
      }
    }
  }

	return (
		<>
			{/* //* 일반 팝업 */}
      <section className={ consentCategoryPopupBool ? "com_popup type_normal active" : "com_popup type_normal" } >
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={ () => setConsentCategoryPopupBool(false) }></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">동의서 카테고리 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">     
              <fieldset className="add">
                <input type="text" className="input" ref={ consentCategoryRef } />
                <button type="button" className="com_btn m blue" onClick={ addConsentCategory }>등록</button>
              </fieldset>
              <article className="list">
                <div>

                  {
                    (consentCategoryList == null || consentCategoryList.length < 1) ?
                    <ul className="item_wrap scroll">
                      <li className="item no_data">등록된 등급이 없습니다.</li>
                    </ul>
                    :
                    <ul className="item_wrap scroll">
                      {
                        consentCategoryList.map((item, index) => (
                          item.code_level > 1 ?
                            <li key={item.common_code} className="item">
                              <fieldset className="com_radio_wrap">
                                <input type="radio" className="radio" id={"consentCategory" + index} name="selectConsentCategory" value={item.common_code} //defaultChecked={index === 1}
                                  onChange={ clickHandler } checked={ item.common_code === selectedValue } />
                                <label className="btn_radio" htmlFor={"consentCategory" + index}>&nbsp;</label>
                                <label htmlFor={"consentCategory" + index}>{item.code_name}</label>
                              </fieldset>
                            </li>
                          : null
                        ))
                      }
                    </ul>
                  }

                </div>
              </article>
              <p className="tip_txt">카테고리내 작성된 동의서가 있는 경우<br/>삭제가 불가능합니다.</p>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={ deleteConsentCategory }>삭제</button>
            </div>
          </div>
        </Draggable>
      </section>
      { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> : null }
		</>
	)
}

export default ConsentCategoryManage