export const MakeAgentSpec = () => {
  /*
	var agentSpec = new Object();
	var trangubun = document.getElementById("tranGubun");	
	var phoneNum = document.getElementById("phoneNum");	
	agentSpec.inTranCode = "UC";
	agentSpec.inTradeType = document.getElementById("tradeType").value;
	agentSpec.inTranAmt = document.getElementById("txtAmount").value;
	agentSpec.inVatAmt = document.getElementById("vatAmt").value;
	agentSpec.inSvcAmt = document.getElementById("svcAmt").value;
	agentSpec.inInstallment = document.getElementById("txtPeriod").value;
	agentSpec.inOrgAuthDate = document.getElementById("orgAuthDate").value;
	agentSpec.inOrgAuthNo = document.getElementById("orgAuthNo").value;
	agentSpec.inCatTranGubun = document.getElementById("catTranGubun").value;
	agentSpec.inUnitLockYN = "Y"; //동기 Or 비동기 선택 동기:Y 비동기:N, 디폴트:N
	agentSpec.inUnitUIMode = "1"; //화면 UI 처리 On.Off 설정-비설정:0, 디폴트:1
	agentSpec.inMerchantTel = document.getElementById("hospitalInfo").value;
	
	if(phoneNum != ""){
		agentSpec.inBarCodeNumber = phoneNum.value; 
	}
	
	var agentApproval = new Object();
				
	agentApproval.KIS_ICApproval = agentSpec;
  */
  const rtnData = {
    KIS_ICApproval: {
      inTranCode: "UC",
      inTradeType: "D1",  //거래구분 [신용승인(D1)/신용용취소(D2)/현금영수증승인(CC)/현금영수증취소(CR)]
      inTranAmt: "",    //결제금액
      inVatAmt: "",     //부가세액
      inInstallment: "",  //할부 개월
      inOrgAuthDate: "",  //거래일자
      inOrgAuthNo: "",    //원 승인번호(취소시)
      inCatTranGubun: "", //현금영수증 취소 사유
      inUnitLockYN: "Y",  //동기 Or 비동기 선택 동기:Y 비동기:N, 디폴트:N
      inUnitUIMode: "1",   //화면 UI 처리 On.Off 설정-비설정:0, 디폴트:1
      inMerchantTel: "",
      inBarCodeNumber: "01022767024",  //전화번호
    }
  }
				
	//return JSON.stringify(rtnData);
	return rtnData;
}