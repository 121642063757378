import { INIT_TIMER, SET_TIMER, UPDATE_TIMER, ADD_TIMER, DEL_TIMER, CLEAR_TIMER } from './ActionType';

const timerBaseInfo = [
  { order_date: 'YYYYMMDD', user_seq: 0, order_seq: 0, sub_tc_seq: 0, value: 0, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 },
  { order_date: 'YYYYMMDD', user_seq: 0, order_seq: 0, sub_tc_seq: 0, value: 1, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 },
  { order_date: 'YYYYMMDD', user_seq: 0, order_seq: 0, sub_tc_seq: 0, value: 2, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 },
  { order_date: 'YYYYMMDD', user_seq: 0, order_seq: 0, sub_tc_seq: 0, value: 3, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 }
]

const stateTimerInit = []

const stateTimerInfo = (state = stateTimerInit, action) => {
  let { type } = action;

  switch (type) {
    case INIT_TIMER:
      if(state == null || state.lenth < 1) return []

      return state.filter(timer => timer?.order_date === action.payload.order_date);

    case SET_TIMER:
      return action.payload.data

    case ADD_TIMER:
      return [ ...state, action.payload.data ]

    case UPDATE_TIMER:
      return [ ...state, state.map((options, index) => index === action.payload.index ? { ...options, [action.payload.option]: action.payload.data } : options) ]

    case DEL_TIMER:
      if(state == null || state.lenth < 1) return []

      return state.filter(timer => timer.user_seq !== action.payload.user_seq && timer.order_seq !== action.payload.order_seq && timer.sub_tc_seq !== action.payload.sub_tc_seq && timer.value !== action.payload.value);

    case CLEAR_TIMER:
        return stateTimerInit;

    default:
      return state;
  }
}

export default stateTimerInfo;