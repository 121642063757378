//* 미수금 결제
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import CashReceipt from "./_CashReceipt";
import request from "utils/Request.utils";
import CreditCard from "../../atoms/payment/CreditCard";
import { nvl, nvlNumber } from "utils/Common.utils.js";

const Receivable = ({ onClose, popup, todayOrders, setAlertPop, setPopup, userData }) => {
  const popupSectionRef = useRef();
  const nodeRef = useRef(null);
  const cardRef = useRef();
  const cashRef = useRef();
  const bankRef = useRef();
  const numArr = [1,2,3,6,12];

  const filterVatY = todayOrders?.filter((el) => el.vat_type === 'I'); //과세
  const filterVatN = todayOrders?.filter((el) => el.vat_type === 'N'); //비과세
  const vatYTotalPrice = filterVatY?.reduce((sum, item) => sum + item.goods_price, 0); //과세 총합
  const vatNTotalPrice = filterVatN?.reduce((sum, item) => sum + item.goods_price, 0); //비과세 총합
  const stateToday = useSelector(state => state.Today);
  const stateCustomer = useSelector(state => state.Customer);
  const accountList = [{value:1, account:"농협 / 465-6542123-6541321 ㈜올리프트"}, {value:2, account:"하나 / 465-6542123-6541321 대전지점"}];
  const [currentInput, setCurrentInput] = useState('');
  const [managerList, setManagerList] = useState([]); 
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [creditCardInfo, setCreditCardInfo] = useState({
    installment: 1, // 할부개월
    totlaPrice: 0, // 총금액
    tax: 0, // 세금
    taxFreePrice: 0, // 면세금액
    serviceCharge: 0, // 봉사료
    currentDate: stateToday // 원거래 일시
  })

  const [creditCardActivate, setCreditCardActivate] = useState({
    credit: false,
    creditCancel: false,
    cash: false,
    cashCancel: false
  })

  const payInit = {
    cardPrice: '',
    payType: "1", // 1: 일시불, 2 ~ : 할부
    installments: '',
    cashPrice: '',
    received: '',
    bankPrice: '',
    bankDepositor: '', // 예금자명
    bankAccount: '',
    receiverSeq: '' // 수납자
  }

  const [payInfo, setPayInfo] = useState(payInit);

  const total2 = 100000; //2023-06-08 결제금액 임시로
  const realPay = Number(payInfo.cardPrice) + Number(payInfo.cashPrice) + Number(payInfo.bankPrice);
  const remainPay = total2 - Number(payInfo.cardPrice) - Number(payInfo.cashPrice) - Number(payInfo.bankPrice);

  const onPopupOpen = (popupName) => {
    setPopup((prev) => { return { ...prev, [popupName]: true } })
  }

  const onClosePop = (popupName) => {
    setPopup((prev) => { return { ...prev, [popupName]: false } });
  }

  const ChangeToRecipientPhoneNumber = (userList) => {
    let result = [];
    for (const i of userList) {
      const phone_number = i.phone_number.replace(/-/g, "");
      result.push({ to: phone_number });
    }
    return result
  }

  const cashReceiptHandler = (e) => {
    const { id, checked } = e.target

    if(checked) onPopupOpen(id);
    else onClosePop(id);
  }

  const sendMessage = () => {

    if(payInfo.bankAccount === ""){
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '계좌를 선택해주세요.', alertType: 'alert' } });
    }else{
      let params = {
        sendMethod: "M",
        sendType: "N",
        msgCate: "",
        msgStatus: "R",
        recipientPhoneNumber: ChangeToRecipientPhoneNumber([{phone_number: `${userData.mobile_1}-${userData.mobile_2}-${userData.mobile_3}`}]),
        subject: "올리프트 웹차트",
        content: payInfo.bankAccount,
        managerSeq: managerInfo.seq
      }

      const successHandler = (response) => {
        if (response.messageCode === 200) {
          setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '문자로 계좌번호를 전송하였습니다.', alertType: 'alert' } });
        }
      }
      const failHandler = (response) => {
        console.log("에러" + response)
      }
      request.post("/api/message/sms/send", params, successHandler, failHandler, null, null);
    }

  } 

  const onChangePayInfo = (e) => {
    const {name, value} = e.target;
    if(name === "installments"){
      if(payInfo.payType === "1"){
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '할부를 선택해주세요.', alertType: 'alert' } });
        return;
      }
    } else if(name === "payType" && value === "1"){
      setPayInfo((prev) => ({ ...prev, installments: "" }));
    }
    setCurrentInput(name);
    const changeValue = value === '0' ? '' : value;
    setPayInfo((prev) => ({ ...prev, [name]: changeValue }));
  }
  
  useEffect(() => {
    const total = Number(payInfo.cardPrice) + Number(payInfo.cashPrice) + Number(payInfo.bankPrice);
    if(total > total2){
      setPayInfo((prev) => ({ ...prev, [currentInput]: '' }));
    }
  }, [payInfo.cardPrice, payInfo.cashPrice, payInfo.bankPrice, currentInput, total2]);

  const getManager = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setManagerList(response?.data);
      } else {
        console.log("등록자 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/manager`, null, successHandler, failHandler, null, null);
  }

  const paymentActivate = () => {    
    if(nvlNumber(creditCardInfo.totlaPrice) === 0) setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '결제를 진행할 금액이 없습니다.', alertType: 'alert' } });
    else setCreditCardActivate((prev) => { return { ...prev, credit: true } }) // (true)
  }

  useEffect(() => {
    setCreditCardInfo((prev) => { return { ...prev, totlaPrice: 1004 } });

    return () => { // 다른 화면으로 이동하기 전 초기화

		}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnResult = (resultValue) => {
    console.log("결과", resultValue)


    setCreditCardActivate({ credit: false, creditCancel: false, cash: false, cashCancel: false }) // 초기화
  }


  useEffect(() => {
    getManager();
  }, [])


  return (
    <>
      <section ref={popupSectionRef} className="com_popup type_normal pop_receivable active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container xxl">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">미수금 내역</h1>
            </div>
            <div className="pop_body">
              <article className="com_list">
                <table className="list">
                  <colgroup><col width="11%" /><col /><col width="11%" /><col width="11%" /><col width="11%" /><col width="11%" /><col width="11%" /></colgroup>
                  <thead>
                    <tr>
                      <th>결제일</th>
                      <th>시술명</th>
                      <th>결제액</th>
                      <th>쿠폰할인</th>
                      <th>포인트사용</th>
                      <th>실결제액</th>
                      <th>미수액</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2023, 12, 10</td>
                      <td className="tit">루카스 토닝 외 3종</td>
                      <td className="num txt_blue">100,000</td>
                      <td className="num">10,000</td>
                      <td className="num">6,500</td>
                      <td className="num">10,000</td>
                      <td className="num txt_red">6,500</td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <ul className="com_input_section payment">
                <li className="tit_field">총 결제 금액</li>
                <li className="input_field">12,467,000</li>
                <li className="tit_field">실 결제 금액</li>
                <li className="input_field txt_blue">467,000</li>
                <li className="tit_field txt_red">미수금</li>
                <li className="input_field txt_red">566,000</li>
              </ul>
              <div className="com_input_section__sec_tit">
                <p>미수금 결제 방법 선택</p>
              </div>
              <ul className="com_input_section select_payment">
                <li className="tit_field">카드</li>
                <li className="input_field input_wrap">
                  <input type="number" className="input txt_red" ref={cardRef} name="cardPrice" value={payInfo.cardPrice} onChange={onChangePayInfo}/><span className="unit">원</span>
                  <fieldset className="com_radio_wrap" name="payType" value={payInfo.payType}>
                    <input type="radio" className="radio" name="payType" id="payType1" value="1" checked={payInfo.payType === "1"} onChange={onChangePayInfo}/>
                    <label className="btn_radio" htmlFor="payType1">&nbsp;</label>
                    <label htmlFor="payType1">일시불</label>
                    <input type="radio" className="radio" name="payType" id="payType2" value="2" checked={payInfo.payType === "2"} onChange={onChangePayInfo}/>
                    <label className="btn_radio" htmlFor="payType2">&nbsp;</label>
                    <select className="select" name="installments" value={payInfo.installments} onChange={onChangePayInfo} disabled={payInfo.payType === "2" ? false: true}>
                      <option value="">개월 선택</option>
                      {numArr.map((el,i) => (
                        <option key={i} value={el}>{el}개월</option>
                      ))}
                    </select>
                    <span className="unit">할부</span>
                  </fieldset>
                </li>
                <li className="tit_field">현금</li>
                <li className="input_field input_wrap">
                  <input type="number" className="input txt_red" ref={cashRef} name="cashPrice" value={payInfo.cashPrice} onChange={onChangePayInfo}/><span className="unit">원</span>
                  <input type="number" className="input" placeholder="받은돈" name="received" value={payInfo.received} onChange={onChangePayInfo}/>
                  <input type="number" className="input" placeholder="거스름 돈" name="exchange" value={nvl(payInfo.received) === "" ? '' : Number(payInfo.received) - Number(payInfo.cashPrice)} readOnly/>
                </li>
                <li className="tit_field">통장 입금</li>
                <li className="input_field">
                  <div className="input_wrap">
                    <input type="number" className="input txt_red" ref={bankRef} name="bankPrice" value={payInfo.bankPrice} onChange={onChangePayInfo}/><span className="unit">원</span>
                    <input type="text" className="input" placeholder="입금자명" name="bankDepositor" value={payInfo.bankDepositor} onChange={onChangePayInfo}/>
                  </div>
                  <div className="input_wrap">
                    <select className="select account" name="bankAccount" value={payInfo.bankAccount} onChange={onChangePayInfo}>
                      <option value="">입금계좌 선택</option>
                      {accountList.map((el,i)=>(
                      <option key={i} value={el.account}>{el.account}</option>
                    ))}
                    </select>
                    <button className="com_btn line blue m" onClick={sendMessage}>입금계좌 보내기</button>
                  </div>
                </li>
                <li className="tit_field">실 결제 금액</li>
                <li className="input_field txt_red"><span className="only_txt">{new Intl.NumberFormat().format(realPay)}</span></li>
                <li className="tit_field">미수금</li>
                <li className="input_field txt_red"><span className="only_txt">{new Intl.NumberFormat().format(remainPay)}</span></li>
                <li className="tit_field">수납자</li>
                <li className="input_field input_wrap">
                  <select className="select" name="receiverSeq" value={payInfo.receiverSeq} onChange={onChangePayInfo}>
                    <option value="">수납자 선택</option>
                    {managerList.map((el,i)=>(
                      <option key={i} value={el.manager_seq}>{el.manager_name}</option>
                    ))}
                  </select>
                  <input type="checkbox" className="btn_chk" id="cashReceipt3" onChange={ (e) => cashReceiptHandler(e) } /><label className="com_btn chk red m" htmlFor="cashReceipt3">현금영수증발행</label>
                </li>
              </ul>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn l blue" onClick={ paymentActivate }>결제하기</button>
            </div>
          </div>
        </Draggable>
      </section>
      {popup.cashReceipt3 && <CashReceipt onClose={() => { onClosePop('cashReceipt3') }} userData={userData}/>}
      <CreditCard creditCardInfo={ creditCardInfo } setCreditCardInfo={ setCreditCardInfo } creditCardActivate={ creditCardActivate } setCreditCardActivate={ setCreditCardActivate }
        setAlertPop={ setAlertPop } returnResult={ returnResult } />
    </>
  )
}

export default Receivable