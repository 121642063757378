//* 서류발급 > 처방전
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { nvl, nvlNumber } from "utils/Common.utils";
import { decrypt } from "utils/aes256.manager";
import request from "utils/Request.utils";

const Prescription = forwardRef((props, ref) => {
  const { selectedCustomer, userInfo, prescriptionInfo, setPrescriptionInfo, hospitalInfo, medicineSetList, setMedicineSetList } = props

  const stateToday = useSelector(state => state.Today);
  // 서류발급 프론트에서 데이터 못 보내주는 부분
  // 요양기관번호, 의료기관/팩스번호, 처방 의료인 성명/서명, 보인부담률 구분코드(입력미확실), 질병분류기호(프론트 수정 필요)

  // 주민등록번호 표기 관련
  const residentY = prescriptionInfo?.resident_no_yn === "Y";
  let finalJumin = "";
  if (nvl(selectedCustomer) === "") {
    if (residentY) {
      finalJumin = `${userInfo.jumin_1}-${userInfo.jumin_2}`;
    } else {
      finalJumin = `${userInfo.jumin_1}-${userInfo?.jumin_2?.slice(0, 1)}******`;
    }
  } else {
    if (residentY) {
      finalJumin = `${decrypt(selectedCustomer?.user_jumin)?.substring(0, 6)}-${decrypt(selectedCustomer?.user_jumin)?.substring(6, 13)}`;
    } else {
      finalJumin = `${decrypt(selectedCustomer?.user_jumin)?.substring(0, 6)}-${decrypt(selectedCustomer?.user_jumin)?.substring(6, 7)}******`;
    }
  }

  const getIssuance = (user_seq, order_date) => {
    let params = {
      user_seq: nvlNumber(user_seq) !== 0 ? user_seq : (nvl(selectedCustomer) === "" ? userInfo.user_seq : selectedCustomer.user_seq)
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const document = response.data.dataList[0];

        setPrescriptionInfo((prev) => ({
          ...prev,
          issue_seq: document?.issue_seq,
          prescription_use: document?.prescription_use,
          period_amount: document?.period_amount,
          medical_history_yn: document?.medical_history_yn,
          resident_no_yn: document?.resident_no_yn,
          including_injections_yn: document?.including_injections_yn,
          disease_code: document?.disease_code
        }))
        setMedicineSetList(
          document?.medicine_info.split("::").map(el => (
            {
              medi_seq: el.split("|")[0],
              pres_medi_seq: el.split("|")[1],
              medi_name: el.split("|")[2],
              medi_volume: el.split("|")[3],
              day_injection: el.split("|")[4],
              day_amount: el.split("|")[5],
              in_out_flag: el.split("|")[6],
              insurance_type: el.split("|")[7],
              initialPrice: el.split("|")[8],
              upper_limit_price: el.split("|")[4] * el.split("|")[5] * el.split("|")[8]
            }
          )))
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }
  
    request.get(`/api/medicine/user/prescription?user_seq=${params.user_seq}&reg_date=${moment(order_date).format("YYYY-MM-DD")}`, null, successHandler, failHandler)
  }

  useEffect(() => {
    getIssuance(userInfo.user_seq, stateToday);
  },[])
  

  return (
    <>
      <section className="com_document p_prescription" ref={ref} >
        <div className="top_area">
          <p>(약국제출용)</p>
          <h1 className="document_tit">처방전</h1>
          <p>증번호:</p>
          <div className="sec">
            <fieldset className="com_check_wrap">
              <input type="checkbox" className="checkbox" id="doctor01" checked disabled />
              <label className="btn_checkbox" htmlFor="doctor01">&nbsp;</label>
              <label htmlFor="doctor01">의료보험</label>
              <input type="checkbox" className="checkbox" id="doctor02" disabled />
              <label className="btn_checkbox" htmlFor="doctor02">&nbsp;</label>
              <label htmlFor="doctor02">의료급여</label>
              <input type="checkbox" className="checkbox" id="doctor03" disabled />
              <label className="btn_checkbox" htmlFor="doctor03">&nbsp;</label>
              <label htmlFor="doctor03">산재보험</label>
              <input type="checkbox" className="checkbox" id="doctor04" disabled />
              <label className="btn_checkbox" htmlFor="doctor04">&nbsp;</label>
              <label htmlFor="doctor04">자동차보험</label>
              <input type="checkbox" className="checkbox" id="doctor05" disabled />
              <label className="btn_checkbox" htmlFor="doctor05">&nbsp;</label>
              <label htmlFor="doctor05">기타()</label>
            </fieldset>
            <p>요양기관기호 : 34352961</p>
          </div>
        </div>
        <table className="table line">
          <colgroup><col width="6%" /><col width="14%" /><col width="35%" /><col width="6%" /><col width="10%" /><col width="" /></colgroup>
          <tbody>
            <tr>
              <th rowSpan={2} colSpan={2}>교부번호</th>
              <td rowSpan={2}>{moment(prescriptionInfo.modi_date !== null ? prescriptionInfo.reg_date : prescriptionInfo.modi_date).format("YYYY년 MM월 DD일")} 제{prescriptionInfo.issue_seq || ""}호</td>
              <th rowSpan={4}>의<br />료<br />기<br />관</th>
              <th>명칭</th>
              <td>{hospitalInfo.hospital_name}</td>
            </tr>
            <tr>
              <th>전화번호</th>
              <td>{hospitalInfo.hospital_phone}</td>
            </tr>
            <tr>
              <th rowSpan={2}>환<br />자</th>
              <th>성명</th>
              <td>{nvl(selectedCustomer) === "" ? userInfo.user_name : selectedCustomer.user_name}</td>
              <th>팩스번호</th>
              <td>042-488-5828</td>
            </tr>
            <tr>
              <th>주민등록번호</th>
              <td>{finalJumin}</td>
              <th>e-mail</th>
              <td>@</td>
            </tr>
          </tbody>
        </table>
        <table className="table line">
          <colgroup><col width="6%" /><col width="14%" /><col width="8%" /><col width="43%" /><col width="10%" /><col width="" /></colgroup>
          <tbody>
            <tr>
              {/* <th rowSpan={2}>질병분류기호</th>
              {prescriptionInfo.disease_code && prescriptionInfo.disease_code.length > 0 && prescriptionInfo.medical_history_yn === "N" ? prescriptionInfo.disease_code.map((code, index) => (
                <td key={index}>{code}</td>
              )) :
                <td></td>
              } */}
              <th rowSpan={2}>질병분류기호</th>
                <td>{prescriptionInfo.disease_code}</td>
              <th rowSpan={2}>처방 의료인 성명</th>
              <td rowSpan={2}>
                <div className="sign_field">
                  <p>홍길동</p>
                  <p className="txt">(서명 또는 날인)</p>
                </div>
              </td>
              <th>면허종별</th>
              <td>의사</td>
            </tr>
            <tr>
              <td></td>
              <th>면허번호</th>
              <td></td>
            </tr>
          </tbody>
        </table>
        <table className="table line">
          <tbody>
            <tr>
              <td>
                ※ 환자의 요구가 있을 때에는 질병분류기호를 기재하지 않습니다.
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table medicine">
          <colgroup><col width="10%" /><col /><col width="10%" /><col width="10%" /><col width="7%" /><col width="7%" /><col width="12.5%" /><col width="12.5%" /></colgroup>
          <thead>
            <tr>
              <th colSpan={2} className="big_txt"><b>처방 의약품의 명칭</b></th>
              <th className="small_txt">본인<br />부담률<br />구분코드</th>
              <th className="small_txt">1회<br />투여량</th>
              <th className="small_txt">1일<br />투여<br />횟수</th>
              <th className="small_txt">총<br />투약<br />일수</th>
              <th colSpan={2}>용법</th>
            </tr>
          </thead>
          <tbody>
            {medicineSetList?.length > 0 && medicineSetList?.map(item => (
              <tr key={item.pres_medi_seq}>
                <td colSpan={2}>{item.medi_name}</td>
                <td></td>
                <td>{item.medi_volume}</td>
                <td>{item.day_injection}</td>
                <td>{item.day_amount}</td>
                <td colSpan={2}></td>
              </tr>
            ))}
            {/* <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}></td>
            </tr> */}
            <tr>
              <th colSpan={6}>주사제 처방내역(&nbsp;&nbsp;
                <fieldset className="com_check_wrap">
                  <input type="checkbox" className="checkbox" id="shot01" checked disabled />
                  <label className="btn_checkbox" htmlFor="shot01">&nbsp;</label>
                  <label htmlFor="shot01">원내조제</label>
                  <input type="checkbox" className="checkbox" id="shot02" disabled />
                  <label className="btn_checkbox" htmlFor="shot02">&nbsp;</label>
                  <label htmlFor="shot02">원외처방</label>
                </fieldset>
                &nbsp;&nbsp;)</th>
              <th>조제시<br />참고사항</th>
              <th>본인부담<br />구분기호</th>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>사용기간</th>
              <th colSpan={2} className="big_txt">교부일로부터(&nbsp;&nbsp;{prescriptionInfo.period_amount}&nbsp;&nbsp;)일간</th>
              <th colSpan={5} className="big_txt">* 사용기간내에 약국에 제출하여야 합니다.</th>
            </tr>
          </tbody>
        </table>
        <table className="table list">
          <colgroup><col width="5%" /><col width="20%" /><col width="40%" /><col /></colgroup>
          <thead>
            <tr>
              <th colSpan={4}>의약품 조제내역</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan={4}>조제내역</th>
              <th>조제기관의 명칭</th>
              <td></td>
              <th>처방의 변경·수정·확인 그 내용 등</th>
            </tr>
            <tr>
              <th>조제약사</th>
              <td>
                <div className="sign_field">
                  <span>홍길동</span><span className="txt">(서명또는 날인)</span>
                </div>
              </td>
              <td rowSpan={3}></td>
            </tr>
            <tr>
              <th>조제량(조제일수)</th>
              <td></td>
            </tr>
            <tr>
              <th>조제년월일</th>
              <td></td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  )
})




export default Prescription