
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import request from "utils/Request.utils.js";
// import { nvl } from "utils/Common.utils.js";
import moment from "moment";
import TimeManage from "./_TimeManage.js"//시간별 예약 인원 관리 팝업
import DayList from "./_DayList.js";
import LoadingBar from '../../utils/LoadingBar';


const ReservationWeek = ({ loadingBarActive }) => {
  const [timeManageBool, setTimeManageBool] = useState(false)
  const [reserPopup, setReserPopup] = useState(false)

  // 시간별 예약인원 관리 팝업창
  const timeManageToggle = () => {
    setTimeManageBool(!timeManageBool)
  }

  // 전체 주차 구하기
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // 해당 달의 주차 구하기
  const getWeekInMonth = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const daysUntilFirstDayOfWeek = firstDayOfMonth.getDay();
    const daysUntilDate = date.getDate() + daysUntilFirstDayOfWeek - 1;
    return Math.ceil(daysUntilDate / 7);
  };

  // 주차 달력 라벨 
  const getWeekLabel = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const weekInMonth = getWeekInMonth(date);
    const weekNumber = getWeekNumber(date);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const isLastWeekOfMonth =
      weekNumber === getWeekNumber(lastDayOfMonth) && lastDayOfMonth.getDate() - date.getDate() < 6;

    if (isLastWeekOfMonth) {
      const nextMonth = date.getMonth() === 11 ? 0 : date.getMonth() + 1;
      const nextYear = date.getMonth() === 11 ? date.getFullYear() + 1 : date.getFullYear();
      return `${nextYear}년 ${nextMonth + 1}월 (1째주)`;
    }

    return `${year}년 ${month}월 (${weekInMonth}째주)`;
  };

  // 주차 시작일 가져오기
  const getWeekStartDate = (weekNumber, year) => {
    const januaryFirst = new Date(year, 0, 1);
    const daysToAdd = (weekNumber - 1) * 7;
    const daysUntilFirstDayOfWeek = januaryFirst.getDay() === 0 ? 0 : 7 - januaryFirst.getDay();
    const startDate = new Date(year, 0, 1 + daysToAdd + daysUntilFirstDayOfWeek + 1);
    return startDate;
  };

  // 주차 마지막일  가져오기
  const getWeekEndDate = (weekStartDate) => {
    const weekEndDate = new Date(weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    return weekEndDate;
  };

  // 이전 주차
  const handlePrevWeek = () => {
    const prevWeekStartDate = new Date(weekStartDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    setWeekStartDate(prevWeekStartDate);
    setWeekEndDate(getWeekEndDate(prevWeekStartDate))
  };
  // 이후 주차
  const handleNextWeek = () => {
    const nextWeekStartDate = new Date(weekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    setWeekStartDate(nextWeekStartDate);
    setWeekEndDate(getWeekEndDate(nextWeekStartDate))
  };

  const today = new Date();
  const initialWeekStartDate = getWeekStartDate(getWeekNumber(today), today.getFullYear());
  const [weekStartDate, setWeekStartDate] = useState(initialWeekStartDate);
  const [weekEndDate, setWeekEndDate] = useState(getWeekEndDate(weekStartDate))
  const weekNumber = getWeekNumber(weekStartDate);

  const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(weekStartDate.getTime() + i * 24 * 60 * 60 * 1000);
    const dayOfWeek = weekdays[date.getDay()];
    const dayOfMonth = date.getDate();
    const fullDate = `${date.getFullYear()}-${(String(date.getMonth() + 1)).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    weekDates.push({ dayOfWeek, dayOfMonth, fullDate });
  }

  // 시간 배열  가져오기
  const getTimeString = (hour) => {
    if (hour < 12) {
      return hour === 0 ? 12 : hour;
    } else {
      return hour === 12 ? 12 : hour
    }
  }

  const getTimeArray = () => {
    const times = [];
    for (let i = 10; i <= 21; i++) {
      for (let j = 0; j < 60; j += 30) {
        if (i === 21 && j === 30) {
          break;
        }
        const timeString =
          `${getTimeString(i)}:${(j === 0 ? '00' : '30')}`;
        times.push(timeString);
      }
    }
    return times;
  };

  const changeReservationTime = (time) => {
    const hours = parseInt(time.split(":")[0], 10)
    const minutes = parseInt(time.split(":")[1], 10)

    if (minutes === 0) {
      return `${hours}:00`;
    } else if (minutes <= 30) {
      return `${hours}:30`;
    } else {
      return `${hours + 1}:00`;
    }
  }

  const [reservationTime, setReservationTime] = useState(getTimeArray())
  const [reservationWeekList, setReservationWeekList] = useState([]) // 주차별 예약자 리스트
  const [reservationDateList, setReservationDateList] = useState([]) // 일일예약자리스트
  const [reservationLimit, setReservationLimit] = useState([])
  const [selectDate, setSelectDate] = useState("")




  // 주차별 예약 내역 불러오기
  const getReservationWeek = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setReservationWeekList(response.data.dataList)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/reservation?listType=F&delete_yn=N&start_date=${moment(weekStartDate).format("YYYY-MM-DD")}&end_date=${moment(weekEndDate).format("YYYY-MM-DD")}`, null, successHandler, failHandler, null, null)
  }

  // 예약인원 불러오기
  const getReservationLimit = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        let result = response.data
        let formattedData = result.map(el => ({ rmt_seq: el.rmt_seq, manage_date: moment(el.manage_date).format("YYYY-MM-DD"), manage_amount: el.manage_amount, modi_manager_seq: el.reg_manager_seq }));
        setReservationLimit(formattedData)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get("/api/reservation/manage?type=T", null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getReservationLimit()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  useEffect(() => {
    getReservationWeek()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekStartDate, weekEndDate])

  // 예약자 확인하기
  const selectDateReservation = (date, time, idx) => {
    const onDate = reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === date && changeReservationTime(el.reser_time) === time);
    setSelectDate({ date, time, idx })

    if (onDate.length > 0) {
      setReservationDateList(reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === date && changeReservationTime(el.reser_time) === time))
      setReserPopup(true)
    } else {
      setReservationDateList([])
    }

  }

  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <Link to={"/reservation"} className="com_btn m lightgray ">월간</Link>
              <Link to={"/reservation/week"} className="com_btn m  blue">주간</Link>
              <Link to={"/reservation/type"} className="com_btn m lightgray">진료분야</Link>
            </div>
          </article>
          <article className="com_lnb2_center com_select_date">
            <button className="com_btn" onClick={handlePrevWeek}><i className="svg_icon icon_prev white">&nbsp;</i></button>
            <p className="date">{getWeekLabel(weekStartDate)}</p>
            <button className="com_btn" onClick={handleNextWeek}><i className="svg_icon icon_next white">&nbsp;</i></button>
          </article>
          <article className="com_lnb2_right">
            <button className="com_btn blue m" onClick={() => timeManageToggle()}><i className="svg_icon icon_set yellow">&nbsp;</i>시간별 예약인원 관리</button>
            <button className="com_btn icon m" onClick={() => getReservationWeek()}><i className="svg_icon icon_refresh">&nbsp;</i></button>
          </article>
        </div>
      </section>
      <section className="com_content com_reservation">
        <div className="com_list box">
          <div className="scroll">
            <table className="list">
              <thead className="sticky top">
                <tr>
                  <th>시간</th>
                  {weekDates.map((date, index) => (
                    <th key={"date" + index}>
                      {`${date.dayOfMonth}(${date.dayOfWeek})`}<br />
                      ({reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === date.fullDate).length} / {reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === date.fullDate)[0]?.time_limit * 23 || reservationLimit.filter(el => el.manage_date === date.fullDate)[0]?.manage_amount * 23 || 0})
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody >
                {reservationTime !== null && reservationTime.map((reserveTime, index) => {
                  let mon = reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === weekDates[0].fullDate && reserveTime === changeReservationTime(el.reser_time))
                  let tue = reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === weekDates[1].fullDate && reserveTime === changeReservationTime(el.reser_time))
                  let wed = reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === weekDates[2].fullDate && reserveTime === changeReservationTime(el.reser_time))
                  let thu = reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === weekDates[3].fullDate && reserveTime === changeReservationTime(el.reser_time))
                  let fri = reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === weekDates[4].fullDate && reserveTime === changeReservationTime(el.reser_time))
                  let sat = reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === weekDates[5].fullDate && reserveTime === changeReservationTime(el.reser_time))
                  let sun = reservationWeekList.filter(el => moment(el.reser_date).format("YYYY-MM-DD") === weekDates[6].fullDate && reserveTime === changeReservationTime(el.reser_time))
                  return (
                    <tr key={"reser" + index}>
                      <td>{parseInt(reserveTime.split(":")[0]) < 12 ? `오전 ${reserveTime}` : parseInt(reserveTime.split(":")[0]) === 12 ? `오후 ${reserveTime}` : `오후 ${parseInt(reserveTime.split(":")[0] - 12)}:${reserveTime.split(":")[1]}`}</td>
                      <td className={selectDate.date === weekDates[0].fullDate && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(weekDates[0].fullDate, reserveTime, index)}>
                        {mon.length > 0 ? <span className={mon[0].time_limit && mon[0]?.time_limit < mon.length ? "people_over" : mon[0].time_limit && Math.ceil(mon[0]?.time_limit * 0.6) < mon.length ? "people_60per" : mon[0].time_limit && Math.ceil(mon[0]?.time_limit * 0.5) < mon.length ? "people_half" : ""}>{mon.length}명</span> : ""}
                      </td>
                      <td className={selectDate.date === weekDates[1].fullDate && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(weekDates[1].fullDate, reserveTime, index)} >
                        {tue.length > 0 ? <span className={tue[0]?.time_limit && tue[0]?.time_limit < tue.length ? "people_over" : tue[0]?.time_limit && Math.ceil(tue[0]?.time_limit * 0.6) < tue.length ? "people_60per" : tue[0]?.time_limit && Math.ceil(tue[0]?.time_limit * 0.5) < tue.length ? "people_half" : ""}>{tue.length}명</span> : ""}
                      </td>
                      <td className={selectDate.date === weekDates[2].fullDate && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(weekDates[2].fullDate, reserveTime, index)}>
                        {wed.length > 0 ? <span className={wed[0]?.time_limit && wed[0]?.time_limit < wed.length ? "people_over" : wed[0]?.time_limit && Math.ceil(wed[0]?.time_limit * 0.6) < wed.length ? "people_60per" : wed[0]?.time_limit && Math.ceil(wed[0]?.time_limit * 0.5) < wed.length ? "people_half" : ""}>{wed.length}명</span> : ""}
                      </td>
                      <td className={selectDate.date === weekDates[3].fullDate && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(weekDates[3].fullDate, reserveTime, index)}>
                        {thu.length > 0 ? <span className={thu[0]?.time_limit && thu[0]?.time_limit < thu.length ? "people_over" : thu[0]?.time_limit && Math.ceil(thu[0]?.time_limit * 0.6) < thu.length ? "people_60per" : thu[0]?.time_limit && Math.ceil(thu[0]?.time_limit * 0.5) < thu.length ? "people_half" : ""}>{thu.length}명</span> : ""}
                      </td>
                      <td className={selectDate.date === weekDates[4].fullDate && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(weekDates[4].fullDate, reserveTime, index)}>
                        {fri.length > 0 ? <span className={fri[0]?.time_limit && fri[0]?.time_limit < fri.length ? "people_over" : fri[0]?.time_limit && Math.ceil(fri[0]?.time_limit * 0.6) < fri.length ? "people_60per" : fri[0]?.time_limit && Math.ceil(fri[0]?.time_limit * 0.5) < fri.length ? "people_half" : ""}>{fri.length}명</span> : ""}
                      </td>
                      <td className={selectDate.date === weekDates[5].fullDate && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(weekDates[5].fullDate, reserveTime, index)}>
                        {sat.length > 0 ? <span className={sat[0]?.time_limit && sat[0]?.time_limit < sat.length ? "people_over" : sat[0]?.time_limit && Math.ceil(sat[0]?.time_limit * 0.6) < sat.length ? "people_60per" : sat[0]?.time_limit && Math.ceil(sat[0]?.time_limit * 0.5) < sat.length ? "people_half" : ""}>{sat.length}명</span> : ""}
                      </td>
                      <td className={selectDate.date === weekDates[6].fullDate && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(weekDates[6].fullDate, reserveTime, index)}>
                        {sun.length > 0 ? <span className={sun[0]?.time_limit && sun[0]?.time_limit < sun.length ? "people_over" : sun[0]?.time_limit && Math.ceil(sun[0]?.time_limit * 0.6) < sun.length ? "people_60per" : sun[0]?.time_limit && Math.ceil(sun[0]?.time_limit * 0.5) < sun.length ? "people_half" : ""}>{sun.length}명</span> : ""}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section >
      <TimeManage timeManageBool={timeManageBool} timeManageToggle={timeManageToggle} reservationLimit={reservationLimit} setReservationLimit={setReservationLimit} getReservationLimit={getReservationLimit} />
      {reserPopup && <DayList reservationDateList={reservationDateList} setReserPopup={setReserPopup} />}
      {loadingBarActive && <LoadingBar type={"spin"} color={"#000000"} />}
    </>
  )
}

export default ReservationWeek