//* 사진상세보기
import React, { useCallback } from "react";
//import Img from "../../assets/img/@thumb_review.jpg";

const PicDetail = ({ onClose, paginationDetail, setPagiNationDetail, addedPhotoList }) => {
  const clickLeftPage = useCallback(() => {
    let currentPage;
    setPagiNationDetail((prev) => {
      currentPage = prev.currentPage - 1 === 0 ? prev.pageCount : prev.currentPage - 1
      return {
        ...prev,
        currentPage: currentPage,
      }
    });
  }, [setPagiNationDetail]);


  const clickRightPage = useCallback(() => {
    let currentPage;
    setPagiNationDetail((prev) => {
      currentPage = prev.currentPage + 1 > prev.pageCount ? 1 : prev.currentPage + 1;
      return {
        ...prev,
        currentPage: currentPage,
      }
    });
  }, [setPagiNationDetail]);
  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_pic_detail active">{/*클래스 active추가시 노출*/}
        <div className="pop_container xl">
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
          {/* //^ 파란색 팝업 타이틀 */}
          <div className="pop_header">
            <h1 className="pop_title">{`${paginationDetail.currentPage} / ${paginationDetail.pageCount}`}</h1>
          </div>
          {/* //^ 팝업 본문 영역 */}
          <div className="pop_body">
            <article className="photo_slide">
              <button className="btn_prev" onClick={clickLeftPage}><i className="svg_icon icon_prev">&nbsp;</i></button>
              <ul className="slide">
                {
                  addedPhotoList.slice(paginationDetail.eachPageLimit * (
                    paginationDetail.currentPage - 1),
                    paginationDetail.eachPageLimit * paginationDetail.currentPage)?.map((item, index) => {
                      return (
                        <li key={index + "_img"} className="item">
                          <div className="img" style={item.file_path && { backgroundImage: `url(${process.env.REACT_APP_API_URL}/api/upload/viewer/image/album/${item.file_path.split("/")[item.file_path.split("/").length - 2]}_${item.file_path.split("/")[item.file_path.split("/").length - 1]})` }}>{/*<img src={Img}/>*/}&nbsp;</div>
                          <div className="info" >
                            <p className="tit">{item.before_after === "B" ? `전 ${paginationDetail.currentPage}` : `후 ${paginationDetail.currentPage}`}</p>
                            <p className="date">{item.reg_date}</p>
                          </div>
                        </li>);
                    })
                }
              </ul>
              <button className="btn_next" onClick={clickRightPage}><i className="svg_icon icon_next">&nbsp;</i></button>
            </article>
          </div>
        </div>
      </section>
    </>
  )
}

export default PicDetail