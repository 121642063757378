//*쿠폰 발행
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils.js";
import CustomAlert from "atoms/common/CustomAlert";
import { nvl, changeMobileFormat, convertJuminToAge } from "utils/Common.utils";


const CouponAdd = ({publishCouponRegDisplayBool, setPublishCouponRegDisplayBool, userList, setUserList, totalCount, checkedItems, setCheckedItems, handleCheckboxChange, setTotalCount, closeCustomAlert, alertLayerPopup, setAlertLayerPopup, couponStatus, couponSeq }) => {
  const [searchKeyword, setSearchKeyword] = useState(''); // 이름
  const [searchOption, setSearchOption] = useState();
  const [userSeq, setUserSeq] = useState([]);
  const [checkedList, setCheckedList] = useState([]); // 선택
  const [pubCoupon, setPubCoupon] = useState('');

  const popupSectionRef = useRef();
  const nodeRef = useRef(null);

  if(publishCouponRegDisplayBool) popupSectionRef?.current?.classList.add('active');
  else if(!publishCouponRegDisplayBool && popupSectionRef?.current) popupSectionRef?.current?.classList.remove('active');

  const popupClose = () => {
    setPublishCouponRegDisplayBool(!publishCouponRegDisplayBool);
    getUserList();
    setSearchKeyword('');
  }

  //검색창
  const UserSearchHandler = (e) => {
    const { value } = e.target;
    if(value === '') getUserList();
    setSearchKeyword(value);
  }

  //검색창 삭제
  const RemoveSearchHandler = () => {
    setSearchKeyword("");
    getUserList();
  }

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setSearchOption(value)
  }

  // 고객 리스트 가져오기
  const getUserList = () => {
  
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setUserList(response.data.dataList);
        setCheckedItems(response.data.dataList);
        setCheckedList(response.data.dataList);
        setTotalCount(response.data.totalCnt);
      } else {
        console.log("고객 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/user`, null, successHandler, failHandler, null, null);
  }

  // 고객 리스트 가져오기
  const SearchUserHandler = () => {
  
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setUserList(response.data.dataList);
        setTotalCount(response.data.totalCnt);
      } else {
        console.log("검색 고객 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    
    if(searchKeyword === ""){
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "검색할 단어를 입력해주세요."}});
      // setCheckedBool(true);
      return false;
    } 
    
    request.get(`/api/user?searchOption=${searchOption}&searchKeyword=${searchKeyword}`, null, successHandler, failHandler, null, null);
  }

  // 자동발행 삭제 체크
  const onCheckHandler = (e, user_seq) => {
    const bmode = Number(e.currentTarget.checked);
    let indexNum = checkedList.findIndex(el => el.user_seq === user_seq);
    const copyData = [...checkedList]
    copyData[indexNum] = { ...copyData[indexNum], chkItem: bmode };
    setCheckedList(copyData);
  }

  const registerCoupon = () => {
    let publishArr = checkedList.filter((el) => el.chkItem === 1).map(el=>el.user_seq);
    setPubCoupon(publishArr);

    if (nvl(publishArr).length === 0) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 것이 없습니다.", activateMode: "regPubCoupon" } })
      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage:`선택한 고객에게<br/>쿠폰을 발행 하시겠습니까?<br/>발행한 쿠폰은 취소가 불가능 합니다.`, activateMode: "regPubCoupon"}});
  } 

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      let params = {
        coupon_seq: couponSeq,
        user_seq: pubCoupon,
        coupon_status: couponStatus
      }

      const successHandler = (response) => {
        if (response.messageCode === 200) {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `쿠폰이 발행되었습니다.`, activateMode: "regPubCoupon"}});
          popupClose();
        } else {
          console.log(`쿠폰을 발행하지 못했습니다.`)
        }
      }

      const failHandler = (response) => {
        console.log("에러" + response);
      }

      request.post("/api/coupon/history", params, successHandler, failHandler, null, null);
    }
  }

  useEffect(() => (
    getUserList() // eslint-disable-next-line react-hooks/exhaustive-deps
  ),[])

	return (
		<>{alertLayerPopup.visible && (alertLayerPopup.activateMode === "regPubCoupon" || alertLayerPopup.activateMode === "updatePubCoupon") ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
      <section ref={popupSectionRef} className="com_popup type_normal pop_coupon_add">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container xl">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={popupClose}></button>
            <div className="pop_header">
              <h1 className="pop_title">쿠폰 발행</h1>
            </div>
            <div className="pop_body">
              <div className="com_field list_search">
                <fieldset className="many">
                  <select className="select" name='category' onChange={(e) => onChangeHandler(e)}>
                    <option value="">전체</option>
                    <option value="user_name">이름</option>
                    <option value="user_mobile">휴대폰번호</option>
                    <option value="chart_code">차트번호</option>
                  </select>
                  <div className="input_del_wrap">
                    <input type="text" className="input" placeholder="검색할 단어를 입력해 주세요." name="search" onChange={(e) => UserSearchHandler(e)} onKeyDown={(e) => {if (e.key === 'Enter') SearchUserHandler(e)}} value={searchKeyword}/>
                    <button type="button" className="btn_input_del" onClick={(e) => RemoveSearchHandler(e)}>&nbsp;</button>
                  </div>
                  <button className="com_btn blue m" onClick={(e) => SearchUserHandler(e)}>조회</button>
                </fieldset>
              </div>
              <article className="com_list">
                <div className="scroll">
                  <table className="list">
                    <colgroup><col width="8.6%"/><col width="8.6%"/><col width="17.1%"/><col/><col width="17.1%"/><col width="17.1%"/></colgroup>
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>선택</th>
                        <th>차트번호</th>
                        <th>예약일시</th>
                        <th>이름</th>
                        <th>휴대폰번호</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList?.length > 0 ? userList?.map((el, i) => (
                        <tr key={i}>
                          <td>{totalCount-i}</td>
                          <td>
                            <fieldset className="com_check_wrap">
                              <input type="checkbox" className="checkbox" id={`chk__${i}`} value={userSeq} onChange={(e) => {onCheckHandler(e, el.user_seq); handleCheckboxChange(e, el.user_seq);}} checked={checkedItems.includes(el.user_seq)} />
                              <label className="btn_checkbox" htmlFor={`chk__${i}`}>&nbsp;</label>
                            </fieldset>
                          </td>
                          <td>{el.chart_code}</td>
                          <td>2022,08,25  /  11:30</td>
                          <td>{changeMobileFormat(el.user_mobile)}</td>
                        </tr>
                      )) :
                      <tr>
                        <td colSpan="6">데이터가 없습니다.</td>
                      </tr>}
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn gray l" onClick={popupClose}>취소</button>
              <button className="com_btn blue l" onClick={registerCoupon}>선택항목 발행</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default CouponAdd