//* 진단서 소견서
import React, { useState } from "react";
import PopupCalendar from "atoms/common/PopupCalendar";
import { v4 as uuidv4 } from 'uuid';
import { nvl } from "utils/Common.utils";

const DocumentMediNote = ({ doctorList, selectedArray, medicalLicense, medicalCertificate, setMedicalCertificate, handleTreatmentConfirm }) => {
  const [viewType, setViewType] = useState('');
  const [checked, setChecked] = useState(false);

  const popupCalendarHTML = () => {
    let retArr = [];

    if (viewType === 'from' || viewType === 'to') {
      retArr.push(<PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={'hyphen'} />)
    } else retArr.push(null);

    return retArr;
  }

  const closePopup = () => {
    setViewType('');
  }

  const getCalendar = (value) => {
    if (viewType === 'from') setMedicalCertificate((prev) => ({ ...prev, occur_date: value }));
    else setMedicalCertificate((prev) => ({ ...prev, treatment_date: value }));
  }

  const changeDate = (e) => {
    if (e.target.checked) {
      setMedicalCertificate((prev) => ({ ...prev, occur_date: '', treatment_date: '' }));
    }
    setChecked(!checked);
  }


  return (
    <>
      <ul className="com_input_section p_document_medi_note">
        <li className="tit_field">담당의</li>
        <li className="input_field doctor_field">
          <select name='manager_seq' className="select" onChange={(e) => handleTreatmentConfirm(e, 1)} value={nvl(selectedArray[0]?.manager_seq) === "" ? medicalCertificate?.manager_seq : selectedArray[0]?.manager_seq}>
            <option value="">담당의 선택</option>
            {doctorList != null && doctorList.length > 0 ?
              doctorList.map((item) => { return (<option key={`${item.manager_seq}_0`} value={item.manager_seq}>{item.manager_name}</option>) }) : null}
          </select>
        </li>
        <li className="tit_field tit_license_number">면허번호</li>
        <li className="input_field license_number">
          <p className="number">{medicalLicense}</p>
          {/* <p>의사</p> */}
        </li>
        <li className="tit_field">발병일</li>
        <li className="input_field">
          {!checked ? (
            <>
              <input type="text" className="input datepicker" id="from" placeholder="2023-01-01" name="occur_date" value={medicalCertificate?.occur_date} onClick={() => { setViewType('from') }} readOnly />
              {popupCalendarHTML()}
            </>
          ) : (
            <input type="text" className="input datepicker" id="from" placeholder="2023-01-01" name="occur_date" value={medicalCertificate?.occur_date} onChange={(e) => handleTreatmentConfirm(e, 1)} />
          )}
        </li>
        <li className="tit_field">진단일</li>
        <li className="input_field">
          <div className="input_wrap">
            {!checked ?
              <input type="text" className="input datepicker" id="to" placeholder="2023-01-01" name="treatment_date" value={medicalCertificate?.treatment_date} onClick={() => { setViewType('to') }} readOnly /> :
              <input type="text" className="input datepicker" id="to" placeholder="2023-01-01" name="treatment_date" value={medicalCertificate?.treatment_date} onChange={(e) => handleTreatmentConfirm(e, 1)} />}
            <div className="com_check_wrap">
              <input type="checkbox" className="checkbox" id="type11" onChange={changeDate} />
              <label className="btn_checkbox" htmlFor="type11">&nbsp;</label>
              <label htmlFor="type11">직접입력</label>
            </div>
          </div>
        </li>
        <li className="tit_field">질병 코드</li>
        <li className="input_field">
          <div className="com_list">
            <table className="list">
              <colgroup><col width='200px' /><col /></colgroup>
              <thead>
                <tr>
                  <th>질병코드</th>
                  <th>질병명</th>
                </tr>
              </thead>
              <tbody>
                {selectedArray[0]?.disease_code?.length > 0 ? selectedArray[0]?.disease_code?.map((code, index) => (
                  <tr key={index}>
                    <td>
                      {code}<br />
                    </td>
                    <td className="tit">
                      {selectedArray[0]?.disease_name[index]}<br />
                    </td>
                  </tr>
                )) :
                  <tr>
                    <td>
                      <br />
                    </td>
                    <td className="tit">
                      <br />
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </li>
        <li className="tit_field">진단유형</li>
        <li className="input_field">
          <fieldset className="com_radio_wrap">
            <input type="radio" className="radio" id="type1" name="diagnosis_type" value="017001000" onChange={(e) => handleTreatmentConfirm(e, 1)} checked={medicalCertificate.diagnosis_type === "017001000"} />
            <label className="btn_radio" htmlFor="type1">&nbsp;</label>
            <label htmlFor="type1">임상추적형</label>
            <input type="radio" className="radio" id="type2" name="diagnosis_type" value="017002000" onChange={(e) => handleTreatmentConfirm(e, 1)} checked={medicalCertificate.diagnosis_type === "017002000"} />
            <label className="btn_radio" htmlFor="type2">&nbsp;</label>
            <label htmlFor="type2">최종진단</label>
          </fieldset>
        </li>
        <li className="tit_field">진료내역</li>
        <li className="input_field">
          <textarea className="textarea" name="treatment_detail" value={medicalCertificate.treatment_detail} onChange={(e) => handleTreatmentConfirm(e, 1)} style={{ resize: "none" }}></textarea>
        </li>
        <li className="tit_field">비고</li>
        <li className="input_field">
          <input type="text" className="input" name="paper_remark" value={medicalCertificate.paper_remark} onChange={(e) => handleTreatmentConfirm(e, 1)} />
        </li>
      </ul>
    </>
  )
}

export default DocumentMediNote