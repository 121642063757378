import { SET_ORDER_INFO, CLEAR_ORDER_INFO } from './ActionType';

const stateOrderInfoInit = {
  order_seq: null,
  user_seq: null,
  order_date: null,
  order_status: null,
  reser_seq: null,
  reser_info_status: null,
  reg_date: null,
  reg_manager_seq: null,
  modi_date: null,
  modi_manager_seq: null,
  // 구매 상품 정보
  selected_goods_list: [],
  coupon_history_seq: null,
  point_his_seq: null,
  use_point: 0,
  total_price: 0,
}

const stateOrderInfo = (state = stateOrderInfoInit, action) => {
  let { type } = action;

  switch (type) {
    case SET_ORDER_INFO:
      let newState = {
        ...state,
        [action.payload.fieldName]: action.payload.data
      }

      return newState;

    case CLEAR_ORDER_INFO:
      return stateOrderInfoInit;

    default:
      return state;
  }
}

export default stateOrderInfo;