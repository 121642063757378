import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import CrmDay from "../components/crm/Day.js";
import CrmFile from "../components/crm/File.js";
import Satisfaction from "../components/crm/Satisfaction.js";
import Complain from "../components/crm/Complain.js";
import ComplainChart from "../components/crm/ComplainChart.js";
import Customer from "../components/crm/Customer.js";
import CustomerView from "../components/crm/CustomerVeiw.js";
import request from '../utils/Request.utils';

const CrmRoute = () => {
  let { pathname } = window.location

  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    let authBool = false;

    authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

    if(authBool) {
      const authRoutes = [
        { path: "/day", element: <CrmDay /> }, // 날짜별
        { path: "/file", element: <CrmFile /> }, // 파일별
        { path: "/satisfaction", element: <Satisfaction /> }, // 고객만족도
        { path: "/complain", element: <Complain /> }, // 컴플레인
        { path: "/complain_chart", element: <ComplainChart /> }, // 컴플레인통계
        { path: "/customer", element: <Customer /> }, // 고객관리>list
        { path: "/customer_view", element: <CustomerView /> } // 고객관리>view
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/*", element: <Navigate to='/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

  return (
    <>
      <Gnb />
      <main>
        <Lnb />
        <Routes> { AuthorizationRoutes() } </Routes>
      </main>
    </>
  )
}

export default CrmRoute