//* 공지사항 - 등록 - 완료알림 팝업
import React from "react";

const AlertNoticeWriteOk = (props) => {
  const alertClose = () => {
    props.onClose();
  }

  // console.log(props.popupVisible)

	return (
		<>
			{/* //* 알림 팝업 active */}
      <section className={ props.popupVisible ? "com_popup type_alert active" : "com_popup type_alert" }>
        <div className="pop_container">
          <button type="button" className="svg_icon btn_pop_close btnPopClose" onClick={ alertClose }></button>
          {/* //^ 알림 디자인 본문 영역 */}
          <div className="pop_body">
            <h1 className="alert_title">등록 되었습니다.</h1>
          </div>
          {/* //^ 알림 디자인 버튼 영역 */}
          <div className="pop_footer com_btn_wrap one">
            <button type="button" className="com_btn m blue btnPopClose" onClick={ alertClose }>확인</button>
          </div>
        </div>
      </section>
		</>
	)
}

export default AlertNoticeWriteOk