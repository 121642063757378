const setItem = (category, fieldName, data) => {
  let localStorageItem = window.localStorage.getItem(category);
  let localStorageItemJson = JSON.parse(localStorageItem ? localStorageItem : '{}');

  localStorageItemJson[fieldName] = data;
  
  window.localStorage.setItem(category, JSON.stringify(localStorageItemJson));
}

const getItem = (category) => {
  return window.localStorage.getItem(category);
}

const getItemJsonParse = (category) => {
  return JSON.parse(getItem(category));
}

const clearItem = (category) => {
  window.localStorage.setItem(category, '{}');
}

const clearAll = () => {
  window.localStorage.clear();
}

const localStorage = {
  setItem,
  getItem,
  getItemJsonParse,
  clearItem,
  clearAll
}

export default localStorage;