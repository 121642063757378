import CustomAlert from "atoms/common/CustomAlert";
import React, { useCallback, useRef, useEffect, useState } from "react";
import request from "../../utils/Request.utils";

const ScreenList = ({ displayScreen, setDisplayScreen }) => {
  const popUpName = {
    customer_display_yn: '고객정보',
    todaychart_display_yn: '오늘의 차트',
    cust_request_display_yn: '요청사항',
    photo_disaplay_yn: '사진',
    goods_search_disaplay_yn: '시술검색',
    visit_display_yn: '내원 기록',
    today_order_display_yn: '당일 오더',
    treatment_display_yn: '잔여시술'
  }

  return (
    <tbody>
      {
        Object.keys(displayScreen).map((key) => (
          <tr key={ key }>
            <td>{ popUpName[key] }</td>
            <td key={ key }>
              <fieldset className="com_radio_wrap" key={ key }>
                <input type="radio" className="radio" id={ key + 'Y' } name={ key } checked={ displayScreen[key] === 'Y' }
                  onChange={() => { setDisplayScreen((prev) => { return { ...prev, [key]: 'Y' } }) }} />
                <label className="btn_radio" htmlFor={ key + 'Y' }>&nbsp;</label>
                <label htmlFor={ key + 'Y' }>노출</label>
                <input type="radio" className="radio" id={ key + 'N' } name={ key } checked={displayScreen[key] === 'N'}
                  onChange={() => { setDisplayScreen((prev) => { return { ...prev, [key]: 'N' } }) }} />
                <label className="btn_radio" htmlFor={key + 'N'}>&nbsp;</label>
                <label htmlFor={key + 'N'}>미노출</label>
              </fieldset>
            </td>
          </tr>
        ))
      }
    </tbody>
  )
}

const ChartSetting = ({ displayScreen, setDisplayScreen, setChartSetting }) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const initialChartDisplay = useRef({}); // 차트 화면설정 팝업 상태 (수정 전)

  const [alertPop, setAlertPop] = useState({ 
    visible: false,
    alertMessage: '차트 화면설정이 변경 됐습니다.',
    alertType: 'alert',
    returnValue: () => { }
  });

  const closeCustomAlert = (() => { 
    setAlertPop((prev) => { return { ...prev, visible: false } })
  });

  const onSaveChartSetting = (() => {
    // console.log(initialChartDisplay.current)

    const checkDisplayStatus = () => { // 차트 화면설정 수정 여부
      for (let item in initialChartDisplay.current) {
        if (displayScreen[item] !== initialChartDisplay.current[item]) return true;
      }
    }

    const successHandler = (response) => {
      // console.log(response)

      if(response.messageCode === 200) setAlertPop((prev) => { return { ...prev, visible: true} });
      else console.debug("차트 화면설정을 병경하던 중 에러가 발생했습니다.");
    }

    if (checkDisplayStatus()) {
      request.put(`/api/charts/setting/${managerInfo.seq}`, { ...displayScreen }, successHandler);
    }
    
    setChartSetting(false);
  });

  useEffect(() => {
    initialChartDisplay.current = displayScreen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_chart_setting active">{/*클래스 active추가시 노출*/}
        <div className="pop_container m">
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { setChartSetting(false) }}></button>
          {/* //^ 파란색 팝업 타이틀 */}
          <div className="pop_header">
            <h1 className="pop_title">차트 화면설정</h1>
          </div>
          {/* //^ 팝업 본문 영역 */}
          <div className="pop_body">
            <article className="com_list">
              <table className="list">
                <thead>
                  <tr>
                    <th>화면명</th>
                    <th>노출여부</th>
                  </tr>
                </thead>
                <ScreenList displayScreen={ displayScreen } setDisplayScreen={ setDisplayScreen } />
              </table>
            </article>
          </div>
          <div className="pop_footer com_btn_wrap">
            <button type="button" className="com_btn l blue" onClick={ onSaveChartSetting }>확인</button>
          </div>
        </div>
      </section>
      { alertPop.visible &&
        <CustomAlert onClose={ closeCustomAlert } alertType={ alertPop.alertType } alertMessage={ alertPop.alertMessage } returnValue={ alertPop.returnValue } /> }
    </>
  )
}

export default ChartSetting