import React, { useState } from "react";
import request from "utils/Request.utils";

const HandoverCheck = ({ onClose, handOverList, setAlertPop }) => {
	const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

	const onHandOverClick = () => {
		const failHandler = (response) => {
			console.error("Error >>  " + response);
			setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '시술 양도하기 실패.', alertType: 'alert' } });
			onClose();
		};

		const successHandler = (response) => {
			if (response.messageCode === 200) {
				setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '시술 양도하기 완료.', alertType: 'alert' } });
				onClose();
			} else {
				console.debug('Error >> ', response);
			}
		}

		const params = {
			user_seq: handOverList.customer,
			treatments: handOverList.treatments
		}

		request.post(`/api/charts/sub/hand-over`, params, successHandler, failHandler, null, null);
		setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '시술 양도하기 완료.', alertType: 'alert' } });
		onClose();
	}

	return (
		<section className="com_popup type_alert active">{/*클래스 active 추가시 노출*/}
			<div className="pop_container">
				<button type="button" className="svg_icon btn_pop_close btnPopClose" onClick={() => { onClose(); }}></button>
				{/* //^ 알림 디자인 본문 영역 */}
				<div className="pop_body">
					<p className="alert_text">해당 고객님께 잔여 시술을 <br />양도하시겠습니까?</p>
				</div>
				{/* //^ 알림 디자인 버튼 영역 */}
				<div className="pop_footer com_btn_wrap two many">
					<button type="button" className="com_btn m gray btnPopClose" onClick={() => { onClose(); }}>취소</button>
					<button type="button" className="com_btn m blue" onClick={onHandOverClick}>양도</button>
				</div>
			</div>
		</section>
	)
}

export default HandoverCheck