import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import Footer from '../layout/Footer.js';
import Main from "../components/main/Main.js";//메인
import Login from "../components/main/Login.js";//로그인
import MyOwner from "../components/main/MyOwner.js";//마이페이지 점주
import MyNormal from "../components/main/MyNormal.js";//마이페이지 일반
import ChangePw from "../components/main/ChangePw.js";//비밀번호 변경
import Styles from "../components/main/Styles.js";
import request from '../utils/Request.utils';

const MainRoute = () => {
  let { pathname } = window.location
  let authBool = false;

  if (pathname.indexOf("/login") < 0) authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    if(authBool) {
      const authRoutes = [
        { path: "/", element: <Main /> },
        { path: "/login", element: <Navigate to='/main' /> },
        { path: "/my_owner", element: <MyOwner /> },
        { path: "/my", element: <MyNormal /> },
        { path: "/change_pw", element: <ChangePw /> },
        { path: "/styles", element: <Styles /> }
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/login", element: <Login /> },
        { path: "/", element: <Navigate to='/main/login' /> },
        { path: "/*", element: <Navigate to='/main/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

  console.log(pathname, authBool)

	return (
		<>
      { pathname.indexOf('/login') < 0 && authBool && <Gnb /> }
      <main className="has_footer">
        { pathname.indexOf('/login') < 0 && authBool && <Lnb /> }
        <Routes> { AuthorizationRoutes() } </Routes>
      </main>
      <Footer />
		</>
	)
}

export default MainRoute