import CustomAlert from "atoms/common/CustomAlert.js";
import CustomCard from "components/common/_CustomCard";
import React, { useEffect, useRef, useState } from "react";
import { decrypt } from "utils/aes256.manager.js";
import { convertJuminToAge, convertGender, nvlNumber, nvl } from "utils/Common.utils.js";
import request from "utils/Request.utils.js";
import Draggable from "react-draggable";
import HandoverCheck from "./_HandoverCheck.js"; //해당 고객님께 잔여 시술을 양도하시겠습니까?

const Handover = ({ onClose, remains, setRemains }) => {
  const [popup, setPopup] = useState({});
  const [handOverList, setHandOverList] = useState({ customer: "", treatments: {} });
  const [customerList, setCustomerList] = useState([]);
  const customers = useRef([]);
  const nodeRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => { },
    id: '',
  });

  const [pointData, setPointData] = useState({});
  const [couponData, setCouponData] = useState({});

  const userDataInit = {
    user_seq: '',
    chart_code: '',
    user_name: '',
    user_gender: 'M',
    user_jumin: '',
    jumin_1: '',
    jumin_2: '',
    mobile_1: '010',
    mobile_2: '',
    mobile_3: '',
    user_mobile: '',
    user_level: '',
    user_photo: 0,
    user_photo_path: '',
    user_reser_seq: 0
  }

  const [userData, setUserData] = useState(userDataInit);

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  };

  const onPopupClick = (e) => {
    if (e.target.id === 'addCustomer') setUserData(userDataInit)
    setPopup((prev) => { return { ...prev, [e.target.id]: true } })
  };

  // 양도 시술 선택하기
  const onChangeCheck = (e, index) => {
    setRemains((prev) => {
      const newArr = [...prev];
      newArr[index].checked = e.target.checked;
      return newArr;
    });
    // const newArr = [...remains]
    // newArr[index].checked = e.target.checked;

    // if (newArr[index].checked) {
    //   setHandOverList(...newArr[index])
    // } else {
    //   const updatedHandOverList = handOverList.filter((item) => item !== newArr[index]);
    //   setHandOverList(updatedHandOverList);
    // }
  };

  // 시술 회차 +
  const plusUseTimes = (index) => {
    const max = remains[index].total_times - remains[index].used_times;
    let currentTimes = remains[index].current_times;
    let remainTimes = remains[index].remain_times;

    if (currentTimes < max) {
      currentTimes++;
      remainTimes--;
    } else {
      currentTimes = max;
      remainTimes = max;
    }

    // if (currentTimes === max) remains[index].chart_type = 'A';

    remains[index].current_times = currentTimes;
    remains[index].remain_times = remainTimes;
    setRemains([...remains]);
  };
  console.log(remains, "REMAINS")


  // 시술 회차 -
  const minusUseTimes = (index) => {
    const max = remains[index].total_times - remains[index].used_times;
    let currentTimes = remains[index].current_times;
    let remainTimes = remains[index].remain_times;

    if (currentTimes > 0) {
      currentTimes--;
      remainTimes++;
    } else {
      currentTimes = 0;
      remainTimes = max;
    }

    remains[index].current_times = currentTimes;
    remains[index].remain_times = remainTimes;
    setRemains([...remains]);
  };

  const getCustomerList = () => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data.dataList;
        result.forEach((item) => {
          item.checked = false;
          item.age = nvl(item.user_jumin) === "" ? " - " : convertJuminToAge(decrypt(item.user_jumin));
          item.gender = convertGender(item.user_gender);
        });
        customers.current = result;
        setCustomerList(result);
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/user`, null, successHandler, failHandler, null);
  }

  const filterText = () => {
    const result = customers.current.filter(item => item.user_name.includes(searchText) || item.user_mobile.includes(searchText));
    setCustomerList(result);
  }

  const closeCustomAlert = () => {
    setAlertPop((prev) => { return { ...prev, visible: false } })
  }
  // 양도하기 
  const handOverClick = (e) => {

    if (remains.some(item => item.current_times === 0 && item.checked)) {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '선택된 시술의 양도할 회차를 지정해주세요.', alertType: 'alert' } })

      return
    }

    if (customerList.every(item => !item.checked)) {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '양도할 고객을 선택해주세요.', alertType: 'alert' } })

      return
    }

    setHandOverList({ customer: customerList.find(item => item.checked).user_seq, treatments: remains.filter(item => item.checked === true) })
    onPopupClick(e);
  }
  // remains에 current_times 추가
  useEffect(() => {
    if (remains.length > 0) {
      const addCurrent = remains.map(item => ({
        ...item,
        current_times: 0

      }))
      setRemains(addCurrent)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCustomerList();
  }, [popup.addCustomer]);

  // 단일 고객 조회
  const getSelectedUserInfo = (user_seq, mode) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const user = response.data.dataList[0];

        setUserData({
          user_seq: user.user_seq,
          chart_code: user.chart_code,
          user_name: user.user_name,
          user_gender: user.user_gender,
          user_level: user.user_level,
          jumin_1: decrypt(user.user_jumin).substring(0, 6),
          jumin_2: decrypt(user.user_jumin).substring(6, 13),
          mobile_1: user.user_mobile.split("-")[0],
          mobile_2: user.user_mobile.split("-")[1],
          mobile_3: user.user_mobile.split("-")[2],
          user_photo: nvlNumber(user.up_file_seq),
          user_photo_path: user.file_path,
          user_reser_seq: user.user_reser_seq,
          main_tc_seq: user.main_tc_seq,
          sub_tc_seq: user.sub_tc_seq,
          user_address: user.user_address,
          user_address_detail: user.user_address_detail,
          interest_area: user.interest_area,
          admissive_channel: user.admissive_channel,
          bank_name: user.bank_name,
          bank_account_number: user.bank_account_number,
          bank_account_holder: user.bank_account_holder
        })

        setPointData(response.data.pointData[0]);
        setCouponData(response.data.couponHistoryData);
        // setPointData((prev) => { return { ...prev, current_point: response.data[0]?.current_point } })

        if (mode === 'popup') setPopup((prev) => { return { ...prev, reservationAdd: false, customerCard: true, order: false } });
        else if (mode === 'order') setPopup((prev) => { return { ...prev, reservationAdd: false, customerCard: false, order: true } });
      } else {
        console.log("고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/user/${user_seq}`, null, successHandler);
  }

  const selectHandler = (value) => {
    getSelectedUserInfo(value); // 현재 setUserData 변경
  }

  return (
    <>
      <section className="com_popup type_normal pop_handover active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container l">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
            <div className="pop_header">
              <h1 className="pop_title">양도하기</h1>
            </div>
            <div className="pop_body">
              <article className="com_list">
                <table className="list">
                  <colgroup><col /><col width="21%" /><col width="130px" /><col width="60px" /></colgroup>
                  <thead>
                    <tr>
                      <th>서비스 시술명</th>
                      <th>양도 가능한 회차</th>
                      <th>양도 할 회차</th>
                      <th>선택</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      remains.map((item, index) => {
                        return (
                          <tr key={item.remain_tc_seq}>
                            <td className="tit bold">{item.goods_name}</td>
                            <td>{item.total_times - item.used_times}</td>
                            <td key={item.remain_tc_seq + '_amount'}>
                              <div className="com_btn_amount">
                                <button className="com_btn icon m" onClick={() => plusUseTimes(index)} disabled={!item.checked}><i className="svg_icon icon_plus">&nbsp;</i></button>
                                {item.current_times}
                                <button className="com_btn icon m" onClick={() => minusUseTimes(index)} disabled={!item.checked}><i className="svg_icon icon_minus">&nbsp;</i></button>
                              </div>
                            </td>
                            <td key={item.sub_tc_seq + '_check'}>
                              <fieldset className="com_check_wrap">
                                <input type="checkbox" className="checkbox" id={`ho-chk-${index}`} checked={item.checked ? true : false} onChange={(e) => onChangeCheck(e, index)} />
                                <label className="btn_checkbox" htmlFor={`ho-chk-${index}`}>&nbsp;</label>
                              </fieldset>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </article>
              <div className="com_field">
                <fieldset className="many">
                  <button className="com_btn red m" id='addCustomer' onClick={onPopupClick}><i className="svg_icon icon_add white">&nbsp;</i>고객등록</button>
                  <div className="input_del_wrap">
                    <input type="text" className="input" placeholder="양도할 고객명, 전화번호 검색" name="" value={searchText} onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        (e.code === 'Enter' || e.code === 'NumpadEnter') && filterText();
                      }} />
                    <button type="button" className="btn_input_del" onClick={() => setSearchText('')}></button>
                  </div>
                  <button className="com_btn icon blue m" onClick={filterText}><i className="svg_icon icon_search">&nbsp;</i></button>
                </fieldset>
              </div>
              <article className="com_list">
                <div className="scroll">
                  <table className="list">
                    <colgroup><col width="70px" /><col width="60px" /><col /><col width="21%" /><col width="24%" /></colgroup>
                    <thead className="sticky top">
                      <tr>
                        <th>No.</th>
                        <th>선택</th>
                        <th>차트번호</th>
                        <th>이름</th>
                        <th>연락처</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        customerList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <fieldset className="com_radio_wrap">
                                  <input type="radio" className="radio" id={`ho-rd-${index}`} checked={item.checked} onChange={(e) =>
                                    setCustomerList((prev) => {
                                      const newArr = [...prev];
                                      newArr.forEach(item => item.checked = false);
                                      newArr[index].checked = e.target.checked;
                                      return newArr;
                                    })} name="radio" />
                                  <label className="btn_radio" htmlFor={`ho-rd-${index}`}>&nbsp;</label>
                                </fieldset>
                              </td>
                              <td>{item.chart_code}</td>
                              <td>{`${item.user_name} (${item.gender}/${item.age})`}</td>
                              <td>{item.user_mobile}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn l blue" id="handOver" onClick={handOverClick}>양도하기</button>
            </div>
          </div>

        </Draggable>
      </section>
      {popup.addCustomer && <CustomCard onClose={() => { onClosePop('addCustomer') }} flag='I' userData={userData} setUserData={setUserData} selectHandler={selectHandler} />}
      {popup.handOver && <HandoverCheck onClose={() => { onClosePop('handOver') }} handOverList={handOverList} setAlertPop={setAlertPop} />}
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}

    </>
  )
}

export default Handover