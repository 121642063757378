import React, { useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import styled from 'styled-components';

import '../../styles/scss/component/_popupCalendar.scss';

export default function PopupCalendar (props) {
  const [calendarValue, setCalendarValue] = useState(new Date());

  const onClickConfirm = () => {
    props.dateType === 'hyphen' ? props.getCalendar(moment(calendarValue).format("YYYY-MM-DD")) : props.getCalendar(moment(calendarValue).format("YYYY년 MM월 DD일"))

    calendarClose();
  }

  function calendarClose() {
    // console.log("달력 닫기");

    props.onClose();
  }

  // added by psk 20221229 - 토요일은 텍스트 색을 파랑으로 수정
  const CalendarContainer = styled.div`
    /* ~~~ button styles ~~~ */
    button:nth-child(6), button:nth-child(13), button:nth-child(20), button:nth-child(27), button:nth-child(34) {
      color: #0000FF;

      /*
      &:hover {
        background-color: #000000;
      }
      &:active {
        background-color: #FFFFFF;
      }
      */
    }

    .react-calendar__tile--active {
      background-color: #FF32B8;
    }
  `;

  return(
    <div className='PopupCalendar ' style={{position:'relative'}}>
      <div className="PopupCalendar_header">
        <button type="button" className="svg_icon btn_pop_close white" onClick={ () => { calendarClose() } } >&nbsp;</button>
        <p className="PopupCalendar_header_tit">날짜선택</p>
      </div>
      <CalendarContainer>
        <Calendar className='calendar' onChange={setCalendarValue} value={calendarValue}
          formatDay={(locale, date) => date.toLocaleString("en", {day: "numeric"})} />
      </CalendarContainer>
      <div className='buttonWrapper'>
        <button className='confirmButton com_btn blue l' type='button' onClick={ () => { onClickConfirm() } } >확인</button>
      </div>
    </div>
  )
}
