import { SET_PAYMENT_INFO, CLEAR_PAYMENT_INFO } from './ActionType';

const statePaymentInfoInit = {
  // payment
  pay_seq: 0,
  order_seq: 0,
  main_tc_seq: 0,
  sub_tc_seq: 0,
  user_seq: 0,
  treatment_fee: 0, // 진료비
  point_price: 0, // 포인트 사용 값,
  coupon_price: 0, // 쿠폰 사용 값
  sale_price: 0, // 할인금액
  healthy_living_expenses: 0, // 건강생활유지비
  insurance_total: 0, // 급여 금액 (보험 적용된 상품 합산)
  uninsured_total: 0, // 비급여 금액 (보험 적용이 되지 않은 상품 합산)
  insurance_req_yn: 'N', // 보험 청구 여부
  insurance_req_date: null, // 보험 청구일
  taxation_total: 0, // 과세 금액 (과세 금액 합산)
  tax_free_total: 0, // 비과세 금액 (비과세 금액 합산)
  total_price: 0, // 총 결제 금액
  payment_price: 0, // 실 결제 금액
  unpaid_price: 0, // 미결제 금액
  pay_status: '014001000', // 결제 상태 (공통코드 사용)
  reg_date: null,
  reg_manager_seq: 0,
  modi_date: null,
  modi_manager_seq: 0,
  payment_history: [ // payment_history
    {
      pay_his_seq: 0,
      sub_tc_seq: 0,
      treatment_times: '1',
      coupon_history_seq: null,
      point_his_seq: null,
      payment_option: null, // 결제 방법
      card_how: 1, // 할부 옵션
      approval_no: null, // 승인 번호 (카드 승인 번호)
      approval_time: null, // 승인 일자 (카드 승인 일자)
      transaction_serial_number: null, // 거래일련번호 (카드 결제 시 거래일련번호)
      bam_seq: 0, // 은행 계좌 관리 시퀀스 (통장 입금 시)
      his_sale_price: 0, // 컬럼명은 sale_price
      payment_amount: 0, // 결제 금액
      cash_receipts_type: null, // P : 개인 소득공제, B : 사업자 지출증빙
      cash_receipts_no: null, // 현금영수증 발행 번호
      receiver_seq: null, // 수납자
      reg_date: null,
      reg_manager_seq: 0,
      modi_date: null,
      modi_manager_seq: 0
    }
  ]
}

const statePaymentInfo = (state = statePaymentInfoInit, action) => {
  let { type } = action;

  switch (type) {
    case SET_PAYMENT_INFO:
      let newState = {
        ...state,
        [action.payload.fieldName]: action.payload.data
      };

      return newState;

    case CLEAR_PAYMENT_INFO:
      return statePaymentInfoInit;

    default:
      return state;
  }
}

export default statePaymentInfo;