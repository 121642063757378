//* 오늘의 안내사항
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import request from "utils/Request.utils.js";
import { v4 as uuidv4 } from 'uuid'
import PopupCalendar from '../../atoms/common/PopupCalendar';

import Draggable from 'react-draggable';
import moment from "moment";
import AlertNoticeWriteOk from "./AlertWriteOk.js"; // 등록완료 알림팝업
import CustomAlert from '../../atoms/common/CustomAlert';
import { nvlNumber } from "utils/Common.utils";

const Today = () => {
  const dispatch = useDispatch();
  const stateMenuManager = useSelector(state => state.MenuManager);

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [dateType, setDateType] = useState('hyphen');
  const [viewType, setViewType] = useState('');
  const [notiWhen, setNotiWhen] = useState('now');
  const [noticeSeq, setNoticeSeq] = useState(0);
  const [notiType, setNotiType] = useState("N");
  const [notiStatus, setNotiStatus] = useState("R")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [noticeContent, setNoticeContent] = useState("");
  const [onUpdateBool, setOnUpdateBool] = useState(false);
  const [selectNotice, setSelectNotice] = useState({})
  const [todayNoticeList, setTodayNoticeList] = useState([])

  const nodeRef = useRef(null);

  const stopHandler = (event, dragElement) => {
    // console.log("x position", dragElement.x)

    dispatch({ type: "setMenuInfo", payload: { fieldName: "todayPopupLeft", data: dragElement.x } })
    dispatch({ type: "setMenuInfo", payload: { fieldName: "todayPopupTop", data: dragElement.y } })
  }

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const [noticeWriteOkPopup, setNoticeWriteOkPopup] = useState({
    closeType: 'next',
    alertMessage: '등록 되었습니다.',
    visible: false
  });

  // 안내사항 팝업창
  const todayNoticeToggle = () => {
    dispatch({ type: "setMenuInfo", payload: { fieldName: "todayPopupBool", data: !stateMenuManager.todayPopupBool } })
  }

  // 달력 팝업창
  const popupCalendarHTML = () => {
    let retArr = [];

    if (viewType === 'from' || viewType === 'to') {
      retArr.push(
        <PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={dateType} />)
    } else retArr.push(null);
    return retArr;
  }

  const closePopup = () => {
    setViewType('');
  }

  const returnAlertValue = (value) => {
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const closeAlertNoticeWrite = () => {
    setNoticeWriteOkPopup((prev) => { return { ...prev, visible: false } })

    if (noticeWriteOkPopup.closeType === "next") {
      setOnUpdateBool(false)
      setNoticeContent("")
      setStartDate("")
      setEndDate("")
      // 다시  호출
      getNoticeTodayList()
    }
  }

  const getCalendar = (value) => {
    // console.log("get calendarValue : " + value + " | " + dateType + " | " + viewType)
    if (viewType === "from") {
      setStartDate(value)
    } else {
      setEndDate(value)
    }
  }

  // 안내사항 리스트 불러오기
  const getNoticeTodayList = () => {
    let params = {
      order_by: "noti_type DESC, noti_seq DESC",
      delete_yn: "N"
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setTodayNoticeList(response.data.dataList)
      }
    }

    request.get(`/api/boards/today?order_by=${params.order_by}&delete_yn=${params.delete_yn}`, null, successHandler)
  }

  useEffect(() => {
    getNoticeTodayList()
  }, [])

  const changeNoticeContent = (e) => {
    const { value } = e.target;
    setNoticeContent(value)
  }

  const changeInList = (e, num) => {
    const { name, checked } = e.target;
    let copy = [...todayNoticeList]
    if (name === "noti_type") {
      copy[num] = { ...copy[num], [name]: checked === true ? "P" : "N" }
    } else if (name === "noti_status") {
      copy[num] = { ...copy[num], [name]: checked === true ? "C" : "R" }
    }
    updateInList(copy[num])
  }

  // 수정 버튼 클릭 시
  const onUpdateNotice = (notiSeq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        console.log(response.data.dataList[0])
        setOnUpdateBool(true)
        setSelectNotice(response.data.dataList[0]);
        setNoticeSeq(response.data.dataList[0].noti_seq);
        setNotiType(response.data.dataList[0].noti_type);
        setNotiStatus(response.data.dataList[0].noti_status);
        if (response.data.dataList[0].noti_start_date === response.data.dataList[0].noti_end_date) {
          setNotiWhen("now")
        } else {
          setNotiWhen("wish")
          setStartDate(response.data.dataList[0].noti_start_date)
          setEndDate(response.data.dataList[0].noti_end_date)
        }
        setNoticeContent(response.data.dataList[0].noti_content)
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/boards/today/${notiSeq}`, null, successHandler, failHandler, null, null);
  }

  // 안내사항 등록하기
  const saveTodayNotice = () => {
    let params = {
      noti_type: notiType,
      noti_status: notiStatus,
      noti_start_date: notiWhen === "now" ? moment().format("YYYY-MM-DD HH:mm:ss") : startDate,
      noti_end_date: notiWhen === "now" ? moment().format("YYYY-MM-DD HH:mm:ss") : endDate,
      noti_content: noticeContent,
      reg_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        // setNoticeWriteOkPopup((prev) => { return { ...prev, visible: true } })
        setOnUpdateBool(false)
        setNoticeContent("")
        setStartDate("")
        setEndDate("")
        // 다시  호출
        getNoticeTodayList()
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
    }

    request.post("/api/boards/today", params, successHandler, failHandler, null, null);
  }

  // 안내사항 수정하기
  const updateTodayNotice = () => {
    let params = {
      noti_type: notiType,
      noti_status: notiStatus,
      noti_start_date: notiWhen === "now" ? moment().format("YYYY-MM-DD") : moment(startDate).format("YYYY-MM-DD"),
      noti_end_date: notiWhen === "now" ? moment().format("YYYY-MM-DD ") : moment(endDate).format("YYYY-MM-DD"),
      noti_content: noticeContent,
      modi_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // setNoticeWriteOkPopup((prev) => { return { ...prev, visible: true } })
        setOnUpdateBool(false)
        setNoticeContent("")
        setStartDate("")
        setEndDate("")
        // 다시  호출
        getNoticeTodayList()
      } else console.log("안내사항을 수정하지 못했습니다.")
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.put(`/api/boards/today/${noticeSeq}`, params, successHandler, failHandler, null, null)
  }

  // 버튼 클릭 시 수정
  const updateInList = (notice) => {
    if (onUpdateBool === true) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "수정할 안내사항을 등록하고 변경하세요." } })

      return;
    }

    let params = {
      noti_type: notice.noti_type,
      noti_status: notice.noti_status,
      noti_start_date: moment(notice.noti_start_date).format("YYYY-MM-DD"),
      noti_end_date: moment(notice.noti_end_date).format("YYYY-MM-DD"),
      noti_content: notice.noti_content,
      modi_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        //다시 호출
        getNoticeTodayList()
      } else {
        console.log("안내사항을 수정하지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.put(`/api/boards/today/${notice.noti_seq}`, params, successHandler, failHandler, null, null)
  }

  // 안내사항 삭제하기
  const deleteTodayNotice = (noti_seq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        console.log("삭제 완료")
        // 다시 호출
        getNoticeTodayList()
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.del(`/api/boards/today/${noti_seq}/${managerInfo.seq}`, null, successHandler, failHandler, null, null)
  }

  return (
    <>
      <section className="com_popup type_normal pop_today PopToday active" >
        <Draggable nodeRef={nodeRef} onStop={stopHandler} position={{ x: nvlNumber(stateMenuManager?.todayPopupLeft), y: nvlNumber(stateMenuManager?.todayPopupTop) }} cancel=".period" >
          <div ref={nodeRef} className="pop_container">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => todayNoticeToggle()}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">오늘의 안내사항</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_memo">
              <div className="scroll">
                <div className="list">
                  {/* //& 기본타입 */}
                  {todayNoticeList === null || todayNoticeList.length === 0 ? null :
                    todayNoticeList.map((notice, index) => (
                      <section className={notice.noti_status === "C" ? "item checked" : "item"} key={notice.noti_seq}>
                        <article className="top_area">
                          <div className="left_wrap">
                            <p className="name">{notice.writer}</p>
                            <p className="date">{moment(notice.reg_date).format("YYYY-MM-DD")}</p>
                            <p className="time">{moment(notice.reg_date).format("HH:mm")}</p>
                          </div>
                          <div className="right_wrap">
                            {/* //^ 즐겨찾기 */}
                            <fieldset className="com_bookmark">
                              <input type="checkbox" className="input" id={"chk3" + index} name="noti_type" checked={notice.noti_type === "P"} onChange={(e) => changeInList(e, index)} />
                              <label className="btn_bookmark" htmlFor={"chk3" + index}>&nbsp;</label>
                            </fieldset>
                            <fieldset className="com_check_wrap">
                              <input type="checkbox" className="checkbox" id={"chk2" + index} name="noti_status" checked={notice.noti_status === "C"} onChange={(e) => changeInList(e, index)} />
                              <label className="btn_checkbox yellow" htmlFor={"chk2" + index}>&nbsp;</label>
                            </fieldset>
                            <button><i className="svg_icon icon_trashcan darkgray" onClick={() => deleteTodayNotice(notice.noti_seq)}>&nbsp;</i></button>
                            {managerInfo.seq === notice.reg_manager_seq && <button onClick={() => onUpdateNotice(notice.noti_seq)}><i className="svg_icon icon_write darkgray">&nbsp;</i></button>}
                          </div>
                        </article>
                        <article className="txt_area">
                          {notice.noti_content}
                        </article>
                      </section>
                    ))
                  }
                </div>
              </div>
              <div className="period" >
                <h2 className="tit">게시기간설정</h2>
                <fieldset className="many">
                  <input type="checkbox" id="date" className="btn_chk" checked={notiWhen === "now"} onChange={() => { setNotiWhen("now") }} />
                  <label htmlFor="date" className="com_btn blue m">1일</label>
                  <div className="datepicker_from_to">
                    <input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name="" value={notiWhen === "now" ? "" : startDate} onClick={() => { setViewType('from'); setNotiWhen("wish") }} readOnly />
                    {popupCalendarHTML()}
                    <span className="txt_between">~</span>
                    <input type="text" className="input datepicker" id="to" placeholder="2023. 01. 01" name="" value={notiWhen === "now" ? "" : endDate} onClick={() => { setViewType('to'); setNotiWhen("wish") }} readOnly />
                    {popupCalendarHTML()}
                  </div>
                </fieldset>
              </div>
              <div className="com_textarea_in_btn">
                <div className="input_del_wrap">
                  <textarea className="textarea" placeholder="내용을 입력하세요. (500자)" value={noticeContent} onChange={(e) => changeNoticeContent(e)} style={{ resize: ' none' }}></textarea>
                  <button type="button" className="btn_input_del big" onClick={() => setNoticeContent("")}>&nbsp;</button>
                </div>
                <button type="button" className="com_btn blue m w120 btn_save" onClick={() => onUpdateBool === false ? saveTodayNotice() : updateTodayNotice()}>등록</button>
              </div>
            </div>
          </div>
        </Draggable>

        {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
        <AlertNoticeWriteOk onClose={closeAlertNoticeWrite} popupVisible={noticeWriteOkPopup.visible} />
      </section>
    </>
  )
}

export default Today