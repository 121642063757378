import { createContext } from "react";
import socketIo from "socket.io-client";

export const socket = socketIo(String(process.env.REACT_APP_SOCKET_SERVER));

export const SocketContext = createContext(socket);

export const SOCKET_EVENT = {
  JOIN_ROOM: "newUser",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  DASHBOARD: "dashboard"
};

export const makeMessage = props => {
	const { roomno, nickname, typingMessage } = props;
	console.log("makeMessage", typingMessage);
  return {
		to: 0,
    roomno : roomno,
		type: 'CHAT',
    nickname: nickname,
    content: typingMessage,
		time: new Date().toLocaleString('ko-KR'),
		readYN: 'N'
  };
};

export const makeImgMessage = props => {
	const { roomno, nickname, typingMessage, imgURL } = props;
	console.log("makeImgMessage", typingMessage);
  return {
		to: 0,
    roomno : roomno,
		type: 'IMG',
    nickname: nickname,
    content: typingMessage,
    imgURL: imgURL,
		time: new Date().toLocaleString('ko-KR'),
		readYN: 'N'
  };
};


socket.on("connect", () => {
  console.log("SocketServer : Server connected.");
});

socket.on("disconnect", () => {
  console.log("SocketServer : Server disconnected.");
});

socket.on(SOCKET_EVENT.JOIN_ROOM, () => {
    console.log("SocketServer : Joined room.");
});
