//* 고객등급
import CustomAlert from "atoms/common/CustomAlert";
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import { nvl } from "utils/Common.utils";
import request from "utils/Request.utils";

const ProcedureCategoryManage = (props) => {
  let procedureCategoryManageBool = props.procedureCategoryManageBool;

  const popupSectionRef = useRef();
  const categoryRef = useRef();
  const categoryDescRef = useRef();
  const nodeRef = useRef(null);


  // alert, confirm창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  const [onUpdateBool, setOnUpdateBool] = useState(false)
  const [activateMode, setActivateMode] = useState('');
  const [selectedCategory, setSelectedCategory] = useState("") // 선택한 카테고리
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  if (procedureCategoryManageBool) popupSectionRef.current.classList.add("active");
  else if (!procedureCategoryManageBool && popupSectionRef.current) popupSectionRef.current.classList.remove("active");

  // 시술 카테고리 관리 팝업창 닫기
  const popupClose = () => {
    props.procedureCategoryManageToggle()
  }
  // alert 창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, visible: false, alertMessage: "" } })
  }
  // 시술 카테고리 등록하기
  const addCategory = () => {
    let category = categoryRef.current.value;

    if (nvl(category) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "카테고리명을 입력해주세요." } })

      return;
    }
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "카테고리를 등록하시겠습니까?" } })
    setActivateMode("addCategory")
  }

  // 시술 카테고리 수정하기
  const updateCategory = () => {

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "카테고리를 수정하시겠습니까?" } })
    setActivateMode('updateCategory')
  }

  // 카테고리 선택하기
  const clickHandler = (e, commonCode, categoryName, categoryDesc) => {
    if (selectedCategory === commonCode) {
      setOnUpdateBool(false);
      setSelectedCategory("")
      categoryRef.current.value = ""

      e.target.checked = false
    } else {
      setOnUpdateBool(true);
      setSelectedCategory(commonCode)
      categoryRef.current.value = categoryName
      categoryDescRef.current.value = categoryDesc

      e.target.checked = true
    }
  }

  // 시술 카테고리 삭제하기
  const deleteCategory = () => {
    if (nvl(selectedCategory) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 카테고리가 없습니다." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "카테고리를 삭제하시겠습니까?" } })
    setActivateMode("deleteCategory")
  }

  //추가/수정/삭제 > 처리
  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (activateMode === "addCategory") {//-------------------> 추가
        let params = {
          parent_code: "001000000",
          code_level: "2",
          code_name: nvl(categoryRef.current.value),
          code_desc: nvl(categoryDescRef.current.value),
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            categoryRef.current.value = "";
            categoryDescRef.current.value = "";
            props.getProcedureCategory(); //리스트 재호출
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "info", visible: true, alertMessage: "등록되었습니다." } })
          } else {
            console.log("카테고리를 등록하지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response)
        }

        request.post("/api/etc/commoncd", params, successHandler, failHandler, null, null);
      } else if (activateMode === "deleteCategory") {//-------------------> 삭제
        let params = {
          modi_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            categoryRef.current.value = "";
            categoryDescRef.current.value = "";

            props.getProcedureCategory(); //다시 호출
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "info", visible: true, alertMessage: "삭제되었습니다." } })
          } else if (response.messageCode === 409) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "경로 내 고객이 있으므로 삭제가 불가능합니다." } })
          } else {
            console.log("코드 값을 찾지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.del(`/api/etc/commoncd/category/${selectedCategory}/${managerInfo.seq}`, params, successHandler, failHandler, null, null);
      } else if (activateMode === 'updateCategory') {//---------------------> 수정
        let params = {
          code_name: categoryRef.current.value,
          code_desc: categoryDescRef.current.value,
          modi_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            categoryRef.current.value = "";
            categoryDescRef.current.value = "";
            setSelectedCategory("")
            props.getProcedureCategory(); //다시 호출
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "info", visible: true, alertMessage: "수정되었습니다." } });
          } else {
            console.log("코드 값을 찾지 못했습니다.")
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }
        request.put(`/api/etc/commoncd/category/${selectedCategory}`, params, successHandler, failHandler, null, null);
      }
    }
  }
  return (
    <>
      {/* //* 일반 팝업 */}
      <section ref={popupSectionRef} className="com_popup type_normal PopProcedureCategoryManage pop_cate_manage">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { popupClose() }}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">시술 카테고리 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">
              <fieldset className="add">
                <input type="text" className="input" ref={categoryRef} placeholder="카테고리 명 입력" />
                <input type="text" className="input" ref={categoryDescRef} placeholder="카테고리 설명 입력"/>{/*231115 추가*/}
                <button type="button" className="com_btn m blue" onClick={onUpdateBool ? updateCategory : addCategory}>{onUpdateBool ? "수정" : "등록"}</button>
              </fieldset>
              <article className="list">
                {props.procedureCategory.length === 0 ?
                  <ul className="item_wrap scroll">
                    <li className="item no_data">등록된 카테고리가 없습니다.</li>
                  </ul>
                  :
                  <ul className="item_wrap scroll">
                    {props.procedureCategory.map((category, index) => (
                      <li className="item" key={category.common_code}>
                        <fieldset className="com_radio_wrap">
                          <input type="radio" className="radio" id={"category" + index} name="select_cate" value={category.common_code}
                            onClick={(e) => clickHandler(e, category?.common_code, category?.code_name, category?.code_desc)} checked={selectedCategory === category.common_code} />
                          <label className="btn_radio" htmlFor={"category" + index}>&nbsp;</label>
                          <label htmlFor={"category" + index}>{category.code_name}</label>
                        </fieldset>
                      </li>
                    ))}
                  </ul>
                }
              </article>
              <p className="tip_txt">카테고리내 문자내용이 있는 경우 삭제가 불가능합니다.</p>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={deleteCategory}>삭제</button>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default ProcedureCategoryManage