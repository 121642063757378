import React, { useState } from "react";
import Editor from '../smartEditor/Editor';



const EditorTest = () => {
  const [content, setContent] = useState("");


  const newContent = (value) => {

    setContent(value)
  }

  return (
    <div className="editor" style={{ minHeight: '400px' }}>
      <Editor
        height={400}
        data={content}
        newContent={newContent}
      />
    </div>
  )
}

export default EditorTest