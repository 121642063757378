//*hot key목록
import React, { useState, useRef } from "react";
import moment from 'moment';
import Draggable from "react-draggable";

import { nvl } from "../../utils/Common.utils";
import HotkeyAdd from "../../components/common/_HotkeyAdd";//hotkey 등록,수정
import CustomAlert from '../../atoms/common/CustomAlert';
import request from '../../utils/Request.utils';

const HotkeySetting = ({ hotkeyBool, searchList, totalCount, setLnbSearchOptions, lnbCustomerSearchActivate }) => {
  moment.locale('ko-KR');

  const nodeRef = useRef(null);

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  const [layerPopup, setLayerPopup] = useState({
    hotkeyAdd: false,
    hotkey_seq: 0,
    hotkey_keyword: '',
    hotkey_description: ''
  });

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const closeHotkeyLayer = () => {
    setLnbSearchOptions((prev) => { return { ...prev, hotkeyBool: false, totalCount: 0, searchList: null, searchKeyword: '', userSeq: 0 } })
  }

  const hotkeyAdd =() => {
    setLayerPopup((prev) => { return { ...prev, hotkeyAdd: true } })
  }

  const hotkeyModify =(value) => {
    setLayerPopup((prev) => { return { ...prev, hotkeyAdd: true, hotkey_seq: value } })
  }

  const hotkeyDelete =(value) => {
    setLayerPopup((prev) => { return { ...prev, hotkey_seq: value } })

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "핫키 정보를 삭제하시겠습니까?", activateMode: 'hotkeyDelete' } })
  }

  const returnAlertValue = (value) => {
    if(nvl(value) === 'OK') {
      if(alertLayerPopup.activateMode === 'hotkeyDelete') {
        const successHandler = (response) => {
          if(response.messageCode === 200) {            
            closeHotkeyAddPopup()
          } else {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "핫키 목록을 가져오던 중 에러가 발생했습니다.<br />다시 시도해 주십시오." } })
          }
        }
  
        request.del(`/api/etc/hotkeys/${layerPopup.hotkey_seq}/${managerInfo.seq}`, null, successHandler);
      }
    }
  }

  const closeHotkeyAddPopup = () => {
    setLayerPopup((prev) => { return { ...prev, hotkeyAdd: false, hotkey_seq: 0, hotkey_keyword: '', hotkey_description: '' } })

    lnbCustomerSearchActivate()
  }

	return (
		<>
      <section className={ hotkeyBool ? "com_popup type_normal PopHotkeySetting active" : "com_popup type_normal PopHotkeySetting" }>
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container xxl">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => closeHotkeyLayer()}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">HotKey</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_memo">
              <div className="com_list">
                <div className="scroll">
                  <table className="list">
                    <colgroup><col width="7%"/><col width="9%"/><col/><col width="9%"/><col width="9%"/></colgroup>
                    <thead className="sticky top">
                      <tr>
                        <th>No.</th>
                        <th>HotKey</th>
                        <th>내용</th>
                        <th>수정</th>
                        <th>삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        searchList != null && searchList?.length > 0 ?
                          searchList?.map((item, i) => (
                            <tr key={ "tr_" + item.hotkey_seq }>
                              <td>{ i + 1 }</td>
                              <td>/{ item.hotkey_keyword }</td>
                              <td className="tit">{ item.hotkey_description }</td>
                              <td><a href="#/" onClick={ () => hotkeyModify(item.hotkey_seq) } className="com_btn txt">수정</a></td>
                              <td><a href="#/" onClick={ () => hotkeyDelete(item.hotkey_seq) } className="com_btn txt red">삭제</a></td>
                            </tr>
                          ))
                        :
                          <tr>
                            <td colSpan="5">일치하는 데이터가 없습니다.</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn blue l" onClick={ hotkeyAdd }>HotKey 등록</button>
            </div>
          </div>
        </Draggable>
      </section>
      { layerPopup.hotkeyAdd && <HotkeyAdd layerPopup={ layerPopup } setLayerPopup={ setLayerPopup } closeHotkeyAddPopup={ closeHotkeyAddPopup } /> }
      { alertLayerPopup.visible && <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> }
		</>
	)
}

export default HotkeySetting