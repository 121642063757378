//* 사진등록하기
import React, { useCallback, useEffect, useState, useRef } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { nvl, nvlNumber } from "utils/Common.utils";
import request from "utils/Request.utils.js";
import CustomAlert from "atoms/common/CustomAlert.js";




const PicAdd = ({ onClose, getUserPhotoList, photoList, customerInfo }) => {

  const imageRef = useRef()
  const beforeRef = useRef(null);
  const afterRef = useRef(null);

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [beforeImage, setBeforeImage] = useState([])
  const [afterImage, setAfterImage] = useState([])
  const [selectArea, setSelectArea] = useState("before") // 임시
  const [selectedPhotos, setSelectedPhotos] = useState([])

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const clickLeftPage = useCallback(() => {
    const scrollAmount = beforeRef.current.offsetWidth;
    smoothScroll(beforeRef.current, -scrollAmount);
    smoothScroll(afterRef.current, -scrollAmount);
  }, []);

  const clickRightPage = useCallback(() => {
    const scrollAmount = beforeRef.current.offsetWidth;
    smoothScroll(beforeRef.current, scrollAmount);
    smoothScroll(afterRef.current, scrollAmount);

  }, []);

  const smoothScroll = (element, scrollAmount) => {
    const scrollOptions = {
      left: element.scrollLeft + scrollAmount,
      behavior: "smooth",
    };
    element.scroll(scrollOptions);
  };


  useEffect(() => {
    setBeforeImage(photoList.filter(el => el.before_after === "B"))
    setAfterImage(photoList.filter(el => el.before_after === "A"))
  }, [photoList])

  const imageHandler = (e) => {
    const imageLists = e.target.files;
    let imageFileLists = [...(selectArea === "before" ? beforeImage : afterImage)];

    for (let i = 0; i < imageLists.length; i++) {
      const currentImgFile = imageLists[i]
      imageFileLists.push(currentImgFile)
    }
    if (selectArea === "before") {
      setBeforeImage(imageFileLists)
    } else {
      setAfterImage(imageFileLists)
    }
  }

  const Card = ({ imageUrl, index, area, moveCard, photoSeq }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: "CARD",
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId()
        };
      },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

        const clientOffset = monitor.getClientOffset();
        const hoverClientX = clientOffset.x - hoverBoundingRect.left;

        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }

        // 빈 이미지의 경우 위치 변경 X
        if (area === "before") {
          if (hoverIndex >= beforeImage.length) {
            return;
          }
        } else if (area === "after") {
          if (hoverIndex >= afterImage.length) {
            return;
          }
        }

        moveCard(dragIndex, hoverIndex, area);

        item.index = hoverIndex;
      },
      canDrop(item, monitor) {
        // 이미지가 있는 경우에만 드롭 가능
        return !!imageUrl;
      }
    });

    const [{ isDragging }, drag] = useDrag({
      type: "CARD",
      item: () => {
        return { index };
      },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      canDrag() {
        // 이미지가 있는 경우에만 드래그 가능하도록 설정
        return !!imageUrl;
      }
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    const selectPhotoSeq = (photoSeq, index) => {
      setSelectedPhotos(prev => {
        // 이미 선택된 사진 앨범인 경우 제거
        if (prev.includes(photoSeq)) {
          return prev.filter(seq => seq !== photoSeq);
        } else {
          // 선택되지 않은 사진 앨범인 경우 추가
          return [...prev, photoSeq];
        }
      });
    }

    return (
      <li
        ref={ref}
        key={`${area}${index}`}
        data-handler-id={handlerId}
        style={{
          ...(imageUrl && { backgroundImage: `url(${imageUrl})` }), // 이미지가 있는 경우에만 배경 이미지를 추가
          opacity
        }}
        className="img"
      >
        <fieldset className="com_check_wrap">
          <input type="checkbox" className="checkbox" id={`chk${area}${index}`} checked={selectedPhotos.includes(photoSeq)} onChange={() => selectPhotoSeq(photoSeq)} disabled={!imageUrl} />
          <label className="btn_checkbox" htmlFor={`chk${area}${index}`}>
            &nbsp;
          </label>
        </fieldset>
      </li>
    );
  };

  const moveCard = useCallback((dragIndex, hoverIndex, area) => {
    if (area === "before") {
      setBeforeImage((prevImages) => {
        const newImages = [...prevImages];
        const [dragImage] = newImages.splice(dragIndex, 1);
        newImages.splice(hoverIndex, 0, dragImage);
        return newImages;
      });
    } else if (area === "after") {
      setAfterImage((prevImages) => {
        const newImages = [...prevImages];
        const [dragImage] = newImages.splice(dragIndex, 1);
        newImages.splice(hoverIndex, 0, dragImage);
        return newImages;
      });
    }
  }, []);

  const uploadImage = () => {
    const formData = new FormData();

    if (beforeImage) {
      const newImage = beforeImage.filter(el => nvl(el.photo_seq) === "")
      newImage.forEach((image) => {
        formData.append("uploadFile", image);
        formData.append("file_cate", "before")
      });
    }
    if (afterImage) {
      const newImage = afterImage.filter(el => nvl(el.photo_seq) === "")
      newImage.forEach((image) => {
        formData.append("uploadFile", image);
        formData.append("file_cate", "after")
      });
    }
    formData.append("user_seq", customerInfo.user_seq);
    formData.append("manager_seq", managerInfo.seq);
    formData.append("main_tc_seq", customerInfo.main_tc_seq);

    const successHandler = (response) => {
      console.log(response.data)
      if (response.messageCode === 200) {

        savePhotoAlbum(response.data)
      }
    }
    const failHandler = (response) => {
      console.log("에라야 에라~~" + response);
    }

    request.postMultipart("/api/upload/album", formData, successHandler, failHandler, null, null);
  }


  // 사진 앨범에 저장
  const savePhotoAlbum = (data) => {
    console.log(data)
    let params = [];
    if (beforeImage) {
      let prevMainTcSeq = null;
      let photoEdition = 1 // 시술 회차 구분
      beforeImage.forEach((image, index) => {

        const matchingItem = data && data.find((item) => item.photo_type === "before" && item.photo_name === image.name);
        const mainTcSeq = matchingItem ? nvlNumber(matchingItem.main_tc_seq) : nvlNumber(image.main_tc_seq);
        const photoPosition = index + 1;// 사진 회차 정하기 => 차트 구별 없이 이미지 순서대로 회차 지정

        if (prevMainTcSeq !== null && prevMainTcSeq !== mainTcSeq) {
          photoEdition += 1;
        }
        params.push({
          main_tc_seq: matchingItem ? matchingItem.main_tc_seq : image.main_tc_seq,
          user_seq: customerInfo.user_seq,
          photo_edition: photoEdition,
          photo_type: "P",
          photo_position: photoPosition,
          before_after: matchingItem?.photo_type === "before" ? "B" : matchingItem?.photo_type === "after" ? "A" : image.before_after,
          up_file_seq: matchingItem ? matchingItem.up_file_seq : image.up_file_seq,
          photo_name: matchingItem ? matchingItem.photo_name : image.photo_name,
          reg_manager_seq: managerInfo.seq
        });

        prevMainTcSeq = mainTcSeq;
        if (prevMainTcSeq !== null && prevMainTcSeq !== mainTcSeq) {
          photoEdition += 1;
        }
      });
    }
    if (afterImage) {
      let prevMainTcSeq = null;
      let photoEdition = 1 // 시술 회차 구분
      afterImage.forEach((image, index) => {

        const matchingItem = data && data.find((item) => item.photo_type === "after" && item.photo_name === image.name);
        const mainTcSeq = matchingItem ? nvlNumber(matchingItem.main_tc_seq) : nvlNumber(image.main_tc_seq);
        const photoPosition = index + 1; // 사진 회차 정하기 => 차트 구별 없이 이미지 순서대로 회차 지정
        params.push({
          main_tc_seq: matchingItem ? matchingItem.main_tc_seq : image.main_tc_seq,
          user_seq: customerInfo.user_seq,
          photo_edition: photoEdition,
          photo_type: "P",
          photo_position: photoPosition,
          before_after: matchingItem?.photo_type === "before" ? "B" : matchingItem?.photo_type === "after" ? "A" : image.before_after,
          up_file_seq: matchingItem ? matchingItem.up_file_seq : image.up_file_seq,
          photo_name: matchingItem ? matchingItem.photo_name : image.photo_name,
          reg_manager_seq: managerInfo.seq
        });

        prevMainTcSeq = mainTcSeq;
        if (prevMainTcSeq !== null && prevMainTcSeq !== mainTcSeq) {
          photoEdition += 1;
        }
      });
    }
    const successHandler = (response) => {
      console.log(response)
      if (response.messageCode === 200) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "사진이 등록되었습니다." } })
        // 다시 호출
        getUserPhotoList()

      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.post("/api/photos", params, successHandler, failHandler, null, null);
  }

  const deletePhotoAlbum = () => {

    const filterSelectedPhotos = selectedPhotos.filter(item => {
      const notUpload = typeof item === 'string' && (item.includes("before") || item.includes("after"));
      return !notUpload
    })
    console.log(filterSelectedPhotos)

    if (filterSelectedPhotos.length > 0) { // 업로드된 이미지 있는 경우

      const successHandler = (response) => {
        console.log(response)
        if (response.messageCode === 200) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "사진이 삭제되었습니다." } })
          // 다시 호출
          getUserPhotoList()
          setSelectedPhotos([])
        }
      }

      const failHandler = (response) => {
        console.log("에러" + response)
      }

      request.del(`/api/photos/${customerInfo.user_seq}/${filterSelectedPhotos}/${managerInfo.seq}`, null, successHandler, failHandler, null, null)
    } else { // 미리보기 이미지만 있는 경우
      setBeforeImage(prevBeforeImage => {
        return prevBeforeImage.filter((item, index) => {
          const photoSeq = item.photo_seq;

          if (photoSeq) {
            return !selectedPhotos.includes(photoSeq);
          }

          const indexStr = `before${index}`;
          return !selectedPhotos.includes(indexStr);
        });
      });

      setAfterImage(prevAfterImage => {
        return prevAfterImage.filter((item, index) => {
          const photoSeq = item.photo_seq;

          if (photoSeq) {
            return !selectedPhotos.includes(photoSeq);
          }

          const indexStr = `after${index}`;
          return !selectedPhotos.includes(indexStr);
        });
      });
    }
  }

  const returnAlertValue = (value) => {
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }


  return (
    <>

      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_pic_add active">{/*클래스 active추가시 노출*/}
        <div className="pop_container xl">
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
          {/* //^ 파란색 팝업 타이틀 */}
          <div className="pop_header">
            <h1 className="pop_title">사진등록하기</h1>
          </div>
          {/* //^ 팝업 본문 영역 */}
          <div className="pop_body">
            <button className="button"><i className="svg_icon icon_photo yellow">&nbsp;</i>촬영하기</button>
            <article className="com_photo">
              <button className="btn_prev" onClick={clickLeftPage}><i className="svg_icon icon_prev">&nbsp;</i></button>
              <div className={selectArea === "before" ? "before_wrap" : "after_wrap"} >
                <p className="tit" onClick={() => setSelectArea("before")}>전 사진 이미지</p>
                <DndProvider backend={HTML5Backend} >
                  <ul className="slide scroll" style={{ overflowX: "scroll", maxHeight: "200px", whiteSpace: "nowrap" }} ref={beforeRef}>
                    {[...beforeImage, ...Array(Math.max(0, 6 - beforeImage.length))].map((image, index) => {
                      const imageUrl = image && image.photo_position
                        ? `${process.env.REACT_APP_API_URL}/api/upload/viewer/image/album/${image.file_path.split("/")[image.file_path.split("/").length - 2]}_${image.file_path.split("/")[image.file_path.split("/").length - 1]}`
                        : nvl(image) !== "" ? URL.createObjectURL(image) : null;
                      const photoSeq = image && image.photo_seq ? image.photo_seq : `before${index}`
                      return (<Card
                        key={`before_${index}`} // 유일한 key 값으로 수정
                        index={index}
                        imageUrl={imageUrl}
                        moveCard={moveCard}
                        photoSeq={photoSeq}
                        area="before"
                      />
                      )
                    })}
                  </ul>
                </DndProvider>
              </div>
              <div className={selectArea === "after" ? "before_wrap" : "after_wrap"} >
                <p className="tit" onClick={() => setSelectArea("after")}>후 사진 이미지</p>
                <DndProvider backend={HTML5Backend} >
                  <ul className="slide scroll" style={{ overflowX: "scroll", maxHeight: "200px", whiteSpace: "nowrap" }} ref={afterRef}>
                    {[...afterImage, ...Array(Math.max(0, 6 - afterImage.length))].map((image, index) => {
                      const imageUrl = image && image.photo_position
                        ? `${process.env.REACT_APP_API_URL}/api/upload/viewer/image/album/${image.file_path.split("/")[image.file_path.split("/").length - 2]}_${image.file_path.split("/")[image.file_path.split("/").length - 1]}`
                        : nvl(image) !== "" ? URL.createObjectURL(image) : null;
                      const photoSeq = image && image.photo_seq ? image.photo_seq : `after${index}`
                      return (<Card
                        key={`after_${index}`} // 유일한 key 값으로 수정
                        index={index}
                        imageUrl={imageUrl}
                        moveCard={moveCard}
                        photoSeq={photoSeq}
                        area="after"
                      />
                      )
                    })}
                  </ul>
                </DndProvider>
              </div>
              <button className="btn_next" onClick={clickRightPage} ><i className="svg_icon icon_next">&nbsp;</i></button>
            </article>
          </div>
          <div className="pop_footer">
            <button className="com_btn line l white" onClick={() => deletePhotoAlbum()}>삭제</button>
            <input className="com_btn line l blue" type="file" multiple ref={imageRef} accept=".gif, .jpg, .png" style={{ display: "none" }} onChange={(e) => imageHandler(e)} />
            <button className="com_btn line l blue" onClick={() => imageRef.current.click()}>사진찾기</button>
            <button type="button" className="com_btn l blue division" onClick={() => uploadImage()}>사진등록</button>
          </div>
        </div>
      </section>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage}
        returnValue={returnAlertValue} /> : null}
    </>
  )
}

export default PicAdd
