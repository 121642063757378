import React from "react";
import { Routes, Route } from "react-router-dom";


import Survey from "../components/etc/Survey.js";//설문

const EtcRoute = () => {
	return (
		<>
      <Routes>
        <Route path="/survey" element={<Survey />} />
      </Routes>
		</>
	)
}

export default EtcRoute