//* //* 공통 사용하는 스타일 모듈 페이지입니다.
import React from "react";

import Paging from '../../layout/Paging.js';

const Styles = () => {
	return (
		<>
			{/* //^ 여기부터 모든 본문 공통 START */}
			{/* <section className="section com_lnb2">
				<div className="inner">
					상단 하얀색 lnb2
				</div>
			</section>
			<section className="com_content">
				본문영역
			</section> */}
			{/* //^ 여기까지 모든 본문 공통 END */}
				
			{/* //! 목차 */}
			{/* //! LNB관련, 알림 팝업, 일반 팝업, LNB관련, 인풋/셀렉트, 버튼류 */}
			<section className="section com_lnb2">
				<div className="inner">

					{/* //* LNB관련 */}
					{/*//^ lnb2 기본버튼 (+아이콘) */}
					{/* <button className="com_btn m green"><i className="svg_icon icon_add white">&nbsp;</i>초진접수</button> */}

					{/*//^ lnb2 날짜 페이징 */}
					{/* <article className="com_date_paging_container">
						<div className="left">
							<button type="button" className="btn_arrow first"><i className="svg_icon paging_arrow icon_prev white">&nbsp;</i><i className="svg_icon paging_arrow icon_prev white">&nbsp;</i></button>
							<button type="button" className="btn_arrow prev"><i className="svg_icon paging_arrow icon_prev white">&nbsp;</i></button>
						</div>
						<h1 className="center">2022. 08. 02 (수)</h1>
						<div className="right">
							<button type="button" className="btn_arrow next"><i className="svg_icon paging_arrow icon_next white">&nbsp;</i></button>
							<button type="button" className="btn_arrow last"><i className="svg_icon paging_arrow icon_next white">&nbsp;</i><i className="svg_icon paging_arrow icon_next white">&nbsp;</i></button>
							<button type="button" className="com_btn blue s oval">오늘</button>
						</div>
					</article> */}

					{/*//^ lnb2 층선택 */}
					{/* <article className="lnb2_floor_container">
						<select className="select">
							<option>2F</option>
						</select>
						<select className="select">
							<option>2F</option>
						</select>
						<button type="button" className="com_btn yellow m line"><i className="svg_icon icon_set yellow">&nbsp;</i></button>
					</article> */}

					{/*//^ lnb2 버튼형 메뉴 : 일반 사이즈 */}
					{/* <article className="lnb2_menu_container">
						<a href="#/" className="com_btn blue m">월간</a>
						<a href="#/" className="com_btn lightgray m">주간</a>
						<a href="#/" className="com_btn lightgray m">진료분야</a>
					</article> */}

					{/*//^ lnb2 버튼형 메뉴 : 작은 사이즈 */}
					{/* <article className="lnb2_menu_container size_small">
						<a href="#/" className="com_btn blue m">오늘</a>
						<a href="#/" className="com_btn blue m line">주간</a>
						<a href="#/" className="com_btn blue m line">월간</a>
					</article> */}
					
					{/*//^ lnb2 시간별 예약인원 관리 */}
					{/* <button type="button" className="com_btn blue m btn_reserve_for_time"><i className="svg_icon icon_set yellow">&nbsp;</i>시간별 예약인원 관리</button> */}
					
					{/*//^ lnb2 새로고침 버튼 */}
					{/* <button type="button"><i className="svg_icon icon_refresh darkgray">&nbsp;</i></button> */}

					{/*//^ lnb2 검색 영역 : input,버튼 */}
					{/* <article className="lnb2_search_wrap">
						<section className="com_field type_normal">
							<fieldset className="many">
								<div className="input_del_wrap">
									<input type="text" className="input" placeholder="공지사항 제목, 작성자 검색" name=""/>
									<button type="button" className="btn_input_del"></button>
								</div>
								<button type="button" className="com_btn m blue">조회</button>
							</fieldset>
						</section>
					</article> */}

					{/*//^ lnb2 검색 영역 : datepicker-from-to,버튼 */}
					<article className="lnb2_search_wrap">
						<section className="com_field type_normal">
							<fieldset className="many">
								<div className="datepicker_from_to">
									<input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name=""/>
									<span className="txt_between">~</span>
									<input type="text" className="input datepicker" id="to" placeholder="2023. 01. 01" name=""/>
								</div>
								<button type="button" className="com_btn m blue">조회</button>
							</fieldset>
						</section>
					</article>

					{/*//^ lnb2 페이지 경로 */}
					<article className="lnb2_page_location">
						<h1 className="dep1">시술 관리</h1>
						<h2 className="dep2 txt_blue">시술 등록</h2>
					</article>

					{/*//^ lnb2 계좌안내 */}
					<article className="lnb2_menu_container">
						<a href="#/" className="com_btn skyblue_bg m w_auto">본사 입금계좌 : 농협 / 465-6542123-6541321 ㈜올리프트</a>
						<a href="#/" className="com_btn skyblue_bg m w_auto">출금 계좌 : 하나 / 465-6542123-6541321 대전지점</a>
					</article>

				</div>
			</section>
			<section className="com_content">
				{/* //* 알림 팝업 */}
				<section className="com_popup type_alert pop_css용_클래스명 pop스크립트용클래스명">
					<div className="pop_container">
						<button type="button" className="svg_icon btn_pop_close btnPopClose"></button>
						{/* //^ 알림 디자인 본문 영역 */}
						<div className="pop_body">
							<h1 className="alert_title">비밀번호가 변경되었습니다.</h1>
							<p className="alert_text">변경된 비밀번호로 <br/>다시 로그인 해 주세요.</p>
						</div>
						{/* //^ 알림 디자인 버튼 영역 */}
						<div className="pop_footer com_btn_wrap many two">
							<button type="button" className="com_btn m gray btnPopClose">취소</button>
							<button type="button" className="com_btn m blue btnPopClose">확인</button>
						</div>
					</div>
				</section>

				{/* //* 일반 팝업 */}
				<section className="com_popup type_normal pop_terms_view popTermsView">
					<div className="pop_container">
						<button type="button" className="svg_icon btn_pop_close white btnPopClose"></button>
						{/* //^ 파란색 팝업 타이틀 */}
						<div className="pop_header">
							<h1 className="pop_title">팝업타이틀</h1>
						</div>
						{/* //^ 팝업 본문 영역 */}
						<div className="pop_body scroll">
							Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore, hic qui excepturi nostrum incidunt voluptate debitis rerum vitae! Repellat quas nisi rem dolorum. Laboriosam, accusantium! Labore autem fugit officiis eos!
						</div>
						{/* //^ 팝업 디자인 중 하단에 버튼묶음 선 그어져 있는 디자인인 경우 */}
						<div className="pop_footer com_btn_wrap many four">
							<button type="button" className="com_btn l gray btnPopClose">예약</button>
							<button type="button" className="com_btn l blue btnPopClose">등록</button>
							<button type="button" className="com_btn l gray btnPopClose">접수</button>
							<button type="button" className="com_btn l blue btnPopClose">가등록</button>
						</div>
					</div>
				</section>

				{/* //* 인풋/셀렉트 */}
				<section className="com_field type_normal">
					<fieldset className="many">
						<div className="input_del_wrap">
							<input type="text" className="input" placeholder="공지사항 제목, 작성자 검색" name=""/>
							<button type="button" className="btn_input_del"></button>
						</div>
						<button type="button" className="com_btn m blue">조회</button>
					</fieldset>
				</section>

        {/* //^ 전화번호 */}
        <fieldset className="com_field type_tel">
          <select className="select">
            <option>010</option>
          </select>
          <input type="text" className="input"/>
          <input type="text" className="input"/>
        </fieldset>

				<input type="text" className="input" placeholder="공지사항 제목, 작성자 검색" name=""/>

				<input type="text" className="input" placeholder="공지사항 제목, 작성자 검색" name="" required/>

				<input type="text" className="input" defaultValue="disabled" name="" disabled/>

				<input type="text" className="input" defaultValue="not edited" name="" readOnly/>

				<input type="text" className="input datepicker" placeholder="2023. 01. 01" name=""/>

				<input type="text" className="input" defaultValue="defaultValue" name=""/>

				<input type="text" className="input centerline" defaultValue="353,000" name=""/>

				<input type="text" className="input txt_blue" defaultValue="353,000" name=""/>

				<div className="input_alert_msg_wrap">
					<input type="text" className="input" placeholder="alert 메세지 있는 input" name=""/>
					<strong>이미 사용중인 아이디/패스워드 입니다.</strong>
				</div>

				<select className="select">
					<option>선택</option>
				</select>

				<textarea className="input" plceholader="텍스트"></textarea>

				<div className="input_del_wrap">
					<input type="text" className="input" placeholder="공지사항 제목, 작성자 검색" name=""/>
					<button type="button" className="btn_input_del"></button>
				</div>

				{/* //* 버튼류 */}
				<Paging />

				<div className="com_toggle_wrap">
					<input type="checkbox" id="btnToggle1"/>
				</div>

				<fieldset className="com_radio_wrap">
					<input type="radio" className="radio" id="payType1" name="payType"/>
					<label className="btn_radio" htmlFor="payType1">&nbsp;</label>
					<label htmlFor="payType1">라디오버튼</label>
				</fieldset>

        <fieldset className="com_check_wrap">
					<input type="checkbox" className="checkbox" id="chk1" />
					<label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
					<label htmlFor="chk1">체크박스:파랑</label>
				</fieldset>

        <fieldset className="com_check_wrap">
					<input type="checkbox" className="checkbox" id="chk2" />
					<label className="btn_checkbox yellow" htmlFor="chk2">&nbsp;</label>
					<label htmlFor="chk2">체크박스:노랑</label>
				</fieldset>

        {/* //^ 버튼형 체크박스 */}
        <input type="checkbox" className="btn_chk" id="sms_auto"/><label className="com_btn chk blue m" htmlFor="sms_auto">문자 자동발송</label>
        
        {/* //^ 즐겨찾기 */}
        <fieldset className="com_bookmark">
					<input type="checkbox" className="input" id="chk3" />
					<label className="btn_bookmark" htmlFor="chk3">&nbsp;</label>
				</fieldset>


        <button className="com_btn line m blue"><i className="svg_icon icon_prev blue">&nbsp;</i>이전</button>
        <button className="com_btn m blue">다음<i className="svg_icon icon_next white">&nbsp;</i></button>
        
        {/* //^ 수량 */}
        <div className="com_btn_amount">
          <button className="com_btn icon m"><i className="svg_icon icon_plus">&nbsp;</i></button>
          08
          <button className="com_btn icon m"><i className="svg_icon icon_minus">&nbsp;</i></button>
        </div>
 
        
        <br/>

        {/* //* 아이콘류 */}
        <i className="svg_icon icon_write yellow">&nbsp;</i>{/* //^ 작성 */}
        <i className="svg_icon icon_trashcan">&nbsp;</i>{/* //^ 삭제_휴지통 (회색)*/}
        <i className="svg_icon icon_trashcan yellow">&nbsp;</i>{/* //^ 삭제_휴지통 (노랑)*/}
        <i className="svg_icon icon_sms yellow">&nbsp;</i>{/* //^ 문자 (노랑)*/}
        <i className="svg_icon icon_list yellow">&nbsp;</i>{/* //^ 문자 내역보기 (노랑)*/}
        <i className="svg_icon icon_set">&nbsp;</i>
				<i className="svg_icon icon_prev">&nbsp;</i>
				<i className="svg_icon icon_next">&nbsp;</i>
				<i className="svg_icon icon_calendar">&nbsp;</i>
				<i className="svg_icon icon_add">&nbsp;</i>
				<i className="svg_icon icon_refresh">&nbsp;</i>
				<i className="svg_icon icon_chk">&nbsp;</i>
				<i className="svg_icon icon_bookmark">&nbsp;</i>
				<i className="svg_icon icon_sort">&nbsp;</i>
				<i className="svg_icon icon_x">&nbsp;</i>
				<i className="svg_icon icon_photo">&nbsp;</i>
				<i className="svg_icon icon_replay">&nbsp;</i>
				<i className="svg_icon icon_del_x">&nbsp;</i>

        {/*//*리스트 */}
        {/* //^ 전체 둥근 테두리형 */}
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="date">2022년 03월 03일 (월)</p>
              <p className="num">전체환자수 (999)명</p>
            </div>
            <div className="btn_field">
              <button type="button" className="com_btn m blue"><i className="svg_icon icon_set yellow">&nbsp;</i>고객 등급 관리</button>
              <button type="button" className="com_btn m blue"><i className="svg_icon icon_sms yellow">&nbsp;</i>문자 보내기</button>
            </div>
          </article>
          <div className="scroll">
            <table className="list">
              <colgroup><col width="3.4%"/><col width="7%"/><col width="7%"/><col width="7%"/><col /><col width="8.5%"/><col width="6.5%"/><col width="7.9%"/><col width="7.9%"/><col width="7.9%"/><col width="7.9%"/><col width="7.9%"/><col width="5%"/></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>선택</th>
                  <th>고객코드</th>
                  <th>이름</th>
                  <th>주민번호</th>
                  <th>이메일</th>
                  <th>휴대폰번호</th>
                  <th>등급</th>
                  <th>가입일</th>
                  <th>최근 시술일</th>
                  <th>총진료비</th>
                  <th>총결제액</th>
                  <th>미수액</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  <fieldset className="com_check_wrap">
                    <input type="checkbox" className="checkbox" id="chk1" />
                    <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                  </fieldset>
                  </td>
                  <td>001</td>
                  <td>가길동(남/42)</td>
                  <td>921214-1*</td>
                  <td>asdkf@asldfja.com</td>
                  <td>010-1111-1111</td>
                  <td>일반</td>
                  <td>2021-02-02</td>
                  <td>2012-03-06</td>
                  <td className="num">2,228,640</td>
                  <td className="num">2,228,640</td>
                  <td className="none_pay"><button>123,456</button></td>
                  <td><a href="#/" className="com_btn txt">상세보기</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* //^ 전체 테두리 x, 테이블, 상단영역 테두리형 */}
        <div className="com_list">
          <article className="top_area box">
            <div className="info_field">
              <p className="tit">나길동 (여25) 010-1234-1234</p>
            </div>
            <div className="btn_field">
              <button type="button" className="com_btn m blue"><i className="svg_icon icon_set yellow">&nbsp;</i>고객 등급 관리</button>
              <button type="button" className="com_btn m blue"><i className="svg_icon icon_sms yellow">&nbsp;</i>문자 보내기</button>
            </div>
          </article>
          <div className="scroll">
            <table className="list">
              <colgroup><col width="3.4%"/><col width="7%"/><col width="7%"/><col width="7%"/><col /><col width="8.5%"/><col width="6.5%"/><col width="7.9%"/><col width="7.9%"/><col width="7.9%"/><col width="7.9%"/><col width="7.9%"/><col width="5%"/></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>선택</th>
                  <th>고객코드</th>
                  <th>이름</th>
                  <th>주민번호</th>
                  <th>이메일</th>
                  <th>휴대폰번호</th>
                  <th>등급</th>
                  <th>가입일</th>
                  <th>최근 시술일</th>
                  <th>총진료비</th>
                  <th>총결제액</th>
                  <th>미수액</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  <fieldset className="com_check_wrap">
                    <input type="checkbox" className="checkbox" id="chk1" />
                    <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                  </fieldset>
                  </td>
                  <td>001</td>
                  <td>가길동(남/42)</td>
                  <td>921214-1*</td>
                  <td>asdkf@asldfja.com</td>
                  <td>010-1111-1111</td>
                  <td>일반</td>
                  <td>2021-02-02</td>
                  <td>2012-03-06</td>
                  <td className="num">2,228,640</td>
                  <td className="num">2,228,640</td>
                  <td className="none_pay"><button>123,456</button></td>
                  <td><a href="#/" className="com_btn txt">상세보기</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/*//!view*/}
        <div className="com_view">
					<article className="top_area">
						<div className="info_field">
							<p className="tit">고객정보 상세보기</p>
						</div>
						<div className="btn_field">
							<button type="button" className="com_btn m line blue"><i className="svg_icon icon_sms yellow">&nbsp;</i>문자 보내기</button>
						</div>
					</article>
					<table className="view">
						<colgroup><col width="8.4%" /><col width="41.6%" /><col width="8.4%" /><col /></colgroup>
						<tbody>
							<tr>
								<th>이름</th>
								<td>가길동(남/42)</td>
								<th>주민등록번호</th>
								<td>
									<p className="txt">784654  -  *********</p>
									<button className="com_btn blue m">자격조회</button>
								</td>
							</tr>
						</tbody>
					</table>
					<article className="btn_area">
						<button className="com_btn l blue">수정</button>
						<button className="com_btn l blue line">회원탈퇴</button>
					</article>
				</div>

				{/* //^ 고객카드 */}
				<section className="com_sec pd20">
					<article className="com_user_box">
						<div className="color" style={{background:"#ffbb01"}}>&nbsp;</div>
						<div className="pic">&nbsp;</div>
						<div className="btns_wrap">
							<button type="button" className="btn"><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
							<button type="button" className="btn"><i className="svg_icon icon_list yellow">&nbsp;</i></button>
						</div>
						<div className="num">123-45661</div>
						<div className="age_phone" style={{background:"#0070c0", borderColor:"#005897"}}>
							<h1 className="age">홍길동(남/32)</h1>
							<h2 className="phone">010-1234-5678</h2>
						</div>
						<button type="button" className="btn_memo com_btn m icon_vertical skyblue_bg txt_blue"><i className="svg_icon icon_write blue">&nbsp;</i>메모</button>
					</article>
					<article className="com_user_box_btns">
						<div className="btns_wrap">
							<button type="button" className="com_btn m line blue">동의서</button>
							<button type="button" className="com_btn m line blue">포인트 정보</button>
							<button type="button" className="com_btn m line blue">쿠폰 정보</button>
						</div>
						<button type="button" className="com_btn m red">예약하기</button>
					</article>
				</section>

				{/* //^ Today Chart */}
				{/* FIXME: type_more 이 있는 경우 active 클래스로 on/off 제어 */}
				<section className="com_sec type_more sec_today_chart active">
					<hgroup className="com_sec__hgroup">
						<h1 className="com_sec__title">Today Chat<b className="txt_blue">2022, 08, 25(토)</b></h1>
					</hgroup>
					<article className="more_cont_box">
						<textarea className="input" placeholder="내용을 입력하세요. (500자)"></textarea>
						<button type="button" className="com_btn blue m w120 btn_save">저장</button>
					</article>
					<button type="button" className="btn_more"></button>
				</section>

				{/* //^ 요청사항 */}
				<section className="com_sec type_more ">
					<hgroup className="com_sec__hgroup">
						<h1 className="com_sec__title">요청사항<b className="txt_blue">2022, 08, 25(토)</b></h1>
						<select className="com_sec__posi-r select">
							<option>원장님 선택</option>
						</select>
					</hgroup>
					<button type="button" className="btn_more"></button>
				</section>

				{/* //^ 사진 */}
				<section className="com_sec type_more">
					<hgroup className="com_sec__hgroup space-between">
						<h1 className="com_sec__title">사진(15)</h1>
						<input type="file" id="photo" className="hidden"/><label className="com_btn icon txt non_under" htmlFor="photo"><i className="svg_icon icon_photo yellow">&nbsp;</i><b>사진 등록</b></label>
					</hgroup>
					<button type="button" className="btn_more"></button>
				</section>

        {/* //!write*/}
        {/*//^아이템 등록*/}
        <section className="com_add_item">
          <fieldset className="add">
            <input type="text" className="input"/>
            <button type="button" className="com_btn m blue">등록</button>
          </fieldset>
          <article className="list">
            <ul className="item_wrap scroll">
              <li className="item no_data">등록된 등급이 없습니다.</li>
              <li className="item">
                <fieldset className="com_radio_wrap">
                  <input type="radio" className="radio" id="grade1"  name="select_grade" />
                  <label className="btn_radio" htmlFor="grade1">&nbsp;</label>
                  <label htmlFor="grade1">일반</label>
                </fieldset>
              </li>
            </ul>
          </article>
          <p className="tip_txt">등급내 고객이 있는 경우 삭제가 불가능합니다.</p>
        </section>  
        {/*//^등록 폼 기본*/}
        <ul className="com_input_section">
          <li className="tit_field">
            이름
          </li>
          <li className="input_field input_wrap">
            <p className="only_txt">홍길동</p>
          </li>
          <li className="tit_field">주민등록번호</li>
          <li className="input_field input_wrap">
            <p>798764 - ********</p>
            <button className="com_btn m blue">자격조회</button>
          </li>
          <li className="tit_field">휴대폰번호</li>
          <li className="input_field">
            <fieldset className="com_field type_tel">
              <select className="select">
                <option>010</option>
              </select>
              <input type="text" className="input"/>
              <input type="text" className="input"/>
            </fieldset>
          </li>
          <li className="tit_field col_left">발송조건</li>
          <li className="tit_field col_right">뒤 발송</li>
          <li className="input_field col_left"><input type="text" className="input"/></li>
          <li className="input_field col_right"><input type="text" className="input"/></li>
        </ul>
        
        {/*//^메모 등록 및 리스트*/}
        <section className="com_memo">
          <div className="scroll">
            <div className="list">
              {/* //& 기본타입 */}
              <section className="item">
                <article className="top_area">
                  <div className="left_wrap">
                    <p className="name">홍길동</p>
                    <p className="date">2022,08,20</p>
                    <p className="time">10:30</p>
                  </div>
                  <div className="right_wrap">
                    {/* //^ 즐겨찾기 */}
                    <fieldset className="com_bookmark">
                      <input type="checkbox" className="input" id="chk3" />
                      <label className="btn_bookmark" htmlFor="chk3">&nbsp;</label>
                    </fieldset>
                    <fieldset className="com_check_wrap">
                      <input type="checkbox" className="checkbox" id="chk2" />
                      <label className="btn_checkbox yellow" htmlFor="chk2">&nbsp;</label>
                    </fieldset>
                    <button><i className="svg_icon icon_trashcan darkgray">&nbsp;</i></button>
                    <button><i className="svg_icon icon_write darkgray">&nbsp;</i></button>
                  </div>
                </article>
                <article className="txt_area">
                청소 필요합니다. 화장지도 없네요.
                </article>
              </section>
              {/* //& 즐겨찾기 할경우 */}
              <section className="item bookmark">
                <article className="top_area">
                  <div className="left_wrap">
                    <p className="name">홍길동</p>
                    <p className="date">2022,08,20</p>
                    <p className="time">10:30</p>
                  </div>
                  <div className="right_wrap">
                    {/* //^ 즐겨찾기 */}
                    <fieldset className="com_bookmark">
                      <input type="checkbox" className="input" id="chk5"  checked/>
                      <label className="btn_bookmark" htmlFor="chk5">&nbsp;</label>
                    </fieldset>
                    <fieldset className="com_check_wrap">
                      <input type="checkbox" className="checkbox" id="chk6" />
                      <label className="btn_checkbox yellow" htmlFor="chk6">&nbsp;</label>
                    </fieldset>
                    <button><i className="svg_icon icon_trashcan darkgray">&nbsp;</i></button>
                    <button><i className="svg_icon icon_write darkgray">&nbsp;</i></button>
                  </div>
                </article>
                <article className="txt_area">
                청소 필요합니다. 화장지도 없네요.
                </article>
              </section>
              {/* //& 체크할경우 */}
              <section className="item checked">
                <article className="top_area">
                  <div className="left_wrap">
                    <p className="name">홍길동</p>
                    <p className="date">2022,08,20</p>
                    <p className="time">10:30</p>
                  </div>
                  <div className="right_wrap">
                    {/* //^ 즐겨찾기 */}
                    <fieldset className="com_bookmark">
                      <input type="checkbox" className="input" id="chk7"/>
                      <label className="btn_bookmark" htmlFor="chk7">&nbsp;</label>
                    </fieldset>
                    <fieldset className="com_check_wrap">
                      <input type="checkbox" className="checkbox" id="chk8" checked />
                      <label className="btn_checkbox yellow" htmlFor="chk8">&nbsp;</label>
                    </fieldset>
                    <button><i className="svg_icon icon_trashcan darkgray">&nbsp;</i></button>
                    <button><i className="svg_icon icon_write darkgray">&nbsp;</i></button>
                  </div>
                </article>
                <article className="txt_area">
                청소 필요합니다. 화장지도 없네요.
                </article>
              </section>
            </div>
          </div>
          <div className="period">
            <h2 className="tit">게시기간설정</h2>
            <fieldset className="many">
              <input type="checkbox" id="date" className="btn_chk" checked/>
              <label htmlFor="date" className="com_btn blue m">1일</label>
              <div className="datepicker_from_to">
                <input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name=""/>
                <span className="txt_between">~</span>
                <input type="text" className="input datepicker" id="to" placeholder="2023. 01. 01" name=""/>
              </div>
            </fieldset>
          </div>
          <div className="com_textarea_in_btn">
            <div className="input_del_wrap">
              <textarea className="textarea" placeholder="내용을 입력하세요. (500자)"></textarea>
              <button type="button" className="btn_input_del big">&nbsp;</button>
            </div>
            <button type="button" className="com_btn blue m w120 btn_save">등록</button>
          </div>
        </section>
        {/* //! 고객정보 */}
        <article className="com_custom_info">
          <p className="item">홍길동 (남/37)</p>
          <p className="item">010-123-5678</p>
        </article>

        {/* //! 총결제 정보 */}
        <ul className="com_input_section payment">
          <li className="tit_field">총 결제액</li>
          <li className="input_field">670,000원</li>
          <li className="tit_field">선택한 결제액</li>
          <li className="input_field">100,000원</li>
          <li className="tit_field">위약금</li>
          <li className="input_field"><input type="text" className="input"/></li>
          <li className="tit_field total">최종 환불 금액</li>
          <li className="input_field total">
            <span className="txt_blue">670,000원</span>
          </li>
        </ul>

          {/*총금액*/}
        <div className="com_total_price">
          <p className="item">
            <span className="tit">실결제 총액</span>
            <span className="txt_blue">466,456,000</span>
            <span className="txt">(포인트 + 추가 결제금)</span>
          </p>
          <p className="item">
            <span className="tit">미수 총액</span>
            <span className="txt_red">45,000</span>
          </p>
        </div>

			</section>
		</>
	)
}

export default Styles