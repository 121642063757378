//* 고객카드 검색결과
import React, { useRef, useState } from "react";
import { decrypt } from "../../utils/aes256.manager";
import { nvl, nvlNumber, changeMobileFormat, convertJuminToAge, convertGender } from "utils/Common.utils";
import request from "utils/Request.utils";
import CustomCard from "./_CustomCard";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert";
import moment from 'moment';


const CustomListMsg = ({ checkedBool, checkHandler, searchList, /* changeUserHandler, */ totalCount, returnValue, setUserList, userList }) => {
  moment.locale('ko-KR');

  const [selectedOption, setSelectedOption] = useState(null); // 초기값은 빈 문자열

  const [userData, setUserData] = useState([{
    user_seq: '',
    chart_code: '',
    user_name: '',
    user_gender: 'M',
    user_jumin: '',
    jumin_1: '',
    jumin_2: '',
    mobile_1: '010',
    mobile_2: '',
    mobile_3: '',
    user_mobile: '',
    user_level: ''
  }]);

  const [userSeq, setUserSeq] = useState('');
  const [pointData, setPointData] = useState({});
  const nodeRef = useRef(null);

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  // alert 창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const selectHandler = (e) => {
    const { value, checked } = e.target;
    // changeUserHandler(value);
    getSelectedUserInfo(value?.split(",")[2])
    setUserSeq(value?.split(",")[2]);

    if (checked) {
      setSelectedOption(value);
      returnValue = value;
    }
  }

  const [popup, setPopup] = useState({
    addCustomer: false,
    reserve: false,
    read: false,
  });


  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  };
  const onOpenPop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: true } });
  };

  const isUserExist = (userSeq) => {
    // console.log(userSeq)
    setUserSeq(userSeq)

    if (nvl(userSeq) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객을 선택해주세요." } })
    }
    else {
      if (userList.findIndex(el => el.user_seq === userSeq) < 0) {
        setUserList([...userList, userData]); checkHandler()
      } else {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이미 추가한 고객입니다." } })
      }
    }
  }

  // 단일 고객 조회
  const getSelectedUserInfo = (user_seq, type) => {
    let params = {
      user_seq: user_seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const user = response.data.dataList[0];


        setUserData({
          user_seq: user.user_seq,
          chart_code: user.chart_code,
          user_name: user.user_name,
          user_gender: user.user_gender,
          user_level: user.user_level,
          jumin_1: decrypt(user.user_jumin).substring(0, 6),
          jumin_2: decrypt(user.user_jumin).substring(6, 13),
          mobile_1: user.user_mobile.split("-")[0],
          mobile_2: user.user_mobile.split("-")[1],
          mobile_3: user.user_mobile.split("-")[2],
          phone_number: user.user_mobile // 2023-03-29 added by wem
        })

        setUserSeq(user.user_seq);
        setPointData(response.data.pointData[0]);
        if (type === "user") {
          onOpenPop('read')
        }
      } else {
        console.log("고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/user/${params.user_seq}`, null, successHandler);
  }


  // const updatetHandler = (e, user_seq, type) => {
  //   getSelectedUserInfo(user_seq, type);
  // }

  const returnAlertValue = () => {

  }

  return (
    <>
      <section className={checkedBool ? "com_popup type_normal pop_custom_list PopCustomList active" : "com_popup type_normal pop_custom_list PopCustomList"}>
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container xxl">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => checkHandler()}></button>
            <div className="pop_header">
              <h1 className="pop_title">고객카드 검색결과</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <div className="com_list">
                <article className="top_btn_area">
                  {/* <button className="com_btn m red" onClick={() => onOpenPop('addCustomer')}><i className="svg_icon icon_add white">&nbsp;</i>고객등록</button> */}
                  {/* <button type="button" className="com_btn icon m line yellow division" onClick={() => onOpenPop('sendMsg')}><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
                  <button type="button" className="com_btn icon m line yellow"><i className="svg_icon icon_list yellow">&nbsp;</i></button> */}
                </article>
                <article className="scroll">
                  <table className="list">
                    <colgroup><col width="7%" /><col width="6%" /><col width="17.4%" /><col width="22%" /><col width="22%" /><col /></colgroup>
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>선택</th>
                        <th>차트번호</th>
                        <th>예약일시</th>
                        <th>이름</th>
                        <th>휴대폰번호</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        searchList != null && searchList?.length > 0 ?
                          searchList?.map((el, i) => (
                            <tr className="checked" key={i}>
                              <td>{totalCount - i}</td>
                              <td>
                                <fieldset className="com_check_wrap">
                                  <input type="radio" className="checkbox" id={`rad${i}`} name="radio" checked={selectedOption === `${el.user_name},${el.chart_code},${el.user_seq}`}
                                    value={`${el.user_name},${el.chart_code},${el.user_seq}`} onChange={(e) => selectHandler(e)} />
                                  <label className="btn_checkbox" htmlFor={`rad${i}`}>&nbsp;</label>
                                </fieldset>
                              </td>
                              <td>{el.chart_code}</td>
                              <td>{nvl(el.reser_date) !== "" ? moment(el.reser_date).format("YYYY.MM.DD") + "/" + nvl(el.reser_date) : "-"}</td>
                              <td /* onClick={(e) => updatetHandler(e, el.user_seq, "user")} style={{ cursor: "pointer" }} */>
                                {el.user_name} ({convertGender(el.user_gender)}/{nvl(el.user_jumin) === "" ? " - " : convertJuminToAge((decrypt(el.user_jumin).substring(0, 6) + "-" + decrypt(el.user_jumin).substring(6, 13)))})
                              </td>
                              <td>{changeMobileFormat(el.user_mobile)}</td>
                            </tr>
                          ))
                          :
                          <tr>
                            <td colSpan="6">일치하는 데이터가 없습니다.</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </article>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue" onClick={() => isUserExist(userSeq)}>수신인 넣기</button>
            </div>
          </div>
        </Draggable>
      </section>
      {/* {popup.reserve && nvl(userSeq) !== "" ? <ReservationAdd onClose={() => onClosePop('reserve')} flag='I' userSeq={userSeq} /> : ""} */}
      {/* {popup.addCustomer && <CustomCard onClose={() => onClosePop('addCustomer')} flag='I' />} */}
      {/* {popup.read && <CustomCard onClose={() => onClosePop('read')} flag='U' userData={userData} setPointData={setPointData} pointData={pointData} setUserData={setUserData} userSeq={userSeq} />} */}
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
      {/* {popup.sendMsg && <SnsSend onClose={() => onClosePop('sendMsg')} />} */}
    </>
  )
}

export default CustomListMsg