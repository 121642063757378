//* 예약하기
import React, { useRef, useState, useEffect } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "atoms/common/CustomAlert";
import PopupCalendar from "atoms/common/PopupCalendar";
import request from '../../utils/Request.utils';
import { decrypt } from "../../utils/aes256.manager";
import { v4 as uuidv4 } from 'uuid';
import { convertJuminToAge, convertGender, nvl, nvlNumber, createChartCode } from "../../utils/Common.utils";
import moment from 'moment';

const ReservationAdd = ({ onClose, userData, setLnbSearchOptions, changeUserHandler }) => {
  const dispatch = useDispatch();

  const [category, setCategory] = useState([]); //시술 카테고리
  const [surgeryCategoryDetailList, setSurgeryCategoryDetailList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [visitPathList, setVisitPathList] = useState([]);
  const [surgeryList, setSurgeryList] = useState([]);
  const [reserStatus, setReserStatus] = useState(false);
  const [reservationUser, setReservationUser] = useState([]);
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [categoryShown, setCategoryShown] = useState([]); //시술 카테고리 안에 세부 시술
  const [roomList, setRoomList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('012001000');
  const [viewType, setViewType] = useState('');
  const [dateType, setDateType] = useState('hyphen');
  const stateToday = useSelector(state => state.Today)
  const [userInfo, setUserInfo] = useState([]);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [reservationList, setReservationList] = useState([]);

  const [reservationInfo, setReservationInfo] = useState({
    user_seq: nvlNumber(userData?.user_seq),
    reser_date: stateToday,
    hour: '10',
    min: '00',
    surgery_category: '',
    surgery_category_detail: '',
    manager: '',
    visitPath: '',
    memo: '',
    parent_code: '',
    treatment_room: '',
    cycle_info: '1',
    week: '',
    month: ''
  });

  const nodeRef = useRef(null);

  let hourArr = [];
  let minArr = [];

  for (let i = 10; i <= 20; i++) {
    hourArr.push(("0" + i).slice(-2));
  }

  //10 분 단위
  for (let i = 0; i <= 5; i++) {
    minArr.push(("0" + i * 10).slice(-2));
  }

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  const closePopup = () => {
    setViewType('');
  }

  const getCalendar = (value) => {
    if (value <= stateToday) setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "오늘 이후의 날짜를 선택해주세요." } })
    else {
      setReservationInfo({ ...reservationInfo, reser_date: value })
      setReservationUser({ ...reservationUser, reser_date: value })
    }
  }

  const getUserInfo = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) { // ok 코드
        setUserInfo(response.data.dataList[0])
        getSurgery();
      } else {
        console.log("데이터가 없습니다.");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "사용자 정보를 가져오던 중 에러가 발생하였습니다." } })
    }

    request.get(`/api/user/${nvlNumber(userData?.user_seq)}`, null, successHandler, failHandler);
  }

  // 시술 카테고리 가져오기
  const getSurgery = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setCategory(response?.data.dataList);
        getSurgeryDetail();
      } else {
        console.log("시술 카테고리 목록을 가져오지 못했습니다.")
      }
    }

    request.get(`/api/etc/commoncd/3/001000000`, null, successHandler);
  }

  // 시술 카테고리 > 세부 시술 목록 가져오기
  const getSurgeryDetail = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        if (response.data.length > 0) setCategoryDetail(response.data);
        getSurgeryRoomList();
      } else {
        console.log("세부 시술 목록을 가져오지 못했습니다.")
      }
    }

    request.get(`/api/goods?gubun=G`, null, successHandler);
  }

  const getSurgeryRoomList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setSurgeryList(response.data);
        getManagerList();
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/012000000`, null, successHandler);
  }

  const getManagerList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setManagerList(response.data);
        getReservationList();
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/manager/`, null, successHandler);
  }

  const getReservationList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setReservationList(response.data.dataList);
        getVisitPathList();
      } else {
        console.log("고객 목록을 가져오지 못했습니다.")
      }
    }

    request.get(`/api/reservation?listType=L&user_seq=${userData?.user_seq}`, null, successHandler);
  }

  const getVisitPathList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data;
        setVisitPathList(result);
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/002000000`, null, successHandler);
  }

  const getRoomList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setRoomList(response.data);
      } else {
        console.log('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/${selectedValue}`, null, successHandler);
  }

  const popupCalendarHTML = () => {
    let retArr = [];

    if (viewType === 'from') {
      retArr.push(<PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={dateType} />)
    } else retArr.push(null);

    return retArr;
  }

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const reservationHandler = (e) => {
    const { name, value } = e.target;

    if (nvlNumber(userData?.user_reser_seq) === 0) {
      if (name === "surgery_category") {
        const newArray = categoryDetail.filter(el => value === el.category);

        setCategoryShown(newArray);
      } else if (name === "surgery_category_detail") {
        if (surgeryCategoryDetailList.findIndex(el => value === el.category)) {
          setReservationInfo({ ...reservationInfo, [name]: value })
          return;
        }

        setReservationInfo({ ...reservationInfo, surgeryCategory: '', surgeryCategoryDetail: '' })
        setCategoryShown([]); // 세부 시술 리스트 초기화
      } else if (name === "parent_code") {
        setSelectedValue(value);
      }
      setReservationInfo({ ...reservationInfo, [name]: value })
    } else {
      if (name === "reser_date") {
      } else if (name === "surgery_category") {
        if(value === "0"){
          setReservationUser({ ...reservationUser, surgery_category: value, surgery_category_detail: value })
          return;
        }else{
          const newArray = categoryDetail.filter(el => value === el.category);
          setCategoryShown(newArray);
          setReservationUser({ ...reservationUser, surgery_category_detail: value })
        }
      } else if (name === "surgery_category_detail") {
        if (surgeryCategoryDetailList.findIndex(el => value === el.category)) {
          setReservationUser({ ...reservationUser, [name]: value })
          return;
        }
        setCategoryShown([]); // 세부 시술 리스트 초기화
      } else if (name === "parent_code") {
        setSelectedValue(value);
      } else if (name === "week") {
        setReservationUser({ ...reservationUser, month: "0" })
      } else if (name === "month") {
        setReservationUser({ ...reservationUser, week: "0" })
      }

      setReservationUser({ ...reservationUser, [name]: value })
    }
  }


  const regReservation = (mode) => {
    if (mode === 1) {
      // modified by psk 20230420 - mandatory 체크 해제
      // if (reservationInfo.surgery_category === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 카테고리를 선택해주세요.", activateMode: "" } });
      //   return;
      // } else if (reservationInfo.surgery_category_detail === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술을 선택해주세요.", activateMode: "" } });
      //   return;
      // } else if (reservationInfo.manager === "0") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "예약직원을 선택해주세요.", activateMode: "" } });
      //   return;
      // } else if (reservationInfo.visitPath === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "방문 경로를 선택해주세요.", activateMode: "" } });
      //   return;
      // }else if (reservationInfo.parent_code === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술실을 선택해주세요.", activateMode: "" } });
      //   return;
      // } else if (reservationInfo.treatment_room === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "룸을 선택해주세요.", activateMode: "" } });
      //   return;
      // } else if (reservationInfo.cycle_info === '2'){
      //   if(reservationInfo.week === "") {
      //     setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "주반복 셀렉트박스를 선택해주세요.", activateMode: "" } });
      //     return;
      //   }
      // } else if (reservationInfo.cycle_info === '3'){
      //   if(reservationInfo.month === "") {
      //     setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "월반복 셀렉트박스를 선택해주세요.", activateMode: "" } });
      //     return;
      //   }
      // }

      if (reservationInfo.surgery_category !== "") {
        if (reservationInfo.surgery_category_detail === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술을 선택해주세요.", activateMode: "" } });
          return;
        }
      }

      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "예약하시겠습니까?", activateMode: "reservation" } });
    } else {
      if (nvl(reservationUser.surgery_category) !== "") {
        if (nvl(reservationUser.surgery_category_detail) === "") {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술을 선택해주세요.", activateMode: "" } });
          return;
        }
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "예약을 변경하시겠습니까", activateMode: "update" } });
    }
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (alertLayerPopup.activateMode === "reservation") {
        let params = {
          user_seq: nvlNumber(userData?.user_seq),
          reser_date: reservationInfo.reser_date,
          reser_time: `${reservationInfo.hour}:${reservationInfo.min}`,
          main_tc_seq: userInfo.main_tc_seq,
          sub_tc_seq: null,
          receptionist_seq: reservationInfo.manager,
          visit_path: reservationInfo.visitPath,
          reser_description: reservationInfo.memo,
          treatment_room: reservationInfo.treatment_room,
          cycle_info: nvl(reservationInfo?.cycle_info) !== '' && reservationInfo?.cycle_info === "2" ? reservationInfo.week : reservationInfo.cycle_info === "3" ? reservationInfo.month : reservationInfo.cycle_info,
          reser_status: "R",
          reg_manager_seq: managerInfo.seq,
          chart_status: "013001000", //예약
          modi_manager_seq: managerInfo.seq
        }

        params = { ...params, ...reservationInfo }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `예약이 완료되었습니다.` } });
            // onClose();
            setReservationInfo({
              user_seq: nvlNumber(userData?.user_seq),
              reser_date: stateToday,
              hour: '10',
              min: '00',
              surgery_category: '',
              surgery_category_detail: '',
              manager: '',
              visitPath: '',
              memo: '',
              parent_code: '',
              treatment_room: '',
              cycle_info: '1',
              week: '',
              month: ''
            })

            dispatch({ type: "setOrderInfo", payload: { fieldName: "reser_info_status", data: createChartCode(0, 999) } });
            setLnbSearchOptions((prev) => { return { ...prev, checkedBool: false, totalCount: 0, searchList: null, searchKeyword: '', userSeq: 0 } })
          } else {
            console.log(`예약을 등록하지 못했습니다.`)
          }
        }

        request.post("/api/reservation", params, successHandler);
      } else if (alertLayerPopup.activateMode === "update") {
        let params = {
          actionMode: nvl(reservationUser.surgery_category) === "" ? "N" : "A",
          main_tc_seq: userInfo.main_tc_seq,
          sub_tc_seq: reservationUser.sub_tc_seq,
          reser_date: reservationUser.reser_date,
          reser_time: `${reservationUser.hour}:${reservationUser.min}`,
          receptionist_seq: nvl(reservationUser.manager) === "" ? null : reservationUser.manager,
          visit_path: reservationUser.visitPath,
          reser_description: reservationUser.memo,
          treatment_room: reservationUser.treatment_room,
          cycle_info: reservationUser.cycle_info === "2" ? reservationUser.week : reservationUser.cycle_info === "3" ? reservationUser.month : reservationUser.cycle_info,
          month: reservationUser.cycle_info,
          reser_status: "R",
          modi_manager_seq: managerInfo.seq
        }

        params = { ...params, ...reservationUser }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `예약이 변경되었습니다.` } });
            getReservationList();

            dispatch({ type: "setOrderInfo", payload: { fieldName: "reser_info_status", data: createChartCode(0, 999) } });
            changeUserHandler(userData.user_seq)
            setLnbSearchOptions((prev) => { return { ...prev, checkedBool: false, totalCount: 0, searchList: null, searchKeyword: '', userSeq: 0 } })
          } else {
            console.log(`예약을 변경하지 못했습니다.`)
          }
        }

        request.put(`/api/reservation/${nvlNumber(userData.user_reser_seq)}`, params, successHandler);
      } else if (alertLayerPopup.activateMode === "cancel") {
        let params = {
          reser_status: "C",
          modi_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "예약이 취소되었습니다." } });
            setReserStatus(true);

            dispatch({ type: "setOrderInfo", payload: { fieldName: "reser_info_status", data: createChartCode(0, 999) } });
          } else {
            console.log("예약을 취소하지 못했습니다.");
          }
        }

        request.put(`/api/reservation/${nvlNumber(userData.user_reser_seq)}/${managerInfo.seq}`, params, successHandler);
      }
    }
  }

  useEffect(() => {
    getRoomList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, surgeryList])

  const updateReservationUser = () => {
    if (nvlNumber(userData?.user_reser_seq) < 1) setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "예약을 먼저 해주세요." } })
    else setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "예약을 취소하시겠습니까?", activateMode: "cancel" } })
  }

  useEffect(() => {
    getUserInfo();

    return () => { // 컴포넌트가 화면에서 사라졌을 때 초기화
      setVisitPathList([]);
      setSurgeryList([]);
      setReservationUser([]);
      setCategoryDetail([]);
      setCategoryShown([]); //시술 카테고리 안에 세부 시술
      setRoomList([]);
      setUserInfo([]);
      setReservationList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReservationUser = (reser_seq) => {
    let params = nvl(reser_seq) === "" ? userData.user_reser_seq : reser_seq;

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data.dataList[0]

        setReservationUser({
          reser_seq: result.reser_seq,
          user_seq: result.user_seq,
          sub_tc_seq: result.sub_tc_seq,
          reser_date: moment(result.reser_date).format("YYYY-MM-DD"),
          hour: result.reser_time.split(":")[0],
          min: result.reser_time.split(":")[1],
          surgery_category: result?.goods_info?.split(",")[4]?.split(":")[1],
          surgery_category_detail: result?.goods_info?.split(",")[0]?.split(":")[1],
          manager: result.receptionist_seq,
          visitPath: result.visit_path,
          memo: result.reser_description,
          parent_code: result.parent_code,
          treatment_room: result.treatment_room,
          cycle_info: nvl(result?.cycle_info) === '' ? '' : result?.cycle_info.slice(-1) === 'w' ? '2' : result.cycle_info.slice(-1) === 'm' ? '3' : '1',
          week: nvl(result?.cycle_info) === '' ? '' : result.cycle_info.slice(-1) === 'w' ? result.cycle_info : '',
          month: nvl(result?.cycle_info) === '' ? '' : result.cycle_info.slice(-1) === 'm' ? result.cycle_info : ''
        });

        setSelectedValue(response.data.dataList[0]?.treatment_room?.substr(0, 6) + "000");
        const goods_seq = response.data.dataList[0]?.goods_info?.split(",")[0]?.split(":")[1];
        const newArray = categoryDetail.filter(el => Number(goods_seq) === el.goods_seq);
        setCategoryShown(newArray);
      } else {
        console.log("예약정보를 가져오지 못했습니다.");
      }
    }

    request.get(`/api/reservation/${params}?listType=F`, null, successHandler);
  }

  useEffect(() => {
    if (nvl(userData.user_reser_seq) !== "" && userData.user_reser_seq !== "0" && nvlNumber(userData.user_reser_seq) !== 0){
      getReservationUser(userData.user_reser_seq);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.user_reser_seq, categoryDetail, selectedValue, surgeryList])


  return (
    <>
      <section className="com_popup type_normal pop_reservation_add PopReservationAdd active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container m">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">예약하기</h1>
            </div>
            <div className="pop_body">
              <article className="com_custom_info">
                <p className="item">{userInfo?.user_name} ({convertGender(userInfo.user_gender)}/{nvl(userInfo?.user_jumin) === "" ? " - " :  (userInfo?.user_jumin) === "" ? " - "  : convertJuminToAge((decrypt(userInfo?.user_jumin).substring(0, 6) + "-" + decrypt(userInfo?.user_jumin).substring(6, 13)))})</p>
                <p className="item">{userInfo.user_mobile}</p>
              </article>
              <ul className="com_input_section line">
                <li className="tit_field">예약 일자</li>
                <li className="input_field">
                  <article className="com_lnb2_right">
                    <div className="lnb2_search_wrap">
                      <section className="com_field type_normal">
                        <fieldset className="many">
                          <div className="datepicker_from_to" style={{ width: '130px' }}>
                            <input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name="reser_date" value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo.reser_date : nvl(reservationUser?.reser_date)}
                              onChange={reservationHandler} onClick={() => { setViewType('from') }} readOnly style={{ border: 'none', backgroundColor: 'transparent' }} />
                            {popupCalendarHTML()}
                          </div>
                        </fieldset>
                      </section>
                    </div>
                  </article>
                </li>
                <li className="tit_field">예약 시간</li>
                <li className="input_field input_wrap">
                  <select className="select" name="hour" value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo.hour : reservationUser.hour} onChange={reservationHandler}>
                    <option value="">시간을 선택하세요</option>
                    {
                      hourArr.map((el, i) => (
                        <option key={i} value={el}>{el}시</option>
                      ))
                    }
                  </select>
                  <select className="select" name="min" onChange={reservationHandler} value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo.min : reservationUser.min}>
                    <option value="">분을 선택하세요</option>
                    {
                      minArr.map((el, i) => (
                        <option key={i} value={el}>{el}분</option>
                      ))
                    }
                  </select>
                </li>
                <li className="tit_field">진료 분야</li>
                <li className="input_field">
                  <select className="select" name="surgery_category" onChange={reservationHandler} value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo?.surgery_category || '' : reservationUser?.surgery_category || ''}>
                    <option value={0}>시술 카테고리 선택</option>
                    {
                      category?.map((el, i) => (
                        <option key={i} value={el.common_code}>{el.code_name}</option>
                      ))
                    }
                  </select>
                  <select className="select" name="surgery_category_detail" onChange={reservationHandler} value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo?.surgery_category_detail || '' : reservationUser?.surgery_category_detail || ''}>
                    <option value={0}>시술 선택</option>
                    {
                      categoryShown?.map((el, i) => (
                        <option key={i} value={el?.goods_seq}>{el?.goods_name}</option>
                      ))
                    }
                  </select>
                </li>
                <li className="tit_field">예약직원 / 경로</li>
                <li className="input_field input_wrap">
                  <select className="select" name="manager" value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo?.manager || '' : reservationUser?.manager || ''} onChange={reservationHandler}>
                    <option value={0}>예약직원 선택</option>
                    {managerList?.map((item) => { return <option key={item.manager_seq} value={item.manager_seq}>{item.manager_name}</option> })}
                  </select>
                  <select className="select" name="visitPath" value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo?.visitPath || '' : reservationUser?.visitPath || ''} onChange={reservationHandler}>
                    <option value={0}>경로 선택</option>
                    {visitPathList?.map((item) => { return <option key={item.code} value={item.code}>{item.name}</option> })}
                  </select>
                </li>
                <li className="input_field full">
                  <textarea className="textarea" placeholder="상세내용 입력" style={{ resize: "none" }} name="memo" value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo.memo : reservationUser?.memo} onChange={reservationHandler}></textarea>
                </li>
                <li className="tit_field">룸 배정</li>
                <li className="input_field input_wrap">
                  <select className="select" name="parent_code" key={reservationUser?.parent_code} value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo.parent_code || '' : reservationUser?.parent_code || ''} onChange={reservationHandler}>
                    <option>시술실 선택</option>
                    {surgeryList.map((el, i) => (
                      <option key={i} value={el.code}>{el.name}</option>
                    ))}
                  </select>
                  <select className="select" name="treatment_room" key={reservationUser?.treatment_room} value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo.treatment_room || '' : reservationUser?.treatment_room || ''} onChange={reservationHandler}>
                    <option>선택</option>
                    {roomList.map((el, i) => (
                      <option key={i} value={el.code}>{el.name}</option>
                    ))}
                  </select>
                </li>
                <li className="input_field input_wrap full line">
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="radio1" name="cycle_info" value="1" checked={nvlNumber(userData?.user_reser_seq) === 0 ? (reservationInfo.cycle_info === "1") : (reservationUser?.cycle_info === "1")} onChange={reservationHandler} />
                    <label className="btn_radio" htmlFor="radio1">&nbsp;</label>
                    <label htmlFor="radio1">1회</label>
                    <input type="radio" className="radio" id="radio2" name="cycle_info" value="2" checked={nvlNumber(userData?.user_reser_seq) === 0 ? (reservationInfo.cycle_info === "2") : reservationUser?.cycle_info === "2"} onChange={reservationHandler} />
                    <label className="btn_radio" htmlFor="radio2">&nbsp;</label>
                    <label htmlFor="radio2">주반복</label>
                    <select className="select" name="week" value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo.week : (reservationUser.week)} onChange={reservationHandler}
                      disabled={(reservationUser.cycle_info !== "2" ? true : false) && (reservationInfo.cycle_info !== "2" ? true : false)}>
                      <option value="0">선택</option>
                      <option value="1w">1주</option>
                      <option value="2w">2주</option>
                      <option value="3w">3주</option>
                    </select>
                    <input type="radio" className="radio" id="radio3" name="cycle_info" value="3" checked={nvlNumber(userData?.user_reser_seq) === 0 ? (reservationInfo.cycle_info === "3") : reservationUser?.cycle_info === "3"} onChange={reservationHandler} />
                    <label className="btn_radio" htmlFor="radio3">&nbsp;</label>
                    <label htmlFor="radio3">월반복</label>
                    <select className="select" name="month" value={nvlNumber(userData?.user_reser_seq) === 0 ? reservationInfo.month : (reservationUser.month)} onChange={reservationHandler}
                      disabled={(reservationUser.cycle_info !== "3" ? true : false) && (reservationInfo.cycle_info !== "3" ? true : false)}>
                      <option value="0">선택</option>
                      <option value="1m">1달</option>
                      <option value="2m">2달</option>
                      <option value="3m">3달</option>
                    </select>
                  </fieldset>
                </li>
              </ul>
              <article className="com_list">
                <p className="list_tit">예약 내역</p>
                <div className="scroll">
                  <table className="list">
                    <colgroup><col width="39%" /><col /><col width="19.5%" /></colgroup>
                    <tbody>
                      {
                        reservationList != null && reservationList.length > 0 ?
                          reservationList.map((el, i) => (
                            <tr key={i} onClick={() => getReservationUser(el.reser_seq)}>
                              <td>{moment(el.reser_date).format('YYYY-MM-DD')}&nbsp;&nbsp;{el.reser_time}</td>
                              <td className="tit">{nvl(el.treatment_name) === "" ? "-" : el.treatment_name}</td>
                              <td>{el.user_name}</td>
                            </tr>
                          ))
                          :
                          <tr>
                            <td colSpan="3">예약내역이 없습니다.</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
            <div className="pop_footer com_btn_wrap many two">
              {nvlNumber(userData?.user_reser_seq) === 0 ?
                <button type="button" className="com_btn l red " onClick={() => regReservation(1)}>예약 하기</button>
                :
                <>
                  <button type="button" className="com_btn l red " onClick={() => regReservation(2)}>예약 변경</button>
                  <button type="button" className="com_btn l darkgray" onClick={updateReservationUser}>예약취소</button>
                </>
              }
            </div>
          </div>
        </Draggable>
      </section>
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
    </>
  )
}

export default ReservationAdd