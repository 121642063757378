//* 마취중 환자 목록 added by wem 20230621 _ReserveList.js 복사해서 추가
import React, { useRef, useState, useEffect } from "react";
import Draggable from "react-draggable";
import { convertGender, convertJuminToAge, nvl } from "utils/Common.utils";
import { decrypt } from "utils/aes256.manager";


const AnesthesiaList = ({ setAnesthesiaPopBool, receptionList, timers }) => {

  const nodeRef = useRef(null);

  const [alarmTime, setAlarmTime] = useState([])

  const anesthesiaList = receptionList.filter(el => el.order_status === '013008000') // 마취중 환자 리스트


  useEffect(() => {
    const filteredTimers = timers.filter((timer) =>
      anesthesiaList.some(
        (anesthesia) =>
          timer.order_seq === anesthesia.order_seq && timer.value === 1
      )
    );
    const alarmTimes = filteredTimers.map((timer) => {
      const alarmInfo = {
        order_seq: timer.order_seq,
        totalAlarmTime: 0,
        curTime: 0
      };
      if (timer.alarmTime !== 0) { // 알람타임 설정했을 때
        const timeDiff = timer.alarmTime - timer.startTime;
        const alarmHour = Math.floor((timer.alarmTime % (60 * 60 * 24)) / (60 * 60));
        const alarmMinute = Math.floor((timer.alarmTime % (60 * 60)) / (60));
        alarmInfo.totalAlarmTime = alarmHour * 60 + alarmMinute;
      }
      if (timer.isLive) { // 마취중일 때
        const curTimeDiff = timer.curTime - timer.startTime;
        const curHour = Math.floor((curTimeDiff % (60 * 60 * 24)) / (60 * 60));
        const curMinute = Math.floor((curTimeDiff % (60 * 60)) / (60));
        const curTimes = curHour * 60 + curMinute;
        alarmInfo.curTime = curTimes;
      }

      return alarmInfo;
    });
    setAlarmTime(alarmTimes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receptionList, timers])



  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_reserve_list active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => setAnesthesiaPopBool(false)}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">{`마취중(${anesthesiaList.length})`}</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <ul className="scroll">
                {anesthesiaList.length > 0 ? anesthesiaList.map((el, index) => (
                  <li className="item" key={el.order_seq}>
                    {`${index + 1}. ${el.member_info?.user_name} (${convertGender(el.member_info?.user_gender)}/${nvl(el.member_info?.user_jumin) === "" ? " - " : convertJuminToAge((decrypt(el.member_info?.user_jumin).substring(0, 6) + "-" + decrypt(el.member_info?.user_jumin).substring(6, 13)))})`} {alarmTime.map(alarm => alarm.order_seq === el.order_seq && (<React.Fragment key={alarm.order_seq}><span className="txt_red">{alarm.totalAlarmTime}분</span> {alarm.curTime}분</React.Fragment>))}
                  </li>
                )) : <li className="item no_data">마취중 환자가 없습니다.</li>}
              </ul>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default AnesthesiaList