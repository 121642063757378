//* 서류발급
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomAlert from "atoms/common/CustomAlert";
import MediConfirm from "./_DocumentMediConfirm"//진료확인서
import MediRecord from "./_DocumentMediRecord"//진료비 세부 내역서 , 진료비 영수증
import MediNote from "./_DocumentMediNote" // 진단서 소견서
import DocumentPrescription from "./_DocumentPrescription"//처방전
import request from '../../utils/Request.utils';
import { decrypt } from "../../utils/aes256.manager";
import PopupCalendar from "atoms/common/PopupCalendar";
import { convertJuminToAge, convertGender, nvl, nvlNumber, generateRandomNumber } from "../../utils/Common.utils";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import ReactDaumPost from 'react-daumpost-hook';
import { useReactToPrint } from 'react-to-print';
import MediConfirmPrint from "./_MediConfirm" // 진료확인서 인쇄화면
import MediFeeDetailPrint from "./_MediFeeDetail.js"//진료비 세부 내역서 인쇄화면
import MediRecordPrint from "./_MediRecord.js"//진료기록부 인쇄화면
import MediCertificatePrint from "./_MediCertificate.js"//진단서 인쇄화면
import MediNotePrint from "./_MediNote.js"//소견서 인쇄화면
import PrescriptionPrint from "./_Prescription"; // 처방전 인쇄화면
import MediReceiptPrint from "./_MediReceipt.js"//진료비 계산서 영수증 인쇄화면


const Document = ({ onClose, btnValue, prescriptionInfo, setPrescriptionInfo, prescriptionInit, medicineSetList, setMedicineSetList, userInfo, setUserInfo }) => {
  const componentRef = useRef();
  const customers = useRef([]);

  const stateCustomer = useSelector(state => state.Customer);
  const today = moment(new Date()).format("YYYYMMDD");


  // 공통?
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [updateBool, setUpdateBool] = useState(false) // 발급내역 [보기] 선택 시 상태
  const [popup, setPopup] = useState({});
  const [searchCustomer, setSearchCustomer] = useState(''); //고객명
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [customerList, setCustomerList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedDoctorName, setSelectedDoctorName] = useState('');
  const [searchData, setSearchData] = useState(""); // 검색어
  const [treatmentDateList, setTreatmentDateList] = useState([]);
  const [treatmentOrderSeqList, setTreatmentOrderSeqList] = useState([]);
  const [issuanceList, setIssuanceList] = useState([]) // 발급내역


  // 처방전 관련
  const [medicines, setMedicines] = useState([]); // select box 아래의 약품(세트X) 목록

  const [dateType, setDateType] = useState('hyphen');
  const [viewType, setViewType] = useState('');
  const [medicalLicense, setMedicalLicense] = useState('');
  const [medicalTreatment, setMedicalTreatment] = useState([]);
  const [medicalTreatmentList, setMedicalTreatmentList] = useState([]);
  const [selectedTreatmentIndex, setSelectedTreatmentIndex] = useState(0);
  const [selectedArray, setSelectedArray] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]); // 진료세부내역서 
  const [orderSeq, setOrderSeq] = useState('');
  const [issueSeq, setIssueSeq] = useState('');
  const [selectedRecordArray, setSelectedRecordArray] = useState([]);

  const treatmentConfirmationInit = {
    manager_seq: "",
    treatment_start: "",
    treatment_end: "",
    treatment_date: "",
    disease_code: "",
    treatment_detail: "",
    paper_remark: "",
    diagnosis_type: "017001000",
    disease_info_yn: "N"
  }
  const [treatmentConfirmation, setTreatmentConfirmation] = useState(treatmentConfirmationInit); //진료확인서
  const treatmentReportInit = {
    medical_history_yn: "N",
    symptom_course_yn: "N",
    disease_output_yn: "N",
    selected_treatment_day_yn: "N",
    clinic_hours_yn: "N",
    consultation_contents_yn: "N"
  }
  const [treatmentReport, setTreatmentReport] = useState(treatmentReportInit); //진료비 세부내역서
  const medicalCertificateInit = { //진단서, 소견서
    manager_seq: "",
    occur_date: "",
    treatment_date: moment(new Date()).format("YYYY-MM-DD"),
    disease_code: "",
    treatment_detail: "",
    paper_remark: "",
    diagnosis_type: "017001000",
    disease_info_yn: "N"
  }
  const [medicalCertificate, setMedicalCertificate] = useState(medicalCertificateInit);

  const [buttons, setButtons] = useState([
    { text: "진료확인서", value: 0, active: btnValue === 6 ? false : true },
    { text: "진료비 세부내역서", value: 1, active: false },
    { text: "진료비영수증", value: 2, active: false },
    { text: "진료기록부", value: 3, active: false },
    { text: "진단서", value: 4, active: false },
    { text: "소견서", value: 5, active: false },
    { text: "처방전", value: 6, active: btnValue === 6 ? true : false }
  ]);


  const hospitalInit = {
    hospital_name: "톡스앤필의원 대전둔산점", // 병원명
    hospital_phone: "042-719-7827", // 병원전화번호
    hospital_address: "대전광역시 서구 대덕대로 223", // 병원주소 
    hospital_address_detail: "대우토피아빌딩 403호", // 병원 상세주소
  }

  const [hospitalInfo, setHospitalInfo] = useState(hospitalInit) // 병원정보 - Document 각 서류 공통

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })

  const closePopup = () => {
    setViewType('');
  }

  const getCalendar = (value) => {
    if (viewType === 'from') setTreatmentConfirmation((prev) => ({ ...prev, treatment_start: value }));
    else {
      if (value < treatmentConfirmation.treatment_start) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시작일보다 나중인 날짜를 선택해주세요." } })
        return false;
      }

      setTreatmentConfirmation((prev) => ({ ...prev, treatment_end: value }));
    }
  }


  const popupCalendarHTML = () => {
    let retArr = [];

    if (viewType === 'from' || viewType === 'to') {
      retArr.push(<PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={dateType} />)
    } else retArr.push(null);

    return retArr;
  }


  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  };

  const onPopupClick = (e) => {
    setPopup((prev) => { return { ...prev, [e.target.id]: true } });
  };

  const clickHandler = (value) => {
    let newSubButton = [];

    for (const button of buttons) {
      if (button.value === value) button.active = true;
      else button.active = false;

      newSubButton = [...newSubButton, button]
    }

    setButtons(newSubButton);
  }

  const closeCustomAlert = (() => {
    setAlertLayerPopup((prev) => { return { ...prev, visible: false } })
  });

  const filterText = () => {
    const result = customers.current.filter(item => item.user_name.includes(searchCustomer) || item.user_mobile.includes(searchCustomer));
    setCustomerList(result);
    setSelectedTreatmentIndex(0);
  }

  useEffect(() => {
    filterText(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCustomer]);


  const getCustomerList = () => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data.dataList;
        result.forEach((item) => {
          item.checked = false;
          item.age = nvl(item.user_jumin) === "" ? " - " : convertJuminToAge(decrypt(item.user_jumin));
          item.gender = convertGender(item.user_gender);
        });
        customers.current = result;
        setCustomerList(result);
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/user`, null, successHandler, failHandler, null);
  }

  const selectCustomerHandler = (item) => {
    setSelectedCustomer({ ...item, pubNum: generateRandomNumber() })
    getOrderRecord(0, item.user_seq);
    // 처방전 발급내역 
    // buttons[6].active && getIssuance(item.user_seq)
  }

  // 발급내역 가져오기 (모든 문서 공통)
  const getIssuance = (user_seq, order_date) => {
    let params = {
      user_seq: nvlNumber(user_seq) !== 0 ? user_seq : (nvl(selectedCustomer) === "" ? userInfo.user_seq : selectedCustomer.user_seq)
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setIssuanceList(response.data.dataList)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }
    // buttons[1].active ? request.get(/api/1) : buttons[2].active ?request.get(/api/2) 이런식으로 호출 가능
    if (buttons[6].active) {
      request.get(`/api/medicine/user/prescription?user_seq=${params.user_seq}&reg_date=${moment(order_date).format("YYYY-MM-DD")}`, null, successHandler, failHandler)
    } else if (buttons[0].active) {
      request.get(`/api/document/confirmation?user_seq=${params.user_seq}&reg_date=${moment(order_date).format("YYYY-MM-DD")}`, null, successHandler, failHandler)
    } else if (buttons[1].active || buttons[2].active || buttons[3].active) {
      request.get(`/api/document/report?user_seq=${params.user_seq}&reg_date=${moment(order_date).format("YYYY-MM-DD")}&report_type=${buttons[1].active ? "C" : buttons[2].active ? "B" : "A"}`, null, successHandler, failHandler)
    } else if (buttons[4].active || buttons[5].active) {
      request.get(`/api/document/medicalCertificate?user_seq=${params.user_seq}&reg_date=${moment(order_date).format("YYYY-MM-DD")}&report_type=${buttons[4].active ? "A" : "B"}`, null, successHandler, failHandler)
    }
  }

  // 진료일자 가져오기
  const getOrderRecord = (num, user_seq) => {
    if (num === 0) {
      if (Object.keys(selectedArray).length !== 0) { //서류발급에서 기본으로 호출
        if (nvl(treatmentConfirmation.treatment_start) === "") {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시작일을 선택해주세요." } })
        } else if (nvl(treatmentConfirmation.treatment_end) === "") {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "마감일을 선택해주세요." } })
        }
      }
    } else if (num === 1) {
      if (nvl(treatmentReport.treatment_start) === "") {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "시작일을 선택해주세요." } })
      } else if (nvl(treatmentReport.treatment_end) === "") {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "마감일을 선택해주세요." } })
      }
    }

    let params = {
      user_seq: nvlNumber(user_seq) !== 0 ? user_seq : (nvl(selectedCustomer) === "" ? userInfo.user_seq : selectedCustomer.user_seq),
      start_date: num === 0 ? treatmentConfirmation.treatment_start : treatmentReport.treatment_start,
      end_date: num === 0 ? treatmentConfirmation.treatment_end : treatmentReport.treatment_end,
      group_by: "G"
    }

    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data.dataList;

        setMedicalTreatmentList(result);

        const groupedByYear = {};
        let dateArr = []
        let orderseqArr = []

        result.forEach((item) => {
          if (treatmentConfirmation.treatment_start && treatmentConfirmation.treatment_end) {
            const treatmentDate = moment(item.order_date).format('YYYY-MM-DD');
            dateArr.push(treatmentDate)
            orderseqArr.push(item.order_seq)
          }

          if (treatmentReport.treatment_start && treatmentReport.treatment_end) {
            const treatmentDate = moment(item.order_date).format('YYYY-MM-DD');
            dateArr.push(treatmentDate)
            orderseqArr.push(item.order_seq)
          }

          const orderDate = moment(item.order_date);
          const year = orderDate.format('YYYY');

          if (!groupedByYear[year]) {
            groupedByYear[year] = [];
          }

          groupedByYear[year].push(item);
        });

        const medicalTreatmentArray = Object.entries(groupedByYear).map(([year, data]) => ({ year, data }));

        setMedicalTreatment(medicalTreatmentArray);
        setTreatmentDateList(dateArr);
        setTreatmentOrderSeqList(orderseqArr);
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/document?user_seq=${params.user_seq}&group_by=${params.group_by}&start_date=${params.start_date}&end_date=${params.end_date}`, params, successHandler, failHandler, null);
  }

  const getUserInfo = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) { // ok 코드
        const user = response.data.dataList[0];

        setUserInfo({
          pubNum: generateRandomNumber(),
          user_seq: user.user_seq,
          chart_code: user.chart_code,
          user_name: user.user_name,
          user_gender: user.user_gender,
          user_level: user.user_level,
          jumin_1: decrypt(user.user_jumin).substring(0, 6),
          jumin_2: decrypt(user.user_jumin).substring(6, 13),
          user_dob: user.user_dob,
          user_mobile: user.user_mobile,
          user_photo: nvlNumber(user.up_file_seq),
          user_photo_path: user.file_path,
          user_address: user.user_address,
          user_address_detail: user.user_address_detail,
          user_reser_seq: user.user_reser_seq,
          main_tc_seq: user.main_tc_seq,
          sub_tc_seq: user.sub_tc_seq
        })

        getOrderRecord(0, user.user_seq);
        // getIssuance(user.user_seq)
      } else {
        console.log("데이터가 없습니다..");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "사용자 정보를 가져오던 중 에러가 발생하였습니다." } })
    }

    request.get(`/api/user/${nvlNumber(stateCustomer?.user_seq)}`, null, successHandler, failHandler);
  }


  useEffect(() => {
    getUserInfo();
    getMedicines();
    getCustomerList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getDoctorList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data;
        setDoctorList(result);
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/manager?assignment_position=010003000`, null, successHandler);
  }


  useEffect(() => {
    getDoctorList();
  }, [selectedDoctor])


  // 면허번호 업데이트
  const handleDoctorChange = (e) => {
    const { name, value } = e.target;
    setSelectedDoctor(value);

    const doctor = doctorList.find(item => item.manager_seq === Number(value));
    doctor ? setMedicalLicense(doctor.medical_license) : setMedicalLicense('');
    setSelectedDoctorName(doctor?.manager_name);
    setTreatmentConfirmation((prev) => ({ ...prev, [name]: value }));
  };


  // 약품 가져오기
  const getMedicines = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setMedicines(response.data);
      } else {
        console.log("약품 정보를 불러오지 못했습니다");
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get(`/api/medicine/M?searchData=${searchData}`, null, successHandler, failHandler, null, null);
  }

  // 고객정보 주소
  const postConfig = {
    onComplete: (data) => {

      let addressType = data.userSelectedType;
      let postal = data.zonecode;
      let fullAddress = data.address;
      let extraAddress = '';

      if (data.buildingName !== '') extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);

      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');

      // console.log(addressType + " | " + postal + " | " + fullAddress)

      setUserInfo({ ...userInfo, user_address: fullAddress })
    },
  }

  // 병원정보 주소
  const postConfig2 = {
    onComplete: (data) => {
      // console.log(data)

      let addressType = data.userSelectedType;
      let postal = data.zonecode;
      let fullAddress = data.address;
      let extraAddress = '';


      if (data.buildingName !== '') extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);

      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');

      console.log(addressType + " | " + postal + " | " + fullAddress)

      setHospitalInfo({ ...hospitalInfo, hospital_address: fullAddress })
    },
  }

  // console.log("treatmentDateList1 ",treatmentDateList)

  // 문서 저장
  const saveDocument = () => {
    if (buttons[6].active) { // 처방전
      // 당일 진료내역 유무 체크
      if (medicalTreatmentList.filter(el => moment(el.order_date).format("YYYY-MM-DD") === moment(prescriptionInfo.prescription_date).format("YYYY-MM-DD")).length === 0) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료일자를 선택해주세요." } })

        return
      }
      if (medicineSetList.length < 1) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "처방할 약품을 추가해 주세요." } })

        return
      }
      if (prescriptionInfo.disease_code.length < 1) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "차트에서 질병코드를 선택해주세요." } })

        return
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "처방전을 저장하시겠습니까?", activateMode: "savePrescription" } })
    } else if (buttons[0].active) { //진료확인서
      if (nvl(treatmentConfirmation.treatment_start) === "" || nvl(treatmentConfirmation.treatment_end) === "") {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료기간을 조회해주세요", activateMode: "savePrescription" } })
      } else if (treatmentDateList.length === 0) { // 진료기간 조회 (selectArray 없음)
        if (nvl(selectedArray[0]?.order_seq) === "") {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료일자를 선택하세요", activateMode: "savePrescription" } })
        } else {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "진료확인서를 저장하시겠습니까?", activateMode: "saveTreatmentConfirm" } })
        }
      } else {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "진료확인서를 저장하시겠습니까?", activateMode: "saveTreatmentConfirm" } })
      }
    } else if (buttons[1].active || buttons[2].active || buttons[3].active) { //진료비 세부내역서 ~ 진료기록부
      if (nvl(checkedItems[0]?.order_seq) === "") {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료일자를 선택하세요", activateMode: "savePrescription" } })
      } else {
        const activeButton = buttons[1].active ? buttons[1] : buttons[2].active ? buttons[2] : buttons[3];
        const objectMarker = buttons[2].active ? "을" : "를";
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: `${activeButton.text}${objectMarker} 저장하시겠습니까?`, activateMode: "saveTreatmentReport" } })
      }
    } else if (buttons[4].active || buttons[5].active) { //진단서, 소견서
      if (selectedArray.length > 0) {
        if (nvl(medicalCertificate.occur_date) === "") {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "발병일을 선택하세요", activateMode: "savePrescription" } })
        } else {
          setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: `${buttons[4].active ? buttons[4].text : buttons[5].text}를 저장하시겠습니까?`, activateMode: "saveMedicalCertificate" } })
        }
      } else if (nvl(medicalCertificate.occur_date) === "") {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "발병일을 선택하세요", activateMode: "savePrescription" } })
      } else {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: `${buttons[4].active ? buttons[4].text : buttons[5].text}를 저장하시겠습니까?`, activateMode: "saveMedicalCertificate" } })
      }
    }
  }

  const returnAlertValue = (value) => {
    // 서류 저장시에 주소|상세주소 형태로 저장 => 발급내역 상세보기 시 |로 분리
    let commonParams = {
      user_seq: nvl(selectedCustomer) === "" ? userInfo.user_seq : selectedCustomer.user_seq,
      phone_no: nvl(selectedCustomer) === "" ? userInfo.user_mobile : selectedCustomer.user_mobile,
      address: nvl(selectedCustomer) === "" ? `${userInfo.user_address}|${userInfo.user_address_detail}` : `${selectedCustomer.user_address}|${selectedCustomer.user_address_detail}`,
      hospital_name: hospitalInfo.hospital_name,
      hospital_phone: hospitalInfo.hospital_phone,
      hospital_address: `${hospitalInfo.hospital_address}|${hospitalInfo.hospital_address_detail}`,
      reg_manager_seq: managerInfo.seq
    };

    if (value === "OK") {
      if (alertLayerPopup.activateMode === "savePrescription") { // 처방전 저장
        let params = {
          ...commonParams,
          prescription_use: prescriptionInfo.prescription_use,
          prescription_date: prescriptionInfo.prescription_date, // 교육일이 아니라 진료일자로 사용, 교욱일은 reg_date, 수정시에는 modi_date로 사용
          period_amount: prescriptionInfo.period_amount,
          medical_history_yn: prescriptionInfo.medical_history_yn,
          resident_no_yn: prescriptionInfo.resident_no_yn,
          including_injections_yn: prescriptionInfo.including_injections_yn,
          use_yn: "N", // 출력 유무 체크 
          medicine_set: medicineSetList
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            if (response.data.status === "issue") setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "처방전이 저장되었습니다." } })
            else if (response.data.status === "reIssue") setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "새로운 처방전이 저장되었습니다." } })
            else if (response.data.status === "update") setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "처방전이 수정되었습니다." } })

            // 발급 시퀀스 인쇄화면으로 저장
            setPrescriptionInfo((prev => ({ ...prev, issue_seq: response.data.issue_seq })))
          }
        }
        const failHandler = (response) => {
          console.log("에러" + response)
        }
        request.post("/api/medicine/prescription", params, successHandler, failHandler, null, null)
      } else if (alertLayerPopup.activateMode === "saveTreatmentConfirm") { // 진료확인서 저장
        let params = {
          ...commonParams,
          order_seq: selectedArray.length > 0 ? selectedArray[0].order_seq : treatmentOrderSeqList[0],
          issue_seq: nvl(selectedCustomer) === "" ? userInfo.pubNum : selectedCustomer.pubNum,
          manager_seq: nvl(selectedArray[0].manager_seq) === "" ? treatmentConfirmation.manager_seq : selectedArray[0].manager_seq,
          treatment_start: selectedArray.length > 0 ? treatmentConfirmation.treatment_start : treatmentDateList[0],
          treatment_end: selectedArray.length > 0 ? treatmentConfirmation.treatment_end : treatmentDateList[treatmentDateList.length - 1],
          treatment_date: selectedArray.length > 0 ? treatmentConfirmation.treatment_start : treatmentDateList.join(' | '),
          disease_code: `${selectedArray[0].disease_code}:${selectedArray[0].disease_name}`,
          treatment_detail: treatmentConfirmation.treatment_detail,
          paper_remark: treatmentConfirmation.paper_remark,
          diagnosis_type: treatmentConfirmation.diagnosis_type,
          disease_info_yn: treatmentConfirmation.disease_info_yn,
          use_yn: "N" // 출력 유무 체크 
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "진료확인서가 저장되었습니다." } })
            setTreatmentConfirmation(treatmentConfirmationInit);
          }
        }
        const failHandler = (response) => {
          console.log("에러" + response)
        }
        request.post("/api/document/confirmation", params, successHandler, failHandler, null, null)
      } else if (alertLayerPopup.activateMode === "saveTreatmentReport") { // 진료비 세부내역서, 진료비영수증, 진료기록부
        let params = {
          ...commonParams,
          issue_seq: nvl(selectedCustomer) === "" ? userInfo.pubNum : selectedCustomer.pubNum,
          treatment_date: nvl(treatmentReport.treatment_start || treatmentReport.treatment_end) === "" ? moment(checkedItems[0]?.order_date).format("YYYY-MM-DD") : treatmentReport.treatment_start,
          doctor_name: checkedItems[0]?.manager_name,
          disease_code: `${checkedItems[0]?.disease_code}:${checkedItems[0]?.disease_name}`,
          order_seq: checkedItems[0]?.order_seq,
          report_type: buttons[1].active ? "C" : buttons[2].active ? "B" : "A", //진료기록부(A), 진료비 영수증(B), 진료비 세부 내역서(C)
          medical_history_yn: treatmentReport.medical_history_yn,
          symptom_course_yn: treatmentReport.symptom_course_yn,
          disease_output_yn: treatmentReport.disease_output_yn,
          selected_treatment_day_yn: treatmentReport.selected_treatment_day_yn,
          clinic_hours_yn: treatmentReport.clinic_hours_yn,
          consultation_contents_yn: treatmentReport.consultation_contents_yn,
          use_yn: "N" // 출력 유무 체크 
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            const activeButton = buttons[1].active ? buttons[1] : buttons[2].active ? buttons[2] : buttons[3];
            const objectMarker = buttons[2].active ? "을" : "를";
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `${activeButton.text}${objectMarker} 저장하였습니다.` } })
            setTreatmentReport(treatmentReportInit);
          }
        }
        const failHandler = (response) => {
          console.log("에러" + response)
        }
        request.post("/api/document/report", params, successHandler, failHandler, null, null)
      } else if (alertLayerPopup.activateMode === "saveMedicalCertificate") { // 진단서, 소견서
        let params = {
          ...commonParams,
          issue_seq: nvl(selectedCustomer) === "" ? userInfo.pubNum : selectedCustomer.pubNum,
          order_seq: selectedArray[0]?.order_seq,
          report_type: buttons[4].active ? "A" : "B", //진단서(A), 소견서(B)
          manager_seq: nvl(selectedArray[0].manager_seq) === "" ? medicalCertificate.manager_seq : selectedArray[0].manager_seq,
          occur_date: nvl(medicalCertificate.occur_date) === "" ? moment(selectedArray[0]?.order_date).format("YYYY-MM-DD") : medicalCertificate.occur_date,
          treatment_date: nvl(medicalCertificate.treatment_date) === "" ? moment(selectedArray[0]?.order_date).format("YYYY-MM-DD") : medicalCertificate.treatment_date,
          disease_code: `${selectedArray[0]?.disease_code}:${selectedArray[0]?.disease_name}`,
          diagnosis_type: medicalCertificate.diagnosis_type,
          treatment_detail: medicalCertificate.treatment_detail,
          paper_remark: medicalCertificate.paper_remark,
          use_yn: "N" // 출력 유무 체크 
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `${buttons[4].active ? buttons[4].text : buttons[5].text}를 저장하였습니다.` } })
            setMedicalCertificate(medicalCertificateInit);
          }
        }
        const failHandler = (response) => {
          console.log("에러" + response)
        }
        request.post("/api/document/medicalCertificate", params, successHandler, failHandler, null, null)
      }
    }
  }

  // 진료일자 선택하기
  const handleTreatmentSelection = (e, order_seq, user_seq, order_date) => {
    if (order_seq === orderSeq) {
      setOrderSeq('');
      e.target.checked = false;
      setSelectedTreatmentIndex('')
      setSelectedArray([])
      setTreatmentConfirmation((prev) => ({ ...prev, manager_seq: '', treatment_start: '', treatment_end: '' }))
      setMedicalLicense('')
      setMedicalCertificate((prev) => ({ ...prev, manager_seq: '', occur_date: '' }))
      // 처방전 진룡일자 선택
      buttons[6].active && setPrescriptionInfo(prescriptionInit)
    } else {
      setOrderSeq(order_seq);
      setSelectedTreatmentIndex(order_seq);
      const copy = [...medicalTreatmentList];
      let selectArray = copy.filter((el) => el.order_seq === order_seq);

      // disease_code를 배열로 변환
      const diseaseCodeArray = nvl(selectArray[0].disease_code) === "" ? [] : selectArray[0].disease_code?.split(",").map(code => code);

      // disease_name을 배열로 변환
      const diseaseNameArray = nvl(selectArray[0].disease_name) === "" ? [] : selectArray[0].disease_name?.split(",");

      const newData = {
        disease_code: diseaseCodeArray,
        disease_name: diseaseNameArray,
        disease_seq: selectArray[0].disease_seq,
        manager_name: selectArray[0].manager_name,
        manager_seq: selectArray[0].manager_seq,
        medical_license: selectArray[0].medical_license,
        order_date: selectArray[0].order_date,
        order_seq: selectArray[0].order_seq,
        user_seq: selectArray[0].user_seq
      };

      setSelectedArray([newData]);
      // console.log(moment(order_date).format("YYYY-MM-DD"))
      setTreatmentConfirmation((prev) => ({ ...prev, manager_seq: nvl(selectArray[0]?.manager_seq) === "" ? "" : selectArray[0]?.manager_seq, treatment_start: moment(selectArray[0]?.order_date).format("YYYY-MM-DD"), treatment_end: moment(selectArray[0]?.order_date).format("YYYY-MM-DD") }))
      setMedicalLicense(selectArray[0]?.medical_license)
      setTreatmentReport((prev) => ({ ...prev, doctor_name: selectArray[0]?.manager_name, treatment_date: moment(selectArray[0]?.order_date).format("YYYY-MM-DD"), disease_code: `${selectedArray.disease_code}:${selectedArray.disease_name}` }))
      setMedicalCertificate((prev) => ({ ...prev, manager_seq: nvl(selectArray[0]?.manager_seq) === "" ? "" : selectArray[0]?.manager_seq, occur_date: moment(selectArray[0]?.order_date).format("YYYY-MM-DD")/*, treatment_date: moment(selectArray[0]?.order_date).format("YYYY-MM-DD")*/ }))
      // 처방전 진료일자 선택 && 진료일 질병코드 정보 가져오기
      buttons[6].active && setPrescriptionInfo((prev) => ({ ...prev, prescription_date: moment(order_date).format("YYYY-MM-DD"), disease_code: diseaseCodeArray?.length > 0 ? diseaseCodeArray : "" }))
      // 발급내역 가져오기
      getIssuance(user_seq, order_date)
    }
  };
  const handleTreatmentConfirm = (e, num) => {
    const { name, value } = e.target;

    num === 0 ? setTreatmentConfirmation((prev) => ({ ...prev, [name]: value })) : setMedicalCertificate((prev) => ({ ...prev, [name]: value }));

    const doctor = doctorList.find(item => item.manager_seq === Number(value));
    doctor ? setMedicalLicense(doctor.medical_license) : setMedicalLicense('');
  }


  const handleCheckedHandler = (e) => {
    const { name, checked } = e.target;
    (name === "disease_info_yn" && checked) ? setTreatmentConfirmation((prev) => ({ ...prev, [name]: "Y" })) : setTreatmentConfirmation((prev) => ({ ...prev, [name]: "Y" }));

  }

  // 서류발급(우선은 처방전) 인쇄 유무 업데이트
  const updatePrintYn = () => {
    if (buttons[6].active) {
      let params = {
        issue_seq: prescriptionInfo.issue_seq,
        use_yn: "Y", // 출력 유무 체크
      }

      const successHandler = (response) => {
        if (response.messageCode === 200) {
          console.log(response)
        }
      }

      const failHandler = (response) => {
        console.log("에러" + response)
      }

      request.put("/api/medicine/prescription", params, successHandler, failHandler, null, null)
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: buttons[0].active ? `${buttons[0].text}_${today}` : buttons[1].active ? `${buttons[1].text}_${today}` : buttons[2].active ? `${buttons[2].text}_${today}` : buttons[3].active ? `${buttons[3].text}_${today}` : buttons[4].active ? `${buttons[4].text}_${today}` : buttons[5].active ? `${buttons[5].text}_${today}` : `${buttons[6].text}_${today}`,
    // onAfterPrint: () => { // 인쇄 팝업창에서 실제 확인/취소 구분 못함 => windows.onAfterPrint로 변경
    //   // 여기서 각 테이블 프린트 여부 컬럼 Y로 업데이트 처리 - 처방전은 필수!! 
    //   // 각 서류 업데이트 함수 만들어서 조건별로 처리
    //   // if(buttons[0].active )
    //   // if(buttons[1].active )
    //   // if(buttons[2].active )
    //   // if(buttons[3].active )
    //   // if(buttons[4].active )
    //   // if(buttons[5].active )
    //   buttons[6].active && updatePrintYn()
    // }
  });

  const previewPrint = () => {

    // 각 서류별 발급 조건 처리
    // if(buttons[0].active )
    // if(buttons[1].active )
    // if(buttons[2].active )
    // if(buttons[3].active )
    // if(buttons[4].active )
    // if(buttons[5].active )
    if (buttons[6].active && nvl(prescriptionInfo.issue_seq) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "처방전을 저장하거나 저장된 처방전을 선택해주세요." } })

      return
    }

    // 각 조건 충족시 미리보기 및 인쇄 가능 처리
    handlePrint()

    // 인쇄 후 테이블 컴럼 Y 처리 => 실제 인쇄 구분
    window.onafterprint = () => {
      // if(buttons[0].active )
      //   // if(buttons[1].active )
      //   // if(buttons[2].active )
      //   // if(buttons[3].active )
      //   // if(buttons[4].active )
      //   // if(buttons[5].active )
      buttons[6].active && updatePrintYn()
    }
  }
  // 발급내역 보기 
  const setDocumentType = (document) => {
    if (document.issue_seq === issueSeq) {
      setIssueSeq('');
      setPrescriptionInfo(prescriptionInit)
      setMedicineSetList('')
      setTreatmentConfirmation(treatmentConfirmationInit)
      setTreatmentReport(treatmentReportInit)
      setMedicalCertificate(medicalCertificateInit)
    } else {
      setIssueSeq(document.issue_seq)
      setUpdateBool(true)
      if (buttons[6].active) { // 처방전
        // console.log(document)
        setPrescriptionInfo((prev) => ({
          ...prev,
          issue_seq: document.issue_seq,
          prescription_use: document.prescription_use,
          period_amount: document.period_amount,
          medical_history_yn: document.medical_history_yn,
          resident_no_yn: document.resident_no_yn,
          including_injections_yn: document.including_injections_yn
        }))
        setMedicineSetList(
          document.medicine_info.split("::").map(el => (
            {
              medi_seq: el.split("|")[0],
              pres_medi_seq: el.split("|")[1],
              medi_name: el.split("|")[2],
              medi_volume: el.split("|")[3],
              day_injection: el.split("|")[4],
              day_amount: el.split("|")[5],
              in_out_flag: el.split("|")[6],
              insurance_type: el.split("|")[7],
              initialPrice: el.split("|")[8],
              upper_limit_price: el.split("|")[4] * el.split("|")[5] * el.split("|")[8]
            }
          )))
      } else if (buttons[0].active) { // 진료확인서
        setTreatmentConfirmation({
          issue_seq: document.issue_seq,
          manager_seq: document.manager_seq,
          treatment_start: moment(document.treatment_start).format("YYYY-MM-DD"),
          treatment_end: moment(document.treatment_end).format("YYYY-MM-DD"),
          treatment_date: moment(document.treatment_date).format("YYYY-MM-DD"),
          diagnosis_type: document.diagnosis_type,
          treatment_detail: document.treatment_detail,
          paper_remark: document.paper_remark,
          period_amount: document.period_amount,
          disease_info_yn: document.disease_info_yn
        })
      } else if (buttons[1].active || buttons[2].active || buttons[3].active) { // 진료비 세부내역서, 진료비 영수증, 진료기록부
        setTreatmentReport({
          issue_seq: document.issue_seq,
          treatment_date: moment(document.treatment_date).format("YYYY-MM-DD"),
          doctor_name: document.doctor_name,
          disease_code: document.disease_code,
          treatment_detail: document.treatment_detail,
          paper_remark: document.paper_remark,
          medical_history_yn: document.medical_history_yn,
          symptom_course_yn: document.symptom_course_yn,
          disease_output_yn: document.disease_output_yn,
          selected_treatment_day_yn: document.selected_treatment_day_yn,
          clinic_hours_yn: document.clinic_hours_yn,
          consultation_contents_yn: document.consultation_contents_yn
        })
      } else if (buttons[4].active || buttons[5].active) { // 진단서, 소견서
        setMedicalCertificate({
          issue_seq: document.issue_seq,
          manager_seq: document.manager_seq,
          occur_date: document.occur_date,
          treatment_date: moment(document.treatment_date).format("YYYY-MM-DD"),
          disease_code: document.disease_code,
          diagnosis_type: document.diagnosis_type,
          treatment_detail: document.treatment_detail,
          paper_remark: document.paper_remark
        })
      }
      // 병원정보, 유저정보(주소)는 모든 서류 공통
      setHospitalInfo({
        hospital_name: document.hospital_name,
        hospital_phone: document.hospital_phone,
        hospital_address: document.hospital_address.split("|")[0],
        hospital_address_detail: document.hospital_address.split("|")[1],
      })
      setUserInfo({
        ...userInfo, pubNum: document.issue_seq, user_address: document.address.split("|")[0], user_address_detail: document.address.split("|")[1]
      })
    }
  }


  useEffect(() => {
    if (buttons[1].active || buttons[2].active || buttons[3].active) {
      getIssuance();
      setTreatmentConfirmation(treatmentConfirmationInit);
      setSelectedArray([])
    } else if (buttons[4].active || buttons[5].active) {
      setTreatmentReport(treatmentReportInit);
    } else if (buttons[6].active) {
      setMedicalCertificate(medicalCertificateInit)
      setSelectedArray([])
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttons])


  return (
    <>
      <div style={{ display: "none" }}>
        {buttons[0].active && <MediConfirmPrint ref={componentRef} selectedCustomer={selectedCustomer} userInfo={userInfo} selectedArray={selectedArray} medicalTreatment={medicalTreatment} treatmentConfirmation={treatmentConfirmation} treatmentDateList={treatmentDateList} hospitalInfo={hospitalInfo} medicalLicense={medicalLicense} selectedDoctorName={selectedDoctorName} />}
        {buttons[1].active && <MediFeeDetailPrint ref={componentRef} selectedCustomer={selectedCustomer} userInfo={userInfo} medicalTreatment={medicalTreatment} treatmentDateList={treatmentDateList} />}
        {buttons[2].active && <MediReceiptPrint ref={componentRef} selectedCustomer={selectedCustomer} userInfo={userInfo} medicalTreatment={medicalTreatment} hospitalInfo={hospitalInfo} />}
        {buttons[3].active && <MediRecordPrint ref={componentRef} selectedCustomer={selectedCustomer} userInfo={userInfo} selectedRecordArray={selectedRecordArray} hospitalInfo={hospitalInfo} />}
        {buttons[4].active && <MediCertificatePrint ref={componentRef} buttonNum={4} selectedCustomer={selectedCustomer} userInfo={userInfo} medicalCertificate={medicalCertificate} selectedArray={selectedArray} hospitalInfo={hospitalInfo} medicalLicense={medicalLicense} selectedDoctorName={selectedDoctorName} />}
        {buttons[5].active && <MediCertificatePrint ref={componentRef} buttonNum={5} selectedCustomer={selectedCustomer} userInfo={userInfo} medicalCertificate={medicalCertificate} selectedArray={selectedArray} hospitalInfo={hospitalInfo} medicalLicense={medicalLicense} selectedDoctorName={selectedDoctorName} />}
        {/* {buttons[5].active && <MediNotePrint ref={componentRef} />} */}
        {buttons[6].active && <PrescriptionPrint ref={componentRef} selectedCustomer={selectedCustomer} userInfo={userInfo} prescriptionInfo={prescriptionInfo} setPrescriptionInfo={setPrescriptionInfo} hospitalInfo={hospitalInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList}/>}
      </div>
      <section className="com_popup type_normal pop_document active">
        <div className="pop_container">
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
          <div className="pop_header">
            <h1 className="pop_title">서류 발급</h1>
          </div>
          <div className="pop_body">
            <article className="menu">
              {
                buttons.map((item, i) => (
                  <button key={"button_" + uuidv4()} className={item.active ? "com_btn blue m " : "com_btn lightgray m"} onClick={() => clickHandler(i)}>{item.text}</button>
                ))
              }
            </article>
            <article className="container" >
              <div className="customer_info">
                <ul className="com_input_section">
                  <li className="tit_field">발급번호</li>
                  <li className="input_field">{nvl(selectedCustomer) === "" ? userInfo.pubNum : selectedCustomer.pubNum}</li>
                  <li className="tit_field">환자명</li>
                  <li className="input_field">{nvl(selectedCustomer) === "" ? `${userInfo.user_name} (${convertGender(userInfo.user_gender)}/${nvl(userInfo.jumin_1) === "" ? " - " : convertJuminToAge((userInfo.jumin_1 + "-" + userInfo.jumin_2))})` : `${selectedCustomer.user_name} (${convertGender(selectedCustomer.user_gender)}/${nvl(userInfo.jumin_1) === "" ? " - " : convertJuminToAge((userInfo.jumin_1 + "-" + userInfo.jumin_2))})`}
                  </li>
                  <li className="tit_field">주민번호</li>
                  <li className="input_field">{nvl(selectedCustomer) === "" ? userInfo.jumin_1 + "-" + userInfo.jumin_2 : (decrypt(selectedCustomer?.user_jumin)?.substring(0, 6) + "-" + decrypt(selectedCustomer?.user_jumin)?.substring(6, 13))}</li>
                  <li className="tit_field">전화번호</li>
                  <li className="input_field">{nvl(selectedCustomer) === "" ? userInfo.user_mobile : selectedCustomer.user_mobile}</li>
                </ul>
                <ul className="com_input_section">
                  <li className="tit_field">차트번호</li>
                  <li className="input_field">{nvl(selectedCustomer) === "" ? userInfo.chart_code : selectedCustomer.chart_code}</li>
                  <li className="tit_field">생년월일</li>
                  <li className="input_field">{nvl(selectedCustomer) === "" ? `${userInfo.user_dob?.slice(0, 4)}년 ${userInfo.user_dob?.slice(4, 6)}월 ${userInfo.user_dob?.slice(6, 8)}일` : `${selectedCustomer.user_dob?.slice(0, 4)}년 ${selectedCustomer.user_dob?.slice(4, 6)}월 ${selectedCustomer.user_dob?.slice(6, 8)}일`}</li>
                  <li className="tit_field">주소</li>
                  <li className="input_field">
                    <div className="input_wrap">
                      <input type="text" className="input" placeholder="주소입력" value={nvl(selectedCustomer) === "" ? userInfo.user_address || "" : selectedCustomer.user_address || ""} onChange={(e) => setUserInfo({ ...userInfo, user_address: e.target.value })} />
                      <button className="com_btn blue m" onClick={ReactDaumPost(postConfig)}>우편번호</button>
                    </div>
                    <div className="input_wrap">
                      <input type="text" className="input" placeholder="상세 주소 입력" value={nvl(selectedCustomer) === "" ? userInfo.user_address_detail || "" : selectedCustomer.user_address_detail || ""} onChange={(e) => setUserInfo({ ...userInfo, user_address_detail: e.target.value })} />
                    </div>
                  </li>
                </ul>
              </div>
              {buttons[0].active && <MediConfirm doctorList={doctorList} handleDoctorChange={handleDoctorChange} medicalLicense={medicalLicense} setMedicalTreatment={setMedicalTreatment} setViewType={setViewType} popupCalendarHTML={popupCalendarHTML} getOrderRecord={getOrderRecord} medicalTreatment={medicalTreatment} selectedArray={selectedArray} treatmentConfirmation={treatmentConfirmation} handleTreatmentConfirm={handleTreatmentConfirm} />}
              {(buttons[1].active || buttons[2].active || buttons[3].active) && <MediRecord setViewType={setViewType} popupCalendarHTML={popupCalendarHTML} getOrderRecord={getOrderRecord} setAlertLayerPopup={setAlertLayerPopup} treatmentReport={treatmentReport} setTreatmentReport={setTreatmentReport} medicalTreatmentList={medicalTreatmentList} checkedItems={checkedItems} setCheckedItems={setCheckedItems} setSelectedRecordArray={setSelectedRecordArray} />}
              {(buttons[4].active || buttons[5].active) && <MediNote doctorList={doctorList} selectedArray={selectedArray} medicalLicense={medicalLicense} medicalCertificate={medicalCertificate} setMedicalCertificate={setMedicalCertificate} handleTreatmentConfirm={handleTreatmentConfirm} />}
              {buttons[6].active && <DocumentPrescription popup={popup} onClosePop={onClosePop} onPopupClick={onPopupClick} getMedicines={getMedicines} medicines={medicines} searchData={searchData} setSearchData={setSearchData} prescriptionInfo={prescriptionInfo} setPrescriptionInfo={setPrescriptionInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList} />}
              <div className="bottom">
                {/*병원 정보*/}
                <ul className="com_input_section">
                  <li className="tit_field">병원명</li>
                  <li className="input_field">
                    <input type="text" className="input name" value={hospitalInfo.hospital_name || ""} onChange={(e) => setHospitalInfo({ ...hospitalInfo, hospital_name: e.target.value })} />
                  </li>
                  <li className="tit_field">병원 전화번호</li>
                  <li className="input_field">
                    <input type="text" className="input tel" value={hospitalInfo.hospital_phone || ""} onChange={(e) => setHospitalInfo({ ...hospitalInfo, hospital_phone: e.target.value })} />
                  </li>
                  <li className="tit_field">병원주소</li>
                  <li className="input_field">
                    <div className="input_wrap">
                      <input type="text" className="input" placeholder="주소입력" value={hospitalInfo.hospital_address || ""} onChange={(e) => setHospitalInfo({ ...hospitalInfo, hospital_address: e.target.value })} />
                      <button className="com_btn blue m" onClick={ReactDaumPost(postConfig2)}>우편번호</button>
                    </div>
                    <div className="input_wrap">
                      <input type="text" className="input" placeholder="상세 주소 입력" value={hospitalInfo.hospital_address_detail || ""} onChange={(e) => setHospitalInfo({ ...hospitalInfo, hospital_address_detail: e.target.value })} />
                    </div>
                  </li>
                </ul>
                {/*제외내용*/}
                <ul className="exclude">
                  {/*진료확인서*/}
                  {buttons[0].active &&
                    <li className="com_check_wrap">
                      <input type="checkbox" className="checkbox" id="type2_1" name="disease_info_yn" value={treatmentConfirmation.disease_info_yn || ""} onChange={(e) => handleCheckedHandler(e)} checked={treatmentConfirmation.disease_info_yn === "Y"} />
                      <label className="btn_checkbox" htmlFor="type2_1">&nbsp;</label>
                      <label htmlFor="type2_1">질병 명 및 코드 제외</label>
                    </li>}
                  {/*진료비 세부내역서, 진료비영수증, 진료기록부, 처방전*/}
                  {(buttons[1].active || buttons[2].active || buttons[3].active || buttons[6].active) &&
                    <li className="com_check_wrap">
                      <input type="checkbox" className="checkbox" id="type2_2"
                        value={buttons[6].active ? prescriptionInfo.medical_history_yn || "" :
                          (buttons[1].active || buttons[2].active || buttons[3].active) ? treatmentReport.medical_history_yn : ""}
                        checked={buttons[6].active ? prescriptionInfo.medical_history_yn === "Y" :
                          (buttons[1].active || buttons[2].active || buttons[3].active) ? treatmentReport.medical_history_yn === "Y" : ""}
                        onChange={buttons[6].active ? (e) => setPrescriptionInfo({ ...prescriptionInfo, medical_history_yn: e.target.checked ? "Y" : "N" }) : (buttons[1].active || buttons[2].active || buttons[3].active) ?
                          (e) => setTreatmentReport({ ...treatmentReport, medical_history_yn: e.target.checked ? "Y" : "N" }) : ""}
                      /> {/* 공통된 문서별로 각가 state 다르게, 우선 처방전만 작업 */}
                      <label className="btn_checkbox" htmlFor="type2_2">&nbsp;</label>
                      <label htmlFor="type2_2">주증상 및 병력 제외</label>
                    </li>}
                  {/*진료기록부*/}
                  {buttons[3].active &&
                    <>
                      <li className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="type2_3" value={treatmentReport.symptom_course_yn} checked={treatmentReport.symptom_course_yn === "Y"} onChange={(e) => setTreatmentReport({ ...treatmentReport, symptom_course_yn: e.target.checked ? "Y" : "N" })} />
                        <label className="btn_checkbox" htmlFor="type2_3">&nbsp;</label>
                        <label htmlFor="type2_3">증상 경과 제외</label>
                      </li>
                      <li className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="type2_4" value={treatmentReport.disease_output_yn} checked={treatmentReport.disease_output_yn === "Y"} onChange={(e) => setTreatmentReport({ ...treatmentReport, disease_output_yn: e.target.checked ? "Y" : "N" })} />
                        <label className="btn_checkbox" htmlFor="type2_4">&nbsp;</label>
                        <label htmlFor="type2_4">질병 출력 제외</label>
                      </li>
                      <li className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="type2_5" value={treatmentReport.selected_treatment_day_yn} checked={treatmentReport.selected_treatment_day_yn === "Y"} onChange={(e) => setTreatmentReport({ ...treatmentReport, selected_treatment_day_yn: e.target.checked ? "Y" : "N" })} />
                        <label className="btn_checkbox" htmlFor="type2_5">&nbsp;</label>
                        <label htmlFor="type2_5">선택된 진료일 주증상 및 병력만 포함</label>
                      </li>
                      <li className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="type2_6" value={treatmentReport.clinic_hours_yn} checked={treatmentReport.clinic_hours_yn === "Y"} onChange={(e) => setTreatmentReport({ ...treatmentReport, clinic_hours_yn: e.target.checked ? "Y" : "N" })} />
                        <label className="btn_checkbox" htmlFor="type2_6">&nbsp;</label>
                        <label htmlFor="type2_6">진료시간 포함</label>
                      </li>
                      <li className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="type2_7" value={treatmentReport.consultation_contents_yn} checked={treatmentReport.consultation_contents_yn === "Y"} onChange={(e) => setTreatmentReport({ ...treatmentReport, consultation_contents_yn: e.target.checked ? "Y" : "N" })} />
                        <label className="btn_checkbox" htmlFor="type2_7">&nbsp;</label>
                        <label htmlFor="type2_7">상담내용 포함</label>
                      </li>
                    </>}
                  {/*처방전*/}
                  {buttons[6].active &&
                    <>
                      <li className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="type2_8" checked={prescriptionInfo.resident_no_yn === "Y"} value={prescriptionInfo.resident_no_yn || ""} onChange={(e) => setPrescriptionInfo({ ...prescriptionInfo, resident_no_yn: e.target.checked ? "Y" : "N" })} />
                        <label className="btn_checkbox" htmlFor="type2_8">&nbsp;</label>
                        <label htmlFor="type2_8">처방전 주민번호 출력</label>
                      </li>
                      <li className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id="type2_9" checked={prescriptionInfo.including_injections_yn === "Y"} value={prescriptionInfo.including_injections_yn || ""} onChange={(e) => setPrescriptionInfo({ ...prescriptionInfo, including_injections_yn: e.target.checked ? "Y" : "N" })} />
                        <label className="btn_checkbox" htmlFor="type2_9">&nbsp;</label>
                        <label htmlFor="type2_9">처방전 원내 주사약 포함</label>
                      </li>
                    </>
                  }
                </ul>
              </div>
            </article>
            <article className="customer">
              <fieldset className="search">
                <div className="input_del_wrap">
                  <input type="text" className="input" placeholder="고객명 검색" name="customerSearch" value={searchCustomer || ""} onChange={(e) => {
                    setSearchCustomer(e.target.value);
                    if (e.target.value !== '') {
                      filterText();
                    }
                  }}
                    onKeyDown={(e) => {
                      (e.code === 'Enter' || e.code === 'NumpadEnter') && filterText();
                    }} />
                  <button type="button" className="btn_input_del" onClick={() => setSearchCustomer('')}></button>
                </div>
                <button type="button" className="com_btn m blue icon" onClick={() => { filterText(); }}><i className="svg_icon icon_search"></i></button>
              </fieldset>
              <article className="com_list">
                <div className="scroll">
                  <table className="list">
                    <colgroup><col width="15%" /><col width="15%" /><col /><col width="22%" /><col width="23%" /></colgroup>
                    {searchCustomer === "" ? null :
                      <thead className="sticky top">
                        <tr>
                          <th>No.</th>
                          <th>선택</th>
                          <th>차트번호</th>
                          <th>이름</th>
                          <th>연락처</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      {searchCustomer === "" ? null : customerList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <fieldset className="com_radio_wrap">
                                <input type="radio" className="radio" id={`ho-rd-${index}`} checked={item.checked} onChange={(e) => {
                                  setCustomerList((prev) => {
                                    const newArr = [...prev];
                                    newArr.forEach(item => item.checked = false);
                                    newArr[index].checked = e.target.checked;
                                    return newArr;
                                  }); setTreatmentConfirmation(treatmentConfirmationInit); setSelectedTreatmentIndex(item.user_seq); setSelectedArray([])
                                }} name="radio" onClick={() => selectCustomerHandler(item)} value={item.user_seq || ""} />
                                <label className="btn_radio" htmlFor={`ho-rd-${index}`}>&nbsp;</label>
                              </fieldset>
                            </td>
                            <td>{item.chart_code}</td>
                            <td>{`${item.user_name}(${item.age}/${item.gender})`}</td>
                            <td>{item.user_mobile}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </article>
              {/*진료일자 : 진료확인서, 진단서, 소견서, 처방전에서만 노출 */}
              {(buttons[0].active || buttons[4].active || buttons[5].active || buttons[6].active) &&
                <div className="com_list">
                  <p className="list_tit">진료일자</p>
                  <table className="list">
                    <colgroup><col width="20%" /><col width="40%" /><col /></colgroup>
                    <thead>
                      <tr>
                        <th>선택</th>
                        <th>진료일자</th>
                        <th>담당의</th>
                      </tr>
                    </thead>
                    <tbody>
                      {medicalTreatmentList.length === 0 ?
                        <tr>
                          <td colSpan="3">
                            데이터가 없습니다.
                          </td>
                        </tr>
                        :
                        medicalTreatmentList.map((el, index) => (
                          <tr key={index}>
                            <td>
                              <fieldset className="com_radio_wrap">
                                <input
                                  type="radio"
                                  className="radio"
                                  name="medicalRadio"
                                  id={`chk${index}1`}
                                  onClick={(e) => handleTreatmentSelection(e, el.order_seq, el.user_seq, el.order_date)}
                                  checked={selectedTreatmentIndex === el.order_seq}
                                  value={el.order_seq || ""}
                                  readOnly
                                />
                                <label className="btn_radio" htmlFor={`chk${index}1`} />
                              </fieldset>
                            </td>
                            <td>{moment(el.order_date).format("YYYY, MM, DD")}</td>
                            <td>{el.manager_name}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>}
              {/*발급내역*/}
              <div className="com_list">
                <p className="list_tit">발급내역</p>
                <table className="list">
                  <colgroup><col width="32%" /><col width="32%" /><col /><col width="15%" /></colgroup>
                  <thead>
                    <tr>
                      <th>발급일</th>
                      <th>발급번호</th>
                      <th>담당의</th>
                      <th>보기</th>
                    </tr>
                  </thead>
                  <tbody>
                    {issuanceList.length > 0 ? issuanceList.map((el, i) => (
                      <tr key={i}>
                        <td>{el.modi_date === null ? moment(el.reg_date).format("YYYY, MM, DD") : moment(el.modi_date).format("YYYY, MM, DD")}</td>
                        <td>{el.issue_seq}</td>
                        <td>김의사</td>
                        <td><button className="com_btn txt" onClick={() => setDocumentType(el)}>보기</button></td>
                      </tr>
                    ))
                      // {/* 서류발급 권한이 의사에게만 있으면 reg_manager_seq로 처리해도 되지만 발급을 직원도 처리할 수 있다면 담당의 컬럼 추가 필요 */}
                      :
                      <tr>
                        <td colSpan="4">
                          데이터가 없습니다.
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </article>
          </div>
          <div className="pop_footer com_btn_wrap">
            <button className="com_btn l blue" onClick={saveDocument}>저장</button>
            {(buttons[1].active || buttons[2].active || buttons[3].active) && <button className="com_btn l line blue"><i className="svg_icon icon_print yellow"></i>일자별 출력</button>}
            <button className="com_btn l line blue" onClick={previewPrint}><i className="svg_icon icon_print yellow"></i>미리보기 및 인쇄</button>
          </div>
        </div>
      </section>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}

    </>
  )
}

export default Document