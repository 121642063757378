import { SET_TODAY } from "./ActionType";


const stateTodayInit = null

const stateTodayInfo = (state = stateTodayInit, action) => {
  let { type } = action;


  switch (type) {
    case SET_TODAY:
      return action.payload.data

    default:
      return state;
  }
}

export default stateTodayInfo;