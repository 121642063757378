import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CalendarTable from "atoms/common/Calendar";
import request from "utils/Request.utils";
import DayList from "./_DayList";
import moment from "moment";
import LoadingBar from '../../utils/LoadingBar';

const ReservationMonth = ({ loadingBarActive }) => {
  const [reserPopup, setReserPopup] = useState(false)

  const [reservationMonth, setReservationMonth] = useState(moment().format("YYYY-MM"))
  const [eventList, setEventList] = useState([]);
  const [reservationMonthList, setReservationMonthList] = useState([])
  const [reservationDateList, setReservationDateList] = useState([])



  // eventList 형식에 맞춰서 변경 (오전/오후 예약,내원, 예약취소, 예약변경)
  const processChartData = (data) => {
    let eventLists = [];
    let chartData = {};

    data.forEach(item => {
      let date = new Date(item.reser_date);
      let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

      let isMorning = false;
      if (parseInt(item.reser_time.split(":")[0]) < 12) {
        isMorning = true;
      }

      if (chartData[dateString]) {
        if (isMorning && item.reser_status !== "C") {
          chartData[dateString].morningReservationCount += 1; // 오전 예약
        } if (!isMorning && item.reser_status !== "C") {
          chartData[dateString].afternoonReservationCount += 1; // 오후 예약
        } if (item.reser_status === "C") {
          chartData[dateString].cancelReservationCount += 1; // 예약 취소
        } if (item.reser_status === "R" && item.modi_date !== null) {
          chartData[dateString].changeReservationCount += 1; // 예약 변경
        } if (isMorning && item.reser_status === "S") {
          chartData[dateString].morningVisitCount += 1; // 오전 내원 - 접수 시 ORDER 테이블에 order_date가 생성(현재 미처리)되거나 RESERVATION 테이블에 reser_status가 S로 변경
        } if (!isMorning && item.reser_status === "S") {
          chartData[dateString].afternoonVisitCount += 1; // 오후 내원
        }
      } else {
        chartData[dateString] = {
          morningReservationCount: isMorning && item.reser_status !== "C" ? 1 : 0,
          afternoonReservationCount: !isMorning && item.reser_status !== "C" ? 1 : 0,
          cancelReservationCount: item.reser_status === "C" ? 1 : 0,
          changeReservationCount: item.reser_status === "R" && item.modi_date !== null ? 1 : 0,
          morningVisitCount: isMorning && item.reser_status === "S" ? 1 : 0,
          afternoonVisitCount: !isMorning && item.reser_status === "S" ? 1 : 0,
        }
      }
    })

    for (let dateString in chartData) {
      let morningReservationCount = chartData[dateString].morningReservationCount;
      let afternoonReservationCount = chartData[dateString].afternoonReservationCount;
      let cancelReservationCount = chartData[dateString].cancelReservationCount;
      let changeReservationCount = chartData[dateString].changeReservationCount;
      let morningVisitCount = chartData[dateString].morningVisitCount;
      let afternoonVisitCount = chartData[dateString].afternoonVisitCount



      if (morningReservationCount > 0 || afternoonReservationCount > 0) {
        if (morningReservationCount > 0) {
          let eventData = {
            title: `[오전] 예약 ${morningReservationCount}명, 내원 ${morningVisitCount}명`,
            start: new Date(dateString),
            end: new Date(dateString),
            desc: '',
            colorEvento: '#e9efff',
            color: '#0132b8'
          };
          eventLists.push(eventData);
        }

        if (afternoonReservationCount > 0) {
          let eventData = {
            title: `[오후] 예약 ${afternoonReservationCount}명, 내원 ${afternoonVisitCount}명`,
            start: new Date(dateString),
            end: new Date(dateString),
            desc: '',
            colorEvento: '#f1faff',
            color: '#00add1'
          };
          eventLists.push(eventData);
        }
      }
      if (cancelReservationCount > 0) {
        let eventData = {
          title: `[예약취소] ${cancelReservationCount}명`,
          start: new Date(dateString),
          end: new Date(dateString),
          desc: '',
          colorEvento: '#fff6f8',
          color: '#f23459'
        };
        eventLists.push(eventData);
      }
      if (changeReservationCount > 0) {
        let eventData = {
          title: `[예약변경] ${changeReservationCount}명`,
          start: new Date(dateString),
          end: new Date(dateString),
          desc: '',
          colorEvento: '#fff9f6',
          color: '#f88041',
        };
        eventLists.push(eventData);
      }
    }
    return eventLists;
  }

  const getReservationMonth = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setEventList(processChartData(response.data.dataList));

        setReservationMonthList(response.data.dataList) // 원본

      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/reservation?listType=F&delete_yn=N&reser_month=${reservationMonth}`, null, successHandler, failHandler, null, null)
  }


  useEffect(() => {
    getReservationMonth()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationMonth])

  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <Link to={"/reservation"} className="com_btn m blue ">월간</Link>
              <Link to={"/reservation/week"} className="com_btn m  lightgray">주간</Link>
              <Link to={"/reservation/type"} className="com_btn m lightgray">진료분야</Link>
            </div>
          </article>
          <article className="com_lnb2_right">
            <button className="com_btn icon m" onClick={() => getReservationMonth()}><i className="svg_icon icon_refresh">&nbsp;</i></button>
          </article>
        </div>
      </section>
      <section className="com_content">
        {/* <CalendarTable
        tileContent={({ date, view }) => { // 날짜 타일에 컨텐츠 추가하기 (html 태그)
          // 추가할 html 태그를 변수 초기화
          let html = [];
          // 다른 조건을 주어서 html.push 에 추가적인 html 태그를 적용할 수 있음.
          return (
            <>
              <div className="flex justify-center items-center absoluteDiv">
                eee
              </div>
            </>
          );
        }}
      /> */}

        <CalendarTable eventList={eventList} reservationMonthList={reservationMonthList} setReservationDateList={setReservationDateList} setReserPopup={setReserPopup} setReservationMonth={setReservationMonth} />

      </section>
      {reserPopup && <DayList reservationDateList={reservationDateList} setReserPopup={setReserPopup} />}
      {loadingBarActive && <LoadingBar type={"spin"} color={"#000000"} />}
    </>
  )
}

export default ReservationMonth