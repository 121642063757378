export const SET_CUSTOMER = 'setCustomer';
export const CLEAR_CUSTOMER = 'clearCustomer';

export const SET_MANAGER = 'setManager';
export const CLEAR_MANAGER = 'clearManager';

export const SET_ORDER_INFO = 'setOrderInfo';
export const CLEAR_ORDER_INFO = 'clearOrderInfo';

export const INIT_TIMER = 'initTimer';
export const SET_TIMER = 'setTimer';
export const ADD_TIMER = 'addTimer';
export const UPDATE_TIMER = 'updateTimer';
export const DEL_TIMER = 'delTimer';
export const CLEAR_TIMER = 'clearTimer';

export const SET_PAYMENT_INFO = 'setPaymentInfo';
export const CLEAR_PAYMENT_INFO = 'clearPaymentInfo';

export const SET_MENU_INFO = 'setMenuInfo';
export const CLEAR_MENU_INFO = 'clearMenuInfo';

export const SET_TODAY = 'setToday';