const setItem = (category, fieldName, data) => {
  let sessionStorageItem = window.sessionStorage.getItem(category);
  let sessionStorageItemJson = JSON.parse(sessionStorageItem ? sessionStorageItem : '{}');

  sessionStorageItemJson[fieldName] = data;
  
  window.sessionStorage.setItem(category, JSON.stringify(sessionStorageItemJson));
}

const getItem = (category) => {
  return window.sessionStorage.getItem(category);
}

const getItemJsonParse = (category) => {
  return JSON.parse(getItem(category));
}

const clearItem = (category) => {
  window.sessionStorage.setItem(category, '{}');
}

const removeItem = (category) => {
  window.sessionStorage.removeItem(category);
}

const sessionStorage = {
  setItem,
  getItem,
  getItemJsonParse,
  clearItem,
  removeItem
}

export default sessionStorage;