//* 고객등록,상세,수정
import React, { useRef, useEffect, useState } from "react";
import request from "utils/Request.utils";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert";
import CustomMemo from "./_CustomMemo";
import SnsSend from "components/common/_SnsSend.js";
import SnsSendList from "components/common/_SnsSendList.js";
import ReservationAdd from "./_ReservationAdd";
import PointManage from "./_PointManage";
import { useSelector } from "react-redux";
import { nvl, nvlNumber, checkKoreanAlphabet, nvlOnlyNumber, createChartCode, addComma } from "utils/Common.utils";
import CouponManage from "./_CouponManage";

const CustomCard = ({ onClose, userData, setUserData, selectHandler, pointData, getReceptionList, setLnbSearchOptions, changeUserHandler }) => {
  const stateToday = useSelector(state => state.Today)
  const nodeRef = useRef(null);
  const uploadFileRef = useRef();

  const customerInit = {
    chartCode: '',
    name: '',
    gender: 'M',
    jumin_1: '',
    jumin_2: '',
    mobile_1: '010',
    mobile_2: '',
    mobile_3: '',
    grade: '', // 008001000
    manager: 1,
    visitPath: '', // 002001000
    comment: '',
    user_status: 'R',
    validate: false,
    interest_area: ''
  }

  const [customer, setCustomer] = useState(customerInit);
  const [gradeList, setGradeList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [visitPathList, setVisitPathList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [modifyModeActivate, setModifyModeActivate] = useState(true);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());

  const [imgBase64, setImgBase64] = useState();
  const [uploadFile, setUploadFile] = useState();

  const [popup, setPopup] = useState({
    register: false,
    registerFail: false,
    // memo: false,
    reserve: false,
    point: false,
    coupon: false
  });

  const [snsSendBool, setSnsSendBool] = useState(false);
  // 고객카드 문자기록, 메모 창 팝업 연결
  const [customerPopup, setCustomerPopup] = useState({
    sent: false,
    memo: false,
    userData: ""
  })

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    closeType: ''
  });

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const onClosePop = (componentName) => {
    if (componentName === 'reserve' && nvl(userData?.user_seq) === '') resetPopup()
    // else setCustomerSeq(nvlNumber(userData?.user_seq))

    setPopup((prev) => { return { ...prev, [componentName]: false } });
  }

  const onOpenPop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: true } });
  }

  const changeGender = (e) => {
    if (nvl(userData?.user_seq) === '') setCustomer((prev) => { return { ...prev, gender: e.target.id } });
    else setUserData((prev) => { return { ...prev, user_gender: e.target.id } })
  }

  const isValidateJumin = () => {
    // 자격조회 로직 추가 자격조회 클릭시, 공인인증서 암호 입력 팝업노출  (보험진료)
    if (nvl(customer.name) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: " 이름을 입력해주세요.", activateMode: "" } })
      return;
    } else if (nvl(customer.jumin_1) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "주민등록번호 앞자리를 입력해주세요.", activateMode: "" } })
      return;
    } else if (customer.jumin_2 === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "주민등록번호 뒷자리를 입력해주세요.", activateMode: "" } })
      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "공인인증서 암호 입력 팝업 연동해야함", activateMode: "" } })
    setCustomer((prev) => { return { ...prev, validate: true } });
  }

  //고객카드 등록 onChange 핸들러
  const regCustomCardHandler = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      if (nvl(value) !== '' && !checkKoreanAlphabet(value)) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "한글 또는 영문만 입력해주세요.", activateMode: "" } })
        return;
      }
    } else if (name === "jumin_1") {
      if (nvl(value) !== '' && !nvlOnlyNumber(value)) {
        // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "" } })
        return;
      }
    } else if (name === "jumin_2") {
      if (nvl(value) !== '' && !nvlOnlyNumber(value)) {
        // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "" } })
        return;
      }
    } else if (name === "mobile_2") {
      if (nvl(value) !== '' && !nvlOnlyNumber(value)) {
        // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "" } })
        return;
      }
    } else if (name === "mobile_3") {
      if (nvl(value) !== '' && !nvlOnlyNumber(value)) {
        // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "" } })
        return;
      }
    }

    if (nvl(userData?.user_seq) === '') setCustomer((prev) => { return { ...prev, [name]: value } })
    else {
      if (name === "name") {
        if (nvl(value) !== '' && !checkKoreanAlphabet(value)) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "한글 또는 영문만 입력해주세요.", activateMode: "" } })
          return;
        }
        setUserData({ ...userData, user_name: value });
      } else if (name === "grade") {
        setUserData({ ...userData, user_level: value });
      }
      setUserData((prev => { return { ...prev, [name]: value } }));
    }
  }
  const resetPopup = () => {
    setCustomer(customerInit)
  }
  // 고객카드 등록 => modified by wem 20230607 등록 => 등록만, 예약 => 등록 + 예약, 접수 => 등록 + 접수로 변경
  const insertUser = (upFileSeq, mode) => {
    const successHandler = (response) => {

      if (response.messageCode === 200) { // 시퀀스 넘어옴
        setCustomer(customerInit) // 고객 등록 후 입력창 초기화
        selectHandler(response.data)
        setUserData((prev) => { return { ...prev, user_seq: response.data } })
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객카드를 등록했습니다.", activateMode: "" } });
        // onClose() // 고객카드 닫기 (고객카드에서 예약 클릭시 setPopup이 충돌나서 주석처리)
        if (mode === "reserve") { // 고객카드 바로 예약 시
          isUserExist("reserve")
        } else if (mode === "visit") { // 고객카드 바로 접수 시
          receptionHandler(response.data)
        }
      } else console.log('Error >> ', response)
    }

    const params = {
      user_name: customer.name,
      user_nickname: '',
      chart_code: customer.chartCode,
      user_jumin: nvl(customer.jumin_1) === "" && nvl(customer.jumin_2) === "" ? "" : `${customer.jumin_1}-${customer.jumin_2}`,
      user_gender: customer.gender,
      user_dob: nvl(customer.jumin_1) === "" && nvl(customer.jumin_2) === "" ? "" : `${customer.jumin_2.charAt(0) < '3' ? '19' : '20'}${customer.jumin_1.substring(0, 2)}-${customer.jumin_1.substring(2, 4)}-${customer.jumin_1.substring(4)}`,
      user_level: customer.grade,
      user_status: 'R',
      user_phone: '',
      user_mobile: nvl(customer.mobile_2) === "" ? "" : `${customer.mobile_1}-${customer.mobile_2}-${customer.mobile_3}`,
      user_email: '',
      user_postcode: '',
      user_address: '',
      user_address_detail: '',
      interest_area: customer.interest_area,
      admissive_channel: customer.visitPath,
      bank_name: '',
      bank_account_number: '',
      bank_account_holder: '',
      receptionist_seq: managerInfo.seq,
      reg_manager_seq: managerInfo.seq,
      incharge_seq: managerInfo.seq,
      counselor_seq: managerInfo.seq,
      treatment_name: '상담',
      up_file_seq: nvlNumber(upFileSeq)
    };

    request.post(`/api/user`, params, successHandler);
  }

  const registerCustomer = (mode) => {
    if (nvl(userData?.user_seq) === '') { // 고객 등록
      if (nvl(customer.name) === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이름을 입력해주세요.", activateMode: "" } })

        return;
      }
      // else if (nvl(customer.jumin_1) === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "주민등록번호 앞자리를 입력해주세요.", activateMode: "" } })

      //   return;
      // } else if (nvl(customer.jumin_2) === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "주민등록번호 뒷자리를 입력해주세요.", activateMode: "" } })

      //   return;
      // } 
      // else if (nvl(customer.mobile_2) === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "휴대폰번호 가운데 4자리를 입력해주세요.", activateMode: "" } })

      //   return;
      // } else if (nvl(customer.mobile_3) === "") {
      //   setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "휴대폰번호 마지막 4자리를 입력해주세요.", activateMode: "" } })

      //   return;
      // }
    }
    if (nvl(customer.name) !== "") {
      const successHandler = (response) => {
        if (response.messageCode === 200) {

          if (nvl(uploadFile) !== "") submitUploadFile(); // 사진 파일이 있으면 사진 파일부터 업로드
          else insertUser(null, mode);
        } else if (response.messageCode === 409) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이미 등록된 고객입니다.<br/>고객정보를 다시 입력해주세요.", activateMode: "" } })

          return;
        }
        else console.log('Error >> ', response)
      }

      const params = {
        user_name: customer.name,
        user_jumin: nvl(customer.user_jumin),
        user_dob: nvl(customer.user_dob) === "" ? "" : nvl(customer.user_dob),
        user_mobile: nvl(customer.mobile_2) === "" ? "" : `${customer.mobile_1}-${customer.mobile_2}-${customer.mobile_3}`,
      }

      request.post(`/api/user/validation`, params, successHandler);
    }
    else {
      insertUser(null, mode);
    }
  }

  const updateCustomerValidation = () => {
    if (userData?.user_name === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이름을 입력해주세요.", activateMode: "" } })

      return;
    } else if (userData?.jumin_1 === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "주민등록번호 앞자리를 입력해주세요.", activateMode: "" } })

      return;
    } else if (userData?.jumin_2 === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "주민등록번호 뒷자리를 입력해주세요.", activateMode: "" } })

      return;
    } else if (userData?.mobile_2 === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "휴대폰번호 가운데 4자리를 입력해주세요.", activateMode: "" } })

      return;
    } else if (userData?.mobile_3 === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "휴대폰번호 마지막 4자리를 입력해주세요.", activateMode: "" } })

      return;
    }

    if (nvl(uploadFile) !== "") submitUploadFile();
    else updateCustomer(null);
  }

  const updateCustomer = (upFileSeq) => {
    let params = {
      user_seq: nvlNumber(userData?.user_seq),
      user_name: nvl(userData?.user_name),
      user_jumin: `${nvl(userData?.jumin_1)}-${nvl(userData?.jumin_2)}`,
      user_gender: nvl(userData?.user_gender),
      user_dob: `${userData?.jumin_2?.charAt(0) < '3' ? '19' : '20'}${userData?.jumin_1.substring(0, 2)}-${userData?.jumin_1.substring(2, 4)}-${userData?.jumin_1.substring(4)}`,
      user_level: userData?.user_level,
      user_mobile: nvl(userData.mobile_2) === "" || nvl(userData.mobile_3) === "" ? "" : `010-${userData?.mobile_2}-${userData?.mobile_3}`,
      up_file_seq: nvlNumber(upFileSeq),
      admissive_channel: userData?.visitPath,
      modi_manager_seq: managerInfo.seq
    }

    params = {...userData,  ...params}

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        selectHandler(response.data)
        setUserData((prev) => { return { ...prev, user_photo: upFileSeq } })

        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "고객정보가 수정되었습니다.", activateMode: '' } });
        onClose()
        // setModifyModeActivate(true)
      } else console.log(`고객정보를 수정하지 못했습니다.`)
    }

    request.put(`/api/user/${userData?.user_seq}`, params, successHandler);
  }

  const getGradeList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setGradeList(response.data);
        setCustomer((prev) => { return { ...prev, grade: nvl(response.data[0]?.code) } })

        getVisitPathList();
      } else console.debug('Error >> ', response)
    }

    request.get(`/api/etc/commoncd/category/008000000`, null, successHandler);
  }

  const getVisitPathList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setVisitPathList(response.data);
        setCustomer((prev) => { return { ...prev, visitPath: nvl(response.data[0]?.code) } })

        getManagerList()
      } else console.debug('Error >> ', response)
    }

    request.get('/api/etc/commoncd/category/002000000', null, successHandler);
  }

  const getManagerList = () => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        setManagerList(response.data);
        setCustomer((prev) => { return { ...prev, manager: nvl(response.data[0]?.code) } })

        getCouponList();
      } else console.debug('Error >> ', response)
    }

    request.get("/api/manager", null, successHandler);
  }

  const getCouponList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) setCouponList(response.data?.dataList);
      else console.debug('Error >> ', response);
    }

    request.get(`/api/coupon/history?user_seq=${userData.user_seq}&delete_yn=N&enable_status=Y&orderBy=coupon_history_seq DESC`, null, successHandler);
  }

  // 차트 번호 생성
  useEffect(() => {
    setCustomer((prev) => { return { ...prev, chartCode: createChartCode(0, 999) } });

    getGradeList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 첨부파일 등록
  const selectUploadFile = () => {
    uploadFileRef.current.click();
  }

  const submitUploadFile = () => {
    const formData = new FormData();

    formData.append("uploadFile", uploadFile)
    formData.append("file_cate", "userPhoto")
    formData.append("user_seq", nvlNumber(userData?.user_seq))

    const successHandler = (response) => {
      console.log(response);

      if (response.messageCode === 200) {
        if (nvl(userData?.user_seq) === '') insertUser(response.data); // 첨부 파일이 있을 때는 첨부 파일을 먼저 입력하고 직원 정보 입력
        else updateCustomer(response.data); // 첨부 파일이 있을 때는 첨부 파일을 먼저 입력하고 직원 정보 업뎃
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 형식의 파일이 있습니다." } })

      uploadFileInit();
    }

    const failHandler = (result) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "파일 첨부중 에러가 발생했습니다." } })

      uploadFileInit();
    }

    request.postMultipart("/api/upload/user", formData, successHandler, failHandler);
  }

  const uploadFileInit = () => {
    uploadFileRef.current.value = "";
    setUploadFile(null);
  }

  const uploadFileHandler = (e) => {
    if (e.target.files[0]) {
      setUploadFile(e.target.files[0]);

      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onloadend = () => {
        const base64 = reader.result;

        if (base64) setImgBase64(base64.toString());
      }
    }
  }

  const returnAlertValue = (value) => {
    console.log(value)
  }

  const isUserExist = (mode) => {
    if (nvl(mode) === "") {
      if (nvl(userData?.user_seq) === '') setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객을 먼저 등록해주세요." } })
      else onOpenPop('reserve');
    } else { // 바로 예약할 경우
      onOpenPop("reserve")
    }

  }


  const receptionHandler = (DirectUserSeq) => {
    const insertOrder = (userSeq) => {
      const params = {
        user_seq: userSeq,
        order_date: stateToday,
        order_status: "013002000",
        reg_manager_seq: managerInfo.seq
      };

      const successHandler = (response) => {
        if (response.messageCode === 200) {
          changeUserHandler(userData.user_seq) // LNB의 stateCustomer
          onClose();
          getReceptionList && getReceptionList() // props로 넘어온 경우에만 실행
          setLnbSearchOptions((prev) => { return { ...prev, checkedBool: false, totalCount: 0, searchList: null, searchKeyword: '', userSeq: 0 } })

        } else if (response.messageCode === 409) {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "이미 접수한 고객입니다." } })
        } else {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객을 접수하지 못했습니다." } })
        }
      };

      request.post(`/api/charts/order`, params, successHandler);
    };

    if (nvl(DirectUserSeq) === "") { // 등록 후 접수할 경우
      if (nvl(userData?.user_seq) === "") {  //등록 안됨
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객을 먼저 등록해주세요." } })
      } else { // 등록 완료
        insertOrder(nvlNumber(userData?.user_seq));
      }
    } else { // 바로 접수 할 경우
      insertOrder(nvlNumber(DirectUserSeq));
    }
  };


  return (
    <>
      <section className="com_popup type_normal pop_custom_card PopCustomCard active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container m">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">
                {nvl(userData?.user_seq) === '' ? '고객카드 등록' : '고객카드'}
              </h1>
            </div>
            <div className="pop_body">
              {
                nvlNumber(userData?.user_seq) > 0 &&
                <article className="top_btn_area">
                  <button className="com_btn txt non_under" onClick={() => setModifyModeActivate(!modifyModeActivate)}>수정하기<i className="svg_icon icon_next blue">&nbsp;</i></button>
                </article>
              }
              <article className="com_user_box">
                <div className={`color ${nvlNumber(userData?.order_count) > 0 || nvl(userData?.user_seq) === "" ? "first" : "second"}`}></div>
                <div className="pic" onClick={selectUploadFile} style={{ cursor: "pointer" }}>
                  <label htmlFor="user_photo">
                    {
                      nvl(userData?.user_seq) === '' ?
                        nvl(imgBase64) !== '' && <img key={imgBase64} src={imgBase64} alt="미리보기" style={{ width: "74px" }} />
                        :
                        nvl(imgBase64) !== '' ?
                          <img key={imgBase64} src={imgBase64} alt="미리보기" style={{ width: "74px" }} />
                          :
                          nvl(userData?.user_photo_path) !== '' &&
                          <img src={`${process.env.REACT_APP_API_URL}/api/upload/viewer/image/photo/${userData?.user_photo_path.split("/")[userData?.user_photo_path.split("/").length - 2]}_${userData?.user_photo_path.split("/")[userData?.user_photo_path.split("/").length - 1]}`}
                            alt="" style={{ width: "74px" }} />
                    }
                  </label>
                  <input type="file" id="uploadFile" name="files" className="input" ref={uploadFileRef} style={{ display: "none" }} onChange={uploadFileHandler} />
                </div>
                <div className="btns_wrap">
                  <button type="button" className="btn" onClick={() => { setSnsSendBool(!snsSendBool) }}><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
                  <button type="button" className="btn" onClick={() => { if (nvl(userData) === "") return; setCustomerPopup(prev => { return { ...prev, sent: true, userData: userData } }) }}><i className="svg_icon icon_list yellow">&nbsp;</i></button>
                </div>
                <div className="num">{nvl(userData?.user_seq) === '' ? customer.chartCode : userData?.chart_code}</div>
                <button type="button" className="btn_memo com_btn m icon_vertical skyblue_bg txt_blue" onClick={() => { if (nvl(userData) === "") return; setCustomerPopup(prev => { return { ...prev, memo: true, userData: userData } }) }}><i className="svg_icon icon_write blue">&nbsp;</i></button>
              </article>
              <ul className="com_input_section line">
                <li className="tit_field">
                  이름
                </li>
                <li className="input_field input_wrap">
                  <input className="input" type="text" name="name" value={nvl(userData?.user_seq) === '' ? customer.name : userData?.user_name} onChange={regCustomCardHandler} readOnly={nvl(userData?.user_seq) === '' ? false : modifyModeActivate} />
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="M" value="M" name="select_sex" checked={nvl(userData?.user_seq) === '' ? (customer.gender === 'M' ? true : false) : userData?.user_gender === 'M' ? true : false}
                      onChange={changeGender} disabled={nvl(userData?.user_seq) === '' ? false : modifyModeActivate} />
                    <label className="btn_radio" htmlFor="M">&nbsp;</label>
                    <label htmlFor="M">남</label>
                    <input type="radio" className="radio" id="F" value="F" name="select_sex" checked={nvl(userData?.user_seq) === '' ? (customer.gender === 'F' ? true : false) : userData?.user_gender === 'F' ? true : false}
                      onChange={changeGender} disabled={nvl(userData?.user_seq) === '' ? false : modifyModeActivate} />
                    <label className="btn_radio" htmlFor="F">&nbsp;</label>
                    <label htmlFor="F">여</label>
                  </fieldset>
                </li>
                <li className="tit_field">주민등록번호</li>
                <li className="input_field input_wrap">
                  <p>
                    <input className="input" type="text" maxLength={6} name="jumin_1" value={nvl(userData?.user_seq) === '' ? customer.jumin_1 : userData?.jumin_1} onChange={regCustomCardHandler}
                      style={{ "width": "80px" }} readOnly={nvl(userData?.user_seq) === '' ? false : modifyModeActivate} />
                    <span className="space">-</span>
                    <input className="input" type="password" maxLength={7} name="jumin_2" value={nvl(userData?.user_seq) === '' ? customer.jumin_2 : userData?.jumin_2} onChange={regCustomCardHandler}
                      style={{ "width": "80px" }} readOnly={nvl(userData?.user_seq) === '' ? false : modifyModeActivate} />
                  </p>
                  <button className="com_btn m blue" onClick={isValidateJumin} disabled={nvl(userData?.user_seq) === '' ? false : modifyModeActivate}>자격조회</button>
                </li>
                <li className="tit_field">휴대폰번호</li>
                <li className="input_field">
                  <fieldset className="com_field type_tel">
                    <select className="select" value={nvl(userData?.user_seq) === '' ? customer.mobile_1 : userData?.mobile_1} onChange={(e) => setCustomer((prev) => { return { ...prev, mobile_1: e.target.value } })} disabled={nvl(userData?.user_seq) === '' ? false : modifyModeActivate}>
                      <option key="010" value={'010'}>010</option>
                    </select>
                    <input type="text" className="input" maxLength={4} name="mobile_2" value={nvl(userData?.user_seq) === '' ? customer.mobile_2 : userData?.mobile_2}
                      onChange={regCustomCardHandler} readOnly={nvl(userData?.user_seq) === '' ? false : modifyModeActivate} />
                    <input type="text" className="input" maxLength={4} name="mobile_3" value={nvl(userData?.user_seq) === '' ? customer.mobile_3 : userData?.mobile_3}
                      onChange={regCustomCardHandler} readOnly={nvl(userData?.user_seq) === '' ? false : modifyModeActivate} />
                  </fieldset>
                </li>
                <li className="tit_field">고객등급</li>
                <li className="input_field">
                  <select className="select" name="grade" value={nvl(userData?.user_seq) === '' ? customer.grade : userData?.user_level} onChange={regCustomCardHandler} disabled={nvl(userData?.user_seq) === '' ? false : modifyModeActivate}>
                    {gradeList?.map((item) => { return <option key={item.code} value={item.code}>{item.name}</option> })}
                  </select>
                </li>
                {/*등록시*/}

                {/*상세*/}
                {
                  nvlNumber(userData?.user_seq) > 0 ?
                    <>
                      <li className="tit_field">포인트</li>
                      <li className="input_field input_wrap">
                        <p>{pointData?.current_point ? addComma(pointData?.current_point) : 0}</p>
                        <button className="com_btn blue m" onClick={() => onOpenPop('point')} disabled={modifyModeActivate}>관리</button>
                      </li>
                      <li className="tit_field">쿠폰</li>
                      <li className="input_field input_wrap">
                        <select className="select" name="coupon" value={userData.coupon} onChange={regCustomCardHandler} disabled={modifyModeActivate}>
                          <option value="">보유 쿠폰 내역</option>
                          {couponList?.map((item) => { return <option key={item.coupon_seq} value={item.coupon_seq}>{item.coupon_name}</option> })}
                        </select>
                        <button className="com_btn blue m" onClick={() => onOpenPop('coupon')} disabled={modifyModeActivate}>지급</button>
                      </li>
                    </>
                    :
                    <>
                      <li className="tit_field">접수직원</li>
                      <li className="input_field">
                        <select className="select" name="manager" onChange={regCustomCardHandler}>
                          {
                            managerList != null && managerList.length > 0 ?
                              managerList.map((item) => (<option key={item.manager_seq} value={item.manager_seq}>{item.manager_name}</option>))
                              : null
                          }
                        </select>
                      </li>
                      <li className="tit_field">내원경로</li>
                      <li className="input_field">
                        <select className="select" name="visitPath" onChange={regCustomCardHandler}>
                          {visitPathList?.map((item) => { return <option key={item.code} value={item.code}>{item.name}</option> })}
                        </select>
                      </li>
                      <li className="input_field full">
                        <textarea placeholder="관심부위 상세내용 입력" name="interest_area" className="textarea" value={customer.interest_area} style={{ resize: 'none' }}
                          onChange={(e) => setCustomer((prev) => { return { ...prev, interest_area: e.target.value } })}></textarea>
                      </li>
                    </>
                }
              </ul>
            </div>
            {
              nvl(userData?.user_seq) === '' ?
                <div className="pop_footer com_btn_wrap three many">
                  <button type="button" className="com_btn l red" onClick={() => registerCustomer("reserve")}>예약</button>
                  <button type="button" className="com_btn l blue" onClick={registerCustomer}>등록</button>
                  <button type="button" className="com_btn l blue" onClick={() => registerCustomer("visit")}>접수</button>
                  <button type="button" className="com_btn l blue" onClick={registerCustomer}>가등록</button>
                </div>
                :
                <div className="pop_footer com_btn_wrap two many">
                  <button type="button" className="com_btn l red" onClick={() => isUserExist()}>예약</button>
                  {modifyModeActivate === false && <button type="button" className="com_btn l blue" onClick={updateCustomerValidation}>수정</button>}
                  <button type="button" className="com_btn l blue" onClick={() => receptionHandler()}>접수</button>
                </div>
            }
          </div>
        </Draggable>
      </section>

      {customerPopup.memo && <CustomMemo customerPopup={customerPopup} setCustomerPopup={setCustomerPopup} />}
      {snsSendBool && <SnsSend snsSendBool={snsSendBool} setSnsSendBool={setSnsSendBool} />}
      {customerPopup.sent && <SnsSendList customerPopup={customerPopup} setCustomerPopup={setCustomerPopup} />}
      {popup.reserve && <ReservationAdd onClose={() => onClosePop('reserve')} userData={userData} setUserData={setUserData} setLnbSearchOptions={setLnbSearchOptions} />}
      {popup.point && <PointManage onClose={() => onClosePop('point')} userSeq={userData?.user_seq} />}
      {popup.coupon && <CouponManage onClose={() => onClosePop('coupon')} userSeq={userData?.user_seq} setUserData={setUserData} />}
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
    </>
  )
}

export default CustomCard