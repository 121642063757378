//* 고객카드 검색결과
import React, { useRef, useState } from "react";
import { decrypt } from "../../utils/aes256.manager";
import { v4 as uuidv4 } from 'uuid';
import { nvl, nvlNumber, changeMobileFormat, convertJuminToAge, convertGender } from "utils/Common.utils";
import request from "utils/Request.utils";
import CustomCard from "./_CustomCard";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert";

import moment from 'moment';
import ReservationAdd from "./_ReservationAdd";

const CustomList = ({ checkedBool, searchList, changeUserHandler, totalCount, setLnbSearchOptions, handleOnKeyPress, lnbCustomerSearchActivate, lnbSearchOptions, updateSearchKeyword, getReceptionList }) => {
  moment.locale('ko-KR');

  const [selectedValue, setSelectedValue] = useState(0); // 선택된 고객
  const userDataInit = {
    user_seq: '',
    chart_code: '',
    user_name: '',
    user_gender: 'M',
    user_jumin: '',
    jumin_1: '',
    jumin_2: '',
    mobile_1: '010',
    mobile_2: '',
    mobile_3: '',
    user_mobile: '',
    user_level: '',
    user_photo: 0,
    user_photo_path: '',
    user_reser_seq: 0
  }

  const [userData, setUserData] = useState(userDataInit);
  const [pointData, setPointData] = useState({});
  const [couponData, setCouponData] = useState({});

  const nodeRef = useRef(null);

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  // alert 창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const selectHandler = (value) => {
    setSelectedValue(value); // 현재 선택된 고객
    changeUserHandler(value); // LNB의 setCustomer 변경
    getSelectedUserInfo(value); // 현재 setUserData 변경
  }

  const [popup, setPopup] = useState({
    customerCard: false,
    reservationAdd: false
  });

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  }

  const onOpenPop = (componentName) => {
    if (componentName === 'customerCard') setUserData(userDataInit)

    setPopup((prev) => { return { ...prev, [componentName]: true } });
  }

  const isUserExist = (userSeq) => {
    if (nvlNumber(userSeq) > 0) onOpenPop('reservationAdd');
    else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "고객을 선택해주세요." } });
  }

  // 단일 고객 조회
  const getSelectedUserInfo = (user_seq, mode) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const user = response.data.dataList[0];

        setUserData({
          user_seq: user.user_seq,
          chart_code: user.chart_code,
          user_name: user.user_name,
          user_gender: user.user_gender,
          user_level: user.user_level,
          jumin_1: decrypt(user.user_jumin).substring(0, 6),
          jumin_2: decrypt(user.user_jumin).substring(6, 13),
          mobile_1: user.user_mobile.split("-")[0],
          mobile_2: user.user_mobile.split("-")[1],
          mobile_3: user.user_mobile.split("-")[2],
          user_photo: nvlNumber(user.up_file_seq),
          user_photo_path: user.file_path,
          user_reser_seq: user.user_reser_seq,
          main_tc_seq: user.main_tc_seq,
          sub_tc_seq: user.sub_tc_seq,
          user_address: user.user_address,
          user_address_detail: user.user_address_detail,
          interest_area: user.interest_area,
          admissive_channel: user.admissive_channel,
          bank_name: user.bank_name,
          bank_account_number: user.bank_account_number,
          bank_account_holder: user.bank_account_holder
        })

        setPointData(response.data.pointData[0]);
        setCouponData(response.data.couponHistoryData);
        // setPointData((prev) => { return { ...prev, current_point: response.data[0]?.current_point } })

        if (mode === 'popup') setPopup((prev) => { return { ...prev, reservationAdd: false, customerCard: true, order: false } });
        else if (mode === 'order') setPopup((prev) => { return { ...prev, reservationAdd: false, customerCard: false, order: true } });
      } else {
        console.log("고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/user/${user_seq}`, null, successHandler);
  }

  const returnAlertValue = () => {

  }


  const closeCustomListLayer = () => {
    setLnbSearchOptions((prev) => { return { ...prev, checkedBool: false, totalCount: 0, searchList: null, searchKeyword: '', userData: {} } })
  }

  const sentList = () => {
    if (userData.user_seq === "") setAlertLayerPopup(prev => { return { ...prev, visible: true, alertMessage: "고객을 선택해주세요." } })
    else setLnbSearchOptions(prev => { return { ...prev, checkedBool: false, sent: true, userData: userData } })
  }


  return (
    <>
      <section className={checkedBool ? "com_popup type_normal pop_custom_list PopCustomList active" : "com_popup type_normal pop_custom_list PopCustomList"}>
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container xxl">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => closeCustomListLayer()}></button>
            <div className="pop_header">
              <h1 className="pop_title">고객카드 검색결과</h1>
            </div>
            <div className="pop_body">
              <div className="com_list">
                <article className="top_btn_area">
                  <button className="com_btn m red" onClick={() => onOpenPop('customerCard')}><i className="svg_icon icon_add white">&nbsp;</i>고객등록</button>
                  {/*20230406*/}
                  <div className="com_field type_normal">
                    <fieldset className="many">
                      <div className="input_del_wrap">
                        <input type="text" className="input" placeholder="검색할 내용을 입력해 주세요." name="customerSearch" value={lnbSearchOptions?.searchKeyword}
                          onKeyDown={handleOnKeyPress} onChange={updateSearchKeyword} />
                        <button type="button" className="btn_input_del" onClick={() => setLnbSearchOptions(prev => { return { ...prev, searchKeyword: '' } })}></button>
                      </div>
                      <button type="button" className="com_btn icon m blue btn_search" onClick={lnbCustomerSearchActivate}><i className="svg_icon icon_search white">&nbsp;</i></button>
                    </fieldset>
                  </div>
                </article>
                <article className="scroll">
                  <table className="list">
                    <colgroup><col width="7%" /><col width="6%" /><col width="17.4%" /><col width="22%" /><col width="22%" /><col /></colgroup>
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>선택</th>
                        <th>차트번호</th>
                        <th>예약일시</th>
                        <th>이름</th>
                        <th>휴대폰번호</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        searchList != null && searchList?.length > 0 ?
                          searchList?.map((el, i) => (
                            <tr className="checked" key={"user_seq" + uuidv4()}>
                              <td>{totalCount - i}</td>
                              <td>
                                <fieldset className="com_check_wrap">
                                  <input type="radio" className="checkbox" id={`customer_${i}`} name="choiceCustomer" checked={parseInt(selectedValue) === parseInt(el.user_seq)}
                                    value={el.user_seq} onChange={() => selectHandler(el.user_seq)} />
                                  <label className="btn_checkbox" htmlFor={`customer_${i}`}>&nbsp;</label>
                                </fieldset>
                              </td>
                              <td>{el.chart_code}</td>
                              <td>{nvl(el.user_reser_date) !== "" ? el.user_reser_date : "-"}</td>
                              <td onClick={() => getSelectedUserInfo(el.user_seq, 'popup')} style={{ cursor: "pointer" }}>
                                {el.user_name} ({convertGender(el.user_gender)}/{(el.user_jumin) === "" ? " - "  : convertJuminToAge((decrypt(el.user_jumin).substring(0, 6) + "-" + decrypt(el.user_jumin).substring(6, 13)))})
                              </td>
                              <td>{changeMobileFormat(el.user_mobile)}</td>
                            </tr>
                          ))
                          :
                          <tr>
                            <td colSpan="6">일치하는 데이터가 없습니다.</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </article>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue" onClick={() => isUserExist(userData.user_seq)}>예약하기</button>
              <div className="right">
                <button type="button" className="com_btn icon m line yellow" onClick={() => setLnbSearchOptions(prev => { return { ...prev, checkedBool: false, send: true } })}><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
                <button type="button" className="com_btn icon m line yellow" onClick={() => sentList()}><i className="svg_icon icon_list yellow">&nbsp;</i></button>
              </div>
            </div>
          </div>
        </Draggable>
      </section>
      {popup.reservationAdd && <ReservationAdd onClose={() => onClosePop('reservationAdd')} userData={userData} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler}/>}
      {popup.customerCard && <CustomCard onClose={() => onClosePop('customerCard')} userData={userData} setUserData={setUserData} pointData={pointData} couponData={couponData} selectHandler={selectHandler} userDataInit={userDataInit} setLnbSearchOptions={setLnbSearchOptions} getReceptionList={getReceptionList} changeUserHandler={changeUserHandler} />}
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
    </>
  )
}

export default CustomList