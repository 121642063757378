import React, { useState, } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import Chart from "../components/chart/Chart.js";
import request from "utils/Request.utils.js";
import { getTodayDate } from "../utils/Common.utils";

const ChartRoute = () => {
  let { pathname } = window.location

  const [chartSetting, setChartSetting] = useState(false);

  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const [lnbSearchOptions, setLnbSearchOptions] = useState({
    checkedBool: false, // 컴포넌트 노출 여부
    totalCount: 0, // 총 갯수
    searchList: null, // 검색 리스트
    searchKeyword: '', // 이름 or 차트코드 or 휴대전화 번호 검색
    userSeq: 0, // 고객 시퀀스
    send: false,
    sent: false
  })

  const [loadingBarActive, setLoadingBarActive] = useState(false);

  const getReceptionList = (order_seq, user_seq) => {
    setLoadingBarActive(true) // 로딩바 띄우기

    const successHandler = (response) => {

      if (response.messageCode === 200) {
        setLoadingBarActive(false) // 로딩바 없애기
      } else {
        console.log("접수 고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/charts/order/dashboard2?chart_type=chart&order_date=${getTodayDate('hyphen')}`, null, successHandler);
  }

  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    let authBool = false;

    authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

    if (authBool) {
      const authRoutes = [
        { path: "/", element: <Chart chartSetting={chartSetting} setChartSetting={setChartSetting} lnbSearchOptions={lnbSearchOptions} setLnbSearchOptions={setLnbSearchOptions} loadingBarActive={loadingBarActive} getReceptionList={getReceptionList} /> }
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/", element: <Navigate to='/main/login' /> },
        { path: "/*", element: <Navigate to='/main/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

  return (
    <>
      <Gnb />
      <main className="p_chart">
        <Lnb setChartSetting={setChartSetting} getReceptionList={getReceptionList} />
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
    </>
  )
}

export default ChartRoute