//* 내원경로별 매출비중
import React, { useEffect, useState, useRef } from "react";
import StatisticsSubDateSearch from "atoms/statistics/StatisticsSubDateSearch";
import { getTodayDate, getPreviousWeekDate, getPreviousMonthDate, getPreviousYearDate } from "../../utils/Common.utils";
import Graph from "atoms/common/graph/graph";

const VisitingRoute = () => {
  const today = getTodayDate()?.slice(0,17);
  const [periodOption, setPeriodOption] = useState(null);
  const [startDate, setStartDate] = useState(getTodayDate()?.slice(0,17));
  const [endDate, setEndDate] = useState('');
  const [periodValue, setPeriodValue] = useState(0);
  const [dateArray, setDateArray] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [visitTotal, setVisitTotal] = useState([]);
  const visitTotalCount = visitTotal.reduce((acc, cur) => acc + cur.total_count, 0);

  const subSearchAction = (getValue) => {
    setPeriodValue(getValue);

    if (getValue === 0){
      setStartDate(today);
      setEndDate(null);
    } else if(getValue === 1){
      setStartDate(getPreviousWeekDate()?.slice(0,17));
      setEndDate(today);
    } else if(getValue === 2){
      setStartDate(getPreviousMonthDate()?.slice(0,17));
      setEndDate(today);
    } else{
      setStartDate(getPreviousYearDate()?.slice(0,17));
      setEndDate(today);
    }
    setPeriodOption(getValue);
  }

  const setPeriodSearchDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodOption('4');
    setPeriodValue(1);
  }


	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">&nbsp;</article>
          <article className="com_lnb2_right">
            <StatisticsSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate}/>
          </article>
        </div>
      </section>
      <section className="com_content">
        <div className="com_list box">
          <article className="top_area">
            <div className="info_field">
              <p className="date">{periodValue === 0 ?  `${startDate}` : `${startDate} ~ ${endDate}`}</p>
            </div>
          </article>
          <article className="chart_area">
            차트영역
            <Graph dateArray={dateArray} data={visitData}/>
          </article>
        </div>
        <div className="com_list box">
          <table className="list sales">
            <colgroup><col width="9.3%"/><col width="9.3%"/><col width="9.3%"/><col width="9.3%"/><col width="9.3%"/><col width="9.3%"/><col width="9.3%"/><col width="9.3%"/><col/></colgroup>
            <thead>
              <tr>
                <th colSpan="9">내원경로별 매출비중</th>
              </tr>
              <tr>
                <th>내원경로</th>
                <th>웹</th>
                <th>앱</th>
                <th>SNS</th>
                <th>지인소개</th>
                <th>간판</th>
                <th>블로그</th>
                <th>버스</th>
                <th>총 매출액</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>환자수</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td rowSpan="3" className="total">0</td>
              </tr>
              <tr>
                <td>건수</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>매출액</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
		</>
	)
}

export default VisitingRoute