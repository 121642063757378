import { SET_MANAGER, CLEAR_MANAGER } from './ActionType';

const stateManagerInit = {
  manager_seq: 0,
  manager_name: '',
  manager_id: '',
  manager_gender: '',
  manager_dob: '',
  manager_status: '',
  manager_position: '',
  assignment_position: '',
  medical_license: '',
  manager_phone: '',
  manager_mobile: '',
  manager_email: '',
  manager_postcode: '',
  manager_address: '',
  manager_address_detail: '',
  last_login: '',
  reg_manager_seq: 0,
  reg_date: '',
  modi_manager_seq: 0,
  modi_date: '',
  withdrawal_manager_seq: 0,
  withdrawal_date: ''
}

const stateManager = (state = stateManagerInit, action) => {
  let { type } = action;

  switch (type) {
    case SET_MANAGER:
      let newState = {
        ...state,
        [action.payload.fieldName]: action.payload.data
      };

      return newState;

    case CLEAR_MANAGER:
      return stateManagerInit;

    default:
      return state;
  }
}

export default stateManager;