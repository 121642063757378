//* 진료별 예약 인원 관리
import React, { useRef, useState, useEffect } from "react";
import request from "utils/Request.utils";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert.js"; // alert창


const TypeManage = (props) => {
  let typeManageBool = props.typeManageBool;
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [checkedControl, setCheckedControl] = useState(false)
  const [checkedList, setCheckedList] = useState([])
  const [procedureLimit, setProcedureLimit] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const popupSectionRef = useRef();
  const nodeRef = useRef(null);

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: "",
    returnValue: () => { }
  })


  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }


  useEffect(() => {
    if (props.procedureCategory) {
      const initLimit = props.procedureCategory.map(el => {
        const limit = props.reservationLimit.find(limit => limit.category === el.common_code);
        return limit ? ({ rmc_seq: limit.rmc_seq, category: el.common_code, code_name: el.code_name, manage_amount: limit.manage_amount }) : { category: el.common_code, code_name: el.code_name, manage_amount: "" };
      });
      setProcedureLimit(initLimit)
    }
  }, [props.procedureCategory, props.reservationLimit]);



  // 진료별 예약인원 관리 팝업창 닫기
  const popupClose = () => {
    props.typeManageToggle()
  }

  // 진료 선택
  const checkedElement = (e, cate) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, cate])
    } else {
      setCheckedList(checkedList.filter(el => el !== cate))
    }
  }

  // 개별 입력
  const changePersonnelLimit = (e, order) => {
    const { value } = e.target;
    let copy = [...procedureLimit]
    copy[order] = { ...copy[order], manage_amount: value }

    setProcedureLimit(copy)
  }

  // 선택 값 전체입력
  const changeCheckedPersonnelLimit = (e) => {

    if (!checkedControl || checkedList.length === 0) {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "하단 진료과목을 선택해주세요." } })
      e.target.value = ""
      return
    }

    const updatedLimit = procedureLimit.map(el => {
      if (checkedList.find(chk => chk.category === el.category)) {
        return { ...el, manage_amount: e.target.value };
      } else {
        return el;
      }
    });
    setSelectValue(e.target.value);
    setProcedureLimit(updatedLimit);
  }
  

  // 등록
  const submitReservationLimit = () => {
    let params = {
      personnel_info: procedureLimit,
      reg_manager_seq: managerInfo.seq
    }
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        props.getReservationLimit();
        popupClose();
        setCheckedList([]);
        setCheckedControl(false);
        setSelectValue("");
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.post("/api/reservation/manage/C", params, successHandler, failHandler, null, null)
  }

  
  if (typeManageBool) popupSectionRef?.current?.classList?.add("active");
  else if (!typeManageBool && popupSectionRef?.current) popupSectionRef?.current?.classList?.remove("active");



  return (
    <>
      <section ref={popupSectionRef} className="com_popup type_normal pop_type_manage">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => {popupClose(); setCheckedList([]); setCheckedControl(false); setSelectValue("");}}></button>
            <div className="pop_header">
              <h1 className="pop_title">진료별 예약인원 관리</h1>
            </div>
            <div className="pop_body com_add_item">
              <article className="top_area">
                <fieldset className="com_check_wrap">
                  <input type="checkbox" className="checkbox" id="chk1" checked={checkedControl} onChange={() => setCheckedControl(!checkedControl)} />
                  <label className="btn_checkbox" htmlFor="chk1">&nbsp;</label>
                  <label htmlFor="chk1">선택항목 한번에 입력</label>
                  <input type="text" className="input" value={selectValue} onChange={(e) => changeCheckedPersonnelLimit(e)} checked={checkedControl} />
                </fieldset>
              </article>
              <article className="list">
                <ul className="item_wrap scroll">
                  {procedureLimit !== null && procedureLimit.map((procedure, index) => (
                    <li className="item" key={procedure.category}>
                      <fieldset className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id={procedure.category} onChange={(e) => checkedElement(e, procedure)} checked={checkedList.some(item => item.category === procedure.category)}/>
                        <label className="btn_checkbox" htmlFor={procedure.category}>&nbsp;</label>
                        <label htmlFor={procedure.category}>{procedure.code_name}</label>
                        <input type="text" className="input" onChange={(e) => changePersonnelLimit(e, index)} value={procedure.manage_amount || ""} />
                      </fieldset>
                    </li>
                  ))}
                </ul>
              </article>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue" onClick={() => submitReservationLimit()}>등록</button>
            </div>
          </div>
        </Draggable>
      </section>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={alertLayerPopup.returnValue} /> : null}
    </>
  )
}

export default TypeManage