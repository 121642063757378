//* 결산요약
import React, { useState, useRef, useEffect, useCallback } from "react";
import moment from 'moment';
import StatisticsSubButton from "atoms/statistics/StatisticsSubButton";
import StatisticsSubDateSearch2 from "atoms/statistics/StatisticsSubDateSearch2";
import { useReactToPrint } from 'react-to-print';
import { convDateToString, addComma } from "../../utils/Common.utils";
import { utils, writeFile } from 'xlsx';
import request from "utils/Request.utils";

const CalcSummary = () => {
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const componentRef = useRef();
  const tableRef = useRef();
  const [startDate, setStartDate] = useState(todayDate);  //검색 > 시작일
  const [endDate, setEndDate] = useState(todayDate);      //검색 > 종료일
  const [summData, setSummData] = useState([]);           //결산요약 DATA
  const [calcuData, setCalcuData] = useState({
    pay_amt: 0,
    cash_amt: 0,
    refund_amt: 0,
    real_cash: 0,
    real_bank: 0,
    real_card: 0,
    realPay_amt: 0,
    ratio_cash: 0,
    ratio_bank: 0,
    ratio_card: 0,
    ratio_tot: 100
  });           //결산요약 DATA

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `결산요약_${todayDate}`,
  });

  const subButtonAction = (getValue) => {
    // console.log(getValue);
  }

  //조회 > 결산요약
  const getSummary = useCallback( async () => {
    
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        console.log(response.data);
        setSummData(response?.data?.dataList);
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }
    request.get(`/api/statistics/settlement/summary?start_date=${startDate}&end_date=${endDate}`, null, successHandler, failHandler, null, null);

  },[startDate, endDate]);

  
  //계산 값들
  useEffect(() => {
    const pay_amt = Number(summData[0]?.payment_cash) + Number(summData[0]?.payment_bank) + Number(summData[0]?.payment_card);
    const cash_amt = Number(summData[0]?.payment_cash) + Number(summData[0]?.payment_bank);
    const realPay_amt = pay_amt - Number(summData[1]?.payment_price);

    //실 금액
    const real_cash = Number(summData[0]?.payment_cash) - Number(summData[1]?.payment_cash);
    const real_bank = Number(summData[0]?.payment_bank) - Number(summData[1]?.payment_bank);
    const real_card = Number(summData[0]?.payment_card) - Number(summData[1]?.payment_card);

    //비율
    const ratio_cash = Math.ceil(((real_cash / Number(summData[0]?.payment_cash)) * 100) * 100) / 100;
    const ratio_bank = Math.ceil(((real_bank / Number(summData[0]?.payment_bank)) * 100) * 100) / 100;
    const ratio_card = Math.ceil(((real_card / Number(summData[0]?.payment_card)) * 100) * 100) / 100;
    const ratio_tot =  Math.ceil(((realPay_amt / pay_amt) * 100) * 100) / 100;
    console.log('ratio_cash', ratio_cash);

    const calcu = {
      pay_amt: pay_amt,
      cash_amt: cash_amt,
      refund_amt: summData[1]?.payment_price,
      real_cash: real_cash,
      real_bank: real_bank,
      real_card: real_card,
      realPay_amt: realPay_amt,
      ratio_cash: ratio_cash,
      ratio_bank: ratio_bank,
      ratio_card: ratio_card,
      ratio_tot: ratio_tot
    }
    setCalcuData(calcu);
  },[summData]);


  useEffect(() => {
    getSummary();
  },[getSummary]);

  //엑셀 파일
  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `결산요약_${todayDate}.xlsx`);
  };


  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <StatisticsSubButton subButtonAction={subButtonAction} />
            </div>
          </article>
          <article className="com_lnb2_right">
            <StatisticsSubDateSearch2 searchHandler={getSummary} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
          </article>
        </div>
      </section>
      <section className="com_content">
        <div ref={componentRef}>
          <div className="com_list box" >
            <article className="top_area">
              <div className="info_field">
                <p className="date">{startDate === endDate ?  convDateToString(startDate) : `${convDateToString(startDate)} ~ ${convDateToString(endDate)}`}</p>
                <p className="num">매출내역</p>
              </div>
            </article>
            <table className="list" ref={tableRef}>
              <colgroup><col /><col width="9%" /><col width="9%" /><col width="9%" /><col width="9%" /><col width="9%" /><col width="9%" /><col width="9%" /><col width="9%" /><col width="9%" /><col width="9%" /></colgroup>
              <thead>
                <tr>
                  <th colSpan="11">매출 내역</th>
                </tr>
                <tr>
                  <th>분류</th>
                  <th>구분</th>
                  <th>보험본인부담금</th>
                  <th>비과세결제액</th>
                  <th>과세 총결제액</th>
                  <th>과세결제액</th>
                  <th>부가세</th>
                  <th>매출</th>
                  <th>환불</th>
                  <th>실 매출금</th>
                  <th>비율</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th rowSpan="2">현금 매출</th>
                  <th>현금</th>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td><span className="txt_blue">{addComma(summData[0]?.payment_cash)}</span></td>
                  <td>{addComma(summData[1]?.payment_cash)}</td>
                  <td>{addComma(calcuData.real_cash)}</td>
                  <td><span className="txt_blue">{calcuData.ratio_cash ? calcuData.ratio_cash : 0}%</span></td>
                </tr>
                <tr>
                  <th>통장</th>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td><span className="txt_blue">{addComma(summData[0]?.payment_bank)}</span></td>
                  <td>{addComma(summData[1]?.payment_bank)}</td>
                  <td>{addComma(calcuData.real_bank)}</td>
                  <td><span className="txt_blue">0.00%</span></td>
                </tr>
                <tr>
                  <th colSpan="2">현금 매출 합계</th>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td><span className="txt_blue">0</span></td>
                  <td>0</td>
                  <td>0</td>
                  <td><span className="txt_blue">0.00%</span></td>
                </tr>
                <tr>
                  <th colSpan="2">카드 매출</th>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td><span className="txt_blue">{addComma(summData[0]?.payment_card)}</span></td>
                  <td>{addComma(summData[1]?.payment_card)}</td>
                  <td>{addComma(calcuData.real_card)}</td>
                  <td><span className="txt_blue">{calcuData.ratio_card ? calcuData.ratio_card : 0}%</span></td>
                </tr>
                <tr className="total">
                  <th colSpan="2">매출 합계</th>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td><span className="txt_red">{addComma(calcuData.pay_amt)}</span></td>
                  <td>{addComma(calcuData.refund_amt)}</td>
                  <td><span className="txt_red">{addComma(calcuData.realPay_amt)}</span></td>
                  <td><span className="txt_blue">{calcuData.ratio_tot ? calcuData.ratio_tot : 0}%</span></td>
                </tr>
                <tr>
                  <th colSpan="2">*.과세/비과세 수납금</th>
                  <td colSpan="8">{addComma(calcuData.pay_amt)}</td>
                  <td><span className="txt_blue">0.00%</span></td>
                </tr>
                <tr>
                  <th colSpan="2">*.환불금</th>
                  <td colSpan="8">{addComma(calcuData.refund_amt)}</td>
                  <td><span className="txt_blue">0.00%</span></td>
                </tr>
                <tr>
                  <th colSpan="2">*.미수</th>
                  <td colSpan="8">{addComma(summData[0]?.unpaid_price)}</td>
                  <td><span className="txt_blue">0.00%</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="com_btn_bottom_area">
          <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
          <button className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
        </div>
      </section>
    </>
  )
}

export default CalcSummary