// *관리>시술관리>list
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import request from "utils/Request.utils.js";
import AlertUpdateOk from "components/notice/AlertWriteOk.js";
import Paging from '../../layout/Paging.js';//페이징
import { addComma } from "utils/Common.utils.js";
import { Link } from "react-router-dom";

const ProcedureOld = () => {
  const navigate = useNavigate();

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [allProcedureList, setAllProcedureList] = useState([]); // 시술 전체 리스트
  const [procedureCategory, setProcedureCategory] = useState([])// 시술 카테고리 리스트
  const [selectedCategory, setSelectedCategory] = useState("")
  const [searchData, setSearchData] = useState(""); // 검색어
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [numPerPage, setNumPerPage] = useState(10)
  const [pagePerBlock, setPagePerBlock] = useState(10)

  const [UpdateOkPopup, setUpdateOkPopup] = useState({
    closeType: 'next',
    visible: false
  });

  const closeAlertWrite = () => {
    setUpdateOkPopup((prev) => { return { ...prev, visible: false } })
  }
  // console.log(searchData, selectedCategoryㅋ)
  // 시술 전체 리스트 가져오기
  const getAllProcedure = () => {
    let params = {
      limit: numPerPage,
      offset: ((currentPage - 1) * numPerPage),
      searchData: searchData,
      category: selectedCategory,
      orderBy: "pack_seq DESC",
      delete_yn: "N",
      manager_mode: "all"
    }

    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        setAllProcedureList(response.data.dataList)
        setTotalCount(response.data.totalCnt)
      } else {
        console.log("시술 전체 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get(`/api/goods?gubun=P&limit=${params.limit}&offset=${params.offset}&searchData=${params.searchData}&category=${params.category}&delete_yn=${params.delete_yn}&manager_mode=${params.manager_mode}&order_by=${params.orderBy}`, null, successHandler, failHandler, null);
  }
  useEffect(() => {
    getAllProcedure() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedCategory])

  // 검색어 입력(시술명) & 시술 카테고리
  const changeSearchData = (e) => {
    const { name, value } = e.target
    console.log(name, value)
    if (name === "goods_name") {
      setSelectedCategory(value)
    } else if (name === "searchData") {
      setSearchData(value)
    }
  }


  // 시술 카테고리 가져오기
  const getProcedureCategory = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setProcedureCategory(response.data.dataList)
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get("/api/etc/commoncd/3/001000000", null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getProcedureCategory()
  }, [])

  const nextPageMove = (value) => {
    setCurrentPage(value)
  }
  const returnChildPack = (array) => {
    if (allProcedureList.length > 0) {
      let first = array?.split("::")[0];
      return first?.split("|");
    }
  }
  const returnCategory = (category) => {
    return procedureCategory.filter((el) => el.common_code === category)[0]?.code_name
  }
  const changeInList = (e, num) => {
    const { name, value, checked } = e.target
    let copy = [...allProcedureList];
    if (name.includes("display")) {
      if (copy[num].goods_type === "D" && copy[num].goods_info === "Y") {
        copy[num] = { ...copy[num], display_yn: value }
      } else if (copy[num].goods_type === "P" || copy[num].goods_type === "A" || copy[num].goods_info === "N") {
        copy[num] = { ...copy[num], p_display_yn: value }
      }
    } else if (name.includes("hot")) {
      if (copy[num].goods_type === "D" && copy[num].goods_info === "Y") {
        copy[num] = { ...copy[num], hot_yn: checked === true ? "Y" : "N" }
      } else if (copy[num].goods_type === "P" || copy[num].goods_type === "A" || copy[num].goods_info === "N") {
        copy[num] = { ...copy[num], p_hot_yn: checked === true ? "Y" : "N" }
      }
    }
    updateInList(copy[num])
    setAllProcedureList(copy)
  }

  const updateInList = (procedure) => {

    let params = {
      goods_seq: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.goods_seq : procedure.pack_seq,
      category: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.category : procedure.p_category,
      goods_type: procedure.goods_type === "D" && procedure.goods_info === "Y" ? "U" : procedure.goods_type === "P" && procedure.goods_info === "P" ? "P" : procedure.goods_type === "A" ? "A" : "D",
      insurance_type: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.insurance_type : procedure.p_insurance_type,
      vat_type: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.vat_type : procedure.p_vat_type,
      goods_name: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.goods_name : procedure.p_goods_name,
      goods_description: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.goods_description : procedure.p_goods_description,
      goods_price: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.goods_price : procedure.p_goods_price,
      goods_sale_price: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.goods_sale_price : procedure.p_goods_sale_price,
      goods_tag: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.goods_tag : procedure.p_goods_tag,
      hot_yn: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.hot_yn : procedure.p_hot_yn,
      event_yn: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.event_yn : procedure.p_event_yn,
      event_start_date: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.event_start_date : procedure.p_event_start_date,
      event_end_date: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.event_end_date : procedure.p_event_end_date,
      display_yn: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.display_yn : procedure.p_display_yn,
      view_flag: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.view_flag : procedure.p_view_flag,
      // using_movie_image: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.using_movie_image : procedure.p_using_movie_image,
      image_url: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.image_url : procedure.p_image_url,
      movie_url: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.movie_url : procedure.p_movie_url,
      upload_file_seq: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.upload_file_seq : procedure.p_upload_file_seq,
      recommendation_goods: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.recommendation_goods : procedure.p_recommendation_goods,
      detail_description: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.detail_description : procedure.p_detail_description,
      attention_items: procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.attention_items : procedure.p_attention_items,
      modi_manager_seq: managerInfo.seq,
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setUpdateOkPopup((prev) => { return { ...prev, visible: true } })
        // 다시 호출
        getAllProcedure()
      } else {
        console.log("시술 목록을 수정하지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log("에러" + response)
    }
    request.put("/api/goods", params, successHandler, failHandler, null, null);
  }
  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') getAllProcedure();
  };


  return (
    <>
      <AlertUpdateOk onClose={closeAlertWrite} popupVisible={UpdateOkPopup.visible} />
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_search_wrap">
              <section className="com_field type_normal">
                <fieldset className="many">
                  <select className="select" name="goods_name" onChange={(e) => changeSearchData(e)}>
                    <option value="">시술 카테고리 전체</option>
                    {procedureCategory?.map((category) => (
                      <option key={category.common_code} value={category.common_code}>{category.code_name}</option>
                    ))}
                  </select>
                  <div className="input_del_wrap">
                    <input type="text" className="input" placeholder="시술명 검색" name="searchData" value={searchData} onChange={(e) => changeSearchData(e)} onKeyDown={handleOnKeyPress} />
                    <button type="button" className="btn_input_del" onClick={() => setSearchData("")}></button>
                  </div>
                  <button type="button" className="com_btn m blue" onClick={() => getAllProcedure()}>조회</button>
                </fieldset>
              </section>
            </div>
          </article>
          <article className="com_lnb2_right">
            <button className="com_btn m blue" onClick={() => navigate("/boffice/procedure_add_old")}><i className="svg_icon icon_add yellow">&nbsp;</i>시술등록</button>
          </article>
        </div>
      </section>
      <section className="com_content">
        <div className="com_list box">
          <table className="list round">
            <colgroup><col width="7.3%" /><col width="9.5%" /><col /><col width="9.5%" /><col width="12.3%" /><col width="7.3%" /><col width="9.5%" /><col width="7.3%" /><col width="160px" /><col width="6.6%" /></colgroup>
            <thead className="sticky top">
              <tr>
                <th>등록일</th>
                <th>카테고리</th>
                <th>시술명</th>
                <th>시술 구분</th>
                <th>각 시술명</th>
                <th>각 시술 금액</th>
                <th>시술 금액</th>
                <th>HOT 노출(10)</th>
                <th>노출상태</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {allProcedureList != null && allProcedureList.length > 0 ? allProcedureList.map((procedure, index) => (
                <React.Fragment key={"procedure" + index}>
                  <tr>
                    <td rowSpan={procedure.children_goods !== null ? procedure.children_goods?.split("::").length : 1}>{procedure?.p_reg_date?.slice(0, 10)}</td>
                    <td rowSpan={procedure.children_goods !== null ? procedure.children_goods?.split("::").length : 1}>{procedure.goods_type === "D" && procedure.goods_info === "Y" ? returnCategory(procedure.category) : returnCategory(procedure.p_category)}</td>
                    <td rowSpan={procedure.children_goods !== null ? procedure.children_goods?.split("::").length : 1} className="tit">{procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.goods_name : procedure.p_goods_name}</td>
                    <td rowSpan={procedure.children_goods !== null ? procedure.children_goods?.split("::").length : 1}>{procedure.goods_type === "D" ? "일반" : procedure.goods_type === "P" ? "패키지" : "보조 상품"}{procedure.event_bool === 2 ? "/이벤트" : ""}</td>
                    <td className="tit">{procedure?.children_goods !== null ? returnChildPack(procedure?.children_goods)[1] : "-"}</td>
                    <td>{procedure.goods_type === "D" || procedure.children_goods === null ? "-" : /*  addComma(returnChildPack(procedure?.children_goods)[2]) : */ <><span className="x_price">{addComma(returnChildPack(procedure?.children_goods)[2])}</span>{"→"}{addComma(returnChildPack(procedure?.children_goods)[3])}</>} </td>
                    <td rowSpan={procedure.children_goods !== null ? procedure.children_goods?.split("::").length : 1}>{procedure.goods_type === "D" && procedure.event_yn === "N" ? addComma(procedure?.goods_price) : procedure.goods_type === "D" && procedure.event_yn === "Y" ? <><span className="x_price">{addComma(procedure?.goods_price)}</span>{"→"}{addComma(procedure?.goods_sale_price)}</>
                      : (procedure.goods_type === "P") && procedure.children_goods === null ? "-" : (procedure.goods_type === "P" && procedure.event_yn === "N") || ((procedure.goods_info === "N" || procedure.goods_type === "A") && procedure.p_event_yn === "N") ? addComma(procedure.p_goods_price) : <><span className="x_price">{addComma(procedure.p_goods_price)}</span>{"→"}{addComma(procedure.p_goods_sale_price)}</>}
                    </td>
                    <td rowSpan={procedure.children_goods !== null ? procedure.children_goods?.split("::").length : 1}>
                      <fieldset className="com_check_wrap">
                        <input type="checkbox" className="checkbox" id={"procedureChk" + index} name={"hot_yn" + index}
                          onChange={(e) => changeInList(e, index)} checked={procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.hot_yn === "Y" : procedure.goods_type === "P" || procedure.goods_type === "A" || procedure.goods_info === "N" ? procedure.p_hot_yn === "Y" : false} />
                        <label className="btn_checkbox" htmlFor={"procedureChk" + index}>&nbsp;</label>
                      </fieldset>
                    </td>
                    <td rowSpan={procedure.children_goods !== null ? procedure.children_goods?.split("::").length : 1}>
                      <fieldset className="com_radio_wrap">
                        <input type="radio" className="radio" id={"showType1" + index} name={"display_yn" + index} value="N"
                          onChange={(e) => changeInList(e, index)} checked={procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.display_yn === "N" : procedure.goods_type === "P" || procedure.goods_type === "A" || procedure.goods_info === "N" ? procedure.p_display_yn === "N" : false} />
                        <label className="btn_radio" htmlFor={"showType1" + index}>&nbsp;</label>
                        <label htmlFor={"showType1" + index}>비노출</label>
                        <input type="radio" className="radio" id={"showType2" + index} name={"display_yn" + index} value="Y"
                          onChange={(e) => changeInList(e, index)} checked={procedure.goods_type === "D" && procedure.goods_info === "Y" ? procedure.display_yn === "Y" : procedure.goods_type === "P" || procedure.goods_type === "A" || procedure.goods_info === "N" ? procedure.p_display_yn === "Y" : false} />
                        <label className="btn_radio" htmlFor={"showType2" + index}>&nbsp;</label>
                        <label htmlFor={"showType2" + index}>노출</label>
                      </fieldset>
                    </td>
                    <td rowSpan={procedure.children_goods !== null ? procedure.children_goods?.split("::").length : 1}>
                      <Link to={"/boffice/procedure_add_old"} state={{ goods_seq: procedure.pack_seq, editBoolean: true }}><button className="com_btn txt" >상세 보기</button></Link>
                    </td>
                  </tr>
                  {procedure.children_goods !== null ? procedure.children_goods?.split("::").slice(1).map((child, index) => (
                    <tr key={"child" + index}>
                      <td className="tit">{child.split("|")[1]}</td>
                      <td>{procedure.event_yn === "N" ? addComma(child.split("|")[2]) : <><span className="x_price">{addComma(child.split("|")[2])}</span>{"→"}{addComma(child.split("|")[3])}</>}</td>
                    </tr>
                  )) : null}
                </React.Fragment>
              ))
                :
                <tr>
                  <td colSpan="10">데이터가 없습니다.</td>
                </tr>
              }
            </tbody>
          </table>
          <article className="bottom_area">
            <Paging totalCount={totalCount} currentPage={currentPage} numPerPage={numPerPage} pagePerBlock={pagePerBlock} nextPageMove={nextPageMove} />
          </article>
        </div>
      </section>
    </>
  )
}

export default ProcedureOld