//* 처방전
import React, { useState, useEffect } from "react";
import request from "utils/Request.utils";
import SetPrescriptionManage from "components/chart/_SetPrescriptionManage";
import CustomAlert from "atoms/common/CustomAlert.js"; // alert창
import SetPrescriptionSave from "components/chart/_SetPrescriptionSave";

const DocumentPrescription = ({ popup, onClosePop, onPopupClick, getMedicines, medicines, searchData, setSearchData, prescriptionInfo, setPrescriptionInfo, medicineSetList, setMedicineSetList }) => {

  // const [medicineSetList, setMedicineSetList] = useState([]) // 세트처방 리스트
  const [medicineSetUpdateList, setMedicineSetUpdateList] = useState([]) // 세트처방 수정 리스트
  const [medicineSetCategory, setMedicineSetCategory] = useState([]); // 세트처방 카테고리
  const [onUpdateBool, setOnUpdateBool] = useState(false)

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })
  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  //세트처방 카테고리 불러오기
  const getMedicineSetCategory = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setMedicineSetCategory(response.data)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get("/api/medicine/set/code", null, successHandler, failHandler, null, null)
  }

  // 세트처방 약품 불러오기
  const getMedicineSetList = (location, selectedSetSeq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        if (location === "main") setMedicineSetList(response.data)
        else setMedicineSetUpdateList(response.data)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/medicine/S/${selectedSetSeq}`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getMedicineSetCategory()
  }, [])

  // 세트처방 약품 추가
  const addMedicineToSet = (medicine) => {
    if (medicineSetList?.findIndex(mediSet => mediSet.medi_seq === medicine.medi_seq) >= 0) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "세트처방 리스트에 추가된 약품입니다." } })
      return
    } else {
      // added by wem 20230911 약품 회차 수정 시 금액 변경 위해 추가
      const addMedicine = { ...medicine }
      addMedicine.initialPrice = addMedicine.upper_limit_price;
      addMedicine.upper_limit_price = addMedicine.day_amount * addMedicine.day_injection * addMedicine.upper_limit_price
      setMedicineSetList(prevMedicineSet => Array.isArray(prevMedicineSet) ? [...prevMedicineSet, addMedicine] : [addMedicine]);
    }
  }
  // 세트처방 약품 삭제
  const deleteMedicineFromSet = (location, medi_seq) => {
    if (location === "main") setMedicineSetList(medicineSetList.filter(mediSet => mediSet.medi_seq !== medi_seq))
    else setMedicineSetUpdateList(medicineSetUpdateList.filter(mediSet => mediSet.medi_seq !== medi_seq))
  }

  // 세트처방 선택
  const selectedSetMedicine = (e) => {
    const { value } = e.target;
    if (value === "0") {
      setMedicineSetList([])
    }
    // setSelectedSetSeq(e.target.value)
    getMedicineSetList("main", value)
  }

  // 약품 회차 변경 +
  const plusTimes = (name, index) => {
    const updatedList = [...medicineSetList]
    const item = updatedList[index];

    if (name === "day_injection") {
      item.day_injection = parseInt(item.day_injection, 10) + 1;
    } else {
      item.day_amount = parseInt(item.day_amount, 10) + 1;
    }

    if (item.in_out_flag === "I") {
      item.upper_limit_price = item.initialPrice * item.day_amount * item.day_injection;
    }

    setMedicineSetList(updatedList);
  }

  // 약품 회차 변경 -
  const minusTimes = (name, index) => {
    const updatedList = [...medicineSetList]
    const item = updatedList[index];

    if (name === "day_injection") {
      const newValue = parseInt(item.day_injection, 10) - 1;
      item.day_injection = newValue >= 0 ? newValue : 0;
    } else {
      const newValue = parseInt(item.day_amount, 10) - 1;
      item.day_amount = newValue >= 0 ? newValue : 0;
    }
    if (item.in_out_flag === "I") {
      item.upper_limit_price = item.initialPrice * item.day_amount * item.day_injection;
    }
    setMedicineSetList(updatedList);
  }



  const returnAlertValue = (value) => {

  }

  return (
    <div className="p_medi_prescription">
      <div className="left">
        <div className="search">
          <select className="select" onChange={(e) => selectedSetMedicine(e)}>
            <option value="0">세트처방 선택</option>
            {medicineSetCategory.length > 0 && medicineSetCategory.map(setCate => (
              <option key={setCate.medi_set_seq} value={setCate.medi_set_seq}>{setCate.medi_set_name}</option>
            ))}
          </select>
          <button className="com_btn blue m" id="setPrescriptionManage" onClick={onPopupClick}><i className="svg_icon icon_set yellow">&nbsp;</i>세트처방관리</button>
        </div>
        <article className="com_list">
          <div className="scroll">
            <table className="list">
              <colgroup><col width="8%" /><col /><col width="11%" /><col width="11%" /><col width="11%" /><col width="11%" /><col width="11%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th></th>
                  <th>약 명 리스트</th>
                  <th>용량</th>
                  <th>일투</th>
                  <th>일수</th>
                  <th>구분</th>
                  <th>금액</th>
                </tr>
              </thead>
              <tbody>
                {medicineSetList?.length > 0 ? medicineSetList?.map((mediSet, index) => (
                  <tr key={mediSet.medi_seq}>
                    <td>
                      <button className="com_btn lightgray icon btn_del" onClick={() => deleteMedicineFromSet("main", mediSet.medi_seq)}><i className="svg_icon icon_x" >&nbsp;</i></button>
                    </td>
                    <td className="tit">{mediSet.medi_name}</td>
                    <td>{mediSet.medi_volume}</td>
                    {/* button태그 스타일 조정 필요  */}
                    <td>
                      <button className="com_btn icon m"><i id={index} onClick={() => minusTimes("day_injection", index)} className="svg_icon icon_minus">&nbsp;</i></button>
                      {mediSet.day_injection}
                      <button className="com_btn icon m"><i id={index} onClick={() => plusTimes("day_injection", index)} className="svg_icon icon_plus">&nbsp;</i></button>
                    </td>
                    <td>
                      <button className="com_btn icon m"><i id={index} onClick={() => minusTimes("day_amount", index)} className="svg_icon icon_minus">&nbsp;</i></button>
                      {mediSet.day_amount}
                      <button className="com_btn icon m"><i id={index} onClick={() => plusTimes("day_amount", index)} className="svg_icon icon_plus">&nbsp;</i></button>
                    </td>
                    {/* button태그 스타일 조정 필요  */}
                    <td>{mediSet.insurance_type === "I" ? "급여" : "비급여"}</td>
                    <td>{mediSet.in_out_flag === "I" ? mediSet.upper_limit_price : "원외"} </td>
                  </tr>
                )) : null}
              </tbody>
            </table>
          </div>
        </article>
        <ul className="com_input_section">
          <li className="tit_field">처방전 사용기간</li>
          <li className="input_field">
            <div className="input_wrap date">
              <p>교부일로 부터 <input type="text" className="input" value={prescriptionInfo.period_amount} onChange={(e) => setPrescriptionInfo({ ...prescriptionInfo, period_amount: e.target.value })} /> 일간</p>
              <button className="com_btn blue m" id="add" onClick={onPopupClick}><i className="svg_icon icon_add yellow">&nbsp;</i>세트 처방내용 저장</button>
            </div>
          </li>
          <li className="tit_field">처방전 용도</li>
          <li className="input_field">
            <fieldset className="com_radio_wrap">
              <input type="radio" className="radio" id="prescriptionRadio01" name="prescriptionRadio" checked={prescriptionInfo.prescription_use === "018001000"} value={prescriptionInfo.prescription_use} onChange={(e) => setPrescriptionInfo({ ...prescriptionInfo, prescription_use: "018001000" })} />
              <label className="btn_radio" htmlFor="prescriptionRadio01" />
              <label htmlFor="prescriptionRadio01">제출</label>
              <input type="radio" className="radio" id="prescriptionRadio02" name="prescriptionRadio" checked={prescriptionInfo.prescription_use === "018002000"} value={prescriptionInfo.prescription_use} onChange={(e) => setPrescriptionInfo({ ...prescriptionInfo, prescription_use: "018002000" })} />
              <label htmlFor="prescriptionRadio02" className="btn_radio" />
              <label htmlFor="prescriptionRadio02">보관</label>
            </fieldset>
          </li>
        </ul>
      </div>
      <div className="right">
        <fieldset className="many">
          <div className="input_del_wrap">
            <input type="text" className="input" placeholder="약 검색" name="drugSearch" value={searchData} onChange={(e) => setSearchData(e.target.value)} />
            <button type="button" className="btn_input_del" onClick={() => setSearchData("")}></button>
          </div>
          <button type="button" className="com_btn m blue icon" onClick={() => getMedicines()}><i className="svg_icon icon_search"></i></button>
        </fieldset>
        <div className="com_list">
          <div className="scroll">
            <table className="list">
              <colgroup><col width="13%" /><col /><col width="15%" /><col width="15%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>추가</th>
                  <th>약 명 리스트</th>
                  <th>구분</th>
                  <th>금액</th>
                </tr>
              </thead>
              <tbody>
                {medicines.length > 0 ? medicines.map((medicine, i) => (
                  <tr key={i}>
                    <td><button type="button" className="btn_more" onClick={() => addMedicineToSet(medicine)}>&nbsp;</button></td>
                    <td className="tit">{medicine.medi_name}</td>
                    <td>{medicine.insurance_type === "I" ? "급여" : "비급여"}</td>
                    <td>{medicine.in_out_flag === "I" ? medicine.upper_limit_price : "원외"}</td>
                  </tr>
                ))
                  :
                  <tr>
                    <td colSpan="4">데이터가 없습니다.</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {popup.setPrescriptionManage && <SetPrescriptionManage onClose={() => { onClosePop('setPrescriptionManage') }} medicineSetCategory={medicineSetCategory} setMedicineSetCategory={setMedicineSetCategory} getMedicineSetCategory={getMedicineSetCategory} setOnUpdateBool={setOnUpdateBool} getMedicineSetList={getMedicineSetList} />}
      {(popup.add || onUpdateBool) && <SetPrescriptionSave onClose={() => { onClosePop('add') }} medicineSetList={medicineSetList} onUpdateBool={onUpdateBool} setOnUpdateBool={setOnUpdateBool} medicineSetUpdateList={medicineSetUpdateList} deleteMedicineFromSet={deleteMedicineFromSet} getMedicineSetCategory={getMedicineSetCategory} />}
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
    </div>
  )
}

export default DocumentPrescription