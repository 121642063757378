import React, { useState } from 'react';
// import Calendar from 'react-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';

import Toolbar from './CalendarToolbar';

import '../../styles/scss/component/_popupCalendar.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

export default function CalendarTable(props) {
  moment.locale('ko-KR');

  const eventsForThisDay = (slotInfo) => {
    const { action, start, end, slots } = slotInfo;

    if (slots.length > 1) {
      // eslint-disable-next-line array-callback-return
      slots.map((item, index) => {
        console.log("기간 : " + moment(item).format("YYYY-MM-DD"))
      });
    }

    else {
      console.log("하루 : " + moment(start).format("YYYY-MM-DD"))
    }
  }


  const selectDate = (event) => {
    props.setReservationDateList(props.reservationMonthList.filter((el) => {
      const isCanceled = event.title.includes("취소") && el.reser_status === "C";
      const isChanged = event.title.includes("변경") && el.reser_status === "R" && el.modi_date !== null;
      const isMorning = event.title.includes("오전") && parseInt(el.reser_time.split(":")[0]) < 12;
      const isAfternoon = event.title.includes("오후") && parseInt(el.reser_time.split(":")[0]) >= 12;
      const isMatchedDate = moment(el.reser_date).isSame(moment(event.start), "day");

      return (isMatchedDate && (isCanceled || isChanged || isMorning || isAfternoon));
    }));
    props.setReserPopup(true)
  }


  const selectMonth = (date, view) => {
    props.setReservationMonth(moment(date).format("YYYY-MM"))
  }

  return (
    <div className='CalendarTable'>
      <Calendar
        localizer={localizer}
        events={props.eventList != null ? props.eventList : null}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        style={{ height: 700 }}
        components={{ toolbar: Toolbar }} // 이 부분 삭제하면 default 옵션이 나옴
        onSelectSlot={(slotInfo) => {
          // console.log(slotInfo)

          eventsForThisDay(slotInfo)
        }}
        eventPropGetter={(ReservationMonth) => {// backgorund-color, color 값 설정 가능하도록
          const backgroundColor = ReservationMonth.colorEvento;
          const color = ReservationMonth.color;
          return { style: { backgroundColor, color } }
        }}
        onSelectEvent={selectDate}
        onNavigate={selectMonth}
        selectable
        popup={true}
      />

    </div>
  )
}