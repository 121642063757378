import React, { useState, useEffect } from "react";

import request from '../../utils/Request.utils';
import { nvl } from "../../utils/Common.utils";

const MyCompany = () => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [managerFullInfo, setManagerFullInfo] = useState({});

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMyInfo = () => {
    const successHandler = (response) => {
      // console.log(response);

      if(response.messageCode === 200) { // ok 코드 값
        setManagerFullInfo(response.data.dataList[0])        
      } else if(response.messageCode === 444) { // accessToken 만료된 상태
        window.location.href = "/main/login"
        return
      } else {
        console.log("에라야 에라");
      }
    }

    request.get("/api/manager/fullinfo/list/" + managerInfo?.seq, null, successHandler);
  }

	return (
		<>
			<section className="com_my_company">
        <article className="company">{ managerFullInfo.company_name }</article>
        <article className="info">
          <p className="tit">사업자번호</p>
          <p className="txt">{ managerFullInfo.registration_number }</p>
        </article>
        <article className="info">
          <p className="tit">상호</p>
          <p className="txt">{ managerFullInfo.company_name }</p>
        </article>
        <article className="info address">
          <p className="tit">소재지</p>
          <p className="txt">{ managerFullInfo.company_address }{ nvl(managerFullInfo.company_address_detail) !== "" ? " " + managerFullInfo.company_address_detail : "" }</p>
        </article>
        <article className="info">
          <p className="tit">전화번호</p>
          <p className="txt">{ managerFullInfo.representative_phone }</p>
        </article>
        <article className="info">
          <p className="tit">대표이름</p>
          <p className="txt">{ managerFullInfo.representative_name }</p>
        </article>
        <article className="info">
          <p className="tit">대표 휴대폰번호</p>
          <p className="txt">{ managerFullInfo.representative_mobile }</p>
        </article>
      </section>
		</>
	)
}

export default MyCompany