import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "utils/Request.utils.js";
import TypeManage from "./_TypeManage.js"//진료별예약인원 관리 팝업
import DayList from "./_DayList.js";
import moment from "moment";
import LoadingBar from '../../utils/LoadingBar';
import { useSelector } from "react-redux";

const ReservationType = ({ loadingBarActive }) => {

  const stateToday = useSelector(state => state.Today)

  const [typeManageBool, setTypeManageBool] = useState(false)
  const [reserPopup, setReserPopup] = useState(false)


  // 시간별 예약인원 관리 팝업창
  const typeManageToggle = () => {
    setTypeManageBool(!typeManageBool)
  }

  // 일 달력 라벨
  const getDayLabel = (today) => {
    const date = new Date(today)
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const dayOfMonth = date.getDate();
    const weekday = date.getDay();
    const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
    const weekdayLabel = weekdays[weekday];
    return `${year}년 ${month}월 ${dayOfMonth}일 (${weekdayLabel})`;
  };

  // 이전 요일 가져오기
  const getPrevDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date.toISOString().split('T')[0];
  };

  // 이후 요일 가져오기
  const getNextDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  };

  const [reservationDate, setReservationDate] = useState(stateToday);


  const handlePrevDate = () => {
    const prevDate = getPrevDate(reservationDate);
    setReservationDate(prevDate);
  };

  const handleNextDate = () => {
    const nextDate = getNextDate(reservationDate);
    setReservationDate(nextDate);
  };

  // 시간 배열  가져오기
  const getTimeString = (hour) => {
    if (hour < 12) {
      return hour === 0 ? 12 : hour;
    } else {
      return hour === 12 ? 12 : hour
    }
  }

  const getTimeArray = () => {
    const times = [];
    for (let i = 10; i <= 21; i++) {
      for (let j = 0; j < 60; j += 30) {
        if (i === 21 && j === 30) {
          break;
        }
        const timeString =
          `${getTimeString(i)}:${(j === 0 ? '00' : '30')}`;
        times.push(timeString);
      }
    }
    return times;
  };

  const changeReservationTime = (time) => {
    const hours = parseInt(time.split(":")[0], 10)
    const minutes = parseInt(time.split(":")[1], 10)

    if (minutes === 0) {
      return `${hours}:00`;
    } else if (minutes <= 30) {
      return `${hours}:30`;
    } else {
      return `${hours + 1}:00`;
    }
  }

  const [reservationTime, setReservationTime] = useState(getTimeArray())
  const [procedureCategory, setProcedureCategory] = useState([])// 시술 카테고리 리스트
  const [reservationDateList, setReservationDateList] = useState([]) // 일일예약자리스트
  const [reservationTodayList, setReservationTodayList] = useState([])
  const [reservationLimit, setReservationLimit] = useState([])
  const [selectDate, setSelectDate] = useState("")


  // 시술 카테고리 가져오기
  const getProcedureCategory = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setProcedureCategory(response.data.dataList)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response);
    }

    request.get("/api/etc/commoncd/3/001000000", null, successHandler, failHandler, null, null);
  }



  // 일일 예약 내역 불러오기
  const getReservationToday = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setReservationTodayList(response.data.dataList)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get(`/api/reservation?listType=F&delete_yn=N&reser_date=${moment(reservationDate).format("YYYY-MM-DD")}`, null, successHandler, failHandler, null, null)
  }

  useEffect(() => {
    getReservationToday()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationDate])

  // 예약인원 불러오기
  const getReservationLimit = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        let result = response.data
        let formattedData = result.map(el => ({ rmc_seq: el.rmc_seq, category: el.category, manage_amount: el.manage_amount, modi_manager_seq: el.reg_manager_seq }));
        setReservationLimit(formattedData)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get("/api/reservation/manage?type=C", null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getProcedureCategory()
    getReservationLimit()
  }, [])


  // 예약자 확인하기
  const selectDateReservation = (cate, time, idx) => {
    const onDate = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === cate && changeReservationTime(el.reser_time) === time);
    setSelectDate({ cate, time, idx })

    if (onDate.length > 0) {
      setReservationDateList(reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info.split(",")[4].split(":")[1] === cate && changeReservationTime(el.reser_time) === time))
      setReserPopup(true)
    } else {
      setReservationDateList([])
    }

  }
  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <Link to={"/reservation"} className="com_btn m lightgray ">월간</Link>
              <Link to={"/reservation/week"} className="com_btn m  lightgray">주간</Link>
              <Link to={"/reservation/type"} className="com_btn m  blue">진료분야</Link>
            </div>
          </article>
          <article className="com_lnb2_center com_select_date">
            <button className="com_btn" onClick={() => handlePrevDate()}><i className="svg_icon icon_prev white">&nbsp;</i></button>
            <p className="date">{getDayLabel(reservationDate)}</p>
            <button className="com_btn" onClick={() => handleNextDate()}><i className="svg_icon icon_next white">&nbsp;</i></button>
          </article>
          <article className="com_lnb2_right">
            <button className="com_btn blue m" onClick={() => typeManageToggle()}><i className="svg_icon icon_set yellow">&nbsp;</i>진료별 예약인원 관리</button>
            <button className="com_btn icon m"><i className="svg_icon icon_refresh">&nbsp;</i></button>
          </article>
        </div>
      </section>
      <section className="com_content com_reservation">
        <div className="com_list box">
          <div className="scroll">
            <table className="list">
              <thead className="sticky top">
                <tr>
                  <th>시간</th>
                  {procedureCategory !== null && procedureCategory.map((category, index) => (
                    <th key={category.common_code}>{category.code_name}<br />
                      ({reservationTodayList.filter(el => el.goods_info?.split("|")[2] === category.common_code).length} /{reservationLimit.filter(el => el.category === category.common_code)[0]?.manage_amount * 23 || 0})</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reservationTime !== null && reservationTime?.map((reserveTime, index) => {
                  let tune_purple = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[0].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let all_six = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[1].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let botox = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[2].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let all_care = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[3].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let elastic = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[4].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let pore = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[5].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let whitening = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[6].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let tattoo = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[7].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let skin_care = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[8].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let skin_booster = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[9].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let contour = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[10].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let body = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[11].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let waxing = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[12].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let scalp_care = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[13].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let anti_aging = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[14].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let vaccination = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[15].common_code && reserveTime === changeReservationTime(el.reser_time))
                  let etc = reservationTodayList.filter(el => el.goods_info === "pack_null" ? "" : el.goods_info?.split(",")[4].split(":")[1] === procedureCategory[16].common_code && reserveTime === changeReservationTime(el.reser_time))
                  return (
                    <tr key={"reser" + index}>
                      <td>{parseInt(reserveTime.split(":")[0]) < 12 ? `오전 ${reserveTime}` : parseInt(reserveTime.split(":")[0]) === 12 ? `오후 ${reserveTime}` : `오후 ${parseInt(reserveTime.split(":")[0] - 12)}:${reserveTime.split(":")[1]}`}</td>
                      <td className={selectDate.cate === procedureCategory[0]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[0].common_code, reserveTime, index)}>
                        {tune_purple.length > 0 ? <span className={tune_purple[0].type_limit && tune_purple[0]?.type_limit < tune_purple.length ? "people_over" : tune_purple[0].type_limit && Math.ceil(tune_purple[0]?.time_limit * 0.6) < tune_purple.length ? "people_60per" : tune_purple[0].type_limit && Math.ceil(tune_purple[0]?.type_limit * 0.5) < tune_purple.length ? "people_half" : ""}>{tune_purple.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[1]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[1].common_code, reserveTime, index)}>
                        {all_six.length > 0 ? <span className={all_six[0].type_limit && all_six[0]?.type_limit < all_six.length ? "people_over" : all_six[0].type_limit && Math.ceil(all_six[0]?.type_limit * 0.6) < all_six.length ? "people_60per" : all_six[0].type_limit && Math.ceil(all_six[0]?.type_limit * 0.5) < all_six.length ? "people_half" : ""}>{all_six.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[2]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[2].common_code, reserveTime, index)}>
                        {botox.length > 0 ? <span className={botox[0].type_limit && botox[0]?.type_limit < botox.length ? "people_over" : botox[0].type_limit && Math.ceil(botox[0]?.type_limit * 0.6) < botox.length ? "people_60per" : botox[0].type_limit && Math.ceil(botox[0]?.type_limit * 0.5) < botox.length ? "people_half" : ""}>{botox.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[3]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[3].common_code, reserveTime, index)}>
                        {all_care.length > 0 ? <span className={all_care[0].type_limit && all_care[0]?.type_limit < all_care.length ? "people_over" : all_care[0].type_limit && Math.ceil(all_care[0]?.type_limit * 0.6) < all_care.length ? "people_60per" : all_care[0].type_limit && Math.ceil(all_care[0]?.type_limit * 0.5) < all_care.length ? "people_half" : ""}>{all_care.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[4]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[4].common_code, reserveTime, index)}>
                        {elastic.length > 0 ? <span className={elastic[0].type_limit && elastic[0]?.type_limit < elastic.length ? "people_over" : elastic[0].type_limit && Math.ceil(elastic[0]?.type_limit * 0.6) < elastic.length ? "people_60per" : elastic[0].type_limit && Math.ceil(elastic[0]?.type_limit * 0.5) < elastic.length ? "people_half" : ""}>{elastic.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[5]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[5].common_code, reserveTime, index)}>
                        {pore.length > 0 ? <span className={pore[0].type_limit && pore[0]?.type_limit < pore.length ? "people_over" : pore[0].type_limit && Math.ceil(pore[0]?.type_limit * 0.6) < pore.length ? "people_60per" : pore[0].type_limit && Math.ceil(pore[0]?.type_limit * 0.5) < pore.length ? "people_half" : ""}>{pore.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[6]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[6].common_code, reserveTime, index)}>
                        {whitening.length > 0 ? <span className={whitening[0].type_limit && whitening[0]?.type_limit < whitening.length ? "people_over" : whitening[0].type_limit && Math.ceil(whitening[0]?.type_limit * 0.6) < whitening.length ? "people_60per" : whitening[0].type_limit && Math.ceil(whitening[0]?.type_limit * 0.5) < whitening.length ? "people_half" : ""}>{whitening.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[7]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[7].common_code, reserveTime, index)}>
                        {tattoo.length > 0 ? <span className={tattoo[0].type_limit && tattoo[0]?.type_limit < tattoo.length ? "people_over" : tattoo[0].type_limit && Math.ceil(tattoo[0]?.type_limit * 0.6) < tattoo.length ? "people_60per" : tattoo[0].type_limit && Math.ceil(tattoo[0]?.type_limit * 0.5) < tattoo.length ? "people_half" : ""}>{tattoo.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[8]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[8].common_code, reserveTime, index)}>
                        {skin_care.length > 0 ? <span className={skin_care[0].type_limit && skin_care[0]?.type_limit < skin_care.length ? "people_over" : skin_care[0].type_limit && Math.ceil(skin_care[0]?.type_limit * 0.6) < skin_care.length ? "people_60per" : skin_care[0].type_limit && Math.ceil(skin_care[0]?.type_limit * 0.5) < skin_care.length ? "people_half" : ""}>{skin_care.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[9]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[9].common_code, reserveTime, index)}>
                        {skin_booster.length > 0 ? <span className={skin_booster[0].type_limit && skin_booster[0]?.type_limit < skin_booster.length ? "people_over" : skin_booster[0].type_limit && Math.ceil(skin_booster[0]?.type_limit * 0.6) < skin_booster.length ? "people_60per" : skin_booster[0].type_limit && Math.ceil(skin_booster[0]?.type_limit * 0.5) < skin_booster.length ? "people_half" : ""}>{skin_booster.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[10]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[10].common_code, reserveTime, index)}>
                        {contour.length > 0 ? <span className={contour[0].type_limit && contour[0]?.type_limit < contour.length ? "people_over" : contour[0].type_limit && Math.ceil(contour[0]?.type_limit * 0.6) < contour.length ? "people_60per" : contour[0].type_limit && Math.ceil(contour[0]?.type_limit * 0.5) < contour.length ? "people_half" : ""}>{contour.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[11]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[11].common_code, reserveTime, index)}>
                        {body.length > 0 ? <span className={body[0].type_limit && body[0]?.type_limit < body.length ? "people_over" : body[0].type_limit && Math.ceil(body[0]?.type_limit * 0.6) < body.length ? "people_60per" : body[0].type_limit && Math.ceil(body[0]?.type_limit * 0.5) < body.length ? "people_half" : ""}>{body.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[12]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[12].common_code, reserveTime, index)}>
                        {waxing.length > 0 ? <span className={waxing[0].type_limit && waxing[0]?.type_limit < waxing.length ? "people_over" : waxing[0].type_limit && Math.ceil(waxing[0]?.type_limit * 0.6) < waxing.length ? "people_60per" : waxing[0].type_limit && Math.ceil(waxing[0]?.type_limit * 0.5) < waxing.length ? "people_half" : ""}>{waxing.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[13]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[13].common_code, reserveTime, index)}>
                        {scalp_care.length > 0 ? <span className={scalp_care[0].type_limit && scalp_care[0]?.type_limit < scalp_care.length ? "people_over" : scalp_care[0].type_limit && Math.ceil(scalp_care[0]?.type_limit * 0.6) < scalp_care.length ? "people_60per" : scalp_care[0].type_limit && Math.ceil(scalp_care[0]?.type_limit * 0.5) < scalp_care.length ? "people_half" : ""}>{scalp_care.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[14]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[14].common_code, reserveTime, index)}>
                        {anti_aging.length > 0 ? <span className={anti_aging[0].type_limit && anti_aging[0]?.type_limit < anti_aging.length ? "people_over" : anti_aging[0].type_limit && Math.ceil(anti_aging[0]?.type_limit * 0.6) < anti_aging.length ? "people_60per" : anti_aging[0].type_limit && Math.ceil(anti_aging[0]?.type_limit * 0.5) < anti_aging.length ? "people_half" : ""}>{anti_aging.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[15]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[15].common_code, reserveTime, index)}>
                        {vaccination.length > 0 ? <span className={vaccination[0].type_limit && vaccination[0]?.type_limit < vaccination.length ? "people_over" : vaccination[0].type_limit && Math.ceil(vaccination[0]?.type_limit * 0.6) < vaccination.length ? "people_60per" : vaccination[0].type_limit && Math.ceil(vaccination[0]?.type_limit * 0.5) < vaccination.length ? "people_half" : ""}>{vaccination.length}명</span> : ""}
                      </td>
                      <td className={selectDate.cate === procedureCategory[16]?.common_code && selectDate.time === reserveTime && selectDate.idx === index ? "active" : ""} onClick={() => selectDateReservation(procedureCategory[16].common_code, reserveTime, index)}>
                        {vaccination.length > 0 ? <span className={etc[0].type_limit && etc[0]?.type_limit < etc.length ? "people_over" : etc[0].type_limit && Math.ceil(etc[0]?.type_limit * 0.6) < etc.length ? "people_60per" : etc[0].type_limit && Math.ceil(etc[0]?.type_limit * 0.5) < etc.length ? "people_half" : ""}>{etc.length}명</span> : ""}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <TypeManage typeManageBool={typeManageBool} typeManageToggle={typeManageToggle} procedureCategory={procedureCategory} reservationLimit={reservationLimit} setReservationLimit={setReservationLimit} getReservationLimit={getReservationLimit} />
      {reserPopup && <DayList reservationDateList={reservationDateList} setReserPopup={setReserPopup} />}
      {loadingBarActive && <LoadingBar type={"spin"} color={"#000000"} />}
    </>
  )
}

export default ReservationType