import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";

import request from "utils/Request.utils";
import ConsentDetail from "./_ConsentDetail";
import CustomAlert from "atoms/common/CustomAlert.js";

import { nvl, nvlNumber, convertGender } from "../../utils/Common.utils";
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

const UserContentList = ({ list, onClickDetail }) => {
  return (
    list?.map((item, index) => {
      return (
        <tr key={ item.agree_seq }>
          <td key={ item.agree_seq + '_date' }>{ item.reg_date }</td>
          <td key={ item.agree_seq + '_name' } className="tit">{ item.agree_name }</td>
          <td key={ item.agree_seq + '_button' }><button className="com_btn txt" onClick={ (e) => onClickDetail(e, item.agree_name, item.agree_description) } id={ item.agree_seq }>상세보기</button></td>
        </tr>
      )
    })
  )
}

const ConsentList = ({ userData, onClose }) => {
  const stateCustomer = useSelector(state => state.Customer);

  const [popup, setPopup] = useState(false);

  const nodeRef = useRef(null);
  const componentRef = useRef();
  const today = moment(new Date()).format("YYYYMMDD");

  const [categoryList, setCategoryList] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [userContentList, setUserContentList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('')

  const selectedContentInit = {
    user_seq: 0,
    agree_seq: 0,
    agree_name: '',
    agree_description: '',
    display_yn: "",
    agree_yn: "",
    remark: "",
    visible: false,
    sign_seq: 0,
    file_cate: 'agree_sign',
    up_file_path: '',
    reg_manager_seq: 0
  }

  const [selectedContent, setSelectedContent] = useState(selectedContentInit)

  const [alertPop, setAlertPop] = useState({
		visible: false,
		alertMessage: '',
		alertType: 'alert',
		returnValue: () => { }
	});


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `동의서내역_${today}`,
  });

  const onChangeCategory = ((e) => {
    setSelectedCategory(e.target.value);
    setSelectedContent(selectedContentInit)
  });

  const onChangeContent = ((e) =>  {
    setSelectedContent((prev) => { return { ...prev, agree_seq: e.target.value } })
  });

  const onClickTablet = (() => {
    if(nvlNumber(selectedContent?.agree_seq)) setPopup(true);
    else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '동의서 양식을 선택해주세요.', alertType: 'alert' } })
  });

  const onClickDetail = ((e, name, description) => {
    // console.log("값 확인", e.target.id, name, description)

    setSelectedContent((prev) => { return { ...prev, agree_seq: e.target.id, agree_name: name, agree_description: description } })
    setPopup(true);
  });

  const getCategoryList = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) {
        setCategoryList(response.data.dataList);
        setSelectedCategory(nvl(response.data.dataList[0]?.common_code));
      } else console.log('Error >> ', response);
    }

    request.get("/api/etc/commoncd/2/007", null, successHandler);
  }

  useEffect(() => {
    getCategoryList();

    return () => { // unload 전 초기화
      setCategoryList([]);
      setSelectedCategory(nvl(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getContentList = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) {
        setContentList(response.data);
      } else console.log('Error >> ', response);
    }

    request.get(`/api/etc/agreements/C/${selectedCategory}`, null, successHandler);
  }

  useEffect(() => {
    if(nvl(selectedCategory) !== '') getContentList();

    return () => { // 바뀌기 전 초기화
      setContentList([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  const getUserContentList = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) setUserContentList(response.data);
      else console.log('Error >> ', response);
    }

    request.get(`/api/etc/agreements/${stateCustomer?.user_seq}/${stateCustomer?.main_tc_seq}/${stateCustomer?.sub_tc_seq}`, null, successHandler);
  }

  useEffect(() => {
    getUserContentList();

    return () => { // 바뀌기 전 초기화
      setUserContentList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomer?.user_seq, stateCustomer?.main_tc_seq, stateCustomer?.sub_tc_seq]);

  const closeCustomAlert = (() => {
		setAlertPop((prev) => { return { ...prev, visible: false } })
	});

  return (
    <>
      <section className="com_popup type_normal pop_consent_list active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container m">
            <div ref={el => componentRef.current = el}>
              <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
              <div className="pop_header">
                <h1 className="pop_title">동의서</h1>
              </div>
              <div ref={el => componentRef.current = el}>
                <div className="pop_body">
                  <article className="com_custom_info">
                    <p className="item">{stateCustomer?.user_name} {`(${convertGender(stateCustomer?.user_gender)}/${stateCustomer?.user_age})`}</p>
                  </article>
                  <ul className="com_input_section">
                    <li key='_category' className="input_field full">
                      <select className="select" name='category' key={ nvl(selectedCategory) } value={ nvl(selectedCategory) } onChange={ onChangeCategory }>
                        <option value="">카테고리 선택</option>
                        {
                          categoryList == null && categoryList.length < 1 ?
                            null
                          :
                            categoryList?.map((item) => (
                              item.code_level > 1 ?
                                <option key={ item.common_code } value={ item.common_code }>{ item.code_name }</option>
                              : null
                            ))
                        }
                      </select>
                    </li>
                    <li key='_content' className="input_field full">
                      <select className="select" name='content' key={ selectedContent.agree_seq } value={ selectedContent.agree_seq } onChange={ onChangeContent }>
                        <option value="">동의서 양식 선택</option>
                        {
                          contentList != null && contentList.length > 0 ?
                            contentList?.map((item) => (
                              <option key={ item.agree_seq } value={ item.agree_seq }>{ item.agree_name }</option>
                            )) : null
                        }
                      </select>
                    </li>
                    <li key='_tablet' className="input_field full">
                      <button className="com_btn blue m" onClick={ onClickTablet }>동의서 테블릿 보기</button>
                    </li>
                  </ul>
                  <article className="com_list">
                    <p className="list_tit">작성한 동의서 내역</p>
                    <div className="scroll">
                      <table className="list">
                        <colgroup><col width="100px" /><col /><col width="75px" /></colgroup>
                        <thead>
                          <tr>
                            <th>작성일</th>
                            <th>동의서 명</th>
                            <th>보기</th>
                          </tr>
                        </thead>
                        <tbody>
                          <UserContentList list={ userContentList } onClickDetail={ onClickDetail } />
                        </tbody>
                      </table>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
            </div>
          </div>
        </Draggable>
      </section>
      { popup && <ConsentDetail userData={ userData } selectedContent={ selectedContent } setSelectedContent={setSelectedContent} onClose={ () => { setPopup(false) } } /> }
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
    </>
  )
}

export default ConsentList