// *관리>문자관리>일반관리
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import request from "utils/Request.utils.js";
import SmsAuto from './_SmsAuto.js';//문자 자동발송 설정
import SmsCategoryManage from "components/common/_SmsCategoryManage.js";
import CustomAlert from "atoms/common/CustomAlert.js";
import AlertNoticeWriteOk from "components/notice/AlertWriteOk.js";
import { nvl } from "utils/Common.utils.js";
import moment from "moment";

const Sms = () => {
  const MemoSmsAuto = useMemo(() => React.memo(SmsAuto), [])
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  const imageRef = useRef();
  const imagePathRef = useRef();

  const [smsCategoryManageBool, setSmsCategoryManageBool] = useState(false);
  const [smsAutoManageBool, setSmsAutoManageBool] = useState(false)
  const [onUpdateBool, setOnUpdateBool] = useState(false)
  const [checkAutoSetting, setCheckAutoSetting] = useState("")

  const [smsCategoryList, setSmsCategoryList] = useState([])
  const [frequentMessageList, setFrequentMessageList] = useState([]);
  const [autoSendType, setAutoSendType] = useState("D")
  const [input, setInput] = useState({
    msg_cate: "",
    msg_subject: "",
    msg_content: ""
  }) // 임시 
  const [popUpInput, setPopUpInput] = useState({})

  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [uploadFilePath, setUploadFilePath] = useState("")

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const messageInfoInit = () => {
    console.log("되나")
    getFrequentMessageList()
    setPopUpInput("")
    setInput("")
    setAutoSendType("D")
    setSmsAutoManageBool(false)
    setCheckAutoSetting("")
    if (onUpdateBool) {
      setOnUpdateBool(!onUpdateBool)
    }
  }

  const [noticeWriteOkPopup, setNoticeWriteOkPopup] = useState({
    closeType: 'next',
    alertMessage: '등록 되었습니다.',
    visible: false
  });

  // 문자 카테고리 관리 팝업창 
  const smsCategoryManageToggle = () => {
    setSmsCategoryManageBool(!smsCategoryManageBool);
  }

  // 문자 자동발송 팝업창 
  const smsAutoManageToggle = () => {
    setSmsAutoManageBool(!smsAutoManageBool)
  }

  // 문자 카테고리 불러오기
  const getSmsCategory = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setSmsCategoryList(response.data.dataList)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }
    request.get("/api/etc/commoncd/3/003000000", null, successHandler, failHandler, null, null);
  }
  const returnCategory = (category) => {
    return smsCategoryList.filter(el => el.common_code === category)[0]?.code_name
  }

  // 자주 사용하는 문자 불러오기
  const getFrequentMessageList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setFrequentMessageList(response.data.resultData)
      } else {
        console.log("목록을 불러오지 못했습니다.")
        setFrequentMessageList([])
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.get("/api/message/frequent/use", null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    getSmsCategory()
    getFrequentMessageList()
  }, [])


  const changePopUpInput = (e) => {
    const { name, value } = e.target;
    setPopUpInput({ ...popUpInput, [name]: value })
  }
  useEffect(() => {
    if (!onUpdateBool) {
      setPopUpInput(autoSendType === "R" ? {
        reservation_day: "",
        reservation_before_1: "",
        reservation_before_2: "",
        reservation_before_3: ""
      } : {
        goods_cate: "",
        goods_seq: "",
        completion_after_1: "",
        completion_after_2: "",
        completion_after_3: ""
      })
    }

  }, [autoSendType, onUpdateBool])

  const changeInput = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setInput({ ...input, [name]: value })
  }

  const autoSetCheck = () => {
    if (autoSendType === "R" && Object.values(popUpInput).every(val => val !== "")) {
      setCheckAutoSetting("OK")
    } else if (autoSendType === "C" && popUpInput.completion_after_1 && popUpInput.completion_after_2 && popUpInput.completion_after_3) {
      setCheckAutoSetting("OK")
    } else {
      setAutoSendType("D")
      setCheckAutoSetting("")
    }
    setSmsAutoManageBool(!smsAutoManageBool)
  }

  // 
  const writeFrequentMessage = () => {
    if (nvl(input.msg_cate) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "카테고리를 선택해주세요." } })

      return;
    }
    if (nvl(input.msg_subject) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "제목을 입력해주세요." } })

      return;
    }
    if (nvl(input.msg_content) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "내용을 입력해주세요." } })

      return;
    }
    if (nvl(thumbnailImage) !== "") uploadImage();
    else submitFrequentMessage()
  }

  const updateFrequentMessage = () => {
    if (nvl(thumbnailImage) !== "") uploadImage();
    else submitFrequentMessage()
  }

  // 이미지 등록하기
  const selectImage = () => {
    imageRef.current.click()
  }
  const changeImageHandler = (e) => {
    let currentValue = e.target.value

    imagePathRef.current.value = currentValue
    setThumbnailImage(e.target.files[0])
  }

  const uploadImage = () => {
    const formData = new FormData();

    formData.append("uploadFile", thumbnailImage)
    formData.append("file_cate", "mmsimage")

    const successHandler = (response) => {
      imagePathRef.current.value = "";
      imageRef.current.value = "";
      setThumbnailImage(null);

      if (response.messageCode === 200) {
        submitFrequentMessage(response.data.up_file_path)
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response);
    }
    request.postMultipart("/api/upload/mmsimage", formData, successHandler, failHandler, null, null)
  }

  // 상세보기 클릭 시
  const onClickUpdateMessage = (msgSeq) => {
    setOnUpdateBool(true)

    const selectMessage = frequentMessageList.filter(el => el.msg_seq === msgSeq)[0]
    setInput({ reservation_msg_seq: selectMessage.reservation_msg_seq, completion_msg_seq: selectMessage.completion_msg_seq, msg_seq: selectMessage.msg_seq, msg_cate: selectMessage.msg_cate, msg_subject: selectMessage.msg_subject, msg_content: selectMessage.msg_content })
    setUploadFilePath(selectMessage.up_file_path)
    if (selectMessage.auto_send_type === "R") {
      setAutoSendType("R")
      setCheckAutoSetting("OK")
      setPopUpInput({ reservation_day: selectMessage.reservation.split("|")[0], reservation_before_1: selectMessage.reservation.split("|")[1], reservation_before_2: selectMessage.reservation.split("|")[2], reservation_before_3: selectMessage.reservation.split("|")[3] })

    } else if (selectMessage.auto_send_type === "C") {
      setAutoSendType("C")
      setCheckAutoSetting("OK")
      setPopUpInput({ goods_cate: selectMessage.completion.split("|")[0], goods_seq: selectMessage.completion.split("|")[1], completion_after_1: selectMessage.completion.split("|")[2], completion_after_2: selectMessage.completion.split("|")[3], completion_after_3: selectMessage.completion.split("|")[4] })
    }
  }
  const submitFrequentMessage = (path) => {
    if (!onUpdateBool) {
      let params = {
        msg_cate: input.msg_cate,
        msg_subject: input.msg_subject,
        msg_content: input.msg_content,
        up_file_path: path,
        auto_send_type: autoSendType,
        reg_manager_seq: managerInfo.seq,
        msg_auto: popUpInput
      }

      const successHandler = (response) => {
        console.log(response)
        if (response.messageCode === 200) {
          setNoticeWriteOkPopup((prev) => { return { ...prev, visible: true } })
        } else {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
        }
      }
      const failHandler = (response) => {
        console.log("에러" + response)
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
      }

      request.post("/api/message/frequent/use", params, successHandler, failHandler, null, null);
    } else {
      let params = {
        msg_seq: input.msg_seq,
        msg_cate: input.msg_cate,
        msg_subject: input.msg_subject,
        msg_content: input.msg_content,
        up_file_path: nvl(thumbnailImage) === "" ? uploadFilePath : path,
        auto_send_type: autoSendType,
        completion_msg_seq: input.completion_msg_seq,
        reservation_msg_seq: input.reservation_msg_seq,
        modi_manager_seq: managerInfo.seq,
        msg_auto: popUpInput
      }
      const successHandler = (response) => {
        console.log(response)
        if (response.messageCode === 200) {
          setNoticeWriteOkPopup((prev) => { return { ...prev, visible: true } })
        } else {
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
        }
      }
      const failHandler = (response) => {
        console.log("에러" + response)
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "입력하던 중 에러가 발생했습니다." } })
      }
      request.put(`/api/message/frequent/use/${params.msg_seq}`, params, successHandler, failHandler, null, null);
    }
  }
  const deleteFrequentMessage = (msgSeq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제되었습니다." } })

        messageInfoInit();

      } else {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제 중 에러가 발생했습니다." } })
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제 중 에러가 발생했습니다s." } })
    }

    request.del(`/api/message/frequent/use/${msgSeq}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
  }
  const returnAlertValue = (value) => {
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }
  const closeAlertNoticeWrite = () => {
    setNoticeWriteOkPopup((prev) => { return { ...prev, visible: false } })

    messageInfoInit();
  }


  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <div className="lnb2_menu_container">
              <Link to={"/boffice/sms"} className="com_btn m blue">일반관리</Link>
              <Link to={"/boffice/sms_stats"} className="com_btn m lightgray">문자통계</Link>
            </div>
          </article>
        </div>
      </section>
      <section className="com_content p_sms">
        <div className="com_write box">
          <article className="left_wrap">
            <ul className="com_input_section column">
              <li className="tit_field">카테고리 구분 1</li>
              <li className="input_field input_wrap">
                <select className="select" name="msg_cate" key={input.msg_cate} value={input.msg_cate} onChange={(e) => changeInput(e)}>
                  <option value="">카테고리 선택</option>
                  {smsCategoryList !== null && smsCategoryList?.map((category) => (
                    <option key={category.common_code} value={category.common_code}>{category.code_name}</option>
                  ))}
                </select>
                <button className="com_btn m blue" onClick={() => smsCategoryManageToggle()}><i className="svg_icon icon_set yellow">&nbsp;</i>카테고리 관리</button>
              </li>
              <li className="tit_field">이미지 추가</li>
              <li className="input_field input_wrap">
                <input type="text" className="input" ref={imagePathRef} />
                <input type="file" accept="image/jpg" style={{ display: "none" }} ref={imageRef} onChange={changeImageHandler} />
                <button className="com_btn blue m" onClick={() => selectImage()}>이미지찾기</button>
                <input type="checkbox" className="btn_chk" id="sms_auto" name="auto_send_type" checked={(autoSendType === "D" && smsAutoManageBool) || (checkAutoSetting === "OK") ? true : false} onChange={() => smsAutoManageToggle()} /><label className="com_btn chk blue m" htmlFor="sms_auto" >문자 자동발송</label>
              </li>
              <li className="tit_field">제목입력</li>
              <li className="input_field">
                <textarea placeholder="제목입력 (50자)" className="textarea" name="msg_subject" value={input.msg_subject || ""} onChange={(e) => changeInput(e)}></textarea>
              </li>
            </ul>
          </article>
          <hr className="line" />
          <article className="right_wrap">
            <table className="input_contents">
              <colgroup><col /><col width="90px" /></colgroup>
              <tbody>
                <tr>
                  <td>
                    <textarea className="textarea" name="msg_content" placeholder={`[병원명]\n[성명] 님 안녕하세요.\n리프팅의 모든 것 올리프트 입니다.\n\n* 줄을 변경하면, 문자에 그대로 적용됩니다`} value={input.msg_content || ""}
                      onChange={(e) => changeInput(e)}>
                    </textarea>
                  </td>
                  {/* 2023-03-21 상세보기(수정 On) 상태에서 등록 -> 저장, 수정 버튼 추가되는 부분 css 조정 필요 - inline으로 임시처리 */}
                  {onUpdateBool === true ?
                    <td className="btn_field two">
                      <button className="com_btn darkgray m" onClick={() => deleteFrequentMessage(input.msg_seq)}>삭제</button>
                      <button className="com_btn blue m" style={{ height: "49%" }} onClick={() => updateFrequentMessage()}>저장</button>
                    </td>
                    :
                    <td className="btn_field">
                      <button className="com_btn blue m" onClick={() => writeFrequentMessage()}>등록</button>
                    </td>}
                </tr>
              </tbody>
            </table>
          </article>
        </div>
        <div className="com_list box">
          <div className="scroll">
            <table className="list">
              <colgroup><col width="9.1%" /><col width="11.4%" /><col width="7.9%" /><col width="17%" /><col /><col width="7.9%" /><col width="10.2%" /><col width="5.7%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>등록일</th>
                  <th>카테고리</th>
                  <th>등록자</th>
                  <th>문자제목</th>
                  <th>문자내용</th>
                  <th>첨부 이미지</th>
                  <th>자동발송</th>
                  <th>보기</th>
                </tr>
              </thead>
              <tbody>
                {frequentMessageList === null || frequentMessageList.length < 1 ?
                  <tr>
                    <td colSpan="8">데이터가 없습니다.</td>
                  </tr>
                  :
                  frequentMessageList?.map((message) => (
                    <tr key={message.msg_seq}>
                      <td>{moment(message.reg_date).format("YYYY-MM-DD")}</td>
                      <td>{returnCategory(message.msg_cate)}</td>
                      <td>{message.writer}</td>
                      <td>{message.msg_subject}</td>
                      <td className="tit">
                        {message.msg_content?.match(/\[.*?\]|[^[\]]+/g).map((item, index) => {
                          if (item.startsWith('[') && item.endsWith(']')) {
                            return <span className="txt_red" key={index}>{item}</span>;
                          } else {
                            return item;
                          }
                        })}
                      </td>
                      <td>
                        {
                          nvl(message.up_file_path) !== "" ?
                            <img src={`${process.env.REACT_APP_API_URL}/api/upload/viewer/image/mmsimage/${message.up_file_path.split("/")[message.up_file_path.split("/").length - 2]}_${message.up_file_path.split("/")[message.up_file_path.split("/").length - 1]}`}
                              alt="Message_Image" style={{ height: "67.250px" }} /> : null
                        }
                      </td>
                      <td>{message.auto_send_type === "D" ? "N" : message.auto_send_type === "R" ? `당일 \b\b\b\b\b${message.reservation?.split("|")[0]}시 발송 \n 예약 ${message.reservation.split("|")[1]}시 전 발송 \n 예약 ${message.reservation.split("|")[0]}시 전 발송 \n 예약 ${message.reservation.split("|")[0]}시 전 발송 ` : `완료 ${message.completion.split("|")[2]}분 후\n완료 ${message.completion.split("|")[3]}일 후\n완료 ${message.completion.split("|")[4]}개월 후\n`}</td>
                      <td>
                        <button className="com_btn txt" onClick={() => onClickUpdateMessage(message.msg_seq)}>상세 보기</button>
                      </td>
                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </section>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage}
        returnValue={returnAlertValue} /> : null}
      <SmsAuto smsAutoManageBool={smsAutoManageBool} smsAutoManageToggle={smsAutoManageToggle} popUpInput={popUpInput} setPopUpInput={setPopUpInput} changePopUpInput={changePopUpInput} autoSendType={autoSendType} setAutoSendType={setAutoSendType} autoSetCheck={autoSetCheck} onUpdateBool={onUpdateBool} />
      <SmsCategoryManage smsCategoryManageBool={smsCategoryManageBool} smsCategoryManageToggle={smsCategoryManageToggle} getSmsCategory={getSmsCategory} smsCategoryList={smsCategoryList} />
      <AlertNoticeWriteOk onClose={closeAlertNoticeWrite} popupVisible={noticeWriteOkPopup.visible} />
    </>
  )
}

export default Sms