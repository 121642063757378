import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import "./styles/scss/styles.scss";

import AdminRoute from "./routes/AdminRoute.js";
import ChartRoute from "./routes/ChartRoute.js";
import CrmRoute from "./routes/CrmRoute.js";
import DashboardRoute from "./routes/DashboardRoute.js";
import MainRoute from "./routes/MainRoute.js";
import MemberRoute from "./routes/MemberRoute.js";
import NoticeRoute from "./routes/NoticeRoute.js";
import PaymentRoute from "./routes/PaymentRoute.js";
import ReservationRoute from "./routes/ReservationRoute.js";
import SalesRoute from "./routes/SalesRoute.js";
import StatisticsRoute from "./routes/StatisticsRoute.js";
import EtcRoute from "./routes/EtcRoute.js";
import EditorTest from "./components/etc/editorTest"
function App() {
	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<Navigate to='/main' />} />
					<Route path="/test" element={<EditorTest />} />
					<Route path="/login" element={<Navigate to='/main/login' />} />
					<Route path="/boffice/*" element={<AdminRoute />} />
					<Route path="/Chart/*" element={<ChartRoute />} />
					<Route path="/Crm/*" element={<CrmRoute />} />
					<Route path="/Dashboard/*" element={<DashboardRoute />} />
					<Route path="/Main/*" element={<MainRoute />} />
					<Route path="/Member/*" element={<MemberRoute />} />
					<Route path="/Notice/*" element={<NoticeRoute />} />
					<Route path="/Payment/*" element={<PaymentRoute />} />
					<Route path="/Reservation/*" element={<ReservationRoute />} />
					<Route path="/Sales/*" element={<SalesRoute />} />
					<Route path="/Statistics/*" element={<StatisticsRoute />} />
					<Route path="/Etc/*" element={<EtcRoute />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
