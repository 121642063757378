// *직원관리
import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import PositionCategoryManage from "./_PositionCategoryManage.js"; // 직무 카테고리 관리
import MemberAddOk from "./_MemberAddOk.js"; // 관리자가 등록되었습니다. alert
import LeaveCheck from "./_LeaveCheck.js"; // 탈퇴하시겠습니까? alert

import CustomerSubDateSearch from "../../atoms/crm/SubDateSearch";
import PopupCalendar from '../../atoms/common/PopupCalendar';
import request from '../../utils/Request.utils';
import { nvl, nvlNumber, validateEmail, convertJuminToAge, birthdayToAge, convertGender, changeMobileFormat, checkOnlyNumber } from "../../utils/Common.utils";
import { decrypt } from "../../utils/aes256.manager";
import CustomAlert from '../../atoms/common/CustomAlert';
import LoadingBar from '../../utils/LoadingBar';
import moment from 'moment';


const Member = ({ loadingBarActive }) => {
  moment.locale('ko-KR');

  const [dobCalBool, setDobCalBool] = useState(false);
  const [positionCategoryManageBool, setPositionCategoryManageBool] = useState(false);
  const [dateType, setDateType] = useState('hyphen');
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [uploadFile, setUploadFile] = useState(null);
  const [idDuplicationBool, setIdDuplicationBool] = useState(false); // 아이디 중복 체크
  const [withdrawalBool, setWithdrawalBool] = useState(false); // 탈퇴 여부
  const [newManagerInfo, setNewManagerInfo] = useState({});
  const [activateMode, setActivateMode] = useState('');
  const [periodOption, setPeriodOption] = useState(null);
  const [modifyModeActivate, setModifyModeActivate] = useState(true);
  const newManagerInfoInit = () => {
    setNewManagerInfo({
      activateMode: "I", // Insert, Update
      updateType: "U",
      manager_seq: 0,
      manager_dob: "",
      manager_gender: "",
      manager_name: "",
      manager_id: "",
      manager_passwd: "",
      manager_mobile: "",
      manager_email: "",
      manager_position: "",
      assignment_position: "",
      medical_license: "",
      up_file_seq: 0,
      bp_seq: managerInfo.company,
      reg_manager_seq: managerInfo.seq,
      modi_manager_seq: managerInfo.seq
    })

    managerPasswdRef.current.value = ""
  }

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    closeType: ''
  });

  const [positionList, setPositionList] = useState([]);
  const [assignmentList, setAssignmentList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const managerIdRef = useRef();
  const managerPasswdRef = useRef();

  const uploadFileRef = useRef();
  const uploadFilePathRef = useRef();

  const managerInfoMobileRef = useRef();

  const closePopup = () => {
    setDobCalBool(false);
  }

  const getCalendar = (value) => {
    // console.log("get calendarValue : " + value + " | " + dateType)

    newManagerInfoUpdate("manager_dob", value)
  }

  // 직급 리스트 가져오기
  const getPositionList = () => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) { // ok 코드 값
        setPositionList(response.data.dataList);

        getAssignmentList();
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    request.get("/api/etc/commoncd/2/009", null, successHandler);
  }

  // 직무 리스트 가져오기
  const getAssignmentList = () => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) setAssignmentList(response.data.dataList);
      else console.log("코드 값을 찾지 못했습니다.");
    }

    request.get("/api/etc/commoncd/2/010", null, successHandler);
  }

  const getManagerList = () => {
    let params = {
      orderBy: "manager_seq DESC",
      delete_yn: "N"
      // limit: 10,
      // offset: 0
    }

    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) setManagerList(response.data.dataList);
      else console.log("직원 리스트를 찾지 못했습니다.");
    }

    if (withdrawalBool) {
      request.get(`/api/manager/fullinfo/list?periodOption=${periodOption}&orderBy=${params.orderBy}&startDate=${startDate}&endDate=${endDate}&bp_seq=${managerInfo.company}&manager_status=W&delete_yn=N`,
        null, successHandler);
    } else {
      request.get(`/api/manager/fullinfo/list?periodOption=${periodOption}&orderBy=${params.orderBy}&startDate=${startDate}&endDate=${endDate}&bp_seq=${managerInfo.company}&delete_yn=N`,
        null, successHandler);
    }
  }

  useEffect(() => {
    newManagerInfoInit();
    getPositionList();
    getManagerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawalBool, periodOption, startDate]);

  const positionCategoryManageClose = () => {
    setPositionCategoryManageBool(false) // 일단 닫고

    getPositionList();
  }

  const getManagerInfo = (seq) => {
    request.get("/api/manager/" + seq, null, (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        setNewManagerInfo((prev) => { return { ...prev, activateMode: "U", manager_seq: seq, manager_dob: response.data[0].manager_dob } })
        setNewManagerInfo((prev) => { return { ...prev, manager_name: response.data[0].manager_name, manager_id: response.data[0].manager_id } })
        setNewManagerInfo((prev) => { return { ...prev, manager_gender: response.data[0].manager_gender, manager_passwd: "", manager_mobile: response.data[0].manager_mobile } })
        setNewManagerInfo((prev) => { return { ...prev, manager_email: response.data[0].manager_email, manager_position: response.data[0].manager_position } })
        setNewManagerInfo((prev) => { return { ...prev, assignment_position: response.data[0].assignment_position, medical_license: response.data[0].medical_license } })

      } else console.log("정보를 찾지 못했습니다.");
    });
  }

  const idDuplicationCheck = (managerId) => { // 아이디 중복 체크
    if(managerId){
      const successHandler = (response) => {
      // console.log("response ",response)
  
        if (response.data === 0) setIdDuplicationBool(false)
        else setIdDuplicationBool(true)
      }
  
      request.get(`/api/manager/id/duplication/${managerId}`, null, successHandler);
    }
  }

  const newManagerInfoUpdate = (key, value) => {
    // console.log(key + " | " + value)

    if (key === "manager_id") idDuplicationCheck(value);
    else if (key === "manager_mobile") {
      if (!checkOnlyNumber(value)) {
        // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "숫자만 입력이 가능합니다." } })

        value = "";

        managerInfoMobileRef.current.value = "";
      }
    }else if (key === "assignment_position") {
      if(value === "010003000") {
        setModifyModeActivate(false);
      } else{
        setModifyModeActivate(true);
      }
    }

    setNewManagerInfo((prev) => { return { ...prev, [key]: value } })
  }

  const managerInfoUpdateActivate = () => { // 등록할 관리자 정보 VALIDATION
    if (nvl(newManagerInfo.manager_dob) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "생년월일을 선택해주세요." } })

      return;
    } else if (nvl(newManagerInfo.manager_gender) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "성별을 선택해주세요." } })

      return;
    } else if (nvl(newManagerInfo.manager_name) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "이름을 입력해주세요." } })

      return;
    } else if (nvl(newManagerInfo.manager_id) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "아이디를 입력해주세요." } })

      return;
    } else if (idDuplicationBool) {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "사용할 수 없는 아이디입니다.<br />다시 입력해주세요." } })

      return;
    } else if (newManagerInfo.activateMode === "I" && nvl(newManagerInfo.manager_passwd) === "") { // 업뎃 일 때는 패스
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "패스워드를 입력해주세요." } })

      return;
    } else if (nvl(newManagerInfo.manager_mobile) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "휴대폰 번호를 입력해주세요." } })

      return;
    }

    newManagerInfo.manager_mobile = changeMobileFormat(newManagerInfo.manager_mobile.replace(/-/g, ''));
    managerInfoMobileRef.current.value = newManagerInfo.manager_mobile; // 다시 하이픈 넣은 상태로

    if (nvl(newManagerInfo.manager_email) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "이메일 주소를 입력해주세요." } })

      return;
    } else {
      if (!validateEmail(newManagerInfo.manager_email)) {
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: '', visible: true, alertMessage: "잘못된 이메일 형식입니다." } })

        return;
      }
    }

    if (nvl(newManagerInfo.manager_position) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "직급을 선택해주세요." } })

      return;
    } else if (nvl(newManagerInfo.assignment_position) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "직무를 선택해주세요." } })

      return;
    } 
    
    // else if (nvl(newManagerInfo.medical_license) === "") {
    //   setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: true, alertMessage: "의사 면허번호를 입력해주세요." } })

    //   return;
    // }

    if (nvl(uploadFile) !== "") submitUploadFile();
    else managerInfoUpdateSubmit(null);
  }

  const managerInfoUpdateSubmit = (fileSeq) => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        if (newManagerInfo.activateMode === "I") setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "등록되었습니다." } })
        else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "수정되었습니다." } })

        newManagerInfoInit();
        getManagerList();
      } else {
        if (newManagerInfo.activateMode === "I") setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "등록하던 중 에러가 발생했습니다." } })
        else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "수정하던 중 에러가 발생했습니다." } })
      }
    }

    if (nvlNumber(fileSeq) > 0) {
      // console.log(fileSeq)

      newManagerInfo.up_file_seq = fileSeq
    }

    if (newManagerInfo.activateMode === "I") request.post("/api/manager", newManagerInfo, successHandler);
    else request.put("/api/manager", newManagerInfo, successHandler);
  }

  const uploadFileInit = () => {
    uploadFilePathRef.current.value = "";
    uploadFileRef.current.value = "";

    setUploadFile(null);
  }

  const uploadFileHandler = (e) => {
    let currentId = e.target.getAttribute("id");
    let currentName = e.target.name;
    let currentValue = e.target.value;

    // console.log(currentId + " | " + currentName + " | " + currentValue);

    uploadFilePathRef.current.value = currentValue;

    setUploadFile(e.target.files[0]);
  }

  // 첨부파일 등록
  const selectUploadFile = () => {
    uploadFileRef.current.click();
  }
  
  const submitUploadFile = () => {
    const formData = new FormData();

    formData.append("uploadFile", uploadFile)
    formData.append("file_cate", "managerStamp")
    formData.append("manager_seq", newManagerInfo.manager_seq)

    const successHandler = (response) => {
      console.log(response);

      if (response.messageCode === 200) managerInfoUpdateSubmit(response.data); // 첨부 파일이 있을 때는 첨부 파일을 먼저 입력하고 직원 정보 입력
      else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 형식의 파일이 있습니다." } })

      uploadFileInit();
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "파일 첨부중 에러가 발생했습니다." } })

      uploadFileInit();
    }

    request.postMultipart("/api/upload/user", formData, successHandler, failHandler);
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", closeType: "", visible: false, alertMessage: "" } })
  }

  const managerUpdateActivate = (managerSeq) => {
    console.log("직원정보 수정 세팅 : " + managerSeq)

    getManagerInfo(managerSeq)
  }

  const managerDeleteActivate = (managerSeq) => {
    console.log("직원정보 탈퇴 가동 : " + managerSeq)

    newManagerInfo.manager_seq = managerSeq;
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "선택된 직원을 탈퇴처리 하시겠습니까?" } })
    setActivateMode('withdrawalManager'); // 다음 실행할 함수를 호출하기 위해서
  }

  const managerRealDeleteActivate = (managerSeq) => {

    newManagerInfo.manager_seq = managerSeq;
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "삭제 하시겠습니까?<br/>삭제된 정보는 복구할 수 없습니다." } })
    setActivateMode('deletewithdrawalManager'); // 다음 실행할 함수를 호출하기 위해서
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      if (activateMode === 'withdrawalManager') {
        const successHandler = (response) => {
          console.log(response)

          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "탈퇴처리가 완료되었습니다." } })

            newManagerInfoInit()
            getManagerList()
          } else if (response.messageCode === 403) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "권한이 없습니다. 다시 시도해주십시오." } })
            request.tokenVerify()
          } else console.log("에라야 에라")
        }

        request.del(`/api/manager/W/${newManagerInfo.manager_seq}/${newManagerInfo.modi_manager_seq}`, null, successHandler);
      } if (activateMode === 'deletewithdrawalManager') {
        const successHandler = (response) => {
          console.log(response)

          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "삭제처리가 완료되었습니다." } })

            newManagerInfoInit()
            getManagerList()
          } else if (response.messageCode === 403) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "권한이 없습니다. 다시 시도해주십시오." } })
            request.tokenVerify()
          } else console.log("에라야 에라")
        }

        request.del(`/api/manager/D/${newManagerInfo.manager_seq}/${newManagerInfo.modi_manager_seq}`, null, successHandler);
      }
    }
  }

  const setPeriodSearchDate = (startDate, endDate) => {
    // console.log(startDate + " | " + endDate)

    setStartDate(startDate)
    setEndDate(endDate)
    setPeriodOption(null)
  }

  const subSearchAction = (getValue) => {
    // console.log(getValue);

    setPeriodOption(getValue);
    setStartDate(null)
    setEndDate(endDate)
  }
  // console.log(modifyModeActivate)

  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <div className="lnb2_menu_container">
            <a href="#/" onClick={() => setWithdrawalBool(false)} className={withdrawalBool ? "com_btn m lightgray" : "com_btn m blue"}>직원관리</a>
            <a href="#/" onClick={() => setWithdrawalBool(true)} className={withdrawalBool ? "com_btn m blue" : "com_btn m lightgray"}>탈퇴 직원관리</a>
          </div>
          <article className="com_lnb2_right">
            <div className="lnb2_search_wrap">
              <CustomerSubDateSearch searchHandler={subSearchAction} setPeriodSearchDate={setPeriodSearchDate} periodOption={periodOption} />
            </div>
          </article>
        </div>
      </section>
      <section className="com_content p_member">
        <div className="com_write box">
          <table className="com_input_section">
            <colgroup><col width="130px" /><col width="17%" /><col width="14%" /><col width="14%" /><col width="14%" /><col /><col width="90px" /></colgroup>
            <tbody>
              <tr>
                <td className="tit">관리자<br />{newManagerInfo.activateMode === 'I' ? '등록' : '수정'}</td>
                <td className="input_field space">
                  <div className="input_wrap">
                    <input type="text" className="input datepicker" placeholder="생년월일" id="manager_dob" name="manager_dob" readOnly
                      value={newManagerInfo != null ? nvl(newManagerInfo.manager_dob) : ""} onClick={() => { setDobCalBool(true) }} />
                    <fieldset className="com_radio_wrap">
                      <input type="radio" className="radio" id="managerGenderM" name="managerGender" value="M" checked={nvl(newManagerInfo.manager_gender) === 'M'}
                        onChange={(e) => newManagerInfoUpdate("manager_gender", e.target.value)} />
                      <label className="btn_radio" htmlFor="managerGenderM">&nbsp;</label>
                      <label htmlFor="managerGenderM">남</label>
                      <input type="radio" className="radio" id="managerGenderF" name="managerGender" value="F" checked={nvl(newManagerInfo.manager_gender) === 'F'}
                        onChange={(e) => newManagerInfoUpdate("manager_gender", e.target.value)} />
                      <label className="btn_radio" htmlFor="managerGenderF">&nbsp;</label>
                      <label htmlFor="managerGenderF">여</label>
                    </fieldset>
                  </div>
                  {dobCalBool && <PopupCalendar onClose={closePopup} getCalendar={getCalendar} key={uuidv4()} dateType={dateType} />}
                </td>
                <td className="input_field">
                  <input type="text" placeholder="이름" className="input" value={newManagerInfo != null ? nvl(newManagerInfo.manager_name) : ""}
                    onChange={(e) => newManagerInfoUpdate("manager_name", e.target.value)} />
                  {idDuplicationBool && <span className="txt_red">이미 사용중인 아이디 입니다.</span>}
                </td>
                <td className="input_field">
                  <input type="text" placeholder="아이디" className="input" ref={managerIdRef} value={newManagerInfo != null ? nvl(newManagerInfo.manager_id) : ""}
                    onChange={(e) => newManagerInfoUpdate("manager_id", e.target.value)} />
                </td>
                <td className="input_field">
                  <input type="password" placeholder="패스워드" className="input" ref={managerPasswdRef} onChange={(e) => newManagerInfoUpdate("manager_passwd", e.target.value)} />
                </td>
                <td className="input_field">
                  <input type="text" ref={managerInfoMobileRef} className="input" placeholder="휴대폰번호 ‘– ‘ 없이 입력" value={newManagerInfo != null ? nvl(newManagerInfo.manager_mobile) : ""}
                    onChange={(e) => newManagerInfoUpdate("manager_mobile", e.target.value)} maxLength={11} />
                </td>
                <td rowSpan="2" className="btn_field_right">
                  <button className="com_btn blue m" onClick={managerInfoUpdateActivate}>{newManagerInfo.activateMode === 'I' ? '등록' : '수정'}</button>
                </td>
              </tr>
              <tr>
                <td className="input_field">
                  <button className="com_btn blue m button" onClick={(e) => { setPositionCategoryManageBool(!positionCategoryManageBool) }}><i className="svg_icon icon_set yellow">&nbsp;</i>직무 관리</button>
                </td>
                <td className="input_field line space">
                  <input type="text" className="input" placeholder="이메일 입력" value={newManagerInfo != null ? nvl(newManagerInfo.manager_email) : ""}
                    onChange={(e) => newManagerInfoUpdate("manager_email", e.target.value)} />
                </td>
                <td className="input_field line">
                  <select className="select" onChange={(e) => { newManagerInfoUpdate("manager_position", e.target.value) }}
                    key={nvl(newManagerInfo.manager_position)} value={nvl(newManagerInfo.manager_position)}>
                    <option value="">직급 선택</option>
                    {
                      (positionList == null || positionList.length < 1) ? null
                        :
                        positionList.map((item, index) => (
                          item.code_level > 1 ?
                            parseInt(managerInfo.role) <= parseInt(item.common_code) && <option key={item.common_code} value={item.common_code}>{item.code_name}</option>
                            : null
                        )
                      )
                    }
                  </select>
                </td>
                <td className="input_field line">
                  <select className="select" onChange={(e) => { newManagerInfoUpdate("assignment_position", e.target.value) }}
                    key={nvl(newManagerInfo.assignment_position)} value={nvl(newManagerInfo.assignment_position)}>
                    <option value="">직무 선택</option>
                    {
                      (assignmentList == null || assignmentList.length < 1) ? null
                        :
                        assignmentList.map((item, index) => (
                          item.code_level > 1 ?
                            <option key={item.common_code} value={item.common_code}>{item.code_name}</option>
                            : null
                        )
                      )
                    }
                  </select>
                </td>
                <td className="input_field line">
                  <input type="text" className="input" placeholder="의사 면허번호 입력" value={newManagerInfo != null ? nvl(newManagerInfo.medical_license) : ""}
                    onChange={(e) => newManagerInfoUpdate("medical_license", e.target.value)} disabled={ modifyModeActivate }/>
                </td>
                <td className="input_field line">
                  <div className="input_wrap">
                    <input type="text" ref={uploadFilePathRef} placeholder="도장이미지 등록 (jpg, jpeg, png, gif)" className="input" disabled={ modifyModeActivate } />
                    <input type="file" id="uploadFile" name="files" className="input" ref={uploadFileRef} style={{ display: "none" }} onChange={uploadFileHandler} />
                    <button className="com_btn blue m button" onClick={selectUploadFile}>이미지 찾기</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="com_list box">
          <div className="scroll">
            <table className="list">
               <colgroup><col width="8.4%" /><col width="8.4%" /><col width="10.1%" /><col width="11.1%" /><col /><col width="8.4%" /><col width="11.6%" /><col width="12.5%" /><col width="9.4%" />{!withdrawalBool && <col width="5%" />}<col width="5%" /></colgroup>
              <thead className="sticky top">
                <tr>
                  <th>등록일</th>
                  <th>이름</th>
                  <th>아이디</th>
                  <th>패스워드</th>
                  <th>이메일</th>
                  <th>관리자 직급</th>
                  <th>의사면허번호</th>
                  <th>도장이미지</th>
                  <th>휴대폰번호</th>
                  {!withdrawalBool && <th>수정</th>}
                  <th>탈퇴</th>
                </tr>
              </thead>
              <tbody>

                {
                  (managerList == null || managerList.length < 1) ?
                    <tr>
                      <td colSpan={!withdrawalBool ? 11 : 10}>데이터가 없습니다.</td>
                    </tr>
                    :
                    managerList.map((item, index) => (
                      <tr key={item.manager_seq}>
                        <td>{moment(item.reg_date).format("YYYY, MM, DD")}</td>
                        <td>
                          {item.manager_name}&nbsp;
                          ({convertGender(item.manager_gender)} / {nvl(item.manager_dob) !== "" ? birthdayToAge(item.manager_dob) :
                            convertJuminToAge((decrypt(item.manager_jumin).substring(0, 6) + "-" + decrypt(item.manager_jumin).substring(6, 13)))})
                        </td>
                        <td>{item.manager_id}</td>
                        <td>******{ /* 패스워드는 알 수가 없습니다. */}</td>
                        <td>{item.manager_email}</td>
                        <td>{nvl(item.manager_position_name) === "" ? "미설정" : item.manager_position_name}</td>
                        <td>{nvl(item.medical_license) === "" ? "-" : item.medical_license}</td>
                        <td>
                          {
                            nvl(item.stamp_image) !== "" ?
                              <img src={`${process.env.REACT_APP_API_URL}/api/upload/viewer/image/stamp/${item.stamp_image.split("/")[item.stamp_image.split("/").length - 2]}_${item.stamp_image.split("/")[item.stamp_image.split("/").length - 1]}`}
                                alt="Manager Stamp" className="stamp" /> : null
                          }
                        </td>
                        <td>{item.manager_mobile}</td>
                        <td>
                          {!withdrawalBool ? <button className="com_btn txt" onClick={() => managerUpdateActivate(item.manager_seq)}>수정</button>
                          :
                          <button className="com_btn txt red" onClick={() => managerRealDeleteActivate(item.manager_seq)}>삭제</button>}
                        </td>
                        {!withdrawalBool && 
                          <td>
                            <button className="com_btn txt red" onClick={() => managerDeleteActivate(item.manager_seq)}>탈퇴</button>
                          </td>
                        }
                      </tr>
                    ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {positionCategoryManageBool && <PositionCategoryManage positionCategoryManageBool={positionCategoryManageBool} onClose={positionCategoryManageClose} />}
      {
        alertLayerPopup.visible ?
          <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />
          : null
      }
      <MemberAddOk />
      <LeaveCheck />
      {loadingBarActive && <LoadingBar type={"spin"} color={"#000000"} />}
    </>
  )
}

export default Member