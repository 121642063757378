import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import request from "utils/Request.utils";
import { nvl, nvlNumber, vatCalculator, nvlOnlyNumber } from "utils/Common.utils.js";
import VisitHistory from "./_VisitHistory"; // 내원기록
import PointManage from "components/common/_PointManage";
import moment from 'moment';
import CustomAlert from "atoms/common/CustomAlert.js";

const Treatment = ({ treatmentList, setTreatmentList }) => {
  const onChangeCheck = (e) => {
    let tempList = []

    treatmentList.forEach((item, itemIndex) => {
      if (item.p_goods_type === 'P' && `pack_${item.pack_seq}` === e.target.name) { // 패키지 상품
        tempList[itemIndex] = { ...treatmentList[itemIndex], checked: e.target.checked } // 일단 checked 상태 변경
      } else if (item.p_goods_type === 'D' && `${item.pack_seq}_${item.goods_seq}` === e.target.value) { // 일반 상품
        tempList[itemIndex] = { ...treatmentList[itemIndex], checked: e.target.checked } // 일단 checked 상태 변경
      } else tempList[itemIndex] = { ...treatmentList[itemIndex] }
    });

    setTreatmentList(tempList)
  }

  const viewVatPrice = (event, item) => {
    // console.log("class확인", event.target.parentElement.children[1].className, event.target.parentElement.children[1].value)

    let currentClass = event.target.parentElement.children[1].className
    let originalPrice = nvlNumber(event.target.parentElement.children[1].value);

    if (currentClass === "com_btn icon m lightblue") {
      event.target.parentElement.children[0].textContent = originalPrice - (originalPrice * 0.1);
      event.target.className = "com_btn icon m blue";
    } else {
      event.target.parentElement.children[0].textContent = originalPrice;
      item.vat_type === "I" ? event.target.className = "com_btn icon m lightblue" : event.target.className = "com_btn icon m lightblue";
    }
  }

  return (
    treatmentList != null && treatmentList?.length > 0 ?
      treatmentList?.map((item, index) => {
        return (
          <tr key={`${item.pack_seq}_${item.goods_seq}`}>
            <td>
              <fieldset className="com_check_wrap">
                <input type="checkbox" className="checkbox" id={`tr-chk-${index}`} name={`pack_${item.pack_seq}`} checked={nvl(item?.checked) === '' ? false : item.checked}
                  onChange={(e) => onChangeCheck(e)} value={`${item.pack_seq}_${item.goods_seq}`} />
                <label className="btn_checkbox" htmlFor={`tr-chk-${index}`}>&nbsp;</label>
              </fieldset>
            </td>
            <td key={`${item.pack_seq}_${item.goods_seq}_name`} className="tit">{item.p_goods_type === 'P' ? `${item.p_goods_name} > ${item.goods_name}` : item.goods_name}</td>
            <td key={`${item.pack_seq}_${item.goods_seq}_insurance`}>{item.insurance_type === "N" ? "비급여" : "급여"}</td>
            <td key={`${item.pack_seq}_${item.goods_seq}_times`}>{item.treatment_times}</td>
            <td key={`${item.pack_seq}_${item.goods_seq}_price`} className="num">
              <span> {vatCalculator(item.p_goods_type, item.p_vat_type, item.pack_sale_price, item.goods_sale_price, item.vat_type, item.goods_price)} </span>
              <button id={index} value={vatCalculator(item.p_goods_type, item.p_vat_type, item.pack_sale_price, item.goods_sale_price, item.vat_type, item.goods_price)}
                className={"com_btn icon m lightblue"} onClick={(e) => viewVatPrice(e, item)}>t</button>
            </td>
          </tr>
        )
      }
      ) : null
  )
}

const ChartCenter = ({ displayScreen, setAddedItems, userData, pointStatus, treatmentList, setTreatmentList, orderInfo }) => {
  const stateCustomer = useSelector(state => state.Customer);
  const [visible, setVisible] = useState({ visited: false });
  const [categoryList, setCategoryList] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchText, setSearchText] = useState('');
  const [couponList, setCouponList] = useState([]);
  const [point, setPoint] = useState(0);
  const [usePoint, setUsePoint] = useState('');
  const [visitList, setVisitList] = useState([]);
  const [popup, setPopup] = useState({ point: false, visit: false });
  const [selectedItems, setSelectedItems] = useState({ treatments: [], coupon: {}, point: 0, total_price: 0 }); // ChartCenter 시술 검색에서 선택한 시술 및 쿠폰, 입력한 포인트
  const [personalRecord, setPersonalRecord] = useState([]);
  const [diseaseCode, setDiseaseCode] = useState([]);

  const [alertPop, setAlertPop] = useState({
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => { },
    id: '',
  });

  const onToggle = (e) => {
    if (nvlNumber(stateCustomer.user_seq) > 0) setVisible((prev) => { return { ...prev, [e.target.id]: !prev[e.target.id] } })
    else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
  }

  const closeCustomAlert = (() => {
    setAlertPop((prev) => { return { ...prev, visible: false } })
  });

  const onChangeCategory = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedItems((prev) => { return { ...prev, treatments: [], point: 0, total_price: 0 } });
  }

  // 시술 전체 리스트 가져오기
  const searchTreatment = () => {
    let params = {
      searchData: searchText,
      category: selectedCategory,
      delete_yn: 'N',
      display_yn: 'Y',
      orderBy: "PG.goods_seq DESC, CG.goods_seq ASC",
      user_seq: userData.user_seq
    }

    const successHandler = (response) => {
      // console.log(response)

      if (response.messageCode === 200) {
        setTreatmentList(response.data.dataList)
        setTotalCount(response.data.totalCnt)
      } else {
        console.log("시술 목록을 가져오지 못했습니다.")
      }
    }

    // 여기서 초기화 하는 이유는 당일 오더에 추가하지 않은 상태에서 다시 검색을 하면 기존 데이터를 날리기 위해서
    setSelectedItems((prev) => { return { ...prev, treatments: [], point: 0, total_price: 0 } });

    request.get(`/api/goods/list/service?searchData=${params.searchData}&category=${params.category}&delete_yn=${params.delete_yn}&display_yn=${params.display_yn}&order_by=${params.orderBy}&user_seq=${params.user_seq}`, null, successHandler);
  }

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') searchTreatment();
  }

  const onChangeCoupon = (e) => {
    // console.log("쿠폰 정보", couponList[e.target.selectedIndex - 1])

    if (e.target.selectedIndex === 0) setSelectedItems((prev) => { return { ...prev, coupon: {} } });
    else setSelectedItems((prev) => { return { ...prev, coupon: couponList[e.target.selectedIndex - 1] } });
  }

  const onClickUsePoint = (e) => {
    const value = parseInt(nvlNumber(usePoint), 10);

    if (point < value) {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '지급받은 포인트 보다 많게는 입력할 수 없습니다.', alertType: 'alert' } })

      return;
    }

    setSelectedItems((prev) => { return { ...prev, point: prev.point + parseInt(usePoint, 10) } });
    setPoint((prev) => prev -= value);
    setUsePoint(0);
  }

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
  }

  const onPopupClick = (e) => {
    if (nvlNumber(stateCustomer.user_seq) > 0) setPopup((prev) => { return { ...prev, [e.target.id]: true } })
    else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
  }

  const onClickTodayOrder = (e) => {
    if (nvlNumber(stateCustomer.user_seq) > 0) {
      setAddedItems(selectedItems); // 시술 검색 => 당일 오더에 담기는 시술
      const treatment = selectedItems.treatments.map(item => item)
      // setPaymentItems(prev => [...prev, ...treatment]) // 구매 시술 추가

      const newArr = [...treatmentList];
      newArr.forEach(item => item.checked = false); // 체크된 거 해제

      setTreatmentList(newArr);
      setSelectedItems((prev) => { return { ...prev, treatments: [], point: 0, total_price: 0 } });
      setUsePoint(0);
    } else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
  }

  const getCategoryList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setCategoryList(response.data);
        // setSelectedCategory(nvl(result[0]?.code));
      } else {
        console.log('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/001000000`, null, successHandler);
  }

  const getTreatmentList = () => {
    setSearchText('')
    searchTreatment()
  }

  useEffect(() => {
    getCategoryList();

    return () => { // 컴포넌트가 화면에서 사라졌을 때 초기화
      setCategoryList([]);
      setTreatmentList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nvl(selectedCategory) !== '') getTreatmentList();

    return () => { // selectedCategory 바뀌기 전 초기화
      setTreatmentList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const getAvailableUserCoupon = () => { // 쿠폰 리스트
    const successHandler = (response) => {
      if (response.messageCode === 200) setCouponList(response.data?.dataList);
      else console.log('Error >> ', response);
    }

    request.get(`/api/coupon/history?user_seq=${stateCustomer.user_seq}&delete_yn=N&enable_status=Y&orderBy=coupon_history_seq DESC`, null, successHandler); // sqlUserEnableCoupon
  }

  useEffect(() => {
    getAvailableUserCoupon();

    return () => { // selectedCategory 바뀌기 전 초기화
      setCouponList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomer.user_seq, userData.user_coupon_cnt]);

  const getPoint = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) setPoint(nvlNumber(response.data[0]?.current_point));
      else console.log('Error >> ', response);
    }

    request.get(`/api/point/${stateCustomer.user_seq}`, null, successHandler);
  }

  const getVisitList = (user_seq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data?.dataList;
        const record1 = nvl(result) !== '' && result.length > 0 ? result.filter(el => JSON.parse(el.chart_info) != null).map(item => JSON.parse(item.chart_info)) : null;
        setPersonalRecord(record1)
        setVisitList(result);
      } else {
        console.log("내원기록 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/charts/order/dashboard?delete_yn=N&user_seq=${user_seq}`, null, successHandler);
  }

  useEffect(() => {
    if (stateCustomer.user_seq !== 0) {
      getVisitList(stateCustomer.user_seq);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomer.user_seq])

  useEffect(() => {
    getPoint();
    getUserDiseaseCode(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomer.user_seq, popup.point, pointStatus]);

  const getUserDiseaseCode = (user_seq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data?.dataList;
        // setDiseaseCode(result.map((el) => (el.disease_code)))
        setDiseaseCode(result)
      } else {
        console.log("질병코드 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/disease/diseaseCode/${stateCustomer.user_seq}`, null, successHandler); //날짜 선택
  }

  useEffect(() => { // added by psk 20230530 - 선택된 상품 관리
    let selectedList = []
    let totalPrice = 0

    for (const item of treatmentList) {
      if (nvl(item?.checked)) {
        selectedList.push(item)

        totalPrice += vatCalculator(item.p_goods_type, item.p_vat_type, item.pack_sale_price, item.goods_sale_price, item.vat_type, item.goods_price) // 시술 상품 가격 (부가세 별도와 포함 구별한 값)
      }
    }

    setSelectedItems((prev) => { return { ...prev, treatments: selectedList, total_price: totalPrice } });
  }, [treatmentList])

  const inputUsePoint = (event) => {
    const pointValue = event.target.value

    if (nvl(pointValue) !== '' && !nvlOnlyNumber(pointValue)) return

    if (nvl(pointValue) === '') setUsePoint('')
    else setUsePoint(parseInt(event.target.value))
  }



  return (
    <>
      <div className="com_content__inner">
        {
          displayScreen?.goods_search_disaplay_yn === 'Y' &&
          <section className="com_sec sec_procedure_search">
            <hgroup className="com_sec__hgroup">
              <h1 className="com_sec__title">시술 검색</h1>
            </hgroup>
            <div className="cont_box">
              <article className="com_field type_normal">
                <fieldset className="many">
                  <select className="select" name='category' onChange={onChangeCategory} key={selectedCategory} value={selectedCategory}>
                    <option value="">카테고리 선택</option>
                    {
                      categoryList != null && categoryList.length ?
                        categoryList?.map((item) => { return <option key={item.code} value={item.code}>{item.name}</option> })
                        : null
                    }
                  </select>
                  <div className="input_del_wrap">
                    <input type="text" className="input" placeholder="시술명 검색" name="" value={nvl(searchText)} onChange={(e) => setSearchText(e.target.value)} onKeyDown={handleOnKeyPress} />
                    <button type="button" className="btn_input_del" onClick={() => setSearchText('')}>&nbsp;</button>
                  </div>
                  <button type="button" className="com_btn m blue icon" onClick={searchTreatment}><i className="svg_icon icon_search white">&nbsp;</i></button>
                </fieldset>
              </article>
              <article className="com_list">
                <div className="scroll">
                  <table className="list">
                    <colgroup><col width="10%" /><col /><col width="11.9%" /><col width="11.9%" /><col width="20.7%" /></colgroup>
                    <thead>
                      <tr>
                        <th>선택</th>
                        <th>시술명</th>
                        <th>구분</th>
                        <th>회차</th>
                        <th>금액</th>
                      </tr>
                    </thead>
                    <tbody>
                      <Treatment treatmentList={treatmentList} setTreatmentList={setTreatmentList} />
                    </tbody>
                  </table>
                </div>
                <div className="total_price">
                  <span>총 시술 금액</span>
                  <span className="price">{new Intl.NumberFormat().format(selectedItems.total_price)}원</span>
                </div>
              </article>
              <ul className="com_input_section">
                <li className="tit_field">사용가능 쿠폰</li>
                <li className="input_field">
                  <select className="select" name='select-coupon' onChange={onChangeCoupon}>
                    <option value="">쿠폰선택</option>
                    {
                      couponList != null && couponList.length > 0 ?
                        couponList.map((item, index) => { return (<option key={item.coupon_seq + `_${index}`} value={item.coupon_seq}>{item.coupon_name}</option>) })
                        : null
                    }
                  </select>
                </li>
                <li className="tit_field">사용가능 포인트</li>
                <li className="input_field">
                  <div className="input_wrap">
                    <p className="point">{new Intl.NumberFormat().format(point)} Point</p><button id='point' className="com_btn m blue line" onClick={onPopupClick}>포인트 충전</button>
                  </div>
                  <div className="input_wrap">
                    <input type="text" min='1' className="input" placeholder="사용할 포인트 입력" value={usePoint} onChange={inputUsePoint} />
                    <button className="com_btn blue m" onClick={onClickUsePoint}>확인</button>
                  </div>
                </li>
              </ul>
              <article className="btn_bottom_area com_btn_wrap">
                <button className="com_btn blue m" disabled={!selectedItems?.treatments.length || nvl(orderInfo?.order_seq) === ""} onClick={onClickTodayOrder}><i className="svg_icon icon_add yellow">&nbsp;</i>당일 오더에 추가</button>
              </article>
            </div>
          </section>
        }
        {/*//!내원 기록*/}
        {/* type_more 이 있는 경우 active 클래스로 on/off 제어 */}
        {displayScreen?.visit_display_yn === 'Y' && <section className={visible.visited ? 'com_sec type_more sec_visit active' : 'com_sec type_more sec_visit'}>
          <hgroup className="com_sec__hgroup">
            <h1 className="com_sec__title">내원 기록</h1>
            <button className="button" id='visit' onClick={onPopupClick} disabled={nvlNumber(personalRecord?.length) === 0}><i className="svg_icon icon_more yellow">&nbsp;</i>더보기</button>
          </hgroup>
          <article className="more_cont_box">
            <div className="scroll">
              {personalRecord?.length > 0 ? personalRecord?.map((el, idx) => (
                <section className="com_list" key={idx}>
                  <article className="top_area">
                    <div className="info_field">
                      <p className="date">{moment(el[0].order_date).format("YYYY,MM,DD")}</p>
                      <p className="txt">{el[0].treatment_name}</p>
                    </div>
                    <div className="info_field right">
                      <p className="txt">미수금<span className="txt_red">50,000</span></p>
                      <p className="txt">결제<span className="txt_blue">100,000</span></p>
                    </div>
                  </article>
                  <table className="list">
                    <thead>
                      <tr>
                        <th>접수</th>
                        <th>상담</th>
                        <th>준비</th>
                        <th>시술</th>
                        <th>관리</th>
                        <th>마무리</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{el[0].receptionist_manager_name}</td>
                        <td>{el[0].counselor_manager_name}</td>
                        <td>{el[0].preparation_manager_name}</td>
                        <td>{el[0].treatment_manager_name}</td>
                        <td>{el[0].manager_name}</td>
                        <td>{el[0].finish_manager_name}</td>
                      </tr>
                      <tr>
                        <td colSpan="6" className="alignL">{el[0].today_content}</td>
                      </tr>
                      <tr>
                        <td colSpan="6" className="alignL">
                          {diseaseCode
                            .filter(item => moment(item.reg_date).format("YYYY-MM-DD") === moment(el[0].order_date).format("YYYY-MM-DD"))
                            .map((item, index) => (
                              <div key={item.disease_code + '_' + index}>{item.disease_code}&nbsp;</div>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              )) : "내원 기록 없음"}
            </div>
          </article>
          <button id='visited' type="button" className="btn_more" onClick={onToggle}>&nbsp;</button>
        </section>}
        {popup.point && <PointManage onClose={() => { onClosePop('point'); }} />}
        {popup.visit && <VisitHistory onClose={() => { onClosePop('visit'); }} personalRecord={personalRecord} diseaseCode={diseaseCode} userData={userData} />}
      </div>
      {alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
    </>
  )
}

export default ChartCenter
