//*일반할인 쿠폰
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import CustomAlert from "atoms/common/CustomAlert";
import { nvl, nvlOnlyNumber } from "utils/Common.utils";

const CouponNormal = ({normalCouponRegDisplayBool, couponRegToggle, onUpdateBool, setOnUpdateBool, surgeryCategory, setSurgeryCategory, category, alertLayerPopup, setAlertLayerPopup, closeCustomAlert, popData, setPopData, autoCouponSeq, getNormalCouponList, deleteCoupon, setCheckedItems}) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [normalData, setNormalData] = useState({
    coupon_type: '4', //쿠폰종류,
    coupon_name: '', //쿠폰명
    goods_cate: '', //시술 카테고리
    goods_seq: '', //시술 선택
    radio_button: 'Y', //할인율, 할인금액 라디오버튼
    discount_rate: '', //할인율
    coupon_price: '', //할인 금액
    expired_date: '', //유효기간
    treatment_times: 0, //회차선택
    attendance_times: '', //출석 회차
    payment_attainment: '', //결제 도달 금액
    normal_yn: 'Y'
  })

  const popupSectionRef = useRef();
  const nodeRef = useRef(null);

  if(normalCouponRegDisplayBool) popupSectionRef?.current?.classList.add('active');
  else if(!normalCouponRegDisplayBool && popupSectionRef.current) popupSectionRef?.current?.classList.remove('active');

  //초기화
  const resetPopup = () => {
    setNormalData({
      coupon_type: '4',
      coupon_name: '', //쿠폰명
      goods_cate: '', //시술 카테고리
      goods_seq: '', //시술 선택
      radio_button: 'Y', //할인율, 할인금액 라디오버튼
      discount_rate: '', //할인율
      coupon_price: '', //할인 금액
      expired_date: '', //유효기간
      normal_yn: 'Y'
    })
    setSurgeryCategory(""); // 시술 카테고리 초기화
    // setPopData([]); //팝업 데이터 
    setOnUpdateBool(false); //수정 초기화
  }
  
  const popupClose = () => {
    resetPopup();
    couponRegToggle(2);
  }

  //일반할인 쿠폰 onChange 핸들러
  const onChangeNormal = (e) => {
    const { name, value } = e.target;
    if(!onUpdateBool){ // 등록
      if(name === "coupon_name"){
        setNormalData({...normalData, coupon_name:value});
      } else if (name === "radio_button"){ //할인율, 할인금액 라디오버튼
        setNormalData({...normalData, radio_button:value, discount_rate:'', coupon_price:''});
      } else if (name === "discount_rate"){ //할인율
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regNormalCoupon"}});
          setNormalData({...normalData, discount_rate:''});
          return false;
        }else{
          setNormalData({...normalData, discount_rate:value});
        }
      } else if (name === "coupon_price"){ //할인금액
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regNormalCoupon"}});
          setNormalData({...normalData, coupon_price:''});
          return false;
        }else{
          setNormalData({...normalData, coupon_price:value});
        }
      } else if (name === "expired_date"){ //유효기간
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "regNormalCoupon"}});
          setNormalData({...normalData, expired_date:''});
          return false;
        }else{
          setNormalData({...normalData, expired_date:value});
        }
      } else if (name === "normal_yn"){
        setNormalData({...normalData, normal_yn:value});
      }
    } else { // 수정
      if(name === "coupon_name"){
        setPopData({...popData, coupon_name:value});
      } else if (name === "surgery_category") {
        setPopData({...popData, goods_cate:value});
      } else if (name === "radio_button"){ //할인율, 할인금액 라디오버튼
        setPopData({...popData, discount_radio_button:value, discount_rate:'', coupon_price:''});
      } else if (name === "discount_rate"){ //할인율
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateNormalCoupon"}});
          setPopData({...popData, discount_rate:''});
          return false;
        }else{
          setPopData({...popData, discount_rate:value});
        }
      } else if (name === "coupon_price"){ //할인금액
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateNormalCoupon"}});
          setPopData({...popData, coupon_price:''});
          return false;
        }else{
          setPopData({...popData, coupon_price:value});
        }
      } else if (name === "expired_date"){ //유효기간
        if(nvl(value) !== '' && !nvlOnlyNumber(value)){
          // setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "숫자만 입력해주세요.", activateMode: "updateNormalCoupon"}});
          setPopData({...popData, expired_date:''});
          return false;
        }else{
          setPopData({...popData, expired_date:value});
        }
      } else if (name === "normal_yn"){
        setPopData({...popData, coupon_status:value});
      }
    }
  }

  // 쿠폰 등록 & 수정 버튼 클릭
  const registerCoupon = () => {
    if (!onUpdateBool) {  // 쿠폰 등록
      if (normalData.coupon_name === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "쿠폰 명을 입력해주세요.", activateMode: "regNormalCoupon"}});
        return;
      } else if (surgeryCategory === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "시술 카테고리를 선택해주세요.", activateMode: "regNormalCoupon"}});
        return;
      } else if (normalData.radio_button === "Y" && normalData.discount_rate === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "할인율을 입력해주세요.", activateMode: "regNormalCoupon"}});
        return;
      } else if (normalData.radio_button === "N" && normalData.coupon_price === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "할인금액을 입력해주세요.", activateMode: "regNormalCoupon"}});
        return;
      } else if (normalData.expired_date === "") {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "유효기간을 입력해주세요.", activateMode: "regNormalCoupon"}});
        return;
      }
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "쿠폰을 등록하시겠습니까?", activateMode: "regNormalCoupon"}});
    } else {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "쿠폰을 수정하시겠습니까?", activateMode: "updateNormalCoupon"}});
    }
  }

  const returnAlertValue = (value) => {
    if (nvl(value) === "OK") {
      if (!onUpdateBool && alertLayerPopup.activateMode === "regNormalCoupon") {  // 쿠폰 등록
        let params = {
          coupon_image: "",
          coupon_type: 4, //normal은 4
          coupon_name: normalData.coupon_name,
          coupon_description: "",
          goods_cate:surgeryCategory,
          goods_seq: 0,
          treatment_times: normalData.treatment_times ? normalData.treatment_times : 0, //시술회차
          discount_rate: normalData.radio_button === "Y" ? normalData.discount_rate : 0,
          coupon_price: normalData.radio_button === "N" ? normalData.coupon_price : 0,
          attendance_times: normalData.attendance_times ? normalData.attendance_times : 0, //출석회차
          payment_attainment: normalData.payment_attainment ? normalData.payment_attainment : 0, //지급회차
          remark: "",
          coupon_status: normalData.normal_yn,
          expired_date: normalData.expired_date,
          use_start_date: null,
          use_end_date: null,
          modi_manager_seq: managerInfo.seq,
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `일반할인 쿠폰이 등록되었습니다.` } });
            getNormalCouponList();
            popupClose();
          } else {
            console.log(`일반할인 쿠폰을 등록하지 못했습니다.`)
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.post("/api/coupon", params, successHandler, failHandler, null, null);
      } else if (onUpdateBool && alertLayerPopup.activateMode === "updateNormalCoupon") { // 쿠폰 수정
        let params = {
          coupon_seq: autoCouponSeq,
          coupon_image: "",
          coupon_type: 4,
          coupon_name: popData.coupon_name,
          coupon_description: "",
          goods_cate: popData.goods_cate,
          goods_seq: popData?.goods_seq,
          treatment_times: popData.treatment_times, //시술회차
          discount_rate: popData.discount_radio_button === "Y" ? popData.discount_rate : 0,
          coupon_price: popData.discount_radio_button === "N" ? popData.coupon_price : 0,
          attendance_times: popData.attendance_times ? popData.attendance_times : 0, //출석회차
          payment_attainment: popData.payment_attainment ? popData.payment_attainment : 0, //지급회차
          remark: "",
          coupon_status: popData.coupon_status,
          expired_date: popData.expired_date,
          use_start_date: null,
          use_end_date: null,
          modi_manager_seq: managerInfo.seq,
          reg_manager_seq: managerInfo.seq
        }

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            getNormalCouponList();
            popupClose();
            // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "일반할인 쿠폰을 수정하였습니다." } });
          } else {
            console.log("쿠폰을 수정하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }

        request.put("/api/coupon", params, successHandler, failHandler, null, null);
      } else if(onUpdateBool && alertLayerPopup.activateMode === "deleteNormalCoupon"){ 
        const queryString = deleteCoupon.map(el => `id=${el}`).join('&');

        const successHandler = (response) => {
          if (response.messageCode === 200) {
            setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "쿠폰을 삭제하였습니다." } });
            setOnUpdateBool(false);
            getNormalCouponList();
            setCheckedItems([]);
          } else {
            console.log("쿠폰을 삭제하지 못했습니다.");
          }
        }

        const failHandler = (response) => {
          console.log("에러" + response);
        }
        
        request.del(`/api/coupon/${queryString}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
      }
    } 
  }

	return (
		<>
      {alertLayerPopup.visible && (alertLayerPopup.activateMode === "regNormalCoupon" || alertLayerPopup.activateMode === "updateNormalCoupon" || alertLayerPopup.activateMode === "deleteNormalCoupon")  ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
      <section ref={popupSectionRef} className="com_popup type_normal">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={popupClose}></button>
            <div className="pop_header">
              <h1 className="pop_title">일반할인 쿠폰</h1>
            </div>
            <div className="pop_body">
              <ul className="com_input_section">
                <li className="tit_field">쿠폰 명</li>
                <li className="input_field">
                  <input type="text" className="input" placeholder="쿠폰 명 입력" name="coupon_name" value={!onUpdateBool ? normalData.coupon_name : popData.coupon_name || ''} onChange={(e) => onChangeNormal(e)}/>
                </li>
                <li className="tit_field">시술 카테고리</li>
                <li className="input_field">
                <select className="select" name="surgery_category" id="surgery_category" value={!onUpdateBool ? surgeryCategory : popData.goods_cate || ''} onChange={(e) => {
                    setSurgeryCategory(e.target.value);
                    onChangeNormal(e);
                    }}>
                  <option value={0}>시술 카테고리 선택</option>
                  {category?.map((el,i)=>(
                    <option key={i} value={el.common_code}>{el.code_name}</option>
                  ))}
                </select>
                </li>
                <li className="tit_field has_input">
                  <fieldset className="com_radio_wrap" name="radio_button">
                    <input type="radio" className="radio" id="discount03" name="radio_button" value='Y' onChange={(e) => onChangeNormal(e)} checked={!onUpdateBool ? (normalData.radio_button === "Y" ? true : false) : (popData?.discount_radio_button === "Y" ? true : false)}/>
                    <label className="btn_radio" htmlFor="discount03">&nbsp;</label>
                    <label htmlFor="discount03">할인율</label>
                  </fieldset>
                </li>
                <li className="input_field input_wrap">
                  <input type="text" className="input" name="discount_rate" value={!onUpdateBool ?  normalData.discount_rate : (popData?.discount_rate === 0 ? "" : popData?.discount_rate || '')} disabled={!onUpdateBool ? (normalData?.radio_button === "N" ? true : false) : (popData?.discount_radio_button === "N" ? true : false)} onChange={(e) => onChangeNormal(e)}/><span className="unit">%</span>
                </li>
                <li className="tit_field has_input">
                  <fieldset className="com_radio_wrap" name="radio_button">
                    <input type="radio" className="radio" id="discount04" name="radio_button" value='N' onChange={(e) => onChangeNormal(e)} checked={!onUpdateBool ? (normalData.radio_button === "N" ? true : false) : (popData?.discount_radio_button === "N" ? true : false)}/>
                    <label className="btn_radio" htmlFor="discount04">&nbsp;</label>
                    <label htmlFor="discount04">할인금액</label>
                  </fieldset>
                </li>
                <li className="input_field input_wrap">
                  <input type="text" className="input" name="coupon_price" 
                      value={!onUpdateBool ? normalData.coupon_price : (popData?.coupon_price === 0 ? "" : (popData?.coupon_price ? (popData?.coupon_price) : ""))} 
                      disabled={!onUpdateBool ? (normalData?.radio_button === "Y" ? true : false) : (popData?.discount_radio_button === "Y" ? true : false)} onChange={(e) => onChangeNormal(e)}/><span className="unit">원</span>
                </li>
                <li className="tit_field">유효기간</li>
                <li className="input_field input_wrap">
                  <input type="text" className="input" name="expired_date" value={!onUpdateBool ?  normalData.expired_date : popData?.expired_date || ''} onChange={(e) => onChangeNormal(e)}/><span className="unit">일</span>
                </li>
                <li className="input_field full line center">
                  <fieldset className="com_radio_wrap">
                    <input type="radio" className="radio" id="useType3" name="normal_yn" value="Y" checked={!onUpdateBool ?  (normalData.normal_yn === "Y" ? true : false) : (popData?.coupon_status === "Y" ? true : false)}
                    onChange={(e) => onChangeNormal(e)}/>
                    <label className="btn_radio" htmlFor="useType3">&nbsp;</label>
                    <label htmlFor="useType3">사용</label>
                    <input type="radio" className="radio" id="useType4" name="normal_yn" value="N" checked={!onUpdateBool ? (normalData.normal_yn === "N" ? true : false) : (popData?.coupon_status === "N" ? true : false)}
                    onChange={(e) => onChangeNormal(e)}/>
                    <label className="btn_radio" htmlFor="useType4">&nbsp;</label>
                    <label htmlFor="useType4">미사용</label>
                  </fieldset>
                </li>
              </ul>
            </div>
            <div className="pop_footer com_btn_wrap many two">
              <button className="com_btn gray l" onClick={popupClose}>취소</button>
              <button className="com_btn blue l" onClick={registerCoupon}>{!onUpdateBool ? "등록" : "수정"}</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default CouponNormal