//* 좌측 예약/취소
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import request from '../../utils/Request.utils';
import DashCustomCard from "./_DashCustomCard";
import LoadingBar from '../../utils/LoadingBar';

const DashReserve = ({ userData, setUserData, startDate, getReceptionList, getSelectedUserInfo, setLnbSearchOptions, changeUserHandler }) => {
  const stateOrderInfo = useSelector(state => state.OrderInfo);

  const [reservationList, setReservationList] = useState([]);
  const [cancelList, setCancelList] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [cancelCount, setCancelCount] = useState([]);
  const [surgeryList, setSurgeryList] = useState([]); 
  const [roomList, setRoomList] = useState([]); 
  const [selectedValue, setSelectedValue] = useState('012001000');
  const [loadingBarActive, setLoadingBarActive] = useState(false);
  const [activeButton, setActiveButton] = useState('reserve');

  const getReservationList = () => {  
    setLoadingBarActive(true);
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data.dataList;
        setReservationList(result.filter((el) => el.reser_status === 'R'));
        setCancelList(result.filter((el) => el.reser_status === 'C'))
        setTotalCount(result.filter((el) => el.reser_status === 'R').length);
        setCancelCount(result.filter((el) => el.reser_status === 'C').length);
        setLoadingBarActive(false);
      } else {
        console.log("예약 고객 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/reservation?listType=F&not_success_status=I&delete_yn=N&reser_date=${startDate}`, null, successHandler, failHandler, null, null);
  }

  const getSurgeryRoomList = () => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data;
        setSurgeryList(result);
        // setSurgeryRoom('');
        getRoomList();
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/012000000`, null, successHandler, failHandler, null);
  }

  const getRoomList = () => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data;
        setRoomList(result);
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/${selectedValue}`, null, successHandler, failHandler, null);
  }

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  useEffect(() => {
    getReservationList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[startDate])

  // added by psk 20230603 - 예약하기 레이어 팝업과 대시보드 연결
  useEffect(() => {
    getReservationList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateOrderInfo.reser_info_status])

	return (
		<>
      <div className="dashboard_sec dashboard_reserve">
        <div className="tab_box">
          <ul className="tab">
            <li className="item"><button className={`menu ${activeButton === 'reserve' ? 'active reserve' : ''}`}
          onClick={() => handleButtonClick('reserve')}>예약 {totalCount}</button></li>
            <li className="item"><button className={`menu ${activeButton === 'cancel' ? 'active reserve' : ''}`}
          onClick={() => handleButtonClick('cancel')}>예약취소 {cancelCount}</button></li>
          </ul>
          <button className="svg_icon icon_refresh" onClick={getReservationList}></button>
        </div>
        <DashCustomCard customerList={reservationList} userData={userData} setUserData={setUserData} getReservationList={getReservationList} getReceptionList={getReceptionList} getSelectedUserInfo={getSelectedUserInfo} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler}/>
        {/*예약취소*/}
        <hr className="division_line"/>
        <DashCustomCard customerList={cancelList} setCustomerList={setCancelList} userData={userData} setUserData={setUserData} getReservationList={getReservationList} getSelectedUserInfo={getSelectedUserInfo} getReceptionList={getReceptionList} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler}/>
      </div>
      {loadingBarActive && <LoadingBar type={"spin"} color={"#000000"} />}
		</>
	)
}

export default DashReserve