//* 서류발급 > 진단서
import React, { forwardRef } from "react";
import { decrypt } from "../../utils/aes256.manager";
import moment from "moment";
import { nvl } from "../../utils/Common.utils";

const DoctorsNote = forwardRef((props, ref) => {
  const { selectedCustomer, userInfo, medicalCertificate, selectedArray, hospitalInfo, medicalLicense, selectedDoctorName, buttonNum } = props;


  return (
    <>
      <section className="com_document " ref={ref}>
        <p className="law">■ 의료법 시행규칙 [별지 제5호의 2서식] {'<'}개정 2015. 12. 23{'>'}</p>
        <h1 className="document_tit">{buttonNum === 4 ? "진단서" : "소견서"}</h1>
        <div className="top_area">
          <table className="table">
            <colgroup><col width="90" /><col /></colgroup>
            <tr>
              <th>등록번호</th>
              <td>8253</td>
            </tr>
            <tr>
              <th>연번호</th>
              <td>202304260001</td>
            </tr>
          </table>
        </div>
        <table className="table">
          <colgroup><col width="230" /><col width="36%" /><col width="15%" /><col /></colgroup>
          <tr>
            <th>환자의 성명</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.user_name : selectedCustomer.user_name}</td>
            <th>주민등록번호</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.jumin_1 + "-" + userInfo.jumin_2 : (decrypt(selectedCustomer?.user_jumin)?.substring(0, 6) + "-" + decrypt(selectedCustomer?.user_jumin)?.substring(6, 13))}</td>
          </tr>
          <tr>
            <th>환자의 주소</th>
            <td colSpan={3} className="address_field">{nvl(selectedCustomer) === "" ? `${userInfo.user_address} ${userInfo.user_address_detail}`  : `${selectedCustomer.user_address} ${selectedCustomer.user_address_detail}`} (전화번호 : {nvl(selectedCustomer) === "" ? userInfo.user_mobile : selectedCustomer.user_mobile})
            </td>
          </tr>
          <tr>
            <th className="big">
              <br /><br />
              병명<br /><br />
              {medicalCertificate.diagnosis_type === "017001000" ?
                <p className="left">
                  [v] 임상적 추정<br />
                  [&nbsp;] 최종진단
                </p>
                :
                <p className="left">
                  [&nbsp;] 임상적 추정<br />
                  [v] 최종진단
                </p>
              }
            </th>
            <td colSpan={3} className="memo" style={{ whiteSpace: "pre-line" }}>{selectedArray[0]?.disease_name.map((name, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {name}
                </React.Fragment>
              ))}</td>
          </tr>
          <tr>
            <th>발병일</th>
            <td>{nvl(medicalCertificate.occur_date) === "" ? "" : moment(medicalCertificate.occur_date).format("YYYY년 MM월 DD일")}</td>
            <th>진단일</th>
            <td>{moment(medicalCertificate.treatment_date).format("YYYY년 MM월 DD일")}</td>
          </tr>
          <tr>
            <th className="big">
              <br /><br /><br /><br /><br /><br /><br /><br />
              향후<br />치료 의견
              <br /><br /><br /><br /><br /><br /><br /><br />
            </th>
            <td colSpan={3} className="memo">{medicalCertificate.treatment_detail}</td>
          </tr>
          <tr>
            <th>비고 </th>
            <td>{medicalCertificate.paper_remark}</td>
            <th>용도 </th>
            <td ></td>
          </tr>
        </table>
        <div className="bottom_area">
          <div className="tit__wrap">
            <p className="tit">「의료법」 제 17조 및 같은 법 시행규칙 제9조 제1항에 따라 위와 같이 진단합니다.</p>
            <p className="date">{moment(new Date()).format("YYYY년 MM월 DD일")}</p>
          </div>
          <ul className="list">
            <li className="item">
              <p className="tit_field">의&nbsp;료&nbsp;기&nbsp;관&nbsp;명&nbsp;칭&nbsp;: </p>
              <p className="input_field">{hospitalInfo.hospital_name}</p>
            </li>
            <li className="item">
              <p className="tit_field">주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소&nbsp;: </p>
              <p className="input_field">{hospitalInfo.hospital_address} {hospitalInfo.hospital_address_detail}</p>
            </li>
          </ul>
          <div className="doctor_info">
            <div className="sec">
              <fieldset className="com_check_wrap">
                <input type="checkbox" className="checkbox" id="doctor01" checked disabled />
                <label className="btn_checkbox" htmlFor="doctor01">&nbsp;</label>
                <label htmlFor="doctor01">의사</label>
                <input type="checkbox" className="checkbox" id="doctor02" disabled />
                <label className="btn_checkbox" htmlFor="doctor02">&nbsp;</label>
                <label htmlFor="doctor02">치과의사</label>
                <input type="checkbox" className="checkbox" id="doctor03" disabled />
                <label className="btn_checkbox" htmlFor="doctor03">&nbsp;</label>
                <label htmlFor="doctor03">한의사</label>
              </fieldset>
              <p className="num">면호번호 : 제 {medicalLicense} 호</p>
            </div>
            <div className="doctor">성명 :&nbsp;&nbsp;&nbsp;&nbsp;{selectedDoctorName}&nbsp;&nbsp;&nbsp;&nbsp;<span>(서명 또는 사인)</span></div>
          </div>
        </div>
      </section>
    </>
  )
})


export default DoctorsNote