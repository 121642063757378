//* 동의서 보기
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from 'moment';
import parse from 'html-react-parser';

import request from "utils/Request.utils";
import PopupSign from '../../atoms/common/PopupSign';
import { nvl, nvlNumber } from "utils/Common.utils.js";
import { useReactToPrint } from 'react-to-print';
import CustomAlert from "atoms/common/CustomAlert";

const ConsentDetail = ({ userData, selectedContent, setSelectedContent, onClose }) => {
  const now = new Date();
  const today = moment(now).format("YYYY년 MM월 DD일");
  const todayConsent = moment(new Date()).format("YYYYMMDD");

  const nodeRef = useRef(null);
  const componentRef = useRef();
  const uploadFileRef = useRef();

  const [res, setRes] = useState(false);
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    closeType: '',
  });
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `동의서_${todayConsent}`,
    onAfterPrint: () => {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "동의서를 서버에 저장하시겠습니까?",activateMode: "" } });
    },
  });


  moment.locale('ko-KR');

  const agreementDocInfoBinding = ({ target: { id, value } }) => {

    if(id === 'agreement_yes' && value === 'Y') setSelectedContent((prev) => { return { ...prev, visible: true } })
    if(id === 'agreement_yes' || id === 'agreement_no') id = 'agree_yn';

    setSelectedContent((prev) => { return { ...prev, [id]: value } })
  }

  const getContentList = () => {
    const successHandler = (response) => {
      // console.log(response)

      if(response.messageCode === 200) {
        setSelectedContent((prev) => { return { ...prev, agree_name: response.data[0].agree_name, agree_description: response.data[0].agree_description  } })
      } else console.log('Error >> ', response);
    }

    request.get(`/api/etc/agreements/S/${selectedContent.agree_seq}`, null, successHandler);
  }

  useEffect(() => {
    getContentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


   // alert창 닫기
   const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const returnAlertValue = (value) => {
    if(nvl(value) === 'OK') {
      setRes(true);
    }
  }

  // 첨부파일 등록
  const selectUploadFile = () => {
    uploadFileRef.current.click();
  }

  const uploadFileHandler = (e) => {
    const formData = new FormData();

    formData.append("uploadFile", e.target.files[0])
    formData.append("file_cate", "consent")
    formData.append("user_seq", nvlNumber(userData.user_seq))

    const successHandler = (response) => {

      if (response.messageCode === 200) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "파일이 저장되었습니다." } })
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 형식의 파일이 있습니다." } })
    }

    const failHandler = (result) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "파일 첨부중 에러가 발생했습니다." } })
    }

    uploadFileInit();

    request.postMultipart("/api/upload/sign/consent", formData, successHandler, failHandler);
  }

  const uploadFileInit = () => {
    uploadFileRef.current.value = "";
  }

  return (
    <>
      <section className="com_popup type_only pop_consent_detail active">
        <div className="pop_container">
          <div ref={el => componentRef.current = el}>
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">동의서</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <article className="editor">
                <div className="textarea">{parse(selectedContent.agree_description)}</div>
                <div className="agree">
                  <p className="txt">* 위내용에 대해 충분한 설명을 맏았고, 정확히 인지하여 아래 서명을 하였음</p>
                  <fieldset className="com_check_wrap">
                    <input type="radio" className="checkbox" id="agreement_yes" name="choiceAgreement" onChange={ agreementDocInfoBinding } checked={ selectedContent.agree_yn === "Y" } value="Y" />
                    <label className="btn_checkbox" htmlFor="agreement_yes">&nbsp;</label>
                    <label htmlFor="agreement_yes">동의</label>
                    <input type="radio" className="checkbox" id="agreement_no" name="choiceAgreement" onChange={ agreementDocInfoBinding } checked={ selectedContent.agree_yn === "N" } value="N" />
                    <label className="btn_checkbox" htmlFor="agreement_no">&nbsp;</label>
                    <label htmlFor="agreement_no">비동의</label>
                  </fieldset>
                  <div className="date_field">
                    <input className="input" type="text" placeholder={ today } value={ today } readOnly/>
                  </div>
                  <div className="info_field">
                    <input className="input" type="text" placeholder="고객이름" value={ userData.user_name } readOnly/>
                    <input className="input" type="text" placeholder="고객주민번호" value={ `${userData.jumin_1}-${userData.jumin_2}` } readOnly/>
                    <div className="sign_area">
                      <div ref={nodeRef} className="sign">
                        {
                          nvlNumber(selectedContent.sing_seq) !== '' && nvl(selectedContent.up_file_path) !== '' ?
                            <img src={`${process.env.REACT_APP_API_URL}/api/upload/viewer/image/sign/${selectedContent.up_file_path.split("/")[selectedContent.up_file_path.split("/").length - 2]}_${selectedContent.up_file_path.split("/")[selectedContent.up_file_path.split("/").length - 1]}`}
                              alt="sign" /> : null
                        }
                      </div>
                      <input className="input" type="text" placeholder="서명" readOnly/>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div className="pop_footer com_btn_wrap">
            <button type="button" className="com_btn l blue" onClick={() => { onClose() }}>닫기</button>
            {res ?
            <>
              <input type="file" id="uploadFile" name="files" className="com_btn l blue lin" ref={uploadFileRef}  onChange={uploadFileHandler} style={{ display: "none" }}/>
              <button type="button" className="com_btn l blue line" onClick={selectUploadFile}>파일 찾기</button>
            </>
            :
            <button type="button" className="com_btn l blue line" onClick={handlePrint}><i className="svg_icon icon_print yellow">&nbsp;</i>인쇄</button>
            }
          </div>
        </div>
      </section>
      { selectedContent.visible && <PopupSign selectedContent={ selectedContent } setSelectedContent={ setSelectedContent } /> }
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} />}
    </>
  )
}

export default ConsentDetail