import React, { useRef, useEffect, useState } from "react";
import moment from 'moment';
import ConsentList from "./_ConsentList.js" // 동의서 팝업
import ReservationAdd from '../../components/common/_ReservationAdd' // 예약하기 팝업 -- 이건 
import CouponManage from '../../components/common/_CouponManage' // 쿠폰 정보 팝업
import PointManage from '../../components/common/_PointManage' // 포인트 정보 팝업
import PicAdd from "./_PicAdd.js" // 사진 등록하기 팝업
import PicDetail from "./_PicDetail.js" // 사진 상세보기 팝업
import MemoCard from "../../components/common/_CustomMemo" // 메모카드 팝업
import CustomCard from "components/common/_CustomCard.js";
import request from "utils/Request.utils.js";
import CustomAlert from "atoms/common/CustomAlert.js";
import SnsSend from "components/common/_SnsSend.js";
import SnsSendList from "components/common/_SnsSendList.js";
import DiseaseAdd from "components/chart/_DiseaseAdd.js";

import { nvl, nvlNumber, convertGender, generateRandomNumber } from "utils/Common.utils.js";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

const CustomerRequestList = ({ list, customerInfo, managerInfo, getCustomerRequestList, setCurrentRequestSeq, setAlertPop, setRequestInput, setUpdateRequestDoctor }) => {
	const [requests, setRequests] = useState({});

	const onClickBookMark = ((e) => {
		const successHandler = (response) => {
			if (response.messageCode === 200) getCustomerRequestList();
			else console.log('Error >> ', response);
		}

		const params = {
			top_display_yn: requests[e.target.id].book_mark ? 'N' : 'Y',
			modi_manager_seq: managerInfo.seq
		}

		request.put(`/api/user/${customerInfo.user_seq}/request/${e.target.id}`, params, successHandler);
	});

	const onClickModify = ((e) => {
		setRequestInput(requests[e.target.id].content)
		setCurrentRequestSeq(e.target?.id)
		setUpdateRequestDoctor(requests[e.target.id].director_seq)
	});

	const onClickDelete = ((e) => {
		const successHandler = (response) => {
			if (response.messageCode === 200) getCustomerRequestList();
			else console.debug('Error >> ', response);
		}

		setAlertPop((prev) => {
			return {
				...prev,
				visible: true,
				id: e.target.id,
				alertMessage: '선택한 요청사항을 삭제하시겠습니까?',
				alertType: 'confirm',
				returnValue: (value) => {
					if (nvl(value) === 'OK') {
						request.del(`/api/user/${customerInfo.user_seq}/request/${e.target.id}/${managerInfo.seq}`, null, successHandler);
					}
				}
			}
		});
	});

	useEffect(() => {
		let temp = {};

		list.forEach(element => {
			temp[element.request_seq] = {
				book_mark: element.book_mark,
				readOnly: true,
				content: element.content,
				director_seq: element.director_seq
			}
		})

		setRequests(temp);

		return () => { // list가 바뀌기 전 초기화

		}
	}, [list])

	return (
		list?.map((item) => {
			return (
				<li key={"itemp_" + uuidv4()} className="request_item">
					<hgroup>
						<span className="date">{moment(item.reg_date).format("YYYY, MM, DD")}</span>
						<div className="btn_group">
							{/* icon_bookmark -> yellow(on) / darkgray(off) */}
							<i key={item.request_seq + '_bookMark'} className={requests[item.request_seq]?.book_mark ? "svg_icon icon_bookmark on" : "svg_icon icon_bookmark off"}
								id={item.request_seq} onClick={onClickBookMark}>&nbsp;</i>
							<i key={item.request_seq + '_write'} className={requests[item.request_seq]?.readOnly ? "svg_icon icon_write darkgray" : "svg_icon icon_write yellow"}
								id={item.request_seq} onClick={onClickModify}>&nbsp;</i>
							<i key={item.request_seq + '_del'} className="svg_icon icon_del_x darkgray" id={item.request_seq} onClick={onClickDelete}>&nbsp;</i>
						</div>
					</hgroup>
					<div className="txt_wrap">{requests[item.request_seq]?.content}</div>
				</li>
			)
		})
	)
}

const ChartLeft = ({ managerInfo, displayScreen, lnbSearchOptions, setLnbSearchOptions, userData, setUserData, setPointStatus, todayOrders, orderInfo, getSelectedCustomerInfo, popup, setPopup, getReceptionList, diseaseData, setDiseaseData, changeUserHandler, pointData, couponData }) => {
	const stateCustomer = useSelector(state => state.Customer); // 고객정보
  const stateToday = useSelector(state => state.Today)
	const uploadFileRef = useRef();
	const [currentRequestSeq, setCurrentRequestSeq] = useState(0);
	const [imgBase64, setImgBase64] = useState();
	const [diseaseList, setDiseaseList] = useState([]);
	const [goodsList, setGoodsList] = useState([]);
	let customerInfo = stateCustomer;

	const [alertPop, setAlertPop] = useState({
		visible: false,
		alertMessage: '',
		alertType: 'alert',
		returnValue: () => { },
		id: '',
	});

	const [visible, setVisible] = useState({ today: false, icd: false, request: false, photos: false });
	// [좌측 상단] '고객 정보' >> 메모, 동의서, 포인트, 쿠폰, 예약하기 팝업창  [좌측 하단] '사진' >> 개별사진, 사진등록 팝업창 
	const [date, setDate] = useState({ yyyyMMdd: '', day: '' });
	const [doctor, setDoctor] = useState('');
	const [selectedDoctor, setSelectedDoctor] = useState('')
	const [updateDoctor, setUpdateDoctor] = useState('')
	const [requestDoctor, setRequestDoctor] = useState('')
	const [updateRequestDoctor, setUpdateRequestDoctor] = useState('')
	const [requestList, setRequestList] = useState([]);
	const [requestInput, setRequestInput] = useState('');
	const [photoList, setPhotoList] = useState([]);
	const [addedPhotoList, setAddedPhotoList] = useState([]);
	const [todaySeq, setTodaySeq] = useState('')
	const [todayData, setTodayData] = useState([]);
	const [updateTodayData, setUpdateTodayData] = useState([]);
	// const [totalPrice, setTotalPrice] = useState(0);
	const [pagination, setPagiNation] = useState({
		eachPageLimit: 4,
		totalPhoto: 0, // photoList length
		currentPage: 1,
		pageCount: 0 // Math.Ceil(totalPages/eachPageLimit)
	});

	const [paginationDetail, setPagiNationDetail] = useState({
		eachPageLimit: 2,
		totalPhoto: 0,
		currentPage: 1,
		pageCount: 0
	});

	const [procedures, setProcedures] = useState([{ goods_seq: '', disease_seq: [''] }]);
	// const [diseaseData, setDiseaseData] = useState([]); // 당일 환자 질병코드 정보 => added by wem 20230915 chartRight에서 사용하기 위해 state 상위 컴포넌트로 이동

	const closeCustomAlert = (() => {
		setAlertPop((prev) => { return { ...prev, visible: false } })
	});

	const onToggle = ((e) => {
		if (nvlNumber(stateCustomer.user_seq) > 0) setVisible((prev) => { return { ...prev, [e.target.id]: !prev[e.target.id] } })
		else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
	});

	const onPopupClick = ((e) => {
		if (nvlNumber(stateCustomer.user_seq) > 0) setPopup((prev) => { return { ...prev, [e.target.id]: true } })
		else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
	});

	const onPhotoClick = ((e) => {
		// console.log(e.target.id)
		const mainTcSeq = nvlNumber(e.target.id.split("|")[0])
		const photoPosition = e.target.id.split("|")[1]

		// 클릭한 사진의 회차 셋트 찾기(before-after 사진 셋트는 회차를 이용할 것) 2023.01.16
		for (let i = 0; i < addedPhotoList.length; i = i + 2) {
			if (addedPhotoList[i].main_tc_seq === mainTcSeq && addedPhotoList[i].photo_position === photoPosition) {
				setPopup((prev) => { return { ...prev, photoDetail: true } });
				setPagiNationDetail((prev) => {
					return {
						...prev,
						currentPage: parseInt(photoPosition, 10)
					}
				})

				return;
			}
		}
	});

	const onClosePop = ((componentName) => {
		setPopup({ ...popup, [componentName]: false });

		if (nvl(componentName) === 'point') setPointStatus(generateRandomNumber)
	});

	const changeTodayChart = (e, num) => {
		const { name, value } = e.target;
		num === 0 ? setTodayData((prev) => ({ ...prev, [name]: value })) : setUpdateTodayData({ ...updateTodayData, [name]: value });
	}

	const saveTodayChart = (() => {
		if (nvl(todaySeq) !== "") { //수정
			const successHandler = (response) => {
				if (response.messageCode === 200) {
					setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '차트를 수정하였습니다.', alertType: 'alert' } })
					getTodayChart();
				} else console.debug('Error >> ', response);
			}

			const params = {
				manager_seq: updateTodayData.manager_seq,
				today_content: updateTodayData.today_content,
				modi_manager_seq: managerInfo.seq
			}

			if (nvlNumber(stateCustomer.user_seq) > 0) request.put(`/api/charts/today2?user_seq=${customerInfo.user_seq}&today=${stateToday}`, params, successHandler);
			else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
		} else {
			const successHandler = (response) => {
				if (response.messageCode === 200) {
					setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '차트를 등록하였습니다.', alertType: 'alert' } })
					getTodayChart();
				} else console.debug('Error >> ', response);
			}

			const params = {
				order_seq: orderInfo.order_seq,
				user_seq: stateCustomer.user_seq,
				manager_seq: todayData.manager_seq,
				today_content: todayData.today_content,
				reg_manager_seq: managerInfo.seq
			}

			if (nvlNumber(stateCustomer.user_seq) > 0) request.post(`/api/charts/today2`, params, successHandler);
			else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
		}
	});

	const getTodayChart = (() => {
		const successHandler = (response) => {
			if (response.messageCode === 200) {
				if (nvl(response.data.dataList[0]) === "") {
					setTodayData([]);
					setUpdateTodayData([]);
				} else {
					setUpdateTodayData(response.data.dataList[0])
					setTodaySeq(response.data.dataList[0]?.today_seq)
				}
			}
			else console.log('Error >> ', response);
		}

		request.get(`/api/charts/today2?user_seq=${customerInfo.user_seq}&today=${stateToday}`, null, successHandler);
	});

	const getCustomerRequestList = (() => {
		const successHandler = (response) => {
			if (response.messageCode === 200) setRequestList(response.data)

			else console.log('Error >> ', response);
		}

		request.get(`/api/user/${stateCustomer.user_seq}/request`, null, successHandler);
	});

	const saveCustomerRequest = (() => {
		if (nvlNumber(currentRequestSeq) > 0) { // 수정      
			const successHandler = (response) => {
				if (response.messageCode === 200) {
					// setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '요청사항 수정완료.', alertType: 'alert' } })

					setCurrentRequestSeq(0);
					setRequestInput('');

					getCustomerRequestList();
				} else console.log('Error >> ', response);
			}


			request.put(`/api/user/${stateCustomer.user_seq}/request/${currentRequestSeq}`, { content: requestInput, director_seq: updateRequestDoctor, modi_manager_seq: managerInfo.seq }, successHandler);
		} else { // 등록
			const successHandler = (response) => {
				if (response.messageCode === 200) {
					// setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '요청사항 등록완료.', alertType: 'alert' } })

					setCurrentRequestSeq(0);
					setRequestInput('');

					getCustomerRequestList();
				} else console.log('Error >> ', response);
			}

			const params = {
				order_seq: orderInfo.order_seq,
				director_seq: requestDoctor,
				content: requestInput,
				reg_manager_seq: managerInfo.seq,
				reg_date: moment().format("YYYY-MM-DD")
			}

			request.post(`/api/user/${stateCustomer.user_seq}/request`, params, successHandler);
		}
	});

	const clickLeftPage = (() => {
		let currentPage;
		setPagiNation((prev) => {
			currentPage = prev.currentPage - 1 === 0 ? prev.pageCount : prev.currentPage - 1
			return {
				...prev,
				currentPage: currentPage,
			}
		});
	});

	const clickRightPage = (() => {
		let currentPage;

		setPagiNation((prev) => {
			currentPage = prev.currentPage + 1 > prev.pageCount ? 1 : prev.currentPage + 1;
			return {
				...prev,
				currentPage: currentPage,
			}
		});
	});

	const changeDoctor = (e, num, cate) => {
		const { value } = e.target;
		if (cate === "disease") {
			num === 0 ? setSelectedDoctor(value) : setUpdateDoctor(value);
		} else if (cate === "request") {
			num === 0 ? setRequestDoctor(value) : setUpdateRequestDoctor(value);
		}
	}


	// common_code table 관리자 직무 : 관리자 직무 카테고리 > 담당의
	const getDoctorList = () => {
		const successHandler = (response) => {
			if (response.messageCode === 200) {
				setDoctor(response.data);
				// setSelectedDoctor(nvl(response.data[0]?.manager_seq));

				getCustomerRequestList();
			} else {
				console.debug('Error >> ', response);
			}
		}

		request.get(`/api/manager?assignment_position=010003000`, null, successHandler);
	}

	useEffect(() => {
		const today = new Date();
		const yyyyMMdd = new Intl.DateTimeFormat('kr').format(today);
		const options = { weekday: 'short' };
		const day = new Intl.DateTimeFormat('kr', options).format(today.getDay()); // Thu

		setDate({ yyyyMMdd: yyyyMMdd.replaceAll('.', ',').substring(0, yyyyMMdd.length - 1), day: day });

		// getDoctorList();

		return () => { // 컴포넌트가 화면에서 사라졌을 때 초기화
			setRequestList([]);
			setDoctor([]);
		}
	}, [])

	// 빈 이미지 추가하기
	const addEmptyImage = (result) => {
		const addArray = [];

		const beforeImage = result.filter(item => item.before_after === "B")
		const afterImage = result.filter(item => item.before_after === "A")

		if (beforeImage.length > afterImage.length) {
			beforeImage.forEach(before => {
				if (!afterImage.some(after => after.photo_position === before.photo_position)) {
					addArray.push({
						before_after: "A",
						photo_position: before.photo_position,
						main_tc_seq: before.main_tc_seq
					})
				}
			})
		} else {
			afterImage.forEach(after => {
				if (!beforeImage.some(before => before.photo_position === after.photo_position)) {
					addArray.push({
						before_after: "B",
						photo_position: after.photo_position,
						main_tc_seq: after.main_tc_seq
					})
				}
			})
		}
		return addArray

	}

	// 사용자 사진 가져오기
	const getUserPhotoList = () => {
		const successHandler = (response) => {
			if (response.messageCode === 200) {
				const result = response.data;
				// const photoListLength = result.length;
				setPhotoList(result);

				const combinedList = [...result, ...addEmptyImage(result)]
				// 재정렬
				combinedList.sort((a, b) => {
					// photo_position, before_after로 정렬
					if (a.photo_position < b.photo_position) return -1;
					if (a.photo_position > b.photo_position) return 1;
					if (a.before_after === 'B' && b.before_after === 'A') return -1;
					if (a.before_after === 'A' && b.before_after === 'B') return 1;

					return 0;
				});
				setAddedPhotoList(combinedList);
				setPagiNation((prev) => { return { ...prev, totalPhoto: combinedList.length, pageCount: Math.ceil(combinedList.length / prev.eachPageLimit) } })
				setPagiNationDetail((prev) => { return { ...prev, totalPhoto: combinedList.length, pageCount: Math.ceil(combinedList.length / prev.eachPageLimit) } })
			} else {
				console.debug('Error >> ', response);
			}
		}

		request.get(`/api/photos/${stateCustomer.user_seq}`, null, successHandler);
	}

	useEffect(() => {
		getUserPhotoList();
		getTodayChart(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateCustomer.user_seq, setUpdateTodayData])


	// 첨부파일 등록
	const selectUploadFile = () => {
		if (nvlNumber(stateCustomer.user_seq) === 0) setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '고객 선택 후 사용이 가능합니다.', alertType: 'alert' } })
		else uploadFileRef.current.click();
	}

	const uploadFileHandler = (e) => {
		if (e.target.files[0]) {

			let reader = new FileReader();
			reader.readAsDataURL(e.target.files[0]);

			reader.onloadend = () => {
				const base64 = reader.result;
				if (base64) setImgBase64(base64.toString());
			}
		}

		const formData = new FormData();

		formData.append("uploadFile", e.target.files[0])
		formData.append("file_cate", "userPhoto")
		formData.append("user_seq", nvlNumber(stateCustomer?.user_seq))
		formData.append("manager_seq", nvlNumber(managerInfo.seq))

		const successHandler = (response) => {

			if (response.messageCode === 200) {

			} else setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 형식의 파일이 있습니다." } })
		}

		const failHandler = (result) => {
			setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: "파일 첨부중 에러가 발생했습니다." } })
		}

		uploadFileRef.current.value = "";

		request.postMultipart("/api/upload/dash/user", formData, successHandler, failHandler);
	}

	const addProcedure = (num) => {
		num === 0 ? setProcedures([...procedures, { goods_seq: '', disease_seq: [''] }]) : setDiseaseData([...diseaseData, { goods_seq: '', disease_seq: [''] }]);
	}

	const addDisease = (pi, num) => {
		if (num === 0) {
			setProcedures((prev) => {
				const updatedProcedures = [...prev];
				updatedProcedures[pi] = { ...updatedProcedures[pi], disease_seq: [...updatedProcedures[pi].disease_seq, ''] };
				return updatedProcedures;
			});
		} else {
			setDiseaseData((prev) => {
				const updatedProcedures = [...prev];
				updatedProcedures[pi] = { ...updatedProcedures[pi], disease_seq: [...updatedProcedures[pi].disease_seq, ''] };
				return updatedProcedures;
			});
		}
	}

	const removeProcedure = (index, num) => {
		if (num === 0) {
			setProcedures((prev) => {
				const updatedProcedures = [...prev];
				updatedProcedures.splice(index, 1);
				return updatedProcedures;
			});
		} else {
			setDiseaseData((prev) => {
				const updatedProcedures = [...prev];
				updatedProcedures.splice(index, 1);
				return updatedProcedures;
			});
		}
	};


	const removeDisease = (num, pi) => {
		if (num === 0) {
			setProcedures((prev) => {
				const updatedProcedures = [...prev];
				const updatedDiseases = [...updatedProcedures[pi].disease_seq];
				updatedDiseases.splice(0, 1);
				updatedProcedures[pi] = { ...updatedProcedures[pi], disease_seq: updatedDiseases };
				return updatedProcedures;
			});
		} else {
			setDiseaseData((prev) => {
				const updatedProcedures = [...prev];
				const updatedDiseases = [...updatedProcedures[pi].disease_seq];
				updatedDiseases.splice(0, 1);
				updatedProcedures[pi] = { ...updatedProcedures[pi], disease_seq: updatedDiseases };
				return updatedProcedures;
			});
		}
	};

	const getGoods = () => {

		const successHandler = (response) => {
			if (response.messageCode === 200) {
				setGoodsList(response.data.dataList);
				// getSurgeryDiseaseCode();
			} else {
				console.log(`상품목록을 조회하지 못했습니다.`)
			}
		}

		request.get(`/api/disease/surgeryList/${stateCustomer.user_seq}/${stateToday}`, null, successHandler);
	};


	const getDiseaseCodeList = () => {

		const successHandler = (response) => {
			if (response.messageCode === 200) {
				setDiseaseList(response.data);
			} else {
				console.log("질병코드 목록을 가져오지 못했습니다.")
			}
		}

		const failHandler = (response) => {
			console.log('에러' + response);
		}

		request.get("/api/disease?use_yn=Y", null, successHandler, failHandler, null, null);
	}

	useEffect(() => {
		getTodayChart();
		getGoods();
		getDoctorList();
		getDiseaseCodeList(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/* Chart.js 150 line에 stateCustomer.user_seq가 변경될 때마다 호출하는 로직에 합치면서 주석 처리 => 동일 API 반복 호출은 막음. 추후 에러 발생시 별도 확인 필요  -added by wem 20230818 */

	// useEffect(() => {
	// 	if (nvlNumber(stateCustomer.user_seq) > 0) {
	// 		setTodayOrders([])

	// 		getTodayOrderInfo(stateCustomer.user_seq);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [stateCustomer.user_seq])

	const diseaseHandler = (e, num, pi, di) => {
		// console.log(num, procedures, diseaseData )
		const { value } = e.target;
		let updatedProcedures;

		num === 0 ? updatedProcedures = [...procedures] : updatedProcedures = [...diseaseData];

		if (nvl(di) === '') {
			updatedProcedures[pi].goods_seq = Number(value);
			setProcedures(updatedProcedures);
		} else {
			updatedProcedures[pi].disease_seq[di] = Number(value);
			setProcedures(updatedProcedures);
		}
	};

	const saveSurgeryDiseaseCode = () => {
		const params = {
			procedures: procedures.map(procedure => ({
				goods_seq: procedure.goods_seq,
				disease_seq: procedure.disease_seq,
				user_seq: stateCustomer.user_seq,
				manager_seq: selectedDoctor,
				reg_manager_seq: managerInfo.seq
			}))
		}

		const filteredProcedures = procedures.filter(item => {
			return item.goods_seq === "" || item.disease_seq.includes("");
		});


		if (filteredProcedures.length === 0) {
			const successHandler = (response) => {

				if (response.messageCode === 200) {
					setAlertPop((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `질병코드를 등록하였습니다.` } });;
					getSurgeryDiseaseCode();
				} else {
					console.log(`질병코드를 등록하지 못했습니다.`)
				}
			}

			request.post("/api/disease/surgery", params, successHandler);
		} else {
			setAlertPop((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `시술이나 질병코드를 선택해주세요.` } });;
		}

	};

	const getSurgeryDiseaseCode = () => {
		const successHandler = (response) => {
			if (response.messageCode === 200) {
				const result = response.data.dataList;
				let tempArr = [];

				if (result.length !== 0) {
					result.forEach((item) => {
						const findItem = tempArr.find((element) => element.goods_seq === item.goods_seq);
						if (findItem) {
							findItem.disease_seq.push(item.disease_seq);
						} else {
							tempArr.push({
								goods_seq: item.goods_seq,
								disease_seq: [item.disease_seq],
								reg_date: item.reg_date,
								manager_seq: item.manager_seq,
								reg_manager_seq: item.reg_manager_seq
							});
							setUpdateDoctor(item.manager_seq);
						}
					});
				}

				setDiseaseData(tempArr);
			} else {
				console.log(`시술&질병코드를 조회하지 못했습니다.`)
			}
		}

		request.get(`/api/disease/surgery/${stateCustomer.user_seq}/${stateToday}`, null, successHandler);
	}


	const updateSurgeryDiseaseCode = () => {
		const params = {
			procedures: diseaseData.map(el => ({
				goods_seq: el.goods_seq,
				disease_seq: el.disease_seq,
				user_seq: stateCustomer.user_seq,
				reg_date: moment(el.reg_date).format("YYYY-MM-DD HH:mm:ss"),
				manager_seq: updateDoctor,
				reg_manager_seq: nvl(el.reg_manager_seq) === "" ? managerInfo.seq : el.reg_manager_seq,
				modi_manager_seq: managerInfo.seq
			}))
		};
		console.log(params)

		const successHandler = (response) => {

			if (response.messageCode === 200) {
				setAlertPop((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `질병코드를 수정하였습니다.` } });;
			} else {
				console.log(`질병코드를 수정하지 못했습니다.`)
			}
		}

		request.put(`/api/disease/surgery/${stateCustomer.user_seq}/${params.reg_date}`, params, successHandler);
	}

	useEffect(() => {
		getGoods();
		getSurgeryDiseaseCode(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setDiseaseData, stateCustomer.user_seq])


	return (
		<>
			<div className="com_content__inner">
				{
					displayScreen?.customer_display_yn === 'Y' &&
					<section className="com_sec pd20">
						<article className="com_user_box">
              <div className={`color ${nvl(userData?.order_count) === "" || userData?.order_count <= 1 ? "first" : "second"}`}>&nbsp;</div>
							<div className="pic" onClick={selectUploadFile} style={{ cursor: "pointer" }}>
								<label htmlFor="user_photo" style={{ cursor: "pointer" }} >
									{
										nvl(userData?.user_photo_path) === '' ?
											nvl(imgBase64) !== '' && <img key={imgBase64} src={imgBase64} alt="미리보기" style={{ width: "74px" }} />
											:
											nvl(imgBase64) !== '' ?
												<img key={imgBase64} src={imgBase64} alt="미리보기" style={{ width: "74px" }} />
												:
												nvl(userData?.user_photo_path) !== '' &&
												<img src={`${process.env.REACT_APP_API_URL}/api/upload/viewer/image/photo/${userData?.user_photo_path.split("/")[userData?.user_photo_path.split("/").length - 2]}_${userData?.user_photo_path.split("/")[userData?.user_photo_path.split("/").length - 1]}`}
													alt="" style={{ width: "74px" }} />
									}
									<input type="file" id="uploadFile" name="files" className="input" ref={uploadFileRef} style={{ display: "none" }} onChange={uploadFileHandler} />
								</label>
							</div>
							<div className="btns_wrap">
								<button type="button" className="btn" id="send" onClick={() => setLnbSearchOptions(prev => { return { ...prev, send: true } })}><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
								<button type="button" className="btn" id="sent" onClick={() => setLnbSearchOptions(prev => { return { ...prev, sent: true, userData: userData } })}><i className="svg_icon icon_list yellow">&nbsp;</i></button>
							</div>
							<div className="num">{customerInfo.chart_code}</div>
							<div className="age_phone" style={{ background: `${userData.user_level_color}` }} onClick={() => getSelectedCustomerInfo('popup')} >
								<h1 className="age">{nvl(customerInfo.user_name) === "" ? "" : `${customerInfo.user_name}(${convertGender(customerInfo.user_gender)}/${customerInfo.user_age})`}</h1>
								<h2 className="phone">{customerInfo.mobile}</h2>
							</div>
							<button id='memo' type="button" className="btn_memo com_btn m icon_vertical skyblue_bg txt_blue" onClick={onPopupClick}>
								<i className="svg_icon icon_write blue">&nbsp;</i>메모
							</button>
						</article>
						<article className="com_user_box_btns">
							<div className="btns_wrap">
								<button id='consent' type="button" className="com_btn m line blue" onClick={onPopupClick}>동의서</button>
								<button id='point' type="button" className="com_btn m line blue" onClick={onPopupClick}>포인트 정보</button>
								<button id='coupon' type="button" className="com_btn m line blue" onClick={onPopupClick}>쿠폰 정보</button>
							</div>
							<button id='reservation' type="button" className="com_btn m red" onClick={onPopupClick}>예약하기</button>
						</article>
					</section>
				}
				{/* //^ Today Chart */}
				{/* FIXME: type_more 이 있는 경우 active 클래스로 on/off 제어 */}
				{
					displayScreen?.todaychart_display_yn === 'Y' ?
						<section className={visible.today ? 'com_sec type_more sec_today_chart active' : 'com_sec type_more sec_today_chart'}>
							<hgroup className="com_sec__hgroup">
								<h1 className="com_sec__title">Today Chart<b className="txt_blue">{`${date.yyyyMMdd} (${date.day})`}</b></h1>
								<select className="select" name="manager_seq" onChange={(e) => nvl(todaySeq) === "" ? changeTodayChart(e, 0) : changeTodayChart(e, 1)} value={nvl(todaySeq) === "" ? todayData?.manager_seq || "" : updateTodayData?.manager_seq || ""}>
									<option value="">담당의 선택</option>
									{
										doctor != null && doctor.length > 0 ?
											doctor.map((item) => { return (<option key={item.manager_seq} value={item.manager_seq}>{item.manager_name}</option>) })
											: null
									}
								</select>
							</hgroup>
							<article className="more_cont_box">
								<textarea className="input" placeholder="내용을 입력하세요. (500자)" name="today_content" value={nvl(todaySeq) === "" ? todayData?.today_content || "" : updateTodayData?.today_content || ""} onChange={(e) => nvl(todaySeq) === "" ? changeTodayChart(e, 0) : changeTodayChart(e, 1)}></textarea>
								<button type="button" className="com_btn blue m w120 btn_save" onClick={saveTodayChart}>{nvl(todaySeq) === "" ? "저장" : "수정"}</button>
							</article>
							<button id='today' type="button" className="btn_more" onClick={onToggle}>&nbsp;</button>
						</section> : null
				}
				{/*//^질병코드 입력 0516*/}
				{displayScreen?.todaychart_display_yn === 'Y' ?
					<section className={visible.icd ? 'com_sec type_more sec_disease active' : 'com_sec type_more sec_disease'} >
						<hgroup className="com_sec__hgroup">
							<h1 className="com_sec__title">질병코드 입력</h1>
							{/*0516 질병코드관리 버튼 추가*/}
							<button className="com_btn blue m" style={{ marginRight: "1%" }} id="diseaseAdd" onClick={onPopupClick}><i className="svg_icon icon_set yellow">&nbsp;</i>질병코드 관리</button>
							<select name='doctor' className="select" onChange={(e) => { diseaseData.length === 0 ? changeDoctor(e, 0, "disease") : changeDoctor(e, 1, "disease") }} value={diseaseData.length === 0 ? selectedDoctor : updateDoctor || ""}>
								<option value="">담당의 선택</option>
								{
									doctor != null && doctor.length > 0 ?
										doctor.map((item) => { return (<option key={item.manager_seq} value={item.manager_seq}>{item.manager_name}</option>) })
										: null
								}
							</select>
						</hgroup>
						<article className="more_cont_box">
							{(diseaseData.length === 0 ? procedures : diseaseData)?.map((procedure, procedureIndex) => (
								<div key={procedureIndex}>
									<div className="select_wrap">
										<select className="select" name="goods_seq" value={diseaseData.length === 0 ? procedures?.goods_seq : diseaseData[procedureIndex]?.goods_seq || ""} onChange={(e) => diseaseData.length === 0 ? diseaseHandler(e, 0, procedureIndex) : diseaseHandler(e, 1, procedureIndex)}>
											<option value="">시술 선택</option>
											{(diseaseData.length === 0 ? todayOrders : goodsList)?.map((el, i) => (
												<option value={el.goods_seq} key={i}>{el.goods_name}</option>
											))}
										</select>
										<button className="com_btn icon m" onClick={() => diseaseData.length === 0 ? removeProcedure(procedureIndex, 0) : removeProcedure(procedureIndex, 1)} >
											<i className="svg_icon icon_minus">&nbsp;</i>
										</button>
									</div>
									{procedure?.disease_seq?.map((disease, diseaseIndex) => (
										<div className="select_wrap disease" key={diseaseIndex}>
											<select className="select" name="diseaseCode" value={diseaseData.length === 0 ? (nvl(procedures[procedureIndex].disease_seq[diseaseIndex]) === "" ? "" : procedures[procedureIndex].disease_seq[diseaseIndex]) || "" : nvl(diseaseData[procedureIndex]?.disease_seq[diseaseIndex]) === "" ? "" : diseaseData[procedureIndex]?.disease_seq[diseaseIndex] || ""} onChange={(e) => diseaseData.length === 0 ? diseaseHandler(e, 0, procedureIndex, diseaseIndex) : diseaseHandler(e, 1, procedureIndex, diseaseIndex)} >
												<option value="">질병코드 선택</option>
												{diseaseList?.map((el, i) => (
													<option value={el.disease_seq} key={i}>{`${el.disease_code} ${el.disease_name}`}</option>
												))}
											</select>
											{procedure?.disease_seq.length === 1 ? null :
												<button className="com_btn icon m" onClick={() => diseaseData.length === 0 ? removeDisease(0, procedureIndex) : removeDisease(1, procedureIndex)} >
													<i className="svg_icon icon_minus">&nbsp;</i>
												</button>}
											<button className="com_btn icon m" onClick={() => diseaseData.length === 0 ? addDisease(procedureIndex, 0) : addDisease(procedureIndex, 1)}>
												<i className="svg_icon icon_plus">&nbsp;</i>
											</button>
										</div>
									))}
								</div>
							))}
							<div className="btn_bottom">
								<button className="com_btn blue m" onClick={() => diseaseData.length === 0 ? addProcedure(0) : addProcedure(1)} style={{ marginRight: "1%" }} >시술 추가</button>
								<button className="com_btn blue m division" style={{ marginLeft: "1%" }} onClick={diseaseData.length === 0 ? saveSurgeryDiseaseCode : updateSurgeryDiseaseCode} >{diseaseData.length === 0 ? "저장" : "수정"}</button>
							</div>
						</article>
						<button id='icd' type="button" className="btn_more" onClick={onToggle}>&nbsp;</button>
					</section> : null
				}
				{ // 요청사항
					displayScreen?.cust_request_display_yn === 'Y' ?
						<section className={visible.request ? 'com_sec type_more sec_request active' : 'com_sec type_more sec_request'}>
							<hgroup className="com_sec__hgroup">
								<h1 className="com_sec__title">요청사항<b className="txt_blue">{`${date.yyyyMMdd} (${date.day})`}</b></h1>
								<select name='doctor_list' className="select" onChange={(e) => { nvlNumber(currentRequestSeq) === 0 ? changeDoctor(e, 0, "request") : changeDoctor(e, 1, "request") }} value={nvlNumber(currentRequestSeq) === 0 ? requestDoctor : updateRequestDoctor || ""}>
									<option value="">원장님 선택</option>
									{
										doctor != null && doctor.length > 0 ?
											doctor.map((item) => { return (<option key={item.manager_seq} value={item.manager_seq}>{item.manager_name}</option>) })
											: null
									}
								</select>
							</hgroup>
							<article className="more_cont_box">
								<div className="com_textarea_in_btn">
									<textarea className="input" placeholder="내용을 입력하세요. (500자)" value={requestInput} onChange={(e) => setRequestInput(e.target.value)}></textarea>
									<button type="button" className="com_btn blue m w120 btn_save" onClick={saveCustomerRequest}>{nvlNumber(currentRequestSeq) > 0 ? '수정' : '저장'}</button>
								</div>
								<ul className="sec_request__list scroll">
									<CustomerRequestList list={requestList} customerInfo={customerInfo} managerInfo={managerInfo} getCustomerRequestList={getCustomerRequestList}
										setCurrentRequestSeq={setCurrentRequestSeq} setAlertPop={setAlertPop} setRequestInput={setRequestInput} setUpdateRequestDoctor={setUpdateRequestDoctor} />
								</ul>
							</article>
							<button id='request' type="button" className="btn_more" onClick={onToggle}>&nbsp;</button>
						</section>
						: null
				}
				{/* //^ 사진 */}
				{
					displayScreen?.photo_disaplay_yn === 'Y' ?
						<section className={visible.photos ? 'com_sec type_more sec_photo active' : 'com_sec type_more sec_photo'}>
							<hgroup className="com_sec__hgroup space-between">
								<h1 className="com_sec__title">{`${'사진'}(${photoList.length})`}</h1>
								<button id="photo" className="hidden" onClick={onPopupClick} /><label className="com_btn txt non_under" htmlFor="photo"><i className="svg_icon icon_photo yellow">&nbsp;</i><b>사진 등록</b></label>
							</hgroup>
							<article className="more_cont_box com_photo">
								<button className="btn_prev" onClick={clickLeftPage}><i className="svg_icon icon_prev">&nbsp;</i></button>
								<ul className="slide" onClick={onPhotoClick}>
									{
										addedPhotoList.slice(pagination.eachPageLimit * (pagination.currentPage - 1), pagination.eachPageLimit * (pagination.currentPage))?.map((item, index) => {
											return (<li key={index + "photo"} id={`${item.main_tc_seq}|${item.photo_position}`} className="img" style={item.file_path && { backgroundImage: `url(${process.env.REACT_APP_API_URL}/api/upload/viewer/image/album/${item.file_path.split("/")[item.file_path.split("/").length - 2]}_${item.file_path.split("/")[item.file_path.split("/").length - 1]})` }}>{/* {item.photo_name} */}</li>);
										})
									}
								</ul>
								<button className="btn_next" onClick={clickRightPage}><i className="svg_icon icon_next">&nbsp;</i></button>
							</article>
							<button id='photos' type="button" className="btn_more" onClick={onToggle}>&nbsp;</button>
						</section>
						: null
				}{popup.customerCard && <CustomCard onClose={() => onClosePop('customerCard')} userData={userData} getReceptionList={getReceptionList} setLnbSearchOptions={setLnbSearchOptions} pointData={pointData} couponData={couponData}/>}
				{popup.memo && <MemoCard onClose={() => { onClosePop('memo') }} />}
				{popup.consent && <ConsentList userData={userData} onClose={() => { onClosePop('consent') }} />}
				{popup.point && <PointManage onClose={() => { onClosePop('point') }} />}
				{popup.coupon && <CouponManage onClose={() => { onClosePop('coupon') }} userData={userData} setUserData={setUserData} />}
				{popup.reservation && <ReservationAdd onClose={() => { onClosePop('reservation') }} userData={userData} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler}/>}
				{popup.photo && <PicAdd onClose={() => { onClosePop('photo') }} getUserPhotoList={getUserPhotoList} photoList={photoList} customerInfo={customerInfo} />}
				{popup.photoDetail && <PicDetail onClose={() => { onClosePop('photoDetail'); }} paginationDetail={paginationDetail} setPagiNationDetail={setPagiNationDetail} addedPhotoList={addedPhotoList} />}
				{/* {popup.medicalFeeManage && <MedicalFeeManage onClose={() => { onClosePop('medicalFeeManage') }}/> } */}
				{lnbSearchOptions.send && <SnsSend setLnbSearchOptions={setLnbSearchOptions} />}
				{lnbSearchOptions.sent && <SnsSendList setLnbSearchOptions={setLnbSearchOptions} lnbSearchOptions={lnbSearchOptions} />}
			</div>
			{alertPop.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertPop.alertType} alertMessage={alertPop.alertMessage} returnValue={alertPop.returnValue} />}
			{popup.diseaseAdd && <DiseaseAdd onClose={() => { onClosePop('diseaseAdd') }} getDiseaseCodeList={getDiseaseCodeList} diseaseList={diseaseList} />}{/*0518*/}
		</>
	)
}
export default ChartLeft