//*문자 미리보기
import React, { useRef } from "react";
import Draggable from "react-draggable";

const SnsSend = ({ onClose, selectMessage }) => {
  const nodeRef = useRef(null)
  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_sms_preview active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div className="pop_container" ref={nodeRef}>
            <div className="pop_body">
              <div className="scroll" onClick={() => { onClose() }} style={{ whiteSpace: "pre-line" }}>
                {selectMessage.msg_content}
              </div>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default SnsSend