//* 시술설명 231115 추가
import Draggable from "react-draggable";

const ProcedureDetail = (props) => {
  //  팝업창 닫기
  const popupClose = () => {
    props.procedureCategoryManageToggle()
  }
  return (
    <>
      {/* //* 일반 팝업 */}
      <section  className="com_popup type_normal pop_procedure_detail">{/*클래스 active추가시 노출*/}
        <Draggable >
          <div  className="pop_container l">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { popupClose() }}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">시술 설명</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <div className="com_write">
                <table className="write">
                  <colgroup><col width="130px"/><col/></colgroup>
                  <tbody>
                    <tr>
                      <th>시술명</th>
                      <td className="tit_field"><span className="bullet">이벤트</span>민감성 두피 캐어</td>
                    </tr>
                    <tr>
                      <th>시술 금액</th>
                      <td className="price_field"><span className="price_x">350,000</span>  → 110,000 → <span className="txt_red">100,000</span></td>
                    </tr>
                    <tr>
                      <th>시술 설명</th>
                      <td>이마 이외에 투약 금지.<br/>이마 이외에 투약 금지.<br/>이마 이외에 투약 금지.<br/>이마 이외에 투약 금지.<br/>이마 이외에 투약 금지.<br/>이마 이외에 투약 금지.<br/></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" >확인</button>
            </div>
          </div>
        </Draggable>
      </section>
    </>
  )
}

export default ProcedureDetail