// *관리>동의서관리>동의서 추가
import React, { useEffect, useState } from "react";
import request from '../../utils/Request.utils';
import Editor from '../smartEditor/Editor';
import CustomAlert from '../../atoms/common/CustomAlert';
import moment from 'moment';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { nvl, nvlNumber } from "../../utils/Common.utils";

const ConsentAdd = () => {
  moment.locale('ko-KR');

  const navigate = useNavigate();
  const location = useLocation();

  const now = new Date();
  const today = moment(now).format("YYYY년 MM월 DD일");

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [consentCategoryList, setConsentCategoryList] = useState([]);
  const [managerList, setManagerList] = useState([]);
    
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
    closeType: ''
  });

  const [agreementDocInfo, setAgreementDocInfo] = useState({
    agree_seq: 0,
    agree_cate: "",
    agree_name: "",
    agree_description: "",
    display_yn: "Y",
    agree_yn: "Y", // 이건 저장할 때 필요 없음
    remark: "",
    reg_manager_seq: managerInfo.seq
  });

  const getConsentCategory = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) { // ok 코드 값
        // console.log("response : " + JSON.stringify(response));

        setConsentCategoryList(response.data.dataList);
        getManagerList()
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서 카테고리 리스트를 가져오지 못했습니다." } })
    }

    request.get("/api/etc/commoncd/2/007", null, successHandler, failHandler, null, null);
  }

  const getManagerList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setManagerList(response.data);
      } else console.debug('Error >> ', response)
    }

    request.get("/api/manager", null, successHandler);
  }

  const getAgreementInfo = (agreeSeq) => {
    const successHandler = (response) => {

      console.log(agreeSeq, response.data[0].agree_cate)

      if (response.messageCode === 200) {
        setAgreementDocInfo((prev) => { return { ...prev, agree_seq: response.data[0].agree_seq, agree_cate: response.data[0].agree_cate, agree_name: response.data[0].agree_name, remark: response.data[0].remark } })
        setAgreementDocInfo((prev) => { return { ...prev, agree_description: response.data[0].agree_description, display_yn: response.data[0].display_yn, reg_manager_seq: response.data[0].reg_manager_seq } })
      } else console.debug('Error >> ', response)
    }

    request.get("/api/etc/agreements/S/" + agreeSeq, null, successHandler);
  }

  useEffect(() => {
    getConsentCategory();

    if(nvlNumber(location.state?.agree_seq) > 0) getAgreementInfo(location.state?.agree_seq)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agreementDocInfoBinding = ({ target: { id, value } }) => {
    // console.log(id + " | " + value)

    if(id === 'display_yes' || id === 'display_no') id = 'display_yn';
    else if(id === 'agreement_yes' || id === 'agreement_no') id = 'agree_yn';

    setAgreementDocInfo((prev) => { return { ...prev, [id]: value } })
  }

  const agreementWriteSubmit = () => {
    if(nvl(agreementDocInfo.agree_cate) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "카테고리를 선택해주세요." } })

      return;
    } else if(nvl(agreementDocInfo.agree_name) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서명을 입력해주세요." } })

      return;
    } else if(nvlNumber(agreementDocInfo.reg_manager_seq) < 1) {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "등록자를 선택해주세요." } })

      return;
    } else if(nvl(agreementDocInfo.display_yn) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "노출 여부를 선택해주세요." } })

      return;
    } else if(nvl(agreementDocInfo.agree_description) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "본문 내용을 입력해주세요." } })

      return;
    }

    // ----------------------------------

    if(nvlNumber(agreementDocInfo.agree_seq) > 0) setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "수정하시겠습니까?", activateMode: "agreementSubmit" } })
    else setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "등록하시겠습니까?", activateMode: "agreementSubmit" } })
  }

  const agreementRegistry = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서가 등록되었습니다.", closeType: 'next' } })
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서를 등록하던 중 에러가 발생했습니다." } })
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서를 등록하던 중 에러가 발생했습니다." } })
    }

    request.post("/api/etc/agreements", agreementDocInfo, successHandler, failHandler, null, null);
  }

  const agreementModify = (agreeSeq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서가 수정되었습니다.", closeType: '' } })
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서를 수정하던 중 에러가 발생했습니다." } })
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서를 수정하던 중 에러가 발생했습니다." } })
    }

    request.put(`/api/etc/agreements/${agreeSeq}/${managerInfo.seq}`, agreementDocInfo, successHandler, failHandler, null, null);
  }


  const agreementDeleteActivate = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "삭제하시겠습니까?", activateMode: "agreementDelete" } })
  }

  const agreementDelete = (agreeSeq) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서가 삭제되었습니다.", closeType: 'next' } })
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서를 삭제하던 중 에러가 발생했습니다." } })
    }

    const failHandler = (result) => {
      console.log("에라야 에라~~" + result);

      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "동의서를 삭제하던 중 에러가 발생했습니다." } })
    }

    request.del(`/api/etc/agreements/${agreeSeq}/${managerInfo.seq}`, agreementDocInfo, successHandler, failHandler, null, null);
  }

  const returnAlertValue = (value) => {
    if(nvl(value) === 'OK') {
      if(alertLayerPopup.activateMode === 'agreementSubmit') {
        if(nvlNumber(agreementDocInfo.agree_seq) > 0) agreementModify(agreementDocInfo.agree_seq);
        else agreementRegistry();
      } else if(alertLayerPopup.activateMode === 'agreementDelete') {
        if(nvlNumber(agreementDocInfo.agree_seq) > 0) agreementDelete(agreementDocInfo.agree_seq);
      }
    }
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "", activateMode: "", closeType: '' } })

    if(alertLayerPopup.closeType === "next") navigate("/boffice/consent");
  }

	return (
		<>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="lnb2_page_location">
            <Link to="/boffice/consent" title="동의서 관리"><h1 className="dep1">동의서 관리</h1></Link>
            <h2 className="dep2 txt_blue">동의서 추가</h2>
          </article>
        </div>
      </section>
      <section className="com_content p_consent_add">
        <div className="com_board_write">
          <table className="write">
            <colgroup><col width="8.5%"/><col/></colgroup>
            <tbody>
              <tr>
                <th>카테고리 선택</th>
                <td>
                  <select id="agree_cate" className="select" key={ agreementDocInfo.agree_cate } value={ agreementDocInfo.agree_cate } onChange={ agreementDocInfoBinding }>
                    <option value="">카테고리 선택</option>
                    {
                      (consentCategoryList == null || consentCategoryList.length < 1) ?
                        null
                      :
                        consentCategoryList.map((item, index) => (
                          item.code_level > 1 ?
                            <option key={ item.common_code } value={ item.common_code }>{ item.code_name }</option>
                          : null
                        ))
                    }
                  </select>
                </td>
              </tr>
              <tr>
                <th>동의서명 입력</th>
                <td><input type="text" id="agree_name" className="input" placeholder="동의서 명 입력 (30자)" maxLength={ 30 } onChange={ agreementDocInfoBinding } value={ agreementDocInfo.agree_name } /></td>
              </tr>
              <tr>
                <th>등록자 선택</th>
                <td>
                  <select className="select" id="reg_manager_seq" key={ agreementDocInfo.reg_manager_seq } value={ agreementDocInfo.reg_manager_seq } onChange={ agreementDocInfoBinding }>
                    <option value="0">등록자 선택</option>
                    {
                      (managerList == null || managerList.length < 1) ?
                        null
                      :
                      managerList.map((item, index) => (
                        <option key={ item.manager_seq } value={ item.manager_seq }>{ item.manager_name }</option>
                      ))
                    }
                  </select>
                </td>
              </tr>
              <tr>
                <th>노출 선택</th>
                <td>
                  <fieldset className="com_check_wrap">
                    <input type="radio" className="radio" id="display_yes" name="choiceDisplay" onChange={ agreementDocInfoBinding } checked={ agreementDocInfo.display_yn === "Y" } value="Y" />
                    <label className="btn_radio" htmlFor="display_yes">&nbsp;</label>
                    <label htmlFor="display_yes">노출</label>
                    <input type="radio" className="radio" id="display_no" name="choiceDisplay" onChange={ agreementDocInfoBinding } checked={ agreementDocInfo.display_yn === "N" } value="N" />
                    <label className="btn_radio" htmlFor="display_no">&nbsp;</label>
                    <label htmlFor="display_no">비노출</label>
                  </fieldset>
                </td>
              </tr>
            </tbody>
          </table>
          <article className="editor">
            <div className="editor" style={{ minHeight: '400px' }}>
              <Editor
                height={ 400 }
                data={ agreementDocInfo.agree_description }
                newContent={ (value) => setAgreementDocInfo((prev) => { return { ...prev, agree_description: value } }) }
              />
            </div>
            <div className="agree">
              <p className="txt">* 위내용에 대해 충분한 설명을 맏았고, 정확히 인지하여 아래 서명을 하였음</p>
              <fieldset className="com_check_wrap">
                <input type="radio" className="radio" id="agreement_yes" name="choiceAgreement" onChange={ agreementDocInfoBinding } checked={ agreementDocInfo.agree_yn === "Y" } value="Y" />
                <label className="btn_radio" htmlFor="agreement_yes">&nbsp;</label>
                <label htmlFor="agreement_yes">동의</label>
                <input type="radio" className="radio" id="agreement_no" name="choiceAgreement" onChange={ agreementDocInfoBinding } checked={ agreementDocInfo.agree_yn === "N" } value="N" />
                <label className="btn_radio" htmlFor="agreement_no">&nbsp;</label>
                <label htmlFor="agreement_no">비동의</label>
              </fieldset>
              <div className="info_field"><input className="input" type="text" placeholder={ today } readOnly/></div>
              <div className="info_field">
                <input className="input" type="text" placeholder="고객이름" readOnly/>
                <input className="input" type="text" placeholder="고객주민번호" readOnly/>
                <input className="input" type="text" placeholder="서명" readOnly/>
              </div>
            </div>
          </article>
          {
            nvlNumber(agreementDocInfo.agree_seq) > 0 ?
              <article className="btn_area">
                <button className="com_btn blue l" onClick={ agreementWriteSubmit }>저장</button>
                <button className="com_btn blue line l" onClick={ agreementDeleteActivate }>삭제</button>
              </article>
            :
              <article className="btn_area">
                <button className="com_btn blue l" onClick={ agreementWriteSubmit }>등록</button>
              </article>
          }
        </div>
      </section>
      { alertLayerPopup.visible && <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> }
		</>
	)
}

export default ConsentAdd