import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Gnb from '../layout/Gnb.js';
import Lnb from '../layout/Lnb.js';
import ProcedureOld from "../components/admin/Procedure_1115bak.js";//시술관리 - 수정 전
import Procedure from "../components/admin/Procedure.js";//시술관리
import ProcedureAdd from "../components/admin/ProcedureAdd.js";//시술등록
import ProcedureAddOld from "../components/admin/ProcedureAdd_1115bak.js";//시술등록
import Sms from "../components/admin/Sms.js";//문자관리
import SmsStats from "../components/admin/SmsStats.js";//문자관리 통계
import Drug from "../components/admin/Drug.js";//약품관리
import Consent from "../components/admin/Consent.js";//동의서 관리
import ConsentAdd from "../components/admin/ConsentAdd.js";//동의서 관리
import Coupon from "../components/admin/Coupon.js";//쿠폰 관리
import request from "utils/Request.utils.js";

const AdminRoute = () => {
  let { pathname } = window.location

  const returnRoutes = (getRoutes) => {
    return getRoutes.map(element => <Route key={element.path} path={element.path} element={element.element} />)
  }

  const AuthorizationRoutes = () => { // 로그인 한 사람만 사용
    let authBool = false;

    authBool = request.tokenVerify(pathname) // 토큰 체크 (refresh 도 됨)

    if(authBool) {
      const authRoutes = [
        { path: "/", element: <Procedure /> }, //시술 관리
        { path: "/old", element: <ProcedureOld /> }, //시술 관리 - 수전 전
        { path: "/procedure_add_old", element: <ProcedureAddOld /> }, //시술 등록 - 수정전
        { path: "/procedure_add", element: <ProcedureAdd /> }, //시술 등록 - 수정
        { path: "/sms", element: <Sms /> }, // 문자 관리
        { path: "/sms_stats", element: <SmsStats /> }, // 문자통계
        { path: "/drug", element: <Drug /> }, // 약품과리
        { path: "/consent", element: <Consent /> }, // 동의서 관리
        { path: "/consent_add", element: <ConsentAdd /> }, // 동의서 추가
        { path: "/coupon", element: <Coupon /> } // 쿠폰
      ]

      return returnRoutes(authRoutes)
    } else {
      const unauthRoutes = [
        { path: "/", element: <Navigate to='/main/login' /> },
        { path: "/*", element: <Navigate to='/main/login' /> }
      ]

      return returnRoutes(unauthRoutes)
    }
  }

  return (
    <>
      <Gnb />
      <main>
        <Lnb />
        <Routes> { AuthorizationRoutes() } </Routes>
      </main>
    </>
  )
}

export default AdminRoute
