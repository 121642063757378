//* 비밀번호 변경 완료
import React from "react";

const ChangePwOk = (props) => {
  const alertClose = () => {
    props.onClose();
  }

  // console.log(props.popupVisible)

	return (
		<>
			{/* //* 알림 팝업 */}
      <section className={ props.popupVisible ? "com_popup type_alert active" : "com_popup type_alert" }>
        <div className="pop_container">
          <button type="button" className="svg_icon btn_pop_close btnPopClose" onClick={ alertClose }></button>
          {/* //^ 알림 디자인 본문 영역 */}
          <div className="pop_body">
            <p className="alert_title">비밀번호가 변경되었습니다.</p>
            <p className="alert_text">변경된 비밀번호로<br/>다시 로그인 해 주세요.</p>
          </div>
          {/* //^ 알림 디자인 버튼 영역 */}
          <div className="pop_footer com_btn_wrap">
            <button type="button" className="com_btn m blue btnPopClose" onClick={ alertClose }>확인</button>
          </div>
        </div>
      </section>
		</>
	)
}

export default ChangePwOk