//* 진료확인서
import React from "react";
import moment from "moment";
import { nvl } from "utils/Common.utils";

const DocumentMediConfirm = ({doctorList, handleDoctorChange, medicalLicense, setViewType, popupCalendarHTML, getOrderRecord, medicalTreatment, setMedicalTreatment, selectedArray, treatmentConfirmation, handleTreatmentConfirm}) => {
  
  const deleteMedicalDate = (orderSeq) => {
    const copyArr = [...medicalTreatment];
    const filterMedicalTreatment = copyArr.flatMap(item => item.data).filter(el => el.order_seq !== orderSeq);

    if(selectedArray.length === 0){
      if (filterMedicalTreatment.length > 0) {
  
        const groupedByYear = {};
  
        filterMedicalTreatment.forEach((item) => {
          const orderDate = moment(item.order_date);
          const year = orderDate.format('YYYY');
  
          if (!groupedByYear[year]) {
            groupedByYear[year] = [];
          }
  
          groupedByYear[year].push(item);
        });
        const medicalTreatmentArray = Object.entries(groupedByYear).map(([year, data]) => ({ year, data }));
        
        setMedicalTreatment(medicalTreatmentArray);
      } else {
        setMedicalTreatment([]);
      }
    } else{
      setMedicalTreatment([]);
    }
  }



  return (
    <>
      <ul className="com_input_section p_document_medi_confirm">
        <li className="tit_field">담당의</li>
        <li className="input_field doctor_field">
        <select name='manager_seq' className="select" onChange={handleDoctorChange} value={nvl(selectedArray[0]?.manager_seq) === "" ? treatmentConfirmation?.manager_seq : selectedArray[0]?.manager_seq}>
          <option value="">담당의 선택</option>
          {doctorList != null && doctorList.length > 0 ?
            doctorList.map((item) => { return (<option key={item?.manager_seq} value={item?.manager_seq}>{item
            ?.manager_name}</option>) }) : null}
        </select>
        </li>
        <li className="tit_field tit_license_number">면허번호</li>
        <li className="input_field license_number">
          <p className="number">{medicalLicense}</p>
          {/* <p>의사</p> */}
        </li>
        <li className="tit_field">진료기간</li>
        <li className="input_field">
          <div className="datepicker_from_to">
            <input type="text" className="input datepicker" id="from" placeholder="2023. 01. 01" name="treatment_start" value={treatmentConfirmation?.treatment_start} onClick={() => {setViewType('from')}} readOnly /> {popupCalendarHTML()}
            <span className="txt_between">~</span>
            <input type="text" className="input datepicker" id="to" placeholder="2023. 01. 01" name="treatment_end" value={treatmentConfirmation?.treatment_end} onClick={() => {setViewType('to')}} readOnly /> {popupCalendarHTML()}
            <button className="com_btn blue m" style={{marginLeft: '1%'}} onClick={() => getOrderRecord(0)}>조회</button>
          </div>
        </li>
        <li className="tit_field">진료일자</li>
          <li className="input_field date_wrap" >
          {selectedArray?.length === 0 ? (
            medicalTreatment?.length === 0 ? "" : medicalTreatment?.map((orders, index) => (
              <React.Fragment key={index}>
                <p className="date"  style={{marginRight: '5px'}}>[{orders.year}]</p>
                <p style={{marginRight: '2%', width: '30%'}}>
                  {orders.data.map((el) => (
                    <span key={el.order_seq} style={{marginRight: '1%'}}>
                      {moment(el?.order_date).format('MMM Do')}
                      <button className="com_btn lightgray icon btn_del" style={{marginLeft: '5px', marginRight: '5px'}} onClick={() => deleteMedicalDate(el.order_seq)}><i className="svg_icon icon_x" >&nbsp;</i></button>
                    </span>
                  ))}
                </p>
              </React.Fragment>
            ))) : 
            (
            medicalTreatment?.length === 0 ? "" : medicalTreatment?.map((orders, index) => (
              <React.Fragment key={index}>
                {selectedArray[0]?.order_date?.substring(0,4) === orders.year ?
                <p className="date"  style={{marginRight: '5px'}}>[{orders.year}]</p> : null }
                <p style={{marginRight: '2%', width: '30%'}}>
                {orders.data.map((el) => (
                  selectedArray[0]?.order_date === el.order_date ? (
                    <span key={el.order_seq} style={{ marginRight: '1%' }}>
                      {moment(el?.order_date).format('MMM Do')}
                      <button className="com_btn lightgray icon btn_del" type="button" style={{ marginLeft: '5px', marginRight: '5px' }} onClick={() => deleteMedicalDate(el.order_seq)}><i className="svg_icon icon_x" >&nbsp;</i></button>
                    </span>
                  ) : 
                 null
                ))}
                </p>
              </React.Fragment>
            )))
          }
        </li>
        <li className="tit_field">질병 코드</li>
        <li className="input_field">
          <div className="com_list">
            <table className="list">
              <colgroup><col width='200px'/><col/></colgroup>
              <thead>
                <tr>
                  <th>질병코드</th>
                  <th>질병명</th>
                </tr>
              </thead>
              <tbody>
              {selectedArray[0]?.disease_code?.length > 0 ? selectedArray[0]?.disease_code?.map((code, index) => (
                <tr key={index}>
                  <td>
                    {code}<br />
                  </td>
                  <td className="tit">
                    {selectedArray[0]?.disease_name[index]}<br />
                  </td>
                </tr>
              )) : 
                <tr>
                  <td>
                    <br />
                  </td>
                  <td className="tit">
                    <br />
                  </td>
                </tr>
              }
            </tbody>
            </table>
          </div>
        </li>
        <li className="tit_field">진단유형</li>
        <li className="input_field">
          <fieldset className="com_radio_wrap">
            <input type="radio" className="radio" id="type1" name="diagnosis_type" value="017001000" onChange={(e) => handleTreatmentConfirm(e, 0)} checked={treatmentConfirmation.diagnosis_type === "017001000"}/>
            <label className="btn_radio" htmlFor="type1">&nbsp;</label>
            <label htmlFor="type1">임상추적형</label>
            <input type="radio" className="radio" id="type2" name="diagnosis_type" value="017002000" onChange={(e) => handleTreatmentConfirm(e, 0)} checked={treatmentConfirmation.diagnosis_type === "017002000"}/>
            <label className="btn_radio" htmlFor="type2">&nbsp;</label>
            <label htmlFor="type2">최종진단</label>
          </fieldset>
        </li>
        <li className="tit_field">진료내역</li>
        <li className="input_field">
          <textarea className="textarea" name="treatment_detail" value={treatmentConfirmation.treatment_detail} onChange={(e) => handleTreatmentConfirm(e, 0)} style={{resize: "none"}}></textarea>
        </li>
        <li className="tit_field">비고</li>
        <li className="input_field">
          <input type="text" className="input" name="paper_remark" value={treatmentConfirmation.paper_remark} onChange={(e) => handleTreatmentConfirm(e, 0)}/>
        </li>
      </ul>
    </>
  )
}

export default DocumentMediConfirm