import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import {v4 as uuidv4} from 'uuid';

const SubButton = (props) => {
  const navigate = useNavigate();

  let { pathname } = useLocation();
  pathname = pathname.toLowerCase()

  const [buttons, setButtons] = useState([
    { text: "날짜별", value: 0, active: false, url: "/crm/day" },
    { text: "파일별", value: 1, active: false, url: "/crm/file" },
    { text: "고객만족도", value: 2, active: false, url: "/crm/satisfaction" },
    { text: "고객관리", value: 3, active: false, url: "/crm/customer" },
    { text: "컴플레인", value: 4, active: false, url: "/crm/complain" }
  ]);

  const clickHandler = (value) => {
    let newSubButton = [];

    for(const button of buttons) {
      if(button.value === value) button.active = true; //!button.active;
      else button.active = false;

      newSubButton = [...newSubButton, button]
    }

    setButtons(newSubButton);

    props.buttonHandler(value);
  }

  const clickNavigate = (value) => {
    for(const button of buttons) {
      if(button.value === value) navigate(button.url); // navigate(button.url); window.location.href = button.url;
    }
  }

  useEffect(() => {
    if(pathname.indexOf("/day") >= 0) {
      clickHandler(0);
    } else if(pathname.indexOf("/file") >= 0) {
      clickHandler(1);
    } else if(pathname.indexOf("/satisfaction") >= 0) {
      clickHandler(2);
    } else if(pathname.indexOf("/customer") >= 0) {
      clickHandler(3);
    } else if(pathname.indexOf("/complain") >= 0) {
      clickHandler(4);
    } else clickHandler(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <article className="com_lnb2_left">
        <div className="lnb2_menu_container">
          {
            buttons.map(item => (
              <span key={"subButton_" + uuidv4()} className={item.active ? "com_btn m blue" : "com_btn m lightgray"} onClick={(e) => {clickNavigate(item.value)}}>{item.text}</span>
            ))
          }
        </div>
      </article>
    </>
  )
}

export default SubButton