//* 내원경로 관리
import React, { useRef, useEffect, useState } from "react";
import Draggable from 'react-draggable';

import request from '../../utils/Request.utils';
import { nvl } from "../../utils/Common.utils";

import CustomAlert from '../../atoms/common/CustomAlert';

const VisitType = (props) => {
  let visitDisplayBool = props.visitDisplayBool;

  const popupSectionRef = useRef();
  const admissiveChannelRef = useRef();
  const nodeRef = useRef(null);

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [admissiveChannel, setAdmissiveChannel] = useState([]);

  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });

  const [selectedValue, setSelectedValue] = useState('');
  const [activateMode, setActivateMode] = useState('');

  if(visitDisplayBool) popupSectionRef.current.classList.add('active');
  else if(!visitDisplayBool && popupSectionRef.current) popupSectionRef.current.classList.remove('active');

  const popupClose = () => {
    props.visitDisplayToggle();
  }

  // 내원 경로 리스트 가져오기
  const getAdmissiveChannel = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) { // ok 코드 값
        // console.log("response : " + JSON.stringify(response));

        setAdmissiveChannel(response.data.dataList);
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      console.log("에라야 에라~~" + response);
    }

    request.get("/api/etc/commoncd/2/002", null, successHandler, failHandler, null, null); // 공통코드 가져오는 부분 (참고 하세요)
  }

  useEffect(() => {
    getAdmissiveChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = (event) => {
    setSelectedValue(event.target.value)
  }

  const addAdmissiveChannel = () => {
    let admissiveChannelName = admissiveChannelRef.current.value;

    console.log("admissiveChannelName : " + admissiveChannelName);

    if(nvl(admissiveChannelName) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "내원경로 명을 입력해주십시오." } })

      return;
    }
    
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "내원경로 명을 추가하시겠습니까?" } })
    setActivateMode('addAdmissiveChannel'); // 다음 실행할 함수를 호출하기 위해서
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const deleteAdmissiveChannel = () => {
    if(nvl(selectedValue) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 내원경로가 없습니다." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "선택된 내원경로를 삭제하시겠습니까?" } })
    setActivateMode('deleteAdmissiveChannel'); // 다음 실행할 함수를 호출하기 위해서
  }

  const returnAlertValue = (value) => {
    // console.log("value ==> " + value);

    if(nvl(value) === 'OK') {
      if(activateMode === 'addAdmissiveChannel') { // 내원 경로 추가
        let params = {
          parent_code: "002000000",
          code_level: "2",
          code_name: nvl(admissiveChannelRef.current.value),
          code_desc: "",
          reg_manager_seq: managerInfo.seq
        }
    
        const successHandler = (response) => {
          // console.log(response);

          if(response.messageCode === 200) { // ok 코드 값
            admissiveChannelRef.current.value = "";
    
            // 다시 호출
            getAdmissiveChannel();
          } else console.log("코드 값을 찾지 못했습니다.");
        }
    
        request.post("/api/etc/commoncd", params, successHandler);
      } else if(activateMode === 'deleteAdmissiveChannel') { // 내원 경로 삭제
        const successHandler = (response) => {
          // console.log(response);

          if(response.messageCode === 200) { // ok 코드 값
            admissiveChannelRef.current.value = "";
            setSelectedValue('');
    
            // 다시 호출
            getAdmissiveChannel();
          } else {
            console.log("코드 값을 찾지 못했습니다.");
          }
        }

        request.del(`/api/etc/commoncd/admissive_channel/${selectedValue}/${managerInfo.seq}`, null, successHandler);
      }
    }
  }

	return (
		<>
			{/* //* 일반 팝업 */}
      <section ref={popupSectionRef} className="com_popup type_normal pop_visit_type PopVisitType">{/*클래스 active추가시 노출*/}

        <Draggable nodeRef={nodeRef}>

        <div ref={ nodeRef } className="pop_container s">
        { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> : null }
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={ () => { popupClose() }}></button>
          {/* //^ 파란색 팝업 타이틀 */}
          <div className="pop_header">
            <h1 className="pop_title">내원경로 관리</h1>
          </div>
          {/* //^ 팝업 본문 영역 */}
          <div className="pop_body com_add_item">     
            <fieldset className="add">
              <input type="text" className="input" ref={ admissiveChannelRef } />
              <button type="button" className="com_btn m blue" onClick={ addAdmissiveChannel }>등록</button>
            </fieldset>
            <article className="list">
              {
                (admissiveChannel == null || admissiveChannel.length < 1) ?
                  <ul className="item_wrap scroll">
                    <li className="item no_data">등록된 내원경로가 없습니다.</li>
                  </ul>
                :
                  <ul className="item_wrap scroll">
                    {
                      admissiveChannel.map((item, index) => (
                        item.code_level > 1 ?
                          <li key={item.common_code} className="item">
                            <fieldset className="com_radio_wrap">
                              <input type="radio" className="radio" id={ "visitType" + index } name="select_visit" value={ item.common_code }
                                onChange={ clickHandler } checked={ item.common_code === selectedValue } />
                              <label className="btn_radio" htmlFor={ "visitType" + index }>&nbsp;</label>
                              <label htmlFor={"visitType" + index}>{item.code_name}</label>
                            </fieldset>
                          </li>
                        : null
                      ))
                    }
                  </ul>
              }
            </article>
            <p className="tip_txt">내원 경로를 삭제하시면<br/>고객이 사용하고 있는 경로 노출도 삭제됩니다.</p>
          </div>
          <div className="pop_footer com_btn_wrap">
            <button type="button" className="com_btn l blue btnPopClose" onClick={ deleteAdmissiveChannel }>삭제</button>
          </div>
        </div>

        </Draggable>

      </section>
		</>
	)
}

export default VisitType