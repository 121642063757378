import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import {v4 as uuidv4} from 'uuid';

const StatisticsSubButton = (props) => {
  const navigate = useNavigate();

  let { pathname } = useLocation();
  pathname = pathname.toLowerCase()

  const [buttons, setButtons] = useState([
    { text: "결산요약", value: 0, active: false, url: "/statistics/summary" },
    { text: "환자별", value: 1, active: false, url: "/statistics/calc_customer" },
    { text: "매출왕", value: 2, active: false, url: "/statistics/calc_best_sales" },
    { text: "접수별", value: 3, active: false, url: "/statistics/calc_register" },
    { text: "연령대별", value: 4, active: false, url: "/statistics/calc_age" },
    { text: "분야별", value: 5, active: false, url: "/statistics/calc_field" },
    { text: "담당별", value: 6, active: false, url: "/statistics/calc_staff" }
  ]);

  const clickHandler = (value) => {
    // console.log(" value : " + value);

    let newSubButton = [];

    for(const button of buttons) {
      if(button.value === value) button.active = true; //!button.active;
      else button.active = false;

      newSubButton = [
        ...newSubButton,
        button
      ]
    }

    setButtons(newSubButton);

    props.subButtonAction(value);
  }

  const clickNavigate = (value) => { // navigate();
    // console.log(" value : " + value);

    for(const button of buttons) {
      if(button.value === value) navigate(button.url);
    }
  }

  useEffect(() => {
    if(pathname.indexOf("/summary") >= 0) {
      clickHandler(0);
    } else if(pathname.indexOf("/calc_customer") >= 0) {
      clickHandler(1);
    } else if(pathname.indexOf("/calc_best_sales") >= 0) {
      clickHandler(2);
    } else if(pathname.indexOf("/calc_register") >= 0) {
      clickHandler(3);
    } else if(pathname.indexOf("/calc_age") >= 0) {
      clickHandler(4);
    } else if(pathname.indexOf("/calc_field") >= 0) {
      clickHandler(5);
    } else if(pathname.indexOf("/calc_staff") >= 0) {
      clickHandler(6);
    } else clickHandler(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <article className="com_lnb2_left">
        <div className="lnb2_menu_container">
          {
            buttons.map(item => (
              <span key={"subButton_" + uuidv4()} className={item.active ? "com_btn m blue" : "com_btn m lightgray"} onClick={(e) => {clickNavigate(item.value)}}>{item.text}</span>
            ))
          }
        </div>
      </article>
    </>
  )
}

export default StatisticsSubButton