import CustomAlert from "atoms/common/CustomAlert";
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import { nvl } from "utils/Common.utils";

const PointList = ({ pointList }) => {
  return (
    pointList?.map((point) => {
      return (
        <tr key={point.point_his_seq}>
          <td>{point.remarks}</td>
          <td className='num'>{ new Intl.NumberFormat().format(point?.current_point) }</td>
          <td className={ point.use_point < 0 ? 'num point_minus' : 'num' }>{ new Intl.NumberFormat().format(point.use_point) }</td>
          <td>{ new Intl.DateTimeFormat('kr').format(new Date(point.reg_date)) }</td>
        </tr>
      )
    })
  )
}

const PointManage = ({ onClose }) => {
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const stateCustomer = useSelector(state => state.Customer);

  const nodeRef = useRef(null);

  const [pointList, setPointList] = useState([]);
  const [point, setPoint] = useState(0);
  const [addPoint, setAddPoint] = useState('');

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const getPointList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setPointList(response.data);
        setPoint(nvl(response.data[0]?.current_point));
      } else console.debug('Error >> ', response);
    }

    request.get(`/api/point/${stateCustomer?.user_seq}/list`, null, successHandler);
  }

  const givePoint = (e) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        if (response.data) {
          getPointList();
          setAddPoint('');
          // setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `포인트가 충전되었습니다.`, activateMode:'' } });
        }
      } else console.log('Error >> ', response);
    }

    if(addPoint === "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: `숫자를 입력해주세요.`, activateMode:'' } });
    } else {
      let params = {
        user_seq: stateCustomer?.user_seq,
        use_point: addPoint,
        use_purpose: addPoint > 0 ? '충전' : '사용',
        reg_manager_seq: managerInfo.seq
      }
  
      request.post(`/api/point`, params, successHandler);
    }
  }

  const returnAlertValue = () => {
    console.log("")
  }

  useEffect(() => {
    getPointList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal pop_point_manage PopPointManage active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container l">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">포인트 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <article className="point_area">
                <b className="point">{new Intl.NumberFormat().format(point)}</b> Point
              </article>
              <article className="com_add_item">
                <fieldset className="add">
                  <input type="number" min='1' className="input" placeholder="포인트 입력" value={addPoint} onChange={(e) => setAddPoint(e.target.value)} />
                  <button type="button" className="com_btn m blue" onClick={ givePoint }>충전하기</button>
                </fieldset>
              </article>
              <article className="com_list">
                <div className="scroll">
                  <table className="list">
                    <thead className="sticky top">
                      <tr>
                        <th>비고</th>
                        <th>보유포인트</th>
                        <th>사용액</th>
                        <th>날짜</th>
                      </tr>
                    </thead>
                    <tbody>
                      <PointList pointList={ pointList } />
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
          </div>
        </Draggable>
      </section>
      { alertLayerPopup.visible &&
        <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> }
    </>
  )
}

export default PointManage