import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashReserve from "./_DashReserve.js"; //좌측 예약/취소
import DashVisit from "./_DashVisit.js"; //중앙 접수/상담/결제/시술/처치대기/마취
import DashFinish from "./_DashFinish.js"; //우측 시술완료
import ReserveList from "./_ReserveList.js"; //총 예약목록
import RoomManage from "./_RoomManage.js"; //층관리,룸관리
import ReservationAdd from "components/common/_ReservationAdd.js";
import CustomCard from "components/common/_CustomCard.js";
import DashCalendar from "./DashCalendar.js";
import request from "utils/Request.utils";
import moment from "moment";
import RoomLayout from "./_RoomLayout.js";//룸배치도
import ParlorManage from "./_ParlorManage.js";//시술실명 관리

import { nvl, nvlNumber, convertJuminToAge } from "utils/Common.utils";
import { decrypt } from "utils/aes256.manager";
import LoadingBar from '../../utils/LoadingBar';

import { socket, SOCKET_EVENT } from "../etc/socket";

const Dash = ({ receptionList, setReceptionList, orderButtons, setOrderButtons, loadingBarActive, getReceptionList, startDate, setStartDate, finishList, setFinishList }) => {
  const dispatch = useDispatch();

  const stateTimerInfo = useSelector(state => state.TimerInfo);

  const [viewType, setViewType] = useState('');
  const [surgeryList, setSurgeryList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [surgeryRoom, setSurgeryRoom] = useState('');
  const [selectedValue, setSelectedValue] = useState('012001000');
  const [totalCount, setTotalCount] = useState(0);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());

  const [userData, setUserData] = useState(''); // _CustomList.js 와 같은 조건으로 호출하기 위해서
  const [pointData, setPointData] = useState(''); // _CustomList.js 와 같은 조건으로 호출하기 위해서

  const [popup, setPopup] = useState({
    addCustomer: false,
    reservationAdd: false,
    handOver: false,
    customerCard: false,
  });

  
  const [lnbSearchOptions, setLnbSearchOptions] = useState({
    checkedBool: false, // 고객리스트 컴포넌트 노출 여부
    hotkeyBool: false, // 핫키 컴포넌트 노출 여부
    totalCount: 0, // 총 갯수
    searchList: null, // 검색 리스트
    searchKeyword: '', // 이름 or 차트코드 or 휴대전화 번호 검색
    userData: {}, // 고객 정보
    send: false,
    sent: false
  })
  
  const [periodOption, setPeriodOption] = useState(null);

  const visitRef = useRef();

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
    setUserData('')
  }

  const onPopupClick = (e) => {
    setPopup((prev) => { return { ...prev, [e.target.id]: true } })
    if (e.target.id === "addCustomer") { // 초진접수 시 state 초기화
      setUserData('')
    }
  }

  const setPeriodSearchDate = (startDate) => {
    setStartDate(startDate);
    setPeriodOption('3');
  }

  const calculateDate = (symbols, value) => {
    if (symbols === 'add') {
      const date = new Date(value);
      date.setDate(date.getDate() - 1);
      const formattedDate = date.toISOString().slice(0, 10);
      setStartDate(formattedDate);
    } else if (symbols === 'sub') {
      const date = new Date(value);
      date.setDate(date.getDate() + 1);
      const formattedDate = date.toISOString().slice(0, 10);
      setStartDate(formattedDate);
    }
  }

  const getSurgeryRoomList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setSurgeryList(response.data);
        setSurgeryRoom('');
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/012000000`, null, successHandler);
  }

  // 단일 고객 조회
  const getSelectedUserInfo = (user_seq, mode) => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const user = response.data.dataList[0];

        setUserData({
          user_seq: user.user_seq,
          chart_code: user.chart_code,
          user_name: user.user_name,
          user_gender: user.user_gender,
          user_level: user.user_level,
          jumin_1: decrypt(user.user_jumin).substring(0, 6),
          jumin_2: decrypt(user.user_jumin).substring(6, 13),
          mobile_1: user.user_mobile.split("-")[0],
          mobile_2: user.user_mobile.split("-")[1],
          mobile_3: user.user_mobile.split("-")[2],
          user_photo: nvlNumber(user.up_file_seq),
          user_photo_path: user.file_path,
          user_reser_seq: user.user_reser_seq,
          order_count: user.order_count,
          user_address: user.user_address,
          user_address_detail: user.user_address_detail,
          interest_area: user.interest_area,
          admissive_channel: user.admissive_channel,
          bank_name: user.bank_name,
          bank_account_number: user.bank_account_number,
          bank_account_holder: user.bank_account_holder
        })

        setPointData(response.data.pointData[0]);
        // setPointData((prev) => { return { ...prev, current_point: response.data[0]?.current_point } })

        if (mode === 'popup') setPopup((prev) => { return { ...prev, reservationAdd: false, customerCard: true } });
        else if (mode === 'reservationAdd') setPopup((prev) => { return { ...prev, reservationAdd: true, customerCard: false } });
      } else {
        console.log("고객 정보를 가져오지 못했습니다.")
      }
    }

    request.get(`/api/user/${user_seq}`, null, successHandler);
  }
  
  useEffect(() => {
    getSurgeryRoomList(); // 하단에서 바로 호출
    console.log("소켓 조인");
    socket.emit(SOCKET_EVENT.JOIN_ROOM, {room:1, name:'react' });
    visitRef?.current?.getTimer2();

    return () => { // 컴포넌트가 화면에서 사라졌을 때 초기화
      setRoomList([]);
      setSurgeryRoom('');
      setReceptionList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //소켓 이벤트 수신 시 데시보드 데이터 갱신 - 2023-09-05 KSR
  useEffect(() => {
		socket.on(SOCKET_EVENT.DASHBOARD, () => {
      console.log("SocketRecive : dashboard.");
      getReceptionList();
      visitRef?.current?.getTimer2();
    });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

  

  const getRoomList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setRoomList(response.data);
      } else {
        console.log('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/${selectedValue}`, null, successHandler);
  }

  useEffect(() => {
    if (nvl(selectedValue) !== '') getRoomList();
    else setRoomList([]) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])


  const selectHandler = (value) => {
    changeUserHandler(value); // setCustomer 변경
    getSelectedUserInfo(value); // 현재 setUserData 변경
  }

  const changeUserHandler = (userSeq) => { // 선택된 값
    if (nvlNumber(userSeq) !== "") {
      if (userSeq > 0) {
        request.get(`/api/user/${userSeq}`, null, (response) => {
          if (response.messageCode === 200) {
            let userInfo = response.data.dataList[0];

            dispatch({ type: "setCustomer", payload: { fieldName: "user_seq", data: userSeq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_name", data: userInfo.user_name } });
            dispatch({ type: "setCustomer", payload: { fieldName: "chart_code", data: userInfo.chart_code } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_gender", data: userInfo.user_gender } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_dob", data: userInfo.user_dob } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_age", data: nvl(userInfo.user_jumin) !== "" ? convertJuminToAge((decrypt(userInfo.user_jumin).substring(0, 6) + "-" + decrypt(userInfo.user_jumin).substring(6, 13))) : " - " } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level", data: userInfo.user_level } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_level_color", data: userInfo.user_level_color } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_status", data: userInfo.user_status } });
            dispatch({ type: "setCustomer", payload: { fieldName: "interest_area", data: userInfo.interest_area } });
            dispatch({ type: "setCustomer", payload: { fieldName: "admissive_channel", data: userInfo.admissive_channel } });
            dispatch({ type: "setCustomer", payload: { fieldName: "main_tc_seq", data: userInfo.main_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "sub_tc_seq", data: userInfo.sub_tc_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo", data: nvlNumber(userInfo.up_file_seq) } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_photo_path", data: userInfo.file_path } });
            dispatch({ type: "setCustomer", payload: { fieldName: "user_reser_seq", data: userInfo.user_reser_seq } });
            dispatch({ type: "setCustomer", payload: { fieldName: "order_seq", data: userInfo.order_seq } });
          } else console.log("고객 정보를 가져오지 못했습니다.")
        });
      }
    }
  }

  useEffect(() => {

    generateTimerButtons(receptionList)  // 대시보드 작업중 console 성가시면 주석 처리

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receptionList])


  // modified by psk 20230508 - 섭 차트 정보까지 들고 감
  const generateTimerButtons = (orders) => {
    let newTimerButtons = []

    let timerButtonsForUser = [
      { user_seq: 0, order_seq: 0, text: "대기시간", value: 0, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 },
      { user_seq: 0, order_seq: 0, text: "마취중", value: 1, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 },
      { user_seq: 0, order_seq: 0, text: "시술시간", value: 2, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 },
      { user_seq: 0, order_seq: 0, text: "관리시간", value: 3, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 }
    ]

    let findButtonInfo = []
    let findButtonInfoSub = []
    const getCurrentTime = new Date().getTime();

    orders.forEach((order) => {

      if (nvl(stateTimerInfo) === '') { // 레디스에 저장된 값이 없을 때
        let firstButton = { ...timerButtonsForUser[0], user_seq: order.user_seq, order_seq: order.order_seq, active: true, isLive: true, startTime: getCurrentTime, curTime: getCurrentTime };
        newTimerButtons.push(timerButtonsForUser.map((button, index) => {
          // 버튼의 인덱스가 0일 때는 firstButton 정보를, 그 외에는 기존 버튼 정보를 유지
          return index === 0 && order.order_status === "013005000"
            ? { ...button, user_seq: order.user_seq, order_seq: order.order_seq, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 }
            : { ...button, user_seq: order.user_seq, order_seq: order.order_seq };
        }));
      } else { // 레디스에 저장된 값이 있을 때는 있는 거 빼고 추가
        findButtonInfo = stateTimerInfo.filter((buttonListInfo) => {
          findButtonInfoSub = buttonListInfo?.filter(buttonInfo => (buttonInfo.user_seq === order.user_seq && buttonInfo.order_seq === order.order_seq /* && buttonInfo.sub_tc_seq === chartInfo.sub_tc_seq */))

          return findButtonInfoSub != null && (Array.isArray(findButtonInfoSub) && findButtonInfoSub.length > 0)
        });

        if (findButtonInfo != null && (Array.isArray(findButtonInfoSub) && findButtonInfo.length > 0)) {
          findButtonInfo.forEach(resultInfo => {
            if (order.order_status === "013005000" && resultInfo[0]?.active === false) {
              let firstButton = { ...resultInfo[0], active: true, isLive: true, startTime: getCurrentTime, curTime: getCurrentTime }
              newTimerButtons.push([firstButton, ...resultInfo.slice(1)])
            } else {
              newTimerButtons.push(resultInfo)
            }
          })
        } else {
          let firstButton = { ...timerButtonsForUser[0], user_seq: order.user_seq, order_seq: order.order_seq, active: true, isLive: true, startTime: getCurrentTime, curTime: getCurrentTime };
          newTimerButtons.push(timerButtonsForUser.map((button, index) => {
            // 버튼의 인덱스가 0일 때는 firstButton 정보를, 그 외에는 기존 버튼 정보를 유지
            return index === 0 && order.order_status === "013005000"
              ? { ...button, user_seq: order.user_seq, order_seq: order.order_seq, active: false, isLive: false, startTime: 0, curTime: 0, alarmTime: 0 }
              : { ...button, user_seq: order.user_seq, order_seq: order.order_seq };
          }));
        }
      }
    })
    if (newTimerButtons != null && newTimerButtons.length > 0) dispatch({ type: "setTimer", payload: { data: newTimerButtons } }); // redux에 저장만 사용하는 걸로 바꿈
  }


  useEffect(() => {
    getReceptionList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate])


  const clickHandler = (e) => {
    setSelectedValue(e.target.value)
  }


  return (
    <>
      <section className="section com_lnb2">
        <div className="inner">
          <article className="com_lnb2_left">
            <button className="com_btn green m" id='addCustomer' onClick={(e) => onPopupClick(e)}><i className="svg_icon icon_add white">&nbsp;</i>초진접수</button>
          </article>
          <article className="com_lnb2_center com_select_date">
            <button className="com_btn" onClick={() => { calculateDate('add', startDate) }}><i className="svg_icon icon_prev white">&nbsp;</i></button>
            <div className="date" onClick={() => { setViewType('from') }} value={viewType}>
              <DashCalendar setPeriodSearchDate={setPeriodSearchDate} periodOption={periodOption} startDate={startDate} setStartDate={setStartDate} />
            </div>
            <button className="com_btn" onClick={() => { calculateDate('sub', startDate) }}><i className="svg_icon icon_next white">&nbsp;</i></button>
          </article>
          <article className="com_lnb2_right">
            <section className="com_field type_normal">
              <fieldset className="many">
                {/*20230406*/}
                {/* <select className="select">
                  <option>층 선택</option>
                </select>
                <select className="select">
                  <option>룸 선택</option>
                </select>
                <select className="select">
                  <option>룸 선택</option>
                </select>
                <button className="com_btn blue m"><i className="svg_icon icon_map">&nbsp;</i>고객 룸 배치도</button> */}
                <select className="select" onChange={clickHandler}>
                  <option value="">시술실 선택</option>
                  {surgeryList.map((el, i) => (<option key={i} value={el.code}>{el.name}</option>))}
                </select>
                <select className="select">
                  <option value="">선택</option>
                  {
                    roomList != null && roomList.length > 0 ? roomList.map((el, i) => (<option key={i} value={el.code}>{el.name}</option>)) : null
                  }
                </select>
                {/* <button className="com_btn icon line yellow" id='handOver' onClick={(e) => onPopupClick(e)}><i id='handOver' className="svg_icon icon_set yellow">&nbsp;</i></button> */}
              </fieldset>
            </section>
          </article>
        </div>
      </section>
      <section className="com_content p_dashboard">
        <DashReserve userData={userData} setUserData={setUserData} startDate={startDate} getReceptionList={getReceptionList} getSelectedUserInfo={getSelectedUserInfo} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler}/>
        <DashVisit receptionList={receptionList} setReceptionList={setReceptionList} userData={userData} setUserData={setUserData} getReceptionList={getReceptionList} orderButtons={orderButtons} setOrderButtons={setOrderButtons} getSelectedUserInfo={getSelectedUserInfo} selectHandler={selectHandler} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler} ref={visitRef} />
        <DashFinish userData={userData} setUserData={setUserData} startDate={startDate} getReceptionList={getReceptionList} getSelectedUserInfo={getSelectedUserInfo} finishList={finishList} setFinishList={setFinishList} totalCount={totalCount} />
        {popup.handOver && <RoomManage onClose={() => { onClosePop('handOver') }} />}
        {/* <ReserveList /> */}
        {/* <AnesthesiaAlarm /> */}
        {/* <SetPrescriptionManage />
        <SupplierManage />
        <RoomLayout />
        <ParlorManage /> */}
      </section>
      {popup.addCustomer && <CustomCard onClose={() => { onClosePop('addCustomer') }} userData={userData} setUserData={setUserData} pointData={pointData} selectHandler={selectHandler} getReceptionList={getReceptionList} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler} />}  {/* 초진접수(고객카드 등록) */}
      {popup.customerCard && <CustomCard onClose={() => { onClosePop('customerCard') }} userData={userData} setUserData={setUserData} pointData={pointData} selectHandler={selectHandler} getReceptionList={getReceptionList} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler} />} {/* 고객카드 */}
      {popup.reservationAdd && <ReservationAdd onClose={() => { onClosePop('reservationAdd') }} userData={userData} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler} />}

      {loadingBarActive && <LoadingBar type={"spin"} color={"#000000"} />}
    </>
  )
}

export default Dash