//* 서류발급 > 진료기록부
import React, { forwardRef } from "react";
import { decrypt } from "../../utils/aes256.manager";
import moment from "moment";
import { convertJuminToAge, convertGender, nvl } from "../../utils/Common.utils";

const DoctorsNote = forwardRef((props, ref) => {
  const { selectedCustomer, userInfo, selectedRecordArray, hospitalInfo } = props;



  return (
    <>
      <section className="com_document" ref={ref}>
        <h1 className="document_tit">진료기록부</h1>
        <p className="chart_num">차트번호 : {nvl(selectedCustomer) === "" ? userInfo.chart_code : selectedCustomer.chart_code}</p>
        <table className="table">
          <colgroup><col width="15%" /><col width="15%" /><col width="15%" /><col /><col width="8%" /><col width="8%" /><col width="8%" /><col width="8%" /></colgroup>
          <tr>
            <th>성명</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.user_name : selectedCustomer.user_name}</td>
            <th>전화번호</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.user_mobile : selectedCustomer.user_mobile}</td>
            <th>증번호</th>
            <td colSpan={3}>30113180256</td>
          </tr>
          <tr>
            <th>조합기호</th>
            <td colSpan={2}></td>
            <th>초진일</th>
            <td colSpan={4}>2022년 06월 23일</td>
          </tr>
          <tr>
            <th>피보험자</th>
            <td></td>
            <th>주민등록번호</th>
            <td>{nvl(selectedCustomer) === "" ? userInfo.jumin_1 + "-" + userInfo.jumin_2 : (decrypt(selectedCustomer?.user_jumin)?.substring(0, 6) + "-" + decrypt(selectedCustomer?.user_jumin)?.substring(6, 13))}</td>
            <th>나이</th>
            <td>{nvl(selectedCustomer) === "" ? convertJuminToAge((userInfo.jumin_1 + "-" + userInfo.jumin_2)) : ""}</td>
            <th>성별</th>
            <td>{nvl(selectedCustomer) === "" ? convertGender(userInfo.user_gender) : selectedCustomer.user_name}</td>
          </tr>
          <tr>
            <th>주소</th>
            <td colSpan={7}>{nvl(selectedCustomer) === "" ? `${userInfo.user_address} ${userInfo.user_address_detail}`  : `${selectedCustomer.user_address} ${selectedCustomer.user_address_detail}`}</td>
          </tr>
        </table>
        <table className="table">
          <colgroup><col width="15%" /><col width="35%" /><col width="15%" /><col /></colgroup>
          <tr>
            <th colSpan={4}>주 증상 및 병력</th>
          </tr>
          <tr>
            <td colSpan={4} className="memo">{selectedRecordArray?.disease_name}</td>
          </tr>
          <tr>
            <th>내원일</th>
            <td>{moment(selectedRecordArray?.order_date).format("YYYY년 MM월 DD일")}</td>
            <th>작성자</th>
            <td>박유라</td>
          </tr>
          <tr>
            <td colSpan={4} className="memo">
              [증상경과]<br />
              안면부 전체적으로 피부 가려움, 피부 건조증 호소하며 내원함.<br />
              주 1회 더마젠 치료가 10회이상 필요할것으로 사료됨.
              <br />
              <br />
              [상병명]<br />
              피부건조증(L853)<br />
              기타 가려움(L298)<br /><br />
              [시술및처방]<br />
              초진진찰료-공휴<br />
              더마젠 1cc<br />
              진료기록부(초진,재진차트)
            </td>
          </tr>
        </table>
        <table className="table">
          <colgroup><col width="17%" /><col width="18.3%" /><col width="15%" /><col width="18.3%" /><col width="15%" /><col /></colgroup>
          <tr>
            <th>사업자등록번호</th>
            <td>3062291740</td>
            <th>상호</th>
            <td>{hospitalInfo.hospital_name}</td>
            <th>전화번호</th>
            <td>{hospitalInfo.hospital_phone}</td>
          </tr>
          <tr>
            <th>사업장 소재지</th>
            <td colSpan={3}>{hospitalInfo.hospital_address} {hospitalInfo.hospital_address_detail}</td>
            <th>성명</th>
            <td>박유라</td>
          </tr>
        </table>
      </section>
    </>
  )
})




export default DoctorsNote