//* 미수액
import React, { useRef, useEffect, useState } from "react";

import request from '../../utils/Request.utils';
import { decrypt } from "../../utils/aes256.manager";

import { nvl, nvlNumber, nvlOnlyNumber, convertJuminToAge, convertGender, addComma } from "../../utils/Common.utils";

const NonePay = (props) => {
  const popupSectionRef = useRef();

  const [paymentList, setPaymentList] = useState([]);
  const [userInfoText, setUserInfoText] = useState('');

  let nonepayDisplayBool = props.nonepayDisplayBool;

  if(nonepayDisplayBool) popupSectionRef.current.classList.add('active');
  else if(!nonepayDisplayBool && popupSectionRef.current) popupSectionRef.current.classList.remove('active');

  const popupClose = () => {
    props.nonePayToggle();
  }

  const getUserInfo = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) { // ok 코드
        // console.log("response : " + JSON.stringify(response));
        const userInfo = response.data.dataList[0];

        const userName = nvl(userInfo.user_name);
        const userAge = nvl(userInfo.user_jumin) !== "" ? convertJuminToAge( (decrypt(userInfo.user_jumin).substring(0, 6) + "-" + decrypt(userInfo.user_jumin).substring(6, 13)) ) : " - ";
        const userGender = nvl(userInfo.user_gender);
        const userMobile = nvl(userInfo.user_mobile);

        setUserInfoText(`${userName} (${convertGender(userGender)}${userAge}) ${userMobile}`);
        getUserNonePayInfo();
      } else {
        console.log("데이터가 없습니다..");
      }
    }

    const failHandler = (response) => {
      console.log("에라야 에라~~" + response);
    }

    request.get(`/api/user/${props.userSeq}`, null, successHandler, failHandler, null, null);
  }

  const getUserNonePayInfo = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) { // ok 코드
        console.log("response : " + JSON.stringify(response));

        setPaymentList(response.data.dataList);
      } else {
        console.log("데이터가 없습니다..");
      }
    }

    const failHandler = (response) => {
      console.log("에라야 에라~~" + response);
    }

    request.get(`/api/payment?user_seq=${props.userSeq}&order_by=pay_his_seq DESC`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    if(nvlOnlyNumber(props.userSeq) > 0) {
      getUserInfo();
      getUserNonePayInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userSeq]);
  
	return (
		<>
			{/* //* 일반 팝업 */}
      <section ref={popupSectionRef} className="com_popup type_normal pop_none_pay PopNonePay">{/*클래스 active추가시 노출*/}
        <div className="pop_container">
          <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={ () => { popupClose() }}></button>
          {/* //^ 파란색 팝업 타이틀 */}
          <div className="pop_header">
            <h1 className="pop_title">미수액</h1>
          </div>
          {/* //^ 팝업 본문 영역 */}
          <div className="pop_body">
            <div className="com_list">
              <article className="top_area box">
                <div className="info_field">
                  <p className="tit">{ userInfoText }</p>
                </div>
                <div className="btn_field">
                  <button type="button" className="com_btn txt"><i className="svg_icon icon_sms yellow">&nbsp;</i>문자 보내기</button>
                </div>
              </article>
              <div className="scroll">
                <table className="list">
                  <thead>
                    <tr>
                      <th>결제일<button className="btn_sort active"><i className="svg_icon icon_sort">&nbsp;</i></button></th>
                      <th>청구금액</th>
                      <th>할인금<button className="btn_sort"><i className="svg_icon icon_sort">&nbsp;</i></button></th>
                      <th>결제금액<button className="btn_sort"><i className="svg_icon icon_sort">&nbsp;</i></button></th>
                      <th>부가세</th>
                      <th>미수금</th>
                      <th>결제종류</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {
                      (paymentList == null || paymentList.length < 1) ?
                        <tr>
                          <td colSpan="7">결제정보가 없습니다.</td>
                        </tr>
                      :
                      paymentList.map((item, index) => (
                        <tr key={item.pay_his_seq}>
                          <td>{ item.reg_date.substring(0, 10) }</td>
                          <td className="num">{ addComma(nvlNumber(item.current_price)) }</td>
                          <td className="num">{ addComma(nvlNumber(item.sale_price)) }</td>
                          <td className="num">{ addComma(nvlNumber(item.use_price)) }</td>
                          <td className="num">{ addComma(nvlNumber(item.current_price * 0.1)) }</td>
                          <td className="num">{ addComma(nvlNumber(item.unpaid_price)) }</td>
                          <td>카드</td>
                        </tr>
                      ))
                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pop_footer"></div>
        </div>
      </section>
		</>
	)
}

export default NonePay