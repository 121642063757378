import React from "react";

import { nvlOnlyNumber } from "utils/Common.utils";

const Paging = (props) => {
	let totalCount = nvlOnlyNumber(props.totalCount) === 0 ? 1 : props.totalCount; // 총 갯수
	let currentPage = nvlOnlyNumber(props.currentPage) === 0 ? 1 : props.currentPage; // 현재 페이지
	let numPerPage = nvlOnlyNumber(props.numPerPage) === 0 ? 10 : props.numPerPage; // 한 페이지에 보여질 리스트 수
	let pagePerBlock = nvlOnlyNumber(props.pagePerBlock) === 0 ? 10 : props.pagePerBlock; // 한 블럭의 페이지 수

	let totalPage = 0;
	let pageListCnt  = 0;

	let totalBlock = 0;
	let currentBlock = 0;

	let startPage = 0;
	let endPage = 0;
	
	let prevBlock = 0;
	let nextBlock = 0;
	
	currentPage--; // 이건 1부터 시작

	if(totalCount === 0) totalPage = 0;
	else {
		totalPage = parseInt(Math.ceil(totalCount / numPerPage)); // 전체 페이지 갯수
		
		if(totalPage > 0) totalBlock = parseInt(Math.ceil(totalPage / pagePerBlock)); // 전체 블럭 갯수
		else totalBlock = 1;
		
		if(currentPage > 0) currentBlock = parseInt(currentPage / pagePerBlock) + 1; // 현재 페이지의 블럭
		else currentBlock = 1;

		// --------------------------------------------
		
		if(currentPage > 0) startPage = parseInt(currentPage / pagePerBlock) * pagePerBlock;

		endPage = startPage + pagePerBlock;

		if(endPage > totalPage) endPage = totalPage; // 페이지 갯수가 한 블럭을 다 못 채울 때

		pageListCnt = endPage - startPage; // 현재 페이지 넘버 총 갯수
		
		// --------------------------------------------
		
		prevBlock = (currentBlock - 1) * pagePerBlock + 1;
		nextBlock = currentBlock * pagePerBlock + 1;

		// console.log("totalPage : " + totalPage + " | currentBlock : " + currentBlock + " | startPage : " + startPage + " | endPage : " + endPage);
		// console.log("totalBlock :" + totalBlock + " | pageListCnt : " + pageListCnt + " | prevBlock : " + prevBlock + " | nextBlock : " + nextBlock);
		// console.log("totalCount : " + totalCount + " | numPerPage : " + numPerPage);
	}

	const pageNumbering = () => {
		let aTagList = []

		for (let i = 0; i < pageListCnt; i++) {
			aTagList.push(
        currentPage === (startPage + i)	?
		      <a href="#/" key={"active" + i} className="active">{ startPage + i + 1 }</a>
			  :
          <a href="#/" onClick={ () => props.nextPageMove(startPage + i + 1) } key={"nonActive" + i}>{ startPage + i + 1 }</a>
      )
		}

		return aTagList;
	}

	return (
		<>
			<div className="com_paging_container">				
				{
					currentBlock > 1 ?
						<article className="com_paging left">
							<a href="#/" onClick={ () => props.nextPageMove(1) } className="first"><i className="svg_icon paging_arrow icon_prev">&nbsp;</i><i className="svg_icon paging_arrow icon_prev">&nbsp;</i></a>
							<a href="#/" onClick={ () => props.nextPageMove(prevBlock) } className="prev"><i className="svg_icon paging_arrow icon_prev">&nbsp;</i></a>
						</article>
					:
						<article className="com_paging left">
							<span className="first"><i className="svg_icon paging_arrow icon_prev">&nbsp;</i><i className="svg_icon paging_arrow icon_prev">&nbsp;</i></span>
							<span className="prev"><i className="svg_icon paging_arrow icon_prev">&nbsp;</i></span>
						</article>
				}
				{
					totalCount > numPerPage ? 
						<article className="com_paging">
							{ pageNumbering() }
						</article>
					:
						<article className="com_paging">
							<span className="active">1</span>
						</article>
				}
				{
					currentBlock < totalBlock ?
						<article className="com_paging right">
							<a href="#/" onClick={ () => props.nextPageMove(nextBlock) } className="next"><i className="svg_icon paging_arrow icon_next">&nbsp;</i></a>
							<a href="#/" onClick={ () => props.nextPageMove(totalPage) } className="last"><i className="svg_icon paging_arrow icon_next">&nbsp;</i><i className="svg_icon paging_arrow icon_next">&nbsp;</i></a>
						</article>
					:
						<article className="com_paging right">
							<span className="next"><i className="svg_icon paging_arrow icon_next">&nbsp;</i></span>
							<span className="last"><i className="svg_icon paging_arrow icon_next">&nbsp;</i><i className="svg_icon paging_arrow icon_next">&nbsp;</i></span>
						</article>
				}
			</div>
		</>
	)
}

export default Paging