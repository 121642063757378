/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import CashReceipt from "./_CashReceipt";
import CreditCard from "../../atoms/payment/CreditCard";
import Document from "./_Document";
import { nvl, nvlNumber, convertJuminToAge, convertGender } from "utils/Common.utils";
import MedicalFeeManage from "./_MedicalFeeManage";
import PrescriptionPrint  from "./_Prescription";
import { useSelector } from "react-redux";

import { MakeAgentSpec } from "utils/KisAgent";

const PaymentDetail = ({ onClose, setAlertPop, userData, payTreatment, taxGoods, taxFreeGoods, prescriptionInfo, setPrescriptionInfo, medicineSetList, setMedicineSetList, userInfo, setUserInfo }) => {
  const componentRef = useRef();
  const nodeRef = useRef(null);
  const cardRef = useRef();
  const cashRef = useRef();
  const bankRef = useRef();

  const numArr = [1,2,3,6,12];
  const stateToday = useSelector(state => state.Today);
  const [mediCateList, setMediCateList] = useState([]);
  const [mediMangeList, setMediMangeList] = useState([]);
  const [categoryShown, setCategoryShown] = useState([]); //진료비 구분안에 진료비 명칭
  const [categoryShown2, setCategoryShown2] = useState([]);
  const [categoryShown3, setCategoryShown3] = useState([]);
  const [keep, setKeep] = useState(0);
  const [copay, setCopay] = useState(0);
  const [managerList, setManagerList] = useState([]); 
  const accountList = [{value:1, account:"농협 / 465-6542123-6541321 ㈜올리프트"}, {value:2, account:"하나 / 465-6542123-6541321 대전지점"}];
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [remain, setRemain] = useState(0);
  const [currentInput, setCurrentInput] = useState({name: '', value: ''});
  const [prescriptionCount, setPrescriptionCount] = useState(0);

  const [creditCardInfo, setCreditCardInfo] = useState({
    installment: 1, // 할부개월
    totalPrice: 0, // 총금액
    tax: 0, // 세금
    taxFreePrice: 0, // 면세금액
    serviceCharge: 0, // 봉사료
    currentDate: stateToday // 원거래 일시
  })

  const [creditCardActivate, setCreditCardActivate] = useState({
    credit: false,
    creditCancel: false,
    cash: false,
    cashCancel: false
  })

  const [popup, setPopup] = useState({
    cashReceipt1: false,
    medicalFeeManage: false,
    document: false,
    prescriptionPrint: false
  });

  const [selectMediFee, setSelectMediFee] = useState({
    mediCate1: '',
    medi1: '',
    mediCate2: '',
    medi2: '',
    mediCate3: '',
    medi3: '',
  });

  const mediFee1 = nvl(selectMediFee.medi1?.split("|")[0]) === "" ? Number(0) : Number(selectMediFee.medi1?.split("|")[0])
  const mediFee2 = nvl(selectMediFee.medi2?.split("|")[0]) === "" ? Number(0) : Number(selectMediFee.medi2?.split("|")[0])
  const mediFee3 = nvl(selectMediFee.medi3?.split("|")[0]) === "" ? Number(0) : Number(selectMediFee.medi3?.split("|")[0])
  const paymentIncludingMediFee = Number(payTreatment.payment_sum) + Number(keep) + Number(copay) + Number(mediFee1) + Number(mediFee2) + Number(mediFee3)

  const payInit = {
    cardPrice: paymentIncludingMediFee,
    payType: "1", // 1: 일시불, 2 ~ : 할부
    installments: '',
    cashPrice: '',
    received: '',
    bankPrice: '',
    bankDepositor: '', // 예금자명
    bankAccount: '',
    receiverSeq: '', // 수납자
    exchange: '' //거스름돈
  }

  const [payInfo, setPayInfo] = useState(payInit);

  const hospitalInit = {
    hospital_name: "톡스앤필의원 대전둔산점", // 병원명
    hospital_phone: "042-719-7827", // 병원전화번호
    hospital_address: "대전광역시 서구 대덕대로 223", // 병원주소 
    hospital_address_detail: "대우토피아빌딩 403호", // 병원 상세주소
  }

  const [hospitalInfo, setHospitalInfo] = useState(hospitalInit);

  //2024-01-12 카드단말 Websocket _____________________
  const ws = useRef(null);
  const [wsConnected, setWsConnected] = useState(false);  //웹소켓(CAT단말) 연결 상태
  const [sendMsg, setSendMsg] = useState(false);          //전문 전송 여부
  const [wsObjectId, setWsObjectId] = useState(null);
    
  useEffect(() => {
    let wsUrl = 'ws://localhost:1516';
    ws.current = new WebSocket(wsUrl);
    ws.current.onopen = () => {
      setWsConnected(ws.current.readyState);
      console.log("KIS OPEN.");
    };    
  }, []);

  useEffect(() => {
    console.log('readyState', ws.current.readyState);
  },[wsConnected]);

  //CAT. 전송결과 - 수신
  useEffect(() => {
    ws.current.onmessage = function (event) {
      if (sendMsg) {
        let catReciveData = JSON.parse(event.data);
        //setCatRespInfo(catReciveData);

        console.log('CAT 수신::', catReciveData);
        /*
        outReplyCode - 5006: 기 취소 거래 / 5009: 기 매입거래 / UC13: 단말기에서 취소
        outTranAmt: 결제금액 / outAuthNo: 승인번호 / outReplyDate: 거래일자 / outAccepterName: 매입사(카드사) 명
        */
        if( catReciveData?.outReplyCode === "0000" ){
          console.log('카드결제 완료.');
          paymentActivate(catReciveData);  //결제완료 처리
          //setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '카드결제가 완료되었습니다.', alertType: 'info' } });
        } else if( catReciveData?.outReplyCode === "UC13" ){
          setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '취소하였습니다.', alertType: 'info' } });
        } else {
          setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: `승인거절[${catReciveData?.outReplyCode}] - ${catReciveData?.outDisplayMsg
          }`, alertType: 'alert' } });
        }
        setWsObjectId(catReciveData.wsObjectId);
      }
    };
  }, [sendMsg]);

  //결제 - TEST
  const KisTest = () => {
    if(wsConnected) {
      let reqData = MakeAgentSpec();  //전문
      reqData.KIS_ICApproval.inTranAmt = "1004";  //승인금액
      console.log("KisTest::reqData", reqData);
      ws.current.send(JSON.stringify(reqData));
      setSendMsg(true);
    } else {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '카드 단말기가 연결되지 않았습니다.', alertType: 'alert' } })
    }
  }

  //카드결제 - CAT.
  const KisCatReq = () => {
    if(wsConnected) {
      const installments = payInfo.payType === "1" ? "" : payInfo.installments;
      let reqData = MakeAgentSpec();  //전문 생성
      reqData.KIS_ICApproval.inTranAmt = payInfo.cardPrice.toString();  //결제금액
      reqData.KIS_ICApproval.inInstallment = installments.toString();   //할부 개월
      //부가세
      ws.current.send(JSON.stringify(reqData));
      setSendMsg(true);
      console.log('reqData', reqData);
    } else {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '카드 단말기가 연결되지 않았습니다.', alertType: 'alert' } });
    }
  }
  //2024-01-12 카드단말 Websocket 끝._________________________________________

  const onClosePop = (popupName) => {
    setPopup((prev) => { return { ...prev, [popupName]: false } });
  }

  const onPopupOpen = (popupName) => {
    setPopup((prev) => { return { ...prev, [popupName]: true } })
  }

  const cashReceiptClickHandler = (e) => {
    const { id, checked } = e.target;

    if(checked) onPopupOpen(id);
    else onClosePop(id);
  }

  const checkPayment = () => {
    if (nvlNumber(payInfo.cardPrice) > 0) { //카드결제
      KisCatReq();
    } else {  //일반결제 - 결제완료 처리
      paymentActivate(null);
    }
  }

  //결제처리
  const paymentActivate = (cardInfo) => {
    if(Number(payInfo.cardPrice) +  Number(payInfo.cashPrice) + Number(payInfo.bankPrice) > paymentIncludingMediFee){
      setAlertPop((prev) => { 
        return { ...prev, visible: true, alertMessage: '입력하신 금액이 초과 되었습니다.', alertType: 'alert' } 
      }); 
    } else {
      let params = {
        ...payTreatment,
        payment_card: nvl(payInfo.cardPrice) === "" ? 0 : Number(payInfo.cardPrice), 
        payment_cash: nvl(payInfo.cashPrice) === "" ? 0 : Number(payInfo.cashPrice),
        payment_bank: nvl(payInfo.bankPrice) === "" ? 0 : Number(payInfo.bankPrice),
        unpaid_price : nvl(remain) === "" ? 0 : Number(remain),
        treatment_fee: 0,
        treatment_fee_detail: [],
        pay_status: '014004000', //결제완료,
        catRespInfo: cardInfo
      }
      
      for (let i = 1; i <= 3; i++) {
        const mediField = `medi${i}`;
        const mediCateField = `mediCate${i}`;
        const mediValue = selectMediFee[mediField];
        const mediCateValue = selectMediFee[mediCateField];
      
        if (mediValue && mediCateValue) {
          const [price, name] = mediValue.split("|");
      
          params.treatment_fee_detail.push({
            treatment_fee_name: name,
            treatment_fee_price: Number(price),
            treatment_fee_type: mediCateValue
          });
          
          params.treatment_fee += Number(price);
        }
      }
      
      const successHandler = (response) => {
        if (response.messageCode === 200) {
          setAlertPop((prev) => { 
            return { 
              ...prev, 
              alertType: "alert", 
              visible: true, 
              alertMessage: `결제를 완료하였습니다.`, 
              activateMode: "regPubCoupon"
            }
          });
          onClose();
        } else {
          console.log(`결제관련 데이터를 저장하는데 오류가 발생했습니다.`)
        }
      }
      
      const failHandler = (response) => {
        console.log("에러" + response);
      }
      
      request.post("/api/payment", params, successHandler, failHandler, null, null);
  
      setCreditCardActivate((prev) => { return { ...prev, credit: true } });
    }
  }
 

  useEffect(() => {
    setCreditCardInfo((prev) => { return { ...prev, totalPrice: payTreatment.payment_sum } });

    return () => { // 다른 화면으로 이동하기 전 초기화

    }
  }, []);



  const returnResult = (resultValue) => {
    console.log("결과", resultValue)

    setCreditCardActivate({ credit: false, creditCancel: false, cash: false, cashCancel: false }) // 초기화
  }


  // 진료비 구분 리스트 가져오기
  const getMedicalFeeList = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) {
        setMediCateList(response.data.dataList);
        getMedicalManageList();
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    request.get("/api/etc/commoncd/2/016", null, successHandler);
  }

    // 진료비 명칭 리스트 가져오기
    const getMedicalManageList = (goods_seq) => {
    const successHandler = (response) => {
      if(response.messageCode === 200) {
        setMediMangeList(response.data);
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    request.get("/api/medicalfee?use_yn=Y&order_by=fee_seq", null, successHandler);
  }


  const onChangeMediFee = (e) => {
    const {name, value} = e.target;
    if(name === "mediCate1"){
      const newArray = mediMangeList.filter(el => value === el.treatment_fee_type);
      setCategoryShown(newArray);
    } else if(name === "mediCate2"){
      const newArray = mediMangeList.filter(el => value === el.treatment_fee_type);
      setCategoryShown2(newArray);
    } else if(name === "mediCate3"){
      const newArray = mediMangeList.filter(el => value === el.treatment_fee_type);
      setCategoryShown3(newArray);
    }
    setSelectMediFee((prev) => ({...prev, [name]: value}));
  }


  //결제수단 및 금액 변경
  const onChangePayInfo = (e) => {
    const {name, value} = e.target;
    if(name === "installments"){
      if(payInfo.payType === "1"){
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '할부를 선택해주세요.', alertType: 'alert' } });
        return;
      }
    } else if(name === "payType" && value === "1"){
      setPayInfo((prev) => ({ ...prev, installments: "" }));
    }
    setCurrentInput((prev) => ({ ...prev, name, value}));

    const changeValue = value === '0' ? '' : value;

    setPayInfo((prev) => ({ ...prev, [name]: changeValue }));
  }


  useEffect(() => { 
    setPayInfo((prev) => ({...prev, exchange: payInfo.received - payInfo.cashPrice}))

    const paymentWithoutMediFee = Number(payInfo.cardPrice) +  Number(payInfo.cashPrice) + Number(payInfo.bankPrice); // 진료비 제외 (카드+현금+통장입금)

    if (!isNaN(paymentWithoutMediFee)) {
      if (nvlNumber(paymentIncludingMediFee) === 0 || paymentIncludingMediFee > paymentWithoutMediFee) {
        setRemain(Math.abs(paymentIncludingMediFee - (paymentWithoutMediFee)));
      }else{
        setRemain(0);
      }
    }
  }, [payInfo.cardPrice, payInfo.cashPrice, payInfo.bankPrice, payInfo.received]);

  //스탭 목록
  const getManager = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setManagerList(response?.data);
      } else {
        console.log("등록자 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/manager`, null, successHandler, failHandler, null, null);
  }

  const ChangeToRecipientPhoneNumber = (userList) => {
    let result = [];
    for (const d of userList) {
      const phone_number = d.phone_number.replace(/-/g, "");
      result.push({ to: phone_number });
    }
    return result
  }

  //SMS 전송
  const sendMessage = () => {

    if(payInfo.bankAccount === ""){
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '계좌를 선택해주세요.', alertType: 'alert' } });
    }else{
      let params = {
        sendMethod: "M",
        sendType: "N",
        msgCate: "",
        msgStatus: "R",
        recipientPhoneNumber: ChangeToRecipientPhoneNumber([{phone_number: `${userData.mobile_1}-${userData.mobile_2}-${userData.mobile_3}`}]),
        subject: "올리프트 웹차트",
        content: payInfo.bankAccount,
        managerSeq: managerInfo.seq
      }

      const successHandler = (response) => {
        console.log(response)
        if (response.messageCode === 200) {
          setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '문자로 계좌번호를 전송하였습니다.', alertType: 'alert' } });
        }
      }
      const failHandler = (response) => {
        console.log("에러" + response)
      }
      request.post("/api/message/sms/send", params, successHandler, failHandler, null, null);
    }
  } 
  

  const getPrescription = () => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if(response.messageCode === 200) setPrescriptionCount(response.data.length)
      else console.debug('Error >> ', response);
    }
    request.get(`/api/prescription/${userData.user_seq}/${stateToday}`, null, successHandler, failHandler, null);
  };
  

  useEffect(() => {
    getMedicalFeeList(); 
    getManager();
    getPrescription();
  }, [userData]);

  useEffect(() => {
    setPayInfo((prev) => ({...prev, cardPrice: Number(payTreatment.payment_sum) + Number(keep) + Number(copay) + Number(mediFee1) + Number(mediFee2) + Number(mediFee3)}))
  },[mediFee1, mediFee2, mediFee3, userData])


	return (
		<>
      <section className="com_popup type_normal pop_pay_detail active">
        {/* <Draggable nodeRef={nodeRef}> */}
          <div /*ref={nodeRef}*/ className="pop_container">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">결제</h1>
            </div>
            <div className="pop_body">
              <article className="top_area">
                <p className="top_info">
                  {`${moment(new Date()).format("YYYY,MM,DD")}`} /&nbsp;
                  {userData.user_name} 
                  &nbsp;(
                    {convertGender(userData.user_gender)} / 
                    {
                      nvl(userData.user_age) === "" ?
                        (nvl(userData.jumin_1) === "" ? " - ": convertJuminToAge(userData.jumin_1 + "-" + userData.jumin_2))
                      :
                        userData.user_age
                    }
                  )
                </p>
                <div className="btn_top_area">
                  <button className="com_btn blue m" id="document" onClick={() => onPopupOpen('document')}><i className="svg_icon icon_print yellow"></i>서류 발급</button>
                  <button className="com_btn blue m" id='medicalFeeManage' onClick={() => onPopupOpen('medicalFeeManage')}><i className="svg_icon icon_set yellow"></i>진료비 관리</button>
                </div>
              </article>
              <div className="sec_wrap">
                
                <div className="left">
                  <article className="com_list">
                    <table className="list">
                      <colgroup><col width="13%"/><col /><col width="18%"/><col width="18%"/></colgroup>
                      <thead>
                        <tr>
                          <th colSpan="4">과세 항목</th>
                        </tr>
                        <tr>
                          <th>비고</th>
                          <th>시술명</th>
                          <th>급여</th>
                          <th>금액</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxGoods?.length > 0 ? taxGoods.map((el, i) => (
                            <tr key={i}>
                              <td>시술</td>
                              <td className="tit">{el.goods_name}</td>
                              <td>{el.insurance_type === "N" ? "비급여" : "급여"}</td>
                              <td className="num">{new Intl.NumberFormat().format(el.goods_price)}</td>
                            </tr>
                          )) : (
                            <tr>
                              <td colSpan="4">데이터가 없습니다.</td>
                            </tr>
                        )}
                      </tbody>
                    </table>
                    <table className="list">
                      <colgroup><col width="13%"/><col /><col width="18%"/><col width="18%"/></colgroup>
                      <thead>
                        <tr>
                          <th colSpan="4">비과세 항목</th>
                        </tr>
                        <tr>
                          <th>비고</th>
                          <th>시술명</th>
                          <th>급여</th>
                          <th>금액</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxFreeGoods?.length > 0 ? taxFreeGoods.map((el, i) => (
                              <tr key={i}>
                                <td>시술</td>
                                <td className="tit">{el.goods_name}</td>
                                <td>{el.insurance_type === "N" ? "비급여" : "급여"}</td>
                                <td className="num">{new Intl.NumberFormat().format(el.goods_price)}</td>
                              </tr>
                            )) : (
                            <tr>
                              <td colSpan="4">데이터가 없습니다.</td>
                            </tr>
                        )}
                      </tbody>
                    </table>
                  </article>
                </div>
                
                <div className="right">
                  <p className="sec_tit"><i className="bullet">*</i> 항목 결제 금액</p>
                  {/*항목*/}
                  <div className="payment_wrap">
                    <ul className="com_input_section payment">
                      <li className="tit_field">급여</li>
                      <li className="input_field">{nvlNumber(payTreatment.insurance_total) === 0 ? 0 : new Intl.NumberFormat().format(payTreatment.insurance_total)}</li>
                      <li className="tit_field">비급여</li>
                      <li className="input_field">{nvlNumber(payTreatment.uninsured_total) === 0 ? 0 : new Intl.NumberFormat().format(payTreatment.uninsured_total)}</li>
                    </ul>
                    <ul className="com_input_section payment">
                      <li className="tit_field">과세 금액</li>
                      <li className="input_field">{nvlNumber(payTreatment.taxation_total) === 0 ? 0 : new Intl.NumberFormat().format(payTreatment.taxation_total)}</li>
                      <li className="tit_field pd_bottom">비과세 금액</li>
                      <li className="input_field pd_bottom">{nvlNumber(payTreatment.tax_free_total) === 0 ? 0 : new Intl.NumberFormat().format(payTreatment.tax_free_total)}</li>
                      <li className="tit_field">- 쿠폰할인 금액</li>
                      <li className="input_field">{nvl(payTreatment.coupon_amt) !== "" ? new Intl.NumberFormat().format(payTreatment.coupon_amt) : 0}</li>
                      <li className="tit_field">- 포인트 결제 금액</li>
                      <li className="input_field">{nvl(payTreatment?.point_amt) !== "" ? new Intl.NumberFormat().format(payTreatment?.point_amt) : 0}</li>
                      <li className="tit_field">결제 금액</li>
                      <li className="input_field">
                        <span className="txt_blue">{nvlNumber(payTreatment.payment_sum) !== 0 ? new Intl.NumberFormat().format(payTreatment.payment_sum) : 0} 원</span>
                      </li>
                    </ul>
                  </div>
                  
                  {/* 진료비 */}
                  <div className="medical_fee">
                    <p className="sec_tit"><i className="bullet">*</i> 진료비</p>
                    <ul className="com_input_section payment">
                      <li className="tit_field">
                        <select className="select" name="mediCate1" value={selectMediFee.mediCate1} onChange={onChangeMediFee}>
                          <option value="">진료비 구분 선택</option>
                          {mediCateList?.map((el, i) => (
                            el.code_level > 1 ? 
                            <option key={el.common_code} value={el.common_code}>{el.code_name}</option>
                            : null
                          ))}
                        </select>
                        <select className="select" name="medi1" value={selectMediFee.medi1} onChange={onChangeMediFee}>
                          <option value="">진료비 명칭 선택</option>
                          {categoryShown.map((el, i) => (
                            <option key={i} value={`${el?.treatment_fee_price}|${el.treatment_fee_name}`}>
                              {el?.treatment_fee_name}
                            </option>
                          ))}
                        </select>
                      </li>
                      <li className="input_field">{nvl(selectMediFee?.medi1?.split("|")[0]) === "" ? 0 : new Intl.NumberFormat().format(selectMediFee?.medi1?.split("|")[0])}</li>
                      <li className="tit_field">
                        <select className="select" name="mediCate2" value={selectMediFee.mediCate2} onChange={onChangeMediFee}>
                          <option value="">진료비 구분 선택</option>
                          {mediCateList?.map((el, i) => (
                            el.code_level > 1 ? 
                            <option key={el.common_code} value={el.common_code}>{el.code_name}</option>
                            : null
                          ))}
                        </select>
                        <select className="select" name="medi2" value={selectMediFee.medi2} onChange={onChangeMediFee}>
                          <option value="">진료비 명칭 선택</option>
                          {categoryShown2.map((el, i) => (
                            <option key={i} value={`${el?.treatment_fee_price}|${el.treatment_fee_name}`}>
                              {el?.treatment_fee_name}
                            </option>
                          ))}
                        </select>
                      </li>
                      <li className="input_field">{nvl(selectMediFee?.medi2?.split("|")[0]) === "" ? 0 : new Intl.NumberFormat().format(selectMediFee?.medi2?.split("|")[0])}</li>
                      <li className="tit_field">
                        <select className="select" name="mediCate3" value={selectMediFee.mediCate3} onChange={onChangeMediFee}>
                          <option value="">진료비 구분 선택</option>
                          {mediCateList?.map((el, i) => (
                            el.code_level > 1 ? 
                            <option key={el.common_code} value={el.common_code}>{el.code_name}</option>
                            : null
                          ))}
                        </select>
                        <select className="select" name="medi3" value={selectMediFee.medi3} onChange={onChangeMediFee}>
                          <option value="">진료비 명칭 선택</option>
                          {categoryShown3.map((el, i) => (
                            <option key={i} value={`${el?.treatment_fee_price}|${el.treatment_fee_name}`}>
                              {el?.treatment_fee_name}
                            </option>
                          ))}
                        </select>
                      </li>
                      <li className="input_field">{nvl(selectMediFee?.medi3?.split("|")[0]) === "" ? 0 : new Intl.NumberFormat().format(selectMediFee?.medi3?.split("|")[0])}</li>
                      <li className="tit_field">- 건강생활유지비</li>
                      <li className="input_field">{keep}</li>
                      <li className="tit_field">진료비 본인 부담금</li>
                      <li className="input_field">
                        <span className="txt_blue">{copay} 원</span>
                      </li>
                    </ul>
                    <article className="total_wrap">
                      <ul className="com_input_section payment">
                        <li className="tit_field total">총 결제금액</li>
                        <li className="input_field total">
                          <span className="txt_blue">{nvlNumber(paymentIncludingMediFee) !== 0 ? new Intl.NumberFormat().format(paymentIncludingMediFee) : 0} 원</span>
                        </li>
                      </ul>
                    </article>
                  </div>

                  {/* 결제수단 별 금액 */}
                  <ul className="com_input_section select_payment">
                    <li className="tit_field">카드</li>
                    <li className="input_field input_wrap">
                      <input type="number" className="input txt_red" ref={cardRef} name="cardPrice" value={nvlNumber(payInfo.cardPrice) !== 0 ? payInfo.cardPrice : ""} onChange={onChangePayInfo}/><span className="unit">원</span>
                      <fieldset className="com_radio_wrap" name="payType" value={payInfo.payType}>
                        <input type="radio" className="radio" name="payType" id="payType1" value="1" checked={payInfo.payType === "1"} onChange={onChangePayInfo}/>
                        <label className="btn_radio" htmlFor="payType1">&nbsp;</label>
                        <label htmlFor="payType1">일시불</label>
                        <input type="radio" className="radio" name="payType" id="payType2" value="2" checked={payInfo.payType === "2"} onChange={onChangePayInfo}/>
                        <label className="btn_radio" htmlFor="payType2">&nbsp;</label>
                        <select className="select" name="installments" value={payInfo.installments} onChange={onChangePayInfo}
                        disabled={payInfo.payType === "2" ? false: true}>
                          <option value="">개월 선택</option>
                          {numArr.map((el,i) => (
                            <option key={i} value={el}>{el}개월</option>
                          ))}
                        </select>
                        <span className="unit">할부</span>
                      </fieldset>
                    </li>
                    <li className="tit_field">현금</li>
                    <li className="input_field input_wrap">
                      <input type="text" className="input txt_red" ref={cashRef} name="cashPrice" value={payInfo.cashPrice} onChange={onChangePayInfo}/><span className="unit">원</span>
                      <input type="text" className="input" placeholder="받은돈" name="received" value={payInfo.received} onChange={onChangePayInfo}/>
                      <input type="text" className="input" placeholder="거스름 돈" name="exchange" value={nvl(payInfo.received) === "" ? 0 : payInfo.exchange} readOnly/>
                    </li>
                    <li className="tit_field">통장 입금</li>
                    <li className="input_field">
                      <div className="input_wrap">
                        <input type="text" className="input txt_red" ref={bankRef} name="bankPrice" value={payInfo.bankPrice} onChange={onChangePayInfo}/><span className="unit">원</span>
                        <input type="text" className="input" placeholder="입금자명" name="bankDepositor" value={payInfo.bankDepositor} onChange={onChangePayInfo}/>
                      </div>
                      <div className="input_wrap">
                        <select className="select account" name="bankAccount" value={payInfo.bankAccount} onChange={onChangePayInfo}>
                          <option value="">입금계좌 선택</option>
                          {accountList.map((el,i)=>(
                          <option key={i} value={el.account}>{el.account}</option>
                        ))}
                        </select>
                        <button className="com_btn line blue m" onClick={sendMessage}>입금계좌 보내기</button>
                      </div>
                    </li>
                    <li className="tit_field">실 결제 금액</li>
                    <li className="input_field txt_red"><span className="only_txt">{nvlNumber(paymentIncludingMediFee) !== 0 ? new Intl.NumberFormat().format(paymentIncludingMediFee) : 0} 원</span></li>
                    { Number(payInfo.cardPrice) +  Number(payInfo.cashPrice) + Number(payInfo.bankPrice) > paymentIncludingMediFee ?
                      <>
                        <li className="tit_field"></li>
                        <li className="input_field txt_red"><span className="only_txt">입력하신 금액이 초과 되었습니다</span></li>
                      </>
                      :
                      <>
                        <li className="tit_field">미수금</li>
                        <li className="input_field txt_red"><span className="only_txt">{new Intl.NumberFormat().format(remain)} 원</span></li>
                      </>
                    }
                    <li className="tit_field">수납자</li>
                    <li className="input_field input_wrap">
                      <select className="select" name="receiverSeq" value={payInfo.receiverSeq} onChange={onChangePayInfo}>
                        <option value="">수납자 선택</option>
                        {managerList.map((el,i)=>(
                          <option key={i} value={el.manager_seq}>{el.manager_name}</option>
                        ))}
                      </select>
                      <input type="checkbox" className="btn_chk" id="cashReceipt1" onChange={ (e) => cashReceiptClickHandler(e) } /><label className="com_btn chk red m" htmlFor="cashReceipt1">현금영수증발행</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="pop_footer">
              <div className="left">
              {prescriptionCount > 0 ? 
                <>
                  <button className="com_btn l blue line"><i className="svg_icon icon_receipt">&nbsp;</i>영수증</button>
                  <button className="com_btn l blue line" onClick={() => setPopup({ ...popup, prescriptionPrint: true })}><i className="svg_icon icon_print yellow">&nbsp;</i>처방전</button>
                </>
                : <><button className="com_btn l"></button><button></button></>}
              </div>
              <div className="right">
                <button className="com_btn blue l" onClick={ checkPayment } disabled={Number(payInfo.cardPrice) +  Number(payInfo.cashPrice) + Number(payInfo.bankPrice) > paymentIncludingMediFee}>결제하기</button>
                <button className="com_btn blue l" onClick={ KisTest } >카드결제TEST</button>
              </div>
            </div>
          </div>
        {/* </Draggable> */}
      </section>
      {popup.cashReceipt1 && <CashReceipt onClose={() => { onClosePop('cashReceipt1') }} vatYTotalPrice={payTreatment.taxation_total} vatNTotalPrice={payTreatment.tax_free_total} userData={userData}/>}
      {popup.medicalFeeManage && <MedicalFeeManage onClose={() => { onClosePop('medicalFeeManage') }} getMedicalManageList={getMedicalManageList} mediMangeList={mediMangeList} mediCateList={mediCateList} setMediCateList={setMediCateList}/>}
      {popup.document && <Document onClose={() => { onClosePop('document') }} setPrescriptionInfo={setPrescriptionInfo} prescriptionInfo={prescriptionInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList} userInfo={userInfo} setUserInfo={setUserInfo}/>}
      {/* 할부개월 (installment), 총금액 (totalPrice), 세금 (tax), 면세금액 (taxFreePrice), 봉사료 (serviceCharge), 원거래 일시 (currentDate) */}
      <CreditCard creditCardInfo={ creditCardInfo } setCreditCardInfo={ setCreditCardInfo } creditCardActivate={ creditCardActivate } setCreditCardActivate={ setCreditCardActivate }
        setAlertPop={ setAlertPop } returnResult={ returnResult } />
      {popup.prescriptionPrint && <PrescriptionPrint ref={componentRef} selectedCustomer={""} userInfo={userData} prescriptionInfo={prescriptionInfo} setPrescriptionInfo={setPrescriptionInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList} hospitalInfo={hospitalInfo}/>}
		</>
	)
}

export default PaymentDetail