import React, { useState, useRef } from "react";
import { nvl } from "utils/Common.utils";
import Draggable from "react-draggable";
import CustomAlert from "atoms/common/CustomAlert.js"; // alert창
import request from "utils/Request.utils";

const SetPrescriptionManage = ({ onClose, medicineSetCategory, setMedicineSetCategory, getMedicineSetCategory, setOnUpdateBool, getMedicineSetList }) => {
  const nodeRef = useRef(null);

  const [selectedIndex, setSelectedIndex] = useState('');
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값

  // alert창
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: "alert",
    alertMessage: "",
    visible: "",
    activateMode: ""
  })
  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  // 세트처방 선택
  const selectSetMedicine = (e, mediSetSeq) => {
    if (selectedIndex === mediSetSeq) {
      setSelectedIndex("")

      e.target.checked = false
    } else {
      setSelectedIndex(mediSetSeq)

      e.target.checked = true
    }
  }
  console.log(selectedIndex)
  // 세트처방 삭제
  const deleteMedicineSet = (mediSetSeq) => {
    if (mediSetSeq === "") setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "세트처방을 선택해주세요." } })

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setSelectedIndex("")
        setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: true, alertMessage: "세트처방이 삭제되었습니다." } })

        getMedicineSetCategory()
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }

    request.del(`/api/medicine/${mediSetSeq}/${managerInfo.seq}`, null, successHandler, failHandler, null, null);
  }

  // 세트처방 수정 
  const updateMedicineSet = (mediSetSeq) => {
    setOnUpdateBool(true)
    getMedicineSetList("sub", mediSetSeq)
  }

  const returnAlertValue = (value) => {

  }
  return (
    <>
      {/* //* 일반 팝업 */}
      <section className="com_popup type_normal active">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container s">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">세트처방 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">
              <article className="list">
                <ul className="item_wrap scroll">
                  {medicineSetCategory.length > 0 && nvl(medicineSetCategory) !== "" ? medicineSetCategory.map((setCate, index) => (
                    <li className="item" key={setCate.medi_set_seq}>
                      <fieldset className="com_radio_wrap">
                        <input type="radio" className="radio" id={index + "set1"} name="set" onClick={(e) => selectSetMedicine(e, setCate.medi_set_seq)} />
                        <label className="btn_radio" htmlFor={index + "set1"}>&nbsp;</label>
                        <label htmlFor="set1">{setCate.medi_set_name}</label>
                      </fieldset>
                      <button className="com_btn txt" id="add" onClick={() => updateMedicineSet(setCate.medi_set_seq)} >수정</button>
                    </li>
                  )) :
                    <li className="item no_data">데이터가 없습니다.</li>
                  }
                </ul>
              </article>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={() => deleteMedicineSet(selectedIndex)}>삭제</button>
            </div>
          </div>
        </Draggable>
      </section>
      {alertLayerPopup.visible ? <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={returnAlertValue} /> : null}
    </>
  )
}

export default SetPrescriptionManage