//* 고객등급
import React, { useRef, useEffect, useState } from "react";
import Draggable from 'react-draggable';

import request from '../../utils/Request.utils';
import { nvl, nvlNumber } from "../../utils/Common.utils";
import ColorPicker from '../../atoms/common/ColorPicker';
import CustomAlert from '../../atoms/common/CustomAlert';

const CsGrade = (props) => {
  const screenWidth = useRef(window.innerWidth);
  const screenHeight = useRef(window.innerHeight);

  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder()); // 토큰에서 가져온 값
  const [userLevel, setUserLevel] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [viewColorPicker, setViewColorPicker] = useState(false);
  const [alertLayerPopup, setAlertLayerPopup] = useState({ 
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: ''
  });
  
  const [currentColor, setCurrentColor] = useState('#FFFFFF');
  const [activateMode, setActivateMode] = useState('');
  const [layerPosition, setLayerPosition] = useState({x: 0, y: 0});

  const gradeNameRef = useRef();
  const popupSectionRef = useRef();
  const nodeRef = useRef(null);

  const popupClose = () => {
    props.csGradeToggle();
  }

  // 고객 등급 리스트 가져오기
  const getCustomerGrade = () => {
    const successHandler = (response) => {
      if(response.messageCode === 200) { // ok 코드 값
        // console.log("response : " + JSON.stringify(response));

        setUserLevel(response.data.dataList);
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    request.get("/api/etc/commoncd/2/008", null, successHandler); // 공통코드 가져오는 부분 (참고 하세요)
  }

  useEffect(() => {
    getCustomerGrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = (event) => {
    // console.log(event.target.value + " | " + event.target.id)

    setSelectedValue(event.target.value)
  }

  // -- 요기는 칼라피커 부분
  const closeColorPicker = () =>  {
    setViewColorPicker(false);
  }

  const setGradeColor = () => {
    let params = { // code_name 만 빼고
      code_desc: currentColor,
      modi_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      if(response.messageCode === 200) { // ok 코드 값
        // console.log("response : " + JSON.stringify(response));

        // 다시 호출
        getCustomerGrade();
      } else {
        console.log("코드 값을 찾지 못했습니다.");
      }
    }

    const failHandler = (response) => {
      console.log("에라야 에라~~" + response);
    }

    request.put("/api/etc/commoncd/u/" + selectedValue, params, successHandler, failHandler, null, null);
  }

  const getColor = (value) => {
    // console.log("getColor value : " + nvl(value) + " | " + nvl(selectedValue)); // click을 안 하면 undefined 로 넘어옴

    // currentColor = value;

    if(nvl(selectedValue) !== "" && nvl(value) !== "") {
      setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "등급 색상을 변경하시겠습니까?" } })
      setActivateMode('setCradeColor'); // 다음 실행할 함수를 호출하기 위해서

      setCurrentColor(value);
    }
  }

  const openColorPicker = (event, index, commonCode) => {
    // console.log(event.target + " | " + index + " | " + commonCode);

    setSelectedValue(commonCode);
    setViewColorPicker(true);
  }

  const addGradeName = () => {
    let gradeName = gradeNameRef.current.value;

    console.log("gradeName : " + gradeName);

    if(nvl(gradeName) === '') {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "등급명을 입력해주십시오." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "등급을 추가하시겠습니까?" } })
    setActivateMode('addUserGrade');
  }

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const deleteGradeName = () => {
    if(nvl(selectedValue) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "선택된 등급이 없습니다." } })

      return;
    }

    setAlertLayerPopup((prev) => { return { ...prev, alertType: "confirm", visible: true, alertMessage: "선택된 등급을 삭제하시겠습니까?" } })
    setActivateMode('deleteUserGrade'); // 다음 실행할 함수를 호출하기 위해서
  }

  const returnAlertValue = (value) => {
    // console.log("value ==> " + value);

    if(nvl(value) === 'OK') {
      if(activateMode === 'setCradeColor') setGradeColor(); // 칼라피커에서 선택된 색 등급색상에 적용
      else if(activateMode === 'addUserGrade') { // 고객 등급 추가
        let params = {
          parent_code: "008000000",
          code_level: "2",
          code_name: nvl(gradeNameRef.current.value),
          code_desc: "#CECECE",
          reg_manager_seq: managerInfo.seq
        }
    
        const successHandler = (response) => {
          // console.log(response);

          if(response.messageCode === 200) { // ok 코드 값
            gradeNameRef.current.value = "";
    
            // 다시 호출
            getCustomerGrade();
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        request.post("/api/etc/commoncd", params, successHandler);
      } else if(activateMode === 'deleteUserGrade') { // 고객 등급 삭제
        const successHandler = (response) => {
          // console.log(response);

          if(response.messageCode === 200) { // ok 코드 값
            gradeNameRef.current.value = "";
            setSelectedValue('');
    
            // 다시 호출
            getCustomerGrade();
          } else if(response.messageCode === 409) {
            setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "등급내 고객이 있으므로 삭제가 불가능합니다." } })
          } else console.log("코드 값을 찾지 못했습니다.");
        }

        const failHandler = (response) => {
          console.log(response)

          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "삭제중 에러가 발생했습니다.<br/>확인 후 다시 시도해주세요." } })
        }

        request.del(`/api/etc/commoncd/grade/${selectedValue}/${managerInfo.seq}`, null, successHandler, failHandler);
      }
    }
  }

  useEffect(() => {
    if (!nodeRef.current?.clientHeight) return;

    // const xPosition = (screenWidth.current / 2) - nodeRef.current?.offsetWidth
    const xPosition = 0
    const yPosition = (screenHeight.current / 2) - nodeRef.current?.offsetHeight
    
    // console.log(xPosition, yPosition)
    
    setLayerPosition({x: xPosition, y: yPosition})

    if(popupSectionRef?.current) popupSectionRef?.current.classList.add('active');
  }, [nodeRef.current?.clientHeight]);

  const stopHandler = (event, dragElement) => {
    // console.log("x position", dragElement.x)

    setLayerPosition({x: dragElement.x, y: dragElement.y})
  }

	return (
		<>
      <section className="com_popup type_normal pop_crm_grade PopCrmGrade active">

        <Draggable nodeRef={nodeRef} onStop={stopHandler} position={{x: nvlNumber(layerPosition.x), y: nvlNumber(layerPosition.y)}}>
        {/* <Draggable nodeRef={nodeRef}> */}

          <div ref={ nodeRef } className="pop_container s">
            { viewColorPicker ? <ColorPicker onClose={ closeColorPicker } getColor={ getColor } /> : null }
            { alertLayerPopup.visible ? <CustomAlert onClose={ closeCustomAlert } alertType={ alertLayerPopup.alertType } alertMessage={ alertLayerPopup.alertMessage } returnValue={ returnAlertValue } /> : null }
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={ () => popupClose() }></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">고객 등급 관리</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body com_add_item">     
              <fieldset className="add">
                <input type="text" className="input" ref={gradeNameRef} />
                <button type="button" className="com_btn m blue" onClick={ addGradeName }>등록</button>
              </fieldset>
              <article className="list">
                <div>

                  {
                    (userLevel == null || userLevel.length < 1) ?
                    <ul className="item_wrap scroll">
                      <li className="item no_data">등록된 등급이 없습니다.</li>
                    </ul>
                    :
                    <ul className="item_wrap scroll">
                      {
                        userLevel.map((item, index) => (
                          item.code_level > 1 ?
                            <li key={item.common_code} className="item">
                              <fieldset className="com_radio_wrap">
                                <input type="radio" className="radio" id={"grade" + index} name="select_grade" value={item.common_code} //defaultChecked={index === 1}
                                  onChange={ clickHandler } checked={ item.common_code === selectedValue } />
                                <label className="btn_radio" htmlFor={"grade" + index}>&nbsp;</label>
                                <label htmlFor={"grade" + index}>{item.code_name}</label>
                              </fieldset>
                              <button className="color" style={{background: `${item.code_desc}`}} onClick={ (e) => {openColorPicker(e, index, item.common_code)} }>Color</button>
                            </li>
                          : null
                        ))
                      }
                    </ul>
                  }

                </div>
              </article>
              <p className="tip_txt">등급내 고객이 있는 경우 삭제가 불가능합니다.</p>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button type="button" className="com_btn l blue btnPopClose" onClick={ deleteGradeName }>삭제</button>
            </div>
          </div>

        </Draggable>

      </section>
		</>
	)
}

export default CsGrade