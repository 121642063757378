//* 환불
import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import request from "utils/Request.utils";
import { nvl, nvlNumber, convertGender, addComma } from "utils/Common.utils.js";

import { MakeAgentSpec } from "utils/KisAgent"; //CAT. 전문

const Refund = ({ onClose, alertPop, setAlertPop, payTreatment, taxGoods, taxFreeGoods, paySeq, clickHandler }) => {
  const popupSectionRef = useRef();
  const nodeRef = useRef(null);
  const cardRef = useRef();
  const cashRef = useRef();
  const bankRef = useRef();
  const stateCustomer = useSelector(state => state.Customer);
  const todayOrders = [...taxGoods, ...taxFreeGoods];
  const [bankList, setBankList] = useState([]);
  const [currentInput, setCurrentInput] = useState('');
  const [managerList, setManagerList] = useState([]); 
  const [refundList, setRefundList] = useState(todayOrders)
  const refundInit = {
    pay_seq: 0,     //기 결제 시퀀스
    paid_price: 0,  //기 결제 금액
    refund_card: 0,
    refund_cash: 0,
    refund_bank: 0,
    refund_amt: 0,  //환불 합계금액
    penalty_price: 0, //위약금
    bank_code: '',
    bank_own: '',
    bank_account: '',
    reg_manager_seq: 0
  }

  const [payDetail, setPayDetail] = useState();

  const [refundInfo, setRefundInfo] = useState(refundInit);
  const total2 = 100000; //2023-06-08 결제금액 임시로
  //const realPay = Number(refundInfo.cardPrice) + Number(refundInfo.cashPrice) + Number(refundInfo.bankPrice);
  //const remainPay = total2 - Number(refundInfo.cardPrice) - Number(refundInfo.cashPrice) - Number(refundInfo.bankPrice);

  const ws = useRef(null);
  const [wsConnected, setWsConnected] = useState(false);  //웹소켓(CAT단말) 연결 상태
  const [sendMsg, setSendMsg] = useState(false);          //전문 전송 여부
  const [wsObjectId, setWsObjectId] = useState(null);

  //환불처리
  const execRefund = useCallback( async (catReciveData) => {
    let params = refundInfo;
    params.catRespInfo = catReciveData;
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '환불처리가 완료되었습니다.', alertType: 'info' } });
        onClose();
        clickHandler(1); //부모 > 탭메뉴 : 환불
      }
    }
    const failHandler = (response) => {
      console.log("에러" + response)
    }
    request.post("/api/payment/refund", refundInfo, successHandler, failHandler, null, null);
    //console.log('execRefund::refundInfo', refundInfo);
  },[setAlertPop, onClose, clickHandler, refundInfo])

  //2024-01-12 카드단말 Websocket __________________________________________
  useEffect(() => {
    let wsUrl = 'ws://localhost:1516';
    ws.current = new WebSocket(wsUrl);
    ws.current.onopen = () => {
      setWsConnected(ws.current.readyState);
      console.log("KIS OPEN.");
    };    
  }, []);

  useEffect(() => {
    console.log('readyState', ws.current.readyState);
  },[wsConnected]);

  //CAT. 전송결과 - 수신
  useEffect(() => {
    ws.current.onmessage = function (event) {
      if (sendMsg) {
        let catReciveData = JSON.parse(event.data);

        console.log('CAT 수신::', catReciveData);
        /*
        outReplyCode - 5006: 기 취소 거래 / 5009: 기 매입거래 / UC13: 단말기에서 취소
        outTranAmt: 결제금액 / outAuthNo: 승인번호 / outReplyDate: 거래일자 / outAccepterName: 매입사(카드사) 명
        */
        if( catReciveData?.outReplyCode === "0000" ){
          console.log('승인취소 완료.');
          execRefund(catReciveData);
        } else if( catReciveData?.outReplyCode === "UC13" ){
          setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: `${catReciveData.outReplyMsg1}`, alertType: 'info' } });
        } else {
          setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: `승인거절[${catReciveData?.outReplyCode}] - ${catReciveData?.outDisplayMsg
          }`, alertType: 'alert' } });
        }
        setWsObjectId(catReciveData.wsObjectId);
      }
    };
  }, [sendMsg, setAlertPop, execRefund]);

  //승인취소 - CAT.
  const KisCatReq = () => {
    if(wsConnected) {
      if( nvl(payDetail?.pay_detail?.outAuthNo) !== "" ) {
        let reqData = MakeAgentSpec();  //전문 생성
        reqData.KIS_ICApproval.inTradeType = "D2";           //승인취소
        reqData.KIS_ICApproval.inTranAmt = "1004";//refundInfo?.refund_card?.toString();  //취소금액
        reqData.KIS_ICApproval.inOrgAuthNo =  payDetail?.pay_detail?.outAuthNo?.toString();  //원 승인번호
  
        ws.current.send(JSON.stringify(reqData));
        setSendMsg(true);
        console.log('reqData', reqData);
      } else {
        setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '이전 승인 내역이 없습니다.', alertType: 'alert' } });
      }

    } else {
      setAlertPop((prev) => { return { ...prev, visible: true, alertMessage: '카드 단말기가 연결되지 않았습니다.', alertType: 'alert' } });
    }
  }
  //--카드단말 Websocket 끝._______________________________

  //입력 값 변경
  const onChangePayInfo = (e) => {
    const {name, value} = e.target;
    let changeValue = null;

    setCurrentInput(name);
    //const changeValue = value === '0' ? '' : value;
    if(name.indexOf('price') !== -1){
      changeValue = value === '' ? '' : Number(value);
    } else {
      changeValue = value;
    }
    setRefundInfo((prev) => ({ ...prev, [name]: changeValue }));
    CalcuRefundAmt();
  }

  //환불 합계금액 계산
  const CalcuRefundAmt = () =>{
    const refund_amt = (refundInfo.refund_card + refundInfo.refund_cash + refundInfo.refund_bank) - refundInfo.penalty_price;
    setRefundInfo((prev) => ({ ...prev, refund_amt: refund_amt }));
  }


  //은행 목록
  const getBankList = () => {
  
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setBankList(response.data.dataList);
      } else {
        console.log("은행 목록을 가져오지 못했습니다.")
      }
    }
    
    const failHandler = (response) => {
      console.log('에러' + response);
    }
    
    request.get(`/api/bank/list`, null, successHandler, failHandler, null, null);
  }

  //스탭 목록
  const getManager = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setManagerList(response?.data);
      } else {
        console.log("등록자 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/manager`, null, successHandler, failHandler, null, null);
  }

  //결제 상세 내역
  const getPayDetail = (paySeq) => {
    if( nvlNumber(paySeq) > 0 ){
      const successHandler = (response) => {
        if (response.messageCode === 200) {
          console.log('payDetail', response);
          setPayDetail(response?.data);
          setRefundInfo(
            (prev) => ({
            ...prev,
            pay_seq: paySeq,
            paid_price: response?.data?.pay_detail?.payment_sum,
            refund_card: response?.data?.pay_detail?.payment_card,
            refund_cash: response?.data?.pay_detail?.payment_cash,
            refund_bank: response?.data?.pay_detail?.payment_bank
            })
          );
        } else {
          console.log("결제 상세내역 조회 실패!!")
        }
      }

      const failHandler = (response) => {
        console.log('에러' + response);
      }

      request.get(`/api/payment/detail/${paySeq}`, null, successHandler, failHandler, null, null);
    }
  }
  
  /* 시술 삭제
  const deleteTodayOrders = (subTcSeq) => {
    const copy = [...refundList];
    const filterArr = copy.filter(el => el.sub_tc_seq !== subTcSeq);
    setRefundList(filterArr);
  }
  */

  useEffect(() => {
    getBankList();
    getManager();
    getPayDetail();
  }, [])

  //기 결제내역 조회
  useEffect(() => {
    if( nvlNumber(paySeq) > 0 ) getPayDetail(paySeq);
  }, [paySeq])

  //환불 버튼
  const btnRefund = () => {
    if( nvlNumber(refundInfo.refund_card) > 0 ){  //카드 승인취소
      KisCatReq();
    } else {  //일반(나머지)
      execRefund(null);
    }
  }

	return (
		<>
      <section ref={popupSectionRef} className="com_popup type_normal pop_refund active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container xxl">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose() }}></button>
            <div className="pop_header">
              <h1 className="pop_title">환불하기</h1>
            </div>
            <div className="pop_body">
              {/** 시술정보 **********************************************/}
              <article className="com_list">
                <table className="list">
                  <colgroup><col width="6.4%"/><col width="8%"/><col /><col width="11%"/><col width="11%"/><col width="15%"/></colgroup>
                  <thead>
                    <tr>
                      <th></th>
                      <th>비고</th>
                      <th>시술명</th>
                      <th>금액</th>
                      <th>사용 회차</th>
                      <th>환불금액 입력</th>
                    </tr>
                  </thead>
                  <tbody>
                  {payDetail?.treatment_list?.length > 0 ? payDetail?.treatment_list?.map((el, i) => (
                    <tr key={i}>
                      <td>
                        {/*
                        <button className="com_btn lightgray icon btn_del" onClick={(e) => deleteTodayOrders(el.sub_tc_seq)}><i className="svg_icon icon_x" >&nbsp;</i></button>
                        */}
                        <fieldset className="com_check_wrap">
                          <input type="checkbox" name="pay_tc_no" className="checkbox" id={"chk" + i} key={"chk" + i} />
                          <label className="btn_checkbox" htmlFor={"chk" + i}>&nbsp;</label>
                        </fieldset>
                      </td>
                      <td>시술</td>
                      <td className="tit">{el.goods_name}</td>
                      <td className="num">{new Intl.NumberFormat().format(el.goods_price)}</td>
                      <td>1/1</td>
                      <td className="num">
                        <input type="number" className="input price" placeholder="금액입력" value={el.goods_price} />
                      </td>
                    </tr>
                  )):
                    <tr>
                      <td colSpan="6">데이터가 없습니다.</td>
                    </tr>
                  }
                  </tbody>
                </table>
              </article>

              {/** 결제 정보**********************************************/}
              <article className="pay_wrap">
                <div className="com_pay_detail">
                  <p className="com_pay_detail__tit">{nvl(stateCustomer.user_name) === "" ? "" : `${stateCustomer.user_name}(${convertGender(stateCustomer.user_gender)}/${stateCustomer.user_age})`} 결제내역</p>
                  <div className="scroll">
                    <ul className="com_input_section info">
                      <li className="tit_field">결제일</li>
                      <li className="input_field">{payDetail?.pay_detail?.reg_date}</li>
                      <li className="tit_field">결제 방법</li>
                      <li className="input_field">
                        (카드) {addComma(payDetail?.pay_detail?.payment_card)}원<br />
                        (현금) {addComma(payDetail?.pay_detail?.payment_cash)}원<br />
                        (무통장) {addComma(payDetail?.pay_detail?.payment_bank)}원
                      </li>
                      <li className="tit_field">수납자</li>
                      <li className="input_field">{payDetail?.pay_detail?.reg_manager_name}</li>
                      {/*
                      <li className="tit_field">현금 영수증</li>
                      <li className="input_field">90,000원</li>
                      */}
                    </ul>
                  </div>
                </div>
                <ul className="com_input_section payment">
                  <li className="tit_field">총 결제액</li>
                  <li className="input_field">{addComma(payDetail?.pay_detail?.goods_sum)}</li>
                  <li className="tit_field">실 결제 금액</li>
                  <li className="input_field">{addComma(payDetail?.pay_detail?.payment_sum)}</li>
                  <li className="tit_field txt_red pd_bottom">미수금</li>
                  <li className="input_field txt_red pd_bottom"></li>
                  <li className="tit_field">위약금</li>
                  <li className="input_field">
                    <input type="number" name="penalty_price" className="input minus" value={refundInfo.penalty_price} onChange={onChangePayInfo} />
                  </li>
                  <li className="tit_field total">실 환불 금액</li>
                  <li className="input_field total">
                    <span className="txt_blue">{addComma(payDetail?.pay_detail?.payment_sum)}</span>
                  </li>
                </ul>
              </article>

              {/*환불방법 선택*/}
              <p className="com_input_section__sec_tit">환불방법 선택</p>
              <ul className="com_input_section select_payment">
                <li className="tit_field">카드</li>
                <li className="input_field input_wrap">
                  <input type="number" className="input txt_red" ref={cardRef} name="card_price" value={refundInfo.refund_card} onChange={onChangePayInfo}/>
                  <span className="unit">원</span>
                </li>
                <li className="tit_field">현금</li>
                <li className="input_field input_wrap">
                  <input type="number" className="input txt_red" ref={cashRef} name="cash_price" value={refundInfo.refund_cash} onChange={onChangePayInfo}/>
                  <span className="unit">원</span>
                  {/*
                  <input type="number" className="input" placeholder="받은돈" name="received" value={refundInfo.received} onChange={onChangePayInfo}/>
                  <input type="number" className="input" placeholder="거스름 돈" name="exchange" value={nvl(refundInfo.received) === "" ? '' : Number(refundInfo.received) - Number(refundInfo.cashPrice)} readOnly/>
                  */}
                </li>
                <li className="tit_field">통장 입금</li>
                <li className="input_field">
                  <div className="input_wrap">
                    <input type="number" className="input txt_red" ref={bankRef} name="bank_price" value={refundInfo.refund_bank} onChange={onChangePayInfo}/>
                    <span className="unit">원</span>
                    <input type="text" className="input" placeholder="예금주" name="bank_own" value={refundInfo.bank_own} onChange={onChangePayInfo}/>
                  </div>
                  <div className="input_wrap">
                    <select className="select" name="bank_code" onChange={onChangePayInfo}>
                      <option value="0">은행선택</option>
                      {bankList.map((el, i) => (
                        <option key={`bankIdx_${i}`} value={`${el.bank_code}`}>{el.bank_name}</option>
                      ))}
                    </select>
                    <input type="text" className="input account" name="bank_account" value={refundInfo.bankAccount} placeholder="계좌번호 입력" onChange={onChangePayInfo}/>
                  </div>
                </li>
                <li className="tit_field">환불 담당자</li>
                <li className="input_field input_wrap">
                  <select className="select" name="reg_manager_seq" value={refundInfo.reg_manager_seq} onChange={onChangePayInfo}>
                    <option value="">담당자 선택</option>
                    {managerList.map((el,i)=>(
                      <option key={i} value={el.manager_seq}>{el.manager_name}</option>
                    ))}
                  </select>
                </li>
              </ul>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn l blue" onClick={btnRefund}>환불하기</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default Refund