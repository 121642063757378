//* 보험 청구할내역
import React, { useRef } from "react";
import Draggable from "react-draggable";
import moment from "moment";
import { utils, writeFile } from 'xlsx';

const InsuranceList = ({ onClose }) => {
  const nodeRef = useRef(null);
  const tableRef = useRef();
  const todayDate = moment(new Date()).format("YYYYMMDD");

  const exportFile = () => {
    const table = tableRef.current;
    const workSheet = utils.table_to_sheet(table);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "SalesData");
    writeFile(workBook, `현금영수증_${todayDate}.xlsx`);
  };


	return (
		<>
      <section className="com_popup type_normal pop_insurance_list active">
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => { onClose(); }}></button>
            <div className="pop_header">
              <h1 className="pop_title">청구할 내역</h1>
            </div>
            <div className="pop_body">
              <section className="com_num_card__row">
                <article className="com_num_card">
                  <p className="tit">명세서 건 수</p>
                  <div className="info">
                    보험료 <span className="txt_blue">22</span>건,   급여 <span className="txt_blue">12</span>건
                  </div>
                </article>
                <article className="com_num_card">
                  <p className="tit">청구 합계</p>
                  <div className="info">
                    <span className="txt_blue">41,45,120,000원</span>
                    <p className="txt">(보험료 65,432,000원<span className="divisionBar">|</span>3134,000원)</p>
                  </div>
                </article>
                <article className="com_num_card">
                  <p className="tit">본인 부담금</p>
                  <div className="info">
                    <span className="txt_blue">41,45,120,000원</span>
                    <p className="txt">(보험료 65,432,000원<span className="divisionBar">|</span>3134,000원)</p>
                  </div>
                </article>
              </section>
              <article className="com_list" >
                <table className="list" ref={tableRef}>
                  <colgroup><col width="7.4%"/><col width="9%"/><col width="9%"/><col /><col width="9%"/><col width="9%"/><col width="9%"/><col width="9%"/><col width="9%"/><col width="9%"/><col width="9%"/></colgroup>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>진료일</th>
                      <th>이름</th>
                      <th>주민번호</th>
                      <th>진료의</th>
                      <th>종류</th>
                      <th>건보 청구 금액</th>
                      <th>장애인 의료비</th>
                      <th>지원금</th>
                      <th>본인부담금</th>
                      <th>진료비 총액</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>2022-03-03</td>
                      <td>홍길동 (여/39)</td>
                      <td>987131-2******</td>
                      <td>김의사</td>
                      <td>보험</td>
                      <td>50,000</td>
                      <td>50,000</td>
                      <td>50,000</td>
                      <td>50,000</td>
                      <td>50,000</td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="com_total_price">
                <p className="item">
                  <span className="tit">건보 청구 총액</span>
                  <span className="txt_blue">466,456,000</span>
                </p>
                <p className="item">
                  <span className="tit">진료비 총액</span>
                  <span className="txt_blue">466,456,000</span>
                </p>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn l blue" onClick={() => { onClose(); }}>확인</button>
              <button className="com_btn l blue" onClick={exportFile}><i className="svg_icon icon_down white">&nbsp;</i>다운로드</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default InsuranceList