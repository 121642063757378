//* 만족도 상세
import React, { useRef } from "react";
import { changeMobileFormat } from "../../utils/Common.utils";
import Draggable from "react-draggable";

const SatisfactionView = ({answerDisplayBool, AnswerDetailToggle, popData}) => {

  const popupSectionRef = useRef();
  const nodeRef = useRef(null);

  if(answerDisplayBool) popupSectionRef.current.classList.add('active');
  else if(!answerDisplayBool && popupSectionRef.current) popupSectionRef.current.classList.remove('active');


  const oppupClose = () => {
    AnswerDetailToggle();
  }

	return (
		<>
			{/* //* 일반 팝업 */}
      <section ref={popupSectionRef} className="com_popup type_normal pop_none_pay PopSatisfactionView">{/*클래스 active추가시 노출*/}
        <Draggable nodeRef={nodeRef}>
          <div ref={nodeRef} className="pop_container">
            <button type="button" className="svg_icon btn_pop_close white btnPopClose" onClick={() => {oppupClose()}}></button>
            {/* //^ 파란색 팝업 타이틀 */}
            <div className="pop_header">
              <h1 className="pop_title">고객만족도</h1>
            </div>
            {/* //^ 팝업 본문 영역 */}
            <div className="pop_body">
              <div className="p_satisfaction_view">
                <article className="top_area box">
                  <div className="customer_field">
                    <p className="tit">{`${popData?.user_name}`} &nbsp;&nbsp;{`${changeMobileFormat(popData?.user_mobile)}`}</p>
                  </div>
                  <div className="date_field">
                    <p className="date">{popData?.date}</p>
                    <p className="time">{popData?.hm}</p>
                  </div>
                </article>
                <article className="satisfaction_content" style={{whiteSpace:"pre-line"}}>
                  <div className="scroll">
                  {popData?.remark === "" ? "미입력" : popData?.remark}
                  </div>
                </article>
              </div>
            </div>
            <div className="pop_footer com_btn_wrap">
              <button className="com_btn blue l" onClick={() => {oppupClose()}}>확인</button>
            </div>
          </div>
        </Draggable>
      </section>
		</>
	)
}

export default SatisfactionView