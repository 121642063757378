//* 로그인 실패 팝업
import React from "react";

const PwFail = (props) => {
	return (
		<>
			{/* //* 알림 팝업 */}
      <section className="com_popup type_alert">
        <div className="pop_container">
          <button type="button" className="svg_icon btn_pop_close btnPopClose"></button>
          {/* //^ 알림 디자인 본문 영역 */}
          <div className="pop_body">
            <p className="alert_text">5회 이상 ID 또는 PW 입력오류입니다.<br/>관리자에게 문의하세요.</p>
          </div>
          {/* //^ 알림 디자인 버튼 영역 */}
          <div className="pop_footer com_btn_wrap one">
            <button type="button" className="com_btn m blue btnPopClose">확인</button>
          </div>
        </div>
      </section>
		</>
	)
}

export default PwFail