/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import request from '../../utils/Request.utils';
import { decrypt } from "../../utils/aes256.manager";
import { v4 as uuidv4 } from 'uuid';
import { nvl, nvlNumber, convertJuminToAge, convertGender, vatCalculator, /* getTodayDate, */ sortByOrderStatus, addComma } from "utils/Common.utils";
import CustomAlert from "atoms/common/CustomAlert";
import CustomCard from "components/common/_CustomCard";
import ReservationAdd from "components/common/_ReservationAdd";
import AnesthesiaAlarm from "components/dashboard/_AnesthesiaAlarm";
import ExplanAdd from "components/chart/_ExplanAdd";
import SnsSend from "components/common/_SnsSend.js";
import MemoCard from "../../components/common/_CustomMemo";
import OrderPrint from "components/chart/_OrderPrint";
import PaymentDetail from "components/payment/_PaymentDetail";
import moment from "moment";
import AnesthesiaList from "./_AnesthesiaList";


const DashVisit = forwardRef(({ receptionList, setReceptionList, userData, setUserData, getReceptionList, orderButtons, setOrderButtons, getSelectedUserInfo, selectHandler, setLnbSearchOptions, changeUserHandler }, ref) => {
  const uploadFileRef = useRef();

  const dispatch = useDispatch();
  const stateTimerInfo = useSelector(state => state.TimerInfo);

  const [userSeq, setUserSeq] = useState('');
  const [imgBase64, setImgBase64] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [surgeryList, setSurgeryList] = useState([]);
  const [allRoomList, setAllRoomList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("012001000");
  const [managerList, setManagerList] = useState([]);
  const [treatment, setTreatment] = useState({})
  const [todayOrders, setTodayOrders] = useState([])
  const [timers, setTimers] = useState([]);
  const [managerInfo, setManagerInfo] = useState(request.tokenDecoder());
  const [snsSendBool, setSnsSendBool] = useState(false);
  const [anesthesiaPopBool, setAnesthesiaPopBool] = useState(false) // 마취중고객 팝업창
  const [orderInfo, setOrderInfo] = useState() // 오더 시퀀스 정보 
  const prescriptionInit = {
    prescription_use: "", // 처방전 용도
    prescription_date: "", // 교부일
    period_amount: "", // 기간
    medical_history_yn: "N", // 주증상 및 병력 제외
    resident_no_yn: "N", // 처방전 주민번호 출력
    including_injections_yn: "N", // 처방전 원내 주사약 포함
    disease_code: "" // 질병코드 추가
  }
  const [prescriptionInfo, setPrescriptionInfo] = useState(prescriptionInit) // 처방전 데이터

  const [medicineSetList, setMedicineSetList] = useState([]) // 세트처방 리스트

  const handleMouseOver = (value) => {
    if (value === "013008000") {
      setAnesthesiaPopBool(true);
    }
  };

  const handleMouseLeave = () => {
    if (anesthesiaPopBool) {
      setAnesthesiaPopBool(false);
    }
  };

  const [customerPopup, setCustomerPopup] = useState({
    memo: false,
    userData: ""
  })

  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    closeType: '',
    returnValue: () => { },
  });

  // alert창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => { return { ...prev, alertType: "alert", visible: false, alertMessage: "" } })
  }

  const [popup, setPopup] = useState({
    customerCard: false,
    reservationAdd: false,
    anesthesiaAlarm: false,
    explainAdd: false,
    order: false,
    paymentDetail: false,
    note1: false
  });

  const [timerButtons, setTimerButtons] = useState([]); // added by psk 20230522 - 레디스로 연동하고 timerButtons는 따로 분리

  const [taxGoods, setTaxGoods] = useState();
  const [taxFreeGoods, setTaxFreeGoods] = useState();
  const [payTreatment, setPayTreatment] = useState({});

  const onClosePop = (componentName) => {
    setPopup((prev) => { return { ...prev, [componentName]: false } });
    setUserData('');
  }

  const [buttons, setButtons] = useState([
    { text: "접수", value: "013002000", className: "receipt" },
    { text: "상담", value: "013003000", className: "counsel" },
    { text: "결제 대기", value: "013004000", className: "pay_ing" },
    { text: "결제 완료", value: "013005000", className: "pay_end" },
    { text: "준비", value: "013007000", className: "ready" },
    { text: "시술", value: "013009000", className: "procedure" },
    { text: "관리", value: "013011000", className: "manage" },
    { text: "마무리", value: "013012000", className: "finish" }
  ]);

  const statusHandler = (index, value, order_seq, user_seq) => {
    const btnStatusSubmitHandler = (value, user_seq) => { // 여기서는 ORDER_MANAGEMENT와 ORDER_CHART_HUB 모두 order_status 를 바꿔야 함
      let params = {
        user_seq: user_seq,
        order_seq: order_seq,
        order_status: value,
        modi_manager_seq: managerInfo.seq,
        order_date: moment().format("YYYY-MM-DD"),
        // change_mode: 'both'  // 대시보드 버튼 변경 오류로 주석처리
      }

      const successHandler = (response) => {
        if (response.messageCode === 200) {
          getReceptionList() // added by wem 20230608 버튼 클릭시 상단 카운트 변경 위해 재호출 => 재호출로 setReceptionList 변경되기 때문에 아래 라인 주석 처리함 
          // setReceptionList((prev) => prev.map((item, idx) => idx === index ? { ...item, order_status: value } : item));
        } else if (response.messageCode === 409) { //수정해야함
          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "버튼 상태를 변경하던 중 에러가 발생했습니다." } })
        } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "버튼 상태를 변경하던 중 에러가 발생했습니다." } })
      }

      const failHandler = (result) => {
        console.log("Error >> " + result);

        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "버튼 상태를 변경하던 중 에러가 발생했습니다." } })
      }

      request.put(`/api/charts/order/dashboard`, params, successHandler, failHandler);
    }

    btnStatusSubmitHandler(value, user_seq);

    // ===========================================================

    const getCurrentTime = new Date().getTime();

    if (value === "013005000") { //결제완료
      if (stateTimerInfo != null && stateTimerInfo.length > 0) {
        let newButtons = stateTimerInfo?.map((orderList, orderIndex) => {
          // console.log("orderIndex", orderIndex)

          if (orderList != null && orderList.length > 0) {
            return orderList?.map((buttonInfo) => {
              if (user_seq === buttonInfo.user_seq && order_seq === buttonInfo.order_seq) {
                return (buttonInfo.value === 0 ? { ...buttonInfo, active: true, isLive: true, startTime: getCurrentTime, curTime: getCurrentTime } : { ...buttonInfo })
              } else return buttonInfo
            })
          } else return null
        })

        // setTimerButtons(newButtons); // useEffect에서 redis로~
        dispatch({ type: "setTimer", payload: { data: newButtons } }); // redux에 저장만 사용하는 걸로 바꿈
      }

    } else if (value === "013007000") { // 준비
      const keyParams = {
        order_date: moment().format("YYYY-MM-DD"),
        user_seq: user_seq,
        order_seq: order_seq,
        value: 0
      }

      const valueParams = {
        order_status: value,
        active: false,
        isLive: false,
        text: "대기시간"
      }

      changeTimerStatus(keyParams, valueParams)
    } else if (value === "013009000") { // 시술
      if (stateTimerInfo != null && stateTimerInfo.length > 0) {
        let newButtons = stateTimerInfo?.map((orderList, orderIndex) => {
          // console.log("orderIndex", orderIndex)

          if (orderList != null && orderList.length > 0) {
            return orderList?.map((buttonInfo) => {
              if (user_seq === buttonInfo.user_seq && order_seq === buttonInfo.order_seq) {
                return (buttonInfo.value === 2 ? { ...buttonInfo, active: true, isLive: true, startTime: getCurrentTime, curTime: getCurrentTime } : { ...buttonInfo, active: false, isLive: false })
              } else return buttonInfo
            })
          } else return null
        })

        // setTimerButtons(newButtons); // useEffect에서 redis로~
        dispatch({ type: "setTimer", payload: { data: newButtons } }); // redux에 저장만 사용하는 걸로 바꿈
      }
    } else if (value === "013011000") { // 관리
      // 시술 시간 멈춤
      const keyParams = {
        order_date: moment().format("YYYY-MM-DD"),
        user_seq: user_seq,
        order_seq: order_seq,
        value: 2
      }

      const valueParams = {
        order_status: value,
        active: false,
        isLive: false,
        text: "시술시간"
      }

      changeTimerStatus(keyParams, valueParams)
    } else if (value === "013012000") { // 마무리
      const keyParams = {
        order_date: moment().format("YYYY-MM-DD"),
        user_seq: user_seq,
        order_seq: order_seq,
        value: 3
      }

      const valueParams = {
        order_status: value,
        active: false,
        isLive: false,
        text: "시술시간"
      }

      changeTimerStatus(keyParams, valueParams)
    } else { // 나머지

    }
  }

  // 첨부파일 등록
  const selectUploadFile = (user_seq) => {
    setUserSeq(user_seq);
    uploadFileRef.current.click();
  }

  const uploadFileInit = () => {
    uploadFileRef.current.value = "";
    setUploadFile(null);
  }

  const uploadFileHandler = (e) => {
    let copyReceptionList = [...receptionList];
    let findIndex = receptionList.findIndex((el) => el.user_seq === userSeq);

    if (e.target.files[0]) {
      setUploadFile(e.target.files[0]);

      let reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        if (base64) setImgBase64(base64.toString());

        if (base64 && findIndex) {
          copyReceptionList[findIndex].file_path = base64.toString();
        }
        setReceptionList(copyReceptionList);
      }
    }

    const formData = new FormData();

    formData.append("uploadFile", e.target.files[0])
    formData.append("file_cate", "userPhoto")
    formData.append("user_seq", nvlNumber(userSeq))
    formData.append("manager_seq", nvlNumber(managerInfo.seq))

    const successHandler = (response) => {
      if (response.messageCode === 200) getReceptionList();
      else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "잘못된 형식의 파일이 있습니다." } })

      uploadFileInit();
    }

    const failHandler = (result) => {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "파일 첨부중 에러가 발생했습니다." } })

      uploadFileInit();
    }

    request.postMultipart("/api/upload/dash/user", formData, successHandler, failHandler);
  }

  const roomClickHandler = (e, index) => {
    const { name, value } = e.target;
    const newList = [...receptionList];

    if (name === "parent_code") {
      newList[index].parent_code = value;
      setReceptionList(newList);
      getRoomList(value, index);
      setSelectedValue(value);
    } else {
      newList[index].treatment_room = value;
      setReceptionList(newList);

      let params = {
        order_seq: newList[index].order_seq,
        user_seq: newList[index].user_seq,
        treatment_room: newList[index].treatment_room,
        modi_manager_seq: managerInfo.seq
      };

      const successHandler = (response) => {
        if (response.messageCode === 200) {

        } else console.log('Error >> ', response);
      }

      request.post('/api/charts/order/room', params, successHandler);
    }
  }

  useEffect(() => {
    const getSurgeryRoomList = () => {
      const failHandler = (response) => {
        console.error("Error >>  " + response);
      }

      const successHandler = (response) => {
        if (response.messageCode === 200) {
          const result = response.data;
          setSurgeryList(result);
          getAllRoomList();
        } else {
          console.debug('Error >> ', response);
        }
      }

      request.get(`/api/etc/commoncd/category/012000000`, null, successHandler, failHandler, null);
    }

    getSurgeryRoomList();
  }, [])

  const getRoomList = (selectedValue, index) => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data;
        const newList = [...receptionList];
        newList[index].roomList = result; // 접수자 개인별로 roomList를 업데이트
        setReceptionList(newList);
        getManagerList();
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/${selectedValue}`, null, successHandler, failHandler, null);
  }


  const getAllRoomList = () => {
    const failHandler = (response) => {
      console.error("Error >>  " + response);
    };

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        const result = response.data;
        setAllRoomList(result);
        // setRoomList(result);
        getManagerList();
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/etc/commoncd/category/3/012000000`, null, successHandler, failHandler, null);
  }


  const getManagerList = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setManagerList(response.data);
      } else {
        console.debug('Error >> ', response);
      }
    }

    request.get(`/api/manager`, null, successHandler);
  }

  const managerHandler = (e, main_tc_seq, user_seq) => {
    const { name, value } = e.target;
    const newList = [...receptionList];

    if (nvl(main_tc_seq) === "") {
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "오더확인 후에 직원선택 할 수 있습니다." } })
    }

    let params = {
      main_tc_seq: main_tc_seq,
      user_seq: user_seq,
      modi_manager_seq: managerInfo.seq
    };

    if (name === "reception") {
      params.receptionist_seq = Number(value);
    } else {
      params.counselor_seq = Number(value);
    }
    setReceptionList(newList);

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        getReceptionList();

      } else console.log('Error >> ', response);
    }

    request.post('/api/charts/order/manager', params, successHandler);
  }




  // added by psk - 레디스에 타이머 저장
  const addTimer = (currentTimer) => {
    let params = {
      order_date: moment().format("YYYY-MM-DD"),
      timerButtons: currentTimer
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        // console.log("등록 성공!!???")

      } else console.log('Error >> ', response);
    }

    //request.post('/api/etc/timer', params, successHandler);//2023-09-11 타이머 POST 사용 안함
  }

  // added by psk - 레디스에 저장된 타이머 정보 가져오기
  const getTimer = () => { // orderKey : 당일 오더의 사용자 정보들
    const getCurrentTime = new Date().getTime();
    const successHandler = (response) => {
      // console.log("일단 값부터 확인", response)

      let timer = {
        user_seq: 0,
        order_seq: 0,
        value: 1,
        startTime: 0,
        curTime: 0,
        alarmTime: 0
      }

      let newButtonList = []
      let searchButtonInfo = {}

      if (response.messageCode === 200) {
        const timerArrayInRedis = response.data

        timerArrayInRedis?.forEach(timerArray => { // [{}, {}, {}, {}]
          timerArray?.forEach(timerInfo => { // {} 
            searchButtonInfo = timers.find((buttonInfo, index, oriArray) => {

              return (timerInfo.user_seq === buttonInfo.user_seq && timerInfo.order_seq === buttonInfo.order_seq && timerInfo.value === buttonInfo.value)
            })
            // console.log(searchButtonInfo, "asdf")
            if (nvl(searchButtonInfo) === '') { // 새로고침 혹은 다른 페이지 갔다 온 경우
              newButtonList.push({
                ...timer, ...timerInfo, user_seq: timerInfo.user_seq, order_seq: timerInfo.order_seq, /* sub_tc_seq: timerInfo.sub_tc_seq, */ value: timerInfo.value,
                startTime: /* timerInfo.active ? timerInfo.startTime + getCurrentTime - timerInfo.curTime : */ timerInfo.startTime, curTime: timerInfo.isLive ? getCurrentTime : timerInfo.curTime, alarmTime: timerInfo.alarmTime
              })
            } else newButtonList.push({ ...searchButtonInfo, startTime: timerInfo.startTime, curTime: timerInfo.curTime, alarmTime: timerInfo.alarmTime });
          })
        })

        setTimers(newButtonList)

      } else console.debug('Error >> ', response);
    }

    request.get('/api/etc/timer', null, successHandler);
  }
  // console.log(timers[1]?.startTime, timers[1]?.curTime, "asdf")

  //현재 시간을 받아온 그대로 사용 - 2023-09-11 
  const getTimer2 = () => { // orderKey : 당일 오더의 사용자 정보들
    const successHandler = (response) => {
      let newButtonList = []
      if (response.messageCode === 200) {
        const timerArrayInRedis = response.data
        setTimerButtons(timerArrayInRedis); //표시되는 타이머 버튼들 KSR

        timerArrayInRedis?.forEach(timerArray => { // [{}, {}, {}, {}]
          timerArray?.forEach(timerInfo => { 
            newButtonList.push(timerInfo);
          });
        });

        if(newButtonList.length > 0) setTimers(newButtonList);

      } else console.debug('Error >> ', response);
    }
    request.get('/api/etc/timer', null, successHandler);
  }

  //KSR
  useImperativeHandle(ref, () => ({
    getTimer2,  //상위 컴포넌트에서 초기 및 소켓 이벤트 발생 시 호출
  }));

  /* 사용안함 2023-09-16 KSR
  useEffect(() => { // 타이머의 상태만 업뎃 (redis로~)
    // console.log("stateTimerInfo ::: ", stateTimerInfo)
    // console.log("asdf")

    //addTimer(stateTimerInfo) // redis에 저장
    //setTimerButtons(stateTimerInfo);

    console.log('useEffect::stateTimerInfo', stateTimerInfo);
  }, [stateTimerInfo]);
  */

  //프론트 단에서 타이머 증가
  const timerUpCount = () => {
    let arrTimers = [];
    timers.forEach((timer, idx) => {
      if(timer.isLive === true){
        arrTimers.push({ ...timer, curTime: timer.curTime + 1 });
      } else {
        arrTimers.push(timer);
      }
    });

    return arrTimers;
  }

  //인터벌(1초) - 타이머 시간 갱신
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimers(timerUpCount());
    }, 1000);

    return () => {
      clearInterval(timerId);
    }
  }, [timers]);

  //기존-밀리초 단위 - 사용안함 KSR
  /*
  const getDisp = (user_seq, order_seq, sub_tc_seq, value) => {
    if (nvl(timers) === '') return "00:00:00";
    else {
      const matchingTimer = timers.find((timer) => timer.user_seq === user_seq && timer.order_seq === order_seq && timer.value === value);
      // console.log(matchingTimer?.startTime, matchingTimer?.curTime, "asdf2")
      if (matchingTimer) {
        const timeDiff = matchingTimer.curTime - matchingTimer.startTime;
         console.log('>> timeDiff', timeDiff)

        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, "0");
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0");
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000).toString().padStart(2, "0");
        // console.log(`${hours}:${minutes}:${seconds}`)
        return `${hours}:${minutes}:${seconds}`;
      } else return "00:00:00";
    }
  }
  */

  //신규-초 단위 KSR
  const getDisp2 = (user_seq, order_seq, sub_tc_seq, value) => {
    if (nvl(timers) === '') return "00:00:00";
    else {
      const matchingTimer = timers.find((timer) => timer.user_seq === user_seq && timer.order_seq === order_seq && timer.value === value);
      if (matchingTimer) {
        const timeDiff = matchingTimer.curTime - matchingTimer.startTime;
        const hours = Math.floor((timeDiff % (60 * 60 * 24)) / (60 * 60)).toString().padStart(2, "0");
        const minutes = Math.floor((timeDiff % (60 * 60)) / (60)).toString().padStart(2, "0");
        const seconds = Math.floor((timeDiff % (60)) / 1).toString().padStart(2, "0");
        //마취 타이머 체크
        
        if(matchingTimer.alarmTime > 0 && matchingTimer.isLive === true && value === 1){
          if( matchingTimer.alarmTime <= timeDiff ){
            console.log('>>>>>>>>> 마취 타이머 알림');
          }
        }
        return `${hours}:${minutes}:${seconds}`;
      } else return "00:00:00";
    }
  }

  //타이머 버튼 클릭 핸들러 - 기존
  /*
  const buttonClickHandler = (user_seq, order_seq, sub_tc_seq, value, className) => {
    // console.log("파라메터 확인 : ", user_seq, order_seq, sub_tc_seq, value, className)
    const getCurrentTime = new Date().getTime();
    if (value !== 1) return; // 마취중이 아닌 경우 선택 안됨
    if (nvl(className) === "") {
      const btnStatusSubmitHandler = () => {
        let params = {
          user_seq: user_seq,
          order_seq: order_seq,
          order_status: "013008000",
          order_date: moment().format("YYYY-MM-DD"),
          change_mode: "both"
        }
        console.log(params)
        const successHandler = (response) => {
          if (response.messageCode === 200) {
            getReceptionList()
          } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "버튼 상태를 변경하던 중 에러가 발생했습니다." } })
        }

        const failHandler = (result) => {
          console.log("Error >> " + result);

          setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "버튼 상태를 변경하던 중 에러가 발생했습니다." } })
        }

        request.put(`/api/charts/order/dashboard`, params, successHandler, failHandler);
      }//End. btnStatusSubmitHandler

      btnStatusSubmitHandler()

      if (stateTimerInfo != null && stateTimerInfo.length > 0) {
        let newButtons = stateTimerInfo?.map((orderList, orderIndex) => {
          // console.log("orderIndex", orderIndex)

          if (orderList != null && orderList.length > 0) {
            return orderList?.map((buttonInfo) => {
              // console.log(buttonInfo, "asdf")
              if (user_seq === buttonInfo.user_seq && order_seq === buttonInfo.order_seq) {
                return (buttonInfo.value === 1 ? { ...buttonInfo, active: true, isLive: true, text: "마취중", startTime: buttonInfo.text === "마취끝" || !buttonInfo.startTime ? getCurrentTime : buttonInfo.startTime, curTime: buttonInfo.text === "마취끝" || !buttonInfo.curTime ? getCurrentTime : buttonInfo.curTime } : { ...buttonInfo })
              } else return buttonInfo
            })
          } else return null
        })

        // setTimerButtons(newButtons); // useEffect에서 redis로~
        dispatch({ type: "setTimer", payload: { data: newButtons } }); // redux에 저장만 사용하는 걸로 바꿈
      }
    } else if (className === "btnReset") {
      const keyParams = {
        order_date: moment().format("YYYY-MM-DD"),
        user_seq: user_seq,
        order_seq: order_seq,
        value: 1
      }

      const valueParams = {
        order_status: "013008000",
        active: true,
        isLive: false,
        text: "일시정지"
      }

      changeTimerStatus(keyParams, valueParams)
    } else if (className === "btnPlay") {
      const keyParams = {
        order_date: moment().format("YYYY-MM-DD"),
        user_seq: user_seq,
        order_seq: order_seq,
        value: 1
      }

      const valueParams = {
        order_status: "013008000",
        active: true,
        isLive: true,
        text: "마취중"
      }

      changeTimerStatus(keyParams, valueParams)
    } else if (className === "btnPause") {
      const keyParams = {
        order_date: moment().format("YYYY-MM-DD"),
        user_seq: user_seq,
        order_seq: order_seq,
        value: 1
      }

      const valueParams = {
        order_status: "013008000",
        active: false,
        isLive: false,
        text: "마취끝"
      }

      changeTimerStatus(keyParams, valueParams)
    }
  }
  */


  // added by psk - 타이머 상태 변경
  const changeTimerStatus = (keyParams, valueParams) => {
    let params = {
      ...keyParams,
      ...valueParams
    }

    const successHandler = async (response) => {
      if (response.messageCode === 200) {
        // setTimerButtons(response.data)
        //dispatch({ type: "setTimer", payload: { data: response.data } }); // redux에 저장만 사용하는 걸로 바꿈        
      } else console.debug('Error >> ', response);
    }

    //request.put('/api/etc/timer', params, successHandler);
  }

  //마취 타이머 버튼, 수정중.... 2023-09-16  KSR
  const buttonClickHandler = (user_seq, order_seq, sub_tc_seq, value, className) => {
    console.log("buttonClickHandler", user_seq, order_seq, sub_tc_seq, value, className)

    //현재 타이머(state => timers) 시간 가져오기
    const matchingTimer = timers.find((timer) => timer.user_seq === user_seq && timer.order_seq === order_seq && timer.value === value);
    const timerCurTime = matchingTimer.curTime;
    const timerStartTime = matchingTimer.startTime;
    const timerText = matchingTimer.text;
    const timerAlarmTime = matchingTimer.alarmTime;

    if (value !== 1) return; // 마취중이 아닌 경우 선택 안됨

    let params = {
      user_seq: user_seq,
      order_seq: order_seq,
      order_status: "013008000",
      order_date: moment().format("YYYY-MM-DD"),
      className: className,
      startTime: timerStartTime,
      alarmTime: timerAlarmTime
    }

    if (nvl(className) === "") {  //최초 init(active)

      params.curTime = 0;
      params.active = 1;
      params.isLive = 1;
      params.text = "마취중";
      params.alarmTime = 0;
    
    } else if (className === "btnReset") {  //현재, 일시정지(active=1, isLive=0)

      params.curTime = timerCurTime;
      params.active = 1;
      params.isLive = 0;
      params.text = "일시정지";

    } else if (className === "btnPlay") { //현재, 마취중 -> 일시정지

      if(timerText === '마취중'){
        params.isLive = 0;
        params.text = "일시정지";
        params.curTime = timerCurTime;
      } else if(timerText === '마취끝') { //마취 끝 상태에서 재생은 초기화(처음부터)
        params.curTime = 0;
        params.isLive = 1;
        params.text = "마취중";
        params.alarmTime = 0;
        params.className = '';
      } else {  //일시정지
        params.isLive = 1;
        params.text = "마취중";
        params.curTime = timerCurTime;
      }
      
      params.active = 1;

    } else if (className === "btnPause") {  //현재, 일시정지 -> 마취완료

      params.curTime = timerCurTime;
      params.active = 1;
      params.isLive = 0;
      params.text = "마취끝";

    }

    //타이머 상태 변경
    btnTimerSubmitHandler(params);
  }

  //PUT > 타이머 상태 변경 _________________________
  const btnTimerSubmitHandler = (rqParams) => {

    //성공
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        getReceptionList()
      } else setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "버튼 상태를 변경하던 중 에러가 발생했습니다.1" } })
    }

    //실패
    const failHandler = (result) => {
      console.log("Error >> " + result);
      setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "버튼 상태를 변경하던 중 에러가 발생했습니다.2" } })
    }

    request.put(`/api/charts/order/dashboard`, rqParams, successHandler, failHandler);
  }//End. 타이머 상태 변경(btnTimerSubmitHandler)


  const memoHandler = (mode, user_seq, goods_seq, order_status) => {

    getSelectedUserInfo(user_seq)

    const selectedReception = receptionList.filter(el => el.user_seq === user_seq)[0];
    const chart_info = selectedReception?.chart_info;

    setOrderInfo({
      order_seq: receptionList.filter(el => el.user_seq === user_seq)[0]?.order_seq,
      order_date: moment(receptionList.filter(el => el.user_seq === user_seq)[0]?.order_date).format("YYYY-MM-DD"),
      counselor_manager_name: receptionList[0]?.counselor_manager_name
    })

    const extractedOrders = chart_info.map((chart, index) => {
      const packInfo = chart.pack_info;
      const goodsInfo = chart.goods_info;

      const goodsPrice = vatCalculator(packInfo.goods_type, packInfo.vat_type, goodsInfo.pack_sale_price, goodsInfo.goods_sale_price, goodsInfo.vat_type, goodsInfo.goods_price);
      return {
        sub_tc_seq: chart.sub_tc_seq,
        pack_seq: packInfo.goods_seq,
        goods_seq: goodsInfo.goods_seq,
        goods_name: goodsInfo.goods_name,
        goods_price: goodsPrice,
        chart_type: chart.chart_type,
        current_times: chart.current_times,
        // used_times: 0, 
        total_times: chart.total_times,
        vat_type: goodsInfo.vat_type,
        insurance_type: goodsInfo.insurance_type,
        reg_date: chart.order_date.slice(0, 10).replaceAll('-', ','),
        comment: goodsInfo.comment,
        id: index
      };
    });

    setTodayOrders(extractedOrders)

    if (mode === 'memo') {
      setTreatment(extractedOrders.find(el => el.goods_seq === goods_seq))
      setPopup((prev) => { return { ...prev, explainAdd: true } });
    } else if (mode === "order") {
      setPopup((prev) => { return { ...prev, order: true } });
    } else {
      if(order_status < "013005000"){
        setAlertLayerPopup((prev) => { return { ...prev, visible: true, alertMessage: "오더확인 후 결제를 진행해주세요" } })
      }else{
        setPopup((prev) => { return { ...prev, paymentDetail: true } });
      }
    }
  }

  const finishClick = (order_seq, user_seq, sub_tc_seq, sub_tc_status) => {
    let params = {
      chart_status: sub_tc_status === "013013000" ? "013009000" : "013013000",
      user_seq: user_seq,
      sub_tc_seq: sub_tc_seq,
      modi_manager_seq: managerInfo.seq
    }

    const successHandler = (response) => {
      if (response.messageCode === 200) {
        getReceptionList(order_seq, user_seq)
      }
    }

    const failHandler = (response) => {
      console.log(response + "에러")
    }

    request.put("/api/charts/sub/status", params, successHandler, failHandler, null, null);
  }

  const finishHandler = (e, user_seq, sub_tc_seq) => {
    const { name, value } = e.target;

    let params = {};

    const preparation_manager_seq = nvlNumber(e.target.parentElement.children[0].value)
    const treatment_manager_seq = nvlNumber(e.target.parentElement.children[1].value)
    const manager_seq = nvlNumber(e.target.parentElement.children[2].value)
    const finish_manager_seq = nvlNumber(e.target.parentElement.children[3].value)

    // console.log("직원 4명 정보", preparation_manager_seq, treatment_manager_seq, manager_seq, finish_manager_seq)

    if (preparation_manager_seq > 0 && treatment_manager_seq === 0 && manager_seq === 0 && finish_manager_seq === 0) {
      params = {
        preparation_manager_seq: preparation_manager_seq,
        treatment_manager_seq: preparation_manager_seq,
        manager_seq: preparation_manager_seq,
        finish_manager_seq: preparation_manager_seq,
        sub_tc_seq: sub_tc_seq,
        user_seq: user_seq,
        modi_manager_seq: managerInfo.seq
      }
    } else {
      params = {
        [name + '_seq']: Number(value),
        sub_tc_seq: sub_tc_seq,
        user_seq: user_seq,
        modi_manager_seq: managerInfo.seq
      }
    }

    if (preparation_manager_seq > 0 || treatment_manager_seq > 0 || manager_seq > 0 || finish_manager_seq > 0) {
      const successHandler = (response) => {
        // console.log(response)
        if (response.messageCode === 200) {
          console.log("따로 메시지는 안 띄움")
          getReceptionList()
        }
      }

      request.put(`/api/charts/sub/managers`, params, successHandler);
    }
  }
  // added by psk 20230602 - 대시보드 카드 클릭 시 처리
  const shadowBoxToggle = (event, item) => {
    // console.log(event.currentTarget, event.currentTarget.children.length, item)

    for (const divList of event.currentTarget.parentElement.children) {
      // console.log("div확인", divList)

      divList.children[0].className = "com_sec shodow_disable"
      divList.children[1].className = "com_sec shodow_disable detail active"
    }

    event.currentTarget.children[0].className = "com_sec shodow_active"
    event.currentTarget.children[1].className = "com_sec shodow_active detail active"

    selectHandler(item.user_seq)
  }


  const getTreatmentPaytInfo = () => {
    const successHandler = (response) => {
      if (response.messageCode === 200) {
        setTaxGoods(response.data.tax_goods)
        setTaxFreeGoods(response.data.tax_free_goods)
        setPayTreatment(response.data)
      } else {
        console.log("결제할 시술 목록을 가져오지 못했습니다.")
      }
    }

    const failHandler = (response) => {
      console.log('에러' + response);
    }

    request.get(`/api/payment/treatment?order_seq=${orderInfo?.order_seq}`, null, successHandler, failHandler, null, null);
  }

  useEffect(() => {
    if (orderInfo) {
      getTreatmentPaytInfo(orderInfo.order_seq);
    }
  }, [orderInfo])


  return (
    <>
      <div className="dashboard_sec dashboard_visit">
        <div className="tab_box">
          <ul className="tab">
            {orderButtons.map((el, i) => (
              <li className="item" key={i}
                onMouseOver={() => handleMouseOver(el.value)}
              >
                <button
                  className={`menu active ${el.active ? 'receipt' : ''}`}
                  onClick={() => {
                    const updatedOrderButtons = orderButtons.map((button, index) => {
                      return {
                        ...button,
                        active: index === i && !button.active
                      };
                    });
                    setOrderButtons(updatedOrderButtons);

                    const activeButton = updatedOrderButtons.find(button => button.value === el.value)
                    setReceptionList(sortByOrderStatus(activeButton.value, activeButton.active, receptionList))
                  }}
                >
                  {el.text} {el.count}
                </button>
                {anesthesiaPopBool && el.value === "013008000" && <div onMouseOver={() => handleMouseOver("013008000")} onMouseLeave={handleMouseLeave}> <AnesthesiaList setAnesthesiaPopBool={setAnesthesiaPopBool} receptionList={receptionList} timers={timers} /> </div>}
              </li>
            ))}
          </ul>
          <button className="svg_icon icon_refresh" onClick={() => getReceptionList()}></button>
        </div>
        <div className="container">
          {receptionList != null && receptionList?.length > 0 ? receptionList?.map((item, index) => (
            <div className="list" key={index} onClick={(e) => shadowBoxToggle(e, item)}>
              <section className="com_sec shodow_disable">
                <article className="com_user_box">
                  <div className={`color ${item?.order_count <= 1 ? "first" : "second"}`}></div>
                  <div className="pic" onClick={(e) => selectUploadFile(item.user_seq)} style={{ cursor: "pointer" }} >
                    <label htmlFor="user_photo" style={{ cursor: "pointer" }} >
                      {
                        nvl(item?.file_path) === '' ?
                          nvl(imgBase64) !== '' && <img key={imgBase64} src={imgBase64} alt="미리보기" style={{ width: "74px" }} />
                          :
                          nvl(item?.file_path) !== '' &&
                          <img src={`${process.env.REACT_APP_API_URL}/api/upload/viewer/image/photo/${item?.file_path.split("/")[item?.file_path.split("/").length - 2]}_${item?.file_path.split("/")[item?.file_path.split("/").length - 1]}`}
                            alt="" style={{ width: "74px" }} />
                      }
                    </label>
                    <input type="file" id="uploadFile" name="files" className="input" ref={uploadFileRef} style={{ display: "none" }} onChange={(e) => uploadFileHandler(e)} />
                  </div>
                  <div className="age_phone" onClick={() => getSelectedUserInfo(item.user_seq, 'popup')} style={{ background: `${item?.member_info?.user_level_color}` }}>
                    <h1 className="age">{item?.member_info?.user_name} ({convertGender(item?.member_info?.user_gender)}/{nvl(item?.member_info?.user_jumin) === "" ? " - " : convertJuminToAge((decrypt(item?.member_info?.user_jumin).substring(0, 6) + "-" + decrypt(item?.member_info?.user_jumin).substring(6, 13)))})</h1>
                    <h2 className="phone">{item?.member_info?.user_mobile}</h2>
                  </div>
                  <div className="btns_wrap">
                    <button type="button" className="btn" onClick={() => { setSnsSendBool(!snsSendBool) }}><i className="svg_icon icon_sms yellow">&nbsp;</i></button>
                    <button type="button" className="btn" onClick={() => { setCustomerPopup(prev => { return { ...prev, memo: true, userData: { user_seq: item.member_info.user_seq, user_name: item.member_info.user_name, user_jumin: item.member_info.user_jumin, user_gender: item.member_info.user_gender } } }) }}><i className="svg_icon icon_write yellow">&nbsp;</i></button>
                  </div>
                </article>
                <article className="com_user_box_btns">
                  <div className="left_wrap">
                    <div className="btns_wrap"> {/* 상태 버튼 영역 */}
                      {buttons.map((el, i) => (
                        <button key={"subButton_" + uuidv4()} type="button" className={"button " + (item.order_status === el.value ? el.className : "")} disabled={nvl(item?.chart_info) === '' ? (["013004000", "013005000", "013007000", "013009000", "013011000", "013012000"].includes(el.value) ? true : false) : false} onClick={(e) => { statusHandler(index, el.value, item.order_seq, item.user_seq) }} value={el.value}>{el.text}</button>
                      ))}
                      <button className="info gray" onClick={nvl(item?.reservation_info) !== "" ? () => { getSelectedUserInfo(item.user_seq, 'reservationAdd'); } : null} disabled={nvl(item?.reservation_info) === "" ? true : ""}>
                        <span className="txt time">{nvl(item?.reservation_info?.reser_seq) === "" ? "방문 접수" : item?.reservation_info?.reser_time}</span>
                        <span className="division_bar">|</span>
                        <span className="txt product">
                          {
                            nvl(item.reservation_info) === "" ? "" : (
                              item?.chart_info.length > 0 ? item?.chart_info[0]?.goods_info?.goods_name : nvl(item?.reservation_info?.treatment_name) === "" ? "시술 없이 예약" : item?.reservation_info?.treatment_name) // treatment_name 혹은 goods_name
                          }
                        </span>
                        {/* <span className="division_bar">|</span> */}
                        {/* <span className="txt doctor">강원장</span> */}
                      </button>
                    </div>
                  </div>
                  <div className="right_wrap">
                    <select
                      className="select"
                      onChange={(e) => {
                        roomClickHandler(e, index);
                      }}
                      name="parent_code"
                      value={
                        nvl(item?.reservation_info?.treatment_room) !== "" && nvl(item?.dash_treatment_room) !== "" && nvl(item?.parent_code) !== ""
                          ? item?.parent_code
                          : nvl(item?.dash_treatment_room) !== "" && nvl(item?.parent_code) !== ""
                            ? item?.parent_code
                            : nvl(item?.dash_treatment_room) !== ""
                              ? item?.dash_treatment_room?.substr(0, 6) + "000"
                              : nvl(item?.reservation_info?.treatment_room) !== ""
                                ? item?.reservation_info?.treatment_room?.substr(0, 6) + "000"
                                : item?.parent_code
                      }
                    >
                      <option value="0">시술실 선택</option>
                      {surgeryList.map((el, i) => (
                        <option key={el.code} value={el.code}>
                          {el.code ? el.name : ""}
                        </option>
                      ))}
                    </select>
                    <select
                      className="select"
                      onChange={(e) => roomClickHandler(e, index)}
                      name="treatment_room"
                      value={
                        nvl(item?.reservation_info?.treatment_room) !== "" && nvl(item?.dash_treatment_room) !== "" && nvl(item?.treatment_room) !== ""
                          ? item?.treatment_room
                          : nvl(item?.dash_treatment_room) !== "" && nvl(item?.treatment_room) !== ""
                            ? item?.treatment_room
                            : nvl(item?.dash_treatment_room) !== ""
                              ? item?.dash_treatment_room
                              : nvl(item?.treatment_room) !== ""
                                ? item?.treatment_room
                                : nvl(item?.reservation_info?.treatment_room) !== ""
                                  ? item?.reservation_info?.treatment_room
                                  : item?.treatment_room

                      }
                    >
                      <option value="0">룸 선택</option>
                      {
                        (nvl(item?.roomList) !== "" ? item?.roomList : allRoomList).map((el, i) => (
                          <option key={i} value={el.code}>
                            {el.code ? el.name : ""}
                          </option>
                        ))
                      }
                    </select>
                    {nvl(item.doctor_info) !== "" && <p className="doctor">{item.doctor_info}</p>}
                  </div>
                  <div className="bottom_wrap">
                    <div className="select_wrap">
                      <select
                        className="select"
                        name="reception"
                        value={nvl(item.receptionist_seq) || ''}
                        onChange={(e) => managerHandler(e, item.main_tc_seq, item.user_seq)}
                      >
                        <option value="0">직원 선택</option>
                        {managerList?.map((el) => (
                          <option key={el.manager_seq} value={nvl(el.manager_seq) || ''}>{el.manager_name}</option>
                        ))}
                      </select>
                      <span className="txt">접수</span>
                    </div>
                    <div className="select_wrap">
                      <select
                        className="select"
                        name="counselor"
                        value={nvl(item.counselor_seq) || ''}
                        onChange={(e) => managerHandler(e, item.main_tc_seq, item.user_seq)}
                      // disabled={item?.chart_info.length === 0 ? true : false}
                      >
                        <option value="0">직원 선택</option>
                        {managerList?.map((el) => (
                          <option key={el.manager_seq} value={nvl(el.manager_seq) || ''}>{el.manager_name}</option>
                        ))}
                      </select>
                      <span className="txt">상담</span>
                    </div>
                    <button className="com_btn blue m" id='order' onClick={() => memoHandler("order", item.user_seq)}><i className="svg_icon icon_print yellow">&nbsp;</i>오더 인쇄</button>
                  </div>
                </article>
              </section>
              {/*상세*/}
              <section className="com_sec shodow_disable detail active">
                <article className="timeBtn">
                  { /** modified by psk 20230512 */}
                  { // timerButtons
                    timerButtons != null && timerButtons.length > 0 ?
                      timerButtons?.map((orderList) => (
                        orderList != null && orderList.length > 0 ? orderList?.map((buttonInfo) => {
                          const onAlarm = timers.find(timer => timer.user_seq === buttonInfo.user_seq && timer.order_seq === buttonInfo.order_seq && timer.value === 1 && timer.text === "마취중(알람)" && (timer.curTime - timer.startTime) >= timer.alarmTime)
                          return (
                            item.user_seq === buttonInfo.user_seq && item.order_seq === buttonInfo.order_seq && // 일단 사용자와 오더 시퀀스 확인
                            <div className="btnWrap" key={"timerButtons_" + buttonInfo.sub_tc_seq + "_" + buttonInfo.value} >

                              <button style={buttonInfo.value === 1 && onAlarm ? { backgroundColor: "red" } : {}} className={`button ${buttonInfo.active ? "active" : ""}`} id={buttonInfo.user_seq} disabled={buttonInfo.value !== 1} 
                                onClick={(e) => { buttonClickHandler(buttonInfo.user_seq, buttonInfo.order_seq, buttonInfo.sub_tc_seq, buttonInfo.value) }}>
                                <span className="tit">{buttonInfo.text}</span>
                                <span className="time">{getDisp2(buttonInfo.user_seq, buttonInfo.order_seq, buttonInfo.sub_tc_seq, buttonInfo.value)}</span>
                              </button>
                              {
                                buttonInfo.value === 1 &&
                                <>
                                  {buttonInfo.active && ((!buttonInfo.isLive && buttonInfo.text !== "마취끝") || (buttonInfo.isLive && onAlarm)) && 
                                    <button className="btnPause" onClick={(e) => { buttonClickHandler(buttonInfo.user_seq, buttonInfo.order_seq, buttonInfo.sub_tc_seq, buttonInfo.value, e.currentTarget.className); e.stopPropagation() }}>&nbsp;</button>}

                                    {/**알람은 항상 노출 */}
                                    <button className="btnAlarm" onClick={() => setPopup(prev => { return { ...prev, anesthesiaAlarm: true, user_seq: buttonInfo.user_seq, order_seq: buttonInfo.order_seq } })}>&nbsp;</button>

                                  {buttonInfo.active && (!buttonInfo.isLive || buttonInfo.text === "마취끝") && 
                                    <button className="btnPlay" onClick={(e) => { buttonClickHandler(buttonInfo.user_seq, buttonInfo.order_seq, buttonInfo.sub_tc_seq, buttonInfo.value, e.currentTarget.className); e.stopPropagation() }} >&nbsp;</button>}

                                  {buttonInfo.active && buttonInfo.isLive && !onAlarm && 
                                    <button className="btnReset" onClick={(e) => { buttonClickHandler(buttonInfo.user_seq, buttonInfo.order_seq, buttonInfo.sub_tc_seq, buttonInfo.value, e.currentTarget.className); e.stopPropagation() }} >&nbsp;^^</button>}
                                </>
                              }
                            </div>
                          )
                        }) : null
                      )) : null
                  }
                </article>
                <article className="memo_wrap">
                  {
                    nvl(item?.chart_info) !== '' &&
                      item?.chart_info.length > 0 ? item?.chart_info.map((ele, idx) => (
                        <div className="wrap" key={idx}>
                          <div className="memo">
                            <span className="txt">{`${idx + 1} / ${ele.total_times}`}</span> {/* 사용한 회차  / 전체 회차*/}
                            <span className="txt">{ele.current_times} 회차</span> {/* 현재 시술받는 회차 */}
                            <span className="txt">{ele?.goods_info === "goods_null" ? "-" : ele.pack_info.goods_type === "P" ? `패키지 ${ele.goods_info.goods_name}` : `${ele.goods_info.goods_name}`}</span>
                            <span className="txt">{ele.goods_info.comment ? ele.goods_info?.comment : "부연설명"}</span>
                            <button className="button" onClick={() => memoHandler("memo", item.user_seq, ele?.goods_info?.goods_seq)}><i className="svg_icon icon_write yellow">&nbsp;</i></button>
                          </div>
                          <div className="staff">
                            <select className="select" name="preparation_manager" value={nvl(ele?.preparation_manager_seq) || ''} onChange={(e) => finishHandler(e, item.user_seq, ele.sub_tc_seq)}>
                              <option value="">준비자</option>
                              {managerList?.map((el) => (<option key={el?.manager_seq} value={el?.manager_seq}>{el?.manager_name}</option>))}
                            </select>

                            <select className="select" name="treatment_manager" value={nvl(ele?.treatment_manager_seq) || ''} onChange={(e) => finishHandler(e, item.user_seq, ele.sub_tc_seq)}>
                              <option value="">시술자</option>
                              {managerList?.map((el) => (<option key={el?.manager_seq} value={el?.manager_seq}>{el?.manager_name}</option>))}
                            </select>

                            <select className="select" name="manager" value={nvl(ele?.manager_seq) || ''} onChange={(e) => finishHandler(e, item.user_seq, ele.sub_tc_seq)}>
                              <option value="">관리자</option>
                              {managerList?.map((el) => (<option key={el?.manager_seq} value={nvl(el?.manager_seq) || ''}>{el?.manager_name}</option>))}
                            </select>

                            <select className="select" name="finish_manager" value={nvl(ele?.finish_manager_seq) || ''} onChange={(e) => finishHandler(e, item.user_seq, ele.sub_tc_seq)}>
                              <option value="">마무리</option>
                              {managerList?.map((el) => (<option key={el?.manager_seq} value={nvl(el?.manager_seq) || ''}>{el?.manager_name}</option>))}
                            </select>
                          </div>
                          <button className={ele.sub_chart_status === "013013000" ? 'com_btn red' : 'com_btn gray'} onClick={() => finishClick(item.order_seq, item.user_seq, ele.sub_tc_seq, ele.sub_chart_status)}>완료</button>
                        </div>
                      )) : null
                  }
                </article>
                <article className="payment">
                  <button className="com_btn blue m" id="paymentDetail" onClick={() => memoHandler("pay", item.user_seq, null, item.order_status)}>결제하기</button>
                  <div className="price_area">
                    <p className="item">
                      <span className="tit">수납액</span>
                      <span className="price txt_blue">{addComma(item?.payment_info?.payment_price)}원</span>
                    </p>
                    <p className="item">
                      <span className="tit">미수액</span>
                      <span className="price txt_red">{addComma(item?.payment_info?.unpaid_price)}원</span>
                    </p>
                  </div>
                </article>
              </section>
            </div>
          )) : ""}
        </div>
      </div>
      {popup.customerCard && <CustomCard onClose={() => onClosePop('customerCard')} userData={userData} />}
      {popup.reservationAdd && <ReservationAdd onClose={() => onClosePop('reservationAdd')} userData={userData} setLnbSearchOptions={setLnbSearchOptions} changeUserHandler={changeUserHandler}/>}
      {popup.anesthesiaAlarm && <AnesthesiaAlarm onClose={() => onClosePop('anesthesiaAlarm')} popup={popup} changeTimerStatus={changeTimerStatus} timers={timers} btnTimerSubmitHandler={btnTimerSubmitHandler} />}
      {popup.explainAdd && <ExplanAdd onClose={() => onClosePop('explainAdd')} userData={userData} treatment={treatment} todayOrders={todayOrders} setTodayOrders={setTodayOrders} managerInfo={managerInfo} getReceptionList={getReceptionList} />}
      {alertLayerPopup.visible && <CustomAlert onClose={closeCustomAlert} alertType={alertLayerPopup.alertType} alertMessage={alertLayerPopup.alertMessage} returnValue={alertLayerPopup.returnValue} />}
      {snsSendBool && <SnsSend snsSendBool={snsSendBool} setSnsSendBool={setSnsSendBool} />}
      {customerPopup.memo && <MemoCard customerPopup={customerPopup} setCustomerPopup={setCustomerPopup} />}
      {popup.order && <OrderPrint onClose={() => { onClosePop('order'); }} userData={userData} popup={popup} setPopup={setPopup} managerInfo={managerInfo} todayOrders={todayOrders} setTodayOrders={setTodayOrders} orderInfo={orderInfo} receptionList={receptionList} />}
      {popup.paymentDetail && <PaymentDetail onClose={() => { onClosePop('paymentDetail') }} popup={popup} onClosePop={onClosePop} alertPop={alertLayerPopup} setAlertPop={setAlertLayerPopup} userData={userData} orderInfo={orderInfo} payTreatment={payTreatment} taxGoods={taxGoods} taxFreeGoods={taxFreeGoods} prescriptionInfo={prescriptionInfo} setPrescriptionInfo={setPrescriptionInfo} medicineSetList={medicineSetList} setMedicineSetList={setMedicineSetList} userInfo={userData} setUserInfo={setUserData}/>}
    </>
  )
});

export default DashVisit